import React, { useEffect } from "react";

const NumberInputField = props => {
  useEffect(() => {
    if (props.value === undefined) {
      props.setFieldValue(1);
    }
  }, [props.value]);

  return (
    <div className="field">
      <input
        disabled={props.disabled}
        name={props.name}
        type="number"
        value={props.value}
        onChange={e => {
          props.setFieldValue(e.target.value);
        }}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default NumberInputField;
