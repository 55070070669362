import axios from "../axios";

class CommentsService {
  // eslint-disable-next-line camelcase
  static getRossumLink = (id, cancel_url) => {
    return axios.get(`/comments/${id}/renovate_rossum_link`, {
      params: { cancel_url }
    });
  };
}

export default CommentsService;
