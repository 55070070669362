export const openModal = modalClass => {
  $(`.ui.modal.${modalClass}:last`).modal("show");
};

export const closeModal = () => {
  $(".ui.modal").modal("hide");
};

export const handleCloseModal = (modalClass, resetForm) => {
  $(`.ui.modal.${modalClass}:last`).modal({
    onHide: () => {
      resetForm();
    }
  });
};

export const initDropDown = () => {
  // $(".ui.dropdown").dropdown();
  // $(".item.ui.noclose").click(event => {
  //   event.stopPropagation();
  // });
};

export const onChangeDropDown = onChangeFunction => {
  $(".ui.multiple.dropdown").dropdown({
    onChange: onChangeFunction
  });
};

export const initTab = () => {
  $(".menu .item").tab();
};

export const reactSelectStyle = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0
    }
  })
};
