/* eslint-disable react/sort-comp */
import React from "react";
import { debounce, isEqual } from "lodash";
import ApplicationsPreConfiguredCard from "./ApplicationsPreConfiguredCard";
import Pagination from "../../../Pagination";
import i18n from "../../../i18n";
import ApplicationService from "../../../../services/data_services/application";
import WithLoadingComponent from "../../../Ui/WithLoadingComponent";

class ApplicationsPreConfigured extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: undefined,
      queryParams: {},
      nPages: 0
    };
  }

  componentDidMount() {
    this.loadApplications();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.loadApplications();
    }
  }

  setQueryParams(key, value) {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  }

  loadApplications = debounce(() => {
    const query = { ...this.state.queryParams };
    query.pre_configured = true;

    ApplicationService.loadApplications(query)
      .then(response => {
        return Array.isArray(response.data)
          ? { results: response.data, total: 0 }
          : response.data;
      })
      .then(data => {
        this.setState({
          applications: data.results,
          nPages: data.total
        });
      })
      .catch(() => {
        this.setState({
          applications: [],
          nPages: 0
        });
      });
  }, 500);

  applications = () => {
    return this.state.applications ? this.state.applications : [];
  };

  render() {
    if (this.state.applications && this.state.applications.length === 0) {
      return null;
    }

    return (
      <WithLoadingComponent
        loadingCondition={() => !this.state.applications}
        contentCondition={() =>
          this.state.applications && this.state.applications.length > 0
        }
        noItemsKey="NoPreConfiguredApplications"
      >
        <div style={{ paddingBottom: "1rem" }}>
          <h3>{i18n.t("PreConfiguredApplications")}</h3>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap"
              }}
            >
              {this.applications().map(application => {
                return (
                  <ApplicationsPreConfiguredCard
                    key={application.id}
                    application={application}
                  />
                );
              })}
            </div>
            <Pagination
              nPages={this.state.nPages}
              page={this.state.queryParams ? this.state.queryParams.page : 1}
              onChange={page => {
                this.setQueryParams("page", page);
              }}
            />
          </div>
        </div>
      </WithLoadingComponent>
    );
  }
}

export default ApplicationsPreConfigured;
