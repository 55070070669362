import React from "react";
import { Chart } from "react-google-charts";
import i18n from "../i18n";

const EmptyChart = ({ chartHeight = "350px", title }) => {
  return (
    <React.Fragment>
      <div style={{ height: chartHeight }} className="noChart-data">
        No Data
      </div>
      <Chart
        width="100%"
        height={chartHeight}
        chartType="ColumnChart"
        loader={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px"
            }}
          >
            {i18n.t("Loading")}
          </div>
        }
        data={[
          ["", "Density", { role: "style" }],
          ["", 15, "silver"],
          ["", 10.49, "silver"],
          ["", 19.3, "silver"],
          ["", 21.45, "silver"]
        ]}
        options={{
          enableInteractivity: false,
          title,
          hAxis: {
            title: "",
            minValue: 0,
            maxValue: 15,
            baselineColor: "silver",
            gridlines: { color: "silver" }
          },
          vAxis: {
            title: "",
            minValue: 0,
            maxValue: 15,
            baselineColor: "silver",
            gridlines: { color: "silver" }
          },
          legend: "none",
          bar: { groupWidth: "95%" },
          backgroundColor: "#F8F8F8"
        }}
      />
    </React.Fragment>
  );
};

export default EmptyChart;
