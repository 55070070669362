import React from "react";
import TextInputField from "./TextInputField";
import NumberInputField from "./NumberInputField";
import FlagInputField from "./FlagInputField";
import BinaryInputField from "./BinaryInputField";
import PasswordInputField from "./PasswordInputField";
import DateTimeInputField from "./DateTimeInputField";
import DateInputField from "./DateInputField";
import TimeInputField from "./TimeInputField";
import UndefinedInputField from "./UndefinedInputField";

const fieldTypes = {
  text: TextInputField,
  number: NumberInputField,
  date: DateInputField,
  datetime: DateTimeInputField,
  password: PasswordInputField,
  time: TimeInputField,
  flag: FlagInputField,
  binary: BinaryInputField,
  nvarchar: TextInputField,
  int: NumberInputField,
  bigint: NumberInputField,
  tinyint: NumberInputField,
  datetime2: DateTimeInputField,
  bit: FlagInputField,
  bool: FlagInputField,
  boolean: FlagInputField
};

const InputField = props => {
  const componentName = () => {
    return Object.prototype.hasOwnProperty.call(fieldTypes, props.type)
      ? fieldTypes[props.type]
      : UndefinedInputField;
  };

  const className =
    props.fromDashboardIndex || props.fromEnvVariables
      ? "field"
      : "inline fields";

  const FieldComponent = componentName();

  if (!FieldComponent) {
    return null;
  }

  return (
    <div className={className}>
      {props.title && <label>{props.title}</label>}
      <div className="field full-width">
        <FieldComponent
          fromEnvVariables={props.fromEnvVariables}
          disabled={props.disabled}
          name={props.name}
          value={props.value}
          setFieldValue={props.setFieldValue}
        />
      </div>
    </div>
  );
};

InputField.defaultProps = {
  fromDashboardIndex: false
};

InputField.defaultProps = {
  fromDashboardIndex: false
}

export default InputField;
