import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import i18n from "../i18n";
import Toast from "../../services/toasts";
import DataConfigService from "../../services/data_services/dataConfig";
import WidgetService from "../../services/data_services/widget";
import InputField from "../Scheduler/Schedulers/InputFields/InputField";

class WidgetEdit extends Component {
  constructor(props){
    super(props);

    this.state = {
      parameters: undefined,
      isClearing: false,
      isSubmitting: false
    }
  }

  componentDidMount() {
    this.loadWidgetParameters();
    this.setInitialValues();
  }

  loadWidgetParameters = () => {
    const { widget } = this.props;
    WidgetService.loadParameters(widget.service_id, widget.procedure)
      .then(response => {
          this.setState({ parameters: response.data[0].parameters })
      })
      .catch(() => {
        this.setState({ parameters: {} })
      })
  };

  setInitialValues = () => {
    this.setState({ data: this.props.widget.function_inputs });
  }

  getParamValue = name => {
    return this.state.data.find(param => param.parameter_name === name)?.parameter_value;
  }

  onClearHandler = () => {
    this.setState({ isClearing: true })
    DataConfigService.deleteWidgetConfiguration(this.props.user.id, this.props.widget.id)
    .then(() => {
      this.setState({ isClearing: false })
      this.props.onCancel();
      document.getElementById(this.props.reloadButtonId).click();
    })
    .catch(() => {
      this.setState({ isClearing: false })
      this.props.onCancel();
    })
  }

  prepareValuesForSubmit = () => {
    const { data } = this.state;
    const auxArray = [];
    Object.keys(data).forEach(item => {
      auxArray.push({ 
        parameter_name: item,
        parameter_value: data[item]
      })
    })
    return auxArray;
  }

  submitData = () => {
    this.setState({ isSubmitting: true })
    DataConfigService.saveWidgetConfiguration(this.props.user.id, this.props.widget.id, this.state.data)
      .then(() => {
        this.setState({ isSubmitting: false })
        Toast.show.widgetsUpdated;
        document.getElementById(this.props.reloadButtonId).click();
        
      })
      .catch(() => {
        this.setState({ isSubmitting: false })
        Toast.show.widgetsNotUpdated;
      })
  }

  renderLoading = () => {
    return (
      <div
        className="ui"
        style={{ minHeight: "100px", position: "relative" }}
      >
        <div className="ui active inverted dimmer">
          <div className="ui text loader">{i18n.t("Loading")}</div>
        </div>
      </div>
    );
  }

  renderForm = () => {
    return (
    <div className="widgetFormContainer">
      <div className="ui form rightPadding">
        {this.state.parameters.map(parameter => {
          const name = parameter.parameter_name;
          return (
            <InputField
              key={name}
              title={name}
              name={name}
              type={parameter.parameter_type}
              value={this.getParamValue(name)}
              setFieldValue={value => {
                const newData = [ ...this.state.data ];
                const arrayPositionToUpdate = newData.findIndex(param => param.parameter_name === name);
                if (arrayPositionToUpdate >= 0) {
                  newData[arrayPositionToUpdate].parameter_value = value;
                  this.setState({ data: newData });
                }
              }}
              fromDashboardIndex
            />
          )
        })
      }
      <div style={{
        display: "flex",
        justifyContent: "flex-end"
      }}>
        <button 
          className="ui mini button basic blue"
          onClick={() => this.props.onCancel()}  
        >
          {i18n.t("Cancel")}
        </button>
        <button 
          className={`ui mini button basic blue ${this.state.isClearing ? "loading" : ""}`}
          onClick={() => this.onClearHandler()}  
        >
          {i18n.t("ResetButton")}
        </button>
        <button
          disabled={this.state.isSubmitting} 
          className={`ui mini button blue ${this.state.isSubmitting ? "loading" : ""}`}
          onClick={() => this.submitData()}  
        >
          {i18n.t("Save")}
        </button>
      </div>
    </div>
    </div>
    )
  }

  render() {
    if (!this.state.parameters) {
      return this.renderLoading();
    }
    if (isEmpty(this.state.parameters)) {
      return null;
    }

    return this.renderForm();
  }
}

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  }
}

export default connect(mapStateToProps)(WidgetEdit);
