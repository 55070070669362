import React from "react";
import moment from "moment-timezone";
import { Field, Formik } from "formik";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import LanguageSelector from "./LanguageSelector";
import TimezoneSelector from "./TimezoneSelector";
import i18n from "../i18n";
import axios from "../../services/axios";
import Toast from "../../services/toasts";
import { defaultProfileImage, checkImageExists } from "../../services/images";
import { getDateFormat } from "../../services/timezones";
import SubmitButton from "../SubmitButton";
import Upperbar from "../Ui/Upperbar/Upperbar";
import { isDesktop } from "../../services/general";

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.languageRef = React.createRef();
    this.state = {
      changed: false,
      submit: false
    };

    this.changeSubmitOption = this.changeSubmitOption.bind(this);
    props.setBreadcrumb([{ title: i18n.t("Settings"), location: "/settings" }]);
  }

  componentDidMount() {
    this.loadUser();
  }

  loadUser() {
    axios
      .get(`/users/${this.userId()}`)
      .then(response => {
        checkImageExists(response.data.profile_img, imageExists => {
          this.setState({
            language: response.data.language,
            timezone: response.data.timezone,
            dateformat: response.data.dateformat,
            name: response.data.name,
            email: response.data.email,
            phone_number: response.data.phone_number,
            profile_img: response.data.profile_img,
            image_exists: imageExists
          });
        });

        // this.languageRef.current.updateSelected(this.state.language);
      })
      .catch(() => {
        this.setState({
          language: null,
          timezone: null,
          dateformat: null,
          name: "",
          email: "",
          phone_number: "",
          profile_img: null,
          image_exists: false
        });
      });
  }

  profileImage = () => {
    if (this.state.image_exists) {
      return this.state.profile_img;
    }
    return defaultProfileImage();
  };

  changeSubmitOption(option) {
    if (this.state.submit !== option) {
      this.setState({ submit: option });
    }
  }

  getFormData(values) {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    });

    return formData;
  }

  inputChanged = (setFieldValue, e) => {
    this.changeSubmitOption(false);
    if (e.target.name === "timezone") {
      setFieldValue("timezone", e.target.value);
      setFieldValue("dateformat", getDateFormat(e.target.value));
    } else {
      setFieldValue(
        e.target.name,
        e.target.type === "checkbox" ? e.target.checked : e.target.value
      );
    }
    const state = { ...this.state };
    state.changed = true;
    if (e.target.name === "timezone") {
      state.timezone = e.target.value;
      state.dateformat = getDateFormat(e.target.value);
    } else {
      state[e.target.name] = e.target.value;
    }
    this.setState(state);
  };

  inputChangedPhone = (setFieldValue, e) => {
    setFieldValue("phone_number", e);
    this.setState({
      changed: true
    });
  };

  userId = () => {
    return this.props.user.id;
  };

  updateUser = (values, submittingFormik) => {
    axios
      .put(`/users/${this.userId()}`, this.getFormData(values))
      .then(() => {
        Toast.show.accountUpdated();
        this.setState({ changed: false, submit: false });
        submittingFormik(false);

        if (
          this.state.profile_img !== values.profile_img ||
          this.state.language !== values.language
        ) {
          window.location.reload();
        }
      })
      .catch(() => {
        Toast.show.accountNotUpdated();
        submittingFormik(false);
      });
  };

  renderLoading = () => {
    return (
      <div>
        <div
          className="ui"
          style={{ minHeight: "100px", position: "relative" }}
        >
          <div className="ui active inverted dimmer">
            <div className="ui text loader">{i18n.t("Loading")}</div>
          </div>
        </div>
      </div>
    );
  };

  renderForm() {
    return (
      <Formik
        initialValues={{
          language: this.state.language,
          timezone: this.state.timezone || moment.tz.guess(),
          dateformat: this.state.dateformat,
          name: this.state.name || "",
          email: this.state.email || "",
          phone_number: this.state.phone_number || "",
          push_notification: this.state.push_notification || false,
          email_notifications: this.state.email_notifications || false
        }}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          if (this.state.submit) {
            this.updateUser(values, setSubmitting);
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <>
            {isDesktop() && (
              <div style={{ marginTop: "-24px" }}>
                <Upperbar title={i18n.t("AccountSettings")} />
              </div>
            )}
            <form className="ui form account-settings" onSubmit={handleSubmit}>
              <div className="ui segment middle aligned accountFormContainer">
                <div className="accountContainer image">
                  <div className="profileImageContainer">
                    <div
                      id="profileImage"
                      className="ui circular image"
                      style={{
                        backgroundImage: `url(${this.profileImage()})`
                      }}
                    />
                    <button
                      className="circular ui icon button "
                      type="button"
                      onClick={() => {
                        $("#profile_img").click();
                      }}
                    >
                      <i className="icon pencil" />
                    </button>

                    <div className="field" style={{ display: "none" }}>
                      <Field
                        type="file"
                        name="profile"
                        id="profile_img"
                        onChange={event => {
                          setFieldValue(
                            "profile_img",
                            event.currentTarget.files[0]
                          );
                          this.setState({
                            changed: true
                          });
                          $("#profileImage").css(
                            "background-image",
                            `url("${window.URL.createObjectURL(
                              event.currentTarget.files[0]
                            )}")`
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="accountContainer">
                  <div className="field">
                    <p>{i18n.t("Language")}</p>
                    <LanguageSelector
                      defaultLang={this.state.language}
                      onChange={e => {
                        this.inputChanged(setFieldValue, {
                          target: {
                            name: "language",
                            value: e
                          }
                        });
                      }}
                    />
                  </div>
                  <div className="field">
                    <p>{i18n.t("Timezone")}</p>
                    <TimezoneSelector
                      defaultTimezone={values.timezone}
                      onChange={e => {
                        this.inputChanged(setFieldValue, {
                          target: {
                            name: "timezone",
                            value: e.value
                          }
                        });
                      }}
                    />
                  </div>
                  <div className="field">
                    <p>{i18n.t("Name")}</p>
                    <input
                      name="name"
                      onChange={e => {
                        this.inputChanged(setFieldValue, e);
                      }}
                      value={values.name}
                    />
                  </div>

                  <div className="field">
                    <p>{i18n.t("Email")}</p>
                    <input
                      name="email"
                      onChange={e => {
                        this.inputChanged(setFieldValue, e);
                      }}
                      value={values.email}
                      disabled
                    />
                  </div>

                  <div className="field">
                    <p>{i18n.t("PhoneNumber")}</p>
                    <PhoneInput
                      flags={flags}
                      name="phone_number"
                      placeholder="Enter phone number"
                      value={values.phone_number}
                      onChange={e => {
                        this.inputChangedPhone(setFieldValue, e);
                      }}
                    />
                  </div>
                  {this.renderButtons(isSubmitting)}
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    );
  }

  renderButtons(isSubmitting) {
    return (
      <div
        className="ui grid middle aligned"
        style={{ visibility: this.state.changed ? "" : "hidden" }}
      >
        <div className="ui eight wide column">
          <SubmitButton
            label="SaveButton"
            style={{ width: "100%" }}
            isSubmitting={isSubmitting}
            to_submit={this.changeSubmitOption}
          />
        </div>
        <div className="ui eight wide column">
          <button
            className="ui button basic blue"
            style={{ width: "100%" }}
            type="button"
            onClick={() => {
              this.setState({ changed: false });
            }}
          >
            {i18n.t("CancelButton")}
          </button>
        </div>
      </div>
    );
  }

  render() {
    return this.state ? this.renderForm() : this.renderLoading();
  }
}

export default AccountDetails;
