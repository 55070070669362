import React from "react";
import { isEqual } from "lodash";
import { toIntArray } from "../../../services/array";
import { APPLICATION_TYPES } from "../../../constants/ApplicationTypes";

const ActivityCard = props => {
  let backgroundClass = props.squareInfo.backgroundColor;

  let selected = null;
  let squareFilters = null;

  if (props.applicationType === APPLICATION_TYPES.UNATTENDED) {
    selected = props.queryParams.status;
    squareFilters = [props.squareInfo.filter];
  } else {
    selected = toIntArray(props.queryParams.status);
    squareFilters = toIntArray(props.squareInfo.filter);
  }

  if (
    selected?.length > 0 &&
    props.cardsSelected &&
    !isEqual(selected, squareFilters)
  ) {
    backgroundClass += "Unselected";
  }

  const onCardClick = () => {
    let status;
    let date;
    let cards;

    if (!isEqual(selected, squareFilters)) {
      status = Array.isArray(props.squareInfo.filter)
        ? props.squareInfo.filter
        : [props.squareInfo.filter];
      date = props.squareInfo.date;
      cards = true;
    }

    props.onChange({ status, date, cards });
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={`ui dashboardHeaderSquare ${backgroundClass}`}
      onClick={onCardClick}
    >
      <p className="title">{props.squareInfo.title}</p>
      <div className="flexStart">
        <h3>
          <i className={`icon ${props.squareInfo.icons[0]}`} />
          {props.contents}
        </h3>
      </div>
    </div>
  );
};

export default ActivityCard;
