import React from "react";
import { Message, Table } from "semantic-ui-react";
import { toLower } from "lodash";
import i18n from "../i18n";
import Attachment from "./Attachment";
import ActivityEditSidebar from "./ActivityEdit";
import { activityDate } from "../../services/string";
import { attachmentTypes, emailType } from "../../constants/email-data";
import { isDesktop } from "../../services/general";
import { APPLICATION_TYPES } from "../../constants/ApplicationTypes";

import "stylesheets/Activities/ActivityMeta.scss";

class ActivityMeta extends React.Component {
  attachmentsData = () => {
    return (
      this.props.activity.data &&
      Array.isArray(this.props.activity.data) &&
      this.props.activity.data.filter(
        dataElement =>
          Object.values(attachmentTypes).includes(dataElement.type) &&
          dataElement.value !== "" &&
          dataElement.value !== undefined &&
          !dataElement.value.includes(null)
      )
    );
  };

  fieldsData = () => {
    const types = Object.values(attachmentTypes);
    types.push(emailType);

    return (
      this.props.activity.data &&
      Array.isArray(this.props.activity.data) &&
      this.props.activity.data.filter(
        dataElement => !types.includes(dataElement.type)
      )
    );
  };

  fieldEmail = () => {
    const emails =
      this.props.activity.data && Array.isArray(this.props.activity.data)
        ? this.props.activity.data.filter(
            dataElement => dataElement.type === emailType
          )
        : [];

    return emails.length > 0;
  };

  renderValue = value => {
    if (Array.isArray(value)) {
      return value.join(", ");
    }

    return value;
  };

  renderEmail() {
    if (this.fieldEmail()) {
      return (
        <div className="activityData">
          <p className="valueLabel">{i18n.t("Body")}</p>
          <div className="valueContainer">
            <iframe
              src={`/preview_mail/${this.props.activity.id}`}
              style={{ width: "100%", height: "500px", border: "0" }}
            />
          </div>
        </div>
      );
    }

    return undefined;
  }

  renderCollection = data => {
    if (!data) {
      return "";
    }
    const collection = JSON.parse(data);
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            {Object.keys(collection[0]).map((col, index) => {
              const key = `header_${index}_${col}`;
              return <Table.HeaderCell key={key}>{col}</Table.HeaderCell>;
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {collection.map((row, rowIndex) => {
            const trKey = `row_${rowIndex}`;
            return (
              <Table.Row key={trKey}>
                {Object.keys(row).map((cell, index) => {
                  const tdKey = `td_${rowIndex}_${index}`;
                  return (
                    <Table.Cell key={tdKey} style={{ height: "41px" }}>
                      {row[cell]}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  isValidJSON = dataElement => {
    if (toLower(dataElement.type) === "collection") {
      try {
        const parser = JSON.parse(dataElement.value);
        return parser.length > 0 && true;
      } catch (e) {
        return false;
      }
    }
    return false;
  };

  renderData() {
    const dataToShow = this.fieldsData();

    if (dataToShow) {
      return (
        <>
          {dataToShow.map(dataElement => {
            const dataType = dataElement.type.toLowerCase() || "text";
            return (
              <div key={dataElement.name} className="activityData">
                <p
                  className="valueLabel"
                  dangerouslySetInnerHTML={{ __html: dataElement.label }}
                />
                {this.isValidJSON(dataElement) ? (
                  <div className="valueContainer">
                    {this.renderCollection(dataElement.value)}
                  </div>
                ) : (
                  <>
                    {dataType === "collection" && dataElement.value && (
                      <Message warning>
                        <Message.Content>
                          <i
                            className="warning sign icon"
                            style={{ marginRight: "0.5em" }}
                          />
                          {i18n.t("JSONFormatIncorrect")}
                        </Message.Content>
                      </Message>
                    )}
                    <div className="valueContainer">
                      <p style={{ color: "rgb(73, 88, 101)" }}>
                        {this.renderValue(dataElement.value)}
                      </p>
                    </div>
                  </>
                )}
              </div>
            );
          })}

          {this.renderEmail()}
        </>
      );
    }

    return <p>{i18n.t("EmptyData")}</p>;
  }

  senderLink(senderId) {
    if (senderId) {
      const { email } = this.props.users.filter(
        user => user.id === senderId
      )[0];

      return (
        <a
          className="userName"
          href={`mailto:${email}`}
          style={{ marginLeft: "5px" }}
        >
          {email}
        </a>
      );
    }

    return <span>-</span>;
  }

  renderAttachments() {
    const attachments = this.attachmentsData();

    if (attachments) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {attachments
            .filter(attachment => {
              return (
                !(
                  typeof attachment.value === "string" &&
                  attachment.value === "null"
                ) && attachment.value
              );
            })
            .map(attachment => (
              <Attachment
                attachment={attachment.value}
                key={attachment.value}
                previewUrl={this.props.previewUrl}
                setPreviewUrl={this.props.setPreviewUrl}
              />
            ))}
        </div>
      );
    }

    return undefined;
  }

  renderDate(mobile) {
    return (
      <span>
        {mobile ? this._renderDateMobile() : this._renderDate()}
        <br />
      </span>
    );
  }

  renderContent(mobile = false) {
    return (
      <div style={{ paddingTop: "1rem" }}>
        {this.props.editActivity
          ? this._renderContentEdit()
          : this._renderContentPreview(mobile)}
      </div>
    );
  }

  _renderContentPreview(mobile) {
    const mobileComments = !isDesktop()
      ? "headerAppActivitiesMobile"
      : "headerAppActivitiesMobileDesktop";
    return (
      <>
        <div className={mobileComments}>
          <div className="dateContainer">
            {this.props.activity.data &&
              Array.isArray(this.props.activity.data) &&
              this.props.activity.data.requestor_name}
            &nbsp;
            {this.renderDate(mobile)}
          </div>

          {this.renderAttachments()}
        </div>

        <div className="sixteen wide column">{this.renderData()}</div>
      </>
    );
  }

  _renderContentEdit() {
    return (
      <ActivityEditSidebar
        id={this.props.activity.id}
        data={this.props.activity.data}
        loadActivityData={this.props.loadActivityData}
        onCancel={() => this.props.setEditActivity(false)}
        activity={this.props.activity}
      />
    );
  }

  _renderDate = () => {
    return `${i18n.t("Created")} ${activityDate(
      this.props.activity.data &&
        Array.isArray(this.props.activity.data) &&
        this.props.activity.created_at
    )}`;
  };

  _renderDateMobile = () => {
    const cratedMobileDate =
      this.props.activity.data &&
      Array.isArray(this.props.activity.data) &&
      this.props.activity.created_at;
    return activityDate(cratedMobileDate);
  };

  render() {
    const activityMetaClassname =
      this.props.application?.applicationType === APPLICATION_TYPES.UNATTENDED
        ? "meta"
        : "meta activity-meta-collapsed";
    return (
      <div className={activityMetaClassname}>
        <div className="desktop">{this.renderContent()}</div>
        <div className="mobile">{this.renderContent(true)}</div>
      </div>
    );
  }
}

export default ActivityMeta;
