import React, { useEffect } from "react";
import { connect } from "react-redux";
import { DateTimeInput } from "semantic-ui-calendar-react-yz";
import moment from "moment";

const DateTimeInputField = props => {
  useEffect(() => {
    if (!props.value && props.value !== "") {
      props.setFieldValue("");
    }
  }, [props.value]);

  const dateDisplayFormat = props.user.dateformat ?? "DD-MM-YYYY";
  const dateTimeDisplayFormat = `${dateDisplayFormat} HH:mm`;
  const datetimeSentFormat = props.fromEnvVariables
    ? "YYYY-MM-DD HH:mm:SS"
    : "YYYY-MM-DDTHH:mm.SSS";

  const getFormatDateToDisplay = dateString => {
    return dateString ? dateToDisplayFromUTC(dateString) : "";
  };

  const dateToDisplayFromUTC = utcDate => {
    return moment(utcDate, datetimeSentFormat).format(dateTimeDisplayFormat);
  };

  const dateToUTCFromDisplay = displayDate => {
    if (displayDate) {
      const auxDate = moment(displayDate, dateTimeDisplayFormat).format(
        datetimeSentFormat
      );
      return `${auxDate}Z`;
    }
    return "";
  };

  return (
    <DateTimeInput
      name={props.name}
      placeholder="Date Time"
      value={getFormatDateToDisplay(props.value)}
      dateFormat={dateDisplayFormat}
      iconPosition="left"
      closable
      clearable
      animation="none"
      disabled={props.disabled}
      onChange={(event, { _, value }) => {
        props.setFieldValue(dateToUTCFromDisplay(value));
      }}
      style={{ width: "100%" }}
    />
  );
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(DateTimeInputField);
