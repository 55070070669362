import React from "react";
import i18n from "../i18n";
import CompanyService from "../../services/data_services/company";
import Toast from "../../services/toasts";

class CompaniesSuspend extends React.Component {
  noClick = () => {
    this.props.closeModal();
  };

  yesClick = () => {
    CompanyService.deleteCompany(this.props.company.id)
      .then(() => {
        Toast.show.companyDeleted();
        this.props.loadCompanies();
        this.props.closeModal();
      })
      .catch(e => {
        Toast.displayError(
          e.response.data.message,
          i18n.t("CompanyNotDeleted")
        );
        this.props.closeModal();
      });
  };

  render() {
    return (
      <div>
        <p style={{ textAlign: "center", marginBottom: "0" }}>
          {i18n.t("SuspendCompanyInfo")}
        </p>

        <div className="ui container">
          <div
            className="ui text two column grid"
            style={{ paddingBottom: "15px", paddingTop: "25px" }}
          >
            <div className="row" style={{ padding: "0.5rem 0" }}>
              <div className="eight wide column">
                <button
                  type="button"
                  className="ui button basic blue"
                  style={{ width: "100%" }}
                  onClick={() => this.noClick()}
                >
                  {i18n.t("NoButton")}
                </button>
              </div>
              <div className="eight wide column">
                <button
                  type="button"
                  className="ui button blue"
                  style={{ width: "100%" }}
                  onClick={() => this.yesClick()}
                >
                  {i18n.t("YesButton")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompaniesSuspend;
