import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { isDesktop } from "../services/general";

const Pagination = props => {
  const pageProps = props.page ? parseInt(props.page - 1, 10) : 0;
  const [currentPage, setCurrentPage] = useState(pageProps);

  useEffect(() => {
    if (currentPage !== pageProps) {
      props.onChange(currentPage + 1);
    }
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(pageProps);
  }, [props.page]);

  if (!props || props.nPages <= 1) return null;

  return (
    <div id="react-paginate">
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        breakLabel="..."
        containerClassName="ui borderless menu"
        pageClassName="ui"
        previousClassName="item"
        nextClassName="item"
        activeClassName="circular label orange active"
        pageCount={props.nPages}
        pageRangeDisplayed={isDesktop() ? 3 : 1}
        marginPagesDisplayed={1}
        forcePage={currentPage}
        onPageChange={e => setCurrentPage(e.selected)}
      />
    </div>
  );
};

export default Pagination;
