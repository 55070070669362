import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { isEqual } from "lodash";
import Select from "react-select";
import { Divider } from "semantic-ui-react";
import i18n from "../../../i18n";
import EventActivitiesTable from "../../../Activities/Events/EventActivitiesTable";
import QueueService from "../../../../services/data_services/queue";
import ServiceService from "../../../../services/data_services/service";
import { loadServices as saveServices } from "../../../../actions";

const QueuesTable = props => {
  const [loadingQueues, setLoadingQueues] = useState(false);
  const [queues, setQueues] = useState([]);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    props.setBreadcrumb([
      {
        title: i18n.t("Queues"),
        location: "/admin/queues"
      }
    ]);
    loadQueues();
    if (!props.services || props.services.length === 0) {
      loadServices();
    }
  }, []);

  const loadQueues = () => {
    setLoadingQueues(true);
    const query = {
      page: 0,
      company_id: props.user.company_id
    };

    QueueService.loadQueues(query)
      .then(result => {
        if (Array.isArray(result.data?.results)) {
          setQueues(result.data.results);
        } else {
          setQueues([]);
        }
      })
      .catch(() => {
        setQueues([]);
        addToast(i18n.t("QueuesLoadingError"), {
          appearance: "error"
        });
      })
      .finally(() => {
        setLoadingQueues(false);
      });
  };

  const loadServices = () => {
    ServiceService.loadServices()
      .then(response => {
        props.saveServices(response.data);
      })
      .catch(() => {
        props.saveServices([]);
      });
  };

  const renderActivitiesTable = () => {
    const serviceId = props.services?.find(
      service => service.name === selectedQueue.service
    )?.id;
    return <EventActivitiesTable queue={selectedQueue} serviceId={serviceId} />;
  };

  const moreThanOneService = () => {
    if (!queues || queues.length === 0) return false;
    const queueServices = queues.map(queue => queue.service);
    return queueServices.some(queue => queue !== queueServices[0]);
  };

  const queueOptions = !Array.isArray(queues)
    ? []
    : queues.map(queue => ({
        label: moreThanOneService()
          ? `${queue.name} - ${queue.service}`
          : queue.name,
        value: queue
      }));

  const queueValue =
    queueOptions.find(queue => isEqual(queue.value, selectedQueue)) || null;

  return (
    <div className="ui segment">
      <div className="queue-selector">
        <Select
          options={queueOptions}
          value={queueValue}
          placeholder={i18n.t("SelectPlaceholder")}
          isLoading={loadingQueues}
          isClearable
          onChange={selectedOption => setSelectedQueue(selectedOption?.value)}
        />
      </div>
      <Divider />
      {selectedQueue ? renderActivitiesTable() : i18n.t("SelectQueue")}
    </div>
  );
};

const mapStateToProps = state => ({
  services: state.servicesReducer.services
});

const mapDispatchToProps = dispatch => ({
  saveServices: services => dispatch(saveServices(services))
});

export default connect(mapStateToProps, mapDispatchToProps)(QueuesTable);
