import React from "react";
import { Icon, Modal, TransitionablePortal } from "semantic-ui-react";
import i18n from "../../i18n";
import { isDesktop } from "../../../services/general";

const EventModal = props => {
  const deleteItem = () => {
    props.closeModal();
    props.deleteItem(props.elements, props.loadActivities);
  };

  return (
    <TransitionablePortal
      open={props.open}
      transition={{
        animation: "scale",
        duration: 300
      }}
    >
      <Modal
        open
        size="tiny"
        closeOnEscape
        closeOnDimmerClick
        onClose={props.closeModal}
        style={{ overflow: "visible" }}
      >
        <Modal.Header>
          <div style={{ textAlign: "center" }}>
            {i18n.t(props.title)}
            <Icon
              role="button"
              name="close"
              size={!isDesktop() ? "small" : undefined}
              aria-label="Close"
              tabIndex={0}
              className="close icon"
              style={{
                float: "right",
                cursor: "pointer",
                lineHeight: "2rem"
              }}
              onClick={props.closeModal}
            />
          </div>
        </Modal.Header>
        <Modal.Content>
          <p style={{ textAlign: "center" }}>{i18n.t(props.text)}</p>

          <div className="ui modal-buttons-container">
            <button
              type="button"
              className="ui button basic blue"
              onClick={props.closeModal}
            >
              {i18n.t("NoButton")}
            </button>
            <button
              type="button"
              className="ui button blue"
              onClick={deleteItem}
            >
              {i18n.t("YesButton")}
            </button>
          </div>
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};

export default EventModal;
