import React from "react";
import { debounce, isEqual } from "lodash";
import i18n from "../../../i18n";
import Table from "../../../Ui/Table/Table";
import { TYPES } from "../../../Ui/Table/types";
import { tableActions } from "../../../Ui/Table/TableActions";
import EmailService from "../../../../services/data_services/email";
import {
  searchParamFromUrl,
  urlFromSearchParam
} from "../../../../services/url";

class EmailsTable extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = searchParamFromUrl(this.props.location.search);

    const columns = [
      { name: "Alias", show: true, key: "email_alias" },
      { name: "Email", show: true, key: "name" },
      { name: "Subject Filter", show: true, key: "subject_filters" }
    ];

    this.state = {
      emails: undefined,
      columns,
      queryParams,
      nPages: 0,
      loadingEmails: true
    };
  }

  componentDidMount() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Emails"),
        location: "/admin/emails"
      }
    ]);
    this.loadEmails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.props.history.push(`?${urlFromSearchParam(this.state.queryParams)}`);
      this.loadEmails();
    }
  }

  setQueryParams = (key, value) => {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  };

  loadEmails = debounce(() => {
    this.setState({ loadingEmails: true });
    const query = { ...this.state.queryParams };

    EmailService.loadEmails(query)
      .then(result => {
        this.setState({
          emails: result.data.results || [],
          nPages: result.data.total || 0,
          totalItems: result.data.hits
        });
      })
      .catch(() => {
        this.setState({
          emails: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingEmails: false });
      });
  }, 500);

  loadItems(entries) {
    if (!entries) {
      this.loadEmails();
    } else {
      Object.keys(entries).forEach(key => {
        this.setQueryParams(key, entries[key]);
      });
    }
  }

  reloadEmails = () => {
    this.setState(
      {
        emails: null,
        nPages: 0
      },
      this.loadEmails()
    );
  };

  render() {
    return (
      <Table
        totalItems={this.state.totalItems}
        tableId={TYPES.EMAILS}
        columns={this.state.columns}
        items={this.state.emails}
        noItemsKey="NoEmails"
        loadItems={entries => {
          this.loadItems(entries);
        }}
        loadingItems={this.state.loadingEmails}
        actions={[
          tableActions.admin_email.clone,
          tableActions.admin_email.edit,
          tableActions.admin_email.delete
        ]}
        filters={this.state.queryParams}
        user={this.props.user}
        nPages={this.state.nPages}
        page={this.state.queryParams ? this.state.queryParams.page : 1}
        createAction={() => this.props.history.push("emails/new")}
        reloadAction={this.reloadEmails}
      />
    );
  }
}

export default EmailsTable;
