import React, { useState, useEffect } from "react";
import { ErrorMessage } from "formik";
import Select from "react-select";
import TeamService from "../../../../../services/data_services/team";
import { isDesktop } from "../../../../../services/general";
import i18n from "../../../../i18n";
import InputDropdown from "../../../../Ui/InputDropdown";

const NCWFeedWork = props => {
  const [teams, setTeams] = useState(null);
  useEffect(() => {
    loadTeams();
  }, []);

  const loadTeams = () => {
    TeamService.loadTeams({ page: 0 })
      .then(response => {
        setTeams(response.data.results);
      })
      .catch(() => {
        setTeams(null);
      });
  };

  const teamOptions = () => {
    if (teams) {
      return teams.map(team => {
        return {key:team.id, text: team.name, value: team.id };
      });
    }
    return [];
  };

  const approverValues = () => {
    if (props.values.partner_team_ids?.length) {
      return teamOptions().filter(team =>
        props.values.partner_team_ids.includes(team.value)
      )?.map(x => x.value);
    }
    return [];
  };

  const nonApproverValues = () => {
    if (props.values.manager_team_ids?.length) {
      return teamOptions().filter(team =>
        props.values.manager_team_ids.includes(team.value)
      )?.map(x => x.value);
    }
    return [];
  };

  const setValue = (e, name) => {
    props.setFieldValue(name, e ? e : []);
  };

  const baseClass = isDesktop() ? "field inlineField" : "field";
  const subFormClass = isDesktop() ? "subForm" : "subFormMargin";

  return (
    <React.Fragment>
      <InputDropdown
          required
          error={props.errors?.partner_team_ids}
          inputlabel={i18n.t("ApproverTeams")}
          options={teamOptions()}
          value={approverValues()}
          placeholder={`${i18n.t("Select")}...`}
          disabled={props.preConfigured()}
          search
          multiple
          selection
          onChange={(e, { value } )=> {
            setValue(value, "partner_team_ids");
          }}
      />
      <InputDropdown
          required
          error={props.errors?.manager_team_ids}
          inputlabel={i18n.t("NonApproverTeams")}
          options={teamOptions()}
          value={nonApproverValues()}
          placeholder={`${i18n.t("Select")}...`}
          disabled={props.preConfigured()}
          search
          multiple
          selection
          onChange={(e, { value } )=> {
            setValue(value, "manager_team_ids");
          }}
      />
    </React.Fragment>
  );
};

export default NCWFeedWork;
