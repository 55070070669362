import axios from "../axios";

class EventsService {
  static searchEvents = params => {
    return axios.get(`/events`, { params });
  };

  static loadEvents = appUuid => {
    return axios.get("/events", {
      params: { application_uuids: appUuid }
    });
  };

  static loadEventHistory = (eventId, params) => {
    return axios.get(`/events/${eventId}/history`, { params });
  };

  static exportEvents = (activityIds, appUuids, params = {}) => {
    return axios.post("/events/export", {
      application_uuids: appUuids,
      activity_ids: activityIds,
      ...params
    });
  };
}

export default EventsService;
