import React from "react";
import { simpleParser } from "mailparser";
import ReactHtmlParser from "react-html-parser";
import axios from "../../services/axios";
import { emailType } from "../../constants/email-data";
import ActivitiesService from "../../services/data_services/activities";

class EmailData extends React.Component {
  constructor(props) {
    super(props);

    this.loadEmailData();
  }

  insertImage(text){
    let delimiter="Content-Transfer-Encoding: base64"
    let text2 =text.substr(text.indexOf(delimiter)+delimiter.length, text.length)
    return "data:image/png;base64,"+text2.replace(/(\r\n|\n|\r)/gm, "");
  }

  addImage(values, pos){
    let elements=values.split("--")
    elements=elements.slice(2,elements.length-2)
    return this.insertImage(elements[pos]);
  }

  DecomposeSignature(htmlElement, id, values){
    htmlElement=htmlElement.substr(0,htmlElement.indexOf("</html>")+7) 
    let positionStart =htmlElement.indexOf("<img border=\"0\" ")
    let positionEnd =htmlElement.indexOf("\">",positionStart+1)
    let resultString=htmlElement.substr(0,positionStart) +"<img src="+ this.addImage(values, id)+" />" +htmlElement.substr(positionEnd+2,htmlElement.length)
    if(resultString.indexOf("<img border=\"0\"") !==-1){
      resultString=this.DecomposeSignature(resultString,id+1,values)
    }
    return resultString;
  }

  loadEmailData = () => {
    ActivitiesService.loadActivity(this.props.match.params.id).then(
      activityResponse => {
        const activity = activityResponse.data;

        const emails =
          activity.data && Array.isArray(activity.data)
            ? activity.data.filter(
                dataElement => dataElement.type === emailType
              )
            : [];

        const email = emails.length > 0 ? emails[0] : undefined;
        if (email) {
          simpleParser(email.value).then(mailObject => {
            //const value = mailObject.html ? this.DecomposeSignature(mailObject.html, 0, email.value) : email.value;
            const value = mailObject.html ? mailObject.html : email.value;
            let formatedText = value;
            if(!email.value.startsWith("<html")){
              formatedText = escape(value)
              formatedText = formatedText.replaceAll('%0D%0A','<br/>');
              formatedText = unescape(formatedText);
            }
            document.documentElement.innerHTML = unescape(formatedText);
          });

        }
      }
    );
  };

  render() {
    return <></>;
  }
}

export default EmailData;
