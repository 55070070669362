import React from "react";
import i18n from "../../../i18n";

const ClientInfo = props => {
  const renderElement = (label, value) => {
    return (
      <React.Fragment>
        <th>{label}</th>
        <td>{value}</td>
      </React.Fragment>
    );
  };
  return (
    <div className="detail-table-container">
      <table className="detail-table">
        <thead>
          <tr>
            <th colSpan={4}>
              <h4>{i18n.t("BasicInformation")}</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {renderElement(i18n.t("ClientName"), props?.client?.client_name)}
            {renderElement(i18n.t("LegalName"), props?.client?.legal_name)}
          </tr>
          <tr>
            {renderElement(i18n.t("Industry"), props?.client?.industry)}
            {renderElement(i18n.t("Niche"), props?.client?.niche)}
          </tr>
          <tr>
            {renderElement(
              i18n.t("NewClient"),
              props?.client?.mac_id ? "No" : "Yes"
            )}
            {renderElement("", "")}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ClientInfo;
