import React from "react";
import { Menu, Sidebar } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import CompactLogo from "images/logo_compact.png";
import ExpandedLogo from "images/logo-sidebar.png";
import { generateUuidFrom } from "../../services/uuid";
import NavigationBar from "../NavigationBar/NavigationBar";
import IndividualApplications from "./IndividualApplications";
import SidebarItem from "./SidebarItem";
import { VisitableComponents } from "../../services/detailedPermissionRules";
import i18n from "../i18n";
import ApplicationService from "../../services/data_services/application";
import ApplicationRoutes from "../Router/ApplicationRoutes";
import { isDesktop } from "../../services/general";
import UserSection from "./UserSection";

import "../../../assets/stylesheets/Sidebar.scss";

const SidebarWidth = Object.freeze({
  Thin: "thin",
  VeryThin: "very thin"
});

class PushableSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: SidebarWidth.Thin,
      loadingApplications: true
    };

    this.sidebarUuid = this.generateUuidFrom("APPLICATION_SIDEBAR");
  }

  componentDidMount() {
    ApplicationService.loadIndividualItemApplications().then(result => {
      this.props.loadIndividualApplications(result.data);
      this.setState({ loadingApplications: false });
    });
    ApplicationService.loadLaunchApplications({ page: 0 }).then(result => {
      this.props.loadLaunchApplications(result.data.results);
    });
  }

  generateUuidFrom = value => {
    return generateUuidFrom(value);
  };

  collapse = () => {
    this.setState(prevState => ({
      width:
        prevState.width === SidebarWidth.Thin
          ? SidebarWidth.VeryThin
          : SidebarWidth.Thin
    }));
  };

  collapseIconDirection = () => {
    return this.state.width === "thin" ? "left" : "right";
  };

  withSidebar = () => {
    return this.state.width.concat(isDesktop() ? " withSidebar" : "");
  };

  isPushable = () => {
    return !isDesktop() ? "pushable" : "";
  };

  applications = () => {
    return this.props.individualApplications
      ? this.props.individualApplications.filter(
          application => application.active
        )
      : [];
  };

  schedulerVisible = () => {
    if (this.props?.user?.company?.access_to?.scheduling === "true")
      return this.props.user.teams.some(team => {
        return team.monitor || team.scheduling;
      });
  };

  rpaManagementVisible = () => {
    return (
      this.props.user.teams.some(team => team.rpa_management) &&
      this.props?.user?.company?.access_to?.rpa_management === "true"
    );
  };

  rpaManagementVisible = () => {
    return this.props.user.teams.some(team => team.rpa_management);
  };

  isDimmed = () => !isDesktop() && this.props.sideBar;

  onDimmedClick = () => {
    if (this.props.sideBar) {
      this.props.toggleSideBar(false);
    }
  };

  displayDashboard = _ => {
    return (
      !this.props.user.roles.includes("super_admin") &&
      this.props?.user?.company?.access_to?.dashboard === "true"
    );
  };

  displayLauncher = _ => {
    return (
      this.props.launchApplications?.length !== 0 &&
      this.props?.user?.company?.access_to?.launcher === "true"
    );
  };

  displayCaseView = _ => {
    return (
      !this.state.loadingApplications &&
      this.props?.user?.company?.access_to?.case_view === "true"
    );
  };

  getImage = type => {
    if (type === "compact") {
      if (
        this.props.user?.company?.logo_compact &&
        !this.props.user.roles.includes("super_admin")
      ) {
        return this.props.user.company.logo_compact;
      }
      return CompactLogo;
    }

    if (
      this.props.user?.company?.logo_sidebar &&
      !this.props.user.roles.includes("super_admin")
    ) {
      return this.props.user.company.logo_sidebar;
    }
    return ExpandedLogo;
  };

  render() {
    return (
      <div
        className={`ui full height pusher fixed ${this.isPushable()}`}
        style={{ backgroundColor: "transparent", minHeight: "100vh" }}
      >
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          onHide={e => {
            if (
              e &&
              e.target.className instanceof Array &&
              e.target.className.includes("dimmed")
            ) {
              this.props.toggleSideBar(false);
            }
          }}
          vertical
          visible={isDesktop() || this.props.sideBar}
          width={this.state.width}
        >
          <div className="item-sb logo">
            <Link
              to="/"
              onClick={() =>
                this.props.toggleSideBar
                  ? this.props.toggleSideBar(false)
                  : null
              }
            >
              <img
                src={this.getImage()}
                alt="Invoke Logo"
                style={{
                  maxWidth: "150px",
                  display: this.state.width === SidebarWidth.Thin ? "" : "none"
                }}
              />
              <img
                src={this.getImage("compact")}
                alt="Invoke Logo"
                style={{
                  maxWidth: "35px",
                  display: this.state.width === SidebarWidth.Thin ? "none" : ""
                }}
              />
            </Link>
            {isDesktop() && (
              <div
                style={{
                  paddingLeft: this.state.width === "thin" ? "2px" : "4px"
                }}
                className="collapse-menu-button"
                onClick={() => this.collapse()}
              >
                <i
                  className={`icon caret ${this.collapseIconDirection()} pusher`}
                />
              </div>
            )}
          </div>

          <div className="sidebarItemsContainer">
            {this.displayDashboard() && (
              <SidebarItem
                dashboardIcon
                width={this.state.width}
                componentName={i18n.t("Dashboard")}
                url="/"
                location={this.props.location}
                icon="th large"
                toggleSideBar={this.props.toggleSideBar}
              />
            )}
            {!this.state.loadingApplications && (
              <IndividualApplications
                user={this.props.user}
                sidebarUuid={this.sidebarUuid}
                applications={this.applications()}
                width={this.state.width}
              />
            )}
            <SidebarItem
              width={this.state.width}
              component={VisitableComponents.Companies}
              componentName={i18n.t("Companies")}
              url="/companies"
              location={this.props.location}
              icon="building outline"
              toggleSideBar={this.props.toggleSideBar}
            />
            <SidebarItem
              width={this.state.width}
              component={VisitableComponents.Services}
              componentName={i18n.t("Services")}
              url="/services"
              location={this.props.location}
              icon="cog"
              toggleSideBar={this.props.toggleSideBar}
            />
            {this.displayLauncher() && (
              <SidebarItem
                width={this.state.width}
                component={VisitableComponents.LaunchProcess}
                componentName={i18n.t("Launcher")}
                url="/launch"
                location={this.props.location}
                icon="rocket"
                toggleSideBar={this.props.toggleSideBar}
              />
            )}
            {this.schedulerVisible() && (
              <SidebarItem
                width={this.state.width}
                component={VisitableComponents.Scheduler}
                componentName={i18n.t("Scheduler")}
                url="/scheduler_monitor"
                altUrl="/schedulers"
                location={this.props.location}
                icon="clock outline"
                toggleSideBar={this.props.toggleSideBar}
              />
            )}
            {this.rpaManagementVisible() && (
              <SidebarItem
                width={this.state.width}
                component={VisitableComponents.RpaAssets}
                componentName={i18n.t("RPA Management")}
                url="/resources"
                altUrl={[
                  "/env_variables",
                  "/queues",
                  "/alerts",
                  "/credentials"
                ]}
                location={this.props.location}
                icon="robot"
                toggleSideBar={this.props.toggleSideBar}
              />
            )}
            <SidebarItem
              width={this.state.width}
              component={VisitableComponents.Admin}
              componentName={i18n.t("Settings")}
              url="/admin"
              location={this.props.location}
              icon="cog"
              toggleSideBar={this.props.toggleSideBar}
            />
          </div>

          {isDesktop() && (
            <UserSection
              width={this.state.width}
              onLogout={this.props.logOut}
            />
          )}
        </Sidebar>

        <Sidebar.Pusher
          dimmed={this.isDimmed()}
          className={this.withSidebar()}
          id="appContainer"
          onClick={() => this.onDimmedClick()}
        >
          {!isDesktop() && <NavigationBar {...this.props} />}
          <ApplicationRoutes {...this.props} />
        </Sidebar.Pusher>
      </div>
    );
  }
}

export default withRouter(PushableSidebar);
