import React from "react";
import i18n from "../../i18n";
import {Field} from "formik";

const InlineField = props => {
    return(
        <div className={`field inlineField ${props.required ? 'required' : ''}`}>
            <label>{i18n.t(props.label)}</label>
            <Field type="text" name={props.fieldName} autoComplete="off"/>
        </div>
    );
}

export default InlineField;