import axios from "../axios";

class WidgetService {
  static loadProcedures = service_id => {
    return axios.get(`/widgets/procedures`, {
      params: {
        service_id
      }
    });
  };

  static loadParameters = (service_id, procedure) => {
    return axios.get(`/widgets/parameters`, {
      params: {
        service_id,
        procedure
      }
    });
  };

  static loadPreview = (procedure, service_id, chart_type, data) => {
    return axios.get(`/widgets/preview`, {
      params: {
        procedure,
        service_id,
        chart_type,
        data
      }
    });
  };

  /**
   * Creates widget
   * @param widget Widget object. IG:
   * {
   *   name: 'WorkProcessedByHour',
   *   description: 'Workprocessedbyhour',
   *   data: [{"parameter_name":"parameter1","parameter_value":"value1"],
   *   options: {
   *     title: "Widgettitle",
   *     xAxis:'Month',
   *     yAxis: 'Robots'
   *   },
   *   section_id: 22,
   *   Size: 'XL',
   *   chart_type:"bar",
   *   procedure: 'Aria_DS_ItemsByHourByDay',
   *   service_id: 111,
   *   application_id: 3
   * }
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  static create = widget => {
    return axios.post(`/widgets`, widget);
  };

  /**
   * Save a widget
   * @param widget Widget object. IG:
   * {
   *   id: 24,
   *   name: 'WorkProcessedByHour',
   *   description: 'Workprocessedbyhour',
   *   data: [{"parameter_name":"parameter1","parameter_value":"value1"],
   *   options: {
   *     title: "Widgettitle",
   *     xAxis:'Month',
   *     yAxis: 'Robots'
   *   },
   *   section_id: 22,
   *   Size: 'XL',
   *   chart_type:"bar",
   *   procedure: 'Aria_DS_ItemsByHourByDay',
   *   service_id: 111,
   *   application_id: 3
   * }
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  static save = widget => {
    return axios.put(`/widgets/${widget.id}`, widget);
  };

  /**
   * Loads a widget
   * @param id Widget ID
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  static load = id => {
    return axios.get(`/widgets/${id}`);
  };

  /**
   * Delete a widget
   * @param id Widget ID
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  static delete = id => {
    return axios.delete(`/widgets/${id}`);
  }
}

export default WidgetService;
