import React, { Component } from "react";
import { isEqual } from "lodash";
import i18n from "../../i18n";
import Table from "../../Ui/Table/Table";
import { TYPES } from "../../Ui/Table/types";
import { activityDate } from "../../../services/string";
import EventsService from "../../../services/data_services/events";

export const eventHistoryColumns = [
  {
    name: i18n.t("Process"),
    show: true,
    key: "process",
    order: ""
  },
  {
    name: i18n.t("Resource"),
    show: true,
    key: "resource",
    order: ""
  },
  {
    name: i18n.t("Step"),
    show: true,
    key: "step",
    order: ""
  },
  {
    name: i18n.t("StartDate"),
    show: true,
    key: "start_date",
    order: "",
    method: activityDate,
    type: "datetime"
  },
  {
    name: i18n.t("EndDate"),
    show: true,
    key: "end_date",
    order: "",
    method: activityDate,
    type: "datetime"
  },
  {
    name: i18n.t("Details"),
    show: true,
    key: "details",
    order: ""
  }
];

class EventHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      history: null,
      nPages: 0,
      queryParams: { page: 1 },
      loadingEventHistory: true,
      totalItems: null
    };
  }

  componentDidMount() {
    this.loadHistory();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevState.queryParams, this.state.queryParams) ||
      prevProps.eventId !== this.props.eventId
    ) {
      this.loadHistory();
    }
  }

  setQueryParams = (key, value) => {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  };

  loadHistory = () => {
    this.setState({ loadingEventHistory: true });
    EventsService.loadEventHistory(this.props.eventId, this.state.queryParams)
      .then(response => {
        this.setState({
          history: response.data.results,
          nPages: response.data.total,
          totalItems: response.data.hits
        });
      })
      .catch(() => {
        this.setState({
          history: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingEventHistory: false });
      });
  };

  reloadHistory = () => {
    this.setState(
      {
        history: null,
        nPages: 0
      },
      () => {
        this.loadHistory();
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <h4 className="ui horizontal divider header">{i18n.t("History")}</h4>
        <Table
          tableId={TYPES.HISTORY}
          columns={eventHistoryColumns}
          items={this.state.history}
          noItemsKey={i18n.t("NoHistoryYet")}
          loadItems={entries => {
            Object.keys(entries).forEach(key => {
              this.setQueryParams(key, entries[key]);
            });
          }}
          loadingItems={this.state.loadingEventHistory}
          filters={this.state.queryParams}
          nPages={this.state.nPages}
          page={this.state.queryParams.page ? this.state.queryParams.page : 1}
          reloadAction={this.reloadHistory}
          totalItems={this.state.totalItems}
        />
      </React.Fragment>
    );
  }
}

export default EventHistory;
