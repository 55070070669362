import axios from "axios";
import Cookies from "js-cookie";
import store from "./store";
import { logOut } from "../actions/index";

const instance = axios.create({
  headers: { Accept: "application/json" },
  baseURL: "/api"
});

instance.interceptors.request.use(config => {
  const jwt = Cookies.get("JWT");
  const csrfToken = document.querySelector("[name=csrf-token]").content;

  const configToReturn = config;
  configToReturn.headers.authorization = jwt ? `Bearer ${jwt}` : "";
  configToReturn.headers.common["X-CSRF-TOKEN"] = csrfToken;

  return configToReturn;
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      store.dispatch(logOut());
    } /*else if (error.response && !error.response.data.message && error.response.status === 403) {
      if (window.location.pathname !== "/403") {
        window.location.href = "/403";
      }
    }*/
    return Promise.reject(error);
  }
);

export const checkAndRedirect = error => {
  if (
    error.response.status === 500 ||
    error.response.status === 400 ||
    error.response.status === 404
  ) {
    window.location.href = "/403";
  }
};

export default instance;
