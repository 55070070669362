import React from "react";
import i18n from "../i18n";

import { ModalType } from "./CompaniesModal";
import { initDropDown } from "../../semantic-ui";
import {Button , Icon} from "semantic-ui-react"
import Permissions, { check } from "../../services/permissions";
import {
  VisitableComponents,
  Actions
} from "../../services/detailedPermissionRules";

class CompanyRow extends React.Component {
  componentDidMount() {
    initDropDown();
  }

  tableRow = (label, data) => {
    return (
      <td
        data-label={`${label}`}
        style={{ height: "50px" }}
        onClick={() => { this.props.showModal(ModalType.Show, this.props.company); }}
        className="handcursor"
      >
        {data}
      </td>
    );
  };

  render() {
    const { company } = this.props;
    return (
      <tr>
        {this.tableRow(i18n.t("Company Name"), company.name)}
        {this.tableRow(i18n.t("Description"), company.description)}
        {this.tableRow(i18n.t("E-mail"), company.admin_email)}
        <td>
        <Permissions
          component={VisitableComponents.Companies}
          action={Actions.update}
          yes={() => {
            return (
              <Button color="blue" basic floated='right' icon ="trash alternate" onClick={() => {
                    this.props.showModal(ModalType.Suspend, this.props.company);
                  }}/> 
                  );}}
          no={() => {return null;}}
        />

        <Permissions
          component={VisitableComponents.Companies}
          action={Actions.update}
          yes={() => {
            return (
              <Button color="blue" basic floated='right'onClick={() => {
                    this.props.showModal(ModalType.Edit, this.props.company);
                  }}> Edit 
              </Button>
              );}}
          no={() => {return null;}}
        />
        </td>
      </tr>
    );
  }
}

export default CompanyRow;
