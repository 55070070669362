/* eslint-disable react/sort-comp */
import React from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { schedulerColumns } from "../../Activities/Events/EventTable";
import EventHistory from "../../Activities/Events/EventHistory";
import Table from "../../Ui/Table/Table";
import { TYPES } from "../../Ui/Table/types";
import EventsService from "../../../services/data_services/events";
import { searchParamFromUrl, urlFromSearchParam } from "../../../services/url";
import i18n from "../../i18n";

const BACKEND_DATE_FORMAT = "DD-MM-YYYY HH:mm";

class SchedulerMonitor extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = searchParamFromUrl(this.props.location.search);
    const auxQueryParams = this.setDefaultDate(queryParams);

    this.state = {
      elements: undefined,
      loadingEvents: true,
      nPages: 0,
      queryParams: auxQueryParams,
      columns: schedulerColumns,
      eventId: undefined
    };

    this.setBreadcrumb();
    this.setEventId = this.setEventId.bind(this);
    this.setQueryParams = this.setQueryParams.bind(this);
    this.historyTableRef = React.createRef();
    this.eventsTableRef = React.createRef();
  }

  componentDidMount() {
    this.loadEvents();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.props.history.push(`?${urlFromSearchParam(this.state.queryParams)}`);
      this.loadEvents();
    }

    if (
      this.state.eventId &&
      prevState.queryParams.page !== this.state.queryParams.page
    ) {
      this.hideEventHistory();
    }

    if (this.state.eventId && this.state.eventId !== prevState.eventId) {
      this.historyTableRef.scrollIntoView({ behavior: "smooth" });
    }

    if (prevState.eventId && !this.state.eventId) {
      this.eventsTableRef.scrollIntoView({ behavior: "smooth" });
    }
  }

  componentWillUnmount() {
    this.setState({});
  }

  setDefaultDate = queryParams => {
    const { date_from, date_to } = queryParams;
    if (date_from || date_to) return queryParams;

    const dateFrom = moment
      .tz(this.props.user.timezone)
      .startOf("day")
      .utc()
      .format(BACKEND_DATE_FORMAT);
    const dateTo = moment
      .tz(this.props.user.timezone)
      .endOf("day")
      .utc()
      .format(BACKEND_DATE_FORMAT);

    const auxQueryParams = {
      ...queryParams,
      date_from: dateFrom,
      date_to: dateTo
    };
    return auxQueryParams;
  };

  setBreadcrumb = () => {
    this.props.setBreadcrumb([
      {
        title: i18n.t("SchedulerMonitor"),
        location: "/scheduler_monitor"
      }
    ]);
  };

  setQueryParams(key, value, firstPage = false) {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      if (firstPage) {
        queryParams.page = 1;
      }

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  }

  setEventId = element => {
    const eventId = element.id;
    this.setState(prevState => {
      const newState = prevState.eventId === eventId ? undefined : eventId;
      return {
        eventId: newState
      };
    });
  };

  reloadElements = () => {
    this.setState(
      {
        elements: undefined
      },
      this.loadEvents
    );
  };

  loadEvents = () => {
    this.setState({ loadingEvents: true });
    const query = { ...this.state.queryParams };

    EventsService.searchEvents(query)
      .then(response => {
        const { data } = response;

        this.setState({
          elements: data.results,
          nPages: data.total,
          totalItems: data.hits
        });
      })
      .catch(() => {
        this.setState({
          elements: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingEvents: false });
      });
  };

  _renderEventHistory() {
    if (!this.state.eventId) {
      return undefined;
    }

    return (
      <div
        className="ui segment noShadow"
        ref={el => {
          this.historyTableRef = el;
        }}
      >
        <EventHistory eventId={this.state.eventId} />
      </div>
    );
  }

  hideEventHistory = () => {
    this.setState({ eventId: undefined });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="ui segment noShadow"
          ref={el => {
            this.eventsTableRef = el;
          }}
        >
          <Table
            totalItems={this.state.totalItems}
            tableId={TYPES.SCHEDULER_MONITOR}
            noItemsKey="NoElements"
            items={this.state.elements}
            columns={this.state.columns}
            filters={this.state.queryParams}
            nPages={this.state.nPages}
            page={this.state.queryParams ? this.state.queryParams.page : 1}
            loadItems={entries => {
              Object.keys(entries).forEach(key =>
                this.setQueryParams(key, entries[key])
              );
            }}
            loadingItems={this.state.loadingEvents}
            mobileColumns={{
              name: "process_name",
              timestamp: "scheduled_at"
            }}
            onRowClick={this.setEventId}
            selectedItem={{
              id: this.state.eventId,
              identifier: "id"
            }}
            reloadAction={this.reloadElements}
          />
        </div>
        {this._renderEventHistory()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.currentUserReducer.user
});

export default connect(mapStateToProps)(SchedulerMonitor);
