export const searchParamFromUrl = locationSearch => {
  const searchParams = new URLSearchParams(locationSearch);

  const queryParams = {};

  for (const pair of searchParams.entries()) {
    if (pair[0] === "page") {
      queryParams[pair[0]] = parseInt(pair[1], 10);
    } else {
      queryParams[pair[0]] = pair[1].includes(",")
        ? pair[1].split(",")
        : pair[1];
    }
  }

  if (!Object.prototype.hasOwnProperty.call(queryParams, "page")) {
    queryParams.page = 1;
  }

  return queryParams;
};

export const urlFromSearchParam = queryParams => {
  const searchParams = new URLSearchParams();
  Object.keys(queryParams).forEach(key => {
    if (queryParams[key] && typeof queryParams[key] !== "object") {
      searchParams.append(key, queryParams[key]);
    }
  });

  return searchParams.toString();
};

export const tabNameBasedOnPathName = pathname => {
  return pathname.replace("/", "");
};

export const tabActive = (pathname, tabName) => {
  return pathname.includes(tabName) ? "active" : "";
};
