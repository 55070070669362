import React from "react";
import { Formik } from "formik";
import { TextArea } from "semantic-ui-react";
import { mailparser, simpleParser } from "mailparser";
import { ReactFormGenerator } from "react-ui-form-builder-attachment";
import CKEditor from "@ckeditor/ckeditor5-react";
import "@ckeditor/ckeditor5-build-classic";
import { toLower } from "lodash";
import i18n from "../i18n";
import SubmitButton from "../SubmitButton";
import { fileName } from "../../services/string";
import ActivitiesService from "../../services/data_services/activities";
import Toast from "../../services/toasts";
import FormsService from "../../services/data_services/forms";
import "stylesheets/ck-editor.scss";
import TableItemsEdit from "./TableItemsEdit";

class ActivityEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: undefined
    };

    this.loadForm();
  }

  loadForm() {
    if (this.props.activity.form_id) {
      FormsService.loadForm(this.props.activity.form_id).then(result => {
        this.setState({
          form: result.data
        });
      });
    }
  }

  data = () => {
    if (!this.props.data || !Array.isArray(this.props.data)) {
      return [];
    }
    return this.props.data;
  };

  formGeneratorData = () => {
    const formData = this.data().map(field => {
      const config = this.formConfiguration().find(
        formConfig => formConfig.id === field.name
      );

      const fieldToReturn = { ...field };
      if (config) {
        fieldToReturn.name = config.field_name;
      }

      if (fieldToReturn.type === "Tags") {
        const values = Array.isArray(fieldToReturn.value)
          ? fieldToReturn.value
          : [];

        fieldToReturn.value = values.map(fieldValue => {
          return { value: fieldValue };
        });
      }

      return fieldToReturn;
    });

    return formData;
  };

  isEmail = () => {
    return this.data().some(dataElement => dataElement.type === "Email");
  };

  formConfiguration = () => {
    return this.state.form && this.state.form.configuration
      ? this.state.form.configuration
      : [];
  };

  fieldsData = () => {
    return this.data().filter(dataElement => {
      return !(
        dataElement.type === "Link" ||
        dataElement.type === "Image" ||
        dataElement.type === "Email"
      );
    });
  };

  emailsData = _ => {
    return this.data().filter(dataElement => {
      return dataElement.type === "Email";
    });
  };

  attachmentsData = () => {
    return this.data().filter(dataElement => {
      return dataElement.type === "Link" || dataElement.type === "Image";
    });
  };

  initialValues = () => {
    const values = {};
    this.data().forEach(dataElement => {
      values[dataElement.name] = dataElement.value;
    });
    return values;
  };

  initialValuesData = () => {
    return { data: this.initialValues() };
  };

  onSubmit = (values, setSubmitting) => {
    const formData = new FormData();

    if (this.state.form) {
      Object.keys(values).forEach(key => {
        const { name } = values[key];
        const { value } = values[key];

        if (Array.isArray(value)) {
          value.forEach(dataValue => formData.append(`${name}[]`, dataValue));
        } else {
          formData.append(name, value);
        }
      });
    } else {
      const fieldsData = this.fieldsData();

      Object.keys(values).forEach(key => {
        /* if (!fieldsData.some(data => data.name === key && this.isEmail())) {
            formData.append(key, values[key]);
        } */
        formData.append(key, values[key]);
      });
    }

    ActivitiesService.updateActivityData(this.props.id, formData)
      .then(() => {
        setSubmitting(false);
        Toast.show.activitiesUpdated();
        this.props.onCancel && this.props.onCancel();
        this.props.loadActivityData();
      })
      .catch(() => {
        setSubmitting(false);
        Toast.show.activitiesNotUpdated();
      });
  };

  renderLabel = label => {
    return <label dangerouslySetInnerHTML={{ __html: label }} />;
  };

  collectionTable = (dataElement, setFieldValue, values) => {
    return (
      <TableItemsEdit
        dataElement={dataElement}
        setFieldValue={setFieldValue}
        values={values}
      />
    );
  };

  isValidJSON = dataElement => {
    if (toLower(dataElement.type) === "collection") {
      try {
        const parser = JSON.parse(dataElement.value);
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  };

  renderField = (dataElement, handleChange, setFieldValue, values) => {
    const disabled = this.isEmail();

    if (this.isValidJSON(dataElement)) {
      return (
        <TableItemsEdit
          dataElement={dataElement}
          setFieldValue={setFieldValue}
          values={values}
        />
      );
    }

    if (dataElement.type === "Email") {
      return (
        <div className="field" key={dataElement.name}>
          {this.renderLabel(dataElement.label)}
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "blockQuote",
                "link",
                "numberedList",
                "bulletedList",
                "insertTable",
                "tableColumn",
                "tableRow",
                "mergeTableCells",
                "|",
                "undo",
                "redo"
              ],
              fullPage: true,
              allowedContent: true
            }}
            editor={ClassicEditor}
            onInit={editor => {
              simpleParser(values[dataElement.name]).then(content => {
                const result = content.html
                  ? content.html
                  : values[dataElement.name];
                const viewFragment = editor.data.processor.toView(result);
                const modelFragment = editor.data.toModel(viewFragment);
                editor.model.insertContent(modelFragment);
              });
            }}
            onChange={(_, editor) => {
              const currentValue = values[dataElement.name];
              const pattern = /<body.*<\/body>/s;
              const newBody = `<body>${editor.getData()}</body>`;
              const newEmail = currentValue.replace(pattern, newBody);

              setFieldValue(dataElement.name, newEmail);
            }}
          />
        </div>
      );
    }

    const dataType = dataElement.type.toLowerCase() || "text";
    return (
      <div className="field" key={dataElement.name}>
        {this.renderLabel(dataElement.label)}
        {dataType === "collection" ? (
          <TextArea
            disabled={disabled}
            name={dataElement.name}
            onChange={handleChange}
            value={values[dataElement.name]}
          />
        ) : (
          <input
            disabled={disabled}
            name={dataElement.name}
            onChange={handleChange}
            value={values[dataElement.name]}
          />
        )}
      </div>
    );
  };

  renderAttachment = (dataElement, setFieldValue, values, index) => {
    return (
      <div
        key={dataElement.name}
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <div className="field" style={{ width: "100%" }}>
          {this.renderLabel(dataElement.label)}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {(typeof values[dataElement.name] === "string" &&
              values[dataElement.name] === "null") ||
            !values[dataElement.name]
              ? this.renderEmptyBodyAttachment(index)
              : this.renderBodyAttachment(dataElement, setFieldValue, values)}

            <input
              name={dataElement.name}
              hidden
              type="file"
              id={`attachment-${index}`}
              onChange={event => {
                setFieldValue(dataElement.name, event.currentTarget.files[0]);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderBodyAttachment = (dataElement, setFieldValue, values) => {
    return (
      <div>
        <p>{fileName(values[dataElement.name])}</p>
        <p
          className="handcursor"
          onClick={() => {
            setFieldValue(dataElement.name, null);
          }}
        >
          <i className="icon close" /> Clear File
        </p>
      </div>
    );
  };

  renderEmptyBodyAttachment = index => {
    return (
      <div>
        <div>
          <p
            className="handcursor"
            onClick={() => {
              document.getElementById(`attachment-${index}`).click();
            }}
          >
            <i className="icon file" /> Upload File
          </p>
          <p>Select an file from your computer or device</p>
        </div>
      </div>
    );
  };

  renderExistForm() {
    const formValues = this.initialValuesData();
    const formConfiguration = this.formConfiguration();
    const answerData = this.formGeneratorData();

    if (answerData === [] || formConfiguration === []) {
      return null;
    }

    return (
      <Formik
        initialValues={formValues}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) =>
          this.onSubmit(values.data, setSubmitting)
        }
      >
        {({ handleSubmit, setFieldValue, isSubmitting }) => (
          <ReactFormGenerator
            data={formConfiguration}
            answer_data={answerData}
            submit_name={i18n.t("SaveButton")}
            onSubmit={handleSubmit}
            back_name={i18n.t("CancelButton")}
            back_action={() => {
              this.props.onCancel && this.props.onCancel();
            }}
            isSubmitting={isSubmitting}
            onChange={inputs => {
              setFieldValue("data", inputs);
            }}
          />
        )}
      </Formik>
    );
  }

  renderForm() {
    return (
      <Formik
        initialValues={this.initialValues()}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) =>
          this.onSubmit(values, setSubmitting)
        }
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          handleChange
        }) => (
          <form
            className="ui form"
            style={{ marginBottom: "1rem" }}
            onSubmit={handleSubmit}
          >
            <div className="">
              {this.fieldsData().map(dataElement => {
                return this.renderField(
                  dataElement,
                  handleChange,
                  setFieldValue,
                  values
                );
              })}

              {this.emailsData().map(emailData => {
                return this.renderField(
                  emailData,
                  handleChange,
                  setFieldValue,
                  values
                );
              })}

              {this.attachmentsData().map((attachmentElement, index) => {
                return this.renderAttachment(
                  attachmentElement,
                  setFieldValue,
                  values,
                  index
                );
              })}
            </div>
            <div className="actionButtons">
              <button
                type="button"
                className="ui button basic blue"
                onClick={() => {
                  this.props.onCancel && this.props.onCancel();
                }}
              >
                {i18n.t("Cancel")}
              </button>
              <SubmitButton label="SaveButton" isSubmitting={isSubmitting} />
            </div>
          </form>
        )}
      </Formik>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.form ? this.renderExistForm() : this.renderForm()}
      </React.Fragment>
    );
  }
}

export default ActivityEdit;
