export const extension = url => {
  if (!url) {
    return "";
  }

  if (url.includes("temp_file")) {
    return "png";
  }

  return `${url.substring(url.lastIndexOf(".") + 1, url.lastIndexOf("?"))}`;
};

export const previewSupport = url => {
  const ext = extension(url);

  return ["png", "jpeg", "gif", "bmp", "pdf", "jpg"].includes(ext);
};
