import React from "react";
import { Checkbox } from "semantic-ui-react";
import { cloneDeep } from "lodash";
import Modal from "../../Modal";
import i18n from "../../../i18n";

const MobileColumnFilter = props => {
  const columnsToFilter = props.columns.filter(column => {
    return !Object.values(props.mobileColumns).includes(column.key);
  });

  const handleCheckboxChange = (checked, key) => {
    const columnsCopy = cloneDeep(props.columns);
    const columnIndex = columnsCopy.findIndex(col => col.key === key);
    columnsCopy[columnIndex].show = checked;
    props.setColumns(columnsCopy);
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.toggleColumnFilter(false)}
      headerContent={i18n.t("ManageColumns")}
    >
      <form className="column-filter-container">
        {columnsToFilter.map(column => (
          <Checkbox
            key={column.key}
            label={column.name}
            defaultChecked={column.show}
            className="column-filter-item"
            onChange={(_, { checked }) => {
              handleCheckboxChange(checked, column.key);
            }}
          />
        ))}
      </form>
    </Modal>
  );
};

export default MobileColumnFilter;
