import React from "react";
import { isEqual, debounce } from "lodash";
import ResourceService from "../../../../services/data_services/resources";
import i18n from "../../../i18n";
import Table from "../../../Ui/Table/Table";
import { TYPES } from "../../../Ui/Table/types";
import {
  searchParamFromUrl,
  urlFromSearchParam
} from "../../../../services/url";
import { tableActions } from "../../../Ui/Table/TableActions";
import {
  RESOURCESTATUS,
  formatResourcesStatus
} from "../../../../constants/status";
import { capitalizeFirst } from "../../../../services/string";

class Resources extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = searchParamFromUrl(this.props.location.search);

    this.state = {
      loadingResources: true,
      resources: undefined,
      queryParams,
      columns: [
        { name: "Resource name", show: true, key: "resource_name" },
        { name: "Group/Pool", show: true, key: "pool_name" },
        {
          name: "Status",
          show: true,
          key: "DisplayStatus",
          method: formatResourcesStatus,
          type: "select",
          single: true,
          options: RESOURCESTATUS.map(status => ({
            label: capitalizeFirst(status.value),
            value: status.value
          }))
        },
        { name: "Session", show: true, key: "process_name" },
        { name: "Environment", show: true, key: "service" }
      ],
      nPages: 0
    };
  }

  componentDidMount() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Resources"),
        location: "/admin/resources"
      }
    ]);

    this.loadResources();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.props.history.push(`?${urlFromSearchParam(this.state.queryParams)}`);
      this.loadResources();
    }
  }

  setQueryParams(key, value, search = false) {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      if (search) {
        queryParams.page = 1;
      }

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  }

  loadResources = debounce(() => {
    this.setState({ loadingResources: true });
    const query = {
      ...this.state.queryParams,
      company_id: this.props.user.company_id
    };

    ResourceService.loadResources(query)
      .then(result => {
        this.setState({
          resources: result.data.results,
          nPages: result.data.total,
          totalItems: result.data.hits
        });
      })
      .catch(() => {
        this.setState({
          resources: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingResources: false });
      });
  }, 500);

  reloadResources = () => {
    this.setState({ resources: null }, this.loadResources());
  };

  addAction = _ => {
    switch (window.location.hostname) {
      case "localhost":
      case "previewaria.invokeinc.com":
      case "ariadev.invokeinc.com":
        return true;
      default:
        return false;
    }
  };

  render() {
    return (
      <>
        <Table
          tableId={TYPES.RESOURCES}
          columns={this.state.columns}
          items={this.state.resources}
          noItemsKey="NoResources"
          actions={this.addAction() ? [tableActions.resources.view] : []}
          loadingItems={this.state.loadingResources}
          user={this.props.user}
          loadItems={entries => {
            Object.keys(entries).forEach(key => {
              this.setQueryParams(key, entries[key]);
            });
          }}
          filters={this.state.queryParams}
          nPages={this.state.nPages}
          page={this.state.queryParams ? this.state.queryParams.page : 1}
          mobileColumns={{
            name: "resource_name",
            status: "DisplayStatus"
          }}
          reloadAction={this.reloadResources}
          totalItems={this.state.totalItems}
        />
      </>
    );
  }
}

export default Resources;
