import React from "react";
import i18n from "../i18n";
import ApplicationService from "../../services/data_services/application";
import Toast from "../../services/toasts";
import { SERVICE_TYPES } from "../../constants/service-types";

class ProcessElement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false
    };
  }

  render() {
    return (
      <div
        className="ui card"
        style={{
          border: "0"
        }}
      >
        <div className="content" style={{ textAlign: "center" }}>
          <span
            style={{
              width: "100%",
              display: "block",
              overFlow: "hidden",
              wordWrap: "break-word",
              textOverflow: "ellipsis"
            }}
          >
            <b>{this.props.application.name}</b>
          </span>
        </div>
        <div
          className="content"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <span
            style={{
              height: "100px",
              wordWrap: "break-word",
              textOverflow: "ellipsis",
              overflow: "auto"
            }}
          >
            {this.props.application.description}
          </span>
          <button
            type="button"
            className="ui button blue"
            style={{ marginTop: "1rem" }}
            disabled={this.state.disabled}
            onClick={() => {
              switch (this.props.application.trigger_type) {
                case "Form":
                case "Conf::Inbox":
                  this.props.selectApplication(this.props.application);
                  this.props.openModal();
                  break;
                default:
                  this.setState({ disabled: true }, () => {
                    ApplicationService.launchProcess(this.props.application.id)
                      .then(() => {
                        this.setState(
                          { disabled: false },
                          Toast.show.processLaunched()
                        );
                      })
                      .catch(() => {
                        this.setState(
                          { disabled: false },
                          Toast.show.processNotLaunched()
                        );
                      });
                  });
                  break;
              }
            }}
          >
            {this.props.application?.service_type === SERVICE_TYPES.HYPERSCIENCE
              ? i18n.t("ProcessNewRequest")
              : i18n.t("Launch")}
          </button>
        </div>
      </div>
    );
  }
}

export default ProcessElement;
