import React from "react";
import Select from "react-select";
import { isEmpty } from "lodash";
import { ErrorMessage } from "formik";
import i18n from "../../../../i18n";
import { reactSelectStyle } from "../../../../../semantic-ui";
import EmailService from "../../../../../services/data_services/email";
import { isDesktop } from "../../../../../services/general";
import InputDropdown from "../../../../Ui/InputDropdown";

class InboxFeedWork extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inboxes: [],
      emptybox: ""
    };

    this.loadInboxes();
  }

  loadInboxes() {
    EmailService.loadEmails({ page: 0 }).then(response => {
      this.setState({
        inboxes: response.data.results
      });
    });
  }

  inboxOptions() {
    if (!isEmpty(this.state.inboxes)) {
      return this.state.inboxes.map(inbox => {
        return {  value: inbox.id, key: inbox.id, text: inbox.email_alias };
      });
    }
    return [];
  }

  inboxValues() {
    return this.inboxOptions().find(
      inbox => inbox.value === this.props.values.trigger_id
    )?.value;
  }

  subjectOptions() {
    return this.subjectValue();
  }

  changeIdEmpty(title) {
    if (this.state.emptybox !== title) {
      this.setState({ emptybox: title });
    }
  }

  subjectValue() {
    if (!isEmpty(this.state.inboxes)) {
      const subjects = this.state.inboxes.filter(
        inbox => inbox.id === this.props.values.trigger_id
      );

      const subjectFilters =
        subjects.length > 0 ? subjects[0].subject_filters : [];

      this.changeIdEmpty(
        subjectFilters.length === 1 && subjectFilters[0] === ""
          ? "notdisplayed"
          : "ok"
      );

      return subjectFilters.map((subject, index) => {
        return { value: index, label: subject, isFixed: "true" };
      });
    }
    return [];
  }

  description() {
    const forms = this.props.forms.filter(
      form => form.id === this.props.values.trigger_id
    );

    return forms.length > 0 ? forms[0].description : "";
  }

  render() {
    const baseClass = isDesktop() ? "field inlineField" : "field";
    const subFormClass = isDesktop() ? "subForm" : "subFormMargin";
    return (
      <React.Fragment>
        <InputDropdown
            required
            inputlabel={i18n.t("EmailAlias")}
            options={this.inboxOptions()}
            value={this.inboxValues()}
            placeholder={`${i18n.t("Select")}...`}
            disabled={this.props.preConfigured()}
            search
            error={this.props.errors?.trigger_id}
            selection
            onChange={(e, { value } )=> {
              this.props.setFieldValue(
                "trigger_id",
                value ? value : undefined
              );
            }}
        />

        {/* <div className={baseClass}>
          <label>{i18n.t("SubjectFilters")}</label>
          <Select
            id={this.state.emptybox}
            options={this.subjectOptions()}
            value={this.subjectValue()}
            isMulti
            isDisabled
            isClearable={false}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder={`${i18n.t("Select")}...`}
            styles={reactSelectStyle}
          />
        </div>
        <InputDropdown
            required
            inputlabel={i18n.t("NonApproverTeams")}
            options={teamOptions()}
            value={nonApproverValues()}
            placeholder={`${i18n.t("Select")}...`}
            disabled={props.preConfigured()}
            search
            multiple
            selection
            onChange={(e, { value } )=> {
              setValue(value, "manager_team_ids");
            }}
        /> */}
      </React.Fragment>
    );
  }
}

export default InboxFeedWork;
