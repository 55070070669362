import React from "react";
import { Transition, Dropdown, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Widget from "./Widget";
import i18n from "../../../i18n";
import WidgetService from "../../../../services/data_services/widget";
import Toast from "../../../../services/toasts";
import DeleteModal from "./Modal";

class Section extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.section.name,
      editing: !props.section.id,
      collapsed: this.props.index === 0,
      modalOpen: false
    };

    this.handleAccept = this.handleAccept.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleAccept() {
    if (this.state.name) {
      this.setState({ editing: false }, () => {
        this.props.section.id
          ? this.props.updateSection(this.props.section.id, {
              name: this.state.name
            })
          : this.props.createSection({ name: this.state.name, widgets: [] });
      });
    } else {
      Toast.displayError(i18n.t("SectionNameNotEmpty"));
    }
  }

  handleCancel() {
    this.setState({ editing: false }, () => {
      if (this.props.section.id) {
        this.setState({
          name: this.props.section.name
        });
      } else {
        this.props.removeSection();
      }
    });
  }

  handleEdit() {
    this.setState({ editing: true });
  }

  handleDelete() {
    this.setState({ editing: false }, () => {
      if (this.props.section.id) {
        this.props.removeSection(this.props.section.id);
      }
    });
  }

  deleteWidget = id => {
    WidgetService.delete(id)
      .then(() => {
        this.props.reloadSections();
        Toast.show.widgetDeleted();
      })
      .catch(() => {
        Toast.show.widgetNotDeleted();
      })
  }

  onDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    this.props.reorderSectionWidgets(
      this.props.section.id,
      result.source.index,
      result.destination.index
    );

    // return result;
  };

  renderTitle() {
    if (this.state.editing) {
      return (
        <div className="ui input d-flex-center" style={{ width: "100%" }}>
          <input
            defaultValue={this.state.name}
            placeholder="Section name..."
            onChange={e => {
              this.setState({
                name: e.target.value
              });
            }}
          />
          <div className="line-action-button accept" onClick={this.handleAccept} ><i className="check icon green action-icon"></i></div>
          <div className="line-action-button decline" onClick={this.handleCancel} ><i className="times icon red action-icon"></i></div>
        </div>
      );
    }
    return (
      <h3 className="ui header" style={{ marginTop: "0" }}>
        {this.state.name}
      </h3>
    );
  }

  renderCollapseButton() {
    const buttonName = `angle icon ${this.state.collapsed ? "up" : "down"}`;

    return (
      <button
        className="ui mini button icon basic"
        type="button"
        disabled={this.state.editing}
        onClick={() =>
          this.setState(prevState => ({ collapsed: !prevState.collapsed }))
        }
      >
        <i className={buttonName} />
      </button>
    );
  }

  renderBody() {
    return (
      <Transition
        animation="slide down"
        visible={this.state.collapsed}
        mountOnShow
      >
        <div>
          <div className="ui transparent segment grid">
            {this.props.section.widgets && this.props.section.widgets.length > 0
              ? this.renderWidgets()
              : this.renderSectionsEmpty()}
          </div>
        </div>
      </Transition>
    );
  }

  renderWidgets() {
    return (
      <React.Fragment>
        <DragDropContext onDragEnd={this.onDragEnd}  style={{paddingRight:"0px"}}>
          <Droppable droppableId={`droppable-widgets-${this.props.section.id}`} direction="horizontal">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {this.props.section.widgets.map((widget, index) => {
                  return (
                    <Widget
                      services={this.props.services}
                      dummy={false}
                      widget={widget}
                      key={widget.id}
                      index={index}
                      section={this.props.section}
                      handleDelete={this.deleteWidget}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </React.Fragment>
    );
  }

  renderSectionsEmpty() {
    return (
      <p style={{ width: "100%", textAlign: "center" }}>
        {i18n.t("NoWidgetYet")}
      </p>
    );
  }

  render() {
    return (
      <div
        className="ui segment"
        style={{
          borderColor: "#227d98 !important"
        }}
      >
        <div className="header d-flex-center-sb">
          <div
            style={{
              display: "flex",
              minWidth: "50%",
              alignItems: "center"
            }}
          >
            {this.renderTitle()}
          </div>

          <div style={{ display: "flex" }}>
            <Dropdown
              disabled={this.state.editing}
              icon="ellipsis vertical"
              floating
              button
              className="icon mini basic right"
            >
              <Dropdown.Menu>
                <Link
                  className="item"
                  type="button"
                  to={`/admin/dashboard/${this.props.section.id}/widget`}
                >
                  <i className="icon add" /> {i18n.t("AddWidget")}
                </Link>
                <a
                  onClick={this.handleEdit}
                  type="button"
                  className="item"
                >
                  <i className="icon edit" /> {i18n.t("EditName")}
                </a>
                <a
                  className="item"
                  type="button"
                  onClick={_ => {
                    this.setState({ modalOpen: true })
                  }
                }>
                  <i className="icon trash" /> {i18n.t("Delete")}
                </a>
              </Dropdown.Menu>
            </Dropdown>
            {this.renderCollapseButton()}
          </div>
        </div>

        {this.renderBody()}
        <DeleteModal
          title={i18n.t("DeleteSection")}
          confirmationText={i18n.t("DeleteSectionConfirmation")}
          toggleModal={modalOpen => {
            this.setState({ modalOpen });
          }}
          modalOpen={this.state.modalOpen}
          submitAction={this.handleDelete}
        />
      </div>
    );
  }
}

export default Section;
