import React from "react";
import IframeResizer from "iframe-resizer-react";
import { Transition } from "semantic-ui-react";
import ImageAttachment from "./ImageAttachment";
import { extension } from "../../services/files";

const AttachmentContainer = props => {
  const fileExtension = extension(props.url);
  return (
    <Transition visible={props.url !== undefined} unmountOnHide>
      <div
        className="attachment card"
        style={{ alignSelf: "start", textAlign: "center" }}
      >
        <div className="content" style={{ position: "relative" }}>
          {fileExtension === "pdf" ? (
            <IframeResizer
              autoResize
              scrolling
              src={props.url}
              style={{ minWidth: "100%", minHeight: "85vh" }}
            />
          ) : (
            <ImageAttachment url={props.url} />
          )}
        </div>
      </div>
    </Transition>
  );
};

export default AttachmentContainer;
