import React from "react";
import { Field, ErrorMessage } from "formik";
import Process from "../ElementTypes/Process";
import Application from "../ElementTypes/Application";
import TooltipLabel from "../TooltipLabel";
import i18n from "../../../i18n";
import { isDesktop } from "../../../../services/general";
import SchedulersInputs from "../SchedulersInputs";

class SchedulersStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.maxProcesses = 10;

    this.state = {
      showApplication: false
    };
  }

  componentDidMount() {
    if (this.props.user.roles.includes("user") || this._application()) {
      this.setState({ showApplication: true });
    }

    setTimeout(() => {
      this.props.setFieldTouched("processes", []);
    }, 100);
  }

  enabledAddApplication = () => {
    const processEmpty = this._processes().length === 0;

    return !this.state.showApplication && processEmpty;
  };

  enabledAddProcess = () => {
    const noApplication = !this._application();
    const noProcessLimitReached = this._processes().length < this.maxProcesses;

    return (
      noApplication && noProcessLimitReached && this.manageProcessEnabled()
    );
  };

  showProcesses = () => {
    return this.manageProcessEnabled();
  };

  manageProcessEnabled = () => {
    return this.props.user.roles.includes("admin");
  };

  addApplication = () => {
    const processes = [
      {
        application_id: undefined,
        max_resources: 1,
        data: undefined
      }
    ];

    this.props.setFieldValue("processes", processes);
    this.setState({ showApplication: true });
  };

  addProcess = () => {
    const processes = [...this.props.values.processes];

    processes.push({
      service_id: "",
      process_queue: "",
      process_name: "",
      max_resources: 1,
      resource_pools: []
    });

    this.props.setFieldValue("processes", processes);
  };

  removeApplication = () => {
    this.props.setFieldValue("processes", []);
    this.props.setFieldValue("bp_calendar_name", undefined);
    this.props.setFieldTouched("processes", []);
    this.setState({ showApplication: false });
  };

  removeProcesses = index => {
    const processes = [...this.props.values.processes];
    processes.splice(index, 1);

    let touchedProcesses = [];

    if (
      Object.prototype.hasOwnProperty.call(this.props.touched) &&
      Array.isArray(this.props.touched.processes)
    ) {
      touchedProcesses = [...this.props.touched.processes];

      if (touchedProcesses.length > index) {
        touchedProcesses.splice(index, 1);
      }
    }

    this.props.setFieldValue("processes", processes);
    this.props.setFieldValue("bp_calendar_name", undefined);
    this.props.setFieldTouched("processes", touchedProcesses);
  };

  renderProcesses = () => {
    if (!this.showProcesses()) {
      return undefined;
    }

    return (
      <>
        <div className="inline fields">
          <TooltipLabel
            label={i18n.t("Process")}
            content={i18n.t("ProcessTooltip")}
          />
          <button
            type="button"
            className="ui mini icon basic grey button"
            disabled={!this.enabledAddProcess()}
            onClick={this.addProcess}
          >
            <i className="icon plus" />
          </button>
        </div>

        {this._processes().map((process, index) => (
          <React.Fragment key={index}>
            <Process
              removeProcesses={this.removeProcesses}
              index={index}
              values={this.props.values}
              setFieldValue={this.props.setFieldValue}
              errors={this.props.errors}
              touched={this.props.touched}
              setFieldTouched={this.props.setFieldTouched}
              editable={this.manageProcessEnabled()}
            />
            {process.process_name && (
              <SchedulersInputs
                index={index}
                values={this.props.values}
                setFieldValue={this.props.setFieldValue}
              />
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  _application = () => {
    return this.props.values.processes.find(process => {
      return (
        Object.prototype.hasOwnProperty.call(process, "application_id") ||
        (Object.prototype.hasOwnProperty.call(process, "application_uuid") &&
          process.application_uuid)
      );
    });
  };

  _processes = () => {
    return this.props.values.processes.filter(process => {
      return (
        !Object.prototype.hasOwnProperty.call(process, "application_id") &&
        ((Object.prototype.hasOwnProperty.call(process, "application_uuid") &&
          (process.application_uuid === null ||
            process.application_uuid === "")) ||
          !Object.prototype.hasOwnProperty.call(process, "application_uuid"))
      );
    });
  };

  isNotAdmin = () => {
    return !this.props.user.roles.includes("admin");
  };

  renderAddApplicationButton = () => {
    return (
      <button
        type="button"
        className="ui mini icon basic grey button"
        disabled={!this.enabledAddApplication()}
        onClick={this.addApplication}
      >
        <i className="icon plus" />
      </button>
    );
  };

  renderRemoveApplicationButton = () => {
    return (
      <div className="buttonContainer">
        <button
          type="button"
          className="ui mini icon basic grey button"
          disabled={this.isNotAdmin()}
          onClick={this.removeApplication}
        >
          <i className="icon minus" />
        </button>
      </div>
    );
  };

  renderApplicationButton = () => {
    const { showApplication } = this.state;

    if (!isDesktop() && showApplication) {
      return this.renderRemoveApplicationButton();
    }

    return this.renderAddApplicationButton();
  };

  render() {
    return (
      <div className="stepContainer">
        <div className={isDesktop() ? "required inline fields" : "required field"}>
          <label>{i18n.t("SchedulerName")}</label>
          <div className="field full-width">
            <Field
              name="name"
              type="text"
              onChange={this.props.handleChange}
              style={{ width: "100%" }}
              validate={() => {}}
            />
          </div>
        </div>
        <ErrorMessage
          name="name"
          component="div"
          className={isDesktop() ? "inline error" : "mobile error"}
        />
        <div className="inline fields">
          <TooltipLabel
            label={i18n.t("Application")}
            content={i18n.t("ApplicationTooltip")}
          />
          {this.renderApplicationButton()}
        </div>

        {this.state.showApplication && (
          <React.Fragment>
            <Application
              setFieldValue={this.props.setFieldValue}
              removeApplication={this.removeApplication}
              index={0}
              values={this.props.values}
              errors={this.props.errors}
              touched={this.props.touched}
              setFieldTouched={this.props.setFieldTouched}
              user={this.props.user}
              removeApplicationButton={this.renderRemoveApplicationButton}
            />
            {this.props.values.processes?.length > 0 &&
              this.props.values.processes[0]?.application_id && (
                <SchedulersInputs
                  index={0}
                  values={this.props.values}
                  setFieldValue={this.props.setFieldValue}
                />
              )}
          </React.Fragment>
        )}

        {this.renderProcesses()}
      </div>
    );
  }
}

export default SchedulersStep1;
