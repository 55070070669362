import { useState } from "react";

export default function useQueryParams(initialParams) {
  const [queryParams, setQueryParams] = useState({ ...initialParams });

  const updateQueryParams = entries => {
    const shouldUpdate = !Object.keys(entries).every(
      key => entries[key] === queryParams[key]
    );

    if (shouldUpdate) {
      setQueryParams(prevState => {
        const auxQueryParams = { ...prevState };
        Object.keys(entries).forEach(key => {
          auxQueryParams[key] = entries[key] || undefined;
        });
        return auxQueryParams;
      });
    }
  };

  return [queryParams, updateQueryParams];
}
