import React from "react";
import { Icon, Popup } from "semantic-ui-react";

const TooltipLabel = props => {
  return (
    <label>
      <div className="tooltipLabel">
        <div>{props.label}</div>
        {props.isRequired && (
          <span style={{ margin: "-.2em 0 0 .2em", color: "#db2828" }}>*</span>
        )}
        <div style={{ marginLeft: "0.35em" }}>
          <Popup
            trigger={<Icon name="info circle" />}
            content={props.content}
            inverted
          />
        </div>
      </div>
    </label>
  );
};

export default TooltipLabel;
