import React from "react";

export default function ExpandedRow({ children, totalColumns }) {
  return (
    <tr>
      <td
        style={{
          gridColumn: `1 / ${totalColumns + 1}`
        }}
        className="expanded-row"
      >
        {children}
      </td>
    </tr>
  );
}
