import React from "react";
import i18n from "../i18n";
import ServiceService from "../../services/data_services/service";
import Toast from "../../services/toasts";

const ServicesSuspend = props => {
  const noClick = () => {
    props.closeModal();
  };

  const yesClick = () => {
    ServiceService.deleteService(props.service.id)
      .then(() => {
        Toast.show.serviceDeleted();
        props.loadServices();
        props.closeModal();
      })
      .catch(e => {
        Toast.displayError(
          e.response.data.message,
          i18n.t("ServiceNotDeleted")
        );
      });
  };

  return (
    <div>
      <p style={{ textAlign: "center", marginBottom: "0" }}>
        {i18n.t("SuspendServiceInfo")}
      </p>

      <div className="ui container">
        <div
          className="ui text two column grid"
          style={{ paddingBottom: "15px", paddingTop: "25px" }}
        >
          <div className="row" style={{ padding: "0.5rem 0" }}>
            <div className="eight wide column">
              <button
                type="button"
                className="ui button basic blue"
                style={{ width: "100%" }}
                onClick={() => noClick()}
              >
                {i18n.t("NoButton")}
              </button>
            </div>
            <div className="eight wide column">
              <button
                type="button"
                className="ui button blue"
                style={{ width: "100%" }}
                onClick={() => yesClick()}
              >
                {i18n.t("YesButton")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSuspend;
