import React from "react";
import { Loader } from "semantic-ui-react";
import i18n from "../../i18n";

export default function TableLoader() {
  return (
    <Loader active inline="centered">
      {i18n.t("Loading")}
    </Loader>
  );
}
