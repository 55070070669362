import React from "react";
import { Route, Switch } from "react-router-dom";
import SchedulerMain from "./SchedulerMain";
import SchedulersNew from "./Schedulers/SchedulersNew";
import SchedulersEdit from "./Schedulers/SchedulersEdit";

const Scheduler = props => {
  return (
    <Switch>
      <Route
        path="/schedulers/new/:id?"
        render={routerProps => (
          <div className="component-wrapper">
            <SchedulersNew {...props} {...routerProps} />
          </div>
        )}
      />
      <Route
        path="/schedulers/:id"
        render={routerProps => (
          <div className="component-wrapper">
            <SchedulersEdit {...props} {...routerProps} />
          </div>
        )}
      />
      <Route
        exact
        path="/(scheduler_monitor|schedulers)"
        render={componentProps => (
          <SchedulerMain {...props} match={componentProps.match} />
        )}
      />
    </Switch>
  );
};

export default Scheduler;
