import React, { Component } from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";
import i18n from "../i18n";

import ServicesForm from "./ServicesForm";
import ServicesInfo from "./ServicesInfo";
import ServicesSuspend from "./ServicesSuspend";

export const ModalType = Object.freeze({
  New: "NEW",
  Edit: "EDIT",
  Show: "SHOW",
  Suspend: "SUSPEND"
});

class ServicesModal extends Component {
  modalTitle = () => {
    switch (this.props.modalType) {
      case ModalType.New:
        return i18n.t("AddingService");
      case ModalType.Edit:
        return i18n.t("EditingService");
      case ModalType.Suspend:
        return i18n.t("SuspendService");
      case ModalType.Show:
        return "Service Info";
      default:
        return "";
    }
  };

  renderModalContent() {
    switch (this.props.modalType) {
      case ModalType.New:
        return (
          <ServicesForm
            service={{}}
            paramsByType={this.props.paramsByType}
            loadServices={this.props.loadServices}
            closeModal={this.props.closeModal}
          />
        );
      case ModalType.Edit:
        return (
          <ServicesForm
            service={this.props.service}
            paramsByType={this.props.paramsByType}
            loadServices={this.props.loadServices}
            closeModal={this.props.closeModal}
          />
        );
      case ModalType.Suspend:
        return (
          <ServicesSuspend
            service={this.props.service}
            loadServices={this.props.loadServices}
            closeModal={this.props.closeModal}
          />
        );
      case ModalType.Show:
      default:
        return (
          <ServicesInfo
            service={this.props.service}
            paramsByType={this.props.paramsByType}
          />
        );
    }
  }

  render() {
    return (
      <TransitionablePortal
        open={this.props.open}
        transition={{
          animation: "scale",
          duration: 300
        }}
      >
        <Modal open size="tiny" onClose={() => this.props.closeModal()}>
          <Modal.Header>
            <div style={{ textAlign: "center" }}>
              {i18n.t(this.modalTitle())}
              <i
                role="button"
                tabIndex={0}
                aria-label="close"
                className="close icon"
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => this.props.closeModal()}
              />
            </div>
          </Modal.Header>
          <Modal.Content scrolling>{this.renderModalContent()}</Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

export default ServicesModal;
