import React from "react";
import { isEqual } from "lodash-es";
import i18n from "../../i18n";
import ActivityCard from "./ActivityCard";
import { APPLICATION_TYPES } from "../../../constants/ApplicationTypes";
import { toIntArray } from "../../../services/array";

const DEFAULT_CARDS = [
  {
    backgroundColor: "green",
    title: i18n.t("ActivitiesCompleted"),
    icons: ["check"],
    status: "completed",
    filter: 5
  },
  {
    backgroundColor: "yellow",
    title: i18n.t("ActivitiesAttention"),
    icons: ["warning"],
    status: "attention",
    filter: 4
  },
  {
    backgroundColor: "yellow",
    title: i18n.t("ActivitiesIncomplete"),
    icons: ["warning"],
    status: "incomplete",
    filter: 2
  },
  {
    backgroundColor: "red",
    title: i18n.t("ActivitiesCompletedUnsuccessfully"),
    icons: ["exclamation triangle"],
    status: "unsuccessful",
    filter: 1
  },
  {
    backgroundColor: "orange",
    title: i18n.t("ActivitiesInProgress"),
    icons: ["history icon"],
    status: "pending",
    filter: [0, 3]
  }
];

const CARDS = {
  [APPLICATION_TYPES.HUMAN_IN_THE_LOOP]: DEFAULT_CARDS,
  [APPLICATION_TYPES.CASE_VIEW]: DEFAULT_CARDS,
  [APPLICATION_TYPES.HYPERSCIENCE]: [
    {
      backgroundColor: "yellow",
      title: i18n.t("ActivitiesAttention"),
      icons: ["warning"],
      status: "supervision",
      filter: 11
    },
    {
      backgroundColor: "orange",
      title: i18n.t("Processing"),
      icons: ["history icon"],
      status: "processing",
      filter: 10
    },
    {
      backgroundColor: "green",
      title: i18n.t("ActivitiesCompleted"),
      icons: ["check"],
      status: "completed",
      filter: 5
    },
    {
      backgroundColor: "red",
      title: i18n.t("Failed"),
      icons: ["exclamation triangle"],
      status: "failed",
      filter: 12
    }
  ],
  [APPLICATION_TYPES.NCW]: [
    {
      backgroundColor: "green",
      title: i18n.t("Approved"),
      icons: ["check"],
      status: "approved",
      filter: 15
    },
    {
      backgroundColor: "yellow",
      title: i18n.t("ApprovalPending"),
      icons: ["warning"],
      status: "approval_pending",
      filter: 14
    },
    {
      backgroundColor: "red",
      title: i18n.t("Rejected"),
      icons: ["exclamation triangle"],
      status: "rejected",
      filter: 16
    },
    {
      backgroundColor: "orange",
      title: i18n.t("Draft"),
      icons: ["history"],
      status: "draft",
      filter: 13
    }
  ],
  [APPLICATION_TYPES.UNATTENDED]: [
    {
      backgroundColor: "green",
      title: i18n.t("ActivitiesCompleted"),
      icons: ["check"],
      status: "completed",
      filter: "Completed"
    },
    {
      backgroundColor: "yellow",
      title: i18n.t("Locked"),
      icons: ["lock"],
      status: "locked",
      filter: "Locked"
    },
    {
      backgroundColor: "red",
      title: i18n.t("ExceptionUN"),
      icons: ["exclamation triangle"],
      status: "exception",
      filter: "Exception"
    },
    {
      backgroundColor: "orange",
      title: i18n.t("ActivitiesInProgress"),
      icons: ["history icon"],
      status: "pending",
      filter: "Pending"
    }
  ]
};

const ActivityCards = ({ stats = {}, ...props }) => {
  if (!props.application) return null;

  const applicationType =
    props.application?.applicationType || APPLICATION_TYPES.CASE_VIEW;

  const visibleCards = CARDS[applicationType] || DEFAULT_CARDS;

  const nElements = key =>
    stats[key] && !Number.isNaN(stats[key]) ? parseInt(stats[key], 10) : 0;

  let selectedFilter = null;
  let cardFilters = null;

  if (applicationType === APPLICATION_TYPES.UNATTENDED) {
    selectedFilter = Array.isArray(props.queryParams.status)
      ? props.queryParams.status
      : [props.queryParams.status];
    cardFilters = visibleCards.map(card => [card.filter]);
  } else {
    selectedFilter = toIntArray(props.queryParams.status);
    cardFilters = visibleCards.map(card => toIntArray(card.filter));
  }
  const selectedFiltersMatchCard = cardFilters.some(cardFilter =>
    isEqual(cardFilter, selectedFilter)
  );

  const cardsSelected =
    props.queryParams?.cards === "true" || selectedFiltersMatchCard;

  return (
    <div id="activitySquareContainer">
      {visibleCards.map(squareInfo => (
        <ActivityCard
          key={squareInfo.filter}
          applicationType={applicationType}
          cardsSelected={cardsSelected}
          squareInfo={squareInfo}
          contents={nElements(squareInfo.status)}
          onChange={props.onChange}
          queryParams={props.queryParams}
        />
      ))}
    </div>
  );
};

export default ActivityCards;
