import axios from "../axios";

class TeamService {
  static loadTeams = (params = null) => {
    return axios.get("/teams", {
      params
    });
  };

  static loadTeam = id => {
    return axios.get(`/teams/${id}`);
  };

  static createTeam = values => {
    return axios.post("/teams", values);
  };

  static updateTeam = (id, values) => {
    return axios.put(`/teams/${id}`, values);
  };
}

export default TeamService;
