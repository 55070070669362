import React, { useState, useEffect, useCallback } from "react";
import InputDropdown from "./InputDropdown";
import i18n from "../i18n";

const NUMBERS = {
    months: 12,
    daysA: 31,
    daysB: 30,
    feb: 28
}

const DayYearSelection = props => {
    const { value } = props;

    const [month, setMonth] = useState();
    const [day, setDay] = useState();

    
    useEffect(() => {
    if (value) {
        setMonth(value.split('/')[0])
        setDay(value.split('/')[1])
    }
    }, [value]);

    const optionCreator = feed =>{
        let options = []
        for(let i = 0 ; i < NUMBERS[feed]; i++ ){
            let stringValue = i+1 >= 10 ? `${i+1}` : `0${i+1}` 
            options.push({
                  key: stringValue,
                  text: stringValue,
                  value: stringValue,              
                })
        }
        return options;
    }
    
    const getDaysFeed =_=>{
        if(month === '02'){
            return optionCreator('feb')
        }
        return ['01','03','05','07','08','10','12'].indexOf(month)>=0 ? optionCreator('daysA') : optionCreator('daysB')
    }

  return (
    <div className='d-flex-center-sb'>
        <InputDropdown
            containerStyle={{width:'45%'}}
            required
            disabled={props.disabled}
            inputlabel={i18n.t("Year End/Month")}
            placeholder={i18n.t("Enter Month")}
            options={optionCreator('months')}
            search
            selection
            value={month}
            onChange={(e, { value }) =>{
                setMonth(value)
                setDay(1)
                props.onChange(`${value}/${day}`)
            }}
        />
        <InputDropdown
            containerStyle={{width:'45%'}}
            required
            disabled={props.disabled}
            inputlabel={i18n.t("Year End/Day")}
            placeholder={i18n.t("Enter Day")}
            options={getDaysFeed()}
            search
            selection
            value={day}
            onChange={(e, { value }) =>{
                setDay(value)
                props.onChange(`${month}/${value}`)
            }}
        />
    </div>
  );
};

// DayYearSelection.defaultProps = {
//   inputlabel: ""
// };

export default DayYearSelection;
