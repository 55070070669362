import React from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";

const UiModal = props => {
  return (
    <TransitionablePortal
      open={props.open}
      transition={{
        animation: "scale",
        duration: 500
      }}
      closeOnDocumentClick={false}
    >
      <Modal open size={props.size}>
        <Modal.Header>
          <div className="modal-header">
            {props.headerContent}
            <i
              role="button"
              aria-label="Close"
              tabIndex={0}
              className="close icon"
              onClick={() => props.onClose()}
            />
          </div>
        </Modal.Header>
        <Modal.Content>
          <div className="modal-content">{props.children}</div>
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};

UiModal.defaultProps = {
  open: false,
  size: "tiny"
};

export default UiModal;
