import React from "react";

const Toolbar = props => {
  const {
    page,
    scale,
    numPages,
    setPage,
    setScale,
    maxImageSize = false
  } = props;
  return (
    <div className="toolbarBackground">
      {page ? (
        <div>
          <button
            type="button"
            disabled={page === 1}
            onClick={() => setPage(prevPage => prevPage - 1)}
            className="toolbarButton"
          >
            <i className="chevron left icon" />
          </button>
          <span>
            {page} of {numPages}
          </span>
          <button
            type="button"
            disabled={page === numPages}
            onClick={() => setPage(prevPage => prevPage + 1)}
            className="toolbarButton"
          >
            <i className="chevron right icon" />
          </button>
        </div>
      ) : (
        <div />
      )}
      <div>
        <button
          type="button"
          disabled={scale <= 0.5}
          onClick={() => setScale(prevScale => prevScale - 0.25)}
          className="toolbarButton"
        >
          <i className="icon search minus" />
        </button>
        <span>{`${scale * 100}%`}</span>
        <button
          type="button"
          disabled={maxImageSize || scale >= 4}
          onClick={() => setScale(prevScale => prevScale + 0.25)}
          className="toolbarButton"
        >
          <i className="icon search plus" />
        </button>
      </div>
    </div>
  );
};

export default Toolbar;
