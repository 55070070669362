import React from "react";
import { Button } from "semantic-ui-react";
import i18n from "../i18n";

import { ModalType } from "./ServicesModal";
import Permissions from "../../services/permissions";
import {
  VisitableComponents,
  Actions
} from "../../services/detailedPermissionRules";

const ServiceRow = props => {
  const { service, paramsByType, showModal } = props;
  const tableCell = (label, data) => {
    return (
      <td
        data-label={`${label}`}
        style={{ height: "50px" }}
        onClick={() => showModal(ModalType.Show, service)}
        className="handcursor"
      >
        {data}
      </td>
    );
  };

  const allowEdit = () =>
    Object.keys(paramsByType).includes(service.service_type);

  return (
    <tr>
      {tableCell(i18n.t("ServiceName"), service.name)}
      {tableCell(i18n.t("CompanyName"), service.company_name)}
      {tableCell(i18n.t("ServiceUrl"), service.service_url)}
      {tableCell(i18n.t("Type"), service.service_type)}
      <td>
        <Permissions
          component={VisitableComponents.Services}
          action={Actions.update}
          yes={() => {
            return (
              <Button
                color="blue"
                basic
                floated="right"
                icon="trash alternate"
                onClick={() => {
                  showModal(ModalType.Suspend, service);
                }}
              />
            );
          }}
          no={() => null}
        />
        {allowEdit() && (
          <Permissions
            component={VisitableComponents.Services}
            action={Actions.update}
            yes={() => {
              return (
                <Button
                  color="blue"
                  basic
                  floated="right"
                  onClick={() => {
                    showModal(ModalType.Edit, service);
                  }}
                >
                  {i18n.t("Edit")}
                </Button>
              );
            }}
            no={() => null}
          />
        )}
      </td>
    </tr>
  );
};

export default ServiceRow;
