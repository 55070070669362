import React, { useState, useEffect } from "react";

const ImageViewer = props => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
  }, [props.url]);

  useEffect(() => {
    if (!loading) {
      props.setDocumentDimensions();
    }
  }, [props.scale, loading]);

  const imageWidth = props.scale * 100;

  const renderLoading = () => (
    <div
      className="ui active inverted dimmer"
      style={{ marginTop: "50px", backgroundColor: "inherit" }}
    >
      <div className="ui text loader" />
    </div>
  );

  return (
    <div className={props.show ? null : "hiddenImage"}>
      <div style={{ width: `${imageWidth}%` }} className="imageContainer">
        {loading && renderLoading()}
        <img
          ref={props.reference}
          src={props.url}
          className={loading ? "documentImage hidden" : "documentImage"}
          alt=""
          onLoad={() => {
            setLoading(false);
            if (props.show) {
              props.onImageLoad();
            }
          }}
        />
      </div>
    </div>
  );
};

export default ImageViewer;
