export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOAD_COMPANIES = "LOAD_COMPANIES";
export const LOAD_USERS = "LOAD_USERS";
export const LOAD_ROLES = "LOAD_ROLES";
export const SET_BREADCRUMB = "SET_BREADCRUMB";
export const SET_LOADING_BAR = "SET_LOADING_BAR";
export const TOGGLE_SIDE_BAR = "TOGGLE_SIDE_BAR";
export const LOAD_APPLICATIONS = "LOAD_APPLICATIONS";
export const LOAD_INDIVIDUAL_APPLICATIONS = "LOAD_INDIVIDUAL_APPLICATIONS";
export const LOAD_LAUNCH_APPLICATIONS = "LOAD_LAUNCH_APPLICATIONS";
export const LOAD_SERVICES = "LOAD_SERVICES";
export const LOAD_COLUMNS = "LOAD_COLUMNS";
export const LOAD_FILTERS = "LOAD_FILTERS";
export const DELETE_FILTERS = "DELETE_FILTERS";
