import {
  LOAD_APPLICATIONS,
  LOAD_INDIVIDUAL_APPLICATIONS,
  LOAD_LAUNCH_APPLICATIONS
} from "../constants/action-types";

export default (state = {}, action) => {
  if (action.type === LOAD_APPLICATIONS) {
    return { ...state, applications: action.payload };
  }
  if (action.type === LOAD_INDIVIDUAL_APPLICATIONS) {
    return { ...state, individualApplications: action.payload };
  }
  if (action.type === LOAD_LAUNCH_APPLICATIONS) {
    return { ...state, launchApplications: action.payload };
  }
  return state;
};
