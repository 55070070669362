import React, { useEffect } from "react";
import { TimeInput } from "semantic-ui-calendar-react-yz";
import moment from "moment-timezone";

const TimeInputField = props => {
  useEffect(() => {
    if (!props.value && props.value !== "") {
      props.setFieldValue("");
    }
  }, [props.value]);

  const timeDisplayFormat = "HH:mm";
  const timeSentFormat = props.fromEnvVariables
    ? "HH:mm:ss"
    : "YYYY-MM-DDTHH:mm.SSS";

  const getFormatedTimeToDisplay = dateString => {
    return dateString ? timeToDisplayFromUTC(dateString) : "";
  };

  const timeToDisplayFromUTC = utcDate => {
    return moment(utcDate, timeSentFormat).format(timeDisplayFormat);
  };

  const timeToUTCFromDisplay = displayTime => {
    if (displayTime) {
      const auxTime = moment(displayTime, timeDisplayFormat).format(
        timeSentFormat
      );
      return props.fromEnvVariables ? auxTime : `${auxTime}Z`;
    }
    return "";
  };

  return (
    <TimeInput
      name={props.name}
      placeholder="Time"
      value={getFormatedTimeToDisplay(props.value)}
      closable
      clearable
      animation="none"
      iconPosition="left"
      disabled={props.disabled}
      onChange={(_event, { value }) => {
        props.setFieldValue(timeToUTCFromDisplay(value));
      }}
      style={{ width: "100%" }}
    />
  );
};

export default TimeInputField;
