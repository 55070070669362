import React from "react";
import { isEmpty } from "lodash";

const FieldHighlight = props => {
  const returnFieldClass = field => {
    switch (field.state) {
      case "supervision":
        return "incomplete";
      case "complete":
      default:
        if (field.source === "manual_transcription") {
          return "supervised";
        }
        return "complete";
    }
  };

  const isSelected = field => {
    if (field && !isEmpty(props.selectedField)) {
      return field.id === props.selectedField.id;
    }
    return false;
  };

  const width =
    (props.field.coordinates.end_x - props.field.coordinates.start_x) * props.dimensions.width;
  const height =
    (props.field.coordinates.end_y - props.field.coordinates.start_y) * props.dimensions.height;
  const offsetX = props.field.coordinates.start_x * props.dimensions.width;
  const offsetY = props.field.coordinates.start_y * props.dimensions.height;

  const isFieldSelected = isSelected(props.field);

  return (
    <div
      id={`field_${props.field.id}`}
      className={`highlightedField ${returnFieldClass(props.field)} ${isSelected(props.field) ? "selected" : ""}`}
      onClick={() => props.setSelectedField(isFieldSelected ? -1 : props.field.id, "viewer")}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        transform: `translate(${offsetX}px, ${offsetY}px)`
      }}
    />
  );
};

export default FieldHighlight;
