import React from "react";
import { Image, Grid} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import i18n from "../i18n";
import CompactLogo from "images/logo.png";

const Notfound = props => {
    return(
        <div className='cantFindContainer'>
            <div>
                <Image src={CompactLogo} alt="Invoke Logo" verticalAlign="middle" size="medium"/>
                <h2>{i18n.t('UnauthorizedTitle')}</h2>

                {props?.location?.state?.noPermitted ?
                <h4>{i18n.t('NoAccessModule')}</h4>
                :
                <h4>{i18n.t('UnauthorizedBody')}</h4>
                }
            </div>
        </div>
    );
}

export default withRouter(Notfound);