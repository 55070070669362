import Toast from "../../../services/toasts";
import i18n from "../../i18n";
import ResourcesImage from "../../../../assets/images/resourcesImage.jpg";
import References from "../../Admin/AssetManagement/EnvVariables/References";

export const Type = Object.freeze({
  Link: "LINK",
  Modal: "MODAL",
  Click: "CLICK"
});

export const Action = Object.freeze({
  Delete: "DELETE",
  Clone: "CLONE",
  Edit: "EDIT",
  Play: "PLAY",
  Pause: "PAUSE",
  View: "VIEW",
  Component: "COMPONENT"
});

export const tableActions = {
  admin_email: {
    clone: {
      action: Action.Clone,
      type: Type.Link,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/admin/emails/new/${id}",
      button_icon: "clone",
      button_text: "Clone"
    },
    edit: {
      action: Action.Edit,
      type: Type.Link,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/admin/emails/${id}",
      button_icon: "edit",
      button_text: "Edit"
    },
    delete: {
      action: Action.Delete,
      type: Type.Modal,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/conf/inboxes/${id}",
      //button_icon: "trash",
      button_icon: "trash",
      button_text: "Delete",
      title: "Delete Email",
      text: "This mail will be deleted, are you sure?",
      toast: {
        success: Toast.show.emailsDeleted,
        failure: Toast.show.emailsNotDeleted
      }
    }
  },
  user: {
    edit: {
      action: Action.Edit,
      type: Type.Link,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/admin/users/${id}",
      button_icon: "edit",
      button_text: "Edit"
    },
    delete: {
      action: Action.Delete,
      type: Type.Modal,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/users/${id}",
      button_icon: "trash",
      button_text: "Delete",
      title: "Delete User",
      text: "This user will be deleted, are you sure?",
      toast: {
        success: Toast.show.usersDeleted,
        failure: Toast.show.usersNotDeleted
      }
    }
  },
  team: {
    clone: {
      action: Action.Clone,
      type: Type.Link,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/admin/teams/new/${id}",
      button_icon: "clone",
      button_text: "Clone"
    },
    edit: {
      action: Action.Edit,
      type: Type.Link,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/admin/teams/${id}",
      button_icon: "edit",
      button_text: "Edit"
    },
    delete: {
      action: Action.Delete,
      type: Type.Modal,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/teams/${id}",
      button_icon: "trash",
      button_text: "Delete",
      title: "Delete Team",
      text: "This team will be deleted, are you sure?",
      toast: {
        success: Toast.show.teamsDeleted,
        failure: Toast.show.teamsNotDeleted
      }
    }
  },
  applications: {
    clone: {
      action: Action.Clone,
      type: Type.Link,
      url: "/admin/applications/new/${id}",
      button_icon: "clone",
      button_text: "Clone"
    },
    edit: {
      action: Action.Edit,
      type: Type.Link,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/admin/applications/${id}",
      button_icon: "edit",
      button_text: "Edit"
    },
    delete: {
      action: Action.Delete,
      type: Type.Modal,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/applications/${id}",
      button_icon: "trash",
      button_text: "Delete",
      title: "Delete Application",
      text: "This application will be deleted, are you sure?",
      toast: {
        success: Toast.show.applicationsDeleted,
        failure: Toast.show.applicationsNotDeleted
      }
    }
  },
  forms: {
    clone: {
      action: Action.Clone,
      type: Type.Link,
      url: "/admin/forms/new/${id}",
      button_icon: "clone",
      button_text: "Clone"
    },
    edit: {
      action: Action.Edit,
      type: Type.Link,
      url: "/admin/forms/${id}",
      button_icon: "edit",
      button_text: "Edit"
    },
    delete: {
      action: Action.Delete,
      type: Type.Modal,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/forms/${id}",
      button_icon: "trash",
      button_text: "Delete",
      title: "Delete form",
      text: "This form will be deleted, are you sure?",
      toast: {
        success: Toast.show.formsDeleted,
        failure: Toast.show.formsNotDeleted
      },
      show: (form, user) => {
        return form.company_id === user.company_id;
      }
    }
  },
  schedulers: {
    clone: {
      action: Action.Clone,
      type: Type.Link,
      url: "/schedulers/new/${id}",
      button_icon: "clone",
      button_text: "Clone"
    },
    edit: {
      action: Action.Edit,
      type: Type.Link,
      url: "/schedulers/${id}",
      button_icon: "edit",
      button_text: "Edit"
    },
    delete: {
      action: Action.Delete,
      type: Type.Modal,
      // eslint-disable-next-line no-template-curly-in-string
      url: "/schedulers/${id}",
      button_icon: "trash",
      button_text: i18n.t("Delete"),
      title: i18n.t("DeleteScheduler"),
      text: i18n.t("DeleteSchedulerConfirmation"),
      toast: {
        success: Toast.show.scheduleDeleted,
        failure: Toast.show.scheduleNotDeleted
      }
    },
    play: {
      action: Action.Play,
      type: Type.Modal,
      url: "/schedulers/${id}/pause_resume",
      button_icon: "play",
      button_text: i18n.t("Start"),
      title: i18n.t("ResumeTitle"),
      text: i18n.t("ResumeModal"),
      toast: {
        success: Toast.show.schedulerResumed,
        failure: Toast.show.schedulerNotResumed
      }
    },
    pause: {
      action: Action.Pause,
      type: Type.Modal,
      url: "/schedulers/${id}/pause_resume",
      button_icon: "pause",
      button_text: i18n.t("Pause"),
      title: i18n.t("PauseTitle"),
      text: i18n.t("PauseModal"),
      toast: {
        success: Toast.show.schedulerPaused,
        failure: Toast.show.schedulerNotPaused
      }
    }
  },
  resources: {
    view: {
      action: Action.View,
      type: Type.Modal,
      size: "large",
      button_text: i18n.t("ShowScreenshot"),
      title: i18n.t("ShowScreenshot"),
      text: i18n.t(""),
      url: ResourcesImage
    }
  },
  environment_variables: {
    edit: {
      action: Action.Edit,
      itemType: "EnvVariable",
      type: "action",
      title: i18n.t("EditVariable"),
      button_icon: "edit",
      button_text: i18n.t("Edit")
      //url: "/environment_variables/${id}",
    },
    delete: {
      action: Action.Delete,
      type: Type.Modal,
      itemType: "EnvVariable",
      title: i18n.t("DeleteVariable"),
      text: i18n.t("ConfirmDeleteVariable"),
      button_icon: "trash",
      button_text: i18n.t("Delete"),
      toast: {
        success: Toast.show.envVariableDeleted,
        failure: Toast.show.envVariableNotDeleted
      },
      params: ["name", "service_id"],
      url: "/environment_variables/delete"
    },
    find_references: {
      action: Action.Component,
      type: Type.Modal,
      size: "small",
      title: item => item?.name || "",
      button_icon: "search",
      button_text: i18n.t("FindReferences"),
      Component: References
    }
  },
  credentials: {
    edit: {
      action: Action.Edit,
      type: "action",
      button_icon: "edit",
      button_text: i18n.t("Update")
    },
    delete: {
      action: Action.Delete,
      itemType: "Credential",
      type: Type.Modal,
      title: i18n.t("DeleteCredential"),
      text: i18n.t("DeleteCredentialConfirmation"),
      button_icon: "trash",
      button_text: i18n.t("Delete"),
      toast: {
        success: Toast.show.credentialDeleted,
        failure: Toast.show.credentialNotDeleted
      },
      params: ["name", "service_id"],
      url: "/credentials/delete"
    }
  }
};
