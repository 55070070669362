import React from "react";
import i18n from "../i18n";

const ServicesInfo = props => {
  const { service, paramsByType } = props;

  const renderInformationRow = (label, value) => {
    return (
      <div className="row service_info" key={label}>
        <div className="five wide column">
          <strong>{i18n.t(label)}:</strong>
        </div>
        <p className="eleven wide column">{value}</p>
      </div>
    );
  };

  const displayAdditionalRows = () => {
    if (
      Object.keys(paramsByType).includes(service.service_type) &&
      paramsByType[service.service_type].length
    ) {
      return paramsByType[service.service_type].map(param =>
        renderInformationRow(param.label, service[param.value])
      );
    }
    return null;
  };

  return (
    <div>
      <div className="ui two column grid">
        {renderInformationRow(i18n.t("Name"), service.name)}
        {renderInformationRow(i18n.t("Company"), service.company_name)}
        {renderInformationRow(i18n.t("ServiceUrl"), service.service_url)}
        {renderInformationRow(i18n.t("Type"), service.service_type)}
        {displayAdditionalRows()}
      </div>
    </div>
  );
};

export default ServicesInfo;
