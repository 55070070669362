import React from "react";
import i18n from "../i18n";

const ActionButtons = ({ cancelAction, isSubmitting, disabled }) => {
  return (
    <div className="actionButtons">
      <button
        className="ui button blue basic"
        type="button"
        onClick={cancelAction}
      >
        {i18n.t("CancelButton")}
      </button>

      <button
        className={`ui button blue ${isSubmitting ? "loading" : ""}`}
        type="submit"
        disabled={isSubmitting || disabled}
      >
        {i18n.t("SaveButton")}
      </button>
    </div>
  );
};

export default ActionButtons;
