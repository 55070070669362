import React from "react";

const IconButton = props => {
  return (
    <button
      type="button"
      className={`icon-button ${props.type} ${props.className}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <i className={`${props.icon} icon`} />
    </button>
  );
};

IconButton.defaultProps = {
  icon: null,
  type: "primary",
  disabled: false
};

export default IconButton;
