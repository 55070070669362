import React from "react";
import { Route, Switch } from "react-router-dom";
import FormsNew from "./FormsNew";
import FormsEdit from "./FormsEdit";
import FormsTable from "./FormsTable";

const Forms = props => {
  return (
    <Switch>
      <Route
        path="/admin/forms/new/:id?"
        render={routerProps => <FormsNew {...props} {...routerProps} />}
      />
      <Route
        path="/admin/forms/:id"
        render={routerProps => <FormsEdit {...props} {...routerProps} />}
      />
      <Route path="/admin/forms">
        <FormsTable {...props} />
      </Route>
    </Switch>
  );
};

export default Forms;
