import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "proxy-polyfill";

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "../services/store";
import "react-phone-number-input/style.css";
import RoutingContainer from "./RoutingContainer";

export const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <RoutingContainer />
      </Router>
    </Provider>
  );
};

export default App;
