import React from "react";
import { TransitionablePortal, Modal } from "semantic-ui-react";
import i18n from "../../../i18n";

export default function DeleteModal (props) {
    const {modalOpen, toggleModal, title, confirmationText}= props;    
    return(
        <TransitionablePortal
            open={modalOpen}
            transition={{
            animation: "scale",
            duration: 300
            }}
        >
            <Modal open size="tiny" onClose={() => toggleModal(false)}>
                <Modal.Header>
                    <div style={{ textAlign: "center" }}>
                    {title}
                    <i
                        role="button"
                        tabIndex={0}
                        aria-label="close"
                        className="close icon"
                        style={{ float: "right", cursor: "pointer" }}
                        onClick={() => toggleModal(false)}
                    />
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <div 
                    style={{ color: "#606060", textAlign: "center" }}
                    >
                    {confirmationText}
                    </div>
                    <div
                    className="ui text two column grid"
                    style={{ paddingBottom: "15px", paddingTop: "25px" }}
                >
                    <div className="row" style={{ padding: "0.5rem 0" }}>
                        <div className="eight wide column">
                            <button
                            type="button"
                            className="ui button basic blue"
                            style={{ width: "100%" }}
                            onClick={() => toggleModal(false)}
                            >
                            {i18n.t("NoButton")}
                            </button>
                        </div>
                        <div className="eight wide column">
                            <button
                            type="button"
                            className="ui button blue"
                            style={{ width: "100%" }}
                            onClick={() => {
                                props.submitAction()
                                toggleModal(false)
                            }}
                            >
                            {i18n.t("YesButton")}
                            </button>
                        </div>
                    </div>
                </div>
                </Modal.Content>
            </Modal>
        </TransitionablePortal>
    );
}