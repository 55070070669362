import React from "react";
import { Route, Switch } from "react-router-dom";
import EmailsTable from "./EmailsTable";
import EmailsNew from "./EmailsNew";
import EmailsEdit from "./EmailsEdit";

const Emails = props => {
  return (
    <Switch>
      <Route
        path="/admin/emails/new/:id?"
        render={routeProps => <EmailsNew {...props} match={routeProps.match} />}
      />
      <Route
        path="/admin/emails/:id"
        render={routeProps => (
          <EmailsEdit {...props} match={routeProps.match} />
        )}
      />
      <Route
        path="/admin/emails"
        render={routeProps => (
          <EmailsTable {...props} match={routeProps.match} />
        )}
      />
    </Switch>
  );
};

export default Emails;
