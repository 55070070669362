import React from "react";
import ReactFlagsSelect from "react-flags-select";
import i18n from "../i18n";

const languageOptions = { US: i18n.t("English"), DE: i18n.t("German") };

const LanguageSelector = props => {
  return (
    <React.Fragment>
      <ReactFlagsSelect
        className="menu-flags"
        countries={Object.keys(languageOptions)}
        customLabels={languageOptions}
        selected={props.defaultLang}
        onSelect={props.onChange}
        selectedSize={14}
        optionsSize={14}
        placeholder={i18n.t("SelectLanguage")}
        selectButtonClassName="menu-flags-button"
      />
    </React.Fragment>
  );
};

export default LanguageSelector;
