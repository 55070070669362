import React from "react";
import { Icon } from "semantic-ui-react";
import { icon } from "../../services/images";
import { extension, previewSupport } from "../../services/files";
import { fileName } from "../../services/string";

class Attachment extends React.Component {
  fileIcon = () => {
    const ext = extension(this.props.attachment);
    return icon(ext);
  };

  showPreview = () => {
    return previewSupport(this.props.attachment);
  };

  onClickHandler = () => {
    if (this.showPreview()) {
      this.props.setPreviewUrl(this.props.attachment);
    }
  };

  render() {
    const canPreview = this.showPreview() ? "attachmentClickable" : "";
    return (
      <div>
        <span
          className={canPreview}
          onClick={() => this.onClickHandler()}
        >
          <Icon name={this.fileIcon()} />
          <span style={{ fontWeight: "700" }}>{fileName(this.props.attachment)}</span>
        </span>
        <a
          href={this.props.attachment}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon
            name="download"
            style={{ cursor: "pointer", marginLeft: "2px" }}
          />
        </a>
      </div>
    );
  }
}

export default Attachment;
