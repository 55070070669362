import React from "react";
import { debounce, isEqual } from "lodash";
import FormsService from "../../../../services/data_services/forms";
import Table from "../../../Ui/Table/Table";
import { TYPES } from "../../../Ui/Table/types";
import { tableActions } from "../../../Ui/Table/TableActions";
import i18n from "../../../i18n";
import {
  searchParamFromUrl,
  urlFromSearchParam
} from "../../../../services/url";

class FormsTable extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = searchParamFromUrl(this.props.location.search);

    this.state = {
      forms: undefined,
      columns: [
        {
          name: "Title",
          show: true,
          key: "name"
        },
        {
          name: "Description",
          show: true,
          key: "description"
        }
      ],
      queryParams,
      nPages: 0,
      loadingForms: true
    };

    this.setBreadCrumb();

    this.loadForms();
  }

  setBreadCrumb = () => {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Forms"),
        location: "/admin/forms"
      }
    ]);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.props.history.push(`?${urlFromSearchParam(this.state.queryParams)}`);
      this.loadForms();
    }
  }

  setQueryParams = (key, value) => {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  };

  loadForms = debounce(() => {
    this.setState({ loadingForms: true });
    const query = { ...this.state.queryParams };

    FormsService.loadForms(query)
      .then(result => {
        this.setState({
          forms: result.data.results,
          nPages: result.data.total,
          totalItems: result.data.hits
        });
      })
      .catch(() => {
        this.setState({
          forms: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingForms: false });
      });
  }, 500);

  loadItems(entries) {
    if (!entries) {
      this.loadForms();
    } else {
      Object.keys(entries).forEach(key => {
        this.setQueryParams(key, entries[key]);
      });
    }
  }

  reloadForms = () => {
    this.setState(
      {
        forms: null,
        nPages: 0
      },
      this.loadForms()
    );
  };

  render() {
    return (
      <Table
        totalItems={this.state.totalItems}
        tableId={TYPES.FORMS}
        columns={this.state.columns}
        items={this.state.forms}
        noItemsKey="No Forms"
        actions={[
          tableActions.forms.clone,
          tableActions.forms.edit,
          tableActions.forms.delete
        ]}
        filters={this.state.queryParams}
        loadItems={entries => {
          this.loadItems(entries);
        }}
        loadingItems={this.state.loadingForms}
        nPages={this.state.nPages}
        page={this.state.queryParams ? this.state.queryParams.page : 1}
        createAction={() => this.props.history.push("forms/new")}
        reloadAction={this.reloadForms}
      />
    );
  }
}

export default FormsTable;
