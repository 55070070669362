import React from "react";
import { SERVICE_TYPES } from "../../../../constants/service-types";
import Upperbar from "../../../Ui/Upperbar/Upperbar";

const UpperBar = props => {
  const applicationName = _ => {
    return props.application?.name ? props.application.name : "New Application";
  };

  return <Upperbar title={applicationName()} onCancel={props.cancelForm} />;
};

export default UpperBar;
