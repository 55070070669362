import * as Yup from "yup";
import moment from "moment-timezone";
import i18n from "../../i18n";
import store from "../../../services/store";
import { getUTCOffset } from "../../../services/timezones";

import Step1 from "./FormSteps/Step1";
import Step2 from "./FormSteps/Step2";
import Step3 from "./FormSteps/Step3";

class FormConfig {
  constructor(scheduler) {
    this.scheduler = scheduler;
    this.browserTimezone = moment.tz.guess();
    this.userTimezone =
      store.getState().currentUserReducer.user?.timezone ||
      this.browserTimezone;
  }

  _defaultAttribute(attribute, defaultValue) {
    return this.scheduler &&
      this.scheduler[attribute] !== undefined &&
      this.scheduler[attribute] !== null
      ? this.scheduler[attribute]
      : defaultValue;
  }

  _defaultCalendarAttribute(attribute, defaultValue) {
    return this.scheduler &&
      this.scheduler[attribute] !== undefined &&
      this.scheduler[attribute] !== null
      ? this.scheduler[attribute][0]
      : defaultValue;
  }

  /**
   * Return scheduler_processes value transformed from server data to form data
   * @param defaultValue
   * @returns {*}
   * @private
   */
  _defaultAttributeSchedulerProcess(defaultValue) {
    const value = this._defaultAttribute("scheduler_processes", defaultValue);

    value.forEach((process, processIndex) => {
      if (process.data && Array.isArray(process.data)) {
        const newData = {};

        process.data.forEach(item => {
          newData[item.name] = item;
        });
        value[processIndex].data = newData;
      }
    });

    return value;
  }

  _defaultDateTimeAttribute(attribute, defaultValue) {
    const dateTime = this._defaultAttribute(attribute, defaultValue);

    return dateTime
      ? moment(dateTime).tz(this.userTimezone).format()
      : dateTime;
  }

  _defaultTimeAttribute = (attribute, defaultValue) => {
    const time = this._defaultAttribute(attribute, defaultValue);
    return time ? moment(time).format() : null;
  };

  defaultCronTimezone = () => {
    const timezone = this.scheduler
      ? this.scheduler.cron_timezone_description
      : this.userTimezone;
    return getUTCOffset(timezone);
  };

  validateEmailInput = emailString => {
    const emailRegex = /^\S+@\S+\.\S+$/;

    const emailArray = emailString.split(",");
    for (const email of emailArray) {
      if (!email.trim().match(emailRegex)) {
        return false;
      }
    }
    return true;
  };

  minimumStartDateAllowed = value => {
    const currentMoment = moment.tz(this.browserTimezone);
    const selectedStartDate = moment.tz(value, this.browserTimezone);

    if (currentMoment.isSameOrBefore(selectedStartDate)) {
      return currentMoment;
    }

    return selectedStartDate;
  };

  config() {
    const currentMoment = moment.tz(this.browserTimezone).format();

    const applicationsValidationSchema = Yup.object().shape({
      application_id: Yup.string().required(
        i18n.t("ApplicationRequiredValidation")
      ),
      max_resources: Yup.number()
        .required(i18n.t("MaxResourcesRequired"))
        .transform(value => (isNaN(value) ? undefined : value))
        .min(1, i18n.t("MinResources1"))
        .max(100, i18n.t("MaxResources100"))
    });

    const processesValidationSchema = Yup.object().shape({
      service_id: Yup.string().required(i18n.t("ServiceRequiredValidation")),
      process_name: Yup.string().required(i18n.t("ProcessRequiredValidation")),
      max_resources: Yup.number()
        .required(i18n.t("MaxResourcesRequired"))
        .transform(value => (isNaN(value) ? undefined : value))
        .min(1, i18n.t("MinResources1"))
        .max(100, i18n.t("MaxResources100")),
      resource_pools: Yup.string().required(
        i18n.t("ResourcePoolRequiredValidation")
      )
    });

    const genericProcess = Yup.lazy(value => {
      let validations = applicationsValidationSchema;

      if (
        !Object.prototype.hasOwnProperty.call(value, "application_id") &&
        Object.prototype.hasOwnProperty.call(value, "resource_pools")
      ) {
        validations = processesValidationSchema;
      }

      return validations;
    });

    return [
      {
        title: "Scheduler",
        component: Step1,
        fields: [
          {
            name: "name",
            defaultValue: this._defaultAttribute("name", ""),
            validation: Yup.string().required(i18n.t("NameRequiredValidation"))
          },
          {
            name: "processes",
            defaultValue: this._defaultAttributeSchedulerProcess([]),
            validation: Yup.array()
              .of(genericProcess)
              .required("An application or process is required")
          }
        ]
      },
      {
        title: "When",
        component: Step2,
        fields: [
          {
            name: "cron_expression",
            defaultValue: this._defaultAttribute(
              "cron_expression",
              "* * * * *"
            ),
            validation: Yup.string().required(i18n.t("CronRequiredValidation"))
          },
          {
            name: "cron_timezone",
            defaultValue: this.defaultCronTimezone(),
            validation: Yup.string().required(i18n.t("CronRequiredValidation"))
          },
          {
            name: "cron_timezone_description",
            defaultValue: this._defaultAttribute(
              "cron_timezone_description",
              this.userTimezone
            )
          },
          {
            name: "start_date",
            defaultValue: this._defaultDateTimeAttribute(
              "start_date",
              currentMoment
            ),
            validation: Yup.date()
              .required(i18n.t("FirstRunRequiredValidation"))
              .min(
                this.minimumStartDateAllowed(
                  this._defaultDateTimeAttribute("start_date", currentMoment)
                ),
                i18n.t("NoPastDates")
              )
          },
          {
            name: "end_date",
            defaultValue: this._defaultDateTimeAttribute("end_date", undefined),
            validation: Yup.date()
              .required(i18n.t("ExpirationRequiredValidation"))
              .when("start_date", (field, schema) => {
                if (field) {
                  return schema
                    .required(i18n.t("ExpirationRequiredValidation"))
                    .min(field, i18n.t("LastRunLater"));
                }
                return schema;
              })
          },
          {
            name: "bp_calendar",
            defaultValue: this._defaultCalendarAttribute(
              "bp_calendar",
              undefined
            )
          },
          {
            name: "between_from",
            defaultValue: this._defaultTimeAttribute("between_from", null),
            validation: Yup.date()
              .nullable()
              .when("between_to", (field, schema) => {
                if (field) {
                  return schema
                    .required(i18n.t("BetweenFromIsRequired"))
                    .max(field, i18n.t("BetweenFromIsGreater"));
                }
                return schema;
              })
          },
          {
            name: "between_to",
            defaultValue: this._defaultTimeAttribute("between_to", null),
            validation: Yup.string()
              .nullable()
              .when("between_from", (field, schema) => {
                if (field) {
                  return schema.required(i18n.t("BetweenToIsRequired"));
                }
                return schema;
              })
          },
          {
            name: "deadline_activated",
            defaultValue: this._defaultAttribute("deadline_activated", true)
          },
          {
            name: "deadline",
            defaultValue: this._defaultAttribute("deadline", 5),
            validation: Yup.number().when("deadline_activated", {
              is: true,
              then: Yup.number()
                .required(i18n.t("DeadlineRequiredValidation"))
                .min(5, i18n.t("Timeout cannot be shorter than 5 minutes"))
            })
          },
          {
            name: "deadline_type",
            defaultValue: this._defaultAttribute("deadline_type", undefined),
            validation: Yup.string()
              .required(i18n.t("NotificationRequiredValidation"))
              .transform(value => (!value ? "" : value))
          },
          {
            name: "deadline_notification_to",
            defaultValue: this._defaultAttribute(
              "deadline_notification_to",
              undefined,
              this._defaultAttribute("deadline_type")
            ),
            validation: Yup.string()
              .transform(value => (!value ? "" : value))
              .when("deadline_type", (field, schema) => {
                switch (field) {
                  case "other":
                    return schema
                      .required(i18n.t("EmailRequired"))
                      .test(value => {
                        if (value) {
                          return this.validateEmailInput(value);
                        }
                        return false;
                      });
                  case "team(s)":
                    return schema.required(i18n.t("TeamRequired"));
                  default:
                    return schema.nullable();
                }
              })
          }
        ],
        noSortEdges: [["between_from", "between_to"]]
      },
      {
        title: "SlaPriority",
        component: Step3,
        fields: [
          {
            name: "sla_activated",
            defaultValue: this._defaultAttribute("sla_activated", true)
          },
          {
            name: "sla_default",
            defaultValue: this._defaultAttribute("sla_default", true)
          },
          {
            name: "sla",
            defaultValue: this._defaultAttribute("sla", 1440),
            validation: Yup.number().when("sla_activated", {
              is: true,
              then: Yup.number()
                .required(i18n.t("SLA is required"))
                .min(5, "SLA cannot be shorter than 5 minutes")
            })
          },
          {
            name: "sla_action",
            defaultValue: this._defaultAttribute("sla_action", "Notification"),
            validation: Yup.string().required(
              i18n.t("SlaActionRequiredValidation")
            )
          },
          {
            name: "sla_type",
            defaultValue: this._defaultAttribute("sla_type", undefined),
            validation: Yup.string()
              .transform(value => (!value ? "" : value))
              .required(i18n.t("NotificationRequiredValidation"))
          },
          {
            name: "sla_notification_to",
            defaultValue: this._defaultAttribute(
              "sla_notification_to",
              undefined,
              this._defaultAttribute("sla_type")
            ),
            validation: Yup.string()
              .transform(value => (!value ? "" : value))
              .when("sla_type", (field, schema) => {
                switch (field) {
                  case "other":
                    return schema
                      .required("At least one email is required.")
                      .test(value => {
                        if (value) {
                          return this.validateEmailInput(value);
                        }
                        return false;
                      });
                  case "team(s)":
                    return schema.required("A team is required.");
                  default:
                    return schema.nullable();
                }
              })
          },
          {
            name: "priority",
            defaultValue: this._defaultAttribute("priority", undefined),
            validation: Yup.string()
              .required(i18n.t("PriorityRequiredValidation"))
              .matches(/^[0-4]$/, i18n.t("PriorityRequiredValidation"))
          }
        ]
      }
    ];
  }
}

export default FormConfig;
