import React from "react";
import { Route, Switch } from "react-router-dom";
import AlertsTable from "./AlertsTable";

const Alerts = props => {
  return (
    <Switch>
      <Route
        path="/alerts"
        render={routerProps => <AlertsTable {...props} {...routerProps} />}
      />
    </Switch>
  );
};

export default Alerts;
