import { LOAD_COLUMNS } from "../constants/action-types";

const initialState = {};

const columnOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COLUMNS:
      return {
        ...state,
        columnOrder: action.payload
      };
    default:
      return state;
  }
};

export default columnOrderReducer;
