import React, { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import EnvVariableService from "../../../../services/data_services/env_variable";
import i18n from "../../../i18n";
import Spinner from "../../../Ui/Spinner";
import ReferenceTable from "./ReferenceTable";

const References = ({ item }) => {
  const [references, setReferences] = useState();
  const [nPages, setNPages] = useState(1);
  const [searchParams, setSearchParams] = useState({
    page: 1
  });

  useEffect(() => {
    loadReferences();
  }, [searchParams]);

  const loadReferences = () => {
    const params = {
      ...searchParams,
      name: item.name,
      service_id: item.service_id
    };
    EnvVariableService.loadReferences(params)
      .then(({ data, status }) => {
        if (status === 204) {
          setReferences([]);
        } else {
          setReferences(data.results);
          setNPages(data.total);
        }
      })
      .catch(() => {
        setReferences([]);
        setNPages(1);
      });
  };

  const updateSearchParams = entries => {
    setSearchParams(prevParams => {
      const auxSearchParams = cloneDeep(prevParams);
      Object.keys(entries).forEach(key => {
        auxSearchParams[key] = entries[key];
      });
      return auxSearchParams;
    });
  };

  const renderContent = () => {
    if (!references) return <Spinner />;
    if (references.length === 0) return i18n.t("NoReferences");
    return (
      <ReferenceTable
        references={references}
        searchParams={searchParams}
        nPages={nPages}
        onChange={updateSearchParams}
      />
    );
  };
  return <div className="references-container">{renderContent()}</div>;
};

export default References;
