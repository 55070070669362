import React from "react";
import { Route, Switch } from "react-router-dom";
import TeamsTable from "./TeamsTable";
import TeamsEdit from "./TeamsEdit";
import TeamsNew from "./TeamsNew";

const Teams = props => {
  return (
    <Switch>
      <Route
        path="/admin/teams/new/:id?"
        render={routerProps => <TeamsNew {...props} {...routerProps} />}
      />
      <Route
        path="/admin/teams/:id"
        render={routerProps => <TeamsEdit {...props} {...routerProps} />}
      />
      <Route
        path="/admin/teams"
        render={routerProps => <TeamsTable {...props} {...routerProps} />}
      />
    </Switch>
  );
};
export default Teams;
