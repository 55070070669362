import React, { useState, useEffect, useCallback } from "react";
import { debounce, isEmpty } from "lodash";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import GraphColorPicker from "./GraphColorPicker";
import EmptyChart from "../../../../Dashboard/EmptyChart";

import i18n from "../../../../i18n";
import WidgetService from "../../../../../services/data_services/widget";
import { chartTypeMapping } from "../../../../../constants/widgets";
import { formatChart } from "../../../../../services/widgets";

import "stylesheets/Dashboard/Widget.scss";

const GraphPreview = props => {
  const {
    procedure,
    service_id,
    data,
    chart_type,
    name,
    options
  } = props.values;
  const { parameters, colorList } = props;
  const [graphData, setGraphData] = useState(undefined);
  const [labelsList, setLabelsList] = useState([]);
  const [previewReady, setPreviewReady] = useState(false);
  const dateDisplayFormat = props.user.dateformat ?? "DD-MM-YYYY";

  useEffect(() => {
    if (previewReady) setPreviewReady(false);
    if (
      [procedure, data, service_id, chart_type, parameters].includes(undefined)
    ) {
      setGraphData(undefined);
    } else {
      const requestData = formatParametersForPreview();

      if (
        requestData.length > 0 ||
        (requestData.length === 0 && parameters?.length === 0)
      ) {
        loadPreview(requestData);
      }
    }
  }, [procedure, data, service_id, chart_type, options, parameters]);

  const formatParametersForPreview = () => {
    const dataCollection = Object.entries(data);
    const nonEmptyParams = dataCollection.filter(
      ([, parameter_value]) => parameter_value
    );
    if (nonEmptyParams.length === 0) return [];

    const requestData = nonEmptyParams.map(
      ([parameter_name, parameter_value]) => {
        const parameter_type = parameters.find(
          param => param.parameter_name === parameter_name
        )?.parameter_type;

        const parameterData = {
          parameter_name,
          parameter_value,
          parameter_type
        };
        return parameterData;
      }
    );
    return requestData;
  };

  const loadPreview = useCallback(
    debounce(requestData => {
      const stringifiedRequestData = JSON.stringify(requestData);

      WidgetService.loadPreview(
        procedure,
        service_id,
        chart_type,
        stringifiedRequestData
      ).then(res => {
        const formattedData = formatChart(
          res.data[0].chart_data,
          dateDisplayFormat,
          chart_type
        );
        getColumns(formattedData);
        setGraphData(formattedData);
      });
    }, 500),
    [procedure, service_id, chart_type]
  );

  const getColumns = colData => {
    const columnsList = [];
    if (
      Array.isArray(colData) &&
      colData.length > 0 &&
      chart_type !== "table"
    ) {
      if (["piechart", "timeline"].includes(chart_type)) {
        for (let i = 1; i < colData.length; i++) {
          columnsList.push(colData[i][0]);
        }
      } else {
        for (let i = 1; i < colData[0].length; i++) {
          columnsList.push(colData[0][i]);
        }
      }
    }
    setLabelsList(columnsList);
  };

  const renderLoading = () => {
    return (
      <div
        className="ui active inverted dimmer"
        style={{ minHeight: "100px", position: "relative" }}
      >
        <div className="ui text loader">{i18n.t("Loading")}</div>
      </div>
    );
  };

  const chartWidth =
    window.innerWidth < 400 ? `${window.innerWidth}px` : "100%";

  const chart = (
    <Chart
      width={chartWidth}
      height="350px"
      chartType={chartTypeMapping(chart_type)}
      loader={renderLoading()}
      data={graphData}
      options={{
        ...options,
        title: name,
        legend: "none",
        colors:
          colorList.length > 0
            ? colorList
            : ["#6fcf97", "#ffae8b", "#ee5465", "#56ccf2", "#f8ae57"]
      }}
      chartEvents={[
        { eventName: "ready", callback: () => setPreviewReady(true) }
      ]}
    />
  );

  const renderPreview = () => {
    if (!chart_type || !procedure) {
      return <div>{i18n.t("SelectChartProcedure")}</div>;
    }

    if (isEmpty(graphData)) {
      return <EmptyChart title={name} />;
    }

    return chart;
  };

  return (
    <div>
      <h3 className="ui header" style={{ textAlign: "center" }}>
        {i18n.t("Preview")}
      </h3>
      <div className="graph-preview">
        {previewReady && ["timeline", "table"].includes(chart_type) && (
          <div className="graph-title preview">{name}</div>
        )}
        <div className="chart-container">{renderPreview()}</div>
        {chart_type && !isEmpty(graphData) && (
          <GraphColorPicker
            colorList={colorList}
            labelsList={labelsList}
            onChange={props.onChange}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(GraphPreview);
