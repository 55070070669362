import axios from "../axios";

class UserService {
  static loadUsers = (params = null) => {
    return axios.get("/users", {
      params
    });
  };

  static loadUser = id => {
    return axios.get(`/users/${id}`);
  };
}

export default UserService;
