import React from "react";
import { Redirect } from "react-router";
import FormsBuilder from "./FormsBuilder";
import i18n from "../../../i18n";
import FormsService from "../../../../services/data_services/forms";
import Toast from "../../../../services/toasts";
import WithLoadingComponent from "../../../Ui/WithLoadingComponent";
import { checkAndRedirect } from "../../../../services/axios";

class FormsNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      returnToForms: false,
      formData: undefined
    };

    this.setBreadCrumb();

    if (this.props.match.params.id) this.loadForm();

    this.returnToForms = this.returnToForms.bind(this);
  }

  returnToForms = () => {
    this.setState({
      returnToForms: true
    });
  };

  setBreadCrumb = () => {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Forms"),
        location: "/admin/forms"
      },
      {
        title: this.title(),
        location: "/admin/forms/new"
      }
    ]);
  };

  title = () => {
    return "New Form".concat(
      this.state.formData ? ` - ${this.state.formData.name}` : ""
    );
  };

  loadForm = () => {
    FormsService.loadForm(this.props.match.params.id)
      .then(result => {
        this.setState(
          {
            formData: result.data
          },
          () => {
            this.setBreadCrumb();
          }
        );
      })
      .catch(checkAndRedirect);
  };

  submitForm = values => {
    FormsService.createForm(values)
      .then(() => {
        Toast.show.formsCreated();
        this.returnToForms();
      })
      .catch(() => {
        Toast.show.formsNotCreated();
      });
  };

  render() {
    if (this.state.returnToForms) {
      return <Redirect push to="/admin/forms" />;
    }

    return (
      <WithLoadingComponent
        loadingCondition={() =>
          this.props.match.params.id && !this.state.formData}
        contentCondition={() =>
          (this.props.match.params.id && this.state.formData.id) ||
          !this.props.match.params.id
        }
        noItemsKey="NotAbleLoadForm"
      >
        <div className="ui container">
          <FormsBuilder
            formData={this.state.formData}
            submit={this.submitForm}
            returnToForms={this.returnToForms}
          />
        </div>
      </WithLoadingComponent>
    );
  }
}

export default FormsNew;
