import moment from "moment-timezone";
import store from "./store";
import i18n from "../components/i18n";
import { getDateFormat } from "./timezones";

export const capitalizeFirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const userName = user => {
  if (user && Object.prototype.hasOwnProperty.call(user, "name")) {
    return user.name || user.email.split("@")[0];
  }
  return "";
};
export const teamNames = (teams = []) => {
  return teams && teams instanceof Array && teams.length > 0
    ? teams
        .map(team => {
          return capitalizeFirst(team.description);
        })
        .join(", ")
    : i18n.t("NoTeams");
};

export const rolesNames = (roles = []) => {
  return roles && roles instanceof Array && roles.length > 0
    ? roles
        .map(role => {
          return i18n.t(role);
        })
        .join(", ")
    : i18n.t("NoRoles");
};

export const applicationNames = applications => {
  return applications &&
    applications instanceof Array &&
    applications.length > 0
    ? applications.map(application => application.name).join(", ")
    : i18n.t("NoApplications");
};

export const joinArrayToString = elements => {
  return elements && elements instanceof Array ? elements.join(", ") : "-";
};

export const activityDate = date => {
  if (!date) {
    return "";
  }
  const userTimezone =
    store.getState().currentUserReducer.user?.timezone || moment.tz.guess();
  const utcDate = moment.utc(date).format();
  const timeFormattedDate = moment(utcDate).tz(userTimezone);
  return moment(timeFormattedDate).isValid()
    ? timeFormattedDate.format("MMMM D, YYYY - h:mm A")
    : "";
};

export const commentDate = date => {
  return moment(date).format("MMMM D, YYYY - h:mm A");
};

export const fileName = url => {
  let name = "";

  if (typeof url === "object") {
    name = url.name;
  } else if (typeof url === "string") {
    name = url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("?"));
  }

  return decodeURI(name);
};

export const displayPassword = () => "•••••••••";

export const displayDate = value => {
  const userDateFormat = getDateFormat(
    store.getState().currentUserReducer.user?.timezone || moment.tz.guess()
  );
  const parsedDate = moment(value);
  return parsedDate.isValid() ? parsedDate.format(userDateFormat) : "";
};

export const displayFileName = name => {
  const S3_LINK = "https://ww-raasportal.s3.eu-central";
  if (!name || typeof name !== "string") return name;
  if (name.includes(S3_LINK)) return fileName(name);
  return name;
};
