import React from "react";
import { debounce } from "lodash";
import { Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileCode } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../../Pagination";
import SearchBox from "../../../Ui/SearchBox";
import i18n from "../../../i18n";
import MobileTable from "../../../Ui/Table/Mobile/MobileTable";
import { isDesktop } from "../../../../services/general";

// const REFERENCE_COLUMNS = [
//   { name: "", key: "ProcessType", width: "5%" },
//   { name: i18n.t("ProcessName"), key: "name", width: "30%" },
//   { name: i18n.t("Description"), key: "description", width: "65%" }
// ];

const PROCESS_TYPES = {
  O: { icon: faFileCode, name: i18n.t("Object") },
  P: { icon: faFile, name: i18n.t("Process") }
};

const DEFAULT_PROCESS_TYPE = PROCESS_TYPES.P;

const ReferenceTable = ({ references, nPages, searchParams, onChange }) => {
  const renderProcessType = type => {
    const processType = PROCESS_TYPES[type] || DEFAULT_PROCESS_TYPE;
    const icon = (
      <FontAwesomeIcon icon={processType.icon} className="ref-icon" />
    );
    return <Popup inverted trigger={icon} content={processType.name} />;
  };

  const renderCellContent = (item, key) => {
    if (key === "ProcessType") return renderProcessType(item.ProcessType);
    return item[key];
  };

  const REFERENCE_COLUMNS = [
    { name: "", key: "ProcessType", width: "5%", method: renderProcessType },
    { name: i18n.t("ProcessName"), key: "name", width: "30%" },
    { name: i18n.t("Description"), key: "description", width: "65%" }
  ];

  return (
    <React.Fragment>
      <div className="references-searchbar">
        {isDesktop() && (
          <SearchBox
            value={searchParams.process_name}
            loadItems={debounce(
              value =>
                onChange({
                  page: 1,
                  process_name: value
                }),
              500
            )}
          />
        )}
      </div>
      {isDesktop() ? (
        <table className="references-table">
          <thead>
            <tr>
              {REFERENCE_COLUMNS.map(column => {
                return (
                  <th key={column.name} style={{ width: `${column.width}` }}>
                    {column.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {references.map(reference => {
              return (
                <tr key={reference.name}>
                  {REFERENCE_COLUMNS.map(column => {
                    return (
                      <td key={`${reference.name}_${column.name}`}>
                        {renderCellContent(reference, column.key)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <MobileTable
          items={references}
          columns={REFERENCE_COLUMNS}
          loadItems={item => onChange(item)}
          filters={searchParams}
          mobileColumns={{ status: "ProcessType" }}
        />
      )}
      <Pagination
        nPages={nPages}
        page={searchParams.page}
        onChange={page => onChange({ page })}
      />
    </React.Fragment>
  );
};

export default ReferenceTable;
