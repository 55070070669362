import React from "react";
import { Route, Switch } from "react-router-dom";
import EnvVariablesTable from "./EnvVariablesTable";

const EnvVariables = props => {
  return (
    <Switch>
      <Route
        path="/env_variables"
        render={routerProps => (
          <EnvVariablesTable {...props} {...routerProps} />
        )}
      />
    </Switch>
  );
};

export default EnvVariables;
