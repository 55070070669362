import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import i18n from "../i18n";
import { tabActive } from "../../services/url";
import SchedulerMonitor from "./SchedulerMonitor/SchedulerMonitor";
import Schedulers from "./Schedulers/Schedulers";

class SchedulerMain extends React.Component {
  active = name => {
    return tabActive(this.props.location.pathname, name);
  };

  managePermission = view => {
    const main = {
      url: this.props.location.pathname,
      visible:
        this.props.location.pathname === "/schedulers"
          ? this.schedulersVisible()
          : this.schedulerMonitorVisible()
    };

    const redirect = {
      url:
        this.props.location.pathname === "/schedulers"
          ? "/scheduler_monitor"
          : "/schedulers",
      visible:
        this.props.location.pathname === "/schedulers"
          ? this.schedulerMonitorVisible()
          : this.schedulersVisible()
    };

    if (!main.visible) {
      if (redirect.visible) {
        return <Redirect to={redirect.url} />;
      }
      return <Redirect to="/" />;
    }

    return view;
  };

  tabNameBasedOnPathName() {
    return tabNameBasedOnPathName(this.props.location.pathname);
  }

  schedulerMonitorVisible() {
    return this.props.user.teams.some(team => {
      return team.monitor;
    });
  }

  schedulersVisible() {
    return this.props.user.teams.some(team => {
      return team.scheduling;
    });
  }

  renderSchedulerMonitorTab() {
    if (!this.schedulerMonitorVisible()) {
      return null;
    }

    return (
      <a
        className={`item ${this.active("scheduler_monitor")}`}
        data-tab="scheduler_monitor"
        role="button"
        aria-label={i18n.t("scheduler_monitor")}
        tabIndex={0}
        onClick={() => {
          this.props.history.push("/scheduler_monitor");
        }}
      >
        {i18n.t("SchedulerMonitor")}
      </a>
    );
  }

  renderSchedulersTab() {
    if (!this.schedulersVisible()) {
      return null;
    }

    return (
      <a
        className={`item ${this.active("schedulers")}`}
        data-tab="schedulers"
        role="button"
        aria-label={i18n.t("schedulers")}
        tabIndex={0}
        onClick={() => {
          this.props.history.push("/schedulers");
        }}
      >
        {i18n.t("Schedulers")}
      </a>
    );
  }

  renderSchedulerMonitor(routerProps) {
    if (!this.schedulerMonitorVisible()) {
      return null;
    }

    return (
      <div
        className={`ui tab ${this.active("scheduler_monitor")}`}
        data-tab="scheduler_monitor"
      >
        <SchedulerMonitor {...this.props} {...routerProps} />
      </div>
    );
  }

  renderSchedulers(routerProps) {
    if (!this.schedulersVisible()) {
      return null;
    }

    return (
      <div
        className={`ui tab ${this.active("schedulers")}`}
        data-tab="schedulers"
      >
        <Schedulers {...this.props} {...routerProps} />
      </div>
    );
  }

  render() {
    return this.managePermission(
      <>
        <div id="customMenu" className="ui secondary pointing menu">
          {this.renderSchedulerMonitorTab()}
          {this.renderSchedulersTab()}
        </div>
        <div style={{ marginTop: "38px" }}>
          <div className="component-wrapper">
            <Switch>
              <Route
                path="/scheduler_monitor"
                render={routerProps => this.renderSchedulerMonitor(routerProps)}
              />
              <Route
                path="/schedulers"
                render={routerProps => this.renderSchedulers(routerProps)}
              />
            </Switch>
          </div>
        </div>
      </>
    );
  }
}

export default SchedulerMain;
