import React, { useState, useRef, useEffect } from "react";
import { debounce } from "lodash";
import TableSearchbar from "./TableSearchbar";
import TableFilter from "../TableFilter";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import CurrentPageInfo from "../CurrentPageInfo";
import Pagination from "../../../Pagination";
import PerPageSelector from "../PerPageSelector";

const MIN_CELL_WIDTH = "125px";
const CHECKBOX_CELL_WIDTH = "40px";

export default function DesktopTable(props) {
  const visibleColumns = props.columns.filter(column => column.show);
  const defaultColumnsWidth = visibleColumns.map(
    column => column.width ?? "1fr"
  );
  const [columnWidths, setColumnWidths] = useState(defaultColumnsWidth);
  const tableRef = useRef();
  const tableWrapperRef = useRef();

  useEffect(() => {
    updateNewColumnWidths();
  }, [props.visibleColumnsLength]);

  const updateNewColumnWidths = () => {
    const newVisibleColumns = props.columns.filter(column => column.show);
    const newColumnsWidths = newVisibleColumns.map(
      column => column.width ?? "1fr"
    );
    setColumnWidths(newColumnsWidths);
  };

  const onColumnWidthChange = newColumnWidths => {
    const percentages = newColumnWidths.map(width =>
      transformWidthToPercentages(width)
    );
    setColumnWidths(percentages);
    updateUserSettings(percentages);
  };

  const transformWidthToPercentages = width => {
    const tableWidth = tableRef.current?.offsetWidth;
    const widthInPixels = parseInt(width, 0);
    const widthInPercetage = (widthInPixels / tableWidth) * 100;
    const truncatedPercentage = widthInPercetage.toFixed(2);
    return `${truncatedPercentage}%`;
  };

  const applyMinimumCellSize = widthsArray =>
    widthsArray.map(width =>
      width.includes("fr") ? width : `max(${MIN_CELL_WIDTH}, ${width})`
    );

  const convertWidthsArrayToStyleProperty = widthsArray => {
    const gridTemplateColumns = widthsArray.join(" ");
    return gridTemplateColumns;
  };

  const appendSelectAndMenuColumnWidths = newColumnWidths => {
    const checkboxCellIsPresent = props.onSelectAllClick;

    const allColumnWidths = [...newColumnWidths];
    if (checkboxCellIsPresent) {
      allColumnWidths.unshift(CHECKBOX_CELL_WIDTH);
    }
    return allColumnWidths;
  };

  const updateUserSettings = debounce(widthsArray => {
    const visibleColumnsWithWidth = visibleColumns.map((column, index) => ({
      ...column,
      width: widthsArray[index]
    }));
    const notVisibleColumns = props.columns.filter(column => !column.show);
    const allColumns = [...visibleColumnsWithWidth, ...notVisibleColumns];
    props.setColumns(allColumns, true);
  }, 1000);

  const columnsWidthWithMinimum = applyMinimumCellSize(columnWidths);
  const allColumnWidths = appendSelectAndMenuColumnWidths(
    columnsWidthWithMinimum
  );
  const gridTemplateColumnsStyle = convertWidthsArrayToStyleProperty(
    allColumnWidths
  );
  const tableStyle = {
    gridTemplateColumns: gridTemplateColumnsStyle
  };

  return (
    <React.Fragment>
      <TableSearchbar
        activitiesTable={props.activitiesTable}
        component={props.component}
        createAction={props.createAction}
        customSearch={props.customSearch}
        deleteAction={props.deleteAction}
        deleteDisabled={props.deleteDisabled}
        displayDelete={props.displayDelete}
        displayRetry={props.displayRetry}
        exportAction={props.exportAction}
        exportDisabled={props.exportDisabled}
        filters={props.filters}
        loadItems={props.loadItems}
        quickSearchParam={props.quickSearchParam}
        reloadAction={props.reloadAction}
        retryAction={props.retryAction}
        retryDisabled={props.retryDisabled}
        toggleFilter={props.toggleSearch}
        toggleColumns={props.toggleOpenFilter}
      />
      <div className="desktop-table-container" ref={tableWrapperRef}>
        <TableFilter
          columns={props.columns}
          visible={props.openFilter}
          setColumns={props.setColumns}
          toggle={props.toggleOpenFilter}
        />
        <table
          ref={tableRef}
          className="ui very basic table tableContainer"
          style={tableStyle}
          role="grid"
        >
          <TableHead
            selectingAll={props.selectingAll}
            loadingItems={props.loadingItems}
            onSelectAllClick={props.onSelectAllClick}
            selectAllChecked={props.selectAllChecked}
            columns={visibleColumns}
            toggleOpenFilter={props.toggleOpenFilter}
            orderBy={props.orderBy}
            toggleEditField={props.toggleEditField}
            editField={props.editField}
            clearEditedRow={props.clearEditedRow}
            editedRows={props.editedRows}
            updateItems={props.updateItems}
            loadItems={props.loadItems}
            user={props.user}
            filters={props.filters}
            onColumnWidthChange={onColumnWidthChange}
            actions={props.actions}
          />
          <TableBody
            tableRef={tableWrapperRef}
            selectingAll={props.selectingAll}
            selectAllChecked={props.selectAllChecked}
            onRowClick={props.onRowClick}
            noItemsKey={props.noItemsKey}
            onCheckboxChange={props.onCheckboxChange}
            selectedElementIds={props.selectedElementIds}
            columns={visibleColumns}
            items={props.items}
            actions={props.actions}
            openModal={props.openModal}
            editField={props.editField}
            addEditedRow={props.addEditedRow}
            tableId={props.tableId}
            selectItem={props.selectItem}
            selectedItem={props.selectedItem}
            editItem={props.editItem}
            toggleEditRow={props.toggleEditRow}
            editingRow={props.editingRow}
            onEditedRowChange={props.onEditedRowChange}
            onSubmitEdit={props.onSubmitEdit}
            loading={props.loading}
            loadingItems={props.loadingItems}
            editing={props.editing}
            handleEdit={props.handleEdit}
          />
        </table>
      </div>
      {props.items?.length > 0 && (
        <div className="d-flex-center-sb">
          <CurrentPageInfo
            page={props.filters.page}
            perPage={props.filters.per_page}
            totalItems={props.totalItems}
          />

          <Pagination
            nPages={props.nPages}
            page={props.page}
            onChange={page => {
              props.loadItems({ page });
            }}
          />
          <PerPageSelector
            filters={props.filters}
            loadItems={props.loadItems}
          />
        </div>
      )}
    </React.Fragment>
  );
}
