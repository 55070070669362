import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ClickOut from "react-click-out";

class Sorter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
  }

  onDragEnd = result => {
    const { destination, source } = result;

    // User cancel drop
    if (!destination) {
      return;
    }

    // User drop item to the same location
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newFields = Array.from(this.props.sections);
    const element = newFields.splice(source.index, 1);
    newFields.splice(destination.index, 0, element[0]);

    this.props.setNewOrder(newFields);
  };

  toggle = () => {
    this.setState({ visible: !this.state.visible });
  };

  renderSorter() {
    if (this.state.visible) {
      return (
        <ClickOut onClickOut={this.toggle}>
          <div className="sorter-container">
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="menu_1">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {this.props.sections.map((section, index) => (
                      <Draggable
                        key={section.id}
                        index={index}
                        draggableId={`section-sorter-${section.id}`}
                      >
                        {provided => (
                          <div
                            className="sorter-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {section.name}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </ClickOut>
      );
    }
  }

  render() {
    return (
      <div className="sorter">
        <button
          className="ui mini button icon basic"
          type="button"
          onClick={_ => this.toggle()}
          aria-label="Toggle sorter"
        >
          <i className="icon cog" />
        </button>
        {this.renderSorter()}
      </div>
    );
  }
}

export default Sorter;
