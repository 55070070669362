import React from "react";
import TableFilter from "../Ui/Table/TableFilter";

class LaunchFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disableFilter: false,
      visibleFilter: false
    };
  }

  columns = () => {
    return this.props.applications
      ? this.props.applications.map(application => {
          return {
            name: application.name,
            show: application.show,
            key: `${application.id}`
          };
        })
      : [];
  };

  disabledButton = () => {
    return this.state.disableFilter || !this.props.applications
      ? "disabled"
      : "";
  };

  toggleFilter = () => {
    this.setState(prevState => ({ visibleFilter: !prevState.visibleFilter }));
  };

  visible = () => {
    return this.state.disableFilter ? false : this.state.visibleFilter;
  };

  render() {
    return (
      <>
        <button
          disabled={this.props.disabled}
          type="button"
          className={`ui square button ${this.disabledButton()}`}
          onClick={this.toggleFilter}
        >
          <i className="icon cog" />
        </button>

        <TableFilter
          toggle={this.toggleFilter}
          columns={this.columns()}
          visible={this.visible()}
          setColumns={a => {
            this.props.setApplicationsToShow(
              a
                .filter(application => application.show)
                .map(application => application.key),
              this.props.applications
            );
          }}
          style={this.props.customStyle}
        />
      </>
    );
  }
}

export default LaunchFilter;
