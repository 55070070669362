import i18n from "../components/i18n";

export const chartHasAxis = type => {
  return (
    type &&
    [
      "areachart",
      "barchart",
      "bubblechart",
      "combochart",
      "linechart"
    ].includes(type)
  );
};

export const chartColumnWidth = size => {
  switch (size.toLowerCase()) {
    case "s":
      return "four";
    case "m":
      return "eight";
    case "l":
      return "twelve";
    case "xl":
      return "sixteen";
    default:
      return "four";
  }
};


export const chartTypeMapping = type => {
  switch (type) {
    case "areachart":
      return "AreaChart";
    case "hbar":
      return "BarChart";
    case "bar":
      return "BarChart";
    case "vbar":
      return "ColumnChart";
    case "bubblechart":
      return "BubbleChart";
    case "calendar":
      return "Calendar";
    case "combochart":
      return "ComboChart";
    case "gantt":
      return "Gantt";
    case "geochart":
      return "GeoChart";
    case "histogram":
      return "Histogram";
    case "line":
      return "LineChart";
    case "piechart":
      return "PieChart";
    case "timeline":
      return "Timeline";
    case "table":
      return "Table";
    default:
      return type;
  }
};

export const CHART_TYPES = [
  { label: i18n.t("AreaChart"), value: "areachart" },
  { label: i18n.t("HorizontalBarChart"), value: "hbar" },
  { label: i18n.t("VerticalBarChart"), value: "vbar" },
  { label: i18n.t("BubbleChart"), value: "bubblechart" },
  { label: i18n.t("CalendarChart"), value: "calendar" },
  { label: i18n.t("ComboChart"), value: "combochart" },
  { label: i18n.t("GanttChart"), value: "gantt" },
  { label: i18n.t("GeoChart"), value: "geochart" },
  { label: i18n.t("HistogramChart"), value: "histogram" },
  { label: i18n.t("LineChart"), value: "line" },
  { label: i18n.t("PieChart"), value: "piechart" },
  { label: i18n.t("TimelinesChart"), value: "timeline" },
  { label: i18n.t("TableChart"), value: "table" }
];

export const previewData = type => {
  const mappedType = chartTypeMapping(type);

  switch (mappedType) {
    case "AreaChart":
      return [
        ["Year", "Sales", "Expenses"],
        ["2013", 1000, 400],
        ["2014", 1170, 460],
        ["2015", 660, 1120],
        ["2016", 1030, 540]
      ];
    case "BarChart":
      return [
        ["City", "2010 Population", "2000 Population"],
        ["New York City, NY", 8175000, 8008000],
        ["Los Angeles, CA", 3792000, 3694000],
        ["Chicago, IL", 2695000, 2896000],
        ["Houston, TX", 2099000, 1953000],
        ["Philadelphia, PA", 1526000, 1517000]
      ];
    case "BubbleChart":
      return [
        ["ID", "Life Expectancy", "Fertility Rate", "Region", "Population"],
        ["CAN", 80.66, 1.67, "North America", 33739900],
        ["DEU", 79.84, 1.36, "Europe", 81902307],
        ["DNK", 78.6, 1.84, "Europe", 5523095],
        ["EGY", 72.73, 2.78, "Middle East", 79716203],
        ["GBR", 80.05, 2, "Europe", 61801570],
        ["IRN", 72.49, 1.7, "Middle East", 73137148],
        ["IRQ", 68.09, 4.77, "Middle East", 31090763],
        ["ISR", 81.55, 2.96, "Middle East", 7485600],
        ["RUS", 68.6, 1.54, "Europe", 141850000],
        ["USA", 78.09, 2.05, "North America", 307007000]
      ];
    case "Calendar":
      return [
        [
          { type: "date", id: "Date" },
          { type: "number", id: "Won/Loss" }
        ],
        [new Date(2012, 3, 13), 37032],
        [new Date(2012, 3, 14), 38024],
        [new Date(2012, 3, 15), 38024],
        [new Date(2012, 3, 16), 38108],
        [new Date(2012, 3, 17), 38229],
        [new Date(2013, 1, 4), 38177],
        [new Date(2013, 1, 5), 38705],
        [new Date(2013, 1, 12), 38210],
        [new Date(2013, 1, 13), 38029],
        [new Date(2013, 1, 19), 38823],
        [new Date(2013, 1, 23), 38345],
        [new Date(2013, 1, 24), 38436],
        [new Date(2013, 2, 10), 38447]
      ];
    case "ComboChart":
      return [
        [
          "Month",
          "Bolivia",
          "Ecuador",
          "Madagascar",
          "Papua New Guinea",
          "Rwanda",
          "Average"
        ],
        ["2004/05", 165, 938, 522, 998, 450, 614.6],
        ["2005/06", 135, 1120, 599, 1268, 288, 682],
        ["2006/07", 157, 1167, 587, 807, 397, 623],
        ["2007/08", 139, 1110, 615, 968, 215, 609.4],
        ["2008/09", 136, 691, 629, 1026, 366, 569.6]
      ];
    case "Gantt":
      return [
        [
          { type: "string", label: "Task ID" },
          { type: "string", label: "Task Name" },
          { type: "date", label: "Start Date" },
          { type: "date", label: "End Date" },
          { type: "number", label: "Duration" },
          { type: "number", label: "Percent Complete" },
          { type: "string", label: "Dependencies" }
        ],
        [
          "Research",
          "Find sources",
          new Date(2015, 0, 1),
          new Date(2015, 0, 5),
          null,
          100,
          null
        ],
        [
          "Write",
          "Write paper",
          null,
          new Date(2015, 0, 9),
          3 * 24 * 60 * 60 * 1000,
          25,
          "Research,Outline"
        ],
        [
          "Cite",
          "Create bibliography",
          null,
          new Date(2015, 0, 7),
          1 * 24 * 60 * 60 * 1000,
          20,
          "Research"
        ],
        [
          "Complete",
          "Hand in paper",
          null,
          new Date(2015, 0, 10),
          1 * 24 * 60 * 60 * 1000,
          0,
          "Cite,Write"
        ],
        [
          "Outline",
          "Outline paper",
          null,
          new Date(2015, 0, 6),
          1 * 24 * 60 * 60 * 1000,
          100,
          "Research"
        ]
      ];
    case "GeoChart":
      return [
        ["Country", "Popularity"],
        ["Germany", 200],
        ["United States", 300],
        ["Brazil", 400],
        ["Canada", 500],
        ["France", 600],
        ["RU", 700]
      ];
    case "Histogram":
      return [
        ["Dinosaur", "Length"],
        ["Acrocanthosaurus (top-spined lizard)", 12.2],
        ["Albertosaurus (Alberta lizard)", 9.1],
        ["Allosaurus (other lizard)", 12.2],
        ["Apatosaurus (deceptive lizard)", 22.9],
        ["Archaeopteryx (ancient wing)", 0.9],
        ["Argentinosaurus (Argentina lizard)", 36.6],
        ["Baryonyx (heavy claws)", 9.1],
        ["Brachiosaurus (arm lizard)", 30.5],
        ["Ceratosaurus (horned lizard)", 6.1],
        ["Coelophysis (hollow form)", 2.7],
        ["Compsognathus (elegant jaw)", 0.9],
        ["Deinonychus (terrible claw)", 2.7],
        ["Diplodocus (double beam)", 27.1],
        ["Dromicelomimus (emu mimic)", 3.4],
        ["Gallimimus (fowl mimic)", 5.5],
        ["Mamenchisaurus (Mamenchi lizard)", 21.0],
        ["Megalosaurus (big lizard)", 7.9],
        ["Microvenator (small hunter)", 1.2],
        ["Ornithomimus (bird mimic)", 4.6],
        ["Oviraptor (egg robber)", 1.5],
        ["Plateosaurus (flat lizard)", 7.9],
        ["Sauronithoides (narrow-clawed lizard)", 2.0],
        ["Seismosaurus (tremor lizard)", 45.7],
        ["Spinosaurus (spiny lizard)", 12.2],
        ["Supersaurus (super lizard)", 30.5],
        ["Tyrannosaurus (tyrant lizard)", 15.2],
        ["Ultrasaurus (ultra lizard)", 30.5],
        ["Velociraptor (swift robber)", 1.8]
      ];
    case "LineChart":
      return [
        ["x", "dogs"],
        [0, 0],
        [1, 10],
        [2, 23],
        [3, 17],
        [4, 18],
        [5, 9],
        [6, 11],
        [7, 27],
        [8, 33],
        [9, 40],
        [10, 32],
        [11, 35]
      ];
    case "PieChart":
      return [
        ["Task", "Hours per Day"],
        ["Work", 11],
        ["Eat", 2],
        ["Commute", 2],
        ["Watch TV", 2],
        ["Sleep", 7]
      ];
    case "Timeline":
      return [
        [
          { type: "string", id: "President" },
          { type: "date", id: "Start" },
          { type: "date", id: "End" }
        ],
        ["Washington", new Date(1789, 3, 30), new Date(1797, 2, 4)],
        ["Adams", new Date(1797, 2, 4), new Date(1801, 2, 4)],
        ["Jefferson", new Date(1801, 2, 4), new Date(1809, 2, 4)]
      ];
    case "Table":
      return [
        [
          { type: "string", label: "Name" },
          { type: "number", label: "Salary" },
          { type: "boolean", label: "Full Time Employee" }
        ],
        ["Mike", { v: 10000, f: "$10,000" }, true],
        ["Jim", { v: 8000, f: "$8,000" }, false],
        ["Alice", { v: 12500, f: "$12,500" }, true],
        ["Bob", { v: 7000, f: "$7,000" }, true]
      ];
    default:
      return [];
  }
};
