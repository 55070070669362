import React from "react";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router";
import moment from "moment-timezone";
import i18n from "../../../i18n";
import { userName } from "../../../../services/string";
import axios from "../../../../services/axios";
import Toast from "../../../../services/toasts";
import SubmitButton from "../../../SubmitButton";
import { isDesktop } from "../../../../services/general";

class UsersForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      returnToUsers: false
    };

    this.state = { windowWidth: window.innerWidth };
  }

  user = () => {
    return Object.prototype.hasOwnProperty.call(this.props, "user")
      ? this.props.user
      : null;
  };

  rolesOptions = () => {
    return [
      { value: "user", label: i18n.t("user") },
      { value: "admin", label: i18n.t("admin") }
    ];
  };

  createUser = (values, submittingFormik) => {
    const valuesToSubmit = {
      ...values,
      timezone: moment.tz.guess()
    };
    axios
      .post("/users", valuesToSubmit)
      .then(() => {
        submittingFormik(false);
        Toast.show.usersCreated();
        this.setState({
          returnToUsers: true
        });
      })
      .catch(() => {
        submittingFormik(false);
        Toast.show.usersNotCreated();
      });
  };

  updateUser = (values, submittingFormik) => {
    axios
      .put(`/users/${this.userId()}`, values)
      .then(() => {
        submittingFormik(false);
        Toast.show.usersUpdated();
        this.setState({
          returnToUsers: true
        });
      })
      .catch(() => {
        submittingFormik(false);
        Toast.show.usersNotUpdated();
      });
  };

  userId() {
    return this.user() ? this.user().id : null;
  }

  renderButtons(isSubmitting) {
    return (
      <div className="actionButtons">
        <button
          className="ui button blue basic"
          type="button"
          onClick={() => {
            this.setState({
              returnToUsers: true
            });
          }}
        >
          {i18n.t("CancelButton")}
        </button>
        <SubmitButton label="SaveButton" isSubmitting={isSubmitting} />
      </div>
    );
  }

  render() {
    if (this.state.returnToUsers) {
      return <Redirect push to="/admin/users" />;
    }

    const { windowWidth } = this.state;
    const responsiveClass = !isDesktop() ? "required field inlineFieldMobile" : "required field inlineField"
    return (
      <Formik
        initialValues={{
          name: this.user() ? this.user().name : "",
          email: this.user() ? this.user().email : "",
          is_admin: this.user()
            ? this.user() && this.user().roles.includes("admin")
            : false
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(i18n.t("NameRequiredValidation")),
          email: Yup.string()
            .email(i18n.t("EmailInvalidValidation"))
            .required(i18n.t("EmailRequiredValidation")),
          is_admin: Yup.bool()
        })}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          this.userId()
            ? this.updateUser(values, setSubmitting)
            : this.createUser(values, setSubmitting);
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, errors }) => (
          <form className="ui form" onSubmit={handleSubmit}>
            <div className="fieldsContainer">
              <div className={responsiveClass}>
                <label>{i18n.t("Name")}</label>
                <input
                  name="name"
                  onChange={handleChange}
                  placeholder={i18n.t("NamePlaceholder")}
                  value={values.name}
                  autoComplete="off"
                />
                <ErrorMessage name="name" component="div" className="error" />
              </div>
              <div className={responsiveClass}>
                <label>{i18n.t("Email")}</label>
                <input
                  name="email"
                  onChange={handleChange}
                  placeholder={i18n.t("Email")}
                  value={values.email}
                  autoComplete="off"
                />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div
                className={`inline field ${errors.is_admin ? "withError" : ""}`}
              >
                <label className="widthDefaultUser">{i18n.t("IsAdmin")}</label>
                <input
                  name="is_admin"
                  onChange={handleChange}
                  checked={values.is_admin}
                  type="checkbox"

                />
                <ErrorMessage
                  name="is_admin"
                  component="div"
                  className="error"
                />
              </div>
              {this.renderButtons(isSubmitting)}
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

export default UsersForm;
