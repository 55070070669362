import store from "./store";

export const VisitableComponents = Object.freeze({
  Activities: "activities",
  Teams: "teams",
  Tickets: "tickets",
  Companies: "companies",
  Users: "users",
  Settings: "settings",
  Admin: "admin",
  Inbox: "inbox",
  Queues: "queues",
  Items: "items",
  Application: "application",
  EnvVariables: "envVariables",
  LaunchProcess: "launchProcess",
  Services: "services",
  UserConfig: "userConfig",
  Form: "form",
  User: "user",
  Scheduler: "scheduler",
  Resources: "resources",
  RpaAssets: "rpaAssets"
});

export const Actions = Object.freeze({
  read: "read",
  create: "create",
  update: "update",
  show: "show",
  destroy: "destroy",
  list: "list",
  list_all: "list_all",
  manage: "manage"
});

const RolePermissions = {
  super_admin: {
    [Actions.manage]: [
      VisitableComponents.Companies,
      VisitableComponents.Services
    ]
  },
  admin: {
    [Actions.list]: [VisitableComponents.Companies],
    [Actions.list_all]: [VisitableComponents.Users],
    [Actions.manage]: [
      VisitableComponents.Application,
      VisitableComponents.Users,
      VisitableComponents.Inbox,
      VisitableComponents.Queues,
      VisitableComponents.Teams,
      VisitableComponents.EnvVariables,
      VisitableComponents.User,
      VisitableComponents.UserConfig,
      VisitableComponents.Form,
      VisitableComponents.Admin,
      VisitableComponents.Items,
      VisitableComponents.Activities,
      VisitableComponents.LaunchProcess,
      VisitableComponents.Scheduler,
      VisitableComponents.Resources,
      VisitableComponents.RpaAssets
    ]
  },
  user: {
    [Actions.manage]: [
      VisitableComponents.UserConfig,
      VisitableComponents.Inbox
    ],
    [Actions.read]: [
      VisitableComponents.Form,
      VisitableComponents.Activities,
      VisitableComponents.Teams,
      VisitableComponents.LaunchProcess,
      VisitableComponents.Scheduler
    ]
  }
};

const hasUserPermission = (component, permission) => {
  let roles = [];

  try {
    roles = store.getState().currentUserReducer.user.roles;
  } catch {
    roles = [];
  }

  const reducer = (accumulator, currentRole) => {
    try {
      return (
        (Object.prototype.hasOwnProperty.call(
          RolePermissions[currentRole],
          permission
        ) &&
          RolePermissions[currentRole][permission].includes(component)) ||
        (Object.prototype.hasOwnProperty.call(
          RolePermissions[currentRole],
          Actions.manage
        ) &&
          RolePermissions[currentRole][Actions.manage].includes(component)) ||
        accumulator
      );
    } catch (e) {
      return false;
    }
  };

  return roles.reduce(reducer, false);
};

export default hasUserPermission;
