import React from "react";
import { Formik } from "formik";
import { ReactFormGenerator } from "react-ui-form-builder-attachment";
import moment from "moment-timezone";
import i18n from "../i18n";
import FormsService from "../../services/data_services/forms";
import ApplicationService from "../../services/data_services/application";
import { getFieldType } from "../../services/formGenerator";

class LaunchApplication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: undefined
    };

    if (this.props.application) {
      this.loadForm();
    }
  }

  loadForm() {
    if (this.props.application.trigger_type === "Form") {
      FormsService.loadForm(this.props.application.trigger_id).then(result => {
        this.setState({
          form: result.data
        });
      });
    } else {
      FormsService.loadForms({ search_param: "email_default_form" }).then(
        result => {
          if (result.data.results && result.data.results.length) {
            this.setState({
              form: result.data.results[0]
            });
          }
        }
      );
    }
  }

  dateTimeFieldValue(confItem, data) {
    let dataValue = "";

    if (data.value) {
      let inputFormat = "";
      let m = null;
      const dateFormat = confItem.dateFormat.replace("dd", "DD");
      const { timeFormat } = confItem;

      if (confItem.showTimeSelect === "true") {
        if (confItem.showTimeSelectOnly === "true") {
          // Time
          inputFormat = `${timeFormat}`;
          m = moment.tz(data.value, inputFormat, "UTC");
        } else {
          // DateTime
          inputFormat = `${dateFormat} ${timeFormat}`;
          m = moment.tz(data.value, inputFormat, "UTC");
        }
      } else {
        // Date
        inputFormat = `${dateFormat}`;
        m = moment.tz(data.value, inputFormat, "UTC");
      }

      dataValue = `${moment.utc(m).format("YYYY-MM-DDTHH:mm.SSS")}Z`;
    }

    return dataValue;
  }

  createItem(values, setSubmitting) {
    const valuesData = values.data !== undefined ? values.data : [];

    const finalData = valuesData.map(data => {
      const confItem = this.state.form.configuration.filter(
        element => element.id === data.name
      )[0];

      const dataValue =
        confItem.element === "DatePicker"
          ? this.dateTimeFieldValue(confItem, data)
          : data.value;

      return {
        name: data.name,
        value: dataValue,
        type: getFieldType(confItem)
      };
    });

    ApplicationService.launchProcess(this.props.application.id, finalData)
      .then(() => {
        setSubmitting(false);
        this.props.onSuccess();
        this.props.closeModal && this.props.closeModal();
      })
      .catch(() => {
        this.props.onFailure();
        setSubmitting(false);
      });
  }

  renderForm(setFieldValue, isSubmitting, handleSubmit) {
    return (
      <ReactFormGenerator
        data={this.state.form.configuration}
        answer_data={[]}
        submit_name={i18n.t("Launch")}
        onSubmit={handleSubmit}
        back_name={i18n.t("CancelButton")}
        back_action={() => {
          this.props.closeModal && this.props.closeModal();
        }}
        isSubmitting={isSubmitting}
        onChange={inputs => {
          setFieldValue("data", inputs);
        }}
      />
    );
  }

  render() {
    return (
      <>
        <Formik
          onSubmit={(values, { setSubmitting }) => {
            this.createItem(values, setSubmitting);
          }}
        >
          {({ handleSubmit, isSubmitting, setFieldValue }) => (
            <div style={{ width: "100%" }}>
              {this.state.form
                ? this.renderForm(setFieldValue, isSubmitting, handleSubmit)
                : null}
            </div>
          )}
        </Formik>
      </>
    );
  }
}

export default LaunchApplication;
