import React from "react";
import Select from "react-select";
import i18n from "../../i18n";

const PAGE_OPTIONS = [10, 50, 100].map(option => {
  return {
    label: option,
    value: option
  };
});

export const DEFAULT_PER_PAGE_VALUE = 10;

const PerPageSelector = ({ filters, loadItems }) => {
  const customStyles = {
    container: provided => ({
      ...provided,
      width: "70px"
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: "4px 4px 4px 0"
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: "none"
    }),
    valueContainer: provided => ({
      ...provided,
      padding: "0",
      paddingLeft: "8px"
    })
  };

  const value = filters?.per_page
    ? parseInt(filters?.per_page, 0)
    : DEFAULT_PER_PAGE_VALUE;
  const dropdownValue = PAGE_OPTIONS.find(option => option.value === value);

  return (
    <div className="per-page-selector">
      <span className="per-page-label">{`${i18n.t("PerPage")}: `}</span>
      <Select
        styles={customStyles}
        defaultValue={dropdownValue}
        options={PAGE_OPTIONS}
        onChange={evt => {
          loadItems({
            page: 1,
            per_page:
              evt.value === DEFAULT_PER_PAGE_VALUE ? undefined : evt.value
          });
        }}
      />
    </div>
  );
};

export default PerPageSelector;
