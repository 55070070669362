import React from "react";
import { Field, ErrorMessage } from "formik";
import Select from "react-select";
import { isEmpty } from "lodash";
import TooltipLabel from "../TooltipLabel";
import ApplicationService from "../../../../services/data_services/application";
import axios from "../../../../services/axios";
import i18n from "../../../i18n";
import { isDesktop } from "../../../../services/general";

import "stylesheets/Scheduler/Schedulers.scss";
import { APPLICATION_TYPES } from "../../../../constants/ApplicationTypes";

class Application extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: undefined
    };
  }

  componentDidMount() {
    if (!isEmpty(this.propsApplication())) {
      const applicationId = this.propsApplication().application_uuid;
      if (applicationId) {
        this.loadApplication(applicationId);
      }
    } else {
      const processes = [
        {
          application_id: undefined,
          max_resources: 1,
          data: undefined
        }
      ];

      this.props.setFieldValue("processes", processes);
    }

    this.loadApplicationTypes();
  }

  setFieldValue(field, value) {
    this.props.setFieldValue(this.fieldName(field), value);
    this.props.setFieldTouched(this.fieldName(field), true);
  }

  isNotAdmin = () => {
    return !this.props.user.roles.includes("admin");
  };

  propsApplication = () => {
    return this.props.values.processes[0];
  };

  loadApplicationTypes = () => {
    ApplicationService.getApplicationTypes().then(response => {
      this.setState({ applicationTypes: response.data }, this.loadApplications);
    });
  };

  mapTypeIdToApplicationType = typeId => {
    try {
      const applicationType = this.state.applicationTypes.find(
        appType => appType.id === typeId
      );
      return applicationType.name;
    } catch {
      return APPLICATION_TYPES.CASE_VIEW;
    }
  };

  loadApplications = () => {
    ApplicationService.loadApplications({ page: 0 }).then(response => {
      const applications = response.data.results;
      const typedApplications = applications.map(application => ({
        ...application,
        applicationType: this.mapTypeIdToApplicationType(application.type_id)
      }));
      this.setState({
        applications: typedApplications
      });
    });
  };

  loadApplication = (uuid, clearData = false) => {
    ApplicationService.loadApplication(uuid).then(response => {
      const application = response.data;

      this.loadService(application);

      let rpaProcess;
      let rpaQueue;
      const applicationId = application.id;
      const applicationUuid = application.uuid;
      const applicationName = application.name;
      const resourcePool = application.resource_pool.filter(pool => {
        return pool && pool.length > 0;
      });

      if (application.process_entities.length > 0) {
        rpaProcess = application.process_entities[0].rpa_process;
        rpaQueue = application.process_entities[0].rpa_queues.filter(queue => {
          return queue && queue.length > 0;
        });
      }

      this.setFieldValue("rpa_process", rpaProcess);
      this.setFieldValue("rpa_queue", rpaQueue);
      this.setFieldValue("application_id", applicationId);
      this.setFieldValue("application_uuid", applicationUuid);
      this.setFieldValue("application_name", applicationName);
      this.setFieldValue("resource_pool", resourcePool);

      if (clearData) {
        this.setFieldValue("data", undefined);
      }
    });
  };

  loadService = application => {
    axios.get("/services/").then(result => {
      const service = result.data.find(currentService => {
        const processEntities = application.process_entities;

        return (
          processEntities.length > 0 &&
          currentService.id === processEntities[0].service_id
        );
      });

      const name = service ? service.name : undefined;
      const id = service ? service.id : undefined;

      this.setFieldValue("service", name);
      this.setFieldValue("service_id", id);
    });
  };

  applicationDisabled() {
    return this.state.applications === undefined;
  }

  applicationOptions() {
    if (this.state.applications && this.props.user.permitted_applications) {
      const permittedApplications = this.state.applications.filter(
        app =>
          this.props.user.permitted_applications.includes(app.uuid) &&
          app.applicationType === APPLICATION_TYPES.HUMAN_IN_THE_LOOP
      );
      return permittedApplications.map(application => {
        return {
          label: application.name,
          value: application.id,
          uuid: application.uuid
        };
      });
    }

    return [];
  }

  applicationValue() {
    let result = "";
    if (!isEmpty(this.propsApplication())) {
      result = this.applicationOptions().filter(application => {
        let applicationId = this.propsApplication().application_uuid;
        let optionId = application.uuid;
        if (this.propsApplication().application_id) {
          applicationId = parseInt(this.propsApplication().application_id, 10);
          optionId = parseInt(application.value, 10);
        }
        return applicationId !== undefined && applicationId === optionId;
      });
    }

    return result;
  }

  rpaProcessOptions() {
    const rpaProcess = this.propsApplication().rpa_process;
    return rpaProcess ? [{ label: rpaProcess, value: rpaProcess }] : [];
  }

  rpaProcessValue() {
    return this.rpaProcessOptions();
  }

  serviceOptions() {
    const { service } = this.propsApplication();
    return service ? [{ label: service, value: service }] : [];
  }

  serviceValue() {
    return this.serviceOptions();
  }

  resourcePoolOptions() {
    const queues = this.propsApplication().resource_pool
      ? this.propsApplication().resource_pool
      : [];

    return queues.map(resource => {
      return { label: resource, value: resource };
    });
  }

  resourcePoolValue() {
    return this.resourcePoolOptions();
  }

  fieldName(field) {
    return `processes[${this.props.index}].${field}`;
  }

  render() {
    const inlineClasses = isDesktop() ? "inline inlineField" : "";
    const errorClass = isDesktop() ? "inline error" : "mobile error";
    const renderAppInfo =
      this.props.user &&
      Array.isArray(this.props.user.roles) &&
      this.props.user.roles.includes("admin");
    return (
      <div className="schedulerApplication">
        <div className="fieldsContainer">
          <h4 className="ui dividing header">{i18n.t("Application")}</h4>

          <div className={`required ${inlineClasses} field`}>
            <label>{i18n.t("ApplicationName")}</label>
            <Select
              value={this.applicationValue()}
              options={this.applicationOptions()}
              onChange={application => {
                this.loadApplication(application.uuid, true);
              }}
              isDisabled={this.applicationDisabled()}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>
          <ErrorMessage
            name={this.fieldName("application_id")}
            component="div"
            className={errorClass}
          />

          <div className={`required ${inlineClasses} field`}>
            <label>{i18n.t("MaxResources")}</label>
            <Field
              name={this.fieldName("max_resources")}
              style={{ width: "100%" }}
              type="number"
              min={1}
            />
          </div>
          <ErrorMessage
            name={this.fieldName("max_resources")}
            component="div"
            className={errorClass}
          />

          {renderAppInfo && (
            <div className="applicationDataContainer">
              <div className={`${inlineClasses} field`}>
                <label>{i18n.t("Service")}</label>
                <Select
                  value={this.serviceValue()}
                  options={this.serviceOptions()}
                  isDisabled
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
              <div className={`${inlineClasses} field`}>
                <label>{i18n.t("RpaProcess")}</label>
                <Select
                  value={this.rpaProcessValue()}
                  options={this.rpaProcessOptions()}
                  isDisabled
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>

              <div className={`${inlineClasses} field`}>
                <TooltipLabel
                  label={i18n.t("ResourcePool")}
                  content={i18n.t("ResourcePoolTooltip")}
                  isRequired
                />
                <Select
                  value={this.resourcePoolValue()}
                  options={this.resourcePoolOptions()}
                  isDisabled
                  isMulti
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              </div>
            </div>
          )}
          <div />
        </div>
        {isDesktop() && this.props.removeApplicationButton()}
      </div>
    );
  }
}

export default Application;
