import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import EventsIndex from "./Events/EventsIndex";
import Permissions from "../../services/permissions";
import {
  VisitableComponents,
  Actions
} from "../../services/detailedPermissionRules";
import ActivityShow from "./ActivityShow";
import Notfound from "../Error/Notfound";
import FormIndex from "./NCW/FormIndex";

const Activities = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/NCW`}
        render={componentProps => (
          <FormIndex
            {...props}
            activity={componentProps?.location?.state}
            preview={componentProps?.location?.preview}
          />
        )}
      />
      <Route
        path={`${props.match.path}/external`}
        render={componentProps => (
          <ActivityShow
            {...props}
            applicationId={props.match.params.id}
            match={componentProps.match}
            uuid={componentProps?.location?.state.uuid}
            attempt={componentProps?.location?.state.attempt}
          />
        )}
      />
      <Route
        path={`${props.match.path}/activities/:id`}
        render={componentProps => (
          <ActivityShow
            {...props}
            applicationId={props.match.params.id}
            match={componentProps.match}
          />
        )}
      />
      <Route
        exact
        path={`${props.match.path}`}
        render={() => (
          <Permissions
            component={VisitableComponents.Teams}
            action={Actions.read}
            yes={() => {
              return <EventsIndex key={props.match.params.id} {...props} />;
            }}
            no={() => {
              return <Redirect to="/403" />;
            }}
          />
        )}
      />
      <Route>
        <Notfound />
      </Route>
    </Switch>
  );
};

export default Activities;
