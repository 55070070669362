import React from "react";
import { Switch, Route } from "react-router-dom";
import ActivityShow from "../Activities/ActivityShow";
import LaunchProcess from "./LaunchProcess";
import Notfound from "../Error/Notfound";

const Launcher = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.url}/activities/:id`}
        render={componentProps => (
          <ActivityShow {...props} match={componentProps.match} />
        )}
      />
      <Route
        exact
        path={`${props.match.url}/`}
        render={componentProps => (
          <LaunchProcess {...props} match={componentProps.match} />
        )}
      />
      <Route>
        <Notfound />
      </Route>
    </Switch>
  );
};

export default Launcher;
