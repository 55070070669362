import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { logger } from "redux-logger";
import reducers from "../reducers";

const middleware = [thunk];
if (process.env.NODE_ENV !== "production" && false) {
  middleware.push(logger);
}
const middlewareInit = applyMiddleware(...middleware);

const store = createStore(reducers, middlewareInit);

export default store;
