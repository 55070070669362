import React from "react";
import i18n from "../../i18n";
import { isDesktop } from "../../../services/general";

const FieldDetail = props => {
  const transcriptionRow = (fieldState, label, value) => {
    return (
      <div className={`transcriptionItem ${fieldState}`}>
        <div className="transcriptionItemName">{label}</div>
        <div className="transcriptionItemValue">{value}</div>
      </div>
    );
  };

  const getFieldColor = field => {
    switch (field.state) {
      case "supervision":
        return "red";
      case "completed":
      default:
        if (field.source === "manual_transcription") {
          return "green";
        }
        return "blue";
    }
  };

  const isBlank = field => {
    if (field.state === "complete" && field.normalized === "") {
      return true;
    }
    return false;
  };

  // const determineIdentifiedBy = value => {
  //   if (value === "machine_transcription") {
  //     return i18n.t("Machine");
  //   }
  //   return value;
  // };

  const isSelected = props.selectedField.id === props.field.id;
  const fieldState = getFieldColor(props.field);

  return (
    <div id={`fieldDetail_${props.field.id}`} className="fieldData">
      <div className="fieldHeader">
        <div>{props.field.name}</div>
        <div className="headerButtons">
          {isDesktop() && (
            <div>
              <i
                role="button"
                aria-label="Edit"
                tabIndex={0}
                className="edit icon"
                onClick={() => props.reviewField(props.field)}
              />
            </div>
          )}
        </div>
      </div>
      <div
        role="menuitem"
        tabIndex={0}
        className="fieldDetail"
        onClick={() => {
          props.setSelectedField(isSelected ? -1 : props.field.id, "list");
        }}
      >
        {!isSelected ? (
          <div className={`valueContainer ${fieldState}`}>
            {isBlank(props.field) ? (
              <span style={{ color: "gray" }}>BLANK</span>
            ) : (
              props.field.normalized
            )}
          </div>
        ) : (
          <div className={`transcriptionContainer ${fieldState}`}>
            {transcriptionRow(
              fieldState,
              i18n.t("Normalized"),
              props.field.normalized
            )}
            {transcriptionRow(fieldState, i18n.t("Raw"), props.field.raw)}
            {/* {transcriptionRow(fieldState, i18n.t("IdentifiedBy"), determineIdentifiedBy(props.field.source))}
            {transcriptionRow(fieldState, i18n.t("DataType"), props.field.data_type)} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default FieldDetail;
