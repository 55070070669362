import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Upperbar from "../Ui/Upperbar/Upperbar";
import { isDesktop } from "../../services/general";

const ADMIN_URL = "/admin";

export default function AdminUpperbar() {
  const history = useHistory();
  const breadcrumb = useSelector(state => state.interfaceReducer.breadcrumb);

  const onCancelHandler = () => {
    const previousBreadcrumb = breadcrumb[breadcrumb.length - 2];
    const backUrl = previousBreadcrumb?.location ?? ADMIN_URL;
    history.push(backUrl);
  };

  if (!isDesktop()) return null;
  const currentSectionBreadcrumb = breadcrumb
    ? breadcrumb[breadcrumb.length - 1]
    : null;
  return (
    <div style={{ marginTop: "-24px" }}>
      <Upperbar
        title={currentSectionBreadcrumb?.title}
        onCancel={onCancelHandler}
      />
    </div>
  );
}
