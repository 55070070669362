import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { Input, Popup } from "semantic-ui-react";
import { DateTimeInput } from "semantic-ui-calendar-react-yz";
import moment from "moment-timezone";
import i18n from "../../i18n";
import { isDesktop } from "../../../services/general";
import { getDateFormat } from "../../../services/timezones";

const Searchbar = props => {
  const [dateOpen, setDateOpen] = useState(false);

  const timezone = props.user.timezone || moment.tz.guess();
  const displayDateFormat = getDateFormat(timezone);
  const dateFormatString = `${displayDateFormat} HH:mm`;
  const queryParamDateFormatString = "DD-MM-YYYY HH:mm";

  useEffect(() => {
    moment.tz.setDefault(timezone);
  }, []);

  const handleSearch = debounce(searchParams => {
    const query = {
      search_param: searchParams.length > 0 ? searchParams : undefined
    };
    query.page = 1;

    Object.keys(query).forEach(key => {
      props.setQueryParams(key, query[key]);
    });
  }, 500);

  /**
   * Handle date input on change event.
   * @param event
   * @param name
   * @param value
   */
  const handleDateInputChange = (event, { name, value }) => {
    setDateValue(`date_${name}`, value);
    props.setQueryParams("page", 1);
  };

  const handleOnClosePopUp = event => {
    const shouldClose = !event.composedPath().some(pathElement => {
      return pathElement.className && pathElement.className.includes("popup");
    });

    shouldClose && setDateOpen(false);
  };

  /**
   * Set date value.
   * @param name
   * @param value
   * @returns {*}
   */
  const setDateValue = (name, value) => {
    if (!value) {
      return props.setQueryParams(name, null);
    }
    const formattedDate = formatDateForQueryParams(value);

    props.setQueryParams(name, formattedDate);
  };

  const formatDateToDisplay = date => {
    if (date) {
      return moment
        .utc(date, queryParamDateFormatString)
        .tz(timezone)
        .format(dateFormatString);
    }
    return "";
  };

  /**
   * Returns date value on a readable format
   * @param name
   * @returns {string|*}
   */
  const getDateInputValue = name => {
    const value = props.queryParams[name];

    if (value) {
      return formatDateToDisplay(value);
    }
    return "";
  };

  const formatDateForQueryParams = date => {
    if (date) {
      return moment(date, dateFormatString)
        .utc()
        .format(queryParamDateFormatString);
    }
    return null;
  };

  const displayFilteredDate = () => {
    const { queryParams } = props;
    if (queryParams.date_from && !queryParams.date_to) {
      return (
        <div>{`From ${formatDateToDisplay(props.queryParams.date_from)}`}</div>
      );
    }
    if (!queryParams.date_from && queryParams.date_to) {
      return (
        <div>{`Until ${formatDateToDisplay(props.queryParams.date_to)}`}</div>
      );
    }
    return (
      <div>
        {`${
          queryParams.date_from
            ? formatDateToDisplay(queryParams.date_from)
            : moment()
                .startOf("day")
                .format(dateFormatString)
        } - 
              ${
                queryParams.date_to
                  ? formatDateToDisplay(queryParams.date_to)
                  : moment()
                      .endOf("day")
                      .format(dateFormatString)
              }
        `}
      </div>
    );
  };

  return (
    <div className="searchbar">
      <div className="filters">
        <Input
          icon="search"
          type="text"
          placeholder={i18n.t("Search...")}
          defaultValue={props.queryParams.search_param}
          onChange={e => {
            handleSearch(e.target.value);
          }}
          style={{ marginRight: "0.75rem" }}
        />

        {/*
          <Popup
            trigger={
              <button
                className="ui button mini icon"
                style={{ marginRight: "0.5rem" }}
                type="button"
                onClick={() => setDateOpen(!dateOpen)}
              >
                <i className="calendar icon" />
              </button>
            }
            flowing
            on="click"
            open={dateOpen}
            position="right center"
            onClose={handleOnClosePopUp}
            className="popup-container"
          >
            <React.Fragment>
              <DateTimeInput
                name="from"
                placeholder="From"
                disableMinute
                closable
                clearable
                value={getDateInputValue("date_from")}
                dateFormat={displayDateFormat}
                maxDate={getDateInputValue("date_to") || undefined}
                iconPosition="left"
                animation="none"
                onChange={handleDateInputChange}
              />
              <DateTimeInput
                name="to"
                placeholder="To"
                disableMinute
                closable
                clearable
                value={getDateInputValue("date_to")}
                dateFormat={displayDateFormat}
                initialDate={
                  getDateInputValue("date_from") || moment().startOf("day")
                }
                iconPosition="left"
                animation="none"
                minDate={
                  getDateInputValue("date_from") || moment().startOf("day")
                }
                onChange={handleDateInputChange}
              />
            </React.Fragment>
          </Popup>
        {isDesktop() && displayFilteredDate()}
        */}
      </div>

      <button
        className="ui button mini icon"
        type="button"
        onClick={props.reloadElements}
      >
        <i className="sync icon" />
      </button>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(Searchbar);
