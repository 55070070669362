import React from "react";
import { ErrorMessage, Formik } from "formik";
import Select from "react-select";
import { Grid } from "semantic-ui-react";
import UserService from "../../services/data_services/user";

import axios from "../../services/axios";
import Toast from "../../services/toasts";
import i18n from "../i18n";
import { reactSelectStyle } from "../../semantic-ui";
import TeamService from "../../services/data_services/team";
import { PRIORITY } from "../../constants/priority";
import { STATUS, UNATTENDED_STATUS } from "../../constants/status";

import { isDesktop } from "../../services/general";

import { APPLICATION_TYPES } from "../../constants/ApplicationTypes";
import { capitalizeFirst } from "../../services/string";

class ActivityDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teams: undefined,
      users: []
    };
  }

  componentDidMount() {
    this.loadTeams();
  }

  loadTeams = () => {
    TeamService.loadTeams().then(response => {
      this.setState(
        {
          teams: response.data.results
        },
        () => {
          this.loadUsers();
        }
      );
    });
  };

  loadUsers() {
    const params = { page: 0, teams: this.state.teams.map(team => team.id) };

    UserService.loadUsers(params).then(response => {
      const users = response.data.results;

      const agentList = users.map(user => {
        return { value: user.email, label: user.email };
      });

      this.setState({
        users: agentList
      });
    });
  }

  teamOptions = () => {
    return this.state.teams.map(team => {
      return { value: team.id, label: team.description };
    });
  };

  statusOptions = () => {
    const applicationType =
      this.props.application.applicationType || APPLICATION_TYPES.CASE_VIEW;

    if (applicationType === APPLICATION_TYPES.UNATTENDED) {
      return UNATTENDED_STATUS.map(status => ({
        label: capitalizeFirst(status.value),
        value: capitalizeFirst(status.value)
      }));
    }
    return STATUS.filter(status => {
      if (Array.isArray(status.type)) {
        return status.type.includes(applicationType);
      }
      return status.type === applicationType;
    });
  };

  statusValue = () => {
    return (
      this.statusOptions().filter(
        status => status.value === this.props.activity.status
      )[0] || null
    );
  };

  priorityOptions = () => {
    return PRIORITY;
  };

  priorityValue = () => {
    return this.priorityOptions().filter(
      option => option.value === this.props.activity.priority
    )[0];
  };

  agentValue = value => {
    try {
      return this.state.users.find(user => user.label === value);
    } catch {
      return value;
    }
  };

  defaultTeam = () => {
    let teamId;

    if (this.props.activity.assigned_to) {
      const userFromFilter = this.state.users.filter(
        user => user.id === this.state.activity.assigned_to
      )[0];

      if (userFromFilter) {
        teamId = userFromFilter.team_id;
      }
    }

    if (teamId) {
      return (
        this.teamOptions().filter(option => option.value === teamId)[0] || null
      );
    }

    return null;
  };

  updateActivity(values, setSubmitting) {
    const newValues = { ...values };

    ["status", "priority"].forEach(valueKey => {
      if (
        newValues[valueKey] &&
        Object.prototype.hasOwnProperty.call(newValues[valueKey], "value")
      ) {
        newValues[valueKey] = newValues[valueKey].value;
      } else {
        delete newValues[valueKey];
      }
    });

    axios
      .put(`/activities/${this.props.activity.id}`, newValues)
      .then(() => {
        Toast.show.activitiesUpdated();
        setTimeout(() => {
          this.props.loadActivityData();
        }, 1000);
        setSubmitting(false);
      })
      .catch(() => {
        Toast.show.activitiesNotUpdated();
        setSubmitting(false);
      });
  }

  render() {
    const isUnattended =
      this.props.application?.applicationType === APPLICATION_TYPES.UNATTENDED;
    const displayAgent =
      this.props.application?.applicationType === APPLICATION_TYPES.CASE_VIEW;
    return (
      <div
        style={{
          width: this.props.displayRetry && isDesktop() ? "75%" : "100%"
        }}
        className="ui"
      >
        <Formik
          onSubmit={(values, { setSubmitting }) => {
            this.updateActivity(values, setSubmitting);
          }}
          validate={values => {
            const errors = {};

            if (!values.status || values.status === "") {
              errors.status = i18n.t("StatusNotFound");
            }
            if (!values.priority || values.priority === "") {
              errors.priority = i18n.t("PriorityNotFound");
            }

            return errors;
          }}
          enableReinitialize
          initialValues={{
            status: this.statusValue(),
            priority: this.priorityValue(),
            assigned_to: this.props.activity.assigned_to
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <form className="ui form" onSubmit={handleSubmit}>
              <Grid style={{ maxWidth: "900px" }} columns={3} stackable>
                <Grid.Row stretched>
                  <Grid.Column>
                    <div
                      className={`ui field ${isUnattended ? "" : "required"}`}
                    >
                      <label>{i18n.t("Status")}</label>
                      <Select
                        options={this.statusOptions()}
                        value={values.status}
                        onChange={value => {
                          setFieldValue("status", value);
                          setTimeout(() => {
                            handleSubmit();
                          });
                        }}
                        placeholder={`${i18n.t("Select")}...`}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        styles={reactSelectStyle}
                        isDisabled={!this.props.editable || isUnattended}
                      />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <div
                      className={`ui field ${isUnattended ? "" : "required"}`}
                    >
                      <label>{i18n.t("Priority")}</label>
                      <Select
                        options={this.priorityOptions()}
                        value={values.priority}
                        onChange={value => {
                          setFieldValue("priority", value);
                          setTimeout(() => {
                            handleSubmit();
                          });
                        }}
                        placeholder={`${i18n.t("Select")}...`}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        styles={reactSelectStyle}
                        isDisabled={!this.props.editable || isUnattended}
                      />
                      <ErrorMessage
                        name="priority"
                        component="div"
                        className="error"
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    {displayAgent && (
                      <div className="ui field">
                        <label>{i18n.t("Agent")}</label>
                        <Select
                          options={this.state.users}
                          value={this.agentValue(values.assigned_to)}
                          onChange={value => {
                            setFieldValue("assigned_to", value.value);
                            setTimeout(() => {
                              handleSubmit();
                            });
                          }}
                          placeholder={`${i18n.t("Select")}...`}
                          className="react-select-container"
                          classNamePrefix="react-select"
                          styles={reactSelectStyle}
                          isDisabled={!this.props.editable}
                        />
                        <ErrorMessage
                          name="assigned_to"
                          component="div"
                          className="error"
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ActivityDetails;
