import React from "react";
import Select from "react-select";
import { debounce, isEqual } from "lodash";
import i18n from "../../../i18n";
import EnvVariableService from "../../../../services/data_services/env_variable";
import Table from "../../../Ui/Table/Table";
import { TYPES } from "../../../Ui/Table/types";
import { Type, tableActions } from "../../../Ui/Table/TableActions";
import InputField from "../../../Scheduler/Schedulers/InputFields/InputField";
import Toast from "../../../../services/toasts";
import {
  searchParamFromUrl,
  urlFromSearchParam
} from "../../../../services/url";
import EnvVariablesModal from "./EnvVariablesModal";
import { isDesktop } from "../../../../services/general";

const typeOptions = [
  { key: "date", text: "Date", value: "date" },
  { key: "datetime", text: "DateTime", value: "datetime" },
  { key: "flag", text: "Flag", value: "flag" },
  { key: "number", text: "Number", value: "number" },
  { key: "password", text: "Password", value: "password" },
  { key: "text", text: "Text", value: "text" },
  { key: "time", text: "Time", value: "time" }
];

const typeOptionsForFilter = typeOptions.map(option => ({
  label: option.text,
  value: option.value
}));

class EnvVariablesTable extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = searchParamFromUrl(this.props.location.search);

    this.state = {
      envVariables: undefined,
      loadingVariables: true,
      columns: [
        { name: "Name", show: true, key: "name" },
        { name: "Environment", show: true, key: "service" },
        {
          name: "Type",
          show: true,
          key: "datatype",
          toEdit: this.editType,
          type: "select",
          single: true,
          options: typeOptionsForFilter
        },
        {
          name: "Description",
          show: true,
          key: "description",
          toEdit: this.editDescription
        },
        { name: "Value", show: true, key: "value", toEdit: this.editValue }
      ],
      queryParams,
      nPages: 0,
      loading: false,
      editing: false,
      creating: false
    };

    props.setBreadcrumb([
      {
        title: i18n.t("EnvVariables"),
        location: "/admin/env_variables"
      }
    ]);

    this.loadEnvVariables();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.props.history.push(`?${urlFromSearchParam(this.state.queryParams)}`);
      this.reloadEnvVariables();
    }
  }

  loadEnvVariables = debounce(() => {
    this.setState({ loadingVariables: true });
    const query = { ...this.state.queryParams };
    query.company_id = this.props.user.company_id;

    EnvVariableService.loadEnvVariables(query)
      .then(result => {
        this.setState({
          envVariables: result.data.results,
          nPages: result.data.total,
          totalItems: result.data.hits
        });
      })
      .catch(() => {
        this.setState({
          envVariables: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingVariables: false, editing: false });
      });
  }, 500);

  setQueryParams(key, value, search = false) {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      if (search) {
        queryParams.page = 1;
      }

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  }

  handleEdit = value => {
    this.setState({ editing: value });
  };

  editValue = (item, onChange) => {
    return (
      <div className="ui form">
        <InputField
          fromEnvVariables
          disabled={this.state.loading}
          value={item?.value}
          type={item?.datatype}
          setFieldValue={value => onChange("value", value)}
        />
      </div>
    );
  };

  editDescription = (item, onChange) => {
    return (
      <div className="ui fluid input">
        <input
          disabled={this.state.loading}
          defaultValue={item?.description}
          placeholder={i18n.t("Description")}
          onChange={e => onChange("description", e.target.value)}
        />
      </div>
    );
  };

  editType = (item, onChange) => {
    const selectedOption =
      typeOptionsForFilter.find(option => option.value === item?.datatype) ||
      null;
    return (
      <Select
        isDisabled={this.state.loading}
        options={typeOptionsForFilter}
        value={selectedOption}
        search
        selection
        onChange={({ value }) => {
          onChange("datatype", value);
          onChange("value", "");
        }}
      />
    );
  };

  onSubmitEdit = values => {
    this.setState({ loading: true });
    EnvVariableService.updateEnvVariable(values)
      .then(() => {
        Toast.displaySuccess("Updated!");
        this.handleEdit(false);
        this.loadEnvVariables();
      })
      .catch(() => {
        Toast.displayError(i18n.t("SomethingWentWrong"));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleCloseModal = () => {
    this.setState({ creating: false, selectedItem: undefined });
  };

  handleCreate = () => {
    this.setState({ creating: true });
  };

  handleCreateSubmit = () => {
    this.setState(
      {
        creating: false,
        selectedItem: undefined
      },
      () => {
        this.loadEnvVariables();
      }
    );
  };

  toggleEdit = item => {
    this.setState({ creating: true, selectedItem: item });
  };

  reloadEnvVariables = () => {
    this.setState(
      { envVariables: null, editing: false },
      this.loadEnvVariables
    );
  };

  mobileAction = {
    type: Type.Click,
    button_icon: "edit",
    button_text: i18n.t("Edit"),
    onClick: item => this.toggleEdit(item)
  };

  render() {
    return (
      <>
        <Table
          tableId={TYPES.ENV_VARIABLES}
          columns={this.state.columns}
          items={this.state.envVariables}
          noItemsKey="NoEnvVariables"
          loadItems={entries => {
            if (entries) {
              Object.keys(entries).forEach(key => {
                this.setQueryParams(key, entries[key]);
              });
            } else {
              this.loadEnvVariables();
            }
          }}
          actions={[
            isDesktop()
              ? tableActions.environment_variables.edit
              : this.mobileAction,
            tableActions.environment_variables.delete,
            tableActions.environment_variables.find_references
          ]}
          loadingItems={this.state.loadingVariables}
          filters={this.state.queryParams}
          onSubmitEdit={this.onSubmitEdit}
          nPages={this.state.nPages}
          page={this.state.queryParams ? this.state.queryParams.page : 1}
          loading={this.state.loading}
          editing={this.state.editing}
          handleEdit={this.handleEdit}
          createAction={this.handleCreate}
          reloadAction={this.reloadEnvVariables}
          totalItems={this.state.totalItems}
        />
        <EnvVariablesModal
          open={this.state.creating}
          onClose={this.handleCloseModal}
          onSubmit={this.handleCreateSubmit}
          typeOptions={typeOptions}
          item={this.state.selectedItem}
        />
      </>
    );
  }
}

export default EnvVariablesTable;
