import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import background from "images/login_background.png";
import Login from "./Login.jsx";
import RememberPassword from "./RememberPassword";
import ResetPassword from "./ResetPassword";
import ContactAdmin from "./ContactAdmin";

const Authentication = props => {
  return (
    <div
      style={{
        height: "100vh",
        margin: "0",
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "cover"
      }}
    >
      <div
        className="ui segment grid middle aligned"
        style={{
          backgroundColor: "rgba(2,30,80,0.9)",
          height: "100vh",
          margin: "0"
        }}
      >
        <Router>
          <Switch>
            <Route path="/remember">
              <RememberPassword {...props} />
            </Route>
            <Route path="/reset_password">
              <ResetPassword {...props} />
            </Route>
            <Route path="/users/confirmation">
              <ResetPassword {...props} confirmation />
            </Route>
            <Route path="/contact_admin">
              <ContactAdmin {...props} />
            </Route>
            <Route>
              <Login {...props} />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default Authentication;
