import React from "react";
import { Redirect } from "react-router";
import WidgetService from "../../../../../services/data_services/widget";
import Toast from "../../../../../services/toasts";

import WidgetForm from "./WidgetForm";
import i18n from "../../../../i18n";

class WidgetNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      returnToDashboard: false
    };

    this.returnToDashboard = this.returnToDashboard.bind(this);
  }

  componentDidMount() {
    this.setBreadcrumb();
  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Dashboard"),
        location: "/admin/dashboard"
      },
      {
        title: "New widget",
        location: "/admin/dashboard"
      }
    ]);
  }

  onSubmit = (values, setSubmitting) => {
    WidgetService.create(values)
      .then(() => {
        Toast.show.widgetsCreated();
        setSubmitting(false);
        this.returnToDashboard();
      })
      .catch(e => {
        console.log(e);
        Toast.show.widgetsNotCreated();
        setSubmitting(false);
      });
  };

  returnToDashboard = () => {
    this.setState({
      returnToDashboard: true
    });
  };

  render() {
    if (this.state.returnToDashboard) {
      return <Redirect push to="/admin/dashboard" />;
    }

    return (
      <div className="ui segment widget-form-container">
        <WidgetForm
          onSubmit={this.onSubmit}
          onCancel={this.returnToDashboard}
          match={this.props.match}
        />
      </div>
    );
  }
}

export default WidgetNew;
