import { combineReducers } from "redux";
import currentUserReducer from "./currentUserReducer";
import companiesReducer from "./companiesReducer";
import servicesReducer from "./servicesReducer";
import usersReducer from "./usersReducer";
import rolesReducer from "./rolesReducer";
import interfaceReducer from "./interfaceReducer";
import applicationsReducer from "./applicationsReducer";
import columnOrderReducer from "./columnOrderReducer";
import filtersReducer from "./filtersReducer";

export default combineReducers({
  currentUserReducer,
  usersReducer,
  rolesReducer,
  companiesReducer,
  interfaceReducer,
  applicationsReducer,
  servicesReducer,
  columnOrderReducer,
  filtersReducer
});
