import axios from "../axios";

class ActivitiesService {
  static searchActivities = params => {
    return axios.get("/activities", { params });
  };

  static loadActivity = (id, params = null) => {
    return axios.get(`/activities/${id}`, { params });
  };

  static loadActivitiesFromEvent = (eventId, params = {}) => {
    return axios.get(`/events/${eventId}/activities`, {
      params
    });
  };

  static loadExternalActivity = (uuid, params) => {
    return axios.get(`/activities/external/${uuid}`, { params });
  };

  static loadAllActivities = () => {
    return axios.get("/activities", {});
  };

  static loadAllActivitiesByApp = appUuid => {
    return axios.get("/activities", {
      params: { application_uuids: appUuid }
    });
  };

  static loadExternalActivities = params => {
    return axios.get("/activities/external", { params });
  };

  static selectAllExternals = params => {
    return axios.get("/activities/select_all", { params });
  };

  static updateActivity = (id, values) => {
    return axios.put(`/activities/${id}`, values);
  };

  static updateActivityData = (id, values) => {
    return axios.put(`/activities/${id}/update_data`, values);
  };

  static createNCW = values => {
    return axios.post("activities/create_ncw", values);
  };

  static updateNCW = (id, values) => {
    return axios.put(`activities/${id}/update_ncw_data`, values);
  };

  static approveActivity = (id, message) => {
    const params = {};
    if (message) {
      params.message = message;
    }
    return axios.put(`activities/${id}/approve`, params);
  };

  static rejectActivity = (id, message) => {
    return axios.put(`activities/${id}/reject`, { message });
  };

  static retryActivity = (uuids, application_uuid) => {
    return axios.put("/activities/retry", {
      uuids,
      application_uuid
    });
  };

  static deleteActivity = uuids => {
    return axios.delete("/activities/delete", {
      data: {
        uuids
      }
    });
  };
}

export default ActivitiesService;
