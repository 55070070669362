import axios from "../axios";

class EnvVariableService {
  static loadEnvVariables = params => {
    return axios.get("/environment_variables", { params });
  };

  static loadReferences = params => {
    return axios.get("/environment_variables/references", { params });
  };

  static updateEnvVariable = params => {
    return axios.put(`/environment_variables/update`, params);
  };

  static deleteEnvVariable = params => {
    return axios.delete(`/environment_variables/delete`, { data: params });
  };
}

export default EnvVariableService;
