/* eslint-disable react/jsx-indent-props */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { cloneDeep, debounce, isEmpty } from "lodash";
import { Icon, Grid, Checkbox, Popup, Radio, Message } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react-yz";
import moment from "moment-timezone";
import i18n from "../../i18n";
import StepContainer from "./StepContainer";
import InputField from "../../Ui/InputField";
import InputDropdown from "../../Ui/InputDropdown";
import DayYearSelection from "../../Ui/DayYearSelection";
import TableActionButton from "../../Ui/TableActionButton";
import NcwService from "../../../services/data_services/ncw";
import DatePickerForm from "../../Ui/DatePickerFormContainer";
import { getDateFormat } from "../../../services/timezones";

const requiredFields = [
  "client_name",
  "niche",
  "legal_name",
  "industry",
  "short_code",
  "taxpayer_name",
  "year_end",
  "email_address",
  "cell_phone",
  "business_phone",
  "partner",
  "manager",
  "office",
  "originator_1",
  "client_department"
];

const requiredClientFields =  [
  "niche",
  "industry",
  "short_code",
  "year_end",
  "email_address",
  "partner",
  "manager",
  "office",
  "originator_1"
]

const requiredFieldsLabels = [
  "Niche",
  "Industry",
  "ShortCode",
  "ClientYearEnd",
  "BillingEmail",
  "ClientPartner",
  "ClientManager",
  "Office",
  "ClientOriginator"
]

const requiredAddressFields = ['city', 'address', 'country', 'zip', 'state'];
const requiredAddressFieldsLabels = ["City", "Address1", "Country", "Zip", "State"];

const initialValues = {
  address_information: {
    country: "USA"
  },
  billing_address: {
    country: "USA"
  },
  international: false,
  expansion_service: false
}

const yearEndStringFormat = "YYYY-MM-DD"

const BasicInformation = props => {
    const dateFormatString = getDateFormat(props.user.timezone);
    const [loadingClient, setLoadingClient] = useState(false);
    const [loadingNiche, setLoadingNiche] = useState(false);
    const [loadingIndustries, setLoadingIndustries] = useState(false);
    const [loadingOffice, setLoadingOffice] = useState(false);
    const [loadingPartners, setLoadingPartners] = useState(false);
    const [loadingManagers, setLoadingManagers] = useState(false);
    const [loadingOriginators, setLoadingOriginators] = useState(false);
    const [loadingCountries, setLoadingCountries] = useState(false);
    const [loadingStates, setLoadingStates] = useState(false);
    const [loadingDepartments, setLoadingDepartments] = useState(false);
    const [loadingActiveClients, setLoadingActiveClients] = useState(false);
    const [clientOptions, setClientOptions] = useState([]);
    const [optionsNiche, setOptionsNiche] = useState({fullList: [], dummyList: []});
    const [optionsIndus, setOptionsIndus] = useState({fullList: [], dummyList: []});
    const [officeOptions, setOfficeOptions] = useState({fullList: [], dummyList: []});
    const [partnerOptions, setPartnerOptions] = useState({fullList: [], dummyList: []});
    const [managerOptions, setManagerOptions] = useState({fullList: [], dummyList: []});
    const [originatorOptions, setOriginatorOptions] = useState({fullList: [], dummyList: []});
    const [countryOptions, setCountryOptions] = useState({fullList: [], dummyList: []});
    const [stateOptions, setStateOptions] = useState({fullList: [], dummyList: []});
    const [departmentOptions, setDepartmentOptions] =useState({fullList: [], dummyList: []});
    const [activeClientOptions, setActiveClientOptions]= useState({ fullList: [], dummyList: [] });
    const [formFields, setFormFieldsValue] = useState(props?.data?.client || initialValues);
    const [existingClient, setExistingClient] = useState(false);
    const [lostClient, setLostClient] = useState(false);
    const [linkedAddress, setLinkedAddress] = useState(true);
    const [clientEmptyFields, setClientEmptyFields] = useState([]);
    const [clientCode, setClientCode] = useState();

    useEffect(() => {
      if (props.data?.client?.mac_id) {
        setExistingClient(true);
        loadExistingClient(props.data.client.mac_id, true)
      }
    }, [])

    useEffect(() => {
      if (loadingNiche) {
        loadNiche();
      }
    }, [loadingNiche])

    useEffect(() => {
      if(!isEmpty(props?.data) && !isEmpty(props?.data?.client) ){
        setFormFieldsValue(props.data.client);
        setClientCode(props.data.client?.client_name)
      }
    }, [props?.data?.client?.id]);

    useEffect(() => {
      if (loadingIndustries) {
        loadIndustries();
      }
    }, [loadingIndustries])

    useEffect(() => {
      if (loadingOffice) {
        loadOffices();
      }
    }, [loadingOffice])

    useEffect(() => {
      if (loadingPartners) {
        loadPartners()
      }
    }, [loadingPartners])

    useEffect(() => {
      if (loadingManagers) {
        loadManagers();
      }
    }, [loadingManagers])

    useEffect(() => {
      if (loadingOriginators) {
        loadOriginators();
      }
    }, [loadingOriginators])

    useEffect(() => {
      if (loadingCountries) {
        loadCountries();
      }
    }, [loadingCountries])

    useEffect(() => {
      if (loadingStates) {
        loadStates();
      }
    }, [loadingStates])

    useEffect(() => {
      if(loadingDepartments) {
        loadDepartments();
      }
    }, [loadingDepartments])

    useEffect(() => {
      if(loadingActiveClients) {
        loadActiveClients();
      }
    }, [loadingActiveClients])

    
    const onlyNumber = (value) => {
      if (!value) return value;
      return value.replace(/[^\d]/g, '');
    }

    const normalizeInput = (value, previousValue) => {
      if (!value) return value;
      const currentValue = value.replace(/[^\d]/g, '');
      const cvLength = currentValue.length;
      
      if (!previousValue || value.length > previousValue.length) {
        if (cvLength < 4) return currentValue;
        if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
      }
      return "";
    }

    const loadClients = useCallback(debounce(name => {
      NcwService.getClients(props.application.uuid, name)
        .then(response => {
          const auxOptions = response.data.map(client => {
            return {
              key: client.MAC_ID,
              text: `${client.ClientName} (${client.ClientCode}) ${client.Lost ? "(Lost Client)" : ""}` ,
              value: client.ClientCode,
              BEvalue: client.ClientName
            }
          })
          setClientOptions(auxOptions);
        })
        .catch(() => {})
        .finally(() => {
          setLoadingClient(false)
        })
    }, 1000), [])

    const loadExistingClient = (macId, fromLoad = false) => {
      setLoadingClient(true);
      NcwService.getExistingClient(props.application.uuid, macId)
        .then(response => {
          fillExistingClient(response.data[0], macId, fromLoad);
        })
        .catch(() => {})
        .finally(() => {
          setLoadingClient(false);
        })
    }

    const loadNiche = () => {
      NcwService.getNiche(props.application.uuid)
      .then(({ data }) => {
        const auxOptions = data.map(item => {
          return {
            key: item.ID,
            text: item.Info,
            value: item.Info
          }
        });
        setOptionsNiche({fullList:auxOptions, dummyList:auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingNiche(false)
      })
    }

    const loadIndustries = () => {
      NcwService.getIndustries(props.application.uuid, formFields?.niche_id)
      .then(({ data }) => {
        const auxOptions = data.map(item => {
          return {
            key: item.ID,
            text: item.Info,
            value: item.Info
          }
        });
        setOptionsIndus({fullList:auxOptions, dummyList:auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingIndustries(false)
      })
    }

    const loadOffices = () => {
      NcwService.getOffices(props.application.uuid)
        .then(({ data }) => {
          const auxOptions = data.map(option => {
            return {
              key: option.OfficeIndex,
              text: option.OfficeName,
              value: option.OfficeName
            }
          });
          setOfficeOptions({fullList:auxOptions, dummyList:auxOptions });
        })
        .catch(() => {})
        .finally(() => {
          setLoadingOffice(false);
        })
    }

    const loadPartners = () => {
      NcwService.getJobPartners(props.application.uuid)
        .then(({ data }) => {
          const auxOptions = data.map(option => {
            return {
              key: option.StaffIndex,
              text: option.StaffName,
              value: option.StaffName
            }
          })
          setPartnerOptions({fullList:auxOptions, dummyList:auxOptions });
        })
        .catch(() => {})
        .finally(() => {
          setLoadingPartners(false);
        })
    }

    const loadManagers = () => {
      NcwService.getClientManagers(props.application.uuid)
        .then(({ data }) => {
          const auxOptions = data.map(option => {
            return {
              key: option.StaffIndex,
              text: option.StaffName,
              value: option.StaffName
            }
          })
          setManagerOptions({fullList:auxOptions, dummyList:auxOptions });
        })
        .catch(() => {})
        .finally(() => {
          setLoadingManagers(false);
        })
    }

    const loadOriginators = () => {
      NcwService.getClientOriginators(props.application.uuid)
        .then(({ data }) => {
          const auxOptions = data.map(option => {
            return {
              key: option.StaffIndex,
              text: option.StaffName,
              value: option.StaffName
            }
          })
          setOriginatorOptions({fullList:auxOptions, dummyList:auxOptions });
        })
        .catch(() => {})
        .finally(() => {
          setLoadingOriginators(false);
        })
    }

    const loadCountries = () => {
      NcwService.getCountries(props.application.uuid)
        .then(({ data }) => {
          const auxOptions = data.map(option => {
            return {
              key: option.CountryIndex,
              text: option.CountryName,
              value: option.CountryName
            }
          })
          setCountryOptions({fullList:auxOptions, dummyList:auxOptions });
        })
        .catch(() =>{})
        .finally(() => {
          setLoadingCountries(false);
        })
    }

    const loadStates = () => {
      NcwService.getUsaStates(props.application.uuid)
        .then(({ data }) => {
          const auxOptions = data.map(option => {
            return {
              key: option.StateAbbrv,
              text: option.StateName,
              value: option.StateName
            }
          })
          setStateOptions({fullList:auxOptions, dummyList:auxOptions });
        })
        .catch((err) => {console.error(err)})
        .finally(() => {
          setLoadingStates(false);
        })
    }
    const loadDepartments = () => {
      NcwService.getDepartments(props.application.uuid)
        .then((response) => {
          const auxOptions = response.data.map(item => {
            return {
              key: item.DeptIdx,
              id: item.DeptIdx,
              text: item.DeptName,
              value: item.DeptName
            }
          })
          setDepartmentOptions({fullList:auxOptions, dummyList:auxOptions });
        })
        .catch(() => {})
        .finally(() => {
          setLoadingDepartments(false);
        })
    }

    const loadActiveClients = useCallback(debounce(name => {
      NcwService.getActiveClients(props.application.uuid, name)
      .then(response => {
        const auxOptions = response.data.map(client => {
          return {
            key: client.MAC_ID,
            client_code: client.ClientCode,
            text: `${client.ClientName} (${client.ClientCode}) ${client.Lost ? "(Lost Client)" : ""}`,
            value: client.ClientName
          }
        })
        setActiveClientOptions({ fullList: auxOptions, dummyList: auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingActiveClients(false)
      })
    }, 500),
    [])

    const fillExistingClient = (clientInfo, macId, fromLoad = false) => {
      setLinkedAddress(false);
      const clientAddress = {
        address: clientInfo.Address1,
        address1: clientInfo.Address2,
        city: clientInfo.City,
        country: clientInfo.Country,
        state: clientInfo.State,
        zip: clientInfo.Zip
      };
      const billingAddress = {
        address: clientInfo.Billing_Address1,
        address1: clientInfo.Billing_Address2,
        city: clientInfo.Billing_City,
        country: clientInfo.Billing_Country,
        state: clientInfo.Billing_State,
        zip: clientInfo.Billing_Zip
      };
      // let formattedYearEnd = clientInfo.YearEnd;
      // if (formattedYearEnd) {
      //   const currentYear = moment().year()
      //   formattedYearEnd = moment.utc(clientInfo.YearEnd, "MM/DD").year(currentYear).format(yearEndStringFormat);
      // }
      const clientData = {
        address_information: clientAddress,
        billing_address: billingAddress,
        client_code: clientInfo.ClientCode,
        client_name: clientInfo.ClientName,
        year_end: clientInfo.YearEnd,
        niche: clientInfo.Niche,
        niche_id: clientInfo.NicheIndex,
        industry: clientInfo.Industry,
        industry_id: clientInfo.IndustryIndex,
        short_code: clientInfo.ClientShortCode,
        international: clientInfo.International,
        email_address: clientInfo.ClientEmail,
        fax_number: clientInfo.FaxNumber,
        cell_phone: clientInfo.CellPhone,
        business_phone: clientInfo.Business_Phone,
        office: clientInfo.ClientOffice,
        office_id: clientInfo.ClientOfficeIndex,
        partner: clientInfo.PartnerInCharge,
        partner_id: clientInfo.PartnerInChargeStaffIndex,
        manager: clientInfo.ClientManager,
        manager_id: clientInfo.ClientManagerStaffIndex,
        originator_1: clientInfo.ClientOriginator1,
        originator_1_id: clientInfo.ClientOriginator1StaffIndex,
        originator_2: clientInfo.ClientOriginator2,
        originator_2_id: clientInfo.ClientOriginator2StaffIndex,
        mac_id: clientInfo?.Lost === false ? macId : "",
        expansion_service: false,
        client_department: clientInfo.DepartmentName,
        client_department_id: clientInfo.DepartmentIdx,
        summary_client: clientInfo.Lost ? "" : clientInfo.SummaryClient,
        summary_client_code: clientInfo.Lost ? "" : clientInfo.SummaryClientCode,
        summary_client_mac_id: clientInfo.Lost ? "" : clientInfo.SummaryClientMAC_ID
      }
      setClientCode(fromLoad ? clientInfo.ClientName : clientInfo.ClientCode)
      if (formFields.id) {
        clientData.id = formFields.id;
      }
      if (fromLoad) {
        checkEmptyFields(clientData);
        setFormFieldsValue(clientData);
        props.onChange('client', clientData);
      }
      else{
        const ncwServices = {
          tax_software_type: clientInfo.Tax_Software_type
        }
        
        if (clientInfo?.Lost === false) {
          setExistingClient(true);
          checkEmptyFields(clientData);
        } else {
          setLostClient(true)
          setClientEmptyFields([]);
        }
        const newState = resetClientInformation();
        newState.client = clientData;
        newState.ncw_services = ncwServices;
        setFormFieldsValue(clientData);
        props.onChange("fullForm", newState);

      }
    }

    const checkEmptyFields = (clientInfo) => {
      const emptyClientFields = requiredClientFields
        .filter(f => !clientInfo[f])
        .map(f => transformToLabel (f, requiredClientFields, requiredFieldsLabels));
      const emptyAddressFields = requiredAddressFields
        .filter(f => !clientInfo.address_information[f])
        .map(f => transformToLabel (f, requiredAddressFields, requiredAddressFieldsLabels));
      const emptyFields = [...emptyClientFields, ...emptyAddressFields];
      setClientEmptyFields(emptyFields);
    }

    const transformToLabel = (name, variableArray, labelArray) => {
      try {
        const namePosition = variableArray.findIndex(v => v === name);
        return i18n.t(labelArray[namePosition]);
      } catch {
        return name;
      }
    }

    const cleanObject = object => {
      const objectCopy = cloneDeep(object);
      for (const param in objectCopy) {
        if (["id", "ncw_id"].includes(param)) {
          objectCopy[param] = object[param];
        } else if (typeof (objectCopy[param]) === "object" && objectCopy[param]) {
          for (const internalParam in objectCopy[param]){
            objectCopy[param][internalParam] = "";
          }
        } else if (typeof (objectCopy[param]) === "boolean") {
          objectCopy[param] = false;
        } else {
          objectCopy[param] = "";
        }
      }
      return objectCopy;
    }

    const resetClientInformation = () => {
      const newData = cloneDeep(props.data);
      Object.keys(newData).forEach(key => {
        if (typeof (newData[key]) === "object") {
          newData[key] = cleanObject(newData[key])
        } else if (typeof (newData[key]) === "boolean") {
          newData[key] === false;
        } else {
          newData[key] = "";
        }
      })
      return newData;
    }

    const handleNewClient = value => {
      const newData = resetClientInformation();
      const clientData = newData.client ? cloneDeep(newData.client) : {}
      clientData.client_name = value;
      clientData.international = false;
      clientData.expansion_service = false;
      if (formFields.id) {
        clientData.id = formFields.id
      }
      newData.client = clientData;
      setFormFieldsValue(clientData);
      setClientCode(value)
      props.onChange("fullForm", newData);
    }

    const handleSearchChange = (e, { search }) => {
        if (e.currentTarget.value.length >= 3 && clientOptions.length === 0) {
          setLoadingClient(true);
          loadClients(e.currentTarget.value)
        }
        else if(e.currentTarget.value.length < 3 && clientOptions.length > 0){            
            setClientOptions([])
        }
    }

    const handleSummaryClientSearchChange = (e, { search }) => {
      if (e.currentTarget.value.length >= 3 && activeClientOptions.fullList.length === 0) {
        setLoadingActiveClients(true);
        loadActiveClients(e.currentTarget.value)
      }
      else if(e.currentTarget.value.length < 3 && activeClientOptions.fullList.length > 0){            
          setActiveClientOptions({ fullList: [], dummyList: [] })
      }
  }

    const onChangeField = (target, value) =>{
      let tempValues = cloneDeep(formFields);
      tempValues[target] = value;
      setFormFieldsValue(tempValues);
      props.onChange('client', tempValues);
    }

    const onChangeAddressField = (target, value, billing = false) => {
      let tempFormValues = cloneDeep(formFields);
      if((linkedAddress && !billing) || billing){
        if(!tempFormValues.billing_address){
          tempFormValues.billing_address={};
        }
        if (target === "country" && value !== "USA") {
          tempFormValues.billing_address.state = "";
        }
        tempFormValues.billing_address[target] = value;
      }
      if(!billing){
        if(!tempFormValues.address_information){
          tempFormValues.address_information={};
        }
        if (target === "country" && value !== "USA") {
          tempFormValues.address_information.state = "";
        }
        tempFormValues.address_information[target] = value;
      }
      setFormFieldsValue(tempFormValues);
      props.onChange('client', tempFormValues);
    }

    const handleLinkAddress = _ =>{
      if (!linkedAddress) {
        let tempFormValues = cloneDeep(formFields);
        tempFormValues.billing_address =  cloneDeep(tempFormValues.address_information);
        setFormFieldsValue(tempFormValues);
        props.onChange(tempFormValues);
      }
      setLinkedAddress(!linkedAddress)
    }

    const onClientChange = value => {
      setExistingClient(false);
      setLostClient(false);
      onChangeField("client_name", value);
      const clientKey = clientOptions.find(client => client.value === value)?.key
      if (clientKey) {
        loadExistingClient(clientKey);
      } else {
        handleNewClient(value);
      }
    }

    const checkSSNError = target =>{
      if(props.errors?.client?.[target]){
        return props.errors?.client?.[target]
      }
      if( !isEmpty(formFields?.[target]) && formFields?.[target].length != 9){
        return "missingNumbers"
      }
      return undefined;
    }

    const checkemailError = _ =>{
      if(props.errors?.client?.email_address){
        return props.errors?.client?.email_address
      }
      if( !isEmpty(formFields?.email_address) && !emailIsValid(formFields?.email_address)){
        return "wrongFormatEmail"
      }
      return undefined;
    }

    const emailIsValid = email => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
    
    const getDateInputValue = value => moment.utc(value, yearEndStringFormat).format(dateFormatString);

    const setDateInputValue = (name, value) => {
      const toSave = value === '' ? '' : moment.utc(value, dateFormatString).startOf("day").format(yearEndStringFormat);
      onChangeField(name, toSave);
    };

    const onChangeSelect = (name, { value, options }) => {
      const idFieldName = `${name}_id`;
      const id = options.find(o => o.value === value)?.key;
      const auxValues = cloneDeep(formFields);
      auxValues[name] = value;
      auxValues[idFieldName] = id;
      // if (name === "niche") {
      //   auxValues.industry = undefined;
      //   auxValues.industry_id = undefined;
      //   setOptionsIndus([])
      // }
      setFormFieldsValue(auxValues);
      props.onChange('client', auxValues);
    }

    const renderMissingFieldsError = () => {
      return (
        <Grid.Row stretched>
          <Grid.Column width={16}>
            <Message negative>
              <Message.Header>{i18n.t("MissingClientFieldsHeader")}</Message.Header>
              <div className="empty-fields-error">
                {`${i18n.t("MissingClientFields")}: ${clientEmptyFields.join(", ")}`}
              </div>
            </Message>
          </Grid.Column>
        </Grid.Row>
      )
    }

    const singleOptionSelect = (list, selected) => {
      if(selected && list.length > 0){
        let oneListItem = list.find(x => x.key === selected);
        return typeof oneListItem !== "undefined" ? [oneListItem] : list
      }
      return list;
    }

    const renderSummaryClientField = () => {
      if (existingClient || lostClient) {
        return (
          <InputField
            error={props.errors?.client?.summary_client}
            inputlabel={i18n.t("SummaryClient")}
            disabled={props.preview || existingClient}
            value={formFields?.summary_client}
            onChange={(e,  { value }) => onChangeField('summary_client', value )}
        />
        )
      } 
      return (
        <InputDropdown
          onClick={() => {
            if (activeClientOptions.fullList.length > 0 && activeClientOptions.dummyList.length === 0) {
              activeClientOptions.dummyList = activeClientOptions.fullList;
            }
          }}
          error={props.errors?.client?.summary_client}
          onSearchChange={handleSummaryClientSearchChange}
          loading={loadingActiveClients}
          inputlabel={i18n.t("SummaryClient")}
          disabled={props.preview || existingClient}
          placeholder={i18n.t("StartTyping")}
          noResultsMessage={i18n.t("TryAnotherSearch")}
          value={formFields?.summary_client}
          clearable
          search
          selection
          options={activeClientOptions.dummyList}
          onChange={(e, select) => onSummaryClientChange(select)}
        />
      )
    }

    const onSummaryClientChange = ({ value, options }) => {
      const selectedOption = options.find(option => option.value === value);
      const auxValues = cloneDeep(formFields);
      auxValues.summary_client = value;
      auxValues.summary_client_code = selectedOption?.client_code
      auxValues.summary_client_mac_id = selectedOption?.key;
      setFormFieldsValue(auxValues);
      props.onChange("client", auxValues);
      setActiveClientOptions(prevOptions => {
        return {
        ...prevOptions,
        dummyList: []
        }
      })
    }

    const stepContent = () => {
        return(
          <React.Fragment>
          <Grid columns={4} divided>
            {(existingClient && clientEmptyFields.length > 0) && renderMissingFieldsError()}
            <Grid.Row stretched>
              <Grid.Column>
                {/* <InputDropdown
                    error={props.errors?.client?.client_name}
                    loading={loadingClient}
                    required
                    disabled={props.preview}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    onSearchChange={handleSearchChange}
                    inputlabel={i18n.t("ClientName")}
                    placeholder={i18n.t("StartTyping")}
                    additionLabel={<i style={{ color: 'red' }}>{i18n.t("NewClient")}: </i>}
                    options={clientOptions}
                    allowAdditions
                    search
                    selection
                    value={clientCode}
                    onChange={(e, { value }) => onClientChange(value)}
                    onAddItem={(e, { value }) => 
                        setClientOptions(prevState => {
                          const newOptions = cloneDeep(prevState);
                          newOptions.push({ value, key: value, text: value })
                          return newOptions;
                        })}
                /> */}
                <InputField
                    required
                    error={props.errors?.client?.client_name}
                    inputlabel={i18n.t("ClientName")}
                    disabled={props.preview}
                    value={formFields?.client_name}
                    onChange={(e, { value }) => onChangeField('client_name', value )}
                />
                <InputDropdown
                    error={props.errors?.client?.niche}
                    onClick={() => {
                      if (!loadingNiche && optionsNiche?.fullList?.length === 0) {
                        setLoadingNiche(true);
                      }
                      else{
                        setOptionsNiche({fullList: optionsNiche?.fullList, dummyList: optionsNiche?.fullList });
                      }
                    }}
                    required
                    disabled={props.preview || existingClient}
                    loading={loadingNiche}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("Niche")}
                    placeholder={i18n.t("StartTyping")}
                    options={optionsNiche?.dummyList}
                    search
                    selection
                    value={formFields?.niche}
                    onChange={(e, select) =>{
                      onChangeSelect('niche', select);
                      setOptionsNiche({fullList: optionsNiche?.fullList, dummyList: [] });
                    }}
                />
              </Grid.Column>
              <Grid.Column>
                <InputField
                    required
                    error={props.errors?.client?.legal_name}
                    inputlabel={i18n.t("LegalName")}
                    disabled={props.preview || existingClient}
                    value={formFields?.legal_name}
                    onChange={(e,  { value }) => onChangeField('legal_name', value )}
                />
                <InputDropdown
                    onClick={() => {
                      if (!loadingIndustries && optionsIndus?.fullList?.length === 0) {
                        setLoadingIndustries(true)
                      }
                      else{
                        setOptionsIndus({fullList: optionsIndus?.fullList, dummyList: optionsIndus?.fullList });
                      }
                    }}
                    required
                    error={props.errors?.client?.industry}
                    disabled={props.preview || existingClient}
                    loading={loadingIndustries}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("Industry")}
                    placeholder={i18n.t("StartTyping")}
                    options={optionsIndus?.dummyList}
                    search
                    selection
                    value={formFields?.industry}
                    onChange={(e, select) =>{
                      onChangeSelect('industry', select);
                      setOptionsIndus({fullList: optionsIndus?.fullList, dummyList: [] });
                    }}
                />
              </Grid.Column>
              <Grid.Column>
                <InputField
                    inputlabel={i18n.t("Note")}
                    disabled={props.preview || existingClient}
                    value={formFields?.note}
                    onChange={(e,  { value }) => onChangeField('note', value )}
                    textarea
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column>
                <InputField
                    required
                    error={props.errors?.client?.short_code}
                    inputlabel={i18n.t("ShortCode")}
                    disabled={props.preview || existingClient}
                    value={formFields?.short_code}
                    onChange={(e,  { value }) => onChangeField('short_code', value )}
                />
              </Grid.Column>
              <Grid.Column>
                <div className='question-container'>
                  <span>{i18n.t("IntClientQuestion")}</span>
                  <div>
                    <Radio
                      label='Yes'
                      disabled={props.preview || existingClient}
                      checked={formFields?.international === true}
                      onChange={(e,  { value }) => onChangeField('international', true )}
                    />
                    <Radio
                      label='No'
                      disabled={props.preview || existingClient}
                      checked={formFields?.international === false}
                      onChange={(e,  { value }) => onChangeField('international', false )}
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className='question-container'>
                  <span>{i18n.t("ExpansionServices")}​</span>
                  <div>
                    <Radio
                      label='Yes'
                      disabled={props.preview || !existingClient}
                      checked={formFields?.expansion_service === true}
                      onChange={(e, { value }) => onChangeField('expansion_service', true )}
                    />
                    <Radio
                      label='No'
                      disabled={props.preview || !existingClient}
                      checked={formFields?.expansion_service === false}
                      onChange={(e, { value }) => onChangeField('expansion_service', false )}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid columns={4} divided>
            <Grid.Row stretched>
              <Grid.Column>
                {renderSummaryClientField()}
              </Grid.Column>
              <Grid.Column>
                <DayYearSelection
                  inputlabel={i18n.t("ClientYearEnd")} 
                  required
                  disabled={props.preview || existingClient}
                  error={props.errors?.client?.year_end}
                  value={formFields.year_end}
                  onChange={(value) => {
                    onChangeField("year_end", value );
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column>
                <InputField
                    error={props.errors?.client?.taxpayer_name}
                    required
                    inputlabel={i18n.t("TaxPayerName")}
                    disabled={props.preview || existingClient}
                    value={formFields?.taxpayer_name}
                    onChange={(e,  { value }) => onChangeField('taxpayer_name', value )}
                />
                {/* <InputField
                    error={props.errors?.client?.taxpayer_name}
                    inputlabel={i18n.t("TaxPayerSSN")}
                    disabled={props.preview || existingClient}
                    value={formFields?.taxpayer_ssn }
                    onChange={(e) => onChangeField('taxpayer_ssn',onlyNumber(e.currentTarget.value, formFields?.taxpayer_ssn) )}
                    password
                    icon
                    error={checkSSNError('taxpayer_ssn')}
                /> */}
              </Grid.Column>
              <Grid.Column>
                <InputField
                    inputlabel={i18n.t("SpouseName")}
                    disabled={props.preview || existingClient}
                    value={formFields?.spouse_name}
                    onChange={(e,  { value }) => onChangeField('spouse_name', value )}
                />
                {/* <InputField
                    inputlabel={i18n.t("SpouseSSN")}
                    disabled={props.preview || existingClient}
                    value={formFields?.spouse_ssn }
                    onChange={(e) => onChangeField('spouse_ssn', onlyNumber(e.currentTarget.value, formFields?.spouse_ssn) )}
                    password
                    icon
                    error={checkSSNError('spouse_ssn')}
                /> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className='Form-section' stretched >
              <Grid.Column>
                <div className='section-info tex-info'>{i18n.t("AddressInformation")}</div>     
                <InputField
                    disabled={props.preview || existingClient}
                    required
                    error={props.errors?.client?.address}
                    inputlabel={i18n.t("Address1")}
                    value={formFields?.address_information?.address}
                    onChange={(e,  { value }) =>onChangeAddressField('address', value )}
                />
                <InputField
                    disabled={props.preview || existingClient}
                    inputlabel={i18n.t("Address2")}
                    value={formFields?.address_information?.address1}
                    onChange={(e,  { value }) =>onChangeAddressField('address1', value )}
                />
              </Grid.Column>
              <Grid.Column>
                <div className='section-info tex-info'></div>             
                <InputField
                    required
                    error={props.errors?.client?.city}
                    inputlabel={i18n.t("City")}
                    disabled={props.preview || existingClient}
                    value={formFields?.address_information?.city}
                    onChange={(e,  { value }) =>onChangeAddressField('city', value )}
                />
                <InputDropdown
                    error={props.errors?.client?.country}
                    onClick={() => {
                      if (!loadingCountries && countryOptions?.fullList?.length === 0) {
                        setLoadingCountries(true);
                      }
                      else{
                        setCountryOptions({fullList: countryOptions?.fullList, dummyList: countryOptions?.fullList });
                      }
                    }}
                    required
                    disabled={props.preview || existingClient}
                    loading={loadingCountries}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("Country")}
                    placeholder={i18n.t("StartTyping")}
                    options={countryOptions?.dummyList}
                    search
                    selection
                    value={formFields?.address_information?.country}
                    onChange={(e, { value }) =>{
                      onChangeAddressField('country', value);
                      setCountryOptions({fullList: countryOptions?.fullList, dummyList: [] });
                    }}
                />
              </Grid.Column>
              <Grid.Column>
                <div className='section-info tex-info'></div>             
                <InputField
                    required
                    error={props.errors?.client?.zip}
                    inputlabel={i18n.t("Zip")}
                    disabled={props.preview || existingClient}
                    value={formFields?.address_information?.zip}
                    onChange={(e,  { value }) =>onChangeAddressField('zip', value )}
                />
                <InputDropdown
                    error={props.errors?.client?.state}
                    onClick={() => {
                      if (!loadingStates && stateOptions?.fullList?.length === 0) {
                        setLoadingStates(true);
                      }
                      else{
                        setStateOptions({fullList: stateOptions?.fullList, dummyList: stateOptions?.fullList });
                      }
                    }}
                    required
                    disabled={props.preview || existingClient || formFields?.address_information?.country !== "USA"}
                    loading={loadingStates}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("State")}
                    placeholder={i18n.t("StartTyping")}
                    options={stateOptions?.dummyList}
                    search
                    selection
                    value={formFields?.address_information?.state}
                    onChange={(e, { value }) =>{
                      onChangeAddressField('state', value);
                      setStateOptions({fullList: stateOptions?.fullList, dummyList: [] });
                    }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched style={{paddingTop: '50px'}}>
              <Grid.Column>
                <div className='section-info'>                  
                  <Checkbox
                      className={'checkbox-disclamer'}
                      checked={!existingClient && linkedAddress}
                      disabled={props.preview || existingClient}
                      onChange={handleLinkAddress}
                      label={
                        <React.Fragment>
                          <label>{i18n.t("SameBillingAddress")}</label>
                          <Popup
                            trigger={<Icon name='info circle' size='small' />}
                            content={i18n.t("SameBillingAddressDisclaimer")}
                            position='right center'
                            pinned
                            inverted
                            style={{borderRadius: '8px',opacity: 0.8,}}
                          />
                        </React.Fragment>
                      }
                    />
                </div>
                <InputField
                    required
                    disabled={linkedAddress || existingClient}
                    inputlabel={i18n.t("Address1")}
                    value={formFields?.billing_address?.address}
                    onChange={(e,  { value }) =>onChangeAddressField('address', value, true )}
                />
                <InputField
                    disabled={linkedAddress || existingClient}
                    inputlabel={i18n.t("Address2")}
                    value={formFields?.billing_address?.address1}
                    onChange={(e,  { value }) =>onChangeAddressField('address1', value, true )}
                />
              </Grid.Column>
              <Grid.Column>
                <div className='section-info tex-info'></div>             
                <InputField
                    required
                    inputlabel={i18n.t("City")}
                    disabled={linkedAddress || existingClient}
                    value={formFields?.billing_address?.city}
                    onChange={(e,  { value }) =>onChangeAddressField('city', value, true )}
                />
                <InputDropdown
                    onClick={() => {
                      if (!loadingCountries && countryOptions?.fullList?.length === 0) {
                        setLoadingCountries(true);
                      }
                      else{
                        setCountryOptions({fullList: countryOptions?.fullList, dummyList: countryOptions?.fullList });
                      }
                    }}
                    required
                    disabled={linkedAddress || existingClient}
                    loading={loadingCountries}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("Country")}
                    placeholder={i18n.t("StartTyping")}
                    options={countryOptions?.dummyList}
                    search
                    selection
                    value={formFields?.billing_address?.country}
                    onChange={(e, { value }) =>{
                      onChangeAddressField('country', value, true);
                      setCountryOptions({fullList: countryOptions?.fullList, dummyList: [] });
                    }}
                />
              </Grid.Column>
              <Grid.Column>
                <div className='section-info tex-info'></div>             
                <InputField
                    required
                    inputlabel={i18n.t("Zip")}
                    disabled={linkedAddress || existingClient}
                    value={formFields?.billing_address?.zip}
                    onChange={(e,  { value }) =>onChangeAddressField('zip', value, true )}
                />
                <InputDropdown
                    onClick={() => {
                      if (!loadingStates && stateOptions?.fullList?.length === 0) {
                        setLoadingStates(true);
                      }
                      else{
                        setStateOptions({fullList: stateOptions?.fullList, dummyList: stateOptions?.fullList });
                      }
                    }}
                    required
                    disabled={linkedAddress || existingClient || formFields?.billing_address?.country !== "USA"}
                    loading={loadingStates}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("State")}
                    placeholder={i18n.t("StartTyping")}
                    options={stateOptions?.dummyList}
                    search
                    selection
                    value={formFields?.billing_address?.state}
                    onChange={(e, { value }) =>{
                      onChangeAddressField('state', value, true);
                      setStateOptions({fullList: stateOptions?.fullList, dummyList: [] });
                    }}
                />
              </Grid.Column>              
            </Grid.Row>
            <Grid.Row verticalAlign='top' className='Form-section' stretched >
              <Grid.Column>
                <div className='section-info tex-info'>{i18n.t("ContactInformation")}</div>                
                <InputField
                    required
                    error={checkemailError()}
                    inputlabel={i18n.t("BillingEmail")}
                    disabled={props.preview || existingClient}
                    value={formFields?.email_address}
                    onChange={(e,  { value }) => onChangeField('email_address', value )}
                />
              </Grid.Column>
              <Grid.Column>
                <InputField
                    required = {!formFields?.mac_id }
                    error={props.errors?.client?.cell_phone}
                    inputlabel={i18n.t("CellPhoneNumber")}
                    disabled={props.preview || existingClient}
                    value={formFields?.cell_phone}
                    placeholder="(___) ___-____"
                    onChange={(e,  { value }) =>onChangeField('cell_phone', normalizeInput(value, formFields?.cell_phone) )}
                />
                <InputField
                    required = {!formFields?.mac_id }
                    error={props.errors?.client?.business_phone}
                    inputlabel={i18n.t("BusinessPhoneNumber")}
                    disabled={props.preview || existingClient}
                    value={formFields?.business_phone}
                    placeholder="(___) ___-____"
                    onChange={(e,  { value }) => onChangeField('business_phone', normalizeInput(value, formFields?.business_phone) )}
                />
              </Grid.Column>
              <Grid.Column>
                <InputField
                    inputlabel={i18n.t("HomePhoneNumber")}
                    disabled={props.preview || existingClient}
                    value={formFields?.home_phone}
                    placeholder="(___) ___-____"
                    onChange={(e,  { value }) => onChangeField('home_phone', normalizeInput(value, formFields?.home_phone) )}
                />
                <InputField
                    inputlabel={i18n.t("FaxPhone")}
                    disabled={props.preview || existingClient}
                    value={formFields?.fax_number}
                    placeholder="(___) ___-____"
                    onChange={(e,  { value }) => onChangeField('fax_number', normalizeInput(value, formFields?.fax_number) )}
                />
              </Grid.Column>           
            </Grid.Row>
            <Grid.Row verticalAlign='top' className='Form-section' stretched >
              <Grid.Column>
                <div className='section-info tex-info'>{i18n.t("InternalInformation")}</div>
                <InputDropdown
                  onClick={() => {
                    if (!loadingOffice && officeOptions?.fullList?.length === 0) {
                      setLoadingOffice(true)
                    }
                    else{
                      setOfficeOptions({fullList: officeOptions?.fullList, dummyList: officeOptions?.fullList });
                    }
                  }}
                  required
                  error={props.errors?.client?.industry}
                  disabled={props.preview || existingClient}
                  loading={loadingOffice}
                  noResultsMessage={i18n.t("TryAnotherSearch")}
                  inputlabel={i18n.t("Office")}
                  placeholder={i18n.t("StartTyping")}
                  options={officeOptions?.dummyList}
                  search
                  selection
                  value={formFields?.office}
                  onChange={(e, select) =>{
                    onChangeSelect('office', select);
                    setOfficeOptions({fullList: officeOptions?.fullList, dummyList: [] });
                  }}
                />
                <InputDropdown
                    onClick={() => {
                      if (!loadingDepartments && departmentOptions?.fullList?.length === 0) {
                        setLoadingDepartments(true);
                      }
                      else{
                        setDepartmentOptions({fullList: departmentOptions?.fullList, dummyList: departmentOptions?.fullList });
                      }
                    }}
                    loading={loadingDepartments}
                    disabled={props.preview || existingClient}
                    inputlabel={i18n.t("ClientDepartment")}
                    placeholder={i18n.t("StartTyping")}
                    options={departmentOptions?.dummyList}
                    required
                    search
                    selection
                    value={formFields?.client_department}
                    onChange={(e, select) =>{
                      onChangeSelect('client_department', select);
                      setDepartmentOptions({fullList: departmentOptions?.fullList, dummyList: [] });
                    }}
                    error={props.errors?.client?.client_department}
                />
              </Grid.Column>
              <Grid.Column>
                <InputDropdown
                    onClick={() => {
                      if (!loadingPartners && partnerOptions?.fullList?.length === 0) {
                        setLoadingPartners(true)
                      }
                      else{
                        setPartnerOptions({fullList: partnerOptions?.fullList, dummyList: partnerOptions?.fullList });

                      }
                    }}
                    required
                    error={props.errors?.client?.partner}
                    disabled={props.preview || existingClient}
                    loading={loadingPartners}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("ClientPartner")}
                    placeholder={i18n.t("StartTyping")}
                    options={partnerOptions?.dummyList}
                    search
                    selection
                    value={formFields?.partner}
                    onChange={(e, select) =>{
                      onChangeSelect('partner', select);
                      setPartnerOptions({fullList: partnerOptions?.fullList, dummyList: [] });
                    }}
                  />
                <InputDropdown
                  onClick={() => {
                    if (!loadingOriginators && originatorOptions?.fullList?.length === 0) {
                      setLoadingOriginators(true)
                    }
                    else{
                      setOriginatorOptions({fullList: originatorOptions?.fullList, dummyList: originatorOptions?.fullList });
                    }
                  }}
                  required
                  error={props.errors?.client?.originator_1}
                  disabled={props.preview || existingClient}
                  loading={loadingOriginators}
                  noResultsMessage={i18n.t("TryAnotherSearch")}
                  inputlabel={i18n.t("ClientOriginator")}
                  placeholder={i18n.t("StartTyping")}
                  options={originatorOptions?.dummyList}
                  search
                  selection
                  value={formFields?.originator_1}
                  onChange={(e, select) =>{
                    onChangeSelect('originator_1', select);
                    setOriginatorOptions({fullList: originatorOptions?.fullList, dummyList: [] });
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <InputDropdown
                    onClick={() => {
                      if (!loadingManagers && managerOptions?.fullList?.length === 0) {
                        setLoadingManagers(true)
                      }
                      else{
                        setManagerOptions({fullList: managerOptions?.fullList, dummyList: managerOptions?.fullList });
                      }
                    }}
                    required
                    error={props.errors?.client?.manager}
                    disabled={props.preview || existingClient}
                    loading={loadingManagers}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("ClientManager")}
                    placeholder={i18n.t("StartTyping")}
                    options={managerOptions?.dummyList}
                    search
                    selection
                    value={formFields?.manager}
                    onChange={(e, select) =>{
                      onChangeSelect('manager', select);
                      setManagerOptions({fullList: managerOptions?.fullList, dummyList: [] });
                    }}
                  />
                <InputDropdown
                  onClick={() => {
                    if (!loadingOriginators && originatorOptions?.fullList?.length === 0) {
                      setLoadingOriginators(true)
                    }
                    else{
                      setOriginatorOptions({fullList: originatorOptions?.fullList, dummyList: originatorOptions?.fullList });
                    }
                  }}
                  error={props.errors?.client?.originator_2}
                  disabled={props.preview || existingClient}
                  loading={loadingOriginators}
                  noResultsMessage={i18n.t("TryAnotherSearch")}
                  inputlabel={i18n.t("ClientOriginator2")}
                  placeholder={i18n.t("StartTyping")}
                  options={originatorOptions?.dummyList}
                  clearable
                  search
                  selection
                  value={formFields?.originator_2}
                  onChange={(e, select) =>{
                    onChangeSelect('originator_2', select);
                    setOriginatorOptions({fullList: originatorOptions?.fullList, dummyList: [] });
                  }}
                />
              </Grid.Column>           
            </Grid.Row>
          </Grid>
          </React.Fragment>
        );
    }

    const readyForSubmit = (data, collection, isAddress = false) => {
      const auxCollection = cloneDeep(collection);
      if (existingClient) {
        return clientEmptyFields.length === 0;
      }
      if (data?.email_address && !emailIsValid(data?.email_address)){
        return false
      }
      if (data?.email_address && !emailIsValid(data?.email_address)){
        return false
      }
      if(checkSSNError('spouse_ssn') || checkSSNError('taxpayer_ssn')){
        return false
      }
      if (isAddress && data.country !== "USA") {
        auxCollection.pop();
      }
      let filled = 0;
      for (let i = 0; i < auxCollection.length; i++) {
        if (data && data[auxCollection[i]] && data[auxCollection[i]] !== "") {
          filled++
        }
        else {
          return false
        }
      }
      return filled === auxCollection.length;
    }

    return (
      <StepContainer
        collapsed={props.collapsed}
        stepNumber="1"
        stepTitle="BasicInformation"
        completed={clientEmptyFields.length === 0 && (formFields.mac_id || (isEmpty(props.errors?.client) && formFields.id))}
        new={isEmpty(props.data?.client)}
      >
        {stepContent()}
        <div className="action-section">
          {!props.preview && (
            <TableActionButton
              type="primary"
              loading={props.isSubmitting}
              disabled={!readyForSubmit(formFields, requiredFields) || !readyForSubmit(formFields?.address_information, requiredAddressFields, true) || !readyForSubmit(formFields?.billing_address, requiredAddressFields, true)}
              onClick={() => {props.handleSave("save_and_next", {client:formFields})}}
            >
              {i18n.t("SaveNext")}
            </TableActionButton>
          )}
        </div>
      </StepContainer>
    );
  };

  const mapStateToProps = state => {
    return {
      user: state.currentUserReducer.user
    };
  };
  
  export default connect(mapStateToProps)(BasicInformation);
