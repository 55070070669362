import React from "react";
import { debounce, isEqual, sortBy } from "lodash";

import DashboardService from "../../../../services/data_services/dashboard";
import ServiceService from "../../../../services/data_services/service";
import CompanySettingsService from "../../../../services/data_services/companySettings";
import { generateUuidFrom } from "../../../../services/uuid";

import WithLoadingComponent from "../../../Ui/WithLoadingComponent";
import Section from "./Section";

import LaunchFilter from "../../../LaunchProcess/LaunchFilter";

import SearchBox from "../../../Ui/SearchBox";
import Toast from "../../../../services/toasts";

import i18n from "../../../i18n";

class DashboardIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      queryParams: {},
      sections: [],
      services: [],
      sectionsLoaded: false,
      servicesLoaded: false,
      orderSettings: [],
      settingExist: false,
      widgetsOrderSettings: [],
      widgetsOrderSettingsExist: false,
    };

    this.loadSections();
    this.loadServices();

    this.companyId = props.companyId;
    this.settingsUuid = generateUuidFrom("DASHBOARD-SECTIONS");
    this.settingsWidgetSOrderUuid = generateUuidFrom("WIDGETS-ORDER-BY-SECTION");

    this.removeSection = this.removeSection.bind(this);
    this.createSection = this.createSection.bind(this);
    this.updateSection = this.updateSection.bind(this);
    this.updateSectionName = this.updateSectionName.bind(this);
    this.reorderSectionWidgets = this.reorderSectionWidgets.bind(this);
  }

  componentDidMount() {
    this.setBreadcrumb();
  }

  componentDidUpdate(_, prevState) {
    this.queryParamsChanged(prevState, this.state);
    this.sectionsLoaded(prevState, this.state);
    this.orderSettingsChange(prevState, this.state);
    //this.sectionsChanged(prevState, this.state);
  }

  loadServices = () => {
    ServiceService.loadServices()
      .then(response => {
        this.setState({
          services: response.data
        });
      })
      .finally(() => {
        this.setState({ servicesLoaded: true });
      });
  };

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Dashboard"),
        location: "/admin/dashboard"
      }
    ]);
  }

  // eslint-disable-next-line react/sort-comp
  loadSections = debounce((fromDeleteSection = false) => {
    const query = { ...this.state.queryParams };

    DashboardService.loadSections(query)
      .then(response => {
        this.setState({
          sections: response.data
        });
        if (fromDeleteSection) {
          this.setSectionsToShow(this.state.orderSettings,this.state.sections,false);
        } else {
          CompanySettingsService.loadCompanySettings(this.companyId, this.settingsUuid)
          .then(result => {
            this.setState({
              orderSettings: result.data.columns,
              settingExist: true
            },
            () => {
              this.setSectionsToShow(
                this.state.orderSettings,
                this.state.sections,
                false
              );
            });
          })
          .catch(() => {
            this.setState(prevState => {
              const orderSettings = prevState.sections.map(column => column.id);
              return { orderSettings };
            });
          });
        }
      })
      .finally(() => {
        this.setState({ sectionsLoaded: true });
      });
  }, 500);

  /*  applicationIds-> fromSettings  */
  setSectionsToShow = (applicationIds, applications, saveUserConfig = true) => {
    const intIds = applicationIds.map(id => parseInt(id, 10));

    const appsToReturn = applications.map(application => {
      const applicationCopy = { ...application };
      applicationCopy.show = intIds.includes(parseInt(application.id, 10));
      return applicationCopy;
    });

    this.sortApplications(intIds, appsToReturn, saveUserConfig);
  };

  /**
   *
   * @param ids
   * @param applications
   * @param saveUserConfig Indicates wheter changes will be saved or omited
   */
  sortApplications = (ids, applications, saveUserConfig = true) => {
    const sortedApplications = [];
    const allIds = [...ids];
    let applicationsToSort = [...applications];

    applications.forEach(application => {
      if (!allIds.includes(application.id)) {
        allIds.push(application.id);
      }
    });

    allIds.forEach(id => {
      let found = false;
      applicationsToSort = applicationsToSort.filter(application => {
        if (!found && application.id === id) {
          sortedApplications.push({ ...application });
          found = true;
          return false;
        }
        return true;
      });
    });

    this.setState(
      {
        sections: sortedApplications
      },
      () => {
        if (!this.state.isSearching && saveUserConfig) {
          const saveFunction = this.state.settingExist
            ? CompanySettingsService.updateCompanySettings
            : CompanySettingsService.createCompanySettings;
          const sectionIndices = this.state.sections.map(section => section.id?.toString());
          saveFunction(this.companyId, this.settingsUuid, this.columnsToSent()).then(
            result => {
              this.setState({
                orderSettings: result.data.columns,
                settingExist: true
              });
            }
          ).catch(e => console.log(e));
        }
      }
    );
  };

  columnsToSent = () => {
    return this.state.sections
      .filter(column => column.show)
      .map(application => application.id);
  };

  reloadSections = () => {
    this.setState(
      { 
        loadedSections: false 
      }, () => {
        this.loadSections();
      }
    )
  }

  setQueryParams(key, value) {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  }

  createSection = values => {
    DashboardService.createSection(values)
      .then(response => {
        this.setState(prevState => {
          let sections = [...prevState.sections];
          let orderSettings = [...prevState.orderSettings];
          sections = sections.filter(section => section.id);
          sections.unshift(response.data);
          orderSettings.unshift(response.data.id);

          return { sections, orderSettings };
        });
        Toast.show.sectionsCreated();
      })
      .catch(() => {
        Toast.show.sectionsNotCreated();
      });
  };

  updateSection = (id, values) => {
    DashboardService.updateSection(id, values)
      .then(() => {
        Toast.show.sectionsUpdated();
      })
      .catch(() => {
        Toast.show.sectionsNotUpdated();
      });
  };

  loadUserSettings = () => {
    CompanySettingsService.loadCompanySettings(this.companyId, this.settingsUuid)
      .then(result => {
        this.setState({
          orderSettings: result.data.columns,
          settingExist: true
        });
      })
      .catch(() => {
        this.setState(prevState => {
          const orderSettings = prevState.sections.map(column => column.id);

          return { orderSettings };
        });
      });

    CompanySettingsService.loadCompanySettings(this.companyId, this.settingsWidgetSOrderUuid)
      .then(result => {
        this.setState({
          widgetsOrderSettings: result.data.columns,
          widgetsOrderSettingsExist: true
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  updateSettings = id => {
    const saveFunction = CompanySettingsService.updateCompanySettings

    // const sectionIndices = this.state.sections.map(section => );
    const sectionIndices = this.state.sections.filter(section => section.id != id).map(section => section.id?.toString());

    saveFunction(this.companyId, this.settingsUuid, sectionIndices).then(
      result => {
        this.setState({
          orderSettings: result.data.columns,
          settingExist: true
        }, 
          this.loadSections(true)
        );
      }
    ).catch(e => console.log(e));
  };

  saveWidgetsOrder = () => {
    const saveFunction = this.state.widgetsOrderSettingsExist
      ? CompanySettingsService.updateCompanySettings
      : CompanySettingsService.createCompanySettings;

    const sectionIndices = this.state.widgetsOrderSettings.map(item => (item.id || item).toString());

    saveFunction(this.companyId, this.settingsWidgetSOrderUuid, sectionIndices).then(
      result => {
        this.setState({
          widgetsOrderSettingsExist: true,
          widgetsOrderSettings: result.data.columns
        });
      }
    ).catch(e => console.log(e));
  };

  orderWidgets = () => {
    const sections = this.state.sections.map(section => {
      section.widgets = sortBy(section.widgets, widget => {
        return this.state.widgetsOrderSettings.indexOf(`${widget.id}`);
      });
      return section;
    });

    this.setState({ sections });
  };

  reorderSectionWidgets(sectionId, sourceIndex, destinationIndex) {
    const sectionIndex = this.state.sections.findIndex(section => parseInt(section.id) === parseInt(sectionId));
    if (sectionIndex < 0) return;

    const newSections = [ ...this.state.sections ];
    const newWidgets = [ ...newSections[sectionIndex].widgets ];

    [newWidgets[sourceIndex], newWidgets[destinationIndex]] = [
      newWidgets[destinationIndex],
      newWidgets[sourceIndex]
    ];

    newSections[sectionIndex].widgets = newWidgets;

    const widgetsOrderSettings = this.state.sections.reduce((order, section) => {
      return order.concat(section.widgets.map(widget => widget.id));
    }, []);

    this.setState({ sections: newSections, widgetsOrderSettings }, this.saveWidgetsOrder);
  }


  onDragEnd = result => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const destinationIndex = destination.index;
    const sourceIndex = source.index;

    this.setState(prevState => {
      const orderSettings = [...prevState.orderSettings];
      [orderSettings[sourceIndex], orderSettings[destinationIndex]] = [
        orderSettings[destinationIndex],
        orderSettings[sourceIndex]
      ];

      return { orderSettings };
    });
  };

  _removeCreatedSection(id) {
    DashboardService.removeSection(id)
      .then(() => {
        this.updateSettings(id);
        // this.loadSections();
        Toast.show.sectionsDeleted();
      })
      .catch(() => {
        Toast.show.sectionsNotDeleted();
      });
  }

  _removeNewSection() {
    this.setState(prevState => {
      let sections = [...prevState.sections];
      sections = sections.filter(section => section.id);

      return { sections };
    });
  }

  removeSection(id = undefined) {
    id ? this._removeCreatedSection(id) : this._removeNewSection();
  }

  updateSectionName(id, name) {
    this.setState(prevState => {
      const index = prevState.sections.findIndex(s => s.id === id);

      const sections = [...prevState.sections];
      sections[index].name = name;

      return { sections };
    });
  }

  sectionPending() {
    const sections = this.state.sections || [];
    return sections.some(section => !section.id);
  }

  addSection() {
    this.setState(prevState => ({
      sections: [
        { id: undefined, name: "", widgets: [], show: true },
        ...prevState.sections
      ]
    }));
  }

  queryParamsChanged(prevState, currentState) {
    if (!isEqual(prevState.queryParams, currentState.queryParams)) {
      this.loadSections();
    }
  }

  sectionsLoaded(prevState, currentState) {
    if (prevState.sections.length === 0 && currentState.sections.length !== 0) {
      this.loadUserSettings();
    }
  }

  orderSettingsChange(prevState, currentState) {
    if (!isEqual(prevState.orderSettings, currentState.orderSettings)) {
      this.setSectionsToShow(
        this.state.orderSettings,
        this.state.sections,
        true
      );
    }
    if (!isEqual(prevState.widgetsOrderSettings, currentState.widgetsOrderSettings)) {
      this.orderWidgets();
    }
  }

  sectionsChanged(prevState, currentState) {
    const searching = Object.keys(currentState.queryParams).length > 0;
    const preValidSectionLength = prevState.sections
      ? prevState.sections.filter(section => section.id).length
      : 0;
    const validSectionsLength = currentState.sections
      ? this.state.sections.filter(section => section.id).length
      : 0;

    if (
      !searching &&
      preValidSectionLength &&
      preValidSectionLength !== validSectionsLength
    ) {
      this.saveSectionsOrder();
    }
  }

  renderAddButton() {
    let className = "ui button blue";

    if (this.sectionPending()) {
      className += " disabled";
    }

    return (
      <button
        type="button"
        className={className}
        onClick={() => this.addSection()}
      >
        {i18n.t("AddSection")}
      </button>
    );
  }

  renderSections() {
    const isSearching = Object.keys(this.state.queryParams).length > 0;
    let sectionsToShow = this.state.sections
      ? this.state.sections.filter(section => section.show)
      : undefined;
    return sectionsToShow.map((section, index) => (
      <Section
        section={section}
        services={this.state.services}
        key={section.id || -1}
        index={this.sectionPending() ? index - 1 : index}
        createSection={this.createSection}
        updateSection={this.updateSection}
        removeSection={this.removeSection}
        reorderSectionWidgets={this.reorderSectionWidgets}
        isDragDisabled={isSearching}
        reloadSections={this.reloadSections}
      />
    ))
  }

  renderFilter() {
    return (
      <LaunchFilter
        customStyle={{
          position: 'absolute',
          right: '2rem',
          top: 'auto',
          marginTop: '5px'
        }}
        disabled={this.state.sections.length === 0}
        applications={this.state.sections}
        setApplicationsToShow={this.setSectionsToShow}
      />
    );
  }

  render() {
    return (
      <div style={{ marginTop: "14px" }}>
        <div
          className="right menu desktop tableHeader"
          style={{ margin: "0 1rem" }}
        >
          <SearchBox
            loadItems={searchParams => {
              this.setQueryParams("search_param", searchParams);
            }}
            disabled={this.sectionPending()}
          />

          <div className="right menu d-flex-center">
            {this.renderAddButton()}
            <div className="ui item">{this.renderFilter()}</div>
          </div>
        </div>

        <WithLoadingComponent
          loadingCondition={() =>
            !this.state.sectionsLoaded || !this.state.servicesLoaded
          }
          contentCondition={() => this.state.sections.length}
          noItemsKey="NoSectionYet"
          className="noTopMargin"
          transparent
        >
          {this.state.sections && this.renderSections()}
        </WithLoadingComponent>
      </div>
    );
  }
}

export default DashboardIndex;
