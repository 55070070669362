import React from "react";
import { Route, Switch } from "react-router-dom";
import CloseRossum from "../Activities/CloseRossum";
import EmailData from "../Activities/EmailData";

const ServiceRoutes = props => {
  return (
    <Switch>
      <Route path="/activities/cancel">
        <CloseRossum />
      </Route>
      <Route
        path="/preview_mail/:id"
        render={routeProps => <EmailData {...props} match={routeProps.match} />}
      />
      <Route path="/">{props.children}</Route>
    </Switch>
  );
};

export default ServiceRoutes;
