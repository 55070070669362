import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "semantic-ui-react";
import Permissions from "../../services/permissions";
import { isDesktop } from "../../services/general";

class SidebarItem extends React.Component {
  active() {
    const urls = [this.props.url];

    if (this.props.altUrl) {
      if (Array.isArray(this.props.altUrl)) {
        this.props.altUrl.forEach(url => {
          urls.push(url);
        });
      }
      urls.push(this.props.altUrl);
    }

    return urls.some(url =>
      this.props.location.pathname.match(`^${url}(\\/.*)?$`)
    )
      ? "selected"
      : "";
  }

  popupStyle = _ => {
    if (this.props.width === "thin") return null;
    const style = {
      borderRadius: "8px",
      opacity: 0.8
    };
    if (this.active() === "selected") {
      style.marginLeft = "18px";
    }
    return style;
  };

  renderIcon = () => {
    if (this.props.icon === "robot") {
      return <FontAwesomeIcon icon={faRobot} className="sidebarIcon" />;
    }
    return <i className={`${this.props.icon} icon sidebarIcon`} />;
  };

  renderItem = () => {
    return (
      <Popup
        trigger={
          <Link
            to={this.props.url}
            className={`item-sb open ${this.active()}`}
            onClick={() =>
              this.props.toggleSideBar
                ? this.props.toggleSideBar(false)
                : null
            }
          >
            {this.renderIcon()}
            <p>{this.props.componentName}</p>
          </Link>
        }
        content={this.props.componentName}
        position="right center"
        pinned
        inverted
        style={this.popupStyle()}
        disabled={this.props.width === "thin" || !isDesktop()}
      />
    );
  };

  render() {
    if (this.props.dashboardIcon) {
      return this.renderItem();
    }
    return (
      <Permissions
        component={this.props.component}
        action="read"
        yes={() => {
          return this.renderItem();
        }}
        no={() => {
          return null;
        }}
      />
    );
  }
}

export default SidebarItem;
