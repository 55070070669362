import React from "react";
import { I18nextProvider } from "react-i18next";
import {
  ToastProvider,
  DefaultToastContainer
} from "react-toast-notifications";
import LoadingBar from "react-top-loading-bar";
import { isDesktop } from "../services/general";

import i18n from "./i18n";

import PushableSidebar from "./Sidebar/PushableSidebar";

import "stylesheets/Dashboard/Dashboard.scss";
import "stylesheets/semantic-ui.scss";
import "semantic-ui-css/semantic.min.css";
import "stylesheets/App.scss";
import "stylesheets/react-autosuggest.scss";

const CustomToastContainer = props => (
  <DefaultToastContainer {...props} style={{ zIndex: "9999" }} />
);

class AppContainer extends React.Component {
  constructor(props) {
    super(props);

    window.__react_toast_provider = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize() {
    this.props.toggleSideBar(isDesktop());
  }

  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <ToastProvider
          autoDismiss
          ref={window.__react_toast_provider}
          components={{
            ToastContainer: CustomToastContainer
          }}
        >
          <LoadingBar
            height={3}
            className="loadingBar"
            onRef={ref => {
              this.props.setLoadingBar(ref);
            }}
          />
          <div style={{ marginTop: "-3px" }}>
            <PushableSidebar {...this.props} />
          </div>
        </ToastProvider>
      </I18nextProvider>
    );
  }
}

export default AppContainer;
