import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  VisitableComponents,
  Actions
} from "../../services/detailedPermissionRules";
import Permissions from "../../services/permissions";
import AdminShow from "./AdminShow";
import Applications from "./Applications/Applications/Applications";
import Forms from "./Applications/Forms/Forms";
import Dashboard from "./Applications/Dashboard/Dashboard";
import Users from "./UsersManagement/Users/Users";
import Teams from "./UsersManagement/Teams/Teams";
import Emails from "./AssetManagement/Emails/Emails";
import Notfound from "../Error/Notfound";
import AdminUpperbar from "./AdminUpperbar";

class Admin extends React.Component {
  renderComponent() {
    return (
      <div className="component-wrapper">
        <Switch>
          <Route path="/admin/applications">
            <div className="noMargin-container">
              <Applications {...this.props} />
            </div>
          </Route>
          <Route path="/admin/forms">
            <>
              <AdminUpperbar />
              <div
                className="ui segment noShadow"
                style={{ minHeight: "200px" }}
              >
                <Forms {...this.props} />
              </div>
            </>
          </Route>
          <Route path="/admin/users">
            <>
              <AdminUpperbar />
              <div
                className="ui segment noShadow"
                style={{ minHeight: "200px" }}
              >
                <Users {...this.props} />
              </div>
            </>
          </Route>
          <Route path="/admin/teams">
            <>
              <AdminUpperbar />
              <div
                className="ui segment noShadow"
                style={{ minHeight: "200px" }}
              >
                <Teams {...this.props} />
              </div>
            </>
          </Route>
          <Route path="/admin/emails">
            <>
              <AdminUpperbar />
              <div
                className="ui segment noShadow"
                style={{ minHeight: "200px" }}
              >
                <Emails {...this.props} />
              </div>
            </>
          </Route>
          <Route path="/admin/dashboard">
            {this.props?.user?.company?.access_to?.dashboard && (
              <>
                <AdminUpperbar />
                <Dashboard
                  setBreadcrumb={this.props.setBreadcrumb}
                  companyId={this.props.user.company_id}
                />
              </>
            )}
          </Route>
          <Route exact path="/admin/">
            <AdminShow {...this.props} />
          </Route>
          <Route>
            <Notfound />
          </Route>
        </Switch>
      </div>
    );
  }

  render() {
    return (
      <Permissions
        component={VisitableComponents.Admin}
        action={Actions.read}
        yes={() => {
          return this.renderComponent();
        }}
        no={() => {
          return <Redirect to="/" />;
        }}
      />
    );
  }
}

export default Admin;
