import React from "react";
import { Icon } from "semantic-ui-react";
import NavSection from "./NavSection";

import "../../../../assets/stylesheets/UI/UpperBar.scss";

export default function Upperbar(props) {
  return (
    <div className="d-flex-center-sb UpperBar-container">
      <div className="tittle">
        <span>{props.title}</span>
        <span>{props.subtitle}</span>
      </div>
      <div className="nav-container">
        <NavSection
          prevItem={props.prevItem}
          onPrevClick={props.onPrevClick}
          nextItem={props.nextItem}
          onNextClick={props.onNextClick}
          loading={props.loadingNavigation}
        />
        {props.onCancel && (
          <div
            role="button"
            tabIndex={0}
            onClick={props.onCancel}
            className="back-button d-flex-center-c"
          >
            <Icon name="times" />
          </div>
        )}
      </div>
    </div>
  );
}
