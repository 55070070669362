import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import i18n from "../../i18n";
import TableActionButton from "../../Ui/TableActionButton";
import StepContainer from "./StepContainer";
import Review from "./Review/Review";

const FormReview = ({ data = {}, ...props }) => {
  return (
    <React.Fragment>
      <StepContainer
        stepNumber="4"
        stepTitle="Review"
        completed="last"
        collapsed={props.collapsed}
        disabled={props.currentStep === 1}
      >
        <Review {...props} preview={props.preview} data={data} fromForm />
        <div className="action-section">
          {!props.preview && props.isApprover && (
            <TableActionButton
              type="primary"
              disabled={props.isSubmitting || !isEmpty(props.errors)}
              onClick={() => {
                props.onSubmitAndApprove();
              }}
            >
              {i18n.t("SubmitApprove")}
            </TableActionButton>
          )}
          {!props.preview && (
            <TableActionButton
              type="primary"
              disabled={
                !isEmpty(props.errors) ||
                !data?.engagement_info?.partner_in_charge_name
              }
              loading={props.isSubmitting}
              onClick={() => {
                props.handleSave("submit", data);
              }}
            >
              {i18n.t("Submit")}
            </TableActionButton>
          )}
        </div>
      </StepContainer>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(FormReview);
