import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import i18n from "../../i18n";
import { Action, Type } from "./TableActions";

const TableRowDropdownItem = props => {
  const show = () => {
    return Object.prototype.hasOwnProperty.call(props.action, "show")
      ? props.action.show(props.item, props.user)
      : true;
  };

  const renderActionLink = () => {
    // eslint-disable-next-line no-template-curly-in-string
    const link = props.action.url.replace("${id}", props.item.id);

    return (
      <Dropdown.Item
        icon={props.action.button_icon}
        text={i18n.t(props.action.button_text)}
        disabled={props.disabled}
        as={Link}
        to={link}
      />
    );
  };

  const renderActionModal = () => {
    return (
      <Dropdown.Item
        icon={props.action.button_icon}
        text={i18n.t(props.action.button_text)}
        disabled={props.disabled}
        onClick={() => props.openModal(props.action, props.item)}
      />
    );
  };

  const renderActionClick = () => {
    return (
      <Dropdown.Item
        icon={props.action.button_icon}
        text={i18n.t(props.action.button_text)}
        disabled={props.disabled}
        onClick={() => props.action.onClick(props.item)}
      />
    );
  };

  const renderActionContent = () => {
    return (
      <Dropdown.Item
        icon={props.action.button_icon}
        text={i18n.t(props.action.button_text)}
        disabled={props.disabled}
        onClick={() => props.toggleEditRow(props.item)}
      />
    );
  };

  const renderAction = () => {
    switch (props.action.type) {
      case Type.Link:
        return renderActionLink();
      case Type.Modal:
        return renderActionModal();
      case Type.Click:
        return renderActionClick();
      default:
        return renderActionContent();
    }
  };

  if (!show() || (props.action.action === Action.Delete && props.item.locked)) {
    return null;
  }

  return renderAction();
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(TableRowDropdownItem);
