import React from "react";
import { Icon, Checkbox } from "semantic-ui-react";
import MobileColumn from "./MobileColumn";
import TableDropdown from "../TableDropdown";
import { activityDate } from "../../../../services/string";
import { displayActivityStatus } from "../../../../constants/status";

const MobileRow = ({
  item = {},
  name = "name",
  timestamp = "updated_at",
  status = "status",
  columns = [],
  onRowClick,
  ...props
}) => {
  const renderSideItem = () => {
    if (props.actions?.length > 0) {
      return <TableDropdown item={item} {...props} />;
    }
    if (onRowClick) {
      return <Icon fitted name="chevron right" />;
    }
    return null;
  };

  const nameContent = () => {
    const column = columns.find(col => col.key === name);
    if (column?.method) {
      return column.method(item[name]);
    }
    return item[name];
  };

  const statusContent = () => {
    const column = columns.find(col => col.key === status);
    if (column?.method) {
      return column.method(item[status]);
    }
    return displayActivityStatus(item[status]);
  };

  const visibleColumns = columns.filter(
    column => column.show && ![name, timestamp, status].includes(column.key)
  );

  const isSelected = props.selectedElementIds
    ?.map(element => element.id)
    .includes(item.id);

  return (
    <li className="table-element">
      <div className="element-header">
        {props.onCheckboxChange && (
          <div className="checkbox-container">
            <Checkbox
              checked={isSelected}
              onChange={() => props.onCheckboxChange(item)}
            />
          </div>
        )}
        <div
          className="header-container"
          tabIndex={0}
          role="link"
          onClick={() => {
            if (onRowClick) {
              onRowClick(item);
            }
          }}
        >
          <div className="name-timestamp">
            <div className="name">{nameContent()}</div>
            <div className="timestamp">{activityDate(item[timestamp])}</div>
          </div>
          <div className="status-container">
            <span className="status-info">{statusContent()}</span>
            <span className="side-item-container">{renderSideItem()}</span>
          </div>
        </div>
      </div>
      <ul className="other-columns">
        {visibleColumns?.map(column => (
          <MobileColumn key={column.key} column={column} item={item} />
        ))}
      </ul>
    </li>
  );
};

export default MobileRow;
