import React, { useState } from "react";
import { usePdf } from "@mikecousins/react-pdf";

const PDFViewer = props => {
  const [error, setError] = useState(false);
  const { pdfDocument } = usePdf({
    file: props.url,
    page: props.documentPage,
    canvasRef: props.documentRef,
    scale: props.scale,
    onPageRenderSuccess: () => props.onDocumentLoad(),
    onDocumentLoadFail: () => setError(true)
  });

  const renderLoading = () => (
    <div
      className="ui active inverted dimmer"
      style={{ marginTop: "50px", backgroundColor: "inherit" }}
    >
      <div className="ui text loader" />
    </div>
  );

  return (
    <>
      {(!pdfDocument || error) && renderLoading()}
      {pdfDocument && <canvas ref={props.documentRef} />}
    </>
  );
};

export default PDFViewer;
