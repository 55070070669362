import React from "react";
import { isEmpty } from "lodash";

const DatePickerForm = props => {
    const showErrors =_=>{
        // return (typeof(props.value) === 'String') && isEmpty(props.value) && props.error
        return isEmpty(props.value) && props.error
    }
    return (
        <div className={`datepickerContainer ${showErrors() && 'errorDate'}`}>
            <div className="inputField-label">
                {props.inputlabel}{props.required && '*'}
            </div>
            {props.children}
            {showErrors() && <div className='inputDateField-error'>{props.error}</div>}
        </div>
    );
};

DatePickerForm.defaultProps = {
  inputlabel: '',
  required: false,
};

export default DatePickerForm;
