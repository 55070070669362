import axios from "../axios";

class DashboardService {
  static loadSections = (params = null) => {
    return axios.get("/sections", {
      params
    });
  };

  /**
   * Retrieve all the possible sections for that company.
   * EG: [
   *    {
   *     id:1,
   *     name: "Widget1",
   *     widgets: [1,2,3]
   *   },
   *    {
   *     id:2,
   *     name: "Widget2",
   *     widgets:[4, 5, 6]
   *   }
   * ]
   * @returns Axios Promise
   */
  static loadAvailableSections = () => {
    return axios.get("/dashboard/available_sections");
  };

  static createSection = values => {
    return axios.post("/sections", values);
  };

  static updateSection = (id, values) => {
    return axios.put(`/sections/${id}`, values);
  };

  static removeSection = id => {
    return axios.delete(`/sections/${id}`);
  };

  static sectionsByUser = () => {
    return axios.get("/dashboard/sections_by_user");
  };

  /**
   * Get all widgets data of a section.
   * Request response example:
   * [
   *   {
   *     chart_data: [
   *        ['Label1', 'Label2', 'Label3', 'Label4'],
   *        ['Value1_Label1', 'Value1_Label2', 'Value1_Label3', 'Value1_Label4'],
   *        ['Value2_Label1', 'Value2_Label2', 'Value2_Label3', 'Value2_Label4'],
   *        ['Value3_Label1', 'Value3_Label2', 'Value3_Label3', 'Value3_Label4'],
   *        ['Value4_Label1', 'Value4_Label2', 'Value4_Label3', 'Value4_Label4']
   *      ],
   *      options: {title: 'Widgettitle', xAxis: 'Month', yAxis: 'Robots'},
   *      chart_type: "horizontalBar",
   *      created_at: "2020-04-28T09:51:24.281Z",
   *      description: null,
   *      function_inputs: [],
   *      id: 21,
   *      ame: "HandlingTime",
   *      procedure: "Aria_BPDS_AverageHandlingTime",
   *      section_id: 7,
   *      service_id: 1,
   *      updated_at: "2020-08-28T06:43:57.302Z"
   *    },
   *    {
   *      chart_data: [
   *        ['Label1', 'Label2', 'Label3', 'Label4'],
   *        ['Value1_Label1', 'Value1_Label2', 'Value1_Label3', 'Value1_Label4'],
   *        ['Value2_Label1', 'Value2_Label2', 'Value2_Label3', 'Value2_Label4'],
   *        ['Value3_Label1', 'Value3_Label2', 'Value3_Label3', 'Value3_Label4'],
   *        ['Value4_Label1', 'Value4_Label2', 'Value4_Label3', 'Value4_Label4']
   *      ],
   *      options: {title: 'Widgettitle', xAxis: 'Month', yAxis: 'Robots'},
   *      chart_type: "horizontalBar",
   *      created_at: "2020-04-28T09:51:24.281Z",
   *      description: null,
   *      function_inputs: [],
   *      id: 21,
   *      name: "HandlingTime",
   *      procedure: "Aria_BPDS_AverageHandlingTime",
   *      section_id: 7,
   *      service_id: 1,
   *      updated_at: "2020-08-28T06:43:57.302Z"
   *    }
   *  ]
   *
   * @param id Section ID
   * @returns {*} Axios promise
   */
  static dataForSectionByUser = id => {
    return axios.get("/dashboard/data_for_section_by_user", { params: {section_id: id} });
  };
}

export default DashboardService;
