import React from "react";
import { ErrorMessage, Formik, Field } from "formik";
import * as Yup from "yup";
import i18n from "../i18n";
import Select from "react-select";
import CompanyService from "../../services/data_services/company";
import Toast from "../../services/toasts";
import SubmitButton from "../SubmitButton";
import ApplicationsRadioInput from "../Admin/Applications/Applications/ApplicationsRadioInput";

class CompaniesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo_sidebar: props?.company?.logo_sidebar,
      logo_compact: props?.company?.logo_compact,
      logo_sidebar_exist: false,
      logo_compact_exist: false
    };

  }

  formText = () => {
    return this.props.company.id ? "EditCompanyInfo" : "AddCompanyInfo";
  };

  submitText = () => {
    return this.props.company.id ? "SaveButton" : "AddButton";
  };

  getImage = (target) => {
    if (this.state?.[target]) {
      return this.state[target];
    }
    return '';
  };

  createCompany = (values, submittingFormik) => {
    const valuesToPost = values;
    valuesToPost.active = true;

    CompanyService.createCompany(valuesToPost)
      .then(() => {
        Toast.show.companyCreated();
        this.props.loadCompanies();
        submittingFormik(false);
        this.props.closeModal();
      })
      .catch(e => {
        submittingFormik(false);
        Toast.displayError(
          e.response.data.message,
          i18n.t("CompanyNotCreated")
        );
      });
  };

  updateCompany = (values, submittingFormik) => {
    CompanyService.updateCompany(this.props.company.id, this.prepareValues(values))
      .then(() => {
        Toast.show.companyUpdated();
        this.props.loadCompanies();
        submittingFormik(false);
        this.props.closeModal();
      })
      .catch(e => {
        submittingFormik(false);
        Toast.displayError(
          e.response.data.message,
          i18n.t("CompanyNotUpdated")
        );
      });
  };

  prepareValues = values => {
    let AuxValues = {};    
    AuxValues.name= values.name;
    AuxValues.admin_email= values.admin_email;
    AuxValues.description= values.description;
    AuxValues.access_to = {};
    AuxValues.access_to.dashboard = values.dashboard;
    AuxValues.access_to.case_view = values.case_view;
    AuxValues.access_to.launcher = values.launcher;
    AuxValues.access_to.ncw = values.ncw;
    AuxValues.access_to.rpa_management = values.rpa_management;
    AuxValues.access_to.scheduling = values.scheduling;
    AuxValues.access_to.init_module = values.init_module;
    if(this.state.logo_compact_exist)
      AuxValues.logo_compact = values?.logo_compact;
    if(this.state.logo_sidebar_exist)
      AuxValues.logo_sidebar = values?.logo_sidebar;
    // return AuxValues;

    let formData = new FormData();
    for(let dataKey in AuxValues) {
      if(dataKey === 'access_to') {
        for (let previewKey in AuxValues[dataKey]) {
          formData.append(`access_to[${previewKey}]`, AuxValues[dataKey][previewKey]);
        }
      }
      else {
        formData.append(dataKey, AuxValues[dataKey]);
      }
    }
    return formData;
  }

  availableModules = values => {
    let options=[];
    if(values.dashboard === "true"){
      options.push({label:"Dashboard", value:""})
    }
    if(values.launcher === "true"){
      options.push({label:"Launcher", value:"launch"})
    }
    if(values.rpa_management === "true"){
      options.push({label:"RPA Managment", value:"resources"})
    }
    if(values.scheduling === "true"){
      options.push({label:"scheduling", value:"scheduler_monitor"})
    }
    return options
  }

  selectedModule = values => {
    let auxValue = this.availableModules(values)
    auxValue = auxValue.find(resource => values.init_module === resource.value);
    return typeof(auxValue) === "undefined" ? null : auxValue;
  }

  renderButtons(isSubmitting) {
    return (
      <div
        className="ui text two column centered grid"
        style={{ paddingBottom: "25px" }}
      >
        <div className="row">
          {this.props.company.id ? (
            <div className="eight wide column">
              <button
                className="ui button basic blue"
                style={{ width: "100%" }}
                type="button"
                onClick={() => this.props.closeModal()}
              >
                {i18n.t("CancelButton")}
              </button>
            </div>
          ) : null}
          <div className="eight wide column">
            <SubmitButton
              label="SaveButton"
              style={{ width: "100%" }}
              isSubmitting={isSubmitting}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <p style={{ textAlign: "center" }}>{i18n.t(this.formText())}</p>

        <Formik
          initialValues={{
            name: this.props.company.name || "",
            admin_email: this.props.company.admin_email || "",
            description: this.props.company.description || "",
            dashboard: this.props.company?.access_to?.dashboard || "true",
            case_view: this.props.company?.access_to?.case_view || "true",
            launcher: this.props.company?.access_to?.launcher || "true",
            ncw: this.props.company?.access_to?.ncw || "true",
            rpa_management: this.props.company?.access_to?.rpa_management || "true",
            scheduling: this.props.company?.access_to?.scheduling || "true",
            init_module: this.props.company?.access_to?.init_module || "",
            logo_sidebar: this.props?.company?.logo_sidebar || null,
            logo_compact: this.props?.company?.logo_compact || null
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(
              i18n.t("CompanyNameRequiredValidation")
            ),
            admin_email: Yup.string().required(
              i18n.t("EmailRequiredValidation")
            ),
            description: Yup.string(),
            dashboard: Yup.boolean(),
            case_view: Yup.boolean(),
            launcher: Yup.boolean(),
            ncw: Yup.boolean(),
            rpa_management: Yup.boolean(),
            scheduling: Yup.boolean(),
            init_module: Yup.string(),
          })}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            this.props.company.id
              ? this.updateCompany(values, setSubmitting)
              : this.createCompany(values, setSubmitting);
          }}
        >
          {({ values, handleChange, setFieldValue, handleSubmit, isSubmitting, errors }) => (
            <form className="ui form container" onSubmit={handleSubmit}>
              <div
                className={`field required ${errors.name ? "withError" : ""}`}
              >
                <label>{i18n.t("CompanyName")}</label>
                <input
                  name="name"
                  autoComplete="off"
                  onChange={handleChange}
                  placeholder={i18n.t("CompanyNamePlaceholder")}
                  value={values.name}
                />
                <ErrorMessage name="name" component="div" className="error" />
              </div>
              <div
                className={`field required ${
                  errors.admin_email ? "withError" : ""
                }`}
              >
                <label>{i18n.t("Email")}</label>
                <input
                  name="admin_email"
                  autoComplete="off"
                  onChange={handleChange}
                  placeholder={i18n.t("EmailPlaceholder")}
                  value={values.admin_email}
                />
                <ErrorMessage
                  name="admin_email"
                  component="div"
                  className="error"
                />
              </div>
              <div className={`field ${errors.description ? "withError" : ""}`}>
                <label>{i18n.t("Description")}</label>
                <input
                  name="description"
                  autoComplete="off"
                  onChange={handleChange}
                  placeholder={i18n.t("DescriptionPlaceholder")}
                  value={values.description}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>
              <div className="field inlineField" key="dashboard">
                <ApplicationsRadioInput
                  title={i18n.t("Dashboard")}
                  name="dashboard"
                  fields={[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" }
                  ]}
                  onChange={e => {
                    setFieldValue('dashboard', e.currentTarget.value)
                    setFieldValue('init_module','')
                  }}
                  values={values}
                />
              </div>
              <div className="field inlineField" key="case_view">
                <ApplicationsRadioInput
                  title={i18n.t("CaseView")}
                  name="case_view"
                  fields={[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" }
                  ]}
                  onChange={e => {
                    setFieldValue('case_view', e.currentTarget.value)
                  }}
                  values={values}
                />
              </div>
              <div className="field inlineField" key="ncw">
                <ApplicationsRadioInput
                  title={i18n.t("NewClientWorksheet")}
                  name="ncw"
                  fields={[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" }
                  ]}
                  onChange={e => {
                    setFieldValue('ncw', e.currentTarget.value)
                  }}
                  values={values}
                />
              </div>
              <div className="field inlineField" key="launcher">
                <ApplicationsRadioInput
                  title={i18n.t("launcher")}
                  name="launcher"
                  fields={[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" }
                  ]}
                  onChange={e => {
                    setFieldValue('launcher', e.currentTarget.value)
                    setFieldValue('init_module','')
                  }}
                  values={values}
                />
              </div>
              <div className="field inlineField" key="scheduling">
                <ApplicationsRadioInput
                  title={i18n.t("scheduling")}
                  name="scheduling"
                  fields={[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" }
                  ]}
                  onChange={e => {
                    setFieldValue('scheduling', e.currentTarget.value)
                    setFieldValue('init_module','')
                  }}
                  values={values}
                />
              </div>
              <div className="field inlineField" key="rpa_management">
                <ApplicationsRadioInput
                  title={i18n.t("rpa_management")}
                  name="rpa_management"
                  fields={[
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" }
                  ]}
                  onChange={e => {
                    setFieldValue('rpa_management', e.currentTarget.value)
                    setFieldValue('init_module','')
                  }}
                  values={values}
                />
              </div>

              <div className="field inlineField">
                <label>{i18n.t("InitModule")}</label>
                <Select
                  options={this.availableModules(values)}
                  value={this.selectedModule(values)}
                  isDisabled={this.availableModules(values).length===0}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder={`${i18n.t("Select a module")}...`}
                  onChange={option => {
                    setFieldValue(
                      "init_module",
                      option ? option.value : undefined
                    );
                  }}
                />
              </div>
              
              <div
                className={`field ${errors.name ? "withError" : ""}`}
              >
                <label>{i18n.t("Expanded Logo")}</label>
                <div className="d-flex-center">
                  <div
                    id="expandedLogo"
                    className="company-logo-preview"
                    style={{
                      backgroundImage: `url(${this.getImage('logo_sidebar')})`
                    }}
                  />
                  <button
                    className="circular ui icon button "
                    type="button"
                    onClick={() => {
                      $("#logo_sidebar").click();
                    }}
                  >
                    <i className="icon pencil" />
                  </button>
                {values.logo_sidebar && 
                <button
                  className="circular ui icon button "
                  type="button"
                  onClick={() => {
                    this.setState({
                      logo_sidebar: '',
                      logo_sidebar_exist: true
                    })
                    setFieldValue(
                      "logo_sidebar", '');
                    $("#logo_sidebar").val(''); 
                  }}
                >
                  <i className="icon trash" />
                </button>}
              </div>
              <div className="field" style={{ display: "none" }}>
                <Field
                  type="file"
                  name="logoSidebar"
                  id="logo_sidebar"
                  onChange={event => {
                    this.setState({
                      logo_sidebar: event.currentTarget.files[0],
                      logo_sidebar_exist: true
                    })
                    setFieldValue(
                      "logo_sidebar",
                      event.currentTarget.files[0]
                    );
                    $("#expandedLogo").css(
                      "background-image",
                      `url("${window.URL.createObjectURL(
                        event.currentTarget.files[0]
                      )}")`
                    );
                  }}
                />
                </div>
              </div>
              
              <div
                className={`field ${errors.name ? "withError" : ""}`}
              >
              <label>{i18n.t("Compact Logo")}</label>
              <div className="d-flex-center">
                <div
                  id="compactLogo"
                  className="company-logo-preview"
                  style={{
                    backgroundImage: `url(${this.getImage('logo_compact')})`
                  }}
                />
                <button
                  className="circular ui icon button "
                  type="button"
                  onClick={() => {
                    $("#logo_compact").click();
                  }}
                >
                  <i className="icon pencil" />
                </button>
                {values.logo_compact && 
                <button
                  className="circular ui icon button "
                  type="button"
                  onClick={() => {
                    this.setState({
                      logo_compact: '',
                      logo_compact_exist: true
                    })
                    setFieldValue(
                      "logo_compact", '');
                    $("#logo_compact").val(''); 
                  }}
                >
                  <i className="icon trash" />
                </button>}
              </div>
              <div className="field" style={{ display: "none" }}>
                <Field
                  type="file"
                  name="logoCompact"
                  id="logo_compact"
                  onChange={event => {
                    this.setState({
                      logo_compact: event.currentTarget.files[0],
                      logo_compact_exist: true
                    })
                    setFieldValue(
                      "logo_compact",
                      event.currentTarget.files[0]
                    );
                    $("#compactLogo").css(
                      "background-image",
                      `url("${window.URL.createObjectURL(
                        event.currentTarget.files[0]
                      )}")`
                    );
                  }}
                />
                </div>
              </div>

              {this.renderButtons(isSubmitting)}
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default CompaniesForm;
