import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import i18n from "../../i18n";
import Queues from "./Queues/Queues";
import Alerts from "./Alerts/Alerts";
import EnvVariables from "./EnvVariables/EnvVariables";
import Resources from "./Resources/Resources";
import Credentials from "./Credentials/Credentials";
import { tabActive } from "../../../services/url";
import Permissions from "../../../services/permissions";
import {
  VisitableComponents,
  Actions
} from "../../../services/detailedPermissionRules";

const AssetsIndex = props => {
  const active = name => {
    return tabActive(props.location.pathname, name);
  };

  const checkAccess = () => props.user.teams.some(team => team.rpa_management);

  const tabsInformation = [
    { label: "Resources", path: "resources" },
    { label: "Queues", path: "queues" },
    { label: "EnvVariables", path: "env_variables" },
    { label: "Credentials", path: "credentials" }
    //{label: "Alerts", path: "alerts"},
  ];

  const addAlertsTab = _ => {
    switch (window.location.hostname) {
      case "localhost":
      case "previewaria.invokeinc.com":
      case "ariadev.invokeinc.com":
        return true;
      default:
        return false;
    }
  };

  const renderTabs = () => {
    if (addAlertsTab()) {
      tabsInformation.push({ label: "Alerts", path: "alerts" });
    }
    return (
      <div id="customMenu" className="ui secondary pointing menu">
        {tabsInformation.map((tab, index) => 
          <a
            key={`${index}_${tab.path}`}
            className={`item ${active(tab.path.toLowerCase())}`}
            data-tab={tab.label}
            role="button"
            aria-label={i18n.t(tab.label)}
            tabIndex={0}
            onClick={() => {
              props.history.push(`/${tab.path}`);
            }}
          >
            {i18n.t(tab.label)}
          </a>
        )}
      </div>
    );
  };

  const renderRoutes = () => {
    return (
      <Switch>
        <Route path="/credentials">
          <div className="ui segment noShadow" style={{ minHeight: "200px" }}>
            <Credentials {...props} />
          </div>
        </Route>
        <Route path="/resources">
          <div className="ui segment noShadow" style={{ minHeight: "200px" }}>
            <Resources {...props} />
          </div>
        </Route>
        <Route path="/queues">
          <Queues {...props} />
        </Route>
        <Route path="/env_variables">
          <div className="ui segment noShadow" style={{ minHeight: "200px" }}>
            <EnvVariables {...props} />
          </div>
        </Route>
        <Route path="/alerts">
          <div className="ui segment noShadow" style={{ minHeight: "200px" }}>
            <Alerts {...props} />
          </div>
        </Route>
      </Switch>
    );
  };

  const renderComponent = () => {
    return (
      <React.Fragment>
        {renderTabs()}
        <div style={{ marginTop: "38px" }}>
          <div className="component-wrapper">{renderRoutes()}</div>
        </div>
      </React.Fragment>
    );
  };

  if (checkAccess()) {
    return (
      <Permissions
        component={VisitableComponents.RpaAssets}
        action={Actions.read}
        yes={() => renderComponent()}
        no={() => <Redirect to="/" />}
      />
    );
  }

  return <Redirect to="/" />;
};

export default AssetsIndex;
