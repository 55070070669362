import React from "react";
import { Redirect } from "react-router";
import i18n from "../../../i18n";
import axios from "../../../../services/axios";
import Toast from "../../../../services/toasts";
import ApplicationsForm from "./ApplicationsForm";
import UpperBar from "./UpperBar";
import WithLoadingComponent from "../../../Ui/WithLoadingComponent";

class ApplicationsNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      returnToApplications: false,
      application: undefined
    };

    this.setStateRef = this.setState.bind(this);

    this.setBreadcrumb();

    if (this.props.match.params.id) {
      this.loadApplication();
    }
  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Applications"),
        location: "/admin/applications"
      },
      {
        title: this.title(),
        location: "/admin/applications/new"
      }
    ]);
  }

  getProcessEntity(processEntity) {
    if (
      processEntity.name === "" &&
      processEntity.description === "" &&
      processEntity.service_id === "" &&
      processEntity.rpa_process === "" &&
      processEntity.rpa_queues.length === 0
    ) {
      return undefined;
    }
    return processEntity;
  }

  createApplications = (values, setSubmitting) => {
    const valuesToPost = { ...values };

    valuesToPost.process_entity = this.getProcessEntity(
      valuesToPost.process_entity
    );

    axios
      .post("/applications", valuesToPost)
      .then(() => {
        Toast.show.applicationsCreated();
        setSubmitting(false);
        this.setState({
          returnToApplications: true
        });
      })
      .catch(() => {
        Toast.show.applicationsNotCreated();
        setSubmitting(false);
      });
  };

  loadApplication() {
    axios.get(`/applications/${this.props.match.params.id}`).then(response => {
      this.setState(
        {
          application: response.data
        },
        () => {
          this.setBreadcrumb();
          this.props.loadingBar.complete();
        }
      ).catch(() => {
        this.setState({
          application: {}
        });
      });
    });
  }

  title() {
    return this.state.application
      ? `${i18n.t("New Application")} - ${this.state.application.name}`
      : `${i18n.t("New Application")}`;
  }
  cancelForm = _ => {
    this.setState({
      returnToApplications: true
    });
  }

  render() {
    if (this.state.returnToApplications) {
      return <Redirect push to="/admin/applications" />;
    }

    return (
      <WithLoadingComponent
        loadingCondition={() =>
          this.props.match.params.id && !this.state.application
        }
        contentCondition={() =>
          (this.props.match.params.id && this.state.application.id) ||
          !this.props.match.params.id
        }
        noItemsKey="NotAbleLoadApplication"
        noClassName
      >
        <UpperBar
          application={this.state.application} 
          cancelForm={this.cancelForm}/>
        <ApplicationsForm
          user={this.props.user}
          setState={this.setStateRef}
          onSubmit={this.createApplications}
          application={this.state.application}
        />
      </WithLoadingComponent>
    );
  }
}

export default ApplicationsNew;
