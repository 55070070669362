import React from "react";
import { Route, Switch } from "react-router-dom";
import ApplicationsNew from "./ApplicationsNew";
import ApplicationsTable from "./ApplicationsTable";
import ApplicationsShow from "./ApplicationsShow";

const Applications = props => {
  return (
    <Switch>
      <Route
        path="/admin/applications/new/:id?"
        render={routeProps => (
          <ApplicationsNew {...props} match={routeProps.match} />
        )}
      />
      <Route
        path="/admin/applications/:id"
        render={routeProps => (
          <ApplicationsShow {...props} match={routeProps.match} />
        )}
      />
      <Route path="/admin/applications">
        <ApplicationsTable {...props} />
      </Route>
    </Switch>
  );
};

export default Applications;
