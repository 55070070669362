import React from "react";
import { Redirect } from "react-router";
import WidgetNew from "./WidgetNew";
import WidgetService from "../../../../../services/data_services/widget";
import Toast from "../../../../../services/toasts";
import i18n from "../../../../i18n";
import WidgetForm from "./WidgetForm";

class WidgetEdit extends WidgetNew {
  constructor(props) {
    super(props);

    this.state.widget = undefined;

    WidgetService.load(this.props.match.params.widget_id)
      .then(response => {
        if (response.data) {
          const widget = response.data;
          widget.data = JSON.parse(widget.data);
          this.setState({ widget });
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Dashboard"),
        location: "/admin/dashboard"
      },
      {
        title: "Edit widget",
        location: "/admin/dashboard"
      }
    ]);
  }

  onSubmit = (values, setSubmitting) => {
    WidgetService.save({ ...values, id: this.props.match.params.widget_id })
      .then(() => {
        Toast.show.widgetsUpdated();
        setSubmitting(false);
        this.returnToDashboard();
      })
      .catch(() => {
        Toast.show.widgetsNotCreated();
        setSubmitting(false);
      });
  };

  render() {
    if (this.state.returnToDashboard) {
      return <Redirect push to="/admin/dashboard" />;
    }

    return (
      <div className="ui segment widget-form-container">
        <WidgetForm
          onSubmit={this.onSubmit}
          onCancel={this.returnToDashboard}
          match={this.props.match}
          data={this.state.widget}
        />
      </div>
    );
  }
}

export default WidgetEdit;
