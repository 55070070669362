import React, { useState } from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";

const RossumIframe = props => {
  const [fullscreen, setFullscreen] = useState(false);

  const resizableStyle = { width: "75%", height: "90%" };

  return (
    <TransitionablePortal
      open={props.open}
      transition={{
        animation: "scale",
        duration: 500
      }}
      className="transitionablePortal"
    >
      <Modal
        open
        style={fullscreen ? {} : resizableStyle}
        className={`${fullscreen ? "fullScreen" : "resizable"}`}
        closeOnDimmerClick
      >
        <div className="rossumModalHeader">
          <i
            className={`${fullscreen ? "compress" : "expand"} icon handcursor`}
            onClick={() => setFullscreen(!fullscreen)}
          />
          <i
            className="close icon handcursor"
            onClick={() => {
              props.onClose();
              setFullscreen(false);
            }}
          />
        </div>
        <iframe
          title="Rossum Document"
          src={props.url}
          height="100%"
          width="100%"
        />
      </Modal>
    </TransitionablePortal>
  );
};

export default RossumIframe;
