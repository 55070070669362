import axios from "../axios";

class SchedulerService {
  static loadScheduler = id => {
    return axios.get(`/schedulers/${id}`);
  };

  static loadSchedulers = (params = null) => {
    return axios.get("/schedulers", { params });
  };

  static createScheduler = params => {
    return axios.post("/schedulers", params);
  };

  static updateSchedulers = (id, params) => {
    return axios.put(`/schedulers/${id}`, params);
  };

  static loadSlaOptions = () => {
    return axios.get("/schedulers/sla_options");
  };

  static loadNotificationOptions = () => {
    return axios.get("/schedulers/notification_options");
  };

  static loadCalendarOptions = service_id => {
    return axios.get("/schedulers/calendars", { params: { service_id } });
  };

  static getCronText = cron_expression => {
    return axios.get("/schedulers/cron_description", {
      params: { cron_expression }
    });
  };

  /**
   * Make changes in scheduler form values to fit backend requeriments
   */

  static splitEmailAddresses = emails => {
    return emails.split(",").map(email => email.trim());
  };

  static prepareValuesFromFormValuesHelper(values) {
    values.processes.forEach((process, process_index) => {
      if (values.processes[process_index].data) {
        values.processes[process_index].data = Object.values(process.data);
        values.processes[process_index].data.forEach((item, item_index) => {
          values.processes[process_index].data[item_index].name = values.processes[process_index].data[item_index].name.replace(`processes.${process_index}.data.`, '');
        })
      }
    });

    if (
      values.sla_type === "other" &&
      values.sla_notification_to &&
      !Array.isArray(values.sla_notification_to)
    ) {
      values.sla_notification_to = this.splitEmailAddresses(values.sla_notification_to);
    }

    if (
      values.deadline_type === "other" &&
      values.deadline_notification_to &&
      !Array.isArray(values.deadline_notification_to)
    ) {
      values.deadline_notification_to = this.splitEmailAddresses(
        values.deadline_notification_to
      );
    }

    if (values.sla_default) {
      values.sla_default = undefined;
    }

    return values;
  }
}

export default SchedulerService;
