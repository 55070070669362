import { LOAD_FILTERS, DELETE_FILTERS } from "../constants/action-types";

const initialState = {};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FILTERS:
      return {
        [action.appUuid]: action.payload
      };
    case DELETE_FILTERS:
      return initialState;
    default:
      return state;
  }
};

export default filtersReducer;
