import React from "react";
import { Modal } from "semantic-ui-react";
import { ReactFormGenerator } from "react-ui-form-builder-attachment";
import i18n from "../../../i18n";

const FormsModal = props => {
  return (
    <Modal
      size="small"
      open={props.open}
      closeOnEscape
      closeOnDimmerClick
      onClose={props.close}
      style={{ overflow: "visible" }}
    >
      <Modal.Header>
        <div className="title">
          {props.title || i18n.t("FormName")}
          <i
            role="button"
            aria-label="Close"
            tabIndex={0}
            className="close icon"
            onClick={props.close}
          />
        </div>
      </Modal.Header>
      <Modal.Content image>
        <div>
          <ReactFormGenerator
            data={props.configuration}
            answer_data={[]}
            hide_actions
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default FormsModal;
