import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { DateInput } from "semantic-ui-calendar-react-yz";
import CredentialsModal from "./CredentialsModal";
import Table from "../../../Ui/Table/Table";
import { TYPES } from "../../../Ui/Table/types";
import ValueInput from "../../../Scheduler/Schedulers/InputFields/InputField";
import i18n from "../../../i18n";
import Toast from "../../../../services/toasts";
import CredentialService from "../../../../services/data_services/credentials";
import { Type, tableActions } from "../../../Ui/Table/TableActions";
import { displayDate, displayPassword } from "../../../../services/string";
import { getDateFormat } from "../../../../services/timezones";
import useQueryParams from "../../../../hooks/useQueryParams";
import { isDesktop } from "../../../../services/general";

const DATE_FORMAT = "YYYY-MM-DD";

const Credentials = props => {
  const [credentials, setCredentials] = useState();
  const [loadingCredentials, setLoadingCredentials] = useState(false);
  const [nPages, setNPages] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [queryParams, setQueryParams] = useQueryParams({ page: 1 });
  const [totalItems, setTotalItems] = useState(null);

  const userDateFormat = getDateFormat(props.user.timezone);

  useEffect(() => {
    props.setBreadcrumb([
      {
        title: i18n.t("Credentials"),
        location: "/credentials"
      }
    ]);
  }, []);

  useEffect(() => {
    loadCredentials(queryParams);
  }, [queryParams]);

  const loadCredentials = useCallback(
    debounce(searchParams => {
      const params = {
        ...searchParams,
        company_id: props.user.company_id
      };

      setLoadingCredentials(true);
      CredentialService.getCredentials(params)
        .then(({ data }) => {
          const auxCredentials = data.results.map(credential => {
            return {
              ...credential,
              expirydate: moment(credential.expirydate).isValid()
                ? moment(credential.expirydate).format(DATE_FORMAT)
                : ""
            };
          });
          setCredentials(auxCredentials);
          setNPages(data.total);
          setTotalItems(data.hits);
        })
        .catch(() => {
          setCredentials([]);
          setNPages(0);
        })
        .finally(() => {
          setLoadingCredentials(false);
        });
    }, 500),
    []
  );

  const handleSubmit = values => {
    setSubmitting(true);
    const toSubmit = {
      service_id: values.service_id,
      name: values.name,
      username: values.login,
      password: values.password,
      expiration_date: values.expirydate,
      description: values.description
    };
    CredentialService.updateCredentials(toSubmit)
      .then(() => {
        Toast.displaySuccess(i18n.t("CredentialUpdated"));
        setEditing(false);
        loadCredentials();
      })
      .catch(() => {
        Toast.displayError(i18n.t("SomethingWentWrong"));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleCreate = () => {
    setCreating(false);
    setEditingItem(null);
    loadCredentials();
  };

  const reloadCredentials = () => {
    setCredentials(null);
    loadCredentials();
  };

  const getDateInputValue = date =>
    moment(date).isValid() ? moment(date).format(userDateFormat) : "";

  const setDateInputValue = (value, onChange) => {
    const parsedDate = moment(value, userDateFormat);
    onChange(
      "expirydate",
      parsedDate.isValid() ? parsedDate.format(DATE_FORMAT) : ""
    );
  };

  const editExpirationDate = (item, onChange) => {
    return (
      <DateInput
        name="expirydate"
        fluid
        placeholder={i18n.t("ExpirationDate")}
        dateFormat={userDateFormat}
        animation="none"
        value={getDateInputValue(item.expirydate)}
        minDate={moment()}
        iconPosition="left"
        clearable
        closable
        disabled={submitting}
        onChange={(e, { value }) => setDateInputValue(value, onChange)}
      />
    );
  };

  const editUsername = (item, onChange) => {
    return (
      <div className="ui form">
        <ValueInput
          fromEnvVariables
          type="text"
          disabled={submitting}
          placeholder={i18n.t("Username")}
          value={item?.login}
          setFieldValue={value => onChange("login", value)}
        />
      </div>
    );
  };

  const editPassword = (item, onChange) => {
    return (
      <form className="ui form">
        <ValueInput
          fromEnvVariables
          type="password"
          disabled={submitting}
          placeholder={i18n.t("Password")}
          value={item?.password || ""}
          setFieldValue={value => onChange("password", value)}
        />
      </form>
    );
  };

  const editDescription = (item, onChange) => {
    return (
      <div className="ui form">
        <ValueInput
          fromEnvVariables
          type="text"
          disabled={submitting}
          placeholder={i18n.t("Description")}
          value={item?.description}
          setFieldValue={value => onChange("description", value)}
        />
      </div>
    );
  };

  const credentialColumns = [
    { name: i18n.t("CredentialName"), key: "name", show: true },
    {
      name: i18n.t("UserName"),
      key: "login",
      show: true,
      toEdit: editUsername
    },
    {
      name: i18n.t("Password"),
      key: "password",
      show: true,
      searchable: false,
      sortable: false,
      toEdit: editPassword,
      method: displayPassword
    },
    {
      name: i18n.t("ExpirationDate"),
      key: "expirydate",
      show: true,
      type: "datetime",
      toEdit: editExpirationDate,
      method: displayDate
    },
    {
      name: i18n.t("Description"),
      key: "description",
      show: false,
      toEdit: editDescription
    },
    {
      name: i18n.t("Environment"),
      key: "service",
      show: true
    }
  ];

  const toggleMobileEdit = item => {
    setCreating(true);
    setEditingItem(item);
  };

  const mobileEditAction = {
    type: Type.Click,
    button_icon: "edit",
    button_text: i18n.t("Update"),
    onClick: item => toggleMobileEdit(item)
  };

  return (
    <React.Fragment>
      <div>
        <Table
          tableId={TYPES.CREDENTIALS}
          filters={queryParams}
          items={credentials}
          columns={credentialColumns}
          page={queryParams.page}
          nPages={nPages}
          loadItems={entries => {
            if (entries) {
              setQueryParams(entries);
            } else {
              loadCredentials();
            }
          }}
          actions={[
            isDesktop() ? tableActions.credentials.edit : mobileEditAction,
            tableActions.credentials.delete
          ]}
          loadingItems={loadingCredentials}
          loading={submitting}
          editing={editing}
          handleEdit={setEditing}
          onSubmitEdit={handleSubmit}
          createAction={() => setCreating(true)}
          reloadAction={reloadCredentials}
          totalItems={totalItems}
        />
      </div>
      <CredentialsModal
        open={creating}
        onClose={() => {
          setCreating(false);
          setEditingItem(null);
        }}
        onSubmit={handleCreate}
        user={props.user}
        item={editingItem}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(Credentials);
