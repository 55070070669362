import axios from "../axios";

class DataConfigService {
  static saveWidgetConfiguration = (userId, widgetId, widgetData) => {
    return axios.put(`/data_config_by_user/${widgetId}`, {
      user_id: userId,
      widget_id: widgetId,
      data: widgetData
    })
  }

  static deleteWidgetConfiguration = (userId, widgetId) => {
    return axios.delete(`/data_config_by_user/${widgetId}`, {
      data: {
        user_id: userId,
        widget_id: widgetId
      }
    })
  }
}

export default DataConfigService;
