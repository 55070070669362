import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "../Ui/Avatar";

export default function UserSection({ width, onLogout }) {
  const user = useSelector(state => state.currentUserReducer.user);
  const history = useHistory();

  const onSettingsClick = () => history.push("/settings");

  const onLogoutClick = () => {
    history.replace("/");
    onLogout();
  };

  const dropdownMenu = (
    <Dropdown.Menu direction="right" className="collapsed-user-menu">
      <Dropdown.Item icon="cog" content="Settings" onClick={onSettingsClick} />
      <Dropdown.Item
        icon="sign out"
        content="Log out"
        onClick={onLogoutClick}
      />
    </Dropdown.Menu>
  );

  if (width === "very thin") {
    const avatar = (
      <div>
        <Avatar imageUrl={user.profile_img} />
      </div>
    );
    return (
      <Dropdown
        trigger={avatar}
        className="current-user-section collapsed handcursor"
        icon={null}
      >
        {dropdownMenu}
      </Dropdown>
    );
  }

  return (
    <div className="current-user-section expanded">
      <Avatar name={user.name} imageUrl={user.profile_img} />
      <div>
        <Dropdown icon="ellipsis horizontal">{dropdownMenu}</Dropdown>
      </div>
    </div>
  );
}
