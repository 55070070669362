import React from "react";
import Select from "react-select";
import { ErrorMessage } from "formik";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import i18n from "../../../../i18n";
import FormsService from "../../../../../services/data_services/forms";
import { isDesktop } from "../../../../../services/general";
import InputDropdown from "../../../../Ui/InputDropdown";

class FormFeedWork extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forms: [],
      keys: []
    };

    this.loadForms();
    this.loadKeys();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.values.trigger_id !== this.props.values.trigger_id) {
      this.loadKeys();
    }
  }

  loadForms() {
    FormsService.loadForms({ page: 0 }).then(response => {
      this.setState({
        forms: response.data.results
      });
    });
  }

  loadKeys() {
    if (this.props.values.trigger_id) {
      FormsService.loadKeys(this.props.values.trigger_id).then(response => {
        this.setState({
          keys: response.data
        });
      });
    }
  }

  options() {
    return this.state.forms.map(form => {
      return { value: form.id, key: form.id, text: form.name };
    });
  }

  optionsForKeys() {
    return this.state.keys.map(key => {
      return { value: key.key, key: key.key, text: key.value };
    });
  }

  values() {
    return this.options().find(
      form => form.value === this.props.values.trigger_id
    );
  }

  keysValues() {
    return this.optionsForKeys().find(
      key => key.value === this.props.values.identifier_key
    );
  }

  render() {
    const baseClass = isDesktop() ? "field inlineField" : "field";
    const subFormClass = isDesktop() ? "subForm" : "subFormMargin";
    return (
      <React.Fragment>
        <InputDropdown
            required
            error={this.props.errors?.trigger_id}
            inputlabel={i18n.t("Form")}
            options={this.options()}
            value={this.values()?.value}
            placeholder={`${i18n.t("Select")}...`}
            disabled={this.props.preConfigured()}
            search
            selection
            onChange={(e, { value } )=> {
              this.props.setFieldValue(
                "trigger_id",
                value ? value : undefined
              );
            }}
        />
        <InputDropdown
            required
            error={this.props.errors?.identifier_key}
            inputlabel={i18n.t("Key")}
            options={this.optionsForKeys()}
            value={this.keysValues()?.value}
            placeholder={`${i18n.t("Select")}...`}
            disabled={this.props.preConfigured()}
            search
            selection
            onChange={(e, { value } )=> {
              this.props.setFieldValue(
                "identifier_key",
                value ? value : undefined
              );
            }}
        />
      </React.Fragment>
    );
  }
}

export default FormFeedWork;
