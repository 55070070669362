import React from "react";
import { Route, Switch } from "react-router-dom";
import DashboardIndex from "./DashboardIndex";
import WidgetNew from "./Widget/WidgetNew";
import WidgetEdit from "./Widget/WidgetEdit";

const Dashboard = props => {
  return (
    <Switch>
      <Route
        path="/admin/dashboard/:section_id/widget/:widget_id"
        render={routeProps => <WidgetEdit {...props} match={routeProps.match} />}
      />
      <Route
        path="/admin/dashboard/:section_id/widget"
        render={routeProps => <WidgetNew {...props} match={routeProps.match} />}
      />
      <Route
        path="/admin/dashboard"
        render={routeProps => (
          <DashboardIndex {...props} match={routeProps.match} />
        )}
      />
    </Switch>
  );
};

export default Dashboard;
