import React from "react";
import { isEqual } from "lodash";
import { Loader } from "semantic-ui-react";
import TableColumn from "./TableColumn";
import { joinArrayToString } from "../../../../services/string";
import Tick from "../../Tick";
import { TYPES } from "../types";
import TableDropdown from "../TableDropdown";

export const COMPONENTS = {
  Tick
};

const TableRow = ({ actions = [], ...props }) => {
  const isEditing = props.editing && isEqual(props.editItem, props.item);
  const item = column => {
    if (column.methodNoColumn || !column.key) {
      return props.item;
    }

    return props.item[column.key];
  };

  const renderContent = column => {
    if (props.editField === column.key) {
      return column.editContent(
        props.item.id,
        item(column),
        props.addEditedRow
      );
    }

    if (isEditing && column.toEdit) {
      return column.toEdit(props.editingRow, props.onEditedRowChange);
    }

    if (column.method) {
      return column.method(item(column));
    }

    if (column.methodNoColumn) {
      return column.methodNoColumn(item(column));
    }

    if (column.component) {
      const ComponentRef = COMPONENTS[column.component];
      return <ComponentRef value={item(column)} />;
    }

    return Array.isArray(item(column))
      ? joinArrayToString(item(column))
      : item(column);
  };

  const rowClassName = () => {
    const classes = [];
    if (typeof props.onRowClick === "function") {
      classes.push("handcursor");
    }

    if (
      props.selectedItem?.id &&
      isEqual(props.selectedItem.id, props.item[props.selectedItem.identifier])
    ) {
      classes.push("selected-row");
    }

    return classes.length ? classes.join(" ") : null;
  };

  const renderEditButtons = () => {
    const { tableId } = props;
    let editDisabled = false;
    if (tableId === TYPES.CREDENTIALS) {
      editDisabled = !props.editingRow.password;
    } else if (tableId === TYPES.ENV_VARIABLES) {
      !props.editingRow?.value;
    }
    return (
      <>
        <button
          type="button"
          disabled={editDisabled}
          className="line-action-button transparent accept"
          onClick={() => props.onSubmitEdit(props.editingRow)}
        >
          <i className="check icon green action-icon" />
        </button>
        <button
          type="button"
          className="line-action-button transparent decline"
          onClick={() => props.toggleEditRow(undefined)}
        >
          <i className="times icon red action-icon" />
        </button>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <div style={{ paddingRight: "11px" }}>
        <Loader size="tiny" active inline />
      </div>
    );
  };

  const renderElement = () => {
    if (props.loading && isEditing) {
      return renderLoading();
    }
    if (isEditing) {
      return renderEditButtons();
    }
    return <TableDropdown actions={actions} {...props} />;
  };

  const renderColumn = column => (
    <TableColumn
      key={column.key || ""}
      column={column}
      content={renderContent(column)}
    />
  );

  const renderCheckbox = () => {
    const selectedIds = props.selectedElementIds?.map(element => element.id);
    return (
      <td className="table-cell">
        <div className="ui checkbox fitted">
          <input
            disabled={props.selectAllChecked() || props.selectingAll}
            type="checkbox"
            checked={selectedIds?.includes(props.item.id)}
            onChange={() => props.onCheckboxChange(props.item)}
          />
          <label />
        </div>
      </td>
    );
  };

  const cellStyle = column =>
    (props.editField === column.key || isEditing) &&
    (column.editContent || column.toEdit)
      ? { overflow: "visible" }
      : null;

  return (
    <tr className={rowClassName()}>
      {props.onCheckboxChange && renderCheckbox()}
      {props.columns.map((column, index) => {
        const isLastCell = index === props.columns.length - 1;
        return (
          <td
            role="gridcell"
            key={column.name}
            onClick={() => props.onRowClick && props.onRowClick(props.item)}
            style={cellStyle(column)}
            className="table-cell"
          >
            {renderColumn(column)}
            {isLastCell && actions.length > 0 && (
              <div className="cell-dropdown">{renderElement()}</div>
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default TableRow;
