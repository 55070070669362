import React, { useEffect } from "react";

const PasswordInputField = props => {
  useEffect(() => {
    if (props.value === undefined) {
      props.setFieldValue("");
    }
  }, [props.value]);

  return (
    <div className="field">
      <input
        disabled={props.disabled}
        name={props.name}
        type="password"
        autoComplete="new-password"
        style={{ width: "100%" }}
        value={props.value}
        onChange={e => {
          props.setFieldValue(e.target.value);
        }}
      />
    </div>
  );
};

export default PasswordInputField;
