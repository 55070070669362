import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import "@ckeditor/ckeditor5-build-classic";
import { Formik } from "formik";
import i18n from "../../i18n";
import axios from "../../../services/axios";
import Toast from "../../../services/toasts";
import SubmitButton from "../../SubmitButton";
import { displayFileName } from "../../../services/string";

class ActivityNewComment extends React.Component {
  constructor(props) {
    super(props);
    this.inputFilesRef = React.createRef();
  }

  submitComment(values, setSubmitting, resetForm) {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
      if (values[key] instanceof Array) {
        values[key].forEach((file, index) =>
          formData.append(`${key}[${index}]`, file)
        );
      } else {
        formData.append(key, values[key]);
      }
    });

    formData.append("activity_id", this.props.activity.id);
    formData.append("user_id", this.props.user.id);

    if (!this.props.comment) {
      axios
        .post("/comments", formData)
        .then(() => {
          Toast.show.commentCreated();
          this.props.loadActivityData();
          resetForm();
          this.props.onClose();
        })
        .catch(() => Toast.show.commentNotCreated())
        .finally(() => setSubmitting(false));
    } else {
      axios
        .put(`/comments/${this.props.comment.id}`, formData)
        .then(() => {
          Toast.displaySuccess("Comment edited!");
          this.props.loadActivityData();
          this.props.onClose();
        })
        .catch(() =>
          Toast.displayError("Something went wrong updating the comment")
        )
        .finally(() => setSubmitting(false));
    }
  }

  removeAttachment(values, name, setFieldValue) {
    const attachments = values.attachments.filter(attachment => {
      if (typeof attachment === "object") return attachment.name !== name;
      return attachment !== name;
    });

    setFieldValue("attachments", attachments);
  }

  renderAttachmentNames(values, setFieldValue) {
    if (values.attachments.length > 0) {
      const filteredAttachments = values.attachments.filter(
        attachment => attachment
      );
      return (
        <div className="comment-attachments-container">
          {filteredAttachments.map(attachment => {
            const attachmentName =
              typeof attachment === "object" ? attachment.name : attachment;
            return (
              <div key={attachmentName} className="ui basic blue label">
                <i className="file icon" />
                {displayFileName(attachmentName)}
                <a
                  className="detail"
                  href="#"
                  onClick={() => {
                    this.removeAttachment(
                      values,
                      attachmentName,
                      setFieldValue
                    );
                  }}
                >
                  <i className="close icon" />
                </a>
              </div>
            );
          })}
        </div>
      );
    }

    return undefined;
  }

  renderModalContent() {
    return (
      <Formik
        onSubmit={(values, { setSubmitting, resetForm }) => {
          this.submitComment(values, setSubmitting, resetForm);
        }}
        initialValues={{
          description: this.props.comment?.description || "",
          attachments: this.props.comment?.attachments || []
        }}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <form
            onSubmit={handleSubmit}
            className={`ui form ${this.props.comment ? "" : "container"}`}
            style={{ padding: "20px", paddingLeft: "0" }}
          >
            <div className="field">
              <CKEditor
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "blockQuote",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "insertTable",
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "|",
                    "undo",
                    "redo"
                  ],
                  placeholder: "Write a comment..."
                }}
                editor={ClassicEditor}
                data={values.description}
                onChange={(event, editor) => {
                  setFieldValue("description", editor.getData());
                }}
              />
            </div>
            <div className="ui field d-flex-center-sb">
              <div className="comment-form-attachments">
                <input
                  type="file"
                  multiple
                  ref={input => (this.inputFilesRef = input)}
                  onChange={event => {
                    const arrayCopy = [...values.attachments];

                    [...event.currentTarget.files].forEach(file => {
                      if (
                        !values.attachments.some(
                          attachment => attachment.name === file.name
                        )
                      ) {
                        arrayCopy.push(file);
                      }
                    });

                    setFieldValue("attachments", arrayCopy);
                  }}
                  style={{ display: "none" }}
                />
                <button
                  className="ui labeled icon button mini upload-attachment-button"
                  type="button"
                  onClick={() => this.inputFilesRef.click()}
                >
                  <i className="upload icon" />
                  {i18n.t("SelectFiles")}
                </button>
                {this.renderAttachmentNames(values, setFieldValue)}
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {this.props.comment && (
                  <button
                    type="button"
                    className="ui button basic blue"
                    style={{ width: "150px" }}
                    onClick={() => this.props.onClose()}
                  >
                    {i18n.t("CancelButton")}
                  </button>
                )}
                <SubmitButton
                  isDisabled={
                    !values.description && values.attachments?.length === 0
                  }
                  label={this.props.comment ? "Edit" : "Comment"}
                  style={{ width: "150px" }}
                  isSubmitting={isSubmitting}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }

  render() {
    return this.renderModalContent();
  }
}

export default ActivityNewComment;
