import React from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";
import LaunchApplication from "./LaunchApplication";

const LaunchModal = props => {
  return (
    <TransitionablePortal
      open={props.open}
      transition={{
        animation: "scale",
        duration: 300
      }}
    >
      <Modal
        open
        size="small"
        closeOnEscape
        closeOnDimmerClick
        onClose={props.closeModal}
        style={{ overflow: "visible" }}
      >
        <Modal.Header>
          <div style={{ textAlign: "center" }}>
            {props.application && props.application.name}
            <i
              role="button"
              aria-label="Close"
              tabIndex={0}
              className="close icon"
              style={{ float: "right", cursor: "pointer", lineHeight: "2rem" }}
              onClick={props.closeModal}
            />
          </div>
        </Modal.Header>
        <Modal.Content image>
          <LaunchApplication
            application={props.application}
            closeModal={props.closeModal}
            onSuccess={props.onSuccess}
            onFailure={props.onFailure}
          />
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};

export default LaunchModal;
