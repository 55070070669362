import React from "react";
import { Route, Switch } from "react-router-dom";
import QueuesTable from "./QueuesTable";

const Queues = props => {
  return (
    <Switch>
      <Route
        path="/queues"
        render={routerProps => <QueuesTable {...props} {...routerProps} />}
      />
    </Switch>
  );
};

export default Queues;
