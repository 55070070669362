import moment from "moment-timezone";

const BP_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.sssZ";

export const formatDate = (value, dateFormat) => {
  if (moment(value, BP_DATE_FORMAT, true).isValid()) {
    return moment.utc(value).format(dateFormat);
  }
  return value;
};

export const formatDateForTimeline = dateValue => {
  if (moment(dateValue).isValid()) {
    const auxDate = moment.utc(dateValue).format();
    return new Date(auxDate);
  }
  return dateValue;
};

export const formatStringToNumber = value => {
  if (Number.isNaN(value)) return null;
  if (isNaN(value) || !value) return value;
  return parseFloat(value);
};

export const formatChart = (values, dateFormat, chartType) => {
  if (!Array.isArray(values)) return [];
  if (chartType === "timeline") {
    return formatTimelineChart(values);
  }
  const formattedValues = values.map((chartRow, rowIndex) => {
    return chartRow.map(value => {
      if (rowIndex === 0) return value;
      const formattedDate = formatDate(value, dateFormat);
      const formattedValue = formatStringToNumber(formattedDate);
      return formattedValue;
    });
  });

  const formattedNaNValues = formatNotNumberValues(chartType, formattedValues);
  return formattedNaNValues;
};

export const formatNotNumberValues = (chartType, data) => {
  if (!data || data.length < 2) return data;
  // const columnTypes = data[1].map(item => typeof item);
  const columnTypes =
    columnDataTypes(chartType, data) || data[1].map(item => typeof item);

  const formattedData = data.map((row, rowIndex) => {
    if (rowIndex === 0) return row;
    return row.map((item, itemIndex) => {
      if (columnTypes[itemIndex] === "number" && typeof item !== "number") {
        if (item === "") return 0;
        return { v: null, f: typeof item === "string" ? item : null };
      }
      return item;
    });
  });
  return formattedData;
};

const formatTimelineChart = timelineData => {
  const formattedValues = timelineData.map((row, rowIndex) => {
    if (rowIndex === 0) return row;
    return row.map((item, index, auxRow) => {
      if ([auxRow.length - 1, auxRow.length - 2].includes(index)) {
        return formatDateForTimeline(item);
      }
      return item;
    });
  });
  return formattedValues;
};

export const columnDataTypes = (chartType, data) => {
  const nColumns = data[0].length;
  switch (chartType) {
    case "bar":
    case "hbar":
    case "vbar":
    case "combochart":
    case "line":
      return barChartDataTypes(nColumns, data);
    case "areachart":
      return areaChartDataTypes(nColumns);
    case "bubblechart":
      return bubbleChartDataTypes(nColumns, data);
    case "calendar":
      return calendarChartDataTypes(nColumns);
    case "gantt":
      return ganttChartDataTypes;
    case "geochart":
      return geoChartDataType;
    case "histogram":
      return histogramChartDataTypes(nColumns);
    case "piechart":
      return pieChartDataTypes;
    case "table":
      return tableChartDataTypes(nColumns, data);
    default:
      return null;
  }
};

const areaChartDataTypes = nColumns => {
  const dataTypes = ["string"];
  const auxDataTypes = fillArray(dataTypes, "number", 1, nColumns - 1);
  return auxDataTypes;
};

const bubbleChartDataTypes = (nColumns, data) => {
  const dataTypes = ["string", "number", "number"];
  if (nColumns >= 4) {
    const auxType = isNaN(data[1][3]) ? "string" : "number";
    dataTypes.push(auxType);
  }
  if (nColumns === 5) {
    dataTypes.push("number");
  }
  return dataTypes;
};

const barChartDataTypes = (nColumns, data) => {
  const dataTypes = [];
  const firstColDataType = findElementDataType(data[1][0]);
  dataTypes[0] = firstColDataType;
  const auxDataTypes = fillArray(dataTypes, "number", 1, nColumns - 1);
  return auxDataTypes;
};

const calendarChartDataTypes = nColumns => {
  const dataTypes = ["date"];
  const auxDataTypes = fillArray(dataTypes, "number", 1, nColumns - 1);
  return auxDataTypes;
};

const ganttChartDataTypes = [
  "string",
  "string",
  "string",
  "date",
  "date",
  "number",
  "number",
  "string"
];

const geoChartDataType = [
  "string",
  "number"
]

const histogramChartDataTypes = nColumns => {
  if (nColumns === 2) return ["string", "number"];
  const dataTypes = Array.from({ length: 5 }, () => "number");
  return dataTypes;
};

const pieChartDataTypes = ["string", "number"];

const tableChartDataTypes = (nColumns, data) => {
  const dataTypes = [];
  const nRows = data.length;
  for (let column = 0; column < nColumns; column++) {
    let rowIndex;
    for (let row = 1; row < nRows; row++) {
      if (data[row][column] || data[row][column] === 0) {
        rowIndex = row;
        break;
      }
    }
    dataTypes[column] = rowIndex
      ? findElementDataType(data[rowIndex][column])
      : "string";
  }
  return dataTypes;
};

const findElementDataType = element => {
  if (!isNaN(element) && !["", null, undefined, false].includes(element)) {
    return "number";
  }
  if (moment(element).isValid()) return "date";
  return "string";
};

const fillArray = (originalArray, value, start, end) => {
  const newArray = [...originalArray];
  for (let i = start; i <= end; i++) {
    newArray[i] = value;
  }
  return newArray;
};
