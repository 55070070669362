import React, { useEffect } from "react";

const UndefinedInputField = props => {
  useEffect(() => {
    if (props.value === undefined) {
      props.setFieldValue("");
    }
  }, [props.value]);

  return (
    <div className="field">
      <input disabled name={props.name} type="text" style={{ width: "100%" }} />
    </div>
  );
};

export default UndefinedInputField;
