import React from "react";
import i18n from "../../../i18n";
import UsersForm from "./UsersForm";

class UsersNew extends React.Component {
  constructor(props) {
    super(props);

    this.setBreadcrumb();
  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Users"),
        location: "/admin/users"
      },
      {
        title: i18n.t("NewUser"),
        location: "/admin/users/new"
      }
    ]);
  }

  render() {
    return (
      <div className="ui segment">
        <UsersForm />
      </div>
    );
  }
}

export default UsersNew;
