import React from "react";

class CloseRossum extends React.Component {
  render() {
    parent.$(".ui.modal").modal("hide");

    return null;
  }
}

export default CloseRossum;
