import React from "react";
import Select from "react-select";
import { reactSelectStyle } from "../../semantic-ui";

import timezones from "./timezones.json";
import i18n from "../i18n";

const TimezoneSelector = props => {
  const timezoneOptions = () => {
    return timezones.map(timezone => {
      return {
        label: timezone.text,
        value: timezone.utc[0]
      };
    });
  };

  const timezoneValue = () => {
    const selectedTimezone = timezoneOptions().find(
      option => option.value === props.defaultTimezone
    );

    if (!selectedTimezone) {
      const correctTimezone = timezones.find(tz =>
        tz.utc.includes(props.defaultTimezone)
      )?.utc[0];
      return timezoneOptions().find(option => option.value === correctTimezone);
    }

    return selectedTimezone;
  };

  return (
    <Select
      options={timezoneOptions()}
      value={timezoneValue()}
      onChange={props.onChange}
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder={`${i18n.t("Select")}...`}
      styles={reactSelectStyle}
    />
  );
};

export default TimezoneSelector;
