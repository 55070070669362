import React from "react";
import { Loader } from "semantic-ui-react";
import ActivityComment from "./ActivityComment";
import i18n from "../../i18n";

class ActivityComments extends React.Component {
  renderLoading = () => {
    return (
      <Loader active inline="centered">
        {i18n.t("Loading")}
      </Loader>
    );
  };

  handleRender = () => {
    if (this.props.comments === undefined) {
      return this.renderLoading();
    }
    if (this.props.comments.length > 0) {
      return this.renderComments();
    }
    return null;
  };

  renderComments() {
    return (
      <div className="ui cards" style={{ width: "100%" }}>
        {this.props.comments.map(comment => (
          <ActivityComment
            comment={comment}
            key={comment.id}
            openRossumIframe={this.props.openRossumIframe}
            activity={this.props.activity}
            loadActivityData={this.props.loadActivityData}
            setPreviewUrl={this.props.setPreviewUrl}
            fromNCW={this.props.fromNCW}
          />
        ))}
      </div>
    );
  }

  render() {
    return <div style={{ marginTop: "15px" }}>{this.handleRender()}</div>;
  }
}

ActivityComments.defaultProps = {
  fromNCW: false
};

export default ActivityComments;
