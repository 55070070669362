import React from "react";
import i18n from "../i18n";
import ProcessElement from "./ProcessElement";
import SearchBox from "../Ui/SearchBox";
import LaunchModal from "./LaunchModal";
import Toast from "../../services/toasts";
import Pagination from "../Pagination";
import LaunchFilter from "./LaunchFilter";
import { isDesktop } from "../../services/general";

class ProcessGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      application: undefined,
      modalVisible: false
    };
  }

  applications = () => {
    return this.props.applications ? this.props.applications : undefined;
  };

  setApplication = application => {
    this.setState({
      application
    });
  };

  setModal(visible) {
    this.setState({
      modalVisible: visible
    });
  }

  handleRender() {
    if (this.applications()) {
      if (this.applications().length > 0) {
        return this.renderGrid();
      }
      return this.renderEmpty();
    }
    return this.renderLoading();
  }

  renderLoading() {
    return (
      <div className="ui" style={{ minHeight: "100px" }}>
        <div className="ui active centered inline text loader">
          {i18n.t("Loading")}
        </div>
      </div>
    );
  }

  renderEmpty() {
    return (
      <div className="noContent">
        <h4>{i18n.t("NoApplications")}</h4>
      </div>
    );
  }

  renderGrid() {
    return (
      <>
        <div className="processGrid">
          {this.applications().map(application => {
            return (
              <ProcessElement
                key={application.id}
                application={application}
                openModal={() => this.setModal(true)}
                selectApplication={this.setApplication}
              />
            );
          })}
        </div>
        <Pagination
          nPages={this.props.nPages}
          onChange={page => this.props.loadApplications(undefined, page)}
        />
      </>
    );
  }
  

  renderFilter() {
    return (
      <LaunchFilter
        applications={this.props.applicationsInit}
        setApplicationsToShow={this.props.setApplicationsToShow}
        customStyle={{
          position: "absolute",
          right: "27px",
          top: "120px"
        }}
      />
    );
  }

  render() {
    return (
      <div className="processGridContainer">
        <div className={"d-flex-center-sb"} style={{ paddingBottom: "0.5rem", display: !isDesktop() && "none" }}>
          <SearchBox
            loadItems={e => {
              this.props.loadApplications(e);
            }}
          />
          
          <div className="right menu">
              <div className="ui item">{this.renderFilter()}</div>
            </div>
        </div>
        {this.handleRender()}
        <LaunchModal
          application={this.state.application}
          open={this.state.modalVisible}
          closeModal={() => this.setModal(false)}
          onSuccess={() => {
            this.setState({
              modalVisible: false
            });
            Toast.show.processLaunched();
          }}
          onFailure={() => {
            Toast.show.processNotLaunched();
          }}
        />
      </div>
    );
  }
}

export default ProcessGrid;
