/* eslint-disable react/sort-comp */
import React from "react";
import { debounce, isEqual } from "lodash";
import i18n from "../../../i18n";
import { VisitableComponents } from "../../../../services/detailedPermissionRules";
import Table from "../../../Ui/Table/Table";
import { TYPES } from "../../../Ui/Table/types";
import { tableActions } from "../../../Ui/Table/TableActions";
import ApplicationService from "../../../../services/data_services/application";
import ApplicationsPreConfigured from "./ApplicationsPreConfigured";
import AdminUpperbar from "../../AdminUpperbar";

class ApplicationsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: "Application", show: true, key: "name" },
        { name: "Description", show: true, key: "description" }
      ],
      applications: undefined,
      loadingApplications: true,
      queryParams: {},
      nPages: 0
    };

    props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Applications"),
        location: "/admin/applications"
      }
    ]);
  }

  componentDidMount() {
    this.loadApplications();
    this.loadSidebarApplications();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.loadApplications();
    }
  }

  setQueryParams = (key, value, search = false) => {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      if (search) {
        queryParams.page = 1;
      }

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  };

  loadApplications = debounce(() => {
    this.setState({ loadingApplications: true });
    const query = { ...this.state.queryParams };
    query.pre_configured = false;

    ApplicationService.loadApplications(query)
      .then(response => {
        return Array.isArray(response.data)
          ? { results: response.data, total: 0 }
          : response.data;
      })
      .then(data => {
        this.setState({
          applications: data.results,
          nPages: data.total,
          totalItems: data.hits
        });
      })
      .catch(() => {
        this.setState({
          applications: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingApplications: false });
      });
  }, 500);

  loadSidebarApplications = () => {
    ApplicationService.loadIndividualItemApplications().then(result => {
      this.props.loadIndividualApplications(result.data);
    });
  };

  reloadApplications = () => {
    this.setState({ applications: null }, this.loadApplications());
  };

  applications = (preConfigured = false) => {
    return this.props.applications
      ? this.props.applications.filter(
          application => application.pre_configured === preConfigured
        )
      : undefined;
  };

  loadItems(entries) {
    if (!entries) {
      this.loadApplications();
    } else {
      Object.keys(entries).forEach(key => {
        this.setQueryParams(key, entries[key]);
      });
    }
  }

  render() {
    return (
      <>
        <AdminUpperbar />
        <div style={{ paddingTop: "10px" }}>
          <Table
            totalItems={this.state.totalItems}
            tableId={TYPES.APPLICATIONS}
            columns={this.state.columns}
            items={this.state.applications}
            noItemsKey="NoApplications"
            actions={[
              tableActions.applications.clone,
              tableActions.applications.edit,
              tableActions.applications.delete
            ]}
            loadItems={entries => {
              this.loadItems(entries);
            }}
            loadingItems={this.state.loadingApplications}
            filters={this.state.queryParams}
            nPages={this.state.nPages}
            page={this.state.queryParams ? this.state.queryParams.page : 1}
            component={VisitableComponents.Application}
            createAction={() => this.props.history.push("applications/new")}
            reloadAction={this.reloadApplications}
          />
          <ApplicationsPreConfigured {...this.props} />
        </div>
      </>
    );
  }
}

export default ApplicationsTable;
