import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import ActivityComments from "../Comments/ActivityComments";
import Attachment from "./Attachment";
import AttachmentContainer from "../AttachmentContainer";
import i18n from "../../i18n";
import Toast from "../../../services/toasts";
import TableActionButton from "../../Ui/TableActionButton";
import ActivitiesService from "../../../services/data_services/activities";
import RejectModal from "./RejectModal";
import Review from "./Review/Review";

const EMPTY_MANAGER_STRING = "No Staff Allocated";

const NCWDetail = props => {
  const [showModal, toggleModal] = useState(false);
  const [displayAttachment, setDisplayAttachment] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState(null);
  const [approve, setApprove] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  useEffect(() => {
    if (isSubmitting) {
      onSubmit(popupMessage);
    }
  }, [isSubmitting]);

  const closeModal = () => {
    toggleModal(false);
  };

  const onApproveHandler = () => {
    setApprove(true);
    toggleModal(true);
  };

  const onModalSubmit = message => {
    setIsSubmitting(true);
    setPopupMessage(message);
  };

  const onSubmit = message => {
    const actionService = approve
      ? ActivitiesService.approveActivity
      : ActivitiesService.rejectActivity;
    actionService(props.activity.id, message)
      .then(() => {
        Toast.displaySuccess(approve ? "Approved!" : "Rejected!");
        setPopupMessage("");
        props.reloadActivity();
        closeModal();
      })
      .catch(() => {
        Toast.displayError("Something went wrong...");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const currentUserIsFromApproverTeam = () => {
    return props.user.teams.find(team =>
      props.application.partner_team_ids.includes(team.id)
    );
  };

  const isClientManagerEmpty = () =>
    !props.activity?.ncw_to_json?.client?.manager ||
    props.activity?.ncw_to_json?.client?.manager === EMPTY_MANAGER_STRING;

  const toggleAttachmentView = () => {
    if (!displayAttachment) {
      setAttachmentUrl(props.activity.data.link);
      setDisplayAttachment(true);
    } else {
      setAttachmentUrl(null);
      setDisplayAttachment(false);
    }
  };

  const editPreviewButton = _ => {
    if (
      (props.activity.status === 16 && props.activity.creator === props.user.id) ||
      (props.activity.status === 14 && props.activity.approvers?.indexOf(props.user.id) >= 0) ||
      (props.activity.status === 14 && currentUserIsFromApproverTeam() && isClientManagerEmpty())
    ) {
      return (
        <TableActionButton
          icon="pencil"
          className="ncw-action-button"
          onClick={() => {
            props.history.push({
              pathname: `/applications/${props.application.id}/NCW`,
              state: props.activity
            });
          }}
        >
          {i18n.t("Edit")}
        </TableActionButton>
      );
    }
    return (
      <TableActionButton
        icon="eye"
        className="ncw-action-button"
        onClick={() => {
          props.history.push({
            pathname: `/applications/${props.application.id}/NCW`,
            state: props.activity,
            preview: true
          });
        }}
      >
        {i18n.t("Preview")}
      </TableActionButton>
    );
  };

  return (
    <React.Fragment>
      <div className="ncw-detail-container">
        <div className="ncw-detail-topbar">
          <div className="ncw-button-container">
            {editPreviewButton()}
            {currentUserIsFromApproverTeam() && (
              <React.Fragment>
                <TableActionButton
                  type="success"
                  className="ncw-action-button"
                  disabled={props.activity.status !== 14}
                  onClick={() => onApproveHandler()}
                >
                  {i18n.t("Approve")}
                </TableActionButton>
                <TableActionButton
                  type="fail"
                  className="ncw-action-button"
                  disabled={props.activity.status !== 14}
                  onClick={() => {
                    toggleModal(true);
                    setApprove(false);
                  }}
                >
                  {i18n.t("Reject")}
                </TableActionButton>
              </React.Fragment>
            )}
          </div>
          <div className="attachment-container">
            {!isEmpty(props?.activity?.data) && (
              <Attachment
                name={props.activity.data?.filename}
                link={props.activity.data?.link}
                onClick={toggleAttachmentView}
              />
            )}
          </div>
        </div>
        <Review data={props.activity.ncw_to_json} fromForm={false} />
      </div>
      {displayAttachment && <AttachmentContainer url={attachmentUrl} />}
      <div className="comments-container">
        <ActivityComments fromNCW comments={props.activity.comments} />
      </div>
      <RejectModal
        open={showModal}
        onClose={closeModal}
        onSubmit={onModalSubmit}
        id={props.activity.id}
        reloadActivity={props.reloadActivity}
        headerText={approve ? i18n.t("ApproveWorksheet") : i18n.t("RejectWorksheet")}
        infoText={approve ? i18n.t("SubmitApproveMessage") : i18n.t("SubmitReject")}
        buttonText={approve ? i18n.t("Approve") : i18n.t("Reject")}
        approve={approve}
        isSubmitting={isSubmitting}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(withRouter(NCWDetail));
