import axios from "../axios";

class ServiceService {
  static loadServices = () => {
    return axios.get("/services");
  };

  static loadServiceParameters = () => {
    return axios.get("/services/parameters_by_service_type");
  };

  static createService = values => {
    return axios.post("/services", values);
  };

  static updateService = (id, values) => {
    return axios.put(`/services/${id}`, values);
  };

  static deleteService = id => {
    return axios.delete(`/services/${id}`);
  };
}

export default ServiceService;
