import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { indexOf } from "lodash";
import i18n from "../../i18n";
import SchedulerService from "../../../services/data_services/scheduler";
import UserService from "../../../services/data_services/user";
import StepHeader from "./FormSteps/StepHeader";
import FormConfig from "./formConfig";
import WithLoadingComponent from "../../Ui/WithLoadingComponent";

class SchedulersForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      returnToSchedulers: false,
      user: undefined,
      notifications: undefined
    };

    this.formConfig = new FormConfig(this.props.scheduler);
    this.steps = this.formConfig.config();

    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);

    this.nextRef = React.createRef();
  }

  componentDidMount() {
    this.loadUser();
    this.loadNotifications();
  }

  loadUser = () => {
    if (this.props.user.permitted_applications) {
      this.setState({ user: this.props.user });
    } else {
      UserService.loadUser(this.props.user.id).then(response => {
        this.setState({
          user: response.data
        });
      });
    }
  };

  loadNotifications = () => {
    SchedulerService.loadNotificationOptions().then(response => {
      this.setState({
        notifications: response.data
      });
    });
  };

  returnSchedulers = () => {
    this.setState({
      returnToSchedulers: true
    });
  };

  nextPage(errors, touched, setFieldTouched, values) {
    const toTouch = this._pageFields().filter(field => {
      if (field.name === "processes") {
        return (
          Object.prototype.hasOwnProperty.call(values, "processes") &&
          values.processes.length > 0 &&
          Object.prototype.hasOwnProperty.call(errors, "processes") &&
          errors.processes.length > 0
        );
      }

      const hasValidation = Object.prototype.hasOwnProperty.call(
        field,
        "validation"
      );
      const beenTouch =
        !Object.prototype.hasOwnProperty.call(touched, field.name) ||
        touched[field.name] !== true;

      return hasValidation && beenTouch;
    });

    if (toTouch.length > 0) {
      toTouch.forEach(field => {
        if (Array.isArray(values[field.name] && field.name === "processes")) {
          values[field.name].forEach((process, i) => {
            Object.keys(process).forEach(key => {
              const fieldName = `${field.name}.${i}.${key}`;
              setFieldTouched(fieldName, true);
            });
          });
        } else {
          setFieldTouched(field.name, true);
        }
      });

      setTimeout(() => {
        this.nextRef.click();
      }, 100);
    } else if (!this.nextPageDisabled(errors, touched)) {
      this.setState(prevState => {
        return {
          currentStep:
            prevState.currentStep + 1 < this.steps.length
              ? prevState.currentStep + 1
              : this.steps.length - 1
        };
      });
    }
  }

  previousPage() {
    this.setState(prevState => {
      return {
        currentStep:
          prevState.currentStep - 1 >= 0 ? prevState.currentStep - 1 : 0
      };
    });
  }

  nextPageDisabled(errors, touched) {
    return Object.keys(errors).some(field => {
      const fieldNames = this._pageFields().map(f => f.name);

      return (
        indexOf(fieldNames, field) >= 0 &&
        Object.prototype.hasOwnProperty.call(touched, field) &&
        touched[field]
      );
    });
  }

  _isLastStep() {
    return this.state.currentStep === 3;
  }

  _pageFields() {
    return this.steps[this.state.currentStep].fields;
  }

  _validationSchema() {
    const validations = {};
    const noSortEdges = [];

    this.steps.forEach(step => {
      step.fields.forEach(field => {
        if (Object.prototype.hasOwnProperty.call(field, "validation")) {
          validations[field.name] = field.validation;
        }
      });
      step.noSortEdges &&
        step.noSortEdges.forEach(edge => {
          noSortEdges.push(edge);
        });
    });

    return Yup.object().shape(validations, noSortEdges);
  }

  changeCurrentStep = step => {
    this.setState({ currentStep: step });
  };

  _initialValues() {
    const values = {
      company_id: this.props.user.company_id
    };

    this.steps.forEach(step => {
      step.fields.forEach(field => {
        values[field.name] = field.defaultValue;
      });
    });

    return values;
  }

  renderSteps(
    handleChange,
    values,
    setFieldValue,
    errors,
    touched,
    setFieldTouched
  ) {
    const StepToRender = this.steps[this.state.currentStep].component;

    return (
      <StepToRender
        handleChange={handleChange}
        values={values}
        setFieldValue={setFieldValue}
        user={this.state.user}
        errors={errors}
        touched={touched}
        setFieldTouched={setFieldTouched}
        action={this.props.action}
        notifications={this.state.notifications}
      />
    );
  }

  renderButtons(errors, touched, setFieldTouched, values, isSubmitting) {
    return (
      <div className="schedulersFormButtonsContainer">
        <div>
          <button
            type="button"
            className="ui button blue basic"
            onClick={this.returnSchedulers}
          >
            {i18n.t("CancelButton")}
          </button>
        </div>
        <div>
          {this.state.currentStep !== 0 && (
            <button
              type="button"
              className="ui button blue"
              onClick={this.previousPage}
            >
              {i18n.t("BackButton")}
            </button>
          )}
          {this.state.currentStep === 2 && (
            <button
              type="submit"
              className="ui button blue"
              disabled={this.nextPageDisabled(errors, touched) || isSubmitting}
            >
              {i18n.t("FinishButton")}
            </button>
          )}

          {this.state.currentStep < 2 && (
            <button
              type="button"
              className="ui button blue"
              onClick={() =>
                this.nextPage(errors, touched, setFieldTouched, values)
              }
              disabled={this.nextPageDisabled(errors, touched)}
              ref={button => (this.nextRef = button)}
            >
              {i18n.t("NextButton")}
            </button>
          )}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.returnToSchedulers) {
      const goToPausedSchedulers = this.props.scheduler
        ? this.props.scheduler.paused
        : false;
      return (
        <Redirect
          push
          to={{
            pathname: "/schedulers",
            search: `?paused=${goToPausedSchedulers}`
          }}
        />
      );
    }
    return (
      <WithLoadingComponent
        loadingCondition={() => !this.state.user || !this.state.notifications}
        contentCondition={() => this.state.user && this.state.notifications}
        noItemsKey="NotAbleLoadUser"
      >
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="ui ordered steps">
              {this.steps.map((step, index) => {
                return (
                  <StepHeader
                    key={step.title}
                    title={step.title}
                    step={index}
                    action={this.props.action}
                    currentStep={this.state.currentStep}
                    changeCurrentStep={this.changeCurrentStep}
                  />
                );
              })}
            </div>
          </div>

          <div className="schedulerFormContainer">
            <Formik
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                this.props.onSubmit(
                  values,
                  setSubmitting,
                  this.returnSchedulers
                );
              }}
              initialValues={this._initialValues()}
              validationSchema={this._validationSchema()}
            >
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                values,
                touched,
                errors,
                isSubmitting
              }) => (
                <form
                  autoComplete="nope"
                  className="ui form container"
                  onSubmit={handleSubmit}
                >
                  {this.renderSteps(
                    handleChange,
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    setFieldTouched
                  )}
                  {this.renderButtons(
                    errors,
                    touched,
                    setFieldTouched,
                    values,
                    isSubmitting
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </WithLoadingComponent>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(SchedulersForm);
