import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Companies from "../Companies/Companies";
import Services from "../Services/Services";
import Activities from "../Activities/Activities";
import Admin from "../Admin/Admin";
import AssetsIndex from "../Admin/AssetManagement/AssetsIndex";
import AccountDetails from "../Account/AccountDetails";
import Launcher from "../LaunchProcess/Launcher";
import Scheduler from "../Scheduler/Scheduler";
import Notfound from "../Error/Notfound";
import AppIndex from "../AppIndex";

const ApplicationRoutes = props => {
  const checkPermissions = (target, component) => {
    if (props?.user?.company?.access_to[target] === "true") return component;
    return <Redirect to={{ pathname: "/403", state: { noPermitted: true } }} />;
  };

  return (
    <Switch>
      <Route path="/companies">
        <Companies {...props} />
      </Route>
      <Route path="/services">
        <Services {...props} />
      </Route>
      <Route
        path="/applications/:id"
        render={routeProps => (
          checkPermissions('case_view', <Activities {...props} match={routeProps.match} />)
        )}
      />
      <Route path="/(scheduler_monitor|schedulers)">
        {checkPermissions('scheduling',  <Scheduler {...props} />)}
      </Route>
      <Route path="/admin">
        <Admin {...props} />
      </Route>
      <Route path="/settings">
        <div className="ui noShadow component-wrapper">
          <AccountDetails {...props} />
        </div>
      </Route>
      <Route
        path="/(launch|executions)"
        render={routeProps => checkPermissions( 'launcher', <Launcher {...props} match={routeProps.match} />)}
      />
      <Route path="/(queues|env_variables|resources|alerts|credentials)">
        {checkPermissions('rpa_management', <AssetsIndex {...props} />)}
      </Route>
      <Route exact path="/">
        <AppIndex {...props} />
        {/* {checkPermissions( 'dashboard',  <AppIndex {...props} /> )} */}
      </Route>

      <Route>
        <Notfound />
      </Route>
    </Switch>
  );
};

export default ApplicationRoutes;
