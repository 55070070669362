import React from "react";
import InputField from "../../../Ui/InputField";
import i18n from "../../../i18n";

const AdditionalComments = props => {
  return (
    <div className="additional-comments-container">
      <InputField
        inputlabel={i18n.t("AdditionalComments")}
        textarea
        disabled={props.disabled}
        value={props.comments || ""}
      />
    </div>
  );
};

export default AdditionalComments;
