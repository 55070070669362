import React from "react";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import i18n from "../i18n";
import axios from "../../services/axios";

class ContactAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sent: false,
      error: null
    };
  }

  onFormSubmit = (values, submittingFormik) => {
    axios
      .post("/contact_admin", values)
      .then(() => {
        this.setState({
          error: null,
          sent: true
        });
        submittingFormik(false);
      })
      .catch(error => {
        this.setState({ error: error.response.data.message });
        submittingFormik(false);
      });
  };

  errorMessage() {
    return this.state.error ? (
      <p className="error">{this.state.error}</p>
    ) : null;
  }

  renderContactAdminForm() {
    return (
      <div>
        <h3 className="ui center aligned header">{i18n.t("ContactAdmin")}</h3>

        {this.errorMessage()}

        <Formik
          onSubmit={(values, { setSubmitting }) => {
            this.onFormSubmit(values, setSubmitting);
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(i18n.t("EmailInvalidValidation"))
              .required(i18n.t("EmailRequiredValidation"))
          })}
        >
          {({ handleChange, handleSubmit, isSubmitting, errors }) => (
            <form
              className="ui form container"
              onSubmit={handleSubmit}
              style={{ paddingTop: "25px", paddingBottom: "25px" }}
            >
              <div className={`field ${errors.email ? "withError" : ""}`}>
                <label>{i18n.t("Email")}</label>
                <Field
                  name="email"
                  type="email"
                  onChange={handleChange}
                  placeholder={i18n.t("LoginEmailPlaceholder")}
                />
                <ErrorMessage name="email" component="div" className="error" />
              </div>

              <div style={{ paddingTop: "20px" }}>
                <button
                  className="ui button blue"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ width: "100%" }}
                >
                  {i18n.t("ContactButton")}
                </button>
              </div>
            </form>
          )}
        </Formik>

        <div
          className="ui center aligned text"
          style={{
            textAlign: "center",
            paddingTop: "100px",
            paddingBottom: "25px"
          }}
        >
          <Link to="/" className="orange">
            {i18n.t("BackLogin")}
          </Link>
        </div>
      </div>
    );
  }

  renderContactSent() {
    return (
      <div>
        <h3 className="ui center aligned header">
          {i18n.t("ContactAdminSent")}
        </h3>

        <div style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <Link to="/" className="ui button blue" style={{ width: "100%" }}>
            {i18n.t("OkButton")}
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="ui main column container">
        <div className="ui one column centered grid">
          <div
            className="ui raised very padded segment"
            style={{ width: "450px" }}
          >
            {this.state.sent
              ? this.renderContactSent()
              : this.renderContactAdminForm()}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactAdmin;
