import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import store from "../services/store";

import enTranslations from "../locales/en.json";
import deTranslations from "../locales/de.json";

store.subscribe(() => {
  try {
    i18n.changeLanguage(
      store.getState().currentUserReducer.user.language.toLowerCase()
    );
  } catch (e) {}
});

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translation: enTranslations
    },
    de: {
      translation: deTranslations
    }
  },
  lng: "en",
  fallbackLng: "en",
  keySeparator: false,
  react: {
    wait: true
  }
});

export default i18n;
