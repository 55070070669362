import React from "react";
import { Redirect } from "react-router-dom";

import CompaniesTable from "./CompaniesTable";
import CompaniesModal, { ModalType } from "./CompaniesModal";
import i18n from "../i18n";
import CompanyService from "../../services/data_services/company";
import Permissions from "../../services/permissions";
import {
  VisitableComponents,
  Actions
} from "../../services/detailedPermissionRules";

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalType: ModalType.New,
      company: {}
    };
    props.setBreadcrumb([
      { title: i18n.t("Companies"), location: "/companies" }
    ]);
  }

  componentDidMount() {
    this.loadCompanies();
  }

  loadCompanies = () => {
    CompanyService.loadCompanies()
      .then(response => {
        this.props.loadCompanies(response.data);
      })
      .catch(() => {
        this.props.loadCompanies([]);
      });
  };

  showModal = (modalType, company = {}) => {
    this.setState({
      modalOpen: true,
      modalType,
      company
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  };

  renderComponent() {
    return (
      <div className="ui segment noShadow super-admin-container">
        <CompaniesTable
          companies={this.props.companies}
          showModal={this.showModal}
          closeModal={this.closeModal}
        />
        <Permissions
          component={VisitableComponents.Companies}
          action={Actions.create}
          yes={() => {
            return (
              <div className="actionButtons">
                <button
                  type="button"
                  className="ui button blue"
                  onClick={() => this.showModal(ModalType.New)}
                >
                  {i18n.t("AddCompanyButton")}
                </button>
              </div>
            );
          }}
          no={() => {
            return null;
          }}
        />
        <CompaniesModal
          open={this.state.modalOpen}
          company={this.state.company}
          modalType={this.state.modalType}
          closeModal={this.closeModal}
          loadCompanies={this.loadCompanies}
        />
      </div>
    );
  }

  render() {
    return (
      <Permissions
        component={VisitableComponents.Companies}
        action={Actions.read}
        yes={() => {
          return this.renderComponent();
        }}
        no={() => {
          return <Redirect to="/" />;
        }}
      />
    );
  }
}

export default Companies;
