import React from "react";
import { defaultProfileImage } from "../../services/images";

export default function Avatar({ name, imageUrl }) {
  const auxImageUrl = imageUrl || defaultProfileImage();
  return (
    <div className="avatar-container">
      <div
        className="ui avatar image"
        style={{
          backgroundImage: `url(${auxImageUrl})`,
          backgroundPosition: "center center",
          backgroundSize: "cover"
        }}
      />
      {name && <span className="username">{name}</span>}
    </div>
  );
}
