import React from "react";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import UserService from "../services/data_services/user";
import ServiceRoutes from "./Router/ServiceRoutes";
import Authentication from "./Authentication/Authentication";
import AppContainer from "./AppContainer";
import {
  loadApplications,
  loadCompanies,
  loadIndividualApplications,
  loadLaunchApplications,
  loadRoles,
  loadUsers,
  logIn,
  logOut,
  setBreadcrumb,
  setLoadingBar,
  toggleSideBar,
  loadServices
} from "../actions";

class RoutingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.loginUser();
  }

  loginUser = () => {
    const userID = parseInt(Cookies.get("ID"), 10);

    !Number.isNaN(userID) &&
      UserService.loadUser(userID).then(response => {
        const user = response.data;
        if (!user.timezone) {
          user.timezone = moment.tz.guess();
        }
        this.props.logIn(user);
      });
  };

  render() {
    return (
      Cookies.get("JWT") ?
        this.props.user ?
          <ServiceRoutes {...this.props}>
            <AppContainer {...this.props}/>
          </ServiceRoutes>
          :
          <React.Fragment />
          :
        <Authentication logIn={this.props.logIn} />
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user,
    companies: state.companiesReducer.companies,
    users: state.usersReducer.users,
    roles: state.rolesReducer.roles,
    breadcrumb: state.interfaceReducer.breadcrumb,
    loadingBar: state.interfaceReducer.loadingBar,
    sideBar: state.interfaceReducer.sideBar,
    applications: state.applicationsReducer.applications,
    individualApplications: state.applicationsReducer.individualApplications,
    launchApplications: state.applicationsReducer.launchApplications,
    services: state.servicesReducer.services
  };
};

export default connect(mapStateToProps, {
  logIn,
  logOut,
  loadCompanies,
  loadUsers,
  loadRoles,
  setBreadcrumb,
  setLoadingBar,
  toggleSideBar,
  loadApplications,
  loadIndividualApplications,
  loadLaunchApplications,
  loadServices
})(withRouter(RoutingContainer));
