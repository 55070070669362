import axios from "../axios";

class FormsService {
  static createForm = values => {
    return axios.post("/forms", values);
  };

  static updateForm = (id, values) => {
    return axios.put(`/forms/${id}`, values);
  };

  static loadForm = id => {
    return axios.get(`/forms/${id}`);
  };

  static loadForms = (params = null) => {
    return axios.get(`/forms`, {
      params
    });
  };

  static loadKeys = id => {
    return axios.get(`/forms/available_keys/${id}`);
  };
}

export default FormsService;
