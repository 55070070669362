import React from "react";
import { Icon } from "semantic-ui-react";

const Attachment = props => {
  return (
    <div>
      <Icon name="file pdf outline" />
      <span
        onClick={() => {
          props.onClick()
        }}
        className="attachmentClickable"
        style={{ fontWeight: "700", color: "#227D98" }}
      >
        {props.name}
      </span>
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        <Icon name="download" style={{ cursor: "pointer" }} />
      </a>
    </div>
  );
};

export default Attachment;
