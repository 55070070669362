import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Select from "react-select";
import i18n from "../i18n";
import CompanyService from "../../services/data_services/company";
import ServiceService from "../../services/data_services/service";
import Toast from "../../services/toasts";
import { reactSelectStyle } from "../../semantic-ui";
import { loadCompanies } from "../../actions";

const requiredFields = ["name", "company_id", "service_type", "service_url"];

class ServicesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyList: [],
      service: this.props.service
    };
  }

  componentDidMount() {
    if (this.props.companies && this.props.companies.length > 0) {
      const companyList = this.props.companies.map(company => {
        return {
          label: company.name,
          value: company.id
        };
      });
      this.setState({ companyList });
    } else {
      this.loadCompanies();
    }
  }

  loadCompanies = () => {
    CompanyService.loadCompanies()
      .then(response => {
        if (!isEmpty(response.data)) {
          this.props.saveCompanies(response.data);
          const companyList = response.data.map(company => {
            return { label: company.name, value: company.id };
          });
          this.setState({ companyList });
        } else {
          this.setState({ companyList: [] });
        }
      })
      .catch(() => {
        this.setState({ companyList: [] });
      });
  };

  formText = this.props.service.id ? "EditServiceInfo" : "AddServiceInfo";

  createService = values => {
    const valuesToPost = { ...values, active: true };
    ServiceService.createService(valuesToPost)
      .then(() => {
        Toast.show.serviceCreated();
        this.props.loadServices();
        this.props.closeModal();
      })
      .catch(e => {
        Toast.displayError(
          e.response.data.message,
          i18n.t("ServiceNotCreated")
        );
      });
  };

  updateService = values => {
    ServiceService.updateService(this.props.service.id, values)
      .then(() => {
        Toast.show.serviceUpdated();
        this.props.loadServices();
        this.props.closeModal();
      })
      .catch(e => {
        Toast.displayError(
          e.response.data.message,
          i18n.t("ServiceNotUpdated")
        );
      });
  };

  updateObject = (key, value) => {
    this.setState(prevState => {
      const newService = { ...prevState.service, [key]: value };
      return { service: newService };
    });
  };

  serviceTypeHasParameters = () => {
    const { service_type } = this.state.service;
    return (
      Object.keys(this.props.paramsByType).includes(service_type) &&
      !isEmpty(this.props.paramsByType[service_type])
    );
  };

  serviceTypeOptions = () => {
    return Object.keys(this.props.paramsByType).map(type => {
      return { label: type, value: type };
    });
  };

  currentServiceType = () => {
    return this.serviceTypeOptions().find(
      type => type.value === this.state.service.service_type
    );
  };

  handleSubmit = () => {
    const { service } = this.state;
    const values = {
      name: service.name,
      company_id: service.company_id,
      service_type: service.service_type,
      service_url: service.service_url
    };
    if (this.serviceTypeHasParameters(this.state.service)) {
      this.props.paramsByType[service.service_type].forEach(param => {
        values[param.value] = service[param.value];
      });
    }
    if (this.props.service.id) {
      this.updateService(values);
    } else {
      this.createService(values);
    }
  };

  validateInputs = () => {
    const { service } = this.state;
    if (isEmpty(service) || !service.service_type) return false;

    const finalRequiredFields = [
      ...requiredFields,
      ...this.props.paramsByType[service.service_type]
        .filter(param => !param.optional)
        .map(field => field.value)
    ];
    return finalRequiredFields.every(value => service[value]);
  };

  renderField = field => {
    return (
      <div
        key={`field_${field.label}`}
        className={`field ${field.optional ? "" : "required"}`}
      >
        <label>{i18n.t(`${field.label}`)}</label>
        <input
          name={field.label}
          autoComplete="off"
          onChange={e => {
            this.updateObject([field.value], e.target.value);
          }}
          value={
            this.state.service[field.value]
              ? this.state.service[field.value]
              : ""
          }
          placeholder={i18n.t("InputPlaceholder")}
        />
      </div>
    );
  };

  renderTypeFields = () => {
    if (this.state.service.service_type && this.serviceTypeHasParameters()) {
      return this.props.paramsByType[this.state.service.service_type].map(
        param => {
          return this.renderField(param);
        }
      );
    }
    return null;
  };

  renderButtons() {
    return (
      <div
        className="ui text two column centered grid"
        style={{ padding: "25px 0px" }}
      >
        <div className="row">
          {this.props.service.id ? (
            <div className="eight wide column">
              <button
                className="ui button basic blue"
                style={{ width: "100%" }}
                type="button"
                onClick={() => this.props.closeModal()}
              >
                {i18n.t("CancelButton")}
              </button>
            </div>
          ) : null}
          <div className="eight wide column">
            <button
              disabled={!this.validateInputs()}
              className="ui button blue"
              type="submit"
              style={{ width: "100%" }}
              onClick={() => this.handleSubmit()}
            >
              {i18n.t("Save")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { service, companyList } = this.state;
    if (!isEmpty(this.props.paramsByType)) {
      return (
        <div>
          <p style={{ textAlign: "center" }}>{i18n.t(this.formText)}</p>
          <form className="ui form container">
            <div className="field required">
              <label>{i18n.t("ServiceName")}</label>
              <input
                name="name"
                autoComplete="off"
                onChange={e => {
                  this.updateObject("name", e.target.value);
                }}
                placeholder={i18n.t("InputPlaceholder")}
                value={service.name ? service.name : ""}
              />
            </div>
            <div className="field required">
              <label>{i18n.t("Company")}</label>
              <Select
                isDisabled={this.props.service.id}
                value={companyList.find(
                  company => company.value === service.company_id
                )}
                options={companyList}
                onChange={e => {
                  this.updateObject("company_id", e.value);
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                styles={reactSelectStyle}
              />
            </div>
            <div className="field required">
              <label>{i18n.t("Type")}</label>
              <Select
                isDisabled={this.props.service.id}
                options={this.serviceTypeOptions()}
                value={this.currentServiceType()}
                onChange={e => {
                  this.updateObject("service_type", e.value);
                }}
                className="react-select-container"
                classNamePrefix="react-select"
                styles={reactSelectStyle}
              />
            </div>
            <div className="field required">
              <label>{i18n.t("ServiceUrl")}</label>
              <input
                name="service_url"
                autoComplete="off"
                onChange={e => {
                  this.updateObject("service_url", e.target.value);
                }}
                placeholder={i18n.t("ServiceUrlPlaceholder")}
                value={service.service_url ? service.service_url : ""}
              />
            </div>
            {this.renderTypeFields()}
          </form>
          {this.renderButtons()}
        </div>
      );
    }
    return (
      <div style={{ textAlign: "center", color: "black" }}>
        {i18n.t("LoadingError")}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companies: state.companiesReducer.companies
});

const mapDispatchToProps = dispatch => ({
  saveCompanies: companies => dispatch(loadCompanies(companies))
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicesForm);
