import React from "react";
import { Icon, Modal, TransitionablePortal } from "semantic-ui-react";
import i18n from "../../i18n";
import axios from "../../../services/axios";
import { Action } from "./TableActions";
import { ToastType } from "../../../services/toasts";
import { isDesktop } from "../../../services/general";

const TableModal = props => {
  const modalTitle = () => {
    if (typeof props.tableAction?.title === "function") {
      return props.tableAction?.title(props.item);
    }
    return props.tableAction?.title || "";
  };

  const modalText = () => props.tableAction?.text || "";

  const close = () => props.closeModal();

  const yesClick = () => {
    switch (props.tableAction.action) {
      case Action.Delete:
        deleteItem();
        break;
      case Action.Clone:
        cloneItem();
        break;
      case Action.Play:
      case Action.Pause:
        pauseItem();
        break;
      default:
        break;
    }
  };

  const deleteItem = () => {
    props.setLoading(true);
    const data = {};
    if (props.tableAction.params) {
      props.tableAction.params.forEach(param => {
        data[param] = props.item[param];
      });
    }
    axios
      // eslint-disable-next-line no-template-curly-in-string
      .delete(props.tableAction.url.replace("${id}", props.item.id), {
        data
      })
      .then(() => {
        props.loadItems();
        showToast(ToastType.Success);
      })
      .catch(() => {
        showToast(ToastType.Failure);
      })
      .finally(() => {
        props.setLoading(false);
        close();
      });
  };

  const cloneItem = () => {
    axios
      // eslint-disable-next-line no-template-curly-in-string
      .post(props.tableAction.url.replace("${id}", props.item.id))
      .then(() => {
        props.loadItems();
        showToast(ToastType.Success);
        close();
      })
      .catch(() => {
        showToast(ToastType.Failure);
        close();
      });
  };

  const pauseItem = () => {
    axios
      .put(props.tableAction.url.replace("${id}", props.item.id), {
        id: props.item.id
      })
      .then(() => {
        props.loadItems({ paused: !props.item.paused, page: 1 });
        showToast(ToastType.Success);
        close();
      })
      .catch(() => {
        showToast(ToastType.Failure);
        close();
      });
  };

  const renderModalContent = () => {
    if (props?.tableAction?.action === Action.Component) {
      const { Component } = props.tableAction;
      return <Component item={props.item} />;
    }

    if (props.tableAction && props.tableAction.action === Action.View) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <img src={props.tableAction.url} />
        </div>
      );
    }

    return (
      <>
        <p style={{ textAlign: "center" }}>{modalText()}</p>
        <div className="ui modal-buttons-container">
          <button
            type="button"
            disabled={props.loading}
            className="ui button basic blue"
            onClick={close}
          >
            {i18n.t("NoButton")}
          </button>
          <button
            type="button"
            disabled={props.loading}
            className={`ui button blue ${props.loading ? "loading" : ""}`}
            onClick={yesClick}
          >
            {i18n.t("YesButton")}
          </button>
        </div>
      </>
    );
  };

  const showToast = type => {
    if (props.tableAction.toast) {
      if (type === ToastType.Success) {
        props.tableAction.toast.success();
      } else if (type === ToastType.Failure) {
        props.tableAction.toast.failure();
      }
    }
  };

  let modalSize = "tiny";
  if (!isDesktop()) {
    modalSize = undefined;
  } else if (props.tableAction?.size) {
    modalSize = props.tableAction?.size;
  }

  return (
    <TransitionablePortal
      open={props.open}
      transition={{
        animation: "scale",
        duration: 300
      }}
    >
      <Modal
        open
        size={modalSize}
        closeOnEscape
        closeOnDimmerClick
        onClose={close}
        style={{ overflow: "visible" }}
      >
        <Modal.Header>
          <div style={{ textAlign: "center" }}>
            {modalTitle()}
            <Icon
              role="button"
              name="close"
              size={!isDesktop() ? "small" : undefined}
              aria-label="Close"
              tabIndex={0}
              className="close-modal-icon"
              onClick={close}
            />
          </div>
        </Modal.Header>
        <Modal.Content>{renderModalContent()}</Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};

export default TableModal;
