import React, { useState } from "react";
import useImageSize from "../../hooks/useImageSize";
import Toolbar from "./OCR/Toolbar";

const DEFAULT_WIDTH = 1;

export default function ImageAttachment({ url }) {
  const [imageZoom, setImageZoom] = useState(DEFAULT_WIDTH);
  const { isMaxWidth, imageRef } = useImageSize();

  return (
    <React.Fragment>
      <Toolbar
        scale={imageZoom}
        setScale={setImageZoom}
        maxImageSize={isMaxWidth}
      />
      <div className="image-attachment-container">
        <img
          alt=""
          src={url}
          style={{ maxWidth: `${imageZoom * 100}%` }}
          ref={imageRef}
        />
      </div>
    </React.Fragment>
  );
}
