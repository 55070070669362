import React, { useState, useEffect } from "react";
import moment from "moment";
import { Message, TextArea } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react-yz";
import InputField from "../../../Ui/InputField";
import InputDropdown from "../../../Ui/InputDropdown";
import UiModal from "../../../Ui/Modal";
import ActionButtons from "../../../Ui/ActionButtons";
import i18n from "../../../i18n";
import ServiceService from "../../../../services/data_services/service";
import CredentialService from "../../../../services/data_services/credentials";
import Toast from "../../../../services/toasts";
import { getDateFormat } from "../../../../services/timezones";
import { SERVICE_TYPES } from "../../../../constants/service-types";

const initialValues = {
  name: "",
  service_id: null,
  username: "",
  password: "",
  expiration_date: "",
  description: ""
};

const mandatoryFields = ["name", "service_id", "username", "password"];
const DATE_FORMAT = "YYYY-MM-DD";

const CredentialsModal = ({ open, onClose, onSubmit, user, item }) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const userDateFormat = getDateFormat(user.timezone);

  useEffect(() => {
    if (item) {
      const itemValues = {
        name: item.name,
        service_id: item.service_id,
        username: item.login,
        password: "",
        expiration_date: item.expirydate,
        description: item.description
      };
      setFormValues(itemValues);
    } else {
      setFormValues(initialValues);
    }
  }, [item]);

  useEffect(() => {
    if (open && serviceOptions.length === 0) loadServices();
  }, [open]);

  const loadServices = () => {
    ServiceService.loadServices().then(({ data }) => {
      const auxServices = data
        .filter(s => s.service_type === SERVICE_TYPES.BLUEPRISM)
        .map(s => {
          return {
            key: s.id,
            text: s.name,
            value: s.id
          };
        });
      setServiceOptions(auxServices);
    });
  };

  const handleFormChange = (name, value) => {
    setFormValues(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const canSubmit = () =>
    mandatoryFields.filter(f => !formValues[f]).length === 0;

  const handleSubmit = evt => {
    evt.preventDefault();
    const submitAction = item
      ? CredentialService.updateCredentials
      : CredentialService.createCredential;
    setSubmitting(true);
    submitAction(formValues)
      .then(() => {
        Toast.displaySuccess(
          item ? i18n.t("CredentialUpdated") : i18n.t("CredentialCreated")
        );
        onSubmit();
      })
      .catch(() => {
        Toast.displayError(i18n.t("SomethingWentWrong"));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    setFormValues(initialValues);
    onClose();
  };

  const getDateInputValue = date =>
    moment(date).isValid() ? moment(date).format(userDateFormat) : "";

  const setDateInputValue = value => {
    const parsedDate = moment(value, userDateFormat);
    handleFormChange(
      "expiration_date",
      parsedDate.isValid() ? parsedDate.format(DATE_FORMAT) : ""
    );
  };

  const renderForm = () => {
    return (
      <form
        onSubmit={event => handleSubmit(event)}
        className="env-variables-form"
      >
        <InputField
          required
          disabled={item}
          inputlabel={i18n.t("CredentialName")}
          value={formValues.name}
          onChange={(e, { value }) => handleFormChange("name", value)}
        />
        <InputDropdown
          required
          inputlabel="Service"
          placeholder={i18n.t("SelectPlaceholder")}
          search
          selection
          disabled={item || serviceOptions.length === 0}
          options={serviceOptions}
          value={formValues.service_id}
          onChange={(e, { value }) => handleFormChange("service_id", value)}
        />
        <InputField
          required
          inputlabel="Username"
          value={formValues.username}
          onChange={(e, { value }) => handleFormChange("username", value)}
        />
        <InputField
          required
          inputlabel="Password"
          type="password"
          value={formValues.password}
          onChange={(e, { value }) => handleFormChange("password", value)}
        />
        <div className="ui form">
          <div className="inputField-label">{i18n.t("ExpirationDate")}</div>
          <DateInput
            name="expiration_date"
            placeholder={i18n.t("ExpirationDate")}
            dateFormat={userDateFormat}
            animation="none"
            value={getDateInputValue(formValues.expiration_date)}
            minDate={moment()}
            iconPosition="left"
            clearable
            closable
            disabled={submitting}
            onChange={(e, { value }) => setDateInputValue(value)}
          />
        </div>
        <div className="ui form">
          <div className="inputField-label">{i18n.t("Description")}</div>
          <TextArea
            value={formValues.description}
            onChange={(e, { value }) => handleFormChange("description", value)}
          />
        </div>
        <Message warning size="tiny">
          <Message.Content>
            <i className="warning sign icon" /> {i18n.t("CredentialWarning")}
          </Message.Content>
        </Message>
        <ActionButtons
          cancelAction={handleClose}
          isSubmitting={submitting}
          disabled={!canSubmit()}
        />
      </form>
    );
  };
  return (
    <UiModal
      open={open}
      headerContent={
        item ? i18n.t("UpdateCredential") : i18n.t("CreateCredential")
      }
      onClose={handleClose}
    >
      {renderForm()}
    </UiModal>
  );
};

export default CredentialsModal;
