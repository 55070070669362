import React from "react";

export const AppContext = React.createContext();

const Context = props => {
  return (
    <AppContext.Provider value={props.value}>{props.children}</AppContext.Provider>
  );
};

export default Context;
