import React from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";
import i18n from "../../i18n";

const OCRModal = props => {
  return (
    <TransitionablePortal
      open={props.open}
      transition={{
        animation: "scale",
        duration: 300
      }}
    >
      <Modal open size="tiny" onClose={() => props.toggleModal(false)}>
        <Modal.Header>
          <React.Fragment>
            <div style={{ textAlign: "center" }}>
              {i18n.t("SubmitTranscription")}
              <i
                role="button"
                tabIndex={0}
                aria-label="close"
                className="close icon"
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => props.toggleModal(false)}
              />
            </div>
          </React.Fragment>
        </Modal.Header>
        <Modal.Content>
          <p style={{ textAlign: "center" }}>
            {i18n.t("SubmitTranscriptionMessage")}
          </p>
          <div className="modalButtonsContainer">
            <button
              type="button"
              className="ui button blue basic"
              style={{ width: "100%" }}
              onClick={() => props.toggleModal(false)}
            >
              {i18n.t("NoButton")}
            </button>
            <button
              type="button"
              className="ui button blue"
              style={{ width: "100%" }}
              onClick={() => props.onSubmit()}
            >
              {i18n.t("YesButton")}
            </button>
          </div>
        </Modal.Content>
      </Modal>
    </TransitionablePortal>
  );
};

export default OCRModal;
