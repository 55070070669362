import React from "react";
import i18n from "../i18n";

class WithLoadingComponent extends React.Component {
  handleRender() {
    if (this.props.loadingCondition()) {
      return this.renderLoading();
    }
    if (this.props.contentCondition()) {
      return this.renderContent();
    }
    return this.renderEmpty();
  }

  renderLoading = () => {
    if (this.props.transparent) {
      return (
        <div className="ui active text centered inline loader">
          {i18n.t("Loading")}
        </div>
      );
    }

    return (
      <div className="ui active inverted dimmer" style={{ zIndex: 2 }}>
        <div className="ui text loader">{i18n.t("Loading")}</div>
      </div>
    );
  };

  renderEmpty = () => {
    return (
      <div
        className="ui container text center aligned"
        style={{ width: "auto", paddingTop: "2rem" }}
      >
        <span>{i18n.t(this.props.noItemsKey)}</span>
      </div>
    );
  };

  renderContent = () => {
    return this.props.children;
  };

  render() {
    const heightClass = !this.props.noWhiteSpace ? "whiteSpaceHeight" : "";
    const extraClasses = this.props.className ? this.props.className : "";
    const Classes = this.props.noClassName ? "" : `${extraClasses} ui segment transparent noShadow loader-component ${heightClass}`;
    return (
      <div
        style={{ display: "flex !important" }}
        className={Classes}
      >
        {this.handleRender()}
      </div>
    );
  }
}

WithLoadingComponent.defaultProps = {
  noWhiteSpace: false,
  className: false
};
export default WithLoadingComponent;
