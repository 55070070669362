import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Icon, Popup } from "semantic-ui-react";
import i18n from "../../i18n";
import ActivityNewComment from "./ActivityNewComment";
import CommentsService from "../../../services/data_services/comments";
import Toast from "../../../services/toasts";
import Attachment from "../Attachment";
import { commentDate } from "../../../services/string";
import i18next from "i18next";

class ActivityComment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingRossum: false,
      openCommentForm: false
    };
  }

  getUserName() {
    return <span className="userName">{this.props.comment.author}</span>;
  }

  renderAttachments = () => {
    const { attachments } = this.props.comment;
    if (!attachments || attachments.length === 0) return null;

    const filteredAttachments = attachments.filter(attachment => attachment);

    return (
      filteredAttachments &&
      filteredAttachments.length > 0 && (
        <div className="extra content comment-attachments">
          {filteredAttachments.map((attachment, index) => (
            <div key={index} style={{ paddingRight: "10px" }}>
              <Attachment
                attachment={attachment}
                setPreviewUrl={this.props.setPreviewUrl}
              />
            </div>
          ))}
        </div>
      )
    );
  };

  processMessage = () => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(
      this.props.comment.description,
      "text/html"
    );

    const linksFound = Array.from(htmlDoc.getElementsByTagName("a"));
    const rossumLink = linksFound.filter(link => link.host.includes("rossum"));

    return (
      rossumLink.length > 0 && (
        <div className="rossum-button">
          <button
            type="button"
            className={`ui button small blue ${
              this.state.loadingRossum ? "loading disabled" : ""
            }`}
            onClick={() => {
              this.setState(
                {
                  loadingRossum: true
                },
                () => {
                  CommentsService.getRossumLink(
                    this.props.comment.id,
                    `${location.protocol}//${window.location.hostname}${
                      location.port ? `:${location.port}` : ""
                    }/activities/cancel`
                  )
                    .then(result => {
                      this.setState(
                        {
                          loadingRossum: false
                        },
                        () => {
                          this.props.openRossumIframe(result.data);
                        }
                      );
                    })
                    .catch(error => {
                      this.setState(
                        {
                          loadingRossum: false
                        },
                        Toast.displayError(error.response.data.message)
                      );
                    });
                }
              );
            }}
          >
            {i18n.t("Open")}
          </button>
        </div>
      )
    );
  };

  getText = () => {
    return this.props.comment.description.replace(/<\/?a.*?>/, "");
  };

  displayEdit = () => {
    return (
      !this.props.fromNCW &&
      !this.rossumLinkPresent() &&
      this.props.comment?.author_id === this.props.user.id
    );
  };

  toggleEditComment = value => {
    this.setState({ openCommentForm: value });
  };

  rossumLinkPresent = () => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(
      this.props.comment.description,
      "text/html"
    );

    const linksFound = Array.from(htmlDoc.getElementsByTagName("a"));
    const rossumLink = linksFound.filter(link => link.host.includes("rossum"));

    return rossumLink?.length >= 1;
  };

  commentEdited = () => {
    const { author_id, created_at, updated_at } = this.props.comment;
    const createdAtMoment = moment(created_at);
    const updatedAtMoment = moment(updated_at);
    const hasBeenEdited = !updatedAtMoment.isSame(createdAtMoment);
    return hasBeenEdited && author_id;
  };

  displayEditedLabel = () => {
    return (
      <Popup
        inverted
        trigger={(
          <span className="edited-label">{` - ${i18n.t("Edited")}`}</span>
        )}
      >
        {commentDate(this.props.comment.updated_at)}
      </Popup>
    );
  };

  render() {
    return (
      <div
        key={this.props.comment.id}
        className="card"
        style={{ width: "100%" }}
      >
        {this.state.openCommentForm ? (
          <div className="edit-comment">
            <ActivityNewComment
              onClose={() => this.toggleEditComment(false)}
              activity={this.props.activity}
              loadActivityData={this.props.loadActivityData}
              user={this.props.user}
              comment={this.props.comment}
            />
          </div>
        ) : (
          <>
            <div className="content">
              <div className="commentTitle">
                {this.getUserName()} - {commentDate(this.props.comment.created_at)}
                {this.displayEdit() && (
                  <Icon
                    name="edit"
                    className="edit-icon"
                    onClick={() => this.toggleEditComment(true)}
                  />
                )}
                {this.commentEdited() && this.displayEditedLabel()}
              </div>
              <div
                className="extra text"
                dangerouslySetInnerHTML={{ __html: this.getText() }}
              />
              {this.processMessage()}
            </div>
            {this.renderAttachments()}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.currentUserReducer.user
});

export default connect(mapStateToProps)(ActivityComment);
