import I18n from "../components/i18n";

export const PRIORITY = [
  { label: I18n.t("Low"), value: 3 },
  { label: I18n.t("Normal"), value: 2 },
  { label: I18n.t("High"), value: 1 },
  { label: I18n.t("Critical"), value: 0 }
];

export const PRIORITY_OPTIONS = PRIORITY.map(p => {
  return {
    label: p.label,
    value: p.value
  };
});

export const getText = value => {
  try {
    return PRIORITY.filter(element => element.value === value)[0].label;
  } catch (e) {
    return "-";
  }
};
