import React from "react";
import { Redirect } from "react-router-dom";
import i18n from "../../i18n";
import SchedulersForm from "./SchedulersForm";
import Toast from "../../../services/toasts";
import WithLoadingComponent from "../../Ui/WithLoadingComponent";
import SchedulerService from "../../../services/data_services/scheduler";
import { checkAndRedirect } from "../../../services/axios";
import Upperbar from "../../Ui/Upperbar/Upperbar";
import { isDesktop } from "../../../services/general";

class SchedulersNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduler: undefined
    };

    this.setBreadCrumb();

    if (props.match.params.id) {
      SchedulerService.loadScheduler(this.props.match.params.id)
        .then(result => {
          this.setState(
            {
              scheduler: result.data
            },
            this.setBreadCrumb
          );
        })
        .catch(checkAndRedirect);
    }
  }

  setBreadCrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Schedulers"),
        location: "/schedulers"
      },
      {
        title: this.title(),
        location: "/schedulers/new"
      }
    ]);
  }

  createScheduler = (values, setSubmitting, returnToSchedulers) => {
    const toSubmit = SchedulerService.prepareValuesFromFormValuesHelper(values);
    SchedulerService.createScheduler(toSubmit)
      .then(() => {
        setSubmitting(false);
        Toast.show.schedulerCreated();
        returnToSchedulers();
      })
      .catch(e => {
        if (
          Object.prototype.hasOwnProperty.call(
            e.response.data.message,
            "message"
          )
        ) {
          Toast.displayError(e.response.data.message.message[0]);
        } else if (
          Object.prototype.hasOwnProperty.call(e.response.data, "message")
        ) {
          Toast.displayError(e.response.data.message);
        } else {
          Toast.show.schedulerNotCreated();
        }
        setSubmitting(false);
      });
  };

  title() {
    return this.state.scheduler
      ? `${i18n.t("NewScheduler")} - ${this.state.scheduler.name}`
      : `${i18n.t("NewScheduler")}`;
  }

  schedulersVisible() {
    return this.props.user.teams.some(team => {
      return team.scheduling;
    });
  }

  render() {
    if (!this.schedulersVisible()) {
      return <Redirect to="/" />;
    }

    return (
      <>
        {isDesktop() && (
          <div style={{ marginTop: "-24px" }}>
            <Upperbar
              title={this.title()}
              onCancel={() => this.props.history.push("/schedulers")}
            />
          </div>
        )}
        <WithLoadingComponent
          loadingCondition={() =>
            this.props.match.params.id && !this.state.scheduler
          }
          contentCondition={() =>
            (this.props.match.params.id && this.state.scheduler.id) ||
            !this.props.match.params.id
          }
          noItemsKey="NotAbleLoadTeam"
        >
          <div className="ui segment">
            <SchedulersForm
              onSubmit={this.createScheduler}
              scheduler={this.state.scheduler}
              action="new"
            />
          </div>
        </WithLoadingComponent>
      </>
    );
  }
}

export default SchedulersNew;
