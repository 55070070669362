import React from "react";
import i18n from "../../../i18n";

const MobileColumn = ({ item, column }) => {
  const columnValue = () => {
    if (column.method) return column.method(item[column.key]);
    return item[column.key];
  };
  return (
    <li className="column-element">
      <span className="column-name">{i18n.t(column.name)}</span>
      <span className="column-value">{columnValue()}</span>
    </li>
  );
};

export default MobileColumn;
