import React from "react";
import { cloneDeep } from "lodash";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ClickOut from "react-click-out";
import TableFilterItem from "./TableFilterItem";

const DEFAULT_COLUMN_WIDTH = "150px";

class TableFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      style: props.style ? props.style : {}
    };

    this.toggleDisabled = this.toggleDisabled.bind(this);
  }

  disabled = index => {
    return !this.props.columns[index].show ? "disabled" : "";
  };

  toggleDisabled = (index, value) => {
    const columnsCopy = cloneDeep(this.props.columns);
    columnsCopy[index].show = value;
    this.props.setColumns(columnsCopy);
  };

  onDragEnd = result => {
    const { destination, source } = result;

    // User cancel drop
    if (!destination) {
      return;
    }

    // User drop item to the same location
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newFields = Array.from(this.props.columns);
    const element = newFields.splice(source.index, 1);
    newFields.splice(destination.index, 0, element[0]);

    this.props.setColumns(newFields);
  };

  hidePopup = () => {
    this.props.toggle();
  };

  render() {
    if (this.props.visible) {
      return (
        <ClickOut onClickOut={this.hidePopup}>
          <div className="table_filter_modal" style={this.state.style}>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="menu_1">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {this.props.columns.map((column, index) => (
                      <TableFilterItem
                        key={column.key}
                        index={index}
                        column={column}
                        toggleDisabled={this.toggleDisabled}
                        disabled={this.disabled(index)}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </ClickOut>
      );
    }
    return null;
  }
}

export default TableFilter;
