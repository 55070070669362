import React from "react";
import AdminSection from "./AdminSection";
import i18n from "../i18n";
import { VisitableComponents } from "../../services/detailedPermissionRules";
import Permissions from "../../services/permissions";

class AdminShow extends React.Component {
  constructor(props) {
    super(props);
    props.setBreadcrumb([{ title: i18n.t("Settings"), location: "/admin" }]);
  }

  showDashboard = _ => {
    const adminSections = [
      {
        url: "/admin/applications",
        icon: "sitemap",
        title: "Applications",
        component: VisitableComponents.Application
      },
      {
        url: "/admin/forms",
        icon: "clipboard outline",
        title: "Forms",
        component: VisitableComponents.Form
      },
      {
        url: "/admin/emails",
        icon: "mail outline",
        title: "Emails",
        component: VisitableComponents.Users
      }
    ];
    if (this.props?.user?.company?.access_to?.dashboard === "true") {
      adminSections.push({
        url: "/admin/dashboard",
        icon: "chart bar",
        title: "Dashboard",
        component: VisitableComponents.Users
      });
    }
    return adminSections;
  };

  renderSection() {
    return (
      <div>
        <AdminSection
          title="Configuration"
          description="ConfigurationDescription"
          elements={this.showDashboard()}
        />
        <AdminSection
          title="UsersManagement"
          description="UsersManagementDescription"
          elements={[
            {
              url: "/admin/users",
              icon: "user outline",
              title: "Users",
              component: VisitableComponents.Users
            },
            {
              url: "/admin/teams",
              icon: "handshake outline",
              title: "Teams",
              component: VisitableComponents.Teams
            }
          ]}
        />
        {/* <AdminSection
          title="RPA Management"
          description="AssetsManagementDescription"
          elements={[
            {
              url: "/admin/queues",
              icon: "file alternate outline",
              title: "Queues",
              component: VisitableComponents.Queues
            },
            {
              url: "/admin/env_variables",
              icon: "file alternate outline",
              title: "EnvVariables",
              component: VisitableComponents.EnvVariables
            },
            {
              url: "/admin/resources",
              icon: "file alternate outline",
              title: "Resources",
              component: VisitableComponents.Resources
            }
          ]}
        /> */}
      </div>
    );
  }

  render() {
    return (
      <Permissions
        component={[
          VisitableComponents.Application,
          VisitableComponents.Users,
          VisitableComponents.Teams,
          VisitableComponents.Queues,
          VisitableComponents.EnvVariables
        ]}
        action="read"
        yes={() => {
          return this.renderSection();
        }}
        no={() => {
          return null;
        }}
      />
    );
  }
}

export default AdminShow;
