import React, { useState } from "react";
import UiModal from "../../Ui/Modal";
import InputField from "../../Ui/InputField";
import i18n from "../../i18n";

const RejectModal = props => {
  const [message, setMessage] = useState("");
  const onSubmitHandler = () => {
    if (props.approve || (!props.approve && message)) {
      props.onSubmit(message);
    }
  };

  return (
    <UiModal
      open={props.open}
      onClose={props.onClose}
      headerContent={props.headerText}
    >
      <div className="reject-label">{props.infoText}</div>
      <InputField
        textarea
        value={message}
        onChange={e => setMessage(e.target.value)}
        placeholder={i18n.t("RejectPlaceholder")}
      />
      <div className="modal-buttons">
        <button
          type="button"
          className="ui basic blue button"
          disabled={props.isSubmitting}
          onClick={() => {
            setMessage("");
            props.onClose();
          }}
        >
          {i18n.t("Cancel")}
        </button>
        <button
          type="button"
          className={`ui blue ${props.isSubmitting ? "loading" : ""} button`}
          onClick={() => onSubmitHandler()}
          disabled={(!props.approve && !message) || props.isSubmitting}
        >
          {props.buttonText}
        </button>
      </div>
    </UiModal>
  );
};

export default RejectModal;
