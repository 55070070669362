import React from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logo from "images/logo.png";
import i18n from "../i18n";
import axios from "../../services/axios";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  transformEmail = values => {
    return {
      ...values,
      email: values.email.toLowerCase().trim()
    };
  };

  onFormSubmit = (values, submittingFormik) => {
    axios
      .post("/authentication", this.transformEmail(values))
      .then(response => {
        const { user } = response.data;
        if (!user.timezone) {
          user.timezone = moment.tz.guess();
        }
        this.props.logIn(user);
        if(response.data.user?.company?.access_to?.init_module && response.data.user.roles.includes('admin'))
        {
          window.location.replace('/'+response.data.user.company.access_to.init_module) 
        }
        submittingFormik(false);
      })
      .catch(() => {
        this.setState({ error: i18n.t("LoginError") });
        submittingFormik(false);
      });
  };

  errorMessage() {
    if (this.state.error) {
      return (
        <div style={{ minHeight: "50px", paddingBottom: "10px" }}>
          <p className="error">{this.state.error}</p>
        </div>
      );
    }

    return null;
  }

  renderLoginForm() {
    return (
      <div>
        <div className="ui center aligned header">
          <img
            src={logo}
            alt="Invoke Logo"
            style={{ width: "201px"}}
          />
        </div>

        {this.errorMessage()}

        <Formik
          onSubmit={(values, { setSubmitting }) => {
            this.onFormSubmit(values, setSubmitting);
          }}
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .trim()
              .email(i18n.t("EmailInvalidValidation"))
              .required(i18n.t("EmailRequiredValidation")),
            password: Yup.string().required(
              i18n.t("PasswordRequiredValidation")
            )
          })}
        >
          {({ handleChange, handleSubmit, isSubmitting, errors }) => (
            <form
              onSubmit={handleSubmit}
              className="ui form container"
              style={{ paddingBottom: "10px" }}
            >
              <div className={`field ${errors.email ? "withError" : ""}`}>
                <Field
                  name="email"
                  onChange={handleChange}
                  type="text"
                  placeholder={i18n.t("LoginEmailPlaceholder")}
                />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
              <div className={`field ${errors.password ? "withError" : ""}`}>
                <Field
                  name="password"
                  onChange={handleChange}
                  type="password"
                  placeholder={i18n.t("PasswordPlaceholder")}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </div>

              <button
                className="ui button blue"
                type="submit"
                style={{ width: "100%" }}
                disabled={isSubmitting}
              >
                {i18n.t("LoginButton")}
              </button>
            </form>
          )}
        </Formik>

        <div className="ui right aligned" style={{ textAlign: "right" }}>
          <Link to="/remember">{i18n.t("ForgotPassword")}</Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%"
        }}
      >
        <div
          className="ui very padded segment"
          style={{ width: "100%", maxWidth: "450px" }}
        >
          {this.renderLoginForm()}
        </div>
        <div style={{ textAlign: "center", padding: "25px" }}>
          <p style={{ color: "white" }}>{i18n.t("NotAccountYet")}</p>
          <Link to="/contact_admin">{i18n.t("ContactAdmin")}</Link>
        </div>
      </div>
    );
  }
}

export default Login;
