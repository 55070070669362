import React from "react";
import { Link } from "react-router-dom";
import { isEmpty, debounce, isEqual } from "lodash";
import Select from "react-select";
import { Button, Dropdown } from "semantic-ui-react";
import Table from "../../Ui/Table/Table";
import { TYPES } from "../../Ui/Table/types";
import { tableActions } from "../../Ui/Table/TableActions";
import Toast from "../../../services/toasts";
import SchedulerService from "../../../services/data_services/scheduler";
import { activityDate } from "../../../services/string";
import { PRIORITY, PRIORITY_OPTIONS } from "../../../constants/priority";
import axios from "../../../services/axios";
import { urlFromSearchParam, searchParamFromUrl } from "../../../services/url";
import { reactSelectStyle } from "../../../semantic-ui";
import i18n from "../../i18n";
import { isDesktop } from "../../../services/general";
import SearchBox from "../../Ui/SearchBox";

const dropdownOptions = [
  { key: "running", text: "Running", value: false },
  { key: "paused", text: "Paused", value: true }
];

class Schedulers extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = searchParamFromUrl(this.props.location.search);

    this.state = {
      columns: [
        {
          name: i18n.t("Scheduler"),
          show: true,
          key: "name"
        },
        {
          name: i18n.t("Process"),
          show: true,
          key: "scheduler_processes",
          method: this.processName
        },
        {
          name: "When",
          show: true,
          key: "cron_text",
          searchable: false,
          sortable: false
        },
        {
          name: i18n.t("SLA"),
          show: true,
          key: "sla",
          methodNoColumn: this.slaColumn,
          editContent: this.editSlaContent
        },
        {
          name: i18n.t("Timeout"),
          show: true,
          key: "deadline",
          methodNoColumn: this.deadlineColumn,
          editContent: this.editDeadlineContent
        },
        {
          name: i18n.t("Priority"),
          show: true,
          key: "priority",
          method: this.priorityName,
          editContent: this.editPriorityContent,
          type: "select",
          options: PRIORITY_OPTIONS,
          single: true
        },
        {
          name: i18n.t("FirstRun"),
          show: true,
          key: "start_date",
          method: activityDate,
          type: "datetime"
        },
        {
          name: i18n.t("LastRun"),
          show: true,
          key: "end_date",
          method: activityDate,
          type: "datetime"
        }
      ],
      schedulers: undefined,
      loadingSchedulers: true,
      queryParams: {
        ...queryParams,
        paused: queryParams?.paused === "true"
      },
      nPages: 0
    };
  }

  componentDidMount() {
    this.setBreadcrumb();
    this.loadSchedulers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.props.history.replace(
        `schedulers?${urlFromSearchParam(this.state.queryParams)}`
      );
      this.loadSchedulers();
    }
  }

  setBreadcrumb = () => {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Schedulers"),
        location: "/schedulers"
      }
    ]);
  };

  setQueryParams(key, value, forceFirstPage = false) {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      if (forceFirstPage) {
        queryParams.page = 1;
      }

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  }

  // eslint-disable-next-line react/sort-comp
  loadSchedulers = debounce(() => {
    this.setState({ loadingSchedulers: true });
    SchedulerService.loadSchedulers(this.state.queryParams)
      .then(response => {
        this.setState({
          schedulers: response.data.results,
          nPages: response.data.total,
          totalItems: response.data.hits
        });
      })
      .catch(() => {
        this.setState({
          schedulers: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingSchedulers: false });
      });
  }, 500);

  reloadSchedulers = () => {
    this.setState({ schedulers: null }, this.loadSchedulers());
  };

  loadItems = entries => {
    if (!entries) {
      this.loadSchedulers();
    } else {
      Object.keys(entries).forEach(key => {
        this.setQueryParams(key, entries[key]);
      });
    }
  };

  editSlaContent = (id, value, onChange) => {
    return (
      <div className="ui transparent input">
        <input
          type="number"
          className="scheduler-input"
          defaultValue={this.slaColumn(value, false)}
          placeholder={i18n.t("Minutes")}
          onChange={e => onChange(id, e.target.value)}
        />
      </div>
    );
  };

  editDeadlineContent = (id, value, onChange) => {
    return (
      <div className="ui transparent input">
        <input
          type="number"
          className="scheduler-input"
          defaultValue={this.deadlineColumn(value, false)}
          placeholder={i18n.t("Minutes")}
          onChange={e => onChange(id, e.target.value)}
        />
      </div>
    );
  };

  priorityOptions = () => {
    return PRIORITY;
  };

  priorityValue = value => {
    return this.priorityOptions().filter(option => option.value === value);
  };

  editPriorityContent = (id, value, onChange) => {
    return (
      <Select
        defaultValue={this.priorityValue(value)}
        options={this.priorityOptions()}
        onChange={option => {
          onChange(id, option.value);
        }}
        placeholder={`${i18n.t("Select")}...`}
        className="react-select-container"
        classNamePrefix="react-select"
        styles={reactSelectStyle}
      />
    );
  };

  updateTableAction = (editField, values) => {
    if (!isEmpty(values)) {
      const requests = Object.keys(values).map(key => {
        const data = {};
        data[`${editField}`] = values[key];

        return axios.put(`/schedulers/${key}`, data);
      });

      Promise.all(requests)
        .then(() => {
          Toast.show.schedulerUpdated();
          this.loadSchedulers();
        })
        .catch(() => {
          Toast.show.schedulerNotUpdated();
          this.loadSchedulers();
        });
    }
  };

  processName = processes => {
    if (Array.isArray(processes)) {
      return processes.map(p => p.process_name).join(", ");
    }
    return "";
  };

  priorityName = priority => {
    let result = "-";

    if (priority !== null) {
      const foundPriority = PRIORITY.find(p => p.value === priority);
      result = foundPriority ? foundPriority.label : priority;
    }

    return result;
  };

  _timeColumn = (event, column, activatedColumn, addMinutes) => {
    if (event[activatedColumn]) {
      return event[column] + (addMinutes ? ` ${i18n.t("minutes")}` : "");
    }

    return i18n.t("No");
  };

  slaColumn = (event, addMinutes = true) => {
    return this._timeColumn(event, "sla", "sla_activated", addMinutes);
  };

  deadlineColumn = (event, addMinutes = true) => {
    return this._timeColumn(
      event,
      "deadline",
      "deadline_activated",
      addMinutes
    );
  };

  time = minutes => {
    return minutes ? `${minutes} minutes` : "No";
  };

  tableActions = () => {
    const actions = [];
    if (this._allowPause()) {
      actions.push(tableActions.schedulers.pause);
    }
    if (this._allowPlay()) {
      actions.push(tableActions.schedulers.play);
    }
    if (this._allowEdit()) {
      actions.push(tableActions.schedulers.clone);
      actions.push(tableActions.schedulers.edit);
    }
    if (this._allowDelete()) {
      actions.push(tableActions.schedulers.delete);
    }

    return actions;
  };

  _allowEdit = () => this.props.user.teams.some(team => team.allow_edit);

  _allowDelete = () => this.props.user.teams.some(team => team.allow_delete);

  _allowPlay = () => this.state.queryParams?.paused;

  _allowPause = () => !this.state.queryParams?.paused;

  renderCreateButton = () => (
    <div className="create-button-container">
      <Button
        as={Link}
        to="/schedulers/new"
        className="ui button blue create-button"
      >
        {i18n.t("NewScheduler")}
      </Button>
    </div>
  );

  renderSearchBar = () => {
    const dropdown = (
      <Dropdown
        button
        basic
        floating
        options={dropdownOptions}
        onChange={(e, { value }) => {
          this.setQueryParams("paused", value, true);
        }}
        value={this.state.queryParams?.paused}
      />
    );

    return (
      <div className="schedulers-searchbar">
        <SearchBox
          action={dropdown}
          actionPosition="right"
          fullLength={!isDesktop()}
          value={this.state.queryParams?.q}
          loadItems={searchParams => {
            this.setQueryParams("search_param", searchParams, true);
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="ui segment noShadow">
        <div>
          <Table
            totalItems={this.state.totalItems}
            tableId={TYPES.SCHEDULERS}
            columns={this.state.columns}
            items={this.state.schedulers}
            noItemsKey="NoSchedulers"
            actions={this.tableActions()}
            loadItems={entries => {
              this.loadItems(entries);
            }}
            loadingItems={this.state.loadingSchedulers}
            filters={this.state.queryParams}
            updateItems={this.updateTableAction}
            nPages={this.state.nPages}
            page={this.state.queryParams ? this.state.queryParams.page : 1}
            mobileColumns={{
              name: "name",
              timestamp: "start_date"
            }}
            customSearch={this.renderSearchBar}
            createAction={() => this.props.history.push("/schedulers/new")}
            reloadAction={this.reloadSchedulers}
          />
        </div>
      </div>
    );
  }
}

export default Schedulers;
