import React from "react";

const ErrorBadge = props => {
    return (
        props.message ?
            <div
                className="ui mini icon circular button red"
                data-tooltip={props.message}
                data-inverted
                data-position="bottom right"
                style={props.style}
            >
                <i className="info icon"></i>
            </div>
            :
            <React.Fragment/>
    )
};

export default ErrorBadge