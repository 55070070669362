import React from "react";
import { debounce, isEqual } from "lodash";
import i18n from "../../../i18n";
import { rolesNames, activityDate } from "../../../../services/string";
import Permissions from "../../../../services/permissions";
import {
  Actions,
  VisitableComponents
} from "../../../../services/detailedPermissionRules";
import Table from "../../../Ui/Table/Table";
import { TYPES } from "../../../Ui/Table/types";
import { tableActions } from "../../../Ui/Table/TableActions";
import UserService from "../../../../services/data_services/user";
import {
  searchParamFromUrl,
  urlFromSearchParam
} from "../../../../services/url";

const userOptions = [
  { label: "User", value: "user" },
  { label: "Admin", value: "admin" },
  { label: "Super Admin", value: "super_admin" }
];

class UsersTable extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = searchParamFromUrl(this.props.location.search);

    this.state = {
      users: undefined,
      loadingUsers: true,
      columns: [
        { name: "Name", key: "name", show: true },
        { name: "Email", show: true, key: "email" },
        {
          name: "Roles",
          show: true,
          key: "roles",
          method: rolesNames,
          type: "select",
          options: userOptions,
          single: true
        },
        {
          name: "LastLogin",
          show: true,
          key: "last_sign_in_at",
          type: "datetime",
          method: activityDate
        }
      ],
      queryParams,
      nPages: 0
    };

    props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Users"),
        location: "/admin/users"
      }
    ]);

    this.loadUsers();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.props.history.push(`?${urlFromSearchParam(this.state.queryParams)}`);
      this.loadUsers();
    }
  }

  setQueryParams = (key, value) => {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  };

  loadUsers = debounce(() => {
    this.setState({ loadingUsers: true });
    const query = { ...this.state.queryParams };

    UserService.loadUsers(query)
      .then(result => {
        this.setState({
          users: result.data.results.filter(user => user.active),
          nPages: result.data.total,
          totalItems: result.data.hits
        });
      })
      .catch(() => {
        this.setState({
          users: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingUsers: false });
      });
  }, 500);

  loadItems(entries) {
    if (!entries) {
      this.loadUsers();
    } else {
      Object.keys(entries).forEach(key => {
        this.setQueryParams(key, entries[key]);
      });
    }
  }

  reloadUsers = () => {
    this.setState({ users: null, nPages: 0 }, this.loadUsers());
  };

  render() {
    return (
      <Permissions
        component={VisitableComponents.Users}
        action={Actions.read}
        yes={() => {
          return (
            <Table
              totalItems={this.state.totalItems}
              tableId={TYPES.USERS}
              columns={this.state.columns}
              items={this.state.users}
              noItemsKey="NoUsersYet"
              actions={[tableActions.user.edit, tableActions.user.delete]}
              loadItems={entries => {
                this.loadItems(entries);
              }}
              loadingItems={this.state.loadingUsers}
              filters={this.state.queryParams}
              user={this.props.user}
              nPages={this.state.nPages}
              page={this.state.queryParams ? this.state.queryParams.page : 1}
              mobileColumns={{ timestamp: "last_sign_in_at" }}
              createAction={() => this.props.history.push("users/new")}
              component={VisitableComponents.Users}
              reloadAction={this.reloadUsers}
            />
          );
        }}
        no={() => {
          return null;
        }}
      />
    );
  }
}

export default UsersTable;
