import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { cloneDeep } from "lodash";
import { Grid } from "semantic-ui-react";
import InputDateTime from "../../../Ui/InputDateTime";
import InputField from "../../../Ui/InputField";
import i18n from "../../../i18n";
import ClientInfo from "./ClientInfo";
import ServicesInfo from "./ServicesInfo";
import RiskDetail from "./RiskDetail";
import AdditionalComments from "./AdditionalComments";
import { getDateFormat } from "../../../../services/timezones";


import InputDropdown from "../../../Ui/InputDropdown";
import NcwService from "../../../../services/data_services/ncw";

const Review = props => {
  const userTimezone = props.user.timezone || moment.tz.guess();
  const dateTimeFormatString = `${getDateFormat(props.user.timezone)} HH:mm`;

  const getSignatureDate = value => {
    return moment(value)
      .tz(userTimezone)
      .format(dateTimeFormatString);
  };

  
  const [loadingPartnersInCharge, setLoadingPartnersInCharge] = useState(false);
  const [partnerInChargeOptions, setPartnerInChargeOptions] = useState({fullList: [], dummyList: []});

  useEffect(() => {
    if (loadingPartnersInCharge) {
      loadPartnersInCharge();
    }
  }, [loadingPartnersInCharge]);

  const loadPartnersInCharge = () => {
    NcwService.getPartnersInCharge(props.application.uuid)
      .then(response => {
        const auxOptions = response.data.map(item => {
          return {
            key: item.StaffEMail,
            id: item.StaffIndex,
            text: item.StaffName,
            value: item.StaffName
          };
        });
        setPartnerInChargeOptions({fullList:auxOptions, dummyList:auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingPartnersInCharge(false);
      });
  };

  const onSelectChangeField = (target, value, partner = false) =>{
    let pepe = 0;
    let tempValues = {}
    if(typeof(props.data?.engagement_info) !== 'undefined'){
      tempValues = cloneDeep(props.data?.engagement_info);
    }
    let selectedOption = value.options.find(o => o.value === value.value)
    if(partner){
      tempValues[target+'_email'] = selectedOption?.key;
    }
    tempValues[`${target}${partner ? '_name': ''}`] = value.value;
    tempValues[target+'_id'] = selectedOption?.id;

    props.onChange('engagement_info', tempValues);
  }

  return (
    <React.Fragment>
      <div className="ncw-info">
        <Grid columns={2}>
          <Grid.Row stretched>
            <Grid.Column>
              <ClientInfo client={props.data.client} />
              <RiskDetail />
            </Grid.Column>
            <Grid.Column>
              <ServicesInfo
                services={props.data.ncw_services}
                fromForm={props.fromForm}
              />
              {!props.fromForm && (
                <AdditionalComments
                  comments={props.data.additional_comments}
                  disabled={!props.fromForm || props.preview}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      {!props.fromForm ? (
        <div className="signature-container">
          <div className="signature-label">{i18n.t("RequiredSignatures")}</div>
          <div className="signature-items">
            <InputField
              inputlabel={i18n.t("PartnerSignature")}
              value={props.data.partner_signature || ""}
              disabled={!props.fromForm || props.preview}
              fluid
            />
            <InputDateTime
              dateTimeFormat={dateTimeFormatString}
              inputlabel={i18n.t("Date")}
              disabled={!props.fromForm || props.preview}
              value={
                props.data.signature_date
                  ? getSignatureDate(props.data.signature_date)
                  : ""
              }
            />
          </div>
        </div>
      ):(        
        <Grid columns={4}>
          <Grid.Row stretched>
            <Grid.Column>
              <InputDropdown
                onClick={() => {
                  if (!loadingPartnersInCharge && partnerInChargeOptions?.fullList?.length === 0) {
                    setLoadingPartnersInCharge(true);
                  }
                  else{
                    setPartnerInChargeOptions({fullList: partnerInChargeOptions?.fullList, dummyList: partnerInChargeOptions?.fullList });
                  }
                }}
                loading={loadingPartnersInCharge}
                required
                error={props.errors?.engagement_info?.partner_in_charge_name}
                disabled={props.preview}
                noResultsMessage={i18n.t("TryAnotherSearch")}
                inputlabel={i18n.t("Approver")}
                placeholder={i18n.t("StartTyping")}
                options={partnerInChargeOptions?.dummyList}
                search
                selection
                value={props.data?.engagement_info?.partner_in_charge_name}
                onChange={(e, value) =>{
                  onSelectChangeField('partner_in_charge', value, true)
                  setPartnerInChargeOptions({fullList: partnerInChargeOptions?.fullList, dummyList: [] });
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(Review);
