import axios from "../axios";

class UserSettingsService {
  static loadUsersSettings = (userId, tableUuid) => {
    return axios.get(`/user_configs/show`, {
      params: {
        user_id: userId,
        table_id: tableUuid
      }
    });
  };

  static createUserSettings = (
    userId,
    tableUuid,
    fieldsArray,
    columnSettings
  ) => {
    return axios.post("/user_configs", {
      user_id: userId,
      table_id: tableUuid,
      columns: fieldsArray,
      column_settings: columnSettings
    });
  };

  static updateUserSettings = (
    userId,
    tableUuid,
    fieldsArray,
    columnSettings
  ) => {
    return axios.put("/user_configs/update", {
      user_id: userId,
      table_id: tableUuid,
      columns: fieldsArray,
      column_settings: columnSettings
    });
  };
}

export default UserSettingsService;
