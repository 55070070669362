import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import Permissions from "../../services/permissions";
import { Actions } from "../../services/detailedPermissionRules";
import i18n from "../i18n";
import { isDesktop } from "../../services/general";

class AdminElement extends React.Component {
  renderSection() {
    if (isDesktop()) {
      return (
        <div className="desktop">
          <Link to={this.props.url}>
            <span className="adminElement">
              <Icon name={this.props.icon} size="big" />
              <span>{i18n.t(this.props.title)}</span>
            </span>
          </Link>
        </div>
      );
    }
    return (
      <div className="mobile">
        <Link to={this.props.url}>
          <div className="adminElement centerElement">
            <Icon
              name={this.props.icon}
              size="big"
              style={{ margin: "0.5rem" }} />
            <div>{i18n.t(this.props.title)}</div>
          </div>
        </Link>
      </div>
    );
  }

  render() {
    if (this.props.component) {
      return (
        <Permissions
          component={this.props.component}
          action={Actions.read}
          yes={() => {
            return this.renderSection();
          }}
          no={() => {
            return null;
          }}
        />
      );
    }
    return this.renderSection();
  }
}

export default AdminElement;
