import React, { useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import qs from "query-string";
import * as Yup from "yup";
import i18n from "../i18n";
import AuthenticationService from "../../services/data_services/authentication";

const ResetPassword = props => {
  const [alreadyReset, setAlreadyReset] = useState(false);
  const [error, setError] = useState(null);

  const onFormSubmit = (values, submittingFormik) => {
    const valuesWithToken = { ...values };
    if (props.confirmation) {
      valuesWithToken.confirmation_token = qs.parse(
        window.location.search
      ).confirmation_token;
    } else {
      valuesWithToken.reset_password_token = qs.parse(
        window.location.search
      ).reset_password_token;
    }
    AuthenticationService.resetPassword(valuesWithToken)
      .then(() => {
        setAlreadyReset(true);
      })
      .catch(err => {
        const { message } = err.response.data;

        if (
          Object.prototype.hasOwnProperty.call(
            message,
            "reset_password_token"
          ) &&
          message.reset_password_token.includes("is invalid")
        ) {
          setError(i18n.t("ResetPasswordExpired"));
        } else {
          setError(i18n.t("SomethingWrong"));
        }
      })
      .finally(() => {
        submittingFormik(false);
      });
  };

  const headerText = () => {
    if (props.confirmation && alreadyReset) {
      return i18n.t("AccountConfirmed");
    }
    if (props.confirmation) {
      return i18n.t("SetPassword");
    }
    return i18n.t("ResetYourPass");
  };

  const errorMessage = () => {
    return (
      <div className="ui negative message">
        <span
          tabIndex={0}
          role="button"
          onClick={() => {
            setError(null);
          }}
          style={{ float: "right", cursor: "pointer" }}
        >
          <i className="close icon" />
        </span>
        <div className="header">{i18n.t("PasswordNotReset")}</div>
        <p>{error}</p>
      </div>
    );
  };

  const onClickLogin = () => {
    window.location.href = "/";
  };

  const renderPasswordReset = () => {
    const text = props.confirmation
      ? i18n.t("AccountConfirmedSuccess")
      : i18n.t("PasswordHasBeenReset");
    return (
      <div>
        <div
          className="ui center aligned text"
          style={{ textAlign: "center", paddingTop: "50px" }}
        >
          <p>{text}</p>
        </div>

        <div
          className="ui center aligned text"
          style={{
            textAlign: "center",
            paddingTop: "50px",
            paddingBottom: "25px"
          }}
        >
          <span className="linkLogin" onClick={() => onClickLogin()}>
            {i18n.t("BackLogin")}
          </span>
        </div>
      </div>
    );
  };

  const renderPasswordForm = () => {
    return (
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          onFormSubmit(values, setSubmitting);
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required(i18n.t("PasswordRequiredValidation"))
            .min(6, i18n.t("PasswordLengthValidation", { length: 6 })),
          confirm_password: Yup.string()
            .required(i18n.t("PasswordRequiredValidation"))
            .oneOf(
              [Yup.ref("password"), null],
              i18n.t("PasswordMatchValidation")
            )
            .min(6, i18n.t("ConfirmPasswordLengthValidation", { length: 6 }))
        })}
      >
        {({ handleChange, handleSubmit, isSubmitting, errors }) => (
          <form className="ui form container" onSubmit={handleSubmit}>
            {error && errorMessage()}

            <div className={`field ${errors.password ? "error" : ""}`}>
              <label>{i18n.t("Password")}</label>
              <Field
                name="password"
                type="password"
                onChange={handleChange}
                placeholder={i18n.t("NewPasswordPlaceholder")}
              />
              <ErrorMessage name="password" component="div" className="error" />
            </div>
            <div className={`field ${errors.confirm_password ? "error" : ""}`}>
              <label>{i18n.t("ConfirmPassword")}</label>
              <Field
                name="confirm_password"
                type="password"
                onChange={handleChange}
                placeholder={i18n.t("NewPasswordPlaceholder")}
              />
              <ErrorMessage
                name="confirm_password"
                component="div"
                className="error"
              />
            </div>
            <div style={{ paddingTop: "20px" }}>
              <button
                className={`ui button blue ${isSubmitting ? "loading" : ""}`}
                type="submit"
                disabled={isSubmitting}
                style={{ width: "100%" }}
              >
                {props.confirmation ? "Submit password" : i18n.t("ResetButton")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <div className="ui main column container">
      <div className="ui one column centered grid">
        <div
          className="ui raised very padded segment"
          style={{ width: "450px" }}
        >
          <h3 className="ui center aligned header">{headerText()}</h3>
          {alreadyReset ? renderPasswordReset() : renderPasswordForm()}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
