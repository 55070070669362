import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import i18n from "../i18n";
import axios from "../../services/axios";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";

class RememberPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false,
      email: "",
      error: null
    };
  }

  onFormSubmit = (values, submittingFormik = null) => {
    axios
      .post("/users/passwords", values)
      .then(() => {
        this.setState({
          sent: true,
          email: values.email
        });
        submittingFormik ? submittingFormik(false) : null;
      })
      .catch(error => {
        this.setState({ error: error.response.data.message });
        submittingFormik ? submittingFormik(false) : null;
      });
  };

  errorMessage() {
    if (this.state.error) {
      return (
        <div className="ui negative message">
          <span
            role="button"
            tabIndex={0}
            onClick={() => {
              this.setState({ error: null });
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            <i className="close icon" />
          </span>
          <div className="header">{i18n.t("NotSent")}</div>
          <p>{this.state.error}</p>
        </div>
      );
    }

    return null;
  }

  renderRememberPasswordForm() {
    return (
      <div className="ui main column container">
        <div className="ui one column centered grid">
          <div
            className="ui raised very padded segment"
            style={{ width: "450px" }}
          >
            <h3 className="ui center aligned header">
              {i18n.t("ForgotYourPass")}
            </h3>

            <div
              className="ui center aligned text"
              style={{ textAlign: "center", paddingTop: "40px" }}
            >
              <p>{i18n.t("ForgotHelp")}</p>
            </div>

            {this.errorMessage()}

            <Formik
              onSubmit={(values, { setSubmitting }) => {
                this.onFormSubmit(values, setSubmitting);
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(i18n.t("EmailInvalidValidation"))
                  .required(i18n.t("EmailRequiredValidation"))
              })}
            >
              {({ handleChange, handleSubmit, isSubmitting, errors }) => (
                <form
                  className="ui form container"
                  onSubmit={handleSubmit}
                  style={{ paddingTop: "25px", paddingBottom: "25px" }}
                >
                  <div className={`field ${errors.email ? "withError" : ""}`}>
                    <label>{i18n.t("Email")}</label>
                    <Field
                      name="email"
                      type="email"
                      onChange={handleChange}
                      placeholder={i18n.t("LoginEmailPlaceholder")}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div style={{ paddingTop: "20px" }}>
                    <button
                      className={`ui button blue ${
                        isSubmitting ? "loading" : ""
                      }`}
                      type="submit"
                      disabled={isSubmitting}
                      style={{ width: "100%" }}
                    >
                      {i18n.t("ResetButton")}
                    </button>
                  </div>
                </form>
              )}
            </Formik>

            <div
              className="ui center aligned text"
              style={{
                textAlign: "center",
                paddingTop: "100px",
                paddingBottom: "25px"
              }}
            >
              <Link to="/" className="orange">
                {i18n.t("BackLogin")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderEmailSentForm() {
    return (
      <div className="ui main column container">
        <div className="ui one column centered grid">
          <div
            className="ui raised very padded segment"
            style={{ width: "450px" }}
          >
            <h3 className="ui center aligned header">{i18n.t("LinkSent")}</h3>
            <div
              className="ui center aligned text"
              style={{ textAlign: "center", paddingTop: "40px" }}
            >
              <p>{i18n.t("LinkSentHelp")}</p>
            </div>

            {this.errorMessage()}

            <div style={{ paddingTop: "40px" }}>
              <button
                className="ui basic button blue"
                type="button"
                style={{ width: "100%" }}
                onClick={() => {
                  this.onFormSubmit({ email: this.state.email });
                }}
              >
                {i18n.t("ResendButton")}
              </button>
            </div>

            <div
              className="ui center aligned text"
              style={{
                textAlign: "center",
                paddingTop: "100px",
                paddingBottom: "25px"
              }}
            >
              <Link
                to="#"
                onClick={() => this.setState({ sent: false, email: "" })}
                className="orange"
              >
                {i18n.t("ReenterEmail")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.state.sent
      ? this.renderEmailSentForm()
      : this.renderRememberPasswordForm();
  }
}

export default RememberPassword;
