import React, { useState, useEffect, useRef } from "react";
import { extension } from "../../../services/files";
import useImageSize from "../../../hooks/useImageSize";
import FieldHighlight from "./FieldHighlight";
import ImageViewer from "./ImageViewer";
import PDFViewer from "./PDFViewer";
import Toolbar from "./Toolbar";

const DocumentViewer = props => {
  const [renderHighlight, setRenderHighlight] = useState(false);
  const [documentType, setDocumentType] = useState(undefined);
  const [documentMaxWidth, setMaxWidth] = useState(undefined);
  const documentRef = useRef(null);
  const { isMaxWidth, imageRef } = useImageSize();

  const setDocumentDimensions = () => {
    const ref = imageRef.current ? imageRef : documentRef;
    if (ref?.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      props.setDimensions({ width, height });
    }
  };

  const onDocumentLoad = () => {
    setRenderHighlight(true);
    setDocumentDimensions();
  };

  useEffect(() => {
    setDocumentType(
      extension(Array.isArray(props.url) ? props.url[0] : props.url)
    );
    if (renderHighlight) setRenderHighlight(false);

    window.addEventListener("resize", setDocumentDimensions);

    return () => {
      window.removeEventListener("resize", setDocumentDimensions);
    };
  }, [props.url]);

  const renderHighlightedFields = () => {
    return (
      <React.Fragment>
        {props.fields.map(field => {
          return (
            <FieldHighlight
              key={field.id}
              field={field}
              dimensions={props.dimensions}
              selectedField={props.selectedField}
              setSelectedField={props.setSelectedField}
              maxWidth={documentMaxWidth}
            />
          );
        })}
      </React.Fragment>
    );
  };

  const renderImage = () => {
    let urls = props.url;
    if (!Array.isArray(props.url)) {
      urls = [props.url];
    }
    const toReturn = urls.map((url, index) => {
      return (
        <React.Fragment key={index}>
          <ImageViewer
            show={index === props.page - 1}
            reference={index === props.page - 1 ? imageRef : null}
            url={url}
            scale={props.scale}
            onImageLoad={onDocumentLoad}
            setDocumentDimensions={setDocumentDimensions}
            setMaxWidth={setMaxWidth}
          />
        </React.Fragment>
      );
    });
    return toReturn;
  };

  const renderFile = () => {
    switch (documentType) {
      case "pdf":
        return (
          <React.Fragment>
            <PDFViewer
              url={props.url}
              documentPage={props.page}
              documentRef={documentRef}
              scale={props.scale}
              onDocumentLoad={onDocumentLoad}
              setDocumentDimensions={setDocumentDimensions}
            />
            {renderHighlight && renderHighlightedFields()}
          </React.Fragment>
        );
      case "png":
      case "jpg":
      case "jpeg":
      case "tiff":
      case "xps":
        return (
          <React.Fragment>
            {renderImage()}
            {renderHighlight && renderHighlightedFields()}
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Toolbar
        page={props.page}
        scale={props.scale}
        numPages={props.pages}
        setPage={props.setPage}
        setScale={props.scaleDocument}
        maxImageSize={isMaxWidth}
      />
      <div className="canvasBackground" id="canvasBackground">
        <div className="canvasContainer" id="canvasContainer">
          {renderFile()}
          {props.renderFieldInput()}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentViewer;
