import React from "react";
import i18n from "../i18n";

import CompanyRow from "./CompanyRow";

class CompaniesTable extends React.Component {
  renderEmpty = () => {
    return (
      <div className="noContent" style={{ padding: "40px" }}>
        <h4>{i18n.t("NoCompaniesYet")}</h4>
      </div>
    );
  };

  renderLoading = () => {
    return (
      <div className="ui" style={{ minHeight: "100px" }}>
        <div className="ui active inverted dimmer">
          <div className="ui text loader">{i18n.t("Loading")}</div>
        </div>
      </div>
    );
  };

  renderTable = () => {
    return (
      <div className="tableContainer">
        <table className="ui table unstackable">
          <thead>
            <tr>
              <th>{i18n.t("CompanyName")}</th>
              <th>{i18n.t("Description")}</th>
              <th>{i18n.t("Email")}</th>
              <th aria-label="actions" />
            </tr>
          </thead>
          <tbody>
            {this.props.companies.map(company => (
              <CompanyRow
                key={company.id}
                company={company}
                showModal={this.props.showModal}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    if (this.props.companies === undefined) {
      return this.renderLoading();
    }
    if (this.props.companies.length > 0) {
      return this.renderTable();
    }
    return this.renderEmpty();
  }
}

export default CompaniesTable;
