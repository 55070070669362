import React from "react";
import { isEqual } from "lodash";
import { Link } from "react-router-dom";
import { isDesktop } from "../../services/general";

class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);

    this.activeSectionRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  componentDidUpdate() {
    this.resizeBreadcrumb();
  }

  resizeBreadcrumb = () => {
    const activeSectionElement = this.activeSectionRef.current;
    if (
      activeSectionElement?.clientHeight > 24 &&
      !activeSectionElement.parentElement.className.includes("mobile-crumb")
    ) {
      activeSectionElement.parentElement.className += " mobile-crumb";
    } else if (activeSectionElement) {
      activeSectionElement.parentElement.className = "ui breadcrumb";
    }
  };

  _renderEllipsis = () => {
    return this.props.breadcrumb.length > 1;
  };

  active(index, length) {
    return length - 1 === index;
  }

  dividedBreadcrumb() {
    const breadcrumb = [...this.props.breadcrumb];

    for (let i = this.props.breadcrumb.length - 1; i > 0; i -= 1) {
      breadcrumb.splice(i, 0, null);
    }

    return breadcrumb;
  }

  renderDivider(index) {
    return <i key={index} className="right angle icon divider" />;
  }

  renderSection(index, crumb) {
    return (
      <Link key={index} className="section" to={crumb.location}>
        {isDesktop() ? crumb.title : "..."}
      </Link>
    );
  }

  renderActiveSection(index, crumb) {
    return (
      <div ref={this.activeSectionRef} key={index} className="section active">
        {crumb.title}
      </div>
    );
  }

  renderDesktopBreadcrumbs = breadcrumb => {
    return (
      <div className="ui breadcrumb">
        {breadcrumb.map((crumb, index) => {
          if (crumb) {
            return this.active(index, breadcrumb.length)
              ? this.renderActiveSection(index, crumb)
              : this.renderSection(index, crumb);
          }
          return this.renderDivider(index);
        })}
      </div>
    );
  };

  renderMobileBreadcrumbs = breadcrumb => {
    return (
      <div className="ui breadcrumb">
        {this._renderEllipsis()
          ? this.renderSection(
              breadcrumb.length - 3,
              breadcrumb[breadcrumb.length - 3]
            )
          : null}
        {this._renderEllipsis()
          ? this.renderDivider(breadcrumb.length - 2)
          : null}
        {this.renderActiveSection(
          breadcrumb.length - 1,
          breadcrumb[breadcrumb.length - 1]
        )}
      </div>
    );
  };

  render() {
    if (this.props.breadcrumb) {
      const breadcrumb = this.dividedBreadcrumb();

      if (isDesktop()) {
        return this.renderDesktopBreadcrumbs(breadcrumb);
      }

      return this.renderMobileBreadcrumbs(breadcrumb);
    }
    return <div />;
  }
}

export default Breadcrumb;
