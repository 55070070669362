import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { isEmpty } from "lodash";

const InputDropdown = props => {
  const { options, value } = props;
  const [dropdownOptions, setDropdownOptions] = useState(options);

  useEffect(() => {
    if (value && options.length === 0) {
      const valuesArray = options.map(o => o.value);
      if (!valuesArray.includes(value)) {
        const auxOption = {
          value,
          key: value,
          text: value
        };
        setDropdownOptions([auxOption]);
      }
    } else {
      setDropdownOptions(options);
    }
  }, [value, options]);

  const showErrors = _ => {
    // return (typeof(props.value) === 'String') && isEmpty(props.value) && props.error
    return isEmpty(props.value) && props.error;
  };
  return (
    <div style={props?.containerStyle}>
      <div className="inputField-label">
        {props.inputlabel}{props.required && '*'}
      </div>
      <Dropdown
        {...props}
        options={dropdownOptions}
        error={showErrors()}
      />
      {showErrors() && <div className='dropdown-error'>{props.error}</div>}
    </div>
  );
};

InputDropdown.defaultProps = {
  inputlabel: '',
  disabled: false,
  placeholder: '',
  fluid: true,
  required: false,
  value: ""
};

export default InputDropdown;
