/* eslint-disable react/sort-comp */
import React from "react";
import { connect } from "react-redux";
import { debounce, isEqual, isEmpty } from "lodash";
import moment from "moment-timezone";
import i18n from "../../i18n";
import QueueService from "../../../services/data_services/queue";
import {
  formatStatusToIcon,
  QUEUEACTIVITYSTATUS
} from "../../../constants/status";
import {
  getText as priorityName,
  PRIORITY_OPTIONS
} from "../../../constants/priority";
import { capitalizeFirst, activityDate } from "../../../services/string";
import Table from "../../Ui/Table/Table";
import { TYPES } from "../../Ui/Table/types";
import QueueInformation from "./QueueInformation";

const BACKEND_DATE_FORMAT = "DD-MM-YYYY HH:mm";

class EventActivitiesTable extends React.Component {
  constructor(props) {
    super(props);

    this.defaultStartDate = moment
      .tz(props.user.timezone)
      .startOf("day")
      .utc()
      .format(BACKEND_DATE_FORMAT);

    this.defaultEndDate = moment
      .tz(props.user.timezone)
      .endOf("day")
      .utc()
      .format(BACKEND_DATE_FORMAT);

    this.state = {
      activities: undefined,
      columns: [
        { name: i18n.t("Key"), show: true, key: "identifier_key" },
        {
          name: i18n.t("Priority"),
          show: true,
          key: "priority",
          method: priorityName,
          type: "select",
          options: PRIORITY_OPTIONS,
          single: true
        },
        {
          name: i18n.t("Status"),
          show: true,
          key: "status",
          method: formatStatusToIcon,
          type: "select",
          options: QUEUEACTIVITYSTATUS.map(status => {
            return {
              label: capitalizeFirst(status.value),
              value: status.value
            };
          })
        },
        {
          name: i18n.t("Deferred"),
          show: true,
          key: "deferred",
          type: "datetime",
          method: activityDate
        },
        {
          name: i18n.t("CreatedAt"),
          show: true,
          key: "created_at",
          type: "datetime",
          method: activityDate
        },
        {
          name: i18n.t("UpdatedAt"),
          show: true,
          key: "updated_at",
          type: "datetime",
          method: activityDate
        },
        {
          name: i18n.t("ExceptionDate"),
          show: true,
          key: "exception_date",
          type: "datetime",
          method: activityDate
        },
        {
          name: i18n.t("Tag"),
          show: true,
          key: "tag"
        },
        {
          name: i18n.t("WorkTime"),
          show: true,
          key: "worktime"
        }
      ],
      nPages: 0,
      queryParams: {
        page: 1,
        date_from: this.defaultStartDate,
        date_to: this.defaultEndDate
      },
      loadingQueueItems: true,
      totalItems: null,
      averageDuration: null,
      totalDuration: null
    };
  }

  componentDidMount() {
    this.loadInformation();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.loadActivities();
    }

    if (
      this.props.queue !== prevProps.queue ||
      this.props.serviceId !== prevProps.serviceId
    ) {
      this.onQueueChange();
    }
  }

  setQueryParams = (key, value) => {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  };

  reloadActivities = () => {
    this.setState(
      {
        activities: null,
        nPages: 0
      },
      this.loadInformation
    );
  };

  onQueueChange = () => {
    this.setState(
      {
        activities: null,
        nPages: 0,
        averageDuration: null,
        totalDuration: null,
        queryParams: {
          page: 1,
          date_from: this.defaultStartDate,
          date_to: this.defaultEndDate
        }
      },
      this.loadInformation
    );
  };

  loadInformation = () => {
    this.loadActivities();
    this.loadExtraParameters();
  };

  loadActivities = debounce(() => {
    this.setState({ loadingQueueItems: true });
    const query = {
      ...this.state.queryParams,
      queue: this.props.queue.name,
      service_id: this.props.serviceId
    };
    QueueService.loadActivitiesFromQueue(query)
      .then(resp =>
        resp.status === 204 || isEmpty(resp.data)
          ? { results: [], total: 0 }
          : resp.data
      )
      .then(data => {
        this.setState({
          activities: data.results,
          nPages: data.total,
          totalItems: data.hits
        });
      })
      .catch(() => {
        this.setState({
          activities: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingQueueItems: false });
      });
  }, 500);

  loadExtraParameters = () => {
    const query = {
      queue: this.props.queue.name,
      service_id: this.props.serviceId
    };
    QueueService.loadQueueExtraParameters(query)
      .then(response => {
        if (Array.isArray(response.data)) {
          const { average, totalTime } = response.data[0];
          this.setState({ averageDuration: average, totalDuration: totalTime });
        }
      })
      .catch(() => {});
  };

  loadItems(entries) {
    if (!entries) {
      this.loadActivities();
    } else {
      Object.keys(entries).forEach(key => {
        this.setQueryParams(key, entries[key]);
      });
    }
  }

  renderQueueInformation = () => (
    <QueueInformation
      averageDuration={this.state.averageDuration}
      totalDuration={this.state.totalDuration}
    />
  );

  render() {
    return (
      <Table
        tableId={TYPES.ACTIVITIES}
        columns={this.state.columns}
        items={this.state.activities}
        noItemsKey="NoActivityHistory"
        actions={[]}
        loadItems={entries => {
          this.loadItems(entries);
        }}
        mobileColumns={{ name: "identifier_key" }}
        customSearch={this.renderQueueInformation}
        loadingItems={this.state.loadingQueueItems}
        filters={this.state.queryParams}
        nPages={this.state.nPages}
        page={this.state.queryParams ? this.state.queryParams.page : 1}
        reloadAction={this.reloadActivities}
        totalItems={this.state.totalItems}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(EventActivitiesTable);
