import React from "react";
import { debounce, isEqual } from "lodash";
import Cookies from "js-cookie";
import i18n from "../../../i18n";
import Permissions from "../../../../services/permissions";
import {
  Actions,
  VisitableComponents
} from "../../../../services/detailedPermissionRules";
import Table from "../../../Ui/Table/Table";
import { TYPES } from "../../../Ui/Table/types";
import { tableActions } from "../../../Ui/Table/TableActions";
import TeamService from "../../../../services/data_services/team";
import { applicationNames } from "../../../../services/string";
import ApplicationService from "../../../../services/data_services/application";
import {
  searchParamFromUrl,
  urlFromSearchParam
} from "../../../../services/url";
import UserService from "../../../../services/data_services/user";

const permissionsOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];

class TeamsTable extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = searchParamFromUrl(this.props.location.search);

    this.state = {
      teams: undefined,
      columns: [
        { name: "Title", show: true, key: "name" },
        {
          name: "Description",
          show: true,
          key: "description"
        },
        {
          name: "Applications in launcher",
          show: true,
          key: "launcher_applications",
          method: applicationNames,
          searchable: false
        },
        {
          name: "Applications with individual item",
          show: true,
          key: "individual_item_applications",
          method: applicationNames,
          searchable: false
        },
        {
          name: "AllowEdit",
          show: true,
          key: "allow_edit",
          component: "Tick",
          type: "select",
          single: true,
          options: permissionsOptions
        },
        {
          name: "AllowDelete",
          show: true,
          key: "allow_delete",
          component: "Tick",
          type: "select",
          single: true,
          options: permissionsOptions
        }
      ],
      queryParams,
      nPages: 0,
      loadingTeams: true
    };
  }

  componentDidMount() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Teams"),
        location: "/admin/teams"
      }
    ]);
    this.loadTeams();
    this.loadSidebarApplications();
    this.loginUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.props.history.push(`?${urlFromSearchParam(this.state.queryParams)}`);
      this.loadTeams();
    }
  }

  setQueryParams = (key, value) => {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  };

  loadTeams = debounce(() => {
    this.setState({ loadingTeams: true });
    const query = { ...this.state.queryParams };

    TeamService.loadTeams(query)
      .then(result => {
        this.setState({
          teams: result.data.results,
          nPages: result.data.total,
          totalItems: result.data.hits
        });
      })
      .catch(() => {
        this.setState({
          teams: [],
          nPages: 0
        });
      })
      .finally(() => {
        this.setState({ loadingTeams: false });
      });
  }, 500);

  loadSidebarApplications = () => {
    ApplicationService.loadIndividualItemApplications().then(result => {
      this.props.loadIndividualApplications(result.data);
    });
    ApplicationService.loadLaunchApplications({ page: 0 }).then(result => {
      this.props.loadLaunchApplications(result.data.results);
    });
  };

  loginUser = () => {
    const userID = parseInt(Cookies.get("ID"), 10);

    !Number.isNaN(userID) &&
      UserService.loadUser(userID).then(response => {
        this.props.logIn(response.data);
      });
  };

  loadItems(entries) {
    if (!entries) {
      this.loadTeams();
    } else {
      Object.keys(entries).forEach(key => {
        this.setQueryParams(key, entries[key]);
      });
    }
  }

  reloadTeams = () => {
    this.setState({ teams: null, nPages: 0 }, this.loadTeams());
  };

  render() {
    return (
      <Permissions
        component={VisitableComponents.Teams}
        action={Actions.read}
        yes={() => {
          return (
            <Table
              totalItems={this.state.totalItems}
              tableId={TYPES.TEAMS}
              columns={this.state.columns}
              items={this.state.teams}
              noItemsKey="NoTeamsYet"
              actions={[
                tableActions.team.clone,
                tableActions.team.edit,
                tableActions.team.delete
              ]}
              loadItems={entries => {
                this.loadItems(entries);
              }}
              loadingItems={this.state.loadingTeams}
              filters={this.state.queryParams}
              user={this.props.user}
              nPages={this.state.nPages}
              page={this.state.queryParams ? this.state.queryParams.page : 1}
              component={VisitableComponents.Teams}
              createAction={() => this.props.history.push("teams/new")}
              reloadAction={this.reloadTeams}
            />
          );
        }}
        no={() => {
          return null;
        }}
      />
    );
  }
}

export default TeamsTable;
