import React from "react";
import { isEqual, isEmpty } from "lodash";
import ApplicationService from "../../services/data_services/application";
import ActivitiesService from "../../services/data_services/activities";
import RequestDetail from "./OCR/RequestDetail";
import NCWDetail from "./NCW/NCWDetail";
import ActivityRPA from "./ActivityRPA";
import { displayFileName } from "../../services/string";
import { APPLICATION_TYPES } from "../../constants/ApplicationTypes";
import Upperbar from "../Ui/Upperbar/Upperbar";
import { isDesktop } from "../../services/general";

const PROCESSING_STATUS = 10;
const DRAFT_STATUS = 13;

class ActivityShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      application: undefined,
      activity: undefined,
      shownBar: false,
      activityList: [],
      activityListQueryParams: {},
      loadingNavigation: false
    };

    this.loadActivityData = this.loadActivityData.bind(this);
  }

  componentDidMount() {
    this.setLoadingBar();
    this.loadApplicationTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.loadActivityData(false);
    }
  }

  setBreadcrumb = () => {
    this.props.setBreadcrumb([
      {
        title: this.state.application.name,
        location: `/applications/${this.props.applicationId}`
      },
      {
        title: this.activityTitle(),
        location: `/activities/${this.activityId()}`
      }
    ]);
  };

  readFilters = () => {
    const stringifiedFilters = window.sessionStorage.getItem("filters");
    if (!stringifiedFilters) return {};
    return JSON.parse(stringifiedFilters);
  };

  setFilters = () => {
    const filters = this.readFilters();
    const currentApplicationFilters = filters[this.state.application.uuid];
    if (currentApplicationFilters && !isEmpty(currentApplicationFilters)) {
      this.setState(prevState => {
        const auxQueryParams = {
          ...prevState.activityListQueryParams,
          ...currentApplicationFilters
        };
        return { activityListQueryParams: auxQueryParams };
      }, this.loadActivities);
    } else {
      this.loadActivities();
    }
  };

  setLoadingBar = () => {
    if (this.props.loadingBar !== undefined && !this.state.shownBar) {
      this.props.loadingBar.continousStart(5);
      this.setState({
        shownBar: true
      });
    }
  };

  loadApplicationTypes = () => {
    ApplicationService.getApplicationTypes()
      .then(response => {
        this.setState(
          { applicationTypes: response.data },
          this.loadApplication()
        );
      })
      .catch(() => this.setState({ applicationTypes: [] }));
  };

  loadActivities = () => {
    this.setState({ loadingNavigation: true }, () => {
      if (
        this.state.application.applicationType === APPLICATION_TYPES.UNATTENDED
      ) {
        this.loadExternalActivities();
      } else {
        this.loadInternalActivities();
      }
    });
  };

  loadExternalActivities = () => {
    const serviceId = this.state.application.process_entities[0].service_id;
    const query = {
      ...this.state.activityListQueryParams,
      service_id: serviceId,
      queue: this.state.application.process_entities[0].rpa_queues[0]
    };

    const { page, ...auxQuery } = query;

    ActivitiesService.loadExternalActivities(auxQuery)
      .then(response => {
        this.saveActivityList(response.data.results);
      })
      .finally(() => this.setState({ loadingNavigation: false }));
  };

  loadInternalActivities = () => {
    if (this.state.application) {
      const query = {
        ...this.state.activityListQueryParams,
        application_uuids: [this.state.application.uuid],
        page: 0
      };

      ActivitiesService.searchActivities(query)
        .then(response => {
          this.saveActivityList(response.data.results);
        })
        .finally(() => this.setState({ loadingNavigation: false }));
    }
  };

  loadApplication = () => {
    if (this.props.applicationId) {
      ApplicationService.loadApplication(this.props.applicationId).then(
        response => {
          this.setState(
            {
              application: {
                ...response.data,
                applicationType: this.mapTypeIdToApplicationType(
                  response.data.type_id
                )
              }
            },
            () => {
              this.loadActivityData();
              this.setFilters();
            }
          );
        }
      );
    }
  };

  mapTypeIdToApplicationType = typeId => {
    try {
      const applicationType = this.state.applicationTypes.find(
        appType => appType.id === typeId
      );
      return applicationType.name;
    } catch {
      return APPLICATION_TYPES.CASE_VIEW;
    }
  };

  loadActivityData = (fromInit = true) => {
    if (
      this.state.application.applicationType === APPLICATION_TYPES.UNATTENDED
    ) {
      this.loadExternalActivityData(fromInit);
    } else {
      this.loadInternalActivityData(fromInit);
    }
  };

  loadExternalActivityData = (fromInit = true, activityUuid = null) => {
    const { uuid, attempt } = this.props;
    const serviceId = this.state.application.process_entities[0].service_id;
    const auxUuid = activityUuid ?? uuid;
    const query = {
      ...this.state.queryParams,
      service_id: serviceId,
      uuid: auxUuid,
      attempt
    };
    const { page, ...auxQuery } = query;
    ActivitiesService.loadExternalActivity(auxUuid, auxQuery)
      .then(activityResponse => {
        this.setState(
          {
            activity: activityResponse.data
          },
          () => {
            if (fromInit) {
              this.props.loadingBar.complete();
              if (!this.isRPAOrCaseView(this.state.application.applicationType))
                this.setBreadcrumb();
            }
          }
        );
      })
      .catch(() => {});
  };

  loadInternalActivityData = (fromInit = true, id = null) => {
    const activityId = id ?? this.activityId();
    ActivitiesService.loadActivity(activityId)
      .then(activityResponse => {
        this.setState(
          {
            activity: activityResponse.data
          },
          () => {
            if (fromInit) {
              this.props.loadingBar.complete();
              if (!this.isRPAOrCaseView(this.state.application.applicationType))
                this.setBreadcrumb();
            }
          }
        );
      })
      .catch(() => {});
  };

  isRPAOrCaseView = applicationType =>
    [
      APPLICATION_TYPES.UNATTENDED,
      APPLICATION_TYPES.HUMAN_IN_THE_LOOP,
      APPLICATION_TYPES.CASE_VIEW
    ].includes(applicationType);

  activityId = () => {
    return this.props.match.params.id;
  };

  activityTitle = () => {
    if (!this.state.activity) return null;
    return displayFileName(this.state.activity.identifier_key);
  };

  renderActivity() {
    switch (this.state.application.applicationType) {
      case APPLICATION_TYPES.NCW:
        return (
          <NCWDetail
            application={this.state.application}
            activity={this.state.activity}
            reloadActivity={() => {
              this.loadActivityData();
            }}
          />
        );
      case APPLICATION_TYPES.HYPERSCIENCE:
        return (
          <RequestDetail
            activity={this.state.activity}
            setQueryParams={this.setQueryParams}
          />
        );
      case APPLICATION_TYPES.UNATTENDED:
      case APPLICATION_TYPES.HUMAN_IN_THE_LOOP:
      case APPLICATION_TYPES.CASE_VIEW:
      default:
        return this.renderRPAActivity();
    }
  }

  renderRPAActivity() {
    return (
      <ActivityRPA
        activity={this.state.activity}
        activityTitle={this.activityTitle}
        application={this.state.application}
        loadActivityData={this.loadActivityData}
        setBreadcrumb={this.props.setBreadcrumb}
        {...this.props}
      />
    );
  }

  saveActivityList = activities => {
    if (Array.isArray(activities) && activities.length > 0) {
      const activityList = activities
        .filter(
          activity =>
            ![PROCESSING_STATUS, DRAFT_STATUS].includes(activity.status)
        )
        .map(activity => ({
          id: activity.id,
          name: activity.identifier_key
        }));
      this.setState({ activityList });
    }
  };

  returnToApplication = () => {
    this.props.history.push(`/applications/${this.state.application.id}`);
  };

  findCloseActivities = () => {
    const { activityList } = this.state;
    if (!activityList || activityList.length === 0 || !this.state.activity) {
      return {};
    }

    const activityId =
      this.state.application.applicationType === APPLICATION_TYPES.UNATTENDED
        ? this.state.activity.id
        : parseInt(this.props.match.params.id, 0);

    const currentActivityIndex = activityList.findIndex(
      activity => activity.id === activityId
    );
    const prevActivity =
      currentActivityIndex > 0 ? activityList[currentActivityIndex - 1] : null;
    const nextActivity =
      currentActivityIndex < activityList.length - 1
        ? activityList[currentActivityIndex + 1]
        : null;

    return { prevActivity, nextActivity };
  };

  goToActivity = activityId => {
    if (
      this.state.application.applicationType === APPLICATION_TYPES.UNATTENDED
    ) {
      this.loadExternalActivityData(true, activityId);
    } else {
      this.props.history.push(
        `/applications/${this.state.application.id}/activities/${activityId}`
      );
      this.loadInternalActivityData(true, activityId);
    }
  };

  render() {
    const { prevActivity, nextActivity } = this.findCloseActivities();
    const backgroundClass = this.isRPAOrCaseView(
      this.state.application?.applicationType
    )
      ? ""
      : "no-bg";
    return (
      <div className="component-wrapper">
        <div className={`noMargin-container ${backgroundClass}`}>
          {isDesktop() && (
            <Upperbar
              title={this.activityTitle()}
              onCancel={this.returnToApplication}
              prevItem={prevActivity}
              onPrevClick={() => this.goToActivity(prevActivity.id)}
              nextItem={nextActivity}
              onNextClick={() => this.goToActivity(nextActivity.id)}
              loadingNavigation={this.state.loadingNavigation}
            />
          )}
          {this.state.application &&
            this.state.activity &&
            this.renderActivity()}
        </div>
      </div>
    );
  }
}

export default ActivityShow;
