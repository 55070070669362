import React from "react";
import { Table } from "semantic-ui-react";
import { cloneDeep } from "lodash";

export default function TableItemsEdit({ dataElement, setFieldValue, values }) {
  if (!values[dataElement.name]) {
    return "";
  }

  const editedCollection = (rowIndex, cell, collection, value) => {
    const tempCollection = cloneDeep(collection);
    tempCollection[rowIndex][cell] = value;
    const JSONString = JSON.stringify(tempCollection);
    return JSONString;
  };

  const addNewRow = (rowIndex, collection) => {
    const auxCollection = cloneDeep(collection);
    const newRow = {};
    Object.keys(collection[0]).forEach(key => {
      newRow[key] = "";
    });
    auxCollection.splice(rowIndex, 0, newRow);
    const stringifiedCollection = JSON.stringify(auxCollection);
    setFieldValue(dataElement.name, stringifiedCollection);
  };

  const removeRow = (rowIndex, collection) => {
    const collectionWithRowRemoved = collection.filter((_, index) => {
      return index !== rowIndex;
    });
    const stringifiedCollection = JSON.stringify(collectionWithRowRemoved);
    setFieldValue(dataElement.name, stringifiedCollection);
  };

  const collection = JSON.parse(values[dataElement.name]);
  return (
    <div className="field" key={dataElement.name}>
      <label>{dataElement.label}</label>
      <Table>
        <Table.Header>
          <Table.Row>
            {Object.keys(collection[0]).map((col, index) => {
              const key = `header_${index}_${col}`;
              return <Table.HeaderCell key={key}>{col}</Table.HeaderCell>;
            })}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {collection.map((row, rowIndex) => {
            const trKey = `row_${rowIndex}`;
            return (
              <Table.Row key={trKey}>
                {Object.keys(row).map((cell, index) => {
                  const tdKey = `td_${rowIndex}_${index}`;
                  return (
                    <Table.Cell key={tdKey}>
                      <input
                        onChange={e => {
                          setFieldValue(
                            dataElement.name,
                            editedCollection(
                              rowIndex,
                              cell,
                              collection,
                              e.target.value
                            ),
                            false
                          );
                        }}
                        value={row[cell]}
                      />
                    </Table.Cell>
                  );
                })}
                <Table.Cell>
                  <div className="d-flex-start">
                    <button
                      type="button"
                      onClick={() => {
                        addNewRow(
                          rowIndex + 1,
                          collection,
                          setFieldValue,
                          dataElement
                        );
                      }}
                      className="ui mini button positive"
                    >
                      <i
                        className="fa fa-plus-circle"
                        style={{ color: "white" }}
                      />
                    </button>

                    {collection.length > 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          removeRow(
                            rowIndex,
                            collection,
                            setFieldValue,
                            dataElement
                          );
                        }}
                        className="ui mini button negative"
                      >
                        <i
                          className="fa fa-minus-circle"
                          style={{ color: "white" }}
                        />
                      </button>
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
