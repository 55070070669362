/* eslint-disable react/sort-comp */
import React from "react";
import { Grid } from "semantic-ui-react";

import Section from "./Section";
import Sorter from "./Sorter";

import DashboardService from "../../services/data_services/dashboard";
import CompanySettingsService from "../../services/data_services/companySettings";
import UserSettingsService from "../../services/data_services/userSettings";

import i18n from "../i18n";
import { generateUuidFrom } from "../../services/uuid";
import { sortBy } from "lodash";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: [],
      sectionsLoaded: false,
      companySectionsOrder: [],
      companySettingsLoaded: false,
      userSectionsOrder: [],
      userSettingsLoaded: false,
      sectionsSorted: false,
      userSectionsOrderSettingExists: false,
      companyWidgetsOrderBySectionOrder: [],
      userWidgetsOrderBySectionOrder: [],
      userWidgetsOrderBySectionSettingExists: false
    };

    this.sectionWidgets = {};

    this.dashboardSectionsUuid = generateUuidFrom("DASHBOARD-SECTIONS");
    this.settingsWidgetsOrderBySectionUuid = generateUuidFrom("WIDGETS-ORDER-BY-SECTION");
  }

  componentDidMount() {
    this.props.setBreadcrumb([{ title: i18n.t("Dashboard"), location: "/" }]);
    this.loadSections();
    this.loadCompanySettings();
    this.loadUserSettings();
  }

  componentDidUpdate() {
    if (
      !this.state.sectionsSorted &&
      this.state.sectionsLoaded &&
      this.state.companySettingsLoaded &&
      this.state.userSettingsLoaded
    ) {
      this.sortSections();
    }
  }

  loadSections() {
    DashboardService.sectionsByUser()
      .then(response => {
        if (response.data) {
          this.setState({
            sections: response.data
          });
        } else {
          this.setState({
            sections: []
          });
        }
      })
      .catch(() => {
        this.setState({
          sections: []
        });
      })
      .finally(() => {
        this.setState({ sectionsLoaded: true });
      });
  }

  loadCompanySettings() {
    CompanySettingsService.loadCompanySettings(
      this.props.user.company_id,
      this.dashboardSectionsUuid
    )
      .then(response => {
        this.setState({
          companySectionsOrder: response.data.columns
        });
      })
      .catch(e => console.log(e))
      .finally(() => this.setState({ companySettingsLoaded: true }));

    CompanySettingsService.loadCompanySettings(
      this.props.user.company_id,
      this.settingsWidgetsOrderBySectionUuid
    )
      .then(response => {
        this.setState({
          companyWidgetsOrderBySectionOrder: response.data.columns
        });
      })
      .catch(e => console.log(e));
  }

  loadUserSettings() {
    UserSettingsService.loadUsersSettings(
      this.props.user.id,
      this.dashboardSectionsUuid
    )
      .then(response => {
        this.setState({
          userSectionsOrder: response.data.columns,
          userSectionsOrderSettingExists: true
        });
      })
      .catch(e => console.log(e))
      .finally(() => this.setState({ userSettingsLoaded: true }));

    UserSettingsService.loadUsersSettings(
      this.props.user.id,
      this.settingsWidgetsOrderBySectionUuid
    )
      .then(response => {
        this.setState({
          userWidgetsOrderBySectionOrder: response.data.columns,
          userWidgetsOrderBySectionSettingExists: true
        });
      })
      .catch(e => console.log(e));
  }

  sortSections = () => {
    let sections = sortBy(this.state.sections, section => {
      return this.state.companySectionsOrder.indexOf(`${section.id}`);
    });
    sections = sortBy(sections, section => {
      return this.state.userSectionsOrder.indexOf(`${section.id}`);
    });
    this.setState({ sections, sectionsSorted: true });
  };

  saveUserSectionsOrderSetting() {
    const saveFunction = this.state.userSectionsOrderSettingExists
      ? UserSettingsService.updateUserSettings
      : UserSettingsService.createUserSettings;

    const sectionIndexes = this.state.sections.map(section => section.id.toString());

    saveFunction(this.props.user.id, this.dashboardSectionsUuid, sectionIndexes).then(
      result => {
        this.setState({
          userSectionsOrder: result.data.columns,
          userSectionsOrderSettingExists: true
        });
      }
    ).catch(e => console.log(e));
  };

  changeSectionsOrder = sections => {
    this.setState({ sections }, this.saveUserSectionsOrderSetting);
  };

  setSectionWidgets = (sectionId, widgets, save = false) => {
    this.sectionWidgets[sectionId] = widgets;
    this.saveUserSectionsWiggetsOrderSetting(sectionId, widgets);
  };

  saveUserSectionsWiggetsOrderSetting(sectionId, widgets) {
    const saveFunction = this.state.userWidgetsOrderBySectionSettingExists
      ? UserSettingsService.updateUserSettings
      : UserSettingsService.createUserSettings;

    // const widgetIndexes = this.state.sections.reduce((widgets, section) => {
    //   this.sectionWidgets[section.id].map(widget => widgets.push(widget.id.toString()));
    //   return widgets
    // }, []);

    const widgetIndexes = widgets.map(widget => widget.id.toString() )

    saveFunction(this.props.user.id, this.settingsWidgetsOrderBySectionUuid, widgetIndexes).then(
      () => {
        this.setState({
          userWidgetsOrderBySectionSettingExists: true
        });
      }
    ).catch(e => console.log(e));
  };

  render() {
    return (
      <>
      <div style={{padding: '0 24px 24px'}} >
        <div style={{display: 'flex', justifyContent: 'flex-end'}} >
            <Sorter
              sections={this.state.sections}
              setNewOrder={this.changeSectionsOrder}
            />
        </div>

        {this.state.sectionsSorted &&
          this.state.sections.map((section, index) => (
            <Section
              key={section.id}
              section={section}
              setSectionWidgets={this.setSectionWidgets}
              companyWidgetsOrder={this.state.companyWidgetsOrderBySectionOrder}
              userWidgetsOrder={this.state.userWidgetsOrderBySectionOrder}
              index={index}
            />
          ))}
        </div>
      </>
    );
  }
}

export default Dashboard;
