import React from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { loadColumnOrder } from "../../../actions/index";
import Pagination from "../../Pagination";
import TableModal from "./TableModal";
import { generateUuidFrom } from "../../../services/uuid";
import UserSettingsService from "../../../services/data_services/userSettings";
import WithLoadingComponent from "../WithLoadingComponent";
import { isDesktop } from "../../../services/general";
import EmptyTable from "./EmptyTable";
import MobileTable from "./Mobile/MobileTable";

import "../../../../assets/stylesheets/UI/Table.scss";
import "../../../../assets/stylesheets/UI/MobileTable.scss";
import PerPageSelector from "./PerPageSelector";
import DesktopTable from "./Desktop/DesktopTable";
import CurrentPageInfo from "./CurrentPageInfo";

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.tableUuid =
      this.props.tableUuid || generateUuidFrom(this.props.tableId);

    this.state = {
      item: undefined,
      tableAction: undefined,
      openFilter: false,
      modalOpen: false,
      columns: this.loadColumnOrder(props.columns),
      editField: undefined,
      editedRows: {},
      editItem: undefined,
      editingRow: {},
      loading: false
    };
  }

  componentDidMount() {
    this.loadUserSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.state.columns, prevState.columns)) {
      this.reorderItems();
    }

    if (prevProps.editing && !this.props.editing) {
      this.toggleEditRow(undefined);
    }
  }

  loadColumnOrder = columns => {
    const { columnOrder } = this.props;
    const getColumnOrder = column => {
      if (
        columnOrder.tableId === this.tableUuid &&
        columnOrder.column === column.key
      ) {
        return { ...column, order: columnOrder.order };
      }
      return { ...column, order: "" };
    };
    if (this.props.columnOrder) {
      return columns.map(column => getColumnOrder(column));
    }
    return columns.map(column => {
      return { ...column, order: "" };
    });
  };

  loadUserSettings = () => {
    const userId = this.props.user.id;

    UserSettingsService.loadUsersSettings(userId, this.tableUuid)
      .then(({ data }) => {
        this.setState(
          {
            settingExist: true
          },
          () => {
            if (data.column_settings?.length > 0) {
              this.applyUserSettings(data.column_settings, false);
            } else {
              this.setUserSettings(data.columns, false);
            }
          }
        );
      })
      .catch(() => {
        const propsColumnsToShow = this.props.columns
          .filter(column => column.show)
          .map(column => column.key);

        // Do not save settings when we just load them.
        this.setUserSettings(propsColumnsToShow, false);
      });
  };

  /**
   *
   * @param columnKeys
   * @param saveUserSettings Indicates whether changes will be saved or omitted
   */
  setUserSettings = (columnKeys, saveUserSettings = true) => {
    const visibleStateColumns = this.state.columns.filter(column =>
      columnKeys.includes(column.key)
    );

    const sortedResults = visibleStateColumns.sort((col1, col2) => {
      const col1Index = columnKeys.findIndex(key => key === col1.key);
      const col2Index = columnKeys.findIndex(key => key === col2.key);
      if (col1Index > col2Index) return 1;
      if (col2Index > col1Index) return -1;
      return 0;
    });

    const notVisibleColumns = this.state.columns
      .filter(column => !columnKeys.includes(column.key))
      .map(column => {
        return {
          ...column,
          show: false
        };
      });

    const sortedColumns = [...sortedResults, ...notVisibleColumns];

    this.setColumns(sortedColumns, saveUserSettings);
  };

  applyUserSettings = (columnSettings, saveUserSettings) => {
    const visibleColumnsKeys = columnSettings.map(column => column.key);
    const visibleStateColumns = this.state.columns.filter(column =>
      visibleColumnsKeys.includes(column.key)
    );
    const visibleColumnsWithWidth = visibleStateColumns.map(column => {
      const selectedColumnIndex = visibleColumnsKeys.findIndex(
        col => col === column.key
      );
      return {
        ...column,
        show: true,
        width: columnSettings[selectedColumnIndex].width
      };
    });
    const sortedResults = visibleColumnsWithWidth.sort((col1, col2) => {
      const col1Index = visibleColumnsKeys.findIndex(key => key === col1.key);
      const col2Index = visibleColumnsKeys.findIndex(key => key === col2.key);
      if (col1Index > col2Index) return 1;
      if (col2Index > col1Index) return -1;
      return 0;
    });
    const notVisibleColumns = this.state.columns
      .filter(column => !visibleColumnsKeys.includes(column.key))
      .map(column => {
        return {
          ...column,
          show: false
        };
      });

    const sortedColumns = [...sortedResults, ...notVisibleColumns];

    this.setColumns(sortedColumns, saveUserSettings);
  };

  columnsToSent = () => {
    return this.state.columns
      .filter(column => column.show)
      .map(column => column.key);
  };

  columnSettings = () => {
    return this.state.columns
      .filter(column => column.show)
      .map(column => {
        return {
          key: column.key,
          width: column.width
        };
      });
  };

  /**
   *
   * @param columns
   * @param saveUserSettings Indicates whether changes will be saved or omitted
   */
  setColumns = (columns, saveUserSettings = true) => {
    this.setState(
      {
        columns
      },
      () => {
        if (saveUserSettings) {
          if (this.state.settingExist) {
            UserSettingsService.updateUserSettings(
              this.props.user.id,
              this.tableUuid,
              this.columnsToSent(),
              this.columnSettings()
            );
          } else {
            UserSettingsService.createUserSettings(
              this.props.user.id,
              this.tableUuid,
              this.columnsToSent(),
              this.columnSettings()
            ).then(this.setState({ settingExist: true }));
          }
        }
      }
    );
  };

  openModal = (action, item) => {
    this.setState({
      modalOpen: true,
      tableAction: action,
      item
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      item: undefined
    });
  };

  updateColumnOrderState = key => {
    this.setState(prevState => {
      const newColumns = prevState.columns.map(column => {
        const newColumn = { ...column };

        if (column.key === key) {
          if (!column.order || column.order !== "desc") {
            newColumn.order = "desc";
          } else {
            newColumn.order = "asc";
          }
        } else {
          newColumn.order = "";
        }

        return newColumn;
      });

      return { columns: newColumns };
    });
  };

  reorderItems = () => {
    const sortBy = this.state.columns
      .filter(column => column.order)
      .map(column => ({ column: column.key, order: column.order }))[0];
    this.props.loadItems({ sort_by: sortBy });
    if (sortBy) {
      this.props.onColumnOrderChange({
        ...sortBy,
        tableId: this.tableUuid
      });
    }
  };

  toggleOpenFilter = () => {
    this.setState(prevState => ({
      openFilter: !prevState.openFilter
    }));
  };

  toggleEditField = fieldName => {
    this.setState(prevState => ({
      editField: prevState.editField === fieldName ? undefined : fieldName
    }));
  };

  toggleEditRow = item => {
    if (item && !this.state.editItem) {
      this.setState(
        {
          editItem: { ...item },
          editingRow: { ...item }
        },
        this.props.handleEdit(true)
      );
    } else if (!item) {
      this.setState(
        {
          editItem: undefined,
          editingRow: {}
        },
        this.props.handleEdit(false)
      );
    }
  };

  onEditedRowChange = (name, value) => {
    this.setState(prevState => {
      const auxData = { ...prevState.editingRow };
      auxData[name] = value;
      return { editingRow: auxData };
    });
  };

  addEditedRow = (id, value) => {
    this.setState(prevState => {
      const editedRows = { ...prevState.editedRows };

      editedRows[id] = value;

      return {
        editedRows
      };
    });
  };

  clearEditedRow = () => {
    this.setState({
      editedRows: {}
    });
  };

  setLoading = value => {
    this.setState({ loading: value });
  };

  renderEmpty = () => {
    return <EmptyTable noItemsKey={this.props.noItemsKey} />;
  };

  renderDesktopTable = () => {
    const visibleColumnsLength = this.state.columns.filter(
      column => column.show
    ).length;
    return (
      <DesktopTable
        {...this.props}
        columns={this.state.columns}
        visibleColumnsLength={visibleColumnsLength}
        orderBy={this.updateColumnOrderState}
        openFilter={this.state.openFilter}
        toggleOpenFilter={this.toggleOpenFilter}
        setColumns={this.setColumns}
        toggleEditField={this.toggleEditField}
        onEditedRowChange={this.onEditedRowChange}
        addEditedRow={this.addEditedRow}
        onColumnOrderChange={this.onColumnOrderChange}
        toggleEditRow={this.toggleEditRow}
        editField={this.state.editField}
        clearEditedRow={this.clearEditedRow}
        openModal={this.openModal}
        closeModal={this.closeModal}
        editedRows={this.state.editedRows}
        editItem={this.state.editItem}
        editingRow={this.state.editingRow}
      />
    );
  };

  renderMobileTable = () => {
    return (
      <MobileTable
        {...this.props}
        columns={this.state.columns}
        openModal={this.openModal}
        openFilter={this.state.openFilter}
        setColumns={this.setColumns}
        toggleColumns={this.toggleOpenFilter}
      />
    );
  };

  render() {
    return (
      <div className="table-container">
        {isDesktop() ? this.renderDesktopTable() : this.renderMobileTable()}
        <TableModal
          tableAction={this.state.tableAction}
          item={this.state.item}
          loadItems={this.props.loadItems}
          closeModal={this.closeModal}
          open={this.state.modalOpen}
          loading={this.props.loading || this.state.loading}
          setLoading={this.setLoading}
          editing={this.props.editing}
        />
      </div>
    );
  }
}

Table.defaultProps = {
  page: 1,
  nPages: 1
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user,
    columnOrder: state.columnOrderReducer.columnOrder
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onColumnOrderChange: order => dispatch(loadColumnOrder(order))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
