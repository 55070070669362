import React from "react";
import { PDFDownloadLink, Document, Page, Image } from "@react-pdf/renderer";
import i18n from "../i18n";

export const ExportPdf = ({ widgets, fileName, disabled, className= 'mini ui button basic' }) => {
  if (!widgets) {
    return (
      <button type="button" className="mini ui button basic disabled" disabled>
        {i18n.t("Loading")}
      </button>
    );
  }

  const sizeToWidth = {s: '25%', m: '50%', l: '75%', xl: '100%'}
  const widgetsArray = Array.isArray(widgets) ? widgets : [ widgets ];


  const myDoc = (
    <Document>
      <Page orientation="landscape">
        {widgetsArray.map((widget, index) => {
          return (
            <Image
              src={widget.imageUri}
              key={index}
              style={{
                height: 'auto',
                width: sizeToWidth[widget.size.toLowerCase()]
              }}
            />
          )
        })}
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink
      className={className}
      document={myDoc}
      fileName={`${fileName}.pdf`}
      disabled={disabled}
    >
      {({ loading }) => {
        if (loading) {
          return i18n.t("Loading");
        }
        return (
          <>
            <i className="icon cloud download" />
            PDF
          </>
        );
      }}
    </PDFDownloadLink>
  );
};

export default React.memo(ExportPdf);
