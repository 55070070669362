import React, { useState, useRef } from "react";
import { Dropdown, Ref } from "semantic-ui-react";
import TableRowDropdownItem from "./TableRowDropdownItem";

const MENU_ITEM_HEIGHT = 36;

const TableDropdown = props => {
  const { actions = [] } = props;
  const [upward, setUpward] = useState(false);
  const dropdownRef = useRef(null);

  const checkUpward = () => {
    if (props.tableRef?.current && dropdownRef.current) {
      const dropdownBottom = dropdownRef.current.getBoundingClientRect().bottom;
      const tableBottom = props.tableRef.current.getBoundingClientRect().bottom;
      const menuHeight = actions.length * MENU_ITEM_HEIGHT;

      const isNearBottom = dropdownBottom + menuHeight >= tableBottom;
      setUpward(isNearBottom);
    }
  };

  return (
    <Ref innerRef={dropdownRef}>
      <Dropdown
        className="table-dropdown"
        icon="ellipsis vertical"
        direction="left"
        upward={upward}
        onOpen={checkUpward}
      >
        <Dropdown.Menu>
          {actions.map(action => (
            <TableRowDropdownItem
              key={action.action}
              action={action}
              item={props.item}
              disabled={props.editing}
              openModal={props.openModal}
              toggleEditRow={props.toggleEditRow}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Ref>
  );
};

export default TableDropdown;
