export const TYPES = {
  ALERTS: "ALERTS",
  EVENTS: "EVENTS",
  HISTORY: "HISTORY",
  ACTIVITIES: "ACTIVITIES",
  APPLICATIONS: "APPLICATIONS",
  APPLICATION_SHOW: "APPLICATION_SHOW",
  EMAILS: "EMAILS",
  ENV_VARIABLES: "ENV_VARIABLES",
  USERS: "USERS",
  QUEUES: "QUEUES",
  TEAMS: "TEAMS",
  LAUNCH: "LAUNCH",
  FORMS: "FORMS",
  EXECUTIONS: "EXECUTIONS",
  SCHEDULERS: "SCHEDULERS",
  RESOURCES: "RESOURCES",
  CREDENTIALS: "CREDENTIALS",
  SCHEDULER_MONITOR: "SCHEDULER_MONITOR"
};
