import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import { Type, Action } from "./TableActions";

class TableRowAction extends React.Component {
  buttonClass = `ui mini basic blue button ${
    this.props.action.button_icon ? "icon" : ""
  }`;

  show() {
    return Object.prototype.hasOwnProperty.call(this.props.action, "show")
      ? this.props.action.show(this.props.item, this.props.user)
      : true;
  }

  renderButtonLink() {
    // eslint-disable-next-line no-template-curly-in-string
    const link = this.props.action.url.replace("${id}", this.props.item.id);

    return (
      <Link className={this.buttonClass} to={link}>
        {this.renderActionContent()}
      </Link>
    );
  }

  renderButtonModal() {
    return (
      <button
        type="button"
        className={this.buttonClass}
        onClick={() => this.props.openModal(this.props.action, this.props.item)}
      >
        {this.renderActionContent()}
      </button>
    );
  }

  renderActionContent() {
    if (this.props.action.button_icon) {
      return <i className={`icon ${this.props.action.button_icon}`} />;
    }
    return i18n.t(this.props.action.button_text);
  }

  render() {
    if (
      !this.show() ||
      (this.props.action.action === Action.Delete && this.props.item.locked)
    ) {
      return null;
    }

    return this.props.action.type === Type.Link
      ? this.renderButtonLink()
      : this.renderButtonModal();
  }
}

export default TableRowAction;
