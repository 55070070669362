import { useState, useEffect, useRef } from "react";

export default function useImageSize() {
  const [isMaxWidth, setIsMaxWidth] = useState(false);
  const imageRef = useRef();

  useEffect(() => {
    let resizeObserver;

    if (imageRef.current) {
      const onChange = entries => {
        const el = entries[0];
        const result = el.contentRect.width >= el.target.naturalWidth;
        setIsMaxWidth(result);
      };
      // importing polyfill if ResizeObserver is not available (IE, Safari?)
      Promise.resolve(
        typeof ResizeObserver !== "undefined"
          ? resizeObserver
          : import("resize-observer-polyfill")
      ).then(() => {
        resizeObserver = new ResizeObserver(onChange);

        resizeObserver.observe(imageRef.current);
      });
    }
    return () => resizeObserver && resizeObserver.disconnect();
  });

  return { isMaxWidth, imageRef };
}
