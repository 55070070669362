import React, { useEffect } from "react";
import { connect } from "react-redux";
import { DateInput } from "semantic-ui-calendar-react-yz";
import moment from "moment";

const DateInputField = props => {
  useEffect(() => {
    if (!props.value && props.value !== "") {
      props.setFieldValue("");
    }
  }, [props.value]);

  const dateDisplayFormat = props.user.dateformat ?? "DD-MM-YYYY";
  const dateSentFormat = props.fromEnvVariables
    ? "yyyy/MM/DD"
    : "YYYY-MM-DDTHH:mm.SSS";

  const getFormatDateToDisplay = dateString => {
    return dateString ? dateToDisplayFromUTC(dateString) : "";
  };

  const dateToDisplayFromUTC = utcDate => {
    return moment(utcDate, dateSentFormat).format(dateDisplayFormat);
  };

  const dateToUTCFromDisplay = displayDate => {
    if (displayDate) {
      const auxDate = moment(displayDate, dateDisplayFormat).format(
        dateSentFormat
      );
      return props.fromEnvVariables ? auxDate : `${auxDate}Z`;
    }
    return "";
  };

  return (
    <DateInput
      name={props.name}
      placeholder="Date"
      value={getFormatDateToDisplay(props.value)}
      dateFormat={dateDisplayFormat}
      closable
      clearable
      animation="none"
      iconPosition="left"
      disabled={props.disabled}
      onChange={(event, { value }) => {
        props.setFieldValue(dateToUTCFromDisplay(value));
      }}
      style={{ width: "100%" }}
    />
  );
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(DateInputField);
