import React from "react";
import { Link } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import { TransitionablePortal, Dropdown, Modal } from "semantic-ui-react";
import DeleteModal from "./Modal"

import i18n from "../../../i18n";

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  minWidth: "200px",

  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 8px 0`,

  float: 'left',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const Widget = props => {
const [modalOpen, toggleModal] = React.useState(false);

  const serviceName = _ =>{         
    let test = props.services.find(x => x.id === props.widget.service_id)
    return test?.name
  }

  const widgetContent = provided =>{
    return(
      <React.Fragment>
        <span style={{ float: "left" }} {...provided.dragHandleProps}>
          <i className="ui icon bars" />
        </span>
        <div className="widgetSettingsButtons">
          <Dropdown
            icon="ellipsis vertical"
            floating
            button
            className="icon mini basic right"
          >
            <Dropdown.Menu>
            <Link
              to={`/admin/dashboard/${props.section.id}/widget/${props.widget.id}`}
              type="button"
              className="item">
              <i className="icon edit" />{i18n.t("Edit")}
            </Link>
            <a 
              className="item" 
              type="button"
              onClick={() => toggleModal(true)}>
              <i className="icon trash" />{i18n.t("Delete")}
            </a> 
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <p>
          <b>{i18n.t("Title")}:</b> {props.widget.name}
        </p>
        <p>
          <b>{i18n.t("Service")}:</b> {serviceName()}
        </p>
        <p>
          <b>{i18n.t("Data")}:</b> {props.widget.procedure}
        </p>
        <p>
          <b>{i18n.t("Type")}:</b> {props.widget.chart_type}
        </p>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <DeleteModal
        title = {i18n.t("Delete Widget")}
        confirmationText = {i18n.t("DeleteWidgetConfirmation")}
        toggleModal = {state=>{toggleModal(state)}}
        modalOpen = {modalOpen}
        submitAction={()=>{props.handleDelete(props.widget.id)}}/>
      <Draggable 
        draggableId={`${props.widget.id}`} 
        index={props.index}>
        {(provided, snapshot) => (
          <div
            className="four wide column widget-card"
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            {widgetContent(provided)}
          </div>
        )}
      </Draggable>
    </React.Fragment>
  );
};

export default Widget;
