import React, { useState, useEffect, useCallback, useRef } from "react";
import { Loader } from "semantic-ui-react";
import ResizeHandler from "./ResizeHandler";
import SearchInput from "../SearchInput";
import i18n from "../../../i18n";

const MIN_CELL_WIDTH = 125;

const TableHead = ({ filters = {}, ...props }) => {
  const [resizedColumnIndex, setResizedColumnIndex] = useState(null);
  const refsArray = useRef([]);
  const checkboxCellIsPresent = props.selectAllChecked;

  useEffect(() => {
    if (resizedColumnIndex !== null) {
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [resizedColumnIndex, mouseMove, mouseUp, removeListeners]);

  const mouseMove = useCallback(
    e => {
      const newColumnWidths = props.columns.map((col, i) => {
        if (i === resizedColumnIndex) {
          const newWidth =
            e.clientX - refsArray.current[i].getBoundingClientRect().left;

          if (newWidth >= MIN_CELL_WIDTH) {
            return `${newWidth}px`;
          }
        }
        return `${refsArray.current[i].offsetWidth}px`;
      });

      props.onColumnWidthChange(newColumnWidths);
    },
    [resizedColumnIndex, props.columns, MIN_CELL_WIDTH]
  );

  const mouseUp = useCallback(() => {
    setResizedColumnIndex(null);
    removeListeners();
  }, [setResizedColumnIndex, removeListeners]);

  const removeListeners = useCallback(() => {
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", removeListeners);
  }, [mouseMove]);

  const orderIcon = order => {
    if (order) {
      const iconName = order === "asc" ? "angle up" : "angle down";
      return <i className={`icon ${iconName}`} />;
    }
    return undefined;
  };

  const editIcon = (column, disabled) => {
    if (disabled) return <i className="disabled icon pencil" />;
    return (
      <i
        className="icon pencil handcursor"
        role="button"
        tabIndex={0}
        aria-label="Edit"
        disabled
        onClick={() => {
          props.toggleEditField(column.key);
        }}
      />
    );
  };

  const editingIcons = column => {
    return (
      <span>
        <i
          className="icon check green handcursor"
          role="button"
          tabIndex={0}
          aria-label="Update"
          onClick={() => {
            props.updateItems(props.editField, props.editedRows);
            props.toggleEditField(column.key);
          }}
        />
        <i
          className="icon close red handcursor"
          role="button"
          tabIndex={0}
          aria-label="Cancel"
          onClick={() => {
            props.clearEditedRow();
            props.toggleEditField(column.key);
          }}
        />
      </span>
    );
  };

  const _allowEdit = () => props.user.teams.some(team => team.allow_edit);

  const renderEdit = column => {
    if (props.editField) {
      if (props.editField === column.key) {
        return editingIcons(column);
      }

      return editIcon(column, true);
    }

    return editIcon(column, false);
  };

  const renderTitle = column => {
    const isSortable =
      !Object.prototype.hasOwnProperty.call(column, "sortable") ||
      column.sortable;

    if (isSortable) {
      return (
        <span
          onClick={() => props.orderBy(column.key)}
          style={{ cursor: "pointer" }}
          role="button"
          tabIndex={0}
          aria-label="Sort"
        >
          {i18n.t(column.name)}
          {orderIcon(column.order)}
        </span>
      );
    }

    return <span aria-label="Sort">{i18n.t(column.name)}</span>;
  };

  const renderEditContainer = column => {
    if (column.editContent && _allowEdit()) {
      return renderEdit(column);
    }

    return undefined;
  };

  const renderSelectAllCheckbox = () => {
    return (
      <th>
        {props.selectingAll ? (
          <Loader size="small" active={props.selectingAll} />
        ) : (
          <div className="ui checkbox fitted">
            <input
              type="checkbox"
              checked={props.selectAllChecked()}
              onChange={props.onSelectAllClick}
            />
            <label />
          </div>
        )}
      </th>
    );
  };

  return (
    <thead>
      <tr>
        {checkboxCellIsPresent && renderSelectAllCheckbox()}
        {props.columns.map((column, index) => (
          <th key={column.name} ref={el => (refsArray.current[index] = el)}>
            <span className="d-flex-center-sb">
              {renderTitle(column)}
              {renderEditContainer(column)}
            </span>
            <ResizeHandler
              index={index}
              resizedColumnIndex={resizedColumnIndex}
              setResizedColumnIndex={setResizedColumnIndex}
            />
          </th>
        ))}
      </tr>
      <tr>
        {checkboxCellIsPresent && <th />}
        {props.columns.map(column => (
          <th key={`search_${column.key}`} className="table-header-search">
            <SearchInput
              loadingItems={props.loadingItems}
              column={column}
              filters={filters}
              value={filters[column?.key]}
              loadItems={props.loadItems}
            />
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
