import axios from "../axios";

class QueueService {
  static loadQueues = params => {
    return axios.get("/conf/queues", { params });
  };

  static loadActivitiesFromQueue = params => {
    return axios.get("/conf/queues/activities", { params });
  };

  static loadQueueExtraParameters = params => {
    return axios.get("conf/queues/extra_parameters", { params });
  };
}

export default QueueService;
