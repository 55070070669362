import store from "./store";

export const userIdToName = users => {
  const { users: userList } = store.getState().usersReducer;

  const findUserIdInUserList = userId =>
    userList.find(user => user.id === userId)?.name ?? userId;

  if (userList?.length > 0) {
    if (Array.isArray(users)) {
      return users.map(findUserIdInUserList).join(", ");
    }
    if (typeof users !== "number") {
      const parsedUserId = parseInt(users, 10);
      if (Number.isNaN(parsedUserId)) {
        return users;
      }
      return findUserIdInUserList(parsedUserId);
    }
    return findUserIdInUserList(users);
  }
  return Array.isArray(users) ? users.join(", ") : users;
};
