import React from "react";
import { Formik, Field } from "formik";
import CreatableSelect from "react-select/creatable";
import { Redirect } from "react-router";
import i18n from "../../../i18n";

class EmailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      returnToApplications: false
    };
    this.state = { windowWidth: window.innerWidth };

    this.inputs = [
      { label: "Alias", name: "email_alias", type: "text", required: true },
      {
        label: "EmailAddress",
        name: "name",
        type: "email",
        required: true
      },
      // { label: "Domain", name: "domain", type: "text", required: true },
      { label: "ImapServer", name: "address", type: "text", required: true },
      { label: "Folder", name: "folder", type: "text", required: false },
      {
        label: "SubjectFilters",
        name: "subject_filters",
        type: "array",
        required: false
      },
      {
        label: "Password",
        name: "password",
        type: "password",
        required: true
      },
      { label: "ImapPort", name: "port", type: "number", required: true },
      {
        label: "Archive",
        name: "archive_read_emails",
        type: "checkbox",
        required: true
      }
    ];
  }

  initialValues = () => {
    const values = {};

    this.inputs.forEach(input => {
      values[input.name] = this.props.email ? this.props.email[input.name] : "";
      if (this.props.email) {
        if (input.type === "array") {
          if (this.props.email[input.name]) {
            const array = `${this.props.email[input.name]}`.split(",");
            const options = array
              .filter(element => element)
              .map(element => {
                return { value: element, label: element };
              });
            values[input.name] = options;
          } else {
            values[input.name] = null;
          }
        } else {
          values[input.name] = this.props.email[input.name];
        }
      } else if (input.type === "checkbox") {
        values[input.name] = this.props.email
          ? this.props.email[input.name]
          : false;
      } else {
        values[input.name] = "";
      }
    });
    return values;
  };

  returnToApplication = () => {
    this.setState({
      returnToApplications: true
    });
  };

  renderInput(input, values, handleChange, setFieldValue) {
    const { windowWidth } = this.state;
    const mobileClass = windowWidth <= 760 ? "required field inlineFieldMobile" : "required field inlineField"
    if (windowWidth <= 660) {
      return (
        <div
          className={`inline inlineFieldMobile field ${input.required ? "required" : ""
            }`}
        >
          <label>{i18n.t(input.label)}</label>
          {input.type === "array"
            ? this.renderSelect(input, values, handleChange, setFieldValue)
            : this.renderField(input, values, handleChange)}
        </div>
      );
    } else {
      return (
        <div
          className={`inline inlineField field ${input.required ? "required" : ""
            }`}
        >
          <label>{i18n.t(input.label)}</label>
          {input.type === "array"
            ? this.renderSelect(input, values, handleChange, setFieldValue)
            : this.renderField(input, values, handleChange)}
        </div>
      );
    }
  }

  renderField(input, values, handleChange) {
    return (
      <Field
        name={input.name}
        value={values[input.name]}
        type={input.type}
        autoComplete={input.type === "password" ? "new-password" : `form_item_${Math.random()}`}
        style={{ width: input.type === "checkbox" ? "auto" : "100%" }}
        onChange={handleChange}
        checked={values[input.name]}
      />
    );
  }

  renderSelect(input, values, handleChange, setFieldValue) {
    return (
      <CreatableSelect
        isClearable
        isMulti
        defaultValue={this.initialValues().subject_filters}
        options={values.subject_filters}
        styles={{
          container: base => ({
            ...base,
            flex: 1
          })
        }}
        onChange={value => {
          setFieldValue(input.name, value);
        }}
      />
    );
  }

  render() {
    if (this.state.returnToApplications) {
      return <Redirect push to="/admin/emails" />;
    }

    return (
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          this.props.onSubmit(values, setSubmitting, this.returnToApplication);
        }}
        initialValues={this.initialValues()}
        enableReinitialize
      >
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          setFieldValue,
          values
        }) => (
            <form className="ui form" onSubmit={handleSubmit}>
              <div className="fieldsContainer">
                {this.inputs.map(input =>
                  this.renderInput(input, values, handleChange, setFieldValue)
                )}
              </div>

              <div className="actionButtons">
                <button
                  className="ui button blue basic"
                  type="button"
                  onClick={() => this.returnToApplication()}
                >
                  {i18n.t("Cancel")}
                </button>

                <button
                  className="ui button blue"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {i18n.t("SaveButton")}
                </button>
              </div>
            </form>
          )}
      </Formik>
    );
  }
}

export default EmailsForm;
