import React from "react";
import i18n from "../../../i18n";
import EmailsForm from "./EmailsForm";
import EmailService from "../../../../services/data_services/email";
import Toast from "../../../../services/toasts";
import WithLoadingComponent from "../../../Ui/WithLoadingComponent";
import { checkAndRedirect } from "../../../../services/axios";

class EmailsNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: undefined
    };

    this.setBreadcrumb();

    if (this.props.match.params.id) {
      EmailService.loadEmail(this.props.match.params.id)
        .then(result => {
          this.setState(
            {
              email: result.data
            },
            this.setBreadcrumb
          );
        })
        .catch(checkAndRedirect);
    }
  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Emails"),
        location: "/admin/emails"
      },
      {
        title: this.title(),
        location: "/admin/emails/new"
      }
    ]);
  }

  title() {
    if (this.state.email) {
      return `${i18n.t("NewEmail")} - ${this.state.email.name}`;
    }
    return `${i18n.t("NewEmail")}`;
  }

  createEmail(values, setSubmitting, returToApplication) {
    const valuesToPost = { ...values };
    valuesToPost.subject_filters = (values.subject_filters || []).map(
      filter => filter.label
    );

    EmailService.createEmail(valuesToPost)
      .then(() => {
        setSubmitting(false);
        returToApplication();
        Toast.show.emailsCreated();
      })
      .catch(e => {
        setSubmitting(false);
        Toast.show.emailsNotCreated();
      });
  }

  render() {
    return (
      <WithLoadingComponent
        loadingCondition={() => this.props.match.params.id && !this.state.email}
        contentCondition={() =>
          (this.props.match.params.id && this.state.email.id) ||
          !this.props.match.params.id
        }
        noItemsKey="NotAbleLoadEmail"
      >
        <EmailsForm email={this.state.email} onSubmit={this.createEmail} />
      </WithLoadingComponent>
    );
  }
}

export default EmailsNew;
