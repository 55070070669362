import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";

const ApplicationsPreConfiguredCard = props => {
  return (
    <div
      className="ui segment"
      style={{
        width: "250px",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1rem",
        marginRight: "1rem"
      }}
    >
      <h3 style={{ textAlign: "center" }}>{props.application.name}</h3>
      <Link
        to={`/admin/applications/new/${props.application.id}`}
        className="ui button blue"
        style={{ position: "absolute", right: 0, bottom: "4px" }}
      >
        {i18n.t("View Details")}
      </Link>
    </div>
  );
};

export default ApplicationsPreConfiguredCard;
