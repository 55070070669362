export const unique = array => {
  const a = array;
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
};

export const isIE = () => {
  const ua = navigator.userAgent;

  return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
};

export const isDesktop = () => {
  return window.innerWidth >= 768;
};
