/* eslint-disable react/prefer-stateless-function */
import React from "react";
import { isEmpty } from "lodash";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { Grid } from "semantic-ui-react";
import GraphPreview from "./GraphPreview";
import InputField from "../../../../Scheduler/Schedulers/InputFields/InputField";
import i18n from "../../../../i18n";

import ServiceService from "../../../../../services/data_services/service";
import WidgetService from "../../../../../services/data_services/widget";
import ApplicationService from "../../../../../services/data_services/application";

import { CHART_TYPES, chartHasAxis } from "../../../../../constants/widgets";
import { SERVICE_TYPES } from "../../../../../constants/service-types";

const sizeOptionsCollection = [
  { label: "25%", value: "S" },
  { label: "50%", value: "M" },
  { label: "75%", value: "L" },
  { label: "100%", value: "XL" }
];

class WidgetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      services: undefined,
      procedures: undefined,
      parameters: undefined,
      individual_item_applications: undefined,
      colorList: this.fillColorList()
    };
  }

  prepareNumber = color => {

  }

  fillColorList = _ => {
    const tempList = [];
    for (let i = 0; i < 25; i++) {
      const auxColor = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6,"0")}`;
      tempList.push(auxColor);
    }
    return tempList;
  };

  componentDidMount = () => {
    this.loadServices();
    this.loadApplications();
  };

  componentDidUpdate = prevProps => {
    if (
      !prevProps.data &&
      this.props.data &&
      this.props.data?.options?.colors
    ) {
      this.setState({ colorList: this.props.data.options.colors });
    }
    if (
      (!prevProps.data && this.props.data) ||
      (prevProps.data &&
        this.props.data &&
        prevProps.data.service_id !== this.props.data.service_id)
    ) {
      this.loadProcedures(this.props.data.service_id);

      if (this.props.data.procedure) {
        this.loadParameters(
          this.props.data.service_id,
          this.props.data.procedure
        );
      }
    }
  };

  loadServices = () => {
    ServiceService.loadServices().then(response => {
      this.setState({
        services: response.data
      });
    });
  };

  loadProcedures = serviceId => {
    WidgetService.loadProcedures(serviceId)
      .then(response => {
        this.setState({
          procedures: response.data
        });
      })
      .catch(e => console.log("error", e));
  };

  loadParameters = (serviceId, procedure, checkAppValue = null) => {
    WidgetService.loadParameters(serviceId, procedure)
      .then(response => {
        this.setState(
          {
            parameters: response.data.length ? response.data[0].parameters : []
          },
          () => {
            if (checkAppValue) {
              const { values, setFieldValue } = checkAppValue;
              setTimeout(
                () => this.checkApplicationValue(values, setFieldValue),
                500
              );
            }
          }
        );
      })
      .catch(() => {
        this.setState({ parameters: [] });
      });
  };

  loadApplications = () => {
    ApplicationService.loadApplications()
      .then(result => {
        this.setState({
          individual_item_applications: result.data.results
        });
      })
      .catch(e => {
        console.log(e);
      });
  };

  getFieldValue = (values, name) => {
    if (
      Object.prototype.hasOwnProperty.call(values, "data") &&
      Object.prototype.hasOwnProperty.call(values.data, name)
    ) {
      return values.data[name];
    }

    return undefined;
  };

  prepareSubmitData = values => {
    const valuesToPost = { ...values };

    valuesToPost.section_id = this.props.match.params.section_id;

    if (
      Object.prototype.hasOwnProperty.call(valuesToPost, "data") &&
      typeof valuesToPost.data === "object"
    ) {

      let dataCollection =  Object.entries(valuesToPost.data);
      let requestData = [];
      for(let i=0; i < dataCollection.length; i++){
        if(dataCollection[i][1]){
          requestData.push({
            parameter_name: dataCollection[i][0],
            parameter_value: dataCollection[i][1]
          }
          )
        }
      }
      valuesToPost.data = requestData;
      valuesToPost.options.colors = this.state.colorList;

      valuesToPost.data = JSON.stringify(valuesToPost.data);
    }

    return valuesToPost;
  };

  initialValues = () => {
    const initVal = {
      name: this.props.data ? this.props.data.name : "",
      description: this.props.data ? this.props.data.description : "",
      chart_type: this.props.data ? this.props.data.chart_type : undefined,
      size: this.props.data ? this.props.data.size : undefined,
      service_id: this.props.data ? this.props.data.service_id : undefined,
      data: {},
      options: this.props.data ? this.props.data.options : {},
      procedure: this.props.data ? this.props.data.procedure : "",
      application_id: this.props.data ? this.props.data.application_id : ""
    };

    if (this.props.data) {
      this.props.data.data.map(
        parameter =>
          (initVal.data[parameter.parameter_name] = parameter.parameter_value)
      );
    }

    return initVal;
  };

  validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required("Is required"),
      description: Yup.string().nullable(true),
      chart_type: Yup.string().required("Is required"),
      size: Yup.string().required("Is required"),
      service_id: Yup.string().required("Is required"),
      options: Yup.object()
        .shape({
          hAxis: Yup.object()
            .shape({ title: Yup.string().notRequired() })
            .notRequired(),
          yAxis: Yup.object()
            .shape({ title: Yup.string().notRequired() })
            .notRequired()
        })
        .notRequired()
        .nullable(true),
      procedure: Yup.string().required("Is required"),
      application_id: Yup.number()
        .nullable(true)
        .notRequired()
    });
  };

  chartOptions() {
    return CHART_TYPES;
  }

  chartValue(value) {
    return this.chartOptions().filter(option => option.value === value);
  }

  sizeOptions() {
    return sizeOptionsCollection.map(size => {
      return { label: size.label, value: size.value };
    });
  }

  sizeValue(value) {
    return this.sizeOptions().filter(option => option.value === value);
  }

  serviceOptions() {
    if (!this.state.services) {
      return [];
    }

    return (
      this.state.services &&
      this.state.services
        .filter(
          ({ service_type }) =>
            ![SERVICE_TYPES.HYPERSCIENCE, SERVICE_TYPES.NCW].includes(
              service_type
            )
        )
        .map(service => {
          return { label: service.name, value: service.id };
        })
    );
  }

  serviceValue(value) {
    return this.serviceOptions().filter(option => option.value === value);
  }

  proceduresOptions() {
    if (!this.state.procedures) {
      return [];
    }

    return (
      this.state.procedures &&
      this.state.procedures.map(procedure => {
        return { label: procedure, value: procedure };
      })
    );
  }

  proceduresValue(value) {
    return this.proceduresOptions().filter(option => option.value === value);
  }

  applicationIdOptions() {
    if (!this.state.individual_item_applications) {
      return [];
    }

    return this.state.individual_item_applications
      .filter(app => app.active)
      .map(application => {
        return { label: application.name, value: application.id };
      });
  }

  applicationIdValue(value) {
    return this.applicationIdOptions().find(option => option.value === value);
  }

  setApplicationValue = (option, values, setFieldValue) => {
    setFieldValue("application_id", option ? option.value : "");
    if (
      option &&
      !isEmpty(values.data) &&
      Object.keys(values.data).some(x => x === "application_uuid_to_search")
    ) {
      const selectedAppUuid = this.state.individual_item_applications.find(
        app => app.id === option.value
      ).uuid;
      const newData = { ...values.data };
      newData.application_uuid_to_search = selectedAppUuid;
      setFieldValue("data", newData);
    }
  };

  checkApplicationValue = (values, setFieldValue) => {
    if (
      values.application_id &&
      this.state.parameters.some(
        x => x.parameter_name === "application_uuid_to_search"
      )
    ) {
      const selectedAppUuid = this.state.individual_item_applications.find(
        app => app.id === values.application_id
      ).uuid;
      const newData = { ...values.data };
      newData.application_uuid_to_search = selectedAppUuid;
      setFieldValue("data", newData);
    }
  };

  renderAxisFields(chartType) {
    if (!chartHasAxis(chartType)) {
      return undefined;
    }

    return (
      <div className="inlineField">
        <div className="subForm">
          <div className="inline fields">
            <label>{i18n.t("XAxis")}</label>
            <div className="field full-width">
              <Field name="options.hAxis.title" style={{ width: "100%" }} />
              <ErrorMessage
                name="options.hAxis.title"
                component="div"
                className="input-error"
              />
            </div>
          </div>

          <div className="inline fields">
            <label>{i18n.t("YAxis")}</label>
            <div className="field full-width">
              <Field name="options.vAxis.title" style={{ width: "100%" }} />
              <ErrorMessage
                name="options.vAxis.title"
                component="div"
                className="input-error"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  changeColor = colorList => {
    this.setState({ colorList });
  };

  render() {
    return (
      <Formik
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          const valuesToPost = this.prepareSubmitData(values);

          this.props.onSubmit(valuesToPost, setSubmitting);
        }}
        initialValues={this.initialValues()}
        validationSchema={this.validationSchema()}
      >
        {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete="nope"
            className="ui form container widgetForm"
            style={{ width: "100%" }}
          >
            <Grid stackable columns={2}>
              <Grid.Column mobile={16} computer={8}>
                <div className="required inline fields">
                  <label>{i18n.t("Title")}</label>
                  <div className="field full-width">
                    <Field name="name" type="text" style={{ width: "100%" }} />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="input-error"
                    />
                  </div>
                </div>

                <div className="inline fields">
                  <label>{i18n.t("Description")}</label>
                  <div className="field full-width">
                    <Field
                      component="textarea"
                      name="description"
                      style={{ width: "100%" }}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="input-error"
                    />
                  </div>
                </div>

                <div className="required inline fields">
                  <label>{i18n.t("Chart")}</label>
                  <div className="field full-width">
                    <Select
                      value={this.chartValue(values.chart_type)}
                      options={this.chartOptions()}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isClearable
                      onChange={option => {
                        setFieldValue(
                          "chart_type",
                          option ? option.value : undefined
                        );

                        if (!chartHasAxis(option?.value)) {
                          setFieldValue("options.hAxis.title", undefined);
                          setFieldValue("options.vAxis.title", undefined);
                        }
                      }}
                    />
                    <ErrorMessage
                      name="chart_type"
                      component="div"
                      className="input-error"
                    />
                  </div>
                </div>

                {this.renderAxisFields(values.chart_type)}

                <div className="required inline fields">
                  <label>{i18n.t("Size")}</label>
                  <div className="field full-width">
                    <Select
                      value={this.sizeValue(values.size)}
                      options={this.sizeOptions()}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isClearable
                      onChange={option => {
                        setFieldValue(
                          "size",
                          option ? option.value : undefined
                        );
                      }}
                    />
                    <ErrorMessage
                      name="size"
                      component="div"
                      className="input-error"
                    />
                  </div>
                </div>

                <div className="inline fields">
                  <label>{i18n.t("Application")}</label>
                  <div className="field full-width">
                    <Select
                      value={this.applicationIdValue(values.application_id)}
                      options={this.applicationIdOptions()}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isClearable
                      isDisabled={!this.state.individual_item_applications}
                      onChange={option => {
                        this.setApplicationValue(option, values, setFieldValue);
                      }}
                    />
                    <ErrorMessage
                      name="application_id"
                      component="div"
                      className="input-error"
                    />
                  </div>
                </div>

                <div className="required inline fields">
                  <label>{i18n.t("Service")}</label>
                  <div className="field full-width">
                    <Select
                      value={this.serviceValue(values.service_id)}
                      options={this.serviceOptions()}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isClearable
                      isDisabled={this.state.services === undefined}
                      onChange={option => {
                        setFieldValue("procedure", undefined);
                        setFieldValue("data", undefined);
                        this.setState({
                          procedures: undefined,
                          parameters: undefined
                        });

                        if (option) {
                          setFieldValue("service_id", option.value);
                          this.loadProcedures(option.value);
                        } else {
                          setFieldValue("service_id", undefined);
                          this.setState({
                            procedures: undefined
                          });
                        }
                      }}
                    />
                    <ErrorMessage
                      name="service_id"
                      component="div"
                      className="input-error"
                    />
                  </div>
                </div>

                <div className="required inline fields inlineField">
                  <label>{i18n.t("FeedDataFrom")}</label>
                  <div className="field full-width">
                    <Select
                      value={this.proceduresValue(values.procedure)}
                      options={this.proceduresOptions()}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      isClearable
                      isDisabled={this.state.procedures === undefined}
                      onChange={option => {
                        setFieldValue("data", {});
                        this.setState({
                          parameters: undefined
                        });

                        if (option) {
                          setFieldValue("procedure", option.value);
                          this.loadParameters(values.service_id, option.value, {
                            values,
                            setFieldValue
                          });
                        } else {
                          setFieldValue("procedure", undefined);
                        }
                      }}
                    />
                    <ErrorMessage
                      name="procedure"
                      component="div"
                      className="input-error"
                    />
                  </div>
                </div>

                <div className="inlineField">
                  <div className="subForm">
                    {this.state.parameters &&
                      this.state.parameters.map(parameter => {
                        const name = parameter.parameter_name;

                        return (
                          <InputField
                            key={parameter.parameter_name}
                            placeholder={name}
                            title={name}
                            name={parameter.parameter_name}
                            type={parameter.parameter_type}
                            value={this.getFieldValue(values, name)}
                            fromDashboardIndex
                            setFieldValue={value => {
                              const newData = { ...values.data };
                              newData[name] = value;
                              setFieldValue("data", newData);
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column
                // style={{ minWidth: "500px" }}
                mobile={16}
                computer={8}
              >
                <GraphPreview
                  values={values}
                  parameters={this.state.parameters}
                  colorList={this.state.colorList}
                  onChange={this.changeColor}
                />
                {/* <GraphColorPicker colorList={this.state.colorList} onChange={this.changeColor} /> */}
              </Grid.Column>
              <div className="sixteen wide column">
                <div className="save-buttons">
                  <button
                    className="ui button blue basic"
                    type="button"
                    onClick={this.props.onCancel}
                  >
                    {i18n.t("CancelButton")}
                  </button>

                  <button
                    className={`ui button blue ${
                      isSubmitting ? "loading" : ""
                    }`}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {i18n.t("SaveButton")}
                  </button>
                </div>
              </div>
            </Grid>
          </form>
        )}
      </Formik>
    );
  }
}

export default WidgetForm;
