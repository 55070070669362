import { get } from "lodash";
import i18n from "../components/i18n";

export const ToastType = Object.freeze({
  Success: "SUCCESS",
  Failure: "FAILURE"
});

class Toast {
  static animation = "fade down";

  static lifetime = 3000;

  static displaySuccess = message => {
    return this.#successMessage(message);
  };

  static displayWarning = message => {
    return this.#warningToast(message);
  };

  static displayError = (
    customError,
    defaultError = i18n.t("SomethingWentWrong")
  ) => {
    return this.#errorMessage( i18n.t(customError) || defaultError);
  };

  static show = {
    accountUpdated: () =>
      this.#successToast("AccountDetails", "AccountToastUpdated"),
    accountNotUpdated: () =>
      this.#failureToast("AccountDetails", "AccountToastNotUpdated"),
    itemsDeleted: () =>
      this.#successToast("Communications", "ItemsToastDeleted"),
    itemsNotDeleted: () =>
      this.#failureToast("Communications", "ItemsToastNotUpdated"),
    itemsCompleted: () =>
      this.#successToast("Communications", "ItemsToastCompleted"),
    itemsNotCompleted: () =>
      this.#failureToast("Communications", "ItemsToastNotUpdated"),
    itemsException: () =>
      this.#successToast("Communications", "ItemsToastException"),
    itemsNotException: () =>
      this.#failureToast("Communications", "ItemsToastNotUpdated"),
    itemsRetried: () =>
      this.#successToast("Communications", "ItemsToastRetried"),
    itemsNotRetried: () =>
      this.failureToast("Communications", "ItemsToastNotUpdated"),
    activitiesLaunched: () =>
      this.#successToast("Activities", "ActivityToastLaunched"),
    activitiesNotLaunched: () =>
      this.#failureToast("Activities", "ActivityToastNotLaunched"),
    activitiesUpdated: () =>
      this.#successToast("Activities", "ActivityToastUpdated"),
    activitiesNotUpdated: () =>
      this.#failureToast("Activities", "ActivityToastNotUpdated"),
    activityNotLoaded: () =>
      this.#failureToast("Activity", "ActivityNotLoaded"),
    fieldsSubmitted: () => this.#successToast("Activity", "FieldsSubmitted"),
    fieldsNotSubmitted: () =>
      this.#failureToast("Activity", "FieldsNotSubmitted"),
    usersCreated: () => this.#successToast("Users", "UsersToastCreated"),
    usersNotCreated: () => this.#failureToast("Users", "UsersToastNotCreated"),
    usersUpdated: () => this.#successToast("Users", "UsersToastUpdated"),
    usersNotUpdated: () => this.#failureToast("Users", "UsersToastNotUpdated"),
    usersDeleted: () => this.#successToast("Users", "UsersToastDeleted"),
    usersNotDeleted: () => this.#failureToast("Users", "UsersToastNotDeleted"),
    teamsCreated: () => this.#successToast("Teams", "TeamsToastCreated"),
    teamsNotCreated: () => this.#failureToast("Teams", "TeamsToastNotCreated"),
    teamsUpdated: () => this.#successToast("Teams", "TeamsToastUpdated"),
    teamsNotUpdated: () => this.#failureToast("Teams", "TeamsToastNotUpdated"),
    teamsDeleted: () => this.#successToast("Teams", "TeamsToastDelete"),
    teamsNotDeleted: () => this.#failureToast("Teams", "TeamsToastNotDelete"),
    applicationsCreated: () =>
      this.#successToast("Applications", "ApplicationsToastCreated"),
    applicationsNotCreated: () =>
      this.#failureToast("Applications", "ApplicationsToastNotCreated"),
    applicationsUpdated: () =>
      this.#successToast("Applications", "ApplicationsToastUpdated"),
    applicationsNotUpdated: () =>
      this.#failureToast("Applications", "ApplicationsToastNotUpdated"),
    applicationsDeleted: () =>
      this.#successToast("Applications", "ApplicationsToastDeleted"),
    applicationsNotDeleted: () =>
      this.#failureToast("Applications", "ApplicationsToastNotDeleted"),
    emailsCreated: () => this.#successToast("Emails", "EmailsToastCreated"),
    emailsNotCreated: () =>
      this.#failureToast("Emails", "EmailsToastNotCreated"),
    emailsUpdated: () => this.#successToast("Emails", "EmailsToastUpdated"),
    emailsNotUpdated: () =>
      this.#failureToast("Emails", "EmailsToastNotUpdated"),
    emailsDeleted: () => this.#successToast("Emails", "EmailsToastDeleted"),
    emailsNotDeleted: () =>
      this.#failureToast("Emails", "EmailsToastNotDeleted"),
    processLaunched: () =>
      this.#successToast("LaunchProcess", "ProcessToastLaunched"),
    processNotLaunched: () =>
      this.#failureToast("LaunchProcess", "ProcessToastNotLaunched"),
    formsCreated: () => this.#successToast("Forms", "FormsToastCreated"),
    formsNotCreated: () => this.#failureToast("Forms", "FormsToastNotCreated"),
    formsUpdated: () => this.#successToast("Forms", "FormsToastUpdated"),
    formsNotUpdated: () => this.#failureToast("Forms", "FormsToastNotUpdated"),
    formsDeleted: () => this.#successToast("Forms", "FormsToastDeleted"),
    formsNotDeleted: () => this.#failureToast("Forms", "FormsToastNotDeleted"),
    formsCorrupted: () =>
      this.#failureToast("Forms", "FormsConfigurationCorrupted"),
    retrySuccess: () => this.#successToast("Retry", "RetrySuccess"),
    retryCouldNotBeDone: () =>
      this.#failureToast("Retry", "RetryCouldNotBeDone"),
    retryFailed: () => this.#failureToast("Retry", "RetryFailed"),
    deleteFailed: () => this.#failureToast("Delete", "DeleteFailed"),
    exportFailed: () => this.#failureToast("Export", "ExportFailed"),
    rossumNotAvailable: () =>
      this.#failureToast("Rossum", "RossumNotAvailable"),
    schedulerCreated: () =>
      this.#successToast("Schedulers", "SchedulerToastCreated"),
    schedulerNotCreated: () =>
      this.#failureToast("Schedulers", "SchedulerToastNotCreated"),
    scheduleDeleted: () =>
      this.#successToast("Scheduler", "ScheduleToastDeleted"),
    scheduleNotDeleted: () =>
      this.#failureToast("Scheduler", "ScheduleToastNotDeleted"),
    schedulerUpdated: () =>
      this.#successToast("Schedulers", "SchedulerToastUpdated"),
    schedulerNotUpdated: () =>
      this.#failureToast("Schedulers", "SchedulerToastNotUpdated"),
    schedulerPaused: () =>
      this.#successToast("Schedulers", "SchedulerToastPaused"),
    schedulerNotPaused: () =>
      this.#failureToast("Schedulers", "SchedulerToastNotPaused"),
    schedulerResumed: () =>
      this.#successToast("Schedulers", "SchedulerToastResumed"),
    schedulerNotResumed: () =>
      this.#failureToast("Schedulers", "SchedulerToastNotResumed"),
    companyCreated: () => this.#successToast("Companies", "CompanyCreated"),
    companyUpdated: () => this.#successToast("Companies", "CompanyUpdated"),
    companyDeleted: () => this.#successToast("Companies", "CompanyDeleted"),
    serviceCreated: () => this.#successToast("Services", "ServiceCreated"),
    serviceUpdated: () => this.#successToast("Services", "ServiceUpdated"),
    serviceDeleted: () => this.#successToast("Services", "ServiceDeleted"),
    sectionsCreated: () =>
      this.#successToast("Sections", "SectionsToastCreated"),
    sectionsNotCreated: () =>
      this.#failureToast("Sections", "SectionsToastNotCreated"),
    sectionsDeleted: () =>
      this.#successToast("Sections", "SectionsToastDeleted"),
    sectionsNotDeleted: () =>
      this.#failureToast("Sections", "SectionsToastNotDeleted"),
    sectionsUpdated: () =>
      this.#successToast("Sections", "SectionsToastUpdated"),
    sectionsNotUpdated: () =>
      this.#failureToast("Sections", "SectionsToastNotUpdated"),
    widgetsCreated: () => this.#successToast("Widgets", "WidgetsToastCreated"),
    widgetsNotCreated: () =>
      this.#failureToast("Widgets", "WidgetsToastNotCreated"),
    widgetsUpdated: () => this.#successToast("Widgets", "WidgetsToastUpdated"),
    widgetsNotUpdated: () =>
      this.#failureToast("Widgets", "WidgetsToastNotUpdated"),
    widgetDeleted: () => this.#successToast("Widgets", "WidgetDeleted"),
    widgetNotDeleted: () => this.#failureToast("Widgets", "WidgetNotDeleted"),
    envVariableDeleted: () =>
      this.#successToast("EnvVariable", "VariableDeleted"),
    envVariableNotDeleted: () =>
      this.#failureToast("EnvVariable", "VariableNotDeleted"),
    commentCreated: () => this.#successToast("Comments", "CommentCreated"),
    commentNotCreated: () =>
      this.#failureToast("Comments", "CommentNotCreated"),
    credentialDeleted: () =>
      this.#successToast("Credential", "CredentialDeleted"),
    credentialNotDeleted: () =>
      this.#failureToast("Credential", "CredentialNotDeleted"),
    sectionsRefreshingError: sectionName =>
      this.#failureToast("Sections", "sectionsRefreshingError", {
        section_name: sectionName
      })
  };

  static #successToast = (title, description) => {
    const addToast = get(window, "__react_toast_provider.current.add");
    addToast(i18n.t(description), { appearance: "success", autoDismiss: true });
  };

  static #warningToast = (description) => {
    const addToast = get(window, "__react_toast_provider.current.add");
    addToast(i18n.t(description), { appearance: "warning", autoDismiss: true });
  };

  static #failureToast = (title, description, description_params = []) => {
    const addToast = get(window, "__react_toast_provider.current.add");
    addToast(i18n.t(description, description_params), {
      appearance: "error",
      autoDismiss: true
    });
  };

  static #successMessage = description => {
    const addToast = get(window, "__react_toast_provider.current.add");
    addToast(description, {
      appearance: "success",
      autoDismiss: true
    });
  };

  static #errorMessage = description => {
    const addToast = get(window, "__react_toast_provider.current.add");
    addToast(description, {
      appearance: "error",
      autoDismiss: true
    });
  };
}

export default Toast;
