import React from "react";
import { Redirect } from "react-router-dom";
import TeamsForm from "./TeamsForm";
import i18n from "../../../i18n";
import TeamService from "../../../../services/data_services/team";
import Toast from "../../../../services/toasts";
import WithLoadingComponent from "../../../Ui/WithLoadingComponent";
import { checkAndRedirect } from "../../../../services/axios";

class TeamsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      team: undefined
    };

    this.setBreadcrumb();

    TeamService.loadTeam(this.props.match.params.id)
      .then(result => {
        this.setState(
          {
            team: result.data
          },
          this.setBreadcrumb
        );
      })
      .catch(checkAndRedirect);
  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Teams"),
        location: "/admin/teams"
      },
      {
        title: this.state.team
          ? `${i18n.t("Team")} - ${this.state.team.name}`
          : `${i18n.t("Team")} - ${this.props.match.params.id}`,
        location: `/admin/teams/${this.props.match.params.id}`
      }
    ]);
  }

  submitForm = (values, submittingFormik, returnTeams) => {
    TeamService.updateTeam(this.state.team.id, values)
      .then(() => {
        submittingFormik(false);
        Toast.show.teamsUpdated();
        returnTeams();
      })
      .catch((e) => {
        console.log('Error', e);
        submittingFormik(false);
        Toast.show.teamsNotUpdated();
      });
  };

  render() {
    if (this.state.returnToTeams) {
      return <Redirect push to="/admin/teams" />;
    }
    return (
      <WithLoadingComponent
        loadingCondition={() => !this.state.team}
        contentCondition={() => this.state.team.id}
        noItemsKey="NotAbleLoadTeam"
      >
        <TeamsForm team={this.state.team} submit={this.submitForm} />
      </WithLoadingComponent>
    );
  }
}

export default TeamsEdit;
