import React from "react";
import i18n from "../../i18n";

export default function QueueInformation({ averageDuration, totalDuration }) {
  const averageDisplay = averageDuration ?? " - ";
  const totalDisplay = totalDuration ?? " - ";
  return (
    <div className="queue-information">
      <div className="label">{i18n.t("AverageCaseDuration")}:</div>
      <div className="value">{averageDisplay}</div>
      <div className="label">{i18n.t("TotalCaseDuration")}:</div>
      <div className="value">{totalDisplay}</div>
    </div>
  );
}
