import React from "react";
import { isDesktop } from "../../../../services/general";

const ApplicationsRadioInput = props => {
  if (!isDesktop()) {
    return (
      <div className="field">
        <label>{props.title}</label>
        <div className="inline fields">
          {props.fields.map(field => {
            return (
              <div key={field.label} className="field">
                <input
                  type="radio"
                  name={props.name}
                  value={field.value}
                  checked={props.values[props.name] === field.value}
                  onChange={props.onChange}
                />
                <label>{field.label}</label>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <div className="inline fields">
      <label style={{ marginRight: "0" }}>{props.title}</label>

      {props.fields.map(field => {
        return (
          <div key={field.label} className="field">
            <input
              type="radio"
              name={props.name}
              value={field.value}
              checked={props.values[props.name] === field.value}
              onChange={props.onChange}
            />
            <label>{field.label}</label>
          </div>
        );
      })}
    </div>
  );
};

export default ApplicationsRadioInput;
