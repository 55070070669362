import React, { Component } from "react";
import Select from "react-select";
import { reactSelectStyle } from "../../../semantic-ui";
import TeamService from "../../../services/data_services/team";

class TeamSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teams: undefined
    };
  }

  componentDidMount() {
    this.loadTeams();
  }

  loadTeams = () => {
    TeamService.loadTeams().then(response => {
      const teams = response.data.results;
      const availableTeamsForUser = teams.filter(team => {
        for (let userTeam of this.props.user.teams) {
          if (userTeam.id === team.id) {
            return true
          }
        }
        return false;
      });
      this.setState({
        teams: availableTeamsForUser
      });
    });
  };

  teamOptions = () => {
    const options = this.state.teams
      ? this.state.teams.map(team => {
          return { value: team.id.toString(), label: team.name };
        })
      : [];
    return options;
  };

  teamValue = () => {
    if (this.props.values[this.props.fieldName]) {
      return this.teamOptions().filter(team =>
        this.props.values[this.props.fieldName].includes(team.value)
      );
    }
    return [];
  };

  render() {
    return (
      <>
        <Select
          value={this.teamValue()}
          options={this.teamOptions()}
          isMulti
          isClearable
          className="react-select-container"
          classNamePrefix="react-select"
          styles={reactSelectStyle}
          onChange={e => {
            this.props.setFieldValue(
              this.props.fieldName,
              e ? e.map(team => team.value) : []
            );
          }}
        />
      </>
    );
  }
}

export default TeamSelector;
