import React from "react";
import i18n from "../i18n";
import Toast from "../../services/toasts";
import ActivityService from "../../services/data_services/activities";
import { isDesktop } from "../../services/general";

const CANCELLED_STATUS = 1;
const NEEDS_ATTENTION_STATUS = 4;

class RetryButton extends React.Component {
  handleRetry(event) {
    event.preventDefault();
    ActivityService.retryActivity(
      [this.props.activity.uuid],
      this.props.activity.application_uuid
    )
      .then(response => {
        if (response.status === 200) {
          Toast.show.retrySuccess();
        } else if (response.status === 208) {
          Toast.displayWarning('Retry_InProgress');
        } else {
          Toast.displayError(response.data.message);
        }
      })
      .catch((e, response) => {
        console.log(e)
        Toast.displayError(e);
      });
  }

  render() {
    const isDisabled =
      !this.props.editable ||
      ![CANCELLED_STATUS, NEEDS_ATTENTION_STATUS].includes(
        this.props.activity.status
      );
    if (this.props.displayRetry) {
      return (
        <button
          type="button"
          className={`ui button blue retry-button ${
            isDesktop() ? "" : "mobile"
          }`}
          disabled={isDisabled}
          onClick={event => {
            this.handleRetry(event);
          }}
        >
          {i18n.t("Retry")}
        </button>
      );
    }
    return null;
  }
}

export default RetryButton;
