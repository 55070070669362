import axios from "../axios";

class ApplicationService {
  static loadApplication = id => {
    return axios.get(`/applications/${id}`);
  };

  static getApplicationTypes = _ => {
    return axios.get(`/applications_types`);
  };

  static loadApplications = (params = null) => {
    return axios.get("/applications", {
      params
    });
  };

  static loadLauncherApplications = () => {
    return axios.get("/launcher_applications" );
  };

  static loadIndividualItemApplications = () => {
    return axios.get("/individual_item_applications");
  };

  static loadRpaProcesses = serviceId => {
    return axios.get("list_rpa_processes", {
      params: {
        service_id: serviceId
      }
    });
  };

  static checkProcessInputs = (serviceId, processName) => {
    return axios.get("check_process_inputs", {
      params: {
        service_id: serviceId,
        process_name: processName
      }
    });
  };

  static loadResourcesPools = companyId => {
    return axios.get("list_resource_pools", {
      params: {
        company_id: companyId
      }
    });
  };

  static launchProcess = (processId, data = undefined) => {
    const formData = data ? { data } : undefined;
    return axios.put(`/applications/${processId}/launch`, formData);
  };

  static loadLaunchApplications = (params = null) => {
    return axios.get("/load_launch_applications", { params });
  };
}

export default ApplicationService;
