import React from "react";
import XLSX from "xlsx";

export const ExportCsv = ({ as: Component = 'button', pagesData, fileName, disabled, className= 'mini ui button basic' }) => {
  const fileExtension = ".xlsx";

  const exportToCSV = (pagesData1, name) => {
    const wb = XLSX.utils.book_new();
    // Create sheets
    pagesData1.forEach(page => {
      // Prepare and set data.
      const data = page.data.map(dataRow => dataRow.map(dataItem => dataItem || ''));
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        page.title.length > 31
          ? page.title.slice(0, 28) + '...'
          : page.title
      );
    });

    // Trigger Download
    XLSX.writeFile(wb, name + fileExtension, { compression:true });
  }

  return (
    <Component
      type="button"
      className={className}
      onClick={() => exportToCSV(pagesData, fileName)}
      disabled={disabled}
    >
      <i className="icon cloud download" />
      CSV
    </Component>
  );
};

export default ExportCsv;
