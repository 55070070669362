import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import i18n from "../../i18n";
import FieldForm from "./FieldForm";
import FieldList from "./FieldsList";
import DocumentViewer from "./DocumentViewer";
import OCRModal from "./OCRModal";
import Toast from "../../../services/toasts";
import ActivitiesService from "../../../services/data_services/activities";
import { isDesktop } from "../../../services/general";

import "../../../../assets/stylesheets/Hyperscience/Hyperscience.scss";

const DEFAULT_ZOOM = 1;

const RequestDetail = ({ activity }) => {
  const [documentInformation, saveDocumentInformation] = useState({});
  const [currentPageFields, saveCurrentPageFields] = useState(null);
  const [allFields, setAllFields] = useState(null);
  const [nPages, setPages] = useState(1);
  const [editedFields, setEditedFields] = useState([]);
  const [dimensions, setDimensions] = useState({});
  const [page, setPage] = useState(1);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [selectedField, setSelectedField] = useState({});
  const [reviewFieldMode, setReviewFieldMode] = useState(false);
  const [fieldsToReview, setFieldsToReview] = useState(null);
  const [manualTranscription, setManualTranscription] = useState(false);
  const [modalOpen, toggleModal] = useState(false);
  const [scrollAfterZoom, setScrollAfterZoom] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const displayEdit = isDesktop();

  useEffect(() => {
    saveInitialData(activity);
    resetActivityStatus();
  }, [activity]);

  useEffect(() => {
    saveCurrentPageFields(
      activity.data[0].field_items.filter(
        x => x.page_id === activity.data[0].document.pages[page - 1]
      )
    );
    setZoom(DEFAULT_ZOOM);
  }, [page]);

  useEffect(() => {
    if (!isEmpty(selectedField) && isDesktop()) {
      scrollToSelectedField(selectedField);
    }
  }, [selectedField, reviewFieldMode]);

  useEffect(() => {
    if (scrollAfterZoom) {
      setScrollAfterZoom(false);
      scrollDocumentToField(selectedField.id);
    }
  }, [dimensions]);

  const saveInitialData = act => {
    const activityData = act.data[0];
    saveDocumentInformation(activityData.document);
    setAllFields(activityData.field_items);
    const fields = activityData.field_items.filter(
      x => x.page_id === activityData.document.pages[page - 1]
    );
    saveCurrentPageFields(fields);
    setPages(activityData.document.pages?.length || 1);
  };

  const resetActivityStatus = () => {
    setEditedFields([]);
    setDimensions({});
    setPage(1);
    setZoom(DEFAULT_ZOOM);
    setSelectedField({});
    setReviewFieldMode(false);
    setFieldsToReview(null);
    setManualTranscription(false);
  };

  const getFieldPosition = field => {
    const boxWidth =
      (field.coordinates.end_x - field.coordinates.start_x) * dimensions.width;
    const boxHeight =
      (field.coordinates.end_y - field.coordinates.start_y) * dimensions.height;
    const offsetX = field.coordinates.start_x * dimensions.width;
    const offsetY = field.coordinates.start_y * dimensions.height;

    const position = {
      startX: offsetX,
      startY: offsetY,
      boxWidth,
      boxHeight
    };

    return position;
  };

  const scrollDocumentToField = id => {
    const field = allFields.find(f => f.id === id);
    if (field !== -1) {
      const position = getFieldPosition(field);
      const leftPosition = position.startX - 200;
      const topPosition = position.startY - 300;
      const scrollOptions = {
        left: leftPosition,
        top: topPosition,
        behavior: "auto"
      };
      document.getElementById("canvasBackground").scrollTo(scrollOptions);
    }
  };

  const scrollListToField = id => {
    const scrollIntoViewOptions = {
      behavior: "auto",
      block: "center",
      inline: "start"
    };
    document
      .getElementById(`fieldDetail_${id}`)
      .scrollIntoView(scrollIntoViewOptions);
  };

  const setSelected = (id, trigger) => {
    if (id !== -1) {
      setSelectedField({ id, trigger });
    } else {
      setSelectedField({});
    }
  };

  const scrollViewer = field => {
    if (zoom < 2) {
      setZoom(2);
      setScrollAfterZoom(true);
    } else {
      scrollDocumentToField(field.id);
    }
  };

  const scrollToSelectedField = field => {
    switch (field.trigger) {
      case "list":
        scrollViewer(field);
        break;
      case "viewer":
        scrollListToField(field.id);
        break;
      case "manualTranscription":
        scrollViewer(field);
        setTimeout(() => {
          scrollListToField(field.id);
        }, 100);
        break;
      default:
        break;
    }
  };

  const endReviewField = () => {
    if (manualTranscription) {
      if (!allFields.some(f => f.state === "supervision")) {
        toggleModal(true);
      }
      setManualTranscription(false);
      setSelected(-1);
    }
    setReviewFieldMode(false);
    setSelected(-1);
  };

  const startManualTranscription = () => {
    if (!reviewFieldMode) {
      const fieldsToTranscript = allFields.filter(
        field => field.state === "supervision"
      );
      setManualTranscription(true);
      startReviewField(fieldsToTranscript);
    } else {
      endReviewField();
    }
  };

  const changeSelectedField = () => {
    const currentIndex = fieldsToReview.findIndex(
      f => f.id === selectedField.id
    );
    const newIndex = currentIndex + 1;
    if (newIndex < fieldsToReview.length) {
      if (
        fieldsToReview[currentIndex].page_id !==
        fieldsToReview[newIndex].page_id
      ) {
        const newPageIndex = documentInformation.pages.findIndex(p => {
          return p === fieldsToReview[newIndex].page_id;
        });
        setPage(newPageIndex + 1);
      }
      setSelected(fieldsToReview[newIndex].id, "manualTranscription");
    } else {
      endReviewField();
    }
  };

  const onFormSubmit = (field, value) => {
    onFieldEdit(field, value);
    changeSelectedField();
  };

  const canDoManualTranscription = () => {
    if (
      allFields.length &&
      !allFields.some(field => field.state === "supervision")
    ) {
      return false;
    }
    return true;
  };

  const startReviewField = input => {
    let toReview = [];
    if (!Array.isArray(input)) {
      toReview.push(input);
    } else {
      toReview = [...input];
    }
    const startingPage = documentInformation.pages.findIndex(p => {
      return p === toReview[0].page_id;
    });
    setPage(startingPage + 1);
    setReviewFieldMode(true);
    setFieldsToReview(toReview);
    setSelected(toReview[0].id, "manualTranscription");
  };

  const reviewFieldHandler = field => {
    if (reviewFieldMode && field.id !== selectedField.id) {
      setSelectedField(field);
    } else if (reviewFieldMode) {
      endReviewField();
    } else {
      startReviewField(field);
    }
  };

  const renderFieldInput = () => {
    if (!isEmpty(selectedField) && reviewFieldMode) {
      const field = allFields.find(f => f.id === selectedField.id);
      const fieldPosition = getFieldPosition(field);
      return (
        <div
          className="tooltipPosition"
          style={{
            transform: `translate(${
              fieldPosition.startX
            }px, ${fieldPosition.startY + fieldPosition.boxHeight + 10}px)`
          }}
        >
          <FieldForm
            field={field}
            onSkip={changeSelectedField}
            onSubmit={onFormSubmit}
          />
        </div>
      );
    }
    return null;
  };

  const updateFieldInArray = (field, array) => {
    const auxArray = [...array];
    const fieldIndexToUpdate = auxArray.findIndex(
      auxField => auxField.id === field.id
    );
    auxArray[fieldIndexToUpdate] = field;
    return auxArray;
  };

  const determineNormalizedValue = value => {
    if (typeof value === "string") {
      return value.toUpperCase();
    }
    return value;
  };

  const onFieldEdit = (field, value) => {
    const fieldToEdit = allFields.find(auxField => auxField.id === field.id);
    fieldToEdit.raw = value;
    fieldToEdit.normalized = determineNormalizedValue(value);
    fieldToEdit.state = "complete";
    fieldToEdit.source = "manual_transcription";
    const auxFieldsInformation = updateFieldInArray(fieldToEdit, allFields);
    setEditedFields(prevState => {
      return [...prevState, fieldToEdit];
    });
    setAllFields(auxFieldsInformation);
  };

  const handleSubmit = () => {
    setSubmitting(true);
    ActivitiesService.updateActivityData(activity.id, editedFields)
      .then(() => {
        setEditedFields([]);
        Toast.show.fieldsSubmitted();
      })
      .catch(() => {
        Toast.show.fieldsNotSubmitted();
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const canSubmit = () => editedFields?.length > 0;

  const onSubmitModal = () => {
    handleSubmit();
    toggleModal(false);
  };

  const interruptManualTranscription = () => {
    setManualTranscription(false);
    setReviewFieldMode(false);
    setFieldsToReview(null);
  };

  const manualTranscriptionHandler = () => {
    if (!manualTranscription) {
      startManualTranscription();
    } else {
      interruptManualTranscription();
    }
  };

  const renderInformation = () => {
    const isSubmitEnabled = canSubmit();
    const isManualTranscriptionEnabled = canDoManualTranscription();

    return (
      <React.Fragment>
        <div className="ocr-activity-container">
          <div className="requestDetail">
            {displayEdit && (
              <div className="manualTranscriptionButton">
                <button
                  disabled={!isManualTranscriptionEnabled}
                  type="button"
                  className="ui button blue"
                  onClick={() => manualTranscriptionHandler()}
                >
                  {i18n.t("ManualTranscription")}
                </button>
              </div>
            )}
            <FieldList
              fields={currentPageFields}
              selectedField={selectedField}
              setSelectedField={setSelected}
              reviewFieldHandler={reviewFieldHandler}
              scrollListToField={scrollListToField}
            />
            {displayEdit && (
              <div className="submitButtonContainer">
                <button
                  disabled={!isSubmitEnabled || submitting}
                  type="button"
                  className={`ui button blue ${submitting ? "loading" : ""}`}
                  onClick={handleSubmit}
                >
                  {i18n.t("Submit")}
                </button>
              </div>
            )}
          </div>
          <div className="documentContainer">
            <DocumentViewer
              url={documentInformation.submission_files.url}
              page={page}
              setPage={setPage}
              pages={nPages}
              scale={zoom}
              scaleDocument={setZoom}
              fields={currentPageFields}
              goToField={scrollDocumentToField}
              dimensions={dimensions}
              setDimensions={setDimensions}
              renderFieldInput={renderFieldInput}
              selectedField={selectedField}
              setSelectedField={setSelected}
            />
          </div>
          <OCRModal
            open={modalOpen}
            toggleModal={toggleModal}
            onSubmit={onSubmitModal}
          />
        </div>
      </React.Fragment>
    );
  };

  return <React.Fragment>{allFields && renderInformation()}</React.Fragment>;
};

export default RequestDetail;
