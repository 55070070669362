import React from "react";
import { Transition, Dropdown } from "semantic-ui-react";
import { isEmpty, sortBy } from "lodash";

import DashboardService from "../../services/data_services/dashboard";
import Widget from "./Widget";
import ExportPdf from "./ExportPdf";
import ExportCsv from "./ExportCsv";
import Sorter from "./Sorter";

import Toast from "../../services/toasts";
import WithLoadingComponent from "../Ui/WithLoadingComponent";

class Section extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      widgets: [],
      widgetImages: [],
      loadingWidgets: false,
      collapsed: props.index !== 0
    };

    this.addWidgetImage = this.addWidgetImage.bind(this);
    this.renderCollapseButton = this.renderCollapseButton.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.changeWidgetsOrder = this.changeWidgetsOrder.bind(this);
  }

  componentDidMount() {
    if (!this.state.collapsed) {
      this.loadWidgets();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.collapsed &&
      !this.state.collapsed &&
      isEmpty(this.state.widgets)
    ) {
      this.loadWidgets();
    }

    if (
      prevProps.companyWidgetsOrder !== this.props.companyWidgetsOrder
      || prevProps.userWidgetsOrder !== this.props.userWidgetsOrder
      && this.state.widgets.length
    ) {
      // Sort widgets.
      const sortedWidgets = this.sortWidgets(this.state.widgets);
      this.setState({ widgets: sortedWidgets });
      this.props.setSectionWidgets(this.props.section.id, sortedWidgets);
    }
  }

  loadWidgets() {
    this.setState({ loadingWidgets: true });
    DashboardService.dataForSectionByUser(this.props.section.id)
      .then(response => {
        const sortedWidgets = this.sortWidgets(response.data);
        this.setState({
          widgets: sortedWidgets,
          loadingWidgets: false,
        });
        // Set widgets for allowing saving all section's widgets order in a single user config.
        this.props.setSectionWidgets(this.props.section.id, sortedWidgets);
      })
      .catch(e => {
        Toast.show.sectionsRefreshingError(this.props.section.name);
        this.setState({ loadingWidgets: false });
      });
  }

  sortWidgets = widgets => {
    let newWidgets = sortBy(widgets, widget => {
      return this.props.companyWidgetsOrder.indexOf(`${widget.id}`);
    });
    newWidgets = sortBy(newWidgets, section => {
      return this.props.userWidgetsOrder.indexOf(`${section.id}`);
    });
    return newWidgets;
  };

  toggleCollapse() {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  }

  addWidgetImage(imageDescriptor) {
    this.setState(prevState => {
      return {
        widgetImages: [...prevState.widgetImages, imageDescriptor]
      };
    });
  }

  exportDisabled() {
    return !this.state.widgets || this.props.state.length === 0;
  }

  pagesData() {
    return this.state.widgets.map(widget => {
      return { title: widget.name, data: widget.chart_data };
    });
  }

  renderExportPdfButton() {
    if (
      !this.state.widgetImages.length ||
      this.state.widgetImages.length < this.state.widgets.length
    ) {
      return undefined;
    }

    return (
      <ExportPdf
        className="item"
        widgets={this.state.widgetImages}
        fileName={this.props.section.name}
        disabled={this.state.loadingWidgets || !this.state.widgets.length}
      />
    );
  }

  renderCollapseButton() {
    const buttonName = `angle icon ${this.state.collapsed ? "down" : "up"}`;

    return (
      <button
        className="ui mini button icon basic"
        type="button"
        disabled={this.state.editing}
        onClick={this.toggleCollapse}
      >
        <i className={buttonName} />
      </button>
    );
  }

  reloadButtonID = () => {
    return `reload-button-${this.props.section.id}`;
  };

  changeWidgetsOrder(newOrder) {
    this.setState({ widgets: newOrder }, this.saveUserSectionsOrderSetting);
    // Set widgets for allowing saving all section's widgets order in a single user config.
    this.props.setSectionWidgets(this.props.section.id, newOrder, true);
  }

  render() {
    return (
      <div className="ui segment">
        <div
          className="section-top"
          onClick={() => {
            this.toggleCollapse();
          }}
        >
          <h4 className="ui header">{this.props.section.name}</h4>
          <div onClick={e => e.stopPropagation()}>
            {!this.state.collapsed && (
              <React.Fragment>
                <Sorter
                  setNewOrder={this.changeWidgetsOrder}
                  sections={this.state.widgets}
                />
                <button
                  className="ui mini button icon basic"
                  id={this.reloadButtonID()}
                  type="button"
                  onClick={() => this.loadWidgets()}
                  disabled={this.state.loadingWidgets}
                >
                  <i className="angle icon refresh" />
                </button>
              </React.Fragment>
            )}
            <Dropdown
              icon="print"
              floating
              button
              className="icon mini basic right"
            >
              <Dropdown.Menu>
                <ExportCsv
                  as={Dropdown.Item}
                  className="item"
                  pagesData={this.pagesData()}
                  fileName={this.props.section.name}
                  disabled={
                    this.state.loadingWidgets || !this.state.widgets.length
                  }
                />
                {/*this.renderExportPdfButton()*/}
              </Dropdown.Menu>
            </Dropdown>
            {this.renderCollapseButton()}
          </div>
        </div>

        <Transition
          animation="slide down"
          visible={!this.state.collapsed}
          mountOnShow
        >
          <WithLoadingComponent
            loadingCondition={() => this.state.loadingWidgets}
            contentCondition={() => !this.state.loadingWidgets}
            noItemsKey="NoWidgetYet"
            transparent
            className="grid"
            style={{ minHeight: "unset", margin: 0 }}
          >
            {this.state.widgets
              ? this.state.widgets.map(widget => (
                <Widget
                  key={widget.id}
                  widget={widget}
                  addWidgetImage={this.addWidgetImage}
                  reloadButtonId={this.reloadButtonID()}
                />))
              : null}
          </WithLoadingComponent>
        </Transition>
      </div>
    );
  }
}

export default Section;
