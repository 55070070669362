import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Select from "react-select";
import { connect } from "react-redux";
import { Input } from "semantic-ui-react";
import { DatesRangeInput } from "semantic-ui-calendar-react-yz";
import { debounce } from "lodash";
import { getDateFormat } from "../../../services/timezones";
import i18n from "../../i18n";
import { isDesktop } from "../../../services/general";

const BACKEND_DATE_FORMAT = "DD-MM-YYYY HH:mm";

const SearchInput = ({
  column,
  filters,
  loadingItems,
  loadItems,
  value,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchBoxValue, setSearchBoxValue] = useState("");

  useEffect(() => {
    if (isLoading && !loadingItems) {
      setIsLoading(false);
    }
  }, [loadingItems]);

  useEffect(() => {
    setSearchBoxValue(value || "");
  }, [value]);

  // useEffect(() => {
  //   console.log(typeof(column))
  //   if((typeof(column) !== "undefined") )
  //     setSearchBoxValue(filters[column?.key] ? filters[columm?.key] : '');
  // }, []);

  const handleChange = object => {
    loadItems({ ...object, page: 1 });
    setIsLoading(true);
  };

  const onChangeInput = React.useCallback(
    debounce(async object => {
      try {
        await handleChange(object);
      } catch (error) {
        console.error(error);
      }
    }, 1000),
    []
  );

  const userTimezone = props.user.timezone || moment.tz.guess();
  const userDateFormat = getDateFormat(userTimezone);

  const appendDates = (date, key) => {
    const applyTimezone = d =>
      moment
        .tz(d, userDateFormat, userTimezone)
        .startOf("day")
        .utc()
        .format(BACKEND_DATE_FORMAT);

    const applyEndOfDayTimezone = d =>
      moment
        .tz(d, userDateFormat, userTimezone)
        .endOf("day")
        .utc()
        .format(BACKEND_DATE_FORMAT);

    const keyFrom = `${key}_from`;
    const keyTo = `${key}_to`;
    const splitDate = date.split(" - ");
    const from = splitDate[0] ? applyTimezone(splitDate[0]) : undefined;
    const to = splitDate[1] ? applyEndOfDayTimezone(splitDate[1]) : undefined;

    return { [keyFrom]: from, [keyTo]: to };
  };

  const formatDateForInput = (from, to) => {
    const formatDate = date =>
      moment
        .utc(date, BACKEND_DATE_FORMAT)
        .tz(userTimezone)
        .format(userDateFormat);
    if (!from && !to) return "";
    if (!to) {
      return formatDate(from);
    }
    const timezonedFrom = formatDate(from);
    const timezonedTo = formatDate(to);

    return `${timezonedFrom} - ${timezonedTo}`;
  };

  const renderDateTimePicker = ({ key, name, searchable }) => {
    const auxKey = ["updated_at", "scheduled_at"].includes(key) ? "date" : key;
    return (
      <DatesRangeInput
        fluid
        dateFormat={userDateFormat}
        name="key"
        placeholder={isDesktop() ? i18n.t("SearchDatePlaceholder") : name}
        disabled={searchable === false}
        loading={isLoading}
        value={formatDateForInput(
          filters[`${auxKey}_from`],
          filters[`${auxKey}_to`]
        )}
        iconPosition="right"
        closable
        clearable
        animation="none"
        className="table-search-input"
        allowSameEndDate
        onChange={(e, { value }) => handleChange(appendDates(value, auxKey))}
      />
    );
  };

  const renderSelectOptions = ({ key, name, options, single = false }) => {
    const auxOptions =
      options !== "users"
        ? options
        : props.users
            ?.filter(user => !user.roles.includes("super_admin"))
            ?.map(user => {
              return {
                label: user.name,
                value: user.id
              };
            }) || [];

    const selectValues = single
      ? auxOptions.find(option => {
          if (
            typeof option.value === "number" &&
            typeof filters[key] === "string"
          ) {
            return option.value.toString() === filters[key];
          }
          return option.value === filters[key];
        })
      : auxOptions.filter(option => filters[key]?.includes(option.value)) || [];

    const styles = {
      control: provided => ({
        ...provided,
        flexWrap: "no-wrap"
      })
    };

    return (
      <Select
        isClearable
        styles={styles}
        isMulti={!single}
        isLoading={isLoading}
        value={selectValues}
        options={auxOptions}
        placeholder={isDesktop() ? i18n.t("SelectPlaceholder") : name}
        onChange={selection => {
          if (selection !== null) {
            let auxValues = selection.value;
            if (!single) {
              auxValues = selection ? selection.map(v => v.value) : [];
            }
            handleChange({ [key]: auxValues });
          } else {
            handleChange({ [key]: undefined });
          }
        }}
      />
    );
  };

  const renderSearchBox = ({ key, name, searchable }) => {
    return (
      <Input
        fluid
        disabled={searchable === false}
        icon="search"
        loading={isLoading}
        value={searchBoxValue}
        placeholder={isDesktop() ? i18n.t("SearchPlaceholder") : name}
        size="small"
        className="table-search-input"
        onChange={(e, { value }) => {
          setSearchBoxValue(value);
          onChangeInput({ [key]: value });
        }}
      />
    );
  };

  switch (column.type) {
    case "select":
      return renderSelectOptions(column);
    case "datetime":
      return renderDateTimePicker(column);
    default:
      return renderSearchBox(column);
  }
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user,
    users: state.usersReducer.users
  };
};

export default connect(mapStateToProps)(SearchInput);
