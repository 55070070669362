import { LOGIN, LOGOUT } from "../constants/action-types";

export default (state = {}, action) => {
  if (action.type === LOGIN) {
    return { ...state, user: action.payload };
  }
  if (action.type === LOGOUT) {
    return { ...state, user: undefined };
  }
  return state;
};
