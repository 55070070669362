import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { debounce, cloneDeep, isEmpty } from "lodash";
import { Loader } from "semantic-ui-react";
import Toast from "../../../services/toasts";
import i18n from "../../i18n";
import TableActionButton from "../../Ui/TableActionButton";
import BasicInformation from "./BasicInformation";
import Services from "./Services";
import EngagementInfo from "./EngagementInfo";
import FormReview from "./FormReview";
import ApplicationService from "../../../services/data_services/application";
import ActivitiesService from "../../../services/data_services/activities";
import RejectModal from "./RejectModal";
import { activityDate } from "../../../services/string";

const FormIndex = props => {
  const [activityId, setActivityId] = useState(props?.activity?.id || null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [autoSaving, setAutoSaving] = useState(false);
  const [application, setApplication] = useState(null);
  const [formInfo, setFormInfo] = useState({});
  const [errorsForm, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [formLoaded, setFormLoaded] = useState(false);
  const [newClient, setNewClient] = useState(false);
  const [lastUpdate, setLastUpdate] = useState('');
  const [approvers, setApprovers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    props.setBreadcrumb([
      {
        title: "New worksheet",
        location: `/applications/${props.match.params.id}/NCW`
      }
    ]);
    loadApplication();
    loadActivityFromEdit();
  }, []);

  const loadActivityFromEdit = (fromEdit = false, data) => {
    if (props.activity?.ncw_to_json || fromEdit) {
      const { ncw_to_json } = fromEdit ? data : props.activity;
      const formData = {};
      if (ncw_to_json.client) {
        formData.client = { ...ncw_to_json.client };
      }
      if (ncw_to_json.ncw_services && ncw_to_json.ncw_services.length > 0) {
        formData.ncw_services = {
          tax_type: ncw_to_json.ncw_services[0][0].tax_type,
          ...ncw_to_json.ncw_services[0][0].data
        };
      }
      if (ncw_to_json.engagement_info) {
        formData.engagement_info = { ...ncw_to_json.engagement_info };
        delete formData.engagement_info.id;
        delete formData.engagement_info.ncw_id;
        delete formData.engagement_info.created_at;
        delete formData.engagement_info.updated_at;
      }
      if (
        props?.preview ||
        isEmpty(ncw_to_json?.client_errors) ||
        (!isEmpty(ncw_to_json?.client_errors) && ncw_to_json?.client.mac_id)
      ) {
        setCurrentStep(0);
      }
      getErrors(fromEdit ? data : props.activity);
      setApprovers(fromEdit ? data?.approvers : props?.activity?.approvers);
      setFormInfo(formData);
      setFormLoaded(true);
      setLastUpdate(fromEdit ? data?.updated_at : props?.activity?.updated_at);
    }
  };

  const autoSaveHandler = React.useCallback(
    debounce(async data => {
      try {
        await handleSave('save_as_draft', data, true);
      } catch (error) {
        console.error(error);
      }
    }, 3000),
    [application, activityId]
  );

  const loadActivityData = _ => {
    ActivitiesService.loadActivity(props.activity?.id, { page: 1 })
      .then(activityResponse => {
        loadActivityFromEdit(true, activityResponse.data);
      })
      .catch(() => {});
  };

  const loadApplication = () => {
    const applicationId = props.match.params.id;
    ApplicationService.loadApplication(applicationId)
      .then(response => {
        setApplication(response.data);
        if (props.activity?.status === 13) {
          loadActivityData();
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleCancel = () => {
    props.history.push(`/applications/${props.match.params.id}`);
  };

  const getErrors = data => {
    if (!props?.preview) {
      const tempValues = {};
      if (data?.ncw_to_json?.client_errors && !data.ncw_to_json?.client?.mac_id && Object.keys(data?.ncw_to_json?.client_errors).length > 0){
        tempValues.client = cloneDeep(data.ncw_to_json.client_errors);
        setCurrentStep(1);
      }
      if (data?.ncw_to_json?.engagement_info_errors && Object.keys(data?.ncw_to_json?.engagement_info_errors).length > 0){
        tempValues.engagement_info = cloneDeep(data.ncw_to_json.engagement_info_errors)
      }
      if (data?.ncw_to_json?.ncw_service_errors && Object.keys(data?.ncw_to_json?.ncw_service_errors).length > 0){
        tempValues.ncw_services = cloneDeep(data.ncw_to_json.ncw_service_errors)
      }
      setErrors(tempValues);
    }
  };

  const handleSave = (type, values = {}, autoSave = false) => {
    const payload = {
      application_uuid: application.uuid,
      save_type: type,
      ncw: values
    };
    setSubmitting(true);
    if (activityId) {
      ActivitiesService.updateNCW(activityId, payload)
        .then(response => {
          if (!autoSave && type !== "submit") {
            Toast.displaySuccess("Saved!");
          }
          setLastUpdate(response.data.updated_at);
          getErrors(response.data);
          setApprovers(response.data?.approvers);
          if (type === "save_and_next" && currentStep !== 0 && currentStep < 4) {
            setCurrentStep(currentStep + 1);
          }
          if (type === "submit") {
            Toast.displaySuccess("Submitted!");
            handleCancel();
          }
        })
        .catch(e => {
          console.log(e);
          Toast.displayError("Something went wrong...");
        })
        .finally(() => {
          setSubmitting(false);
          setAutoSaving(false);
        });
    } else {
      ActivitiesService.createNCW(payload)
        .then(response => {
          setActivityId(response.data.id);
          Toast.displaySuccess("Saved!");
          setLastUpdate(response.data.updated_at);
          addClientId(response.data)
          getErrors(response.data);
          if (type === "save_and_next" && (currentStep !== 0 && currentStep < 4)) {
            setCurrentStep(currentStep + 1);
          }
        })
        .catch(e => {
          Toast.displayError("Something went wrong...");
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  
  const addClientId = data =>{
    let tempValues = cloneDeep(formInfo);
    tempValues.client.id = data.ncw_to_json.client.id;
    setFormInfo(tempValues);
  }

  const handleSubmitAndApprove = message => {
    const payload = {
      application_uuid: application.uuid,
      save_type: "submit_approve",
      ncw: formInfo
    };
    if (message) {
      payload.message = message;
    }
    setSubmitting(true);
    ActivitiesService.updateNCW(activityId, payload)
      .then(() => {
        Toast.displaySuccess("Approved!");
        handleCancel();
      })
      .catch(() => {
        Toast.displayError("Something went wrong...");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const displayModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleChange = (target, newState, extraUpdate = false) => {
    if (!props?.preview) {
      if (target === "fullForm") {
        setFormInfo(newState);
        setNewClient(true);
        if (activityId) {
          setAutoSaving(true);
          autoSaveHandler(newState);
        }
      } else {
        let tempValues = cloneDeep(formInfo);
        tempValues[target] = newState;
        if(extraUpdate){
          tempValues = addMoreInfo(tempValues, extraUpdate)
        }
        setFormInfo(tempValues);
        if (activityId) {
          setAutoSaving(true);
          autoSaveHandler(tempValues);
        }
      }
    }
  };


  const addMoreInfo = (currentInfo, {formTarget,valueTarget,value,isSelect }) => {
    let tempValues = cloneDeep(currentInfo);
    if(!tempValues[formTarget]){
      tempValues[formTarget] = {}
    }
    tempValues[formTarget][valueTarget]=value
    if(isSelect){      
      tempValues[formTarget][valueTarget+'_id'] = undefined;
    }
    return tempValues
  }

  const renderLoading = () => {
    return (
      <div className="ncw-loading">
        <Loader size="medium" active inline>
          {i18n.t("Loading")}
        </Loader>
      </div>
    );
  };

  if (!application || (props.activity && !formLoaded)) {
    return renderLoading();
  }

  return (
    <React.Fragment>
      <div className="form-buttons last-update-info" >
        {lastUpdate ? autoSaving ?  <div> <Loader size='tiny' active inline /> {i18n.t("Saving")} </div>  : `Last saved at ${activityDate(lastUpdate)}` : ''}
      </div>
      <div className="form-buttons">
        <TableActionButton
          type="secondary"
          disabled={autoSaving}
          onClick={() => {
            handleCancel();
          }}
        >
          {i18n.t("Exit")}
        </TableActionButton>
        {!props?.preview && (
          <TableActionButton
            type="primary"
            disabled={isSubmitting}
            onClick={() => {
              handleSave("save_as_draft", formInfo);
            }}
          >
            {i18n.t("SaveDraft")}
          </TableActionButton>
        )}
      </div>
      <BasicInformation
        application={application}
        data={formInfo}
        errors={errorsForm}
        onChange={handleChange}
        handleSave={handleSave}
        isSubmitting={isSubmitting || autoSaving}
        collapsed={currentStep !== 1}
        currentStep={currentStep}
        preview={props?.preview}
      />
      <Services
        application={application}
        data={formInfo}
        errors={!isEmpty(formInfo?.ncw_services) && errorsForm}
        onChange={handleChange}
        handleSave={handleSave}
        newClient={newClient}
        setNewClient={setNewClient}
        isSubmitting={isSubmitting}
        collapsed={currentStep !== 2}
        currentStep={currentStep}
        preview={props?.preview}
      />
      <EngagementInfo
        application={application}
        data={formInfo}
        errors={!isEmpty(formInfo?.engagement_info) && errorsForm}
        newClient={newClient}
        onChange={handleChange}
        handleSave={handleSave}
        isSubmitting={isSubmitting}
        collapsed={currentStep !== 3}
        currentStep={currentStep}
        preview={props?.preview}
      />
      <FormReview
        application={application}
        errors={errorsForm}
        newClient={newClient}
        onChange={handleChange}
        handleSave={handleSave}
        data={formInfo}
        isSubmitting={isSubmitting}
        collapsed={currentStep !== 4}
        currentStep={currentStep}
        preview={props?.preview}
        isApprover={approvers?.indexOf(props.user.id) > -1}
        onSubmitAndApprove={displayModal}
      />
      <RejectModal
        approve
        open={showModal}
        onClose={closeModal}
        onSubmit={handleSubmitAndApprove}
        headerText={i18n.t("ApproveWorksheet")}
        infoText={i18n.t("SubmitApproveMessage")}
        buttonText={i18n.t("Approve")}
        isSubmitting={isSubmitting}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(FormIndex);
