import React from "react";

const TableColumn = props => {
  if (!props.column.show) return null;
  return props.content || <div />;
};

TableColumn.defaultProps = {
  column: {
    name: "",
    show: false,
    key: ""
  },
  content: <td />
};

export default TableColumn;
