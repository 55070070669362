export const getFieldType = configItem => {
  let fieldType = configItem.element;

  if (fieldType === "DatePicker") {
    const showTime = configItem.showTimeSelect === "true";
    const showTimeOnly = configItem.showTimeSelectOnly === "true";

    if (!showTime && !showTimeOnly) {
      fieldType = "Date";
    } else if (showTime && !showTimeOnly) {
      fieldType = "DateTime";
    } else if (showTime && showTimeOnly) {
      fieldType = "Time";
    }
  }

  return fieldType;
};
