import React from "react";
import { Popup } from "semantic-ui-react";
import I18n from "../components/i18n";
import { APPLICATION_TYPES } from "./ApplicationTypes";

import "../../assets/stylesheets/Status.scss";

const SCHEDULED_EVENT = "schedulers";

export const STATUS = [
  {
    label: I18n.t("New"),
    value: 0,
    editable: true,
    type: [
      APPLICATION_TYPES.HUMAN_IN_THE_LOOP,
      APPLICATION_TYPES.CASE_VIEW,
      SCHEDULED_EVENT
    ],
    class: "pending"
  },
  {
    label: I18n.t("Exception"),
    value: 1,
    editable: true,
    type: [APPLICATION_TYPES.HUMAN_IN_THE_LOOP, APPLICATION_TYPES.CASE_VIEW],
    class: "fail"
  },
  {
    label: I18n.t("Assigned"),
    value: 2,
    editable: true,
    type: [
      APPLICATION_TYPES.HUMAN_IN_THE_LOOP,
      APPLICATION_TYPES.CASE_VIEW,
      SCHEDULED_EVENT
    ],
    class: "locked-item"
  },
  {
    label: I18n.t("InProgress"),
    value: 3,
    editable: true,
    type: [
      APPLICATION_TYPES.HUMAN_IN_THE_LOOP,
      APPLICATION_TYPES.CASE_VIEW,
      SCHEDULED_EVENT
    ],
    class: "pending"
  },
  {
    label: I18n.t("Attention"),
    value: 4,
    editable: true,
    type: [APPLICATION_TYPES.HUMAN_IN_THE_LOOP, APPLICATION_TYPES.CASE_VIEW],
    class: "locked-item"
  },
  {
    label: I18n.t("Completed"),
    value: 5,
    editable: false,
    type: [
      APPLICATION_TYPES.HUMAN_IN_THE_LOOP,
      APPLICATION_TYPES.CASE_VIEW,
      APPLICATION_TYPES.HYPERSCIENCE,
      SCHEDULED_EVENT
    ],
    class: "success"
  },
  {
    label: I18n.t("Closed"),
    value: 6,
    editable: false,
    type: [APPLICATION_TYPES.HUMAN_IN_THE_LOOP, APPLICATION_TYPES.CASE_VIEW],
    class: null
  },
  {
    label: I18n.t("PausedDueLowerPriority"),
    value: 7,
    editable: false,
    type: SCHEDULED_EVENT,
    class: null
  },
  {
    label: I18n.t("SLAExpired"),
    value: 8,
    editable: false,
    type: SCHEDULED_EVENT,
    class: "fail"
  },
  {
    label: I18n.t("TimeoutLimit"),
    value: 9,
    editable: false,
    type: SCHEDULED_EVENT,
    class: "fail"
  },
  {
    label: I18n.t("Processing"),
    value: 10,
    editable: false,
    type: APPLICATION_TYPES.HYPERSCIENCE,
    class: "pending"
  },
  {
    label: I18n.t("ActivitiesAttention"),
    value: 11,
    editable: false,
    type: APPLICATION_TYPES.HYPERSCIENCE,
    class: "locked-item"
  },
  {
    label: I18n.t("Failed"),
    value: 12,
    editable: false,
    type: APPLICATION_TYPES.HYPERSCIENCE,
    class: "fail"
  },
  {
    label: I18n.t("Draft"),
    value: 13,
    editable: false,
    type: APPLICATION_TYPES.NCW,
    class: "pending"
  },
  {
    label: I18n.t("ApprovalPending"),
    value: 14,
    editable: false,
    type: APPLICATION_TYPES.NCW,
    class: "locked-item"
  },
  {
    label: I18n.t("Approved"),
    value: 15,
    editable: false,
    type: APPLICATION_TYPES.NCW,
    class: "success"
  },
  {
    label: I18n.t("Rejected"),
    value: 16,
    editable: false,
    type: APPLICATION_TYPES.NCW,
    class: "fail"
  }
];

export const QUEUEACTIVITYSTATUS = [
  { value: "completed", type: "success" },
  { value: "locked", type: "locked" },
  { value: "exception", type: "warning" },
  { value: "pending", type: "" }
];

export const RESOURCESTATUS = [
  { value: "working", type: "success" },
  { value: "pending", type: "exception" },
  { value: "missing", type: "fail" },
  { value: "offline", type: "fail" },
  { value: "idle", type: "" }
];

export const UNATTENDED_STATUS = [
  { value: "Completed", class: "success" },
  { value: "Locked", class: "locked-item" },
  { value: "Exception", class: "fail" },
  { value: "Pending", class: "pending" }
];

const getStatusEditables = editable => {
  return STATUS.filter(status => status.editable === editable).map(
    status => status.value
  );
};

export const EDITABLE_STATUS = getStatusEditables(true);
export const NON_EDITABLE_STATUS = getStatusEditables(false);

export const displayActivityStatus = statusNumber => {
  if (statusNumber === undefined || statusNumber === null) return null;

  const selectedStatus = STATUS.find(status => status.value === statusNumber);

  const label = selectedStatus?.label ?? null;
  const className = selectedStatus?.class ?? null;

  return <span className={`item-status ${className}`}>{label}</span>;
};

export const formatUnattendedStatus = value => {
  const selectedStatus = UNATTENDED_STATUS.find(
    status => status.value === value
  );
  const className = selectedStatus?.class ?? null;
  return <span className={`item-status ${className}`}>{value}</span>;
};

export const getValue = name => {
  let statusValue = null;
  switch (name) {
    case "Draft":
      statusValue = 13;
      break;
    case "Approval Pending":
      statusValue = 14;
      break;
    case "Approved":
      statusValue = 15;
      break;
    case "Rejected":
      statusValue = 16;
      break;
    case "supervision":
      statusValue = 11;
      break;
    case "pending":
      statusValue = [0, 3];
      break;
    case "needs_attention":
      statusValue = 4;
      break;
    default:
      statusValue = STATUS.find(element => element.label.toLowerCase() === name)
        ?.value;
      break;
  }
  return Array.isArray(statusValue) ? statusValue : [statusValue];
};

export const formatStatusToIcon = status => {
  let content = null;
  switch (status?.toLowerCase()) {
    case "completed":
      content = <i className="icon check completed" />;
      break;
    case "exception":
      content = <i className="icon flag exception" />;
      break;
    case "locked":
      content = <i className="icon lock locked" />;
      break;
    case "pending":
      content = <i className="icon ellipsis horizontal" />;
      break;
    default:
      return status;
  }

  return <Popup key={status} content={status} trigger={content} />;
};

export const formatResourcesStatus = status => {
  const className =
    RESOURCESTATUS.find(st => st.value === status.toLowerCase())?.type || "";
  return <div className={`item-status ${className}`}>{status}</div>;
};
