import React from "react";
import EmptyTable from "../EmptyTable";
import ExpandedRow from "./ExpandedRow";
import TableLoader from "../TableLoader";
import TableRow from "./TableRow";

const TableBody = props => {
  const totalColumns =
    props.columns.length + (props.selectingAll !== undefined ? 1 : 0);

  const renderBody = () => {
    return props.items.map((item, index) => (
      <TableRow
        {...props}
        index={index}
        tableId={props.tableId}
        key={item.id ? item.id : `${index}_${props.tableId}`}
        columns={props.columns}
        item={item}
        actions={props.actions}
        openModal={props.openModal}
        editField={props.editField}
        addEditedRow={props.addEditedRow}
        selectItem={props.selectItem}
        selectedItem={props.selectedItem}
      />
    ));
  };

  const renderContent = () => {
    if (!props.items || props.loadingItems) {
      return (
        <ExpandedRow totalColumns={totalColumns}>
          <TableLoader />
        </ExpandedRow>
      );
    }
    if (props.items.length === 0) {
      return (
        <ExpandedRow totalColumns={totalColumns}>
          <EmptyTable noItemsKey={props.noItemsKey} />
        </ExpandedRow>
      );
    }

    return renderBody();
  };

  return <tbody>{renderContent()}</tbody>;
};

export default TableBody;
