import React from "react";
import FieldDetail from "./FieldDetail";

const FieldsList = props => {
  return (
    <div className="ui cards">
      <div className="card">
        <div className="content">
          {props.fields.map(field => (
            <FieldDetail
              key={`field_${field.id}`}
              field={field}
              selectedField={props.selectedField}
              setSelectedField={props.setSelectedField}
              reviewField={props.reviewFieldHandler}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FieldsList;
