import axios from "../axios";

class CompanyService {
  static loadCompanies = (searchTerm = null) => {
    return axios.get("/companies", {
      params: {
        searchTerm
      }
    });
  };

  static createCompany = values => {
    return axios.post("/companies", values);
  };

  static updateCompany = (id, values) => {
    return axios.put(`/companies/${id}`, values);
  };

  static deleteCompany = id => {
    return axios.delete(`/companies/${id}`);
  };
}

export default CompanyService;
