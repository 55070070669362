import React from "react";
import i18n from "../../../i18n";

const ServicesInfo = props => {
  const serviceOptions = [
    { text: "1040 Individual Tax", value: "1040" },
    { text: "1041 Fiduciary Tax", value: "1041" }
  ];
  const printServices = services => {
    if (!props.fromForm && services?.length > 0) {
      const servicesToDisplay = services.map(s => serviceOptions.find(o => o.value === s[0].tax_type)?.text || s[0].tax_type);
      return servicesToDisplay.join(", ");
    }
    if (props.fromForm && props?.services) {
      const taxType = props.services.tax_type;
      return serviceOptions.find(o => o.value === taxType)?.text || taxType;
    }
    return null;
  };

  return (
    <div className="detail-table-container">
      <table className="detail-table">
        <thead>
          <tr>
            <th>
              <h4>{i18n.t("ScopeOfServices")}</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{printServices(props?.services)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ServicesInfo;
