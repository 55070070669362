import React, { Component } from "react";
import Select from "react-select";
import { Field } from "formik";
import TeamSelector from "./teamSelector";
import TooltipLabel from "./TooltipLabel";
import i18n from "../../i18n";
import { isDesktop } from "../../../services/general";

class NotificationSelector extends Component {
  constructor(props) {
    super(props);

    this.notificationTypeField = `${this.props.fieldName}_type`;
    this.notificationToField = `${this.props.fieldName}_notification_to`;
  }

  labelName = () => {
    if (this.props.fieldName === "sla") {
      return "SLA";
    }
    return "Timeout";
  };

  notificationOptions() {
    if (this.props.notifications) {
      return this.props.notifications.map(action => {
        return { label: action, value: action.toLowerCase() };
      });
    }

    return [];
  }

  notificationValue() {
    const result = this.notificationOptions().filter(action => {
      return (
        this.props.values[this.notificationTypeField] &&
        this.props.values[this.notificationTypeField].toLowerCase() === action.value.toLowerCase()
      );
    });

    return result;
  }

  notificationToValue = () => {
    if (
      this.props.values[this.notificationTypeField] &&
      this.props.values[this.notificationTypeField] !== "other" &&
      !this.props.values[this.notificationToField]
    ) {
      return this.props.user.email;
    }
    return this.props.values[this.notificationToField]
      ? this.props.values[this.notificationToField]
      : "";
  };

  renderTeamSelector = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TeamSelector
          values={this.props.values}
          fieldName={this.notificationToField}
          setFieldValue={this.props.setFieldValue}
          user={this.props.user}
        />
      </div>
    );
  };

  renderEmailFieldInput = () => {
    return (
      <>
        <Field
          disabled={
            this.props.values[this.notificationTypeField] === undefined ||
            this.props.values[this.notificationTypeField] !== "other"
          }
          name={this.notificationToField}
          value={this.notificationToValue()}
          type="text"
          style={{ width: "100%" }}
          onChange={e => {
            this.props.setFieldValue(this.notificationToField, e.target.value);
          }}
        />
      </>
    );
  };

  renderNotificationField = () => {
    if (this.props.values[this.notificationTypeField] === "team(s)") {
      return this.renderTeamSelector();
    }
    return this.renderEmailFieldInput();
  };

  render() {
    return (
      <div className={isDesktop() ? "inline fields" : "field"}>
        <TooltipLabel
          label={i18n.t(`${this.labelName()} notification`)}
          content={this.props.tooltipContent}
          isRequired={this.props.isRequired}
        />
        <div className="field full-width">
          <Select
            value={this.notificationValue()}
            options={this.notificationOptions()}
            disabled={this.notificationOptions() === []}
            onChange={option => {
              this.props.setFieldValue(
                this.notificationTypeField,
                option ? option.value : null
              );
              this.props.setFieldValue(this.notificationToField, null);
            }}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
        <div className="field full-width">
          {this.renderNotificationField()}
        </div>
      </div>
    );
  }
}

export default NotificationSelector;
