import React, { useState, useEffect } from "react";
import RadioInput from "./RadioInput";

const FieldForm = props => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(props.field.raw);
  }, [props]);

  const onSave = () => {
    props.onSubmit(props.field, inputValue);
  };

  const returnInputField = () => {
    let input = "";
    let value;
    switch (props.field.data_type) {
      case "Checkbox":
        input = (
          <div className="radioFieldContainer">
            <RadioInput
              name={props.field.name}
              className="radioInput"
              value={inputValue}
              options={[
                { label: "Checked", value: "True" },
                { label: "Not Checked", value: "False" }
              ]}
              onChange={e => setInputValue(e.target.value)}
            />
          </div>
        );
        break;
      case "Signature":
        input = (
          <div className="radioFieldContainer">
            <RadioInput
              name={props.field.name}
              className="radioInput"
              value={inputValue}
              options={[
                { label: "Signed", value: "True" },
                { label: "Not Signed", value: "False" }
              ]}
              onChange={e => setInputValue(e.target.value)}
            />
          </div>
        );
        break;
      case "US Zip Code":
      case "Cell Number":
      case "Phone Number":
      case "Numeric Text":
        value = isNaN(parseInt(inputValue.split(" ").join(""), 0)) ? undefined : parseInt(inputValue.split(" ").join(""), 0);
        input = (
          <div className="inputText">
            <div className="fieldLabel">{props.field.name}</div>
            <input
              type="number"
              value={value}
              className="formInput"
              onChange={e => setInputValue(e.target.value)}
            />
          </div>
        );
        break;
      default:
        input = (
          <div className="inputText">
            <div className="fieldLabel">{props.field.name}</div>
            <input
              type="text"
              value={inputValue}
              className="formInput"
              onChange={e => setInputValue(e.target.value)}
            />
          </div>
        );
        break;
    }
    return input;
  };

  return (
    <div className="tooltipContainer">
      {returnInputField()}
      <div
        id="buttonContainer"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "5px"
        }}
      >
        <button className="ui button blue basic"
          onClick={() => props.onSkip()}>
          Skip
        </button>
        <button className="ui button blue"
          onClick={() => onSave()}>
          Save
        </button>
      </div>
    </div>
  )
};

export default FieldForm;
