import React from "react";
import i18n from "../../../i18n";

const RiskDetail = props => {
  const renderElement = (label, value) => {
    return (
      <React.Fragment>
        <th>{label}</th>
        <td>{value}</td>
      </React.Fragment>
    );
  };
  return (
    <div className="detail-table-container">
      <table className="detail-table">
        <thead>
          <tr>
            <th colSpan={4}>
              <h4>{i18n.t("Risk")}</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {renderElement(i18n.t("RiskLevel"), "Level 1")}
            {renderElement(i18n.t("TotalValue"), "$75K")}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RiskDetail;
