import React from "react";
import i18n from "../../../i18n";
import UserService from "../../../../services/data_services/user";
import UsersForm from "./UsersForm";
import TeamService from "../../../../services/data_services/team";
import WithLoadingComponent from "../../../Ui/WithLoadingComponent";
import { checkAndRedirect } from "../../../../services/axios";

class UsersEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: undefined
    };

    this.setBreadcrumb();

    UserService.loadUser(this.props.match.params.id)
      .then(result => {
        this.setState(
          {
            user: result.data
          },
          this.setBreadcrumb
        );
      })
      .catch(checkAndRedirect);

    TeamService.loadTeams().then(result => {
      this.setState({
        teams: result.data
      });
    });
  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Users"),
        location: "/admin/users"
      },
      {
        title: this.state.user
          ? `${i18n.t("User")} - ${this.state.user.name}`
          : `${i18n.t("User")} - ${this.props.match.params.id}`,
        location: `/admin/users/${this.props.match.params.id}`
      }
    ]);
  }

  render() {
    return (
      <WithLoadingComponent
        loadingCondition={() => !this.state.user}
        contentCondition={() => this.state.user.id}
        noItemsKey="NotAbleLoadUser"
      >
        <UsersForm user={this.state.user} />
      </WithLoadingComponent>
    );
  }
}

export default UsersEdit;
