import React from "react";
import { Redirect } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import { VisitableComponents, Actions } from "../services/detailedPermissionRules";
import Permissions from "../services/permissions";

const AppIndex = props => {

  const checkPermissions = (target, component )=> {
    if(props?.user?.company?.access_to[target] === "true")
      return component
    return <Redirect to={{pathname: "/403", state:{noPermitted: true}}} />
  }

  return (
    <Permissions 
      component={VisitableComponents.Companies}
      actions={Actions.manage}
      yes={() => {
        return <Redirect to={"/companies"}/>
      }}
      no={() => checkPermissions( 'dashboard',  <Dashboard {...props}/> )}
    />
  )
}

export default AppIndex;
