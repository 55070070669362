import React, { useState, useEffect } from "react";
import { cloneDeep, isEmpty } from "lodash";
import { Grid, Radio } from "semantic-ui-react";
import i18n from "../../i18n";
import StepContainer from "./StepContainer";
import InputField from "../../Ui/InputField";
import InputDropdown from "../../Ui/InputDropdown";
import TableActionButton from "../../Ui/TableActionButton";
import NcwService from "../../../services/data_services/ncw";

// const TaxOptions = [
//   { id: undefined, key: '1040_2020', text: '1040 Individual Tax (2020)', value: '1040_2020' },
//   { id: undefined, key: '1041_2020', text: '1041 Fiduciary Tax (2020)', value: '1041_2020' },
//   { id: undefined, key: '1040_2021', text: '1040 Individual Tax (2021)', value: '1040_2021' },
//   { id: undefined, key: '1041_2021', text: '1041 Fiduciary Tax (2021)', value: '1041_2021' }
// ]

const requiredFields = [
  "tax_type",
  "first_year",
  "second_year",
  "wip_trigger",
  "wip_limit"
];

const Services = props => {
  const [existingClient, setExistingClient] = useState(false);
  const [loadingSoftware, setLoadingSoftware] = useState(false);
  const [softwareTypeOptions, setSoftwareTypeOptions] = useState({
    fullList: [],
    dummyList: []
  });
  const [loadingEntityTypes, setLoadingEntityTypes] = useState(false);
  const [entityTypeOptions, setEntityTypeOptions] = useState({
    fullList: [],
    dummyList: []
  });
  const [formFields, setFormFieldsValue] = useState({
    new_sharefile: true,
    preparation_tax: true
  });

  useEffect(() => {
    if (!isEmpty(props?.data) && !isEmpty(props?.data?.ncw_services)) {
      prepareValuesFromEdit();
    }
  }, [props?.data?.ncw_services?.tax_type]);

  useEffect(() => {
    if (loadingSoftware) {
      loadSoftwareType();
    }
  }, [loadingSoftware]);

  useEffect(() => {
    if (loadingEntityTypes) {
      loadEntityTypes();
    }
  }, [loadingEntityTypes]);

  useEffect(() => {
    if (props.newClient) {
      if (props.data?.ncw_services) {
        const auxFormFields = {
          tax_software_type: props.data.ncw_services?.tax_software_type,
          new_sharefile: true,
          preparation_tax: true
        };
        setFormFieldsValue(auxFormFields);
        setExistingClient(props.data.client?.mac_id ? true : false);
      } else {
        setFormFieldsValue({
          new_sharefile: true,
          preparation_tax: true
        });
        setExistingClient(false);
      }
    }
    props.setNewClient(false);
  }, [props.newClient]);

  const onlyNumber = (value) => {
    if (!value) return value;
    return value.replace(/[^\d]/g, '');
  };

  const prepareValuesFromEdit = () => {
    if (props?.data?.ncw_services) {
      const ncwServices = { ...props.data.ncw_services };
      ncwServices.new_sharefile = ncwServices?.new_sharefile === "true";
      ncwServices.preparation_tax = ncwServices?.preparation_tax === "true";
      setFormFieldsValue(ncwServices);
      if (props?.data?.client?.mac_id) {
        setExistingClient(true);
      }
    }
  };

  const loadSoftwareType = () => {
    NcwService.getSoftwareTypes(props.application.uuid)
      .then(response => {
        const auxOptions = response.data.map(item => {
          return {
            key: item.CatUnique,
            id: item.CatUnique,
            text: item.CatName,
            value: item.CatName
          };
        });
        setSoftwareTypeOptions({fullList:auxOptions, dummyList:auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingSoftware(false);
      });
  };

  const loadEntityTypes = () => {
    NcwService.getClientEntities(props.application.uuid)
      .then(({ data }) => {
        const auxOptions = data.map(item => {
          return {
            key: item.ClientEntityTypeIdx,
            id: item.ClientEntityTypeIdx,
            text: item.ClientEntityTypeName,
            value: item.ClientEntityTypeName
          };
        });
        setEntityTypeOptions({ fullList: auxOptions, dummyList: auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingEntityTypes(false);
      });
  };

  const onChangeField = (target, value) => {
    const tempValues = cloneDeep(formFields);
    tempValues[target] = value;
    setFormFieldsValue(tempValues);
    props.onChange('ncw_services', tempValues);
  };

  const onSelectChangeField = (target, value, linked = false) => {
    const tempValues = cloneDeep(formFields);
    tempValues[target] = value.value;
    tempValues[target+'_id'] = value.options.find(o => o.value === value.value)?.id;
    setFormFieldsValue(tempValues);
    let externalForm = !linked ? false : {
      formTarget: "engagement_info",
      valueTarget: "engagement_type",
      value: value.value,
      isSelect: true,
    }
    props.onChange('ncw_services', tempValues, externalForm);
  };

  const stepContent = () => {
    return (
      <React.Fragment>
        <Grid columns={4} divided>
          <Grid.Row stretched>
            <Grid.Column>
              <InputDropdown
                inputlabel={i18n.t("ServicesToApply")}
                placeholder={i18n.t("StartTyping")}
                multiple
                selection
                options={[{ key: 'Tax', text: 'Tax', value: 'Tax' }]}
                value={['Tax']}
                disabled
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className='Form-section' stretched >
            <Grid.Column>
              <div className='section-info tex-info'>{i18n.t("TaxCC")}</div>
              <InputDropdown
                onClick={() => {
                  if (!loadingSoftware && softwareTypeOptions?.fullList?.length === 0) {
                    setLoadingSoftware(true);
                  } else {
                    setSoftwareTypeOptions({
                      fullList: softwareTypeOptions?.fullList,
                      dummyList: softwareTypeOptions?.fullList
                    });
                  }
                }}
                required
                disabled={props.preview || existingClient}
                loading={loadingSoftware}
                inputlabel={i18n.t("TaxSoftwareType")}
                placeholder={i18n.t("StartTyping")}
                options={softwareTypeOptions?.dummyList}
                search
                selection
                value={formFields?.tax_software_type}
                onChange={(e, select) => {
                  onSelectChangeField('tax_software_type', select);
                  setSoftwareTypeOptions({ fullList: softwareTypeOptions?.fullList, dummyList: [] });
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <InputDropdown
                onClick={() => {
                  if (!loadingEntityTypes && entityTypeOptions?.fullList?.length === 0) {
                    setLoadingEntityTypes(true);
                  } else {
                    setEntityTypeOptions({ fullList: entityTypeOptions?.fullList, dummyList: entityTypeOptions?.fullList });
                  }
                }}
                required
                error={props.errors?.ncw_services?.tax_type}
                disabled={props.preview}
                loading={loadingEntityTypes}
                noResultsMessage={i18n.t("Try another search.")}
                inputlabel={i18n.t("EntityType")}
                placeholder={i18n.t("StartTyping")}
                options={entityTypeOptions?.dummyList}
                search
                selection
                value={formFields?.tax_type}
                onChange={(e, value) => {
                  onSelectChangeField("tax_type", value);
                  setEntityTypeOptions({ fullList: entityTypeOptions?.fullList, dummyList: [] });
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column>
              <div className='question-container'>
                <span>{i18n.t("NewSharefile")}</span>
                <div>
                  <Radio
                    label='Yes'
                    disabled={props.preview}
                    checked={formFields?.new_sharefile === true}
                    onChange={(e,  { value }) => onChangeField('new_sharefile', true )}
                  />
                  <Radio
                    label='No'
                    disabled={props.preview}
                    checked={formFields?.new_sharefile === false}
                    onChange={(e,  { value }) =>onChangeField('new_sharefile', false )}
                  />
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className='question-container'>
                <span>{i18n.t("TaxReturns")}​</span>
                <div>
                  <Radio
                    label='Yes'
                    disabled={props.preview}
                    checked={formFields?.preparation_tax === true}
                    onChange={(e, { value }) => onChangeField('preparation_tax', true )}
                  />
                  <Radio
                    label='No'
                    disabled={props.preview}
                    checked={formFields?.preparation_tax === false}
                    onChange={(e, { value }) => onChangeField('preparation_tax', false )}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column>
              <InputField
                required
                error={ props.errors?.ncw_services?.first_year}
                disabled={props.preview}
                inputlabel={i18n.t("FirstYear")}
                value={formFields?.first_year}
                onChange={(e,  { value }) => onChangeField('first_year', onlyNumber(value))}
                icon='dollar sign' 
                iconPosition='left'
              />
            </Grid.Column>
            <Grid.Column>
              <InputField
                required
                error={props.errors?.ncw_services?.second_year}
                disabled={props.preview}
                inputlabel={i18n.t("SecondYear")}
                value={formFields?.second_year}
                onChange={(e, { value }) =>onChangeField('second_year', onlyNumber(value) )}
                icon='dollar sign' 
                iconPosition='left'
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column>
              <InputField
                required
                error={props.errors?.ncw_services?.wip_trigger}
                disabled={props.preview}
                inputlabel={i18n.t("WIPTrigger")}
                value={formFields?.wip_trigger}
                onChange={(e, { value }) => onChangeField('wip_trigger', onlyNumber(value) )}
                icon='dollar sign'
                iconPosition='left'
              />
            </Grid.Column>
            <Grid.Column>
              <InputField
                required
                error={props.errors?.ncw_services?.wip_limit}
                disabled={props.preview}
                inputlabel={i18n.t("WIPLimit")}
                value={formFields?.wip_limit}
                onChange={(e, { value }) => onChangeField('wip_limit', onlyNumber(value))}
                icon='dollar sign'
                iconPosition='left'
              />
            </Grid.Column>
            <Grid.Column>
              <InputField
                required
                error={props.errors?.ncw_services?.ar_limit}
                disabled={props.preview}
                inputlabel={i18n.t("ARLimit")}
                value={formFields?.ar_limit}
                onChange={(e,  { value }) =>onChangeField('ar_limit', onlyNumber(value) )}
                icon='dollar sign'
                iconPosition='left'
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  };

  const readyForSubmit = (data, collection) => {
    const reqFields = [...collection];
    let filled = 0;
    for(let i=0; i < reqFields.length; i++){
      if(data && data[reqFields[i]] && data[reqFields[i]] !== ""){
        filled++
      } else {
        return false;
      }
    }
    return filled === reqFields.length;
  };

  return (
    <StepContainer
      collapsed={props.collapsed}
      stepNumber="2"
      stepTitle="Services"
      disabled={props.currentStep === 1}
      new={isEmpty(props.data?.ncw_services)}
      completed={
        !isEmpty(props.data?.ncw_services) &&
        isEmpty(props.errors?.ncw_services) &&
        readyForSubmit(formFields, requiredFields)
      }
    >
      {stepContent()}
      <div className="action-section">
        {!props.preview && (
          <TableActionButton
            type="primary"
            disabled={!readyForSubmit(formFields, requiredFields)}
            loading={props.isSubmitting}
            onClick={() => {
              props.handleSave("save_and_next", { ncw_services: formFields });
            }}
          >
            {i18n.t("SaveNext")}
          </TableActionButton>
        )}
      </div>
    </StepContainer>
  );
};

export default Services;
