import React from "react";
import { connect } from "react-redux";
import getUuidByString from "uuid-by-string";
import { isEqual, some, intersection } from "lodash";
import i18n from "../../i18n";
import ActivitiesService from "../../../services/data_services/activities";
import EventsService from "../../../services/data_services/events";
import Table from "../../Ui/Table/Table";
import LaunchModal from "../../LaunchProcess/LaunchModal";
import EventModal from "./EventModal";
import ActivityCards from "../Cards/ActivityCards";
import {
  activityColumns,
  OCRColumns,
  NcwColumns,
  unattendedColumns
} from "./EventTable";
import { loadFilters, loadUsers, deleteFilters } from "../../../actions/index";
import { TYPES } from "../../Ui/Table/types";
import Toast from "../../../services/toasts";
import ApplicationService from "../../../services/data_services/application";
import UserService from "../../../services/data_services/user";
import { searchParamFromUrl, urlFromSearchParam } from "../../../services/url";
import { checkAndRedirect } from "../../../services/axios";
import { NON_EDITABLE_STATUS } from "../../../constants/status";
import { APPLICATION_TYPES } from "../../../constants/ApplicationTypes";

class EventsIndex extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = searchParamFromUrl(this.props.location.search);

    this.state = {
      application: undefined,
      loadingActivities: true,
      columns: [],
      activities: undefined,
      nPages: 0,
      modalVisible: false,
      selectAllDisabled: false,
      selectedActivitiesIds: [],
      queryParams,
      stats: {},
      selectingAll: false,
      keepFilters: false
    };

    this.setBreadcrumb();

    this.selectActivity = this.selectActivity.bind(this);
    this.selectAllElements = this.selectAllElements.bind(this);
    this.deselectAllElements = this.deselectAllElements.bind(this);
    this.loadActivities = this.loadActivities.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.setQueryParams = this.setQueryParams.bind(this);
    this.handleSelectAllChange = this.handleSelectAllChange.bind(this);
    this.deleteAction = this.deleteAction.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    this.loadUsers();
    this.loadApplicationTypes();
    this.setActionsDisabled();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.reloadActivities();
    }

    if (!isEqual(prevState.queryParams, this.state.queryParams)) {
      this.props.history.push(`?${urlFromSearchParam(this.state.queryParams)}`);
      this.loadActivities();
    }

    if (prevState.selectedActivitiesIds !== this.state.selectedActivitiesIds) {
      this.setActionsDisabled();
    }
  }

  componentWillUnmount() {
    if (!this.state.keepFilters) {
      this.props.deleteFilters();
    }
  }

  loadApplicationTypes = () => {
    ApplicationService.getApplicationTypes()
      .then(response => {
        this.setState(
          { applicationTypes: response.data },
          this.loadApplication()
        );
      })
      .catch(() => this.setState({ applicationTypes: [] }));
  };

  setStartingFilters = () => {
    const searchParams = searchParamFromUrl(this.props.location.search);
    const { uuid } = this.state.application;

    const { filters } = this.props;
    const auxFilters = {
      ...filters[uuid],
      ...searchParams
    };

    if (isEqual(this.state.queryParams, auxFilters)) {
      this.loadActivities();
    }
    this.setState({ queryParams: auxFilters });
  };

  tableUuid() {
    return getUuidByString(
      `${TYPES.APPLICATION_SHOW}${this.props.match.params.id}`
    );
  }

  setBreadcrumb() {
    const applicationTitle = this.state.application
      ? `${this.state.application.name}`
      : `${i18n.t("Application")}`;

    this.props.setBreadcrumb([
      {
        title: `${applicationTitle}`,
        location: "/activities"
      }
    ]);
  }

  loadUsers = () => {
    UserService.loadUsers({ page: 0 }).then(response => {
      this.props.onLoadUsers(response.data.results);
    });
  };

  loadApplication = () => {
    const applicationId = this.props.match.params.id;

    ApplicationService.loadApplication(applicationId)
      .then(response => {
        const applicationData = { ...response.data };

        this.setState(
          {
            application: {
              ...applicationData,
              applicationType: this.mapTypeIdToApplicationType(
                applicationData.type_id
              )
            }
          },
          () => {
            this.setBreadcrumb();
            this.setColumns();
          }
        );
      })
      .catch(checkAndRedirect);
  };

  mapTypeIdToApplicationType = typeId => {
    try {
      const applicationType = this.state.applicationTypes.find(
        appType => appType.id === typeId
      );
      return applicationType.name;
    } catch {
      return APPLICATION_TYPES.CASE_VIEW;
    }
  };

  saveBrowserFilters = (key, value) => {
    const newFilters = {
      [key]: value
    };
    const stringifiedFilters = JSON.stringify(newFilters);
    window.sessionStorage.setItem("filters", stringifiedFilters);
  };

  setColumns = () => {
    if (this.state.application) {
      let columns = null;
      const applicationType = this.state.application?.applicationType;
      switch (applicationType) {
        case APPLICATION_TYPES.UNATTENDED:
          columns = unattendedColumns;
          break;
        case APPLICATION_TYPES.NCW:
          columns = NcwColumns;
          break;
        case APPLICATION_TYPES.HYPERSCIENCE:
          columns = OCRColumns;
          break;
        default:
          columns = activityColumns;
          break;
      }
      this.setState(
        {
          columns
        },
        () => {
          const { filters } = this.props;
          if (filters[this.state.application.uuid]) {
            this.setStartingFilters();
          } else if (
            applicationType === APPLICATION_TYPES.NCW &&
            !filters?.[this.state.application.uuid]?.creator
          ) {
            this.setQueryParams("creator", [this.props.user.id]);
          } else {
            this.loadActivities();
          }
        }
      );
    }
    return [];
  };

  loadExternalActivities = () => {
    this.setState({ loadingActivities: true });
    const serviceId = this.state.application.process_entities[0].service_id;
    const query = {
      ...this.state.queryParams,
      service_id: serviceId,
      queue: this.state.application.process_entities[0].rpa_queues[0]
    };

    ActivitiesService.loadExternalActivities(query)
      .then(response => {
        if (response.status === 204) {
          this.setState({ activities: [] });
        } else {
          this.setStats(response.data);
          this.setState({
            totalItems: response.data.hits,
            activities: response.data.results,
            nPages: response.data.total
          });
        }
      })
      .catch(() => this.setState({ activities: [] }))
      .finally(() => this.setState({ loadingActivities: false }));
  };

  loadActivities = () => {
    this.setState({ loadingActivities: true }, () => {
      const { uuid } = this.state.application;
      this.props.saveFilters(uuid, this.state.queryParams);
      this.saveBrowserFilters(uuid, this.state.queryParams);
      if (
        this.state.application.applicationType === APPLICATION_TYPES.UNATTENDED
      ) {
        this.loadExternalActivities();
      } else {
        this.loadInternalActivities();
      }
    });
  };

  loadInternalActivities = () => {
    if (this.state.application) {
      const query = {
        ...this.state.queryParams,
        cards: null,
        application_uuids: [this.state.application.uuid]
      };

      ActivitiesService.searchActivities(query)
        .then(response => {
          const { data } = response;

          this.setStats(data);

          this.setState({
            activities: data.results,
            nPages: data.total,
            totalItems: data.hits
          });
        })
        .catch(() => {
          this.setState({
            activities: [],
            nPages: 0
          });
        })
        .finally(() => {
          this.setState({ loadingActivities: false });
        });
    }
  };

  reloadActivities = () => {
    this.setState(
      {
        activities: undefined
      },
      this.loadActivities()
    );
  };

  setStats = data => {
    const { results, total, ...stats } = data;
    this.setState({ stats });
  };

  clearQueryParams = (status = {}) => {
    const entriesWithoutCards = status;
    entriesWithoutCards.description = undefined;
    entriesWithoutCards.assigned_to = undefined;
    entriesWithoutCards.priority = undefined;
    entriesWithoutCards.page = 1;

    Object.keys(entriesWithoutCards).forEach(key => {
      this.setQueryParams(key, entriesWithoutCards[key]);
    });
  };

  selectActivity = activity => {
    const isUnattended =
      this.state.application.applicationType === APPLICATION_TYPES.UNATTENDED;
    this.setState(prevState => {
      const activityEntry = {
        id: activity.id,
        uuid: isUnattended ? activity.uid : activity.uuid,
        status: activity.status
      };
      const currentActivities = [...prevState.selectedActivitiesIds];
      if (!some(currentActivities, activityEntry)) {
        currentActivities.push(activityEntry);
      }

      return {
        selectedActivitiesIds: currentActivities
      };
    });
  };

  deselectActivity = activity => {
    this.setState(prevState => {
      const currentActivities = [...prevState.selectedActivitiesIds];

      return {
        selectedActivitiesIds: currentActivities.filter(
          currentActivity => currentActivity.id !== activity.id
        )
      };
    });
  };

  onCheckboxChange = activity => {
    const selectedElementIds = this.state.selectedActivitiesIds.map(
      element => element.id
    );
    const activityIsSelected = selectedElementIds.includes(activity.id);

    if (!activityIsSelected) {
      this.selectActivity(activity);
    } else {
      this.deselectActivity(activity);
    }
  };

  selectAllElements = () => {
    this.setState(
      {
        selectAllDisabled: true
      },
      () => {
        let query = {};
        let loadFunction;

        const isUnattended =
          this.state.application.applicationType ===
          APPLICATION_TYPES.UNATTENDED;

        if (isUnattended) {
          loadFunction = ActivitiesService.selectAllExternals;
          query = {
            ...this.state.queryParams,
            service_id: this.state.application.process_entities[0].service_id,
            queue: this.state.application.process_entities[0].rpa_queues[0]
          };
        } else {
          loadFunction = ActivitiesService.searchActivities;
          query.application_uuids = [this.state.application.uuid];
        }
        query.page = 0;

        loadFunction(query)
          .then(response => {
            const { data } = response;

            const auxData = isUnattended ? data : data.results;

            const currentActivitiesIds = auxData.map(activity => {
              return {
                id: activity.id,
                uuid: isUnattended ? activity.uid : activity.uuid,
                status: activity.status
              };
            });

            this.setState({
              selectedActivitiesIds: currentActivitiesIds,
              selectAllDisabled: false
            });
          })
          .catch(() => {
            this.setState({
              selectAllDisabled: false
            });
          })
          .finally(() => {
            this.setState({ selectingAll: false });
          });
      }
    );
  };

  deselectAllElements = () => {
    this.setState({
      selectedActivitiesIds: []
    });
  };

  setModal(visible) {
    this.setState({
      modalVisible: visible
    });
  }

  handleCreate() {
    switch (this.state.application.trigger_type) {
      case "NCW":
        this.props.history.push({
          pathname: `${this.props.match.url}/NCW`
        });
        break;
      case "Form":
      case "Conf::Inbox":
        this.setModal(true);
        break;
      default:
        ApplicationService.launchProcess(this.state.application.id)
          .then(() => {
            Toast.show.processLaunched();
          })
          .catch(() => {
            Toast.show.processNotLaunched();
          });
        break;
    }
  }

  setQueryParams(key, value) {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  }

  onSearch = entries => {
    const entriesWithoutCards = { ...entries };
    entriesWithoutCards.cards = null;
    entriesWithoutCards.date = null;

    Object.keys(entriesWithoutCards).forEach(key => {
      this.setQueryParams(key, entriesWithoutCards[key]);
    });
    // Always force page 1 on search.
    this.setQueryParams("page", 1);
  };

  onSelectAllClick = event => {
    const value = event.target.checked;

    if (value) {
      this.setState({ selectingAll: true }, () => {
        this.selectAllElements();
      });
    } else {
      this.deselectAllElements();
    }
  };

  selectAllChecked = () => {
    const { activities, selectedActivitiesIds } = this.state;
    const isUnattended =
      this.state.application.applicationType === APPLICATION_TYPES.UNATTENDED;

    const elements = activities || [];
    const elementIds = elements.map(element =>
      isUnattended ? element.uid : element.id
    );
    const selectedElementIds = selectedActivitiesIds.map(element =>
      isUnattended ? element.uuid : element.id
    );

    const inter = intersection(elementIds, selectedElementIds);

    return selectedElementIds.length > 0 && elementIds.length === inter.length;
  };

  onRowClick = element => {
    this.setState({ keepFilters: true }, () => {
      if (
        this.state.application.applicationType === APPLICATION_TYPES.UNATTENDED
      ) {
        this.props.history.push({
          pathname: `/applications/${this.state.application.id}/external`,
          state: { uuid: element.id, attempt: element.attempt }
        });
      } else if (
        (element.status === 13 && this.props.user.id === element.creator) ||
        (element.status === 13 &&
          element.approvers?.indexOf(this.props.user.id) >= 0)
      ) {
        this.props.history.push({
          pathname: `/applications/${this.state.application.id}/NCW`,
          state: { id: element.id, status: 13 }
        });
      } else if (element.status !== 10) {
        this.props.history.push(
          `${this.props.match.url}/activities/${element.id}`
        );
      }
    });
  };

  setActionsDisabled() {
    this.setState({
      retryDisabled: this.editDisabled(),
      deleteDisabled: this.deleteDisabled(),
      exportDisabled: this.exportDisabled()
    });
  }

  selectAllChecked() {
    const elements = this.state.activities ? this.state.activities : [];
    const elementIds = elements.map(element => element.id);
    const selectedElementIds = this.state.selectedActivitiesIds.map(
      element => element.id
    );

    const inter = intersection(elementIds, selectedElementIds);

    return elements.length > 0 && elementIds.length === inter.length;
  }

  handleSelectAllChange(event) {
    const value = event.target.checked;

    if (value) {
      this.setState({ selectingAll: true }, () => {
        this.selectAllElements();
      });
    } else {
      this.deselectAllElements();
    }
  }

  handleRetry = () => {
    ActivitiesService.retryActivity(
      this.state.selectedActivitiesIds.map(element => element.uuid),
      this.state.application.uuid
    )
      .then(response => {
        if (response.status === 200) {
          Toast.show.retrySuccess();
        } else if (response.status === 304) {
          Toast.show.retryCouldNotBeDone();
        } else {
          Toast.show.retryFailed();
        }
      })
      .catch(() => {
        Toast.show.retryFailed();
      });
  };

  deleteAction(selectedElementIds, loadFunction) {
    ActivitiesService.deleteActivity(
      selectedElementIds.map(element => element.uuid)
    )
      .then(response => {
        if (response.status >= 200 || response.status < 300) {
          Toast.show.itemsDeleted();
          setTimeout(() => {
            loadFunction();
          }, 1000);
        }
      })
      .catch(() => {
        Toast.show.deleteFailed();
      });
  }

  handleDelete = () => {
    this.showModal();
  };

  activityExportList = _ => {
    const isUnattended =
      this.state.application.applicationType === APPLICATION_TYPES.UNATTENDED;

    return this.selectAllChecked() &&
      this.state.selectedActivitiesIds.length > 1000
      ? ""
      : this.state.selectedActivitiesIds.map(element =>
          isUnattended ? element.uuid : element.id
        );
  };

  getQueryOptionsList = _ => {
    return this.selectAllChecked() &&
      this.state.selectedActivitiesIds.length < 1000
      ? {}
      : this.state.queryParams;
  };

  handleExport = () => {
    EventsService.exportEvents(
      this.activityExportList(),
      [this.state.application.uuid],
      this.getQueryOptionsList()
    )
      .then(response => {
        if (response.data?.type === "toast") {
          Toast.displaySuccess(response.data.message);
        }
        if (response.data?.type === "link") {
          this.setState({
            downloadExportUrl: response.data.message
          });
          const link = document.getElementById("downloadExportUrl");
          link.click();
        }
      })
      .catch(() => {
        Toast.show.exportFailed();
      });
  };

  showModal() {
    this.setState({
      showModal: true
    });
  }

  hideModal() {
    this.setState({
      showModal: false
    });
  }

  elementsEmpty() {
    return this.state.selectedActivitiesIds.length === 0;
  }

  teams() {
    return Array.isArray(this.props.user.teams) ? this.props.user.teams : [];
  }

  editDisabled() {
    const isEmpty = this.elementsEmpty();
    const teamEdit = this.teams().some(team => team.allow_edit);

    const statusEdit =
      this.state.selectedActivitiesIds.filter(app => {
        return NON_EDITABLE_STATUS.includes(app.status);
      }).length === 0;

    return isEmpty || !teamEdit || !statusEdit;
  }

  deleteDisabled() {
    const allowed = this.teams().some(team => team.allow_delete);
    return !allowed || this.elementsEmpty();
  }

  selectAllDisabled() {
    return this.state.selectAllDisabled;
  }

  selectAllClasses() {
    return `ui checkbox ${this.selectAllDisabled() ? "disabled" : ""}`;
  }

  exportDisabled() {
    return this.elementsEmpty();
  }

  render() {
    const createAction =
      this.state.application?.applicationType !==
        APPLICATION_TYPES.UNATTENDED &&
      this.state.application?.create_button &&
      this.handleCreate;
    const displayDelete =
      this.state.application?.applicationType !== APPLICATION_TYPES.UNATTENDED;
    const displayRetry =
      this.state.application?.applicationType ===
      APPLICATION_TYPES.HUMAN_IN_THE_LOOP;
    return (
      <div className="ui segment noShadow component-wrapper">
        <div className="eventContainer">
          <div className="d-flex-start">
            <div className="eventTableContainer">
              <a
                id="downloadExportUrl"
                href={this.state.downloadExportUrl}
                target="_blank"
                rel="noreferrer"
                style={{ display: "none" }}
              />
              <ActivityCards
                application={this.state.application}
                queryParams={this.state.queryParams}
                stats={this.state.stats}
                onChange={this.clearQueryParams}
              />
              {this.state.columns?.length > 0 && (
                <Table
                  tableUuid={this.tableUuid()}
                  items={this.state.activities}
                  columns={this.state.columns}
                  loadItems={entries => {
                    Object.keys(entries).forEach(entry =>
                      this.setQueryParams(entry, entries[entry])
                    );
                  }}
                  filters={this.state.queryParams}
                  nPages={this.state.nPages}
                  page={
                    this.state.queryParams ? this.state.queryParams.page : 1
                  }
                  totalItems={this.state.totalItems}
                  loadingItems={this.state.loadingActivities}
                  selectingAll={this.state.selectingAll}
                  onRowClick={this.onRowClick}
                  onCheckboxChange={this.onCheckboxChange}
                  selectedElementIds={this.state.selectedActivitiesIds}
                  onSelectAllClick={this.onSelectAllClick}
                  selectAllChecked={this.selectAllChecked}
                  mobileColumns={{ name: "identifier_key" }}
                  noItemsKey={i18n.t("NoElements")}
                  createAction={createAction}
                  activitiesTable
                  reloadAction={this.reloadActivities}
                  displayRetry={displayRetry}
                  retryDisabled={this.state.retryDisabled}
                  retryAction={this.handleRetry}
                  exportDisabled={this.state.exportDisabled}
                  exportAction={this.handleExport}
                  displayDelete={displayDelete}
                  deleteDisabled={this.state.deleteDisabled}
                  deleteAction={this.handleDelete}
                />
              )}
            </div>
          </div>
          <LaunchModal
            application={this.state.application}
            open={this.state.modalVisible}
            closeModal={() => this.setModal(false)}
            loadActivities={this.loadActivities}
            onSuccess={() => {
              this.setState({
                modalVisible: false
              });
              Toast.show.activitiesLaunched();
              this.loadActivities();
            }}
            onFailure={() => {
              Toast.show.activitiesNotLaunched();
            }}
          />
          <EventModal
            elements={this.state.selectedActivitiesIds}
            deleteItem={this.deleteAction}
            title="ActivityModalDeleteTitle"
            text="DeleteModalActivityText"
            loadActivities={this.loadActivities}
            open={this.state.showModal}
            closeModal={this.hideModal}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.usersReducer.users,
    filters: state.filtersReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadUsers: users => dispatch(loadUsers(users)),
    saveFilters: (appUuid, filters) => dispatch(loadFilters(appUuid, filters)),
    deleteFilters: () => dispatch(deleteFilters())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsIndex);
