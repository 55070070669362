import React from "react";
import { Input } from "semantic-ui-react";
import i18n from "../i18n";

const SearchBox = props => {
  const className = props.action ? "search-box action" : "search-box";
  return (
    <Input
      icon="search"
      iconPosition={props.actionPosition === "right" ? "left" : undefined}
      type="text"
      placeholder={i18n.t("SearchPlaceholder")}
      className={className}
      onChange={e => {
        props.loadItems(e.target.value);
      }}
      action={props.action}
      disabled={props.disabled}
      defaultValue={props.value || ""}
    />
  );
};

export default SearchBox;
