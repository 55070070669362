import React from "react";
import SearchInput from "../SearchInput";

const MobileFilters = props => {
  return (
    <React.Fragment>
      {props.show && (
        <div className="filter-section">
          {props.columns.map(column => (
            <SearchInput
              key={column.key}
              column={column}
              filters={props.filters}
              loadItems={props.loadItems}
              loadingItems={props.loadingItems}
            />
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default MobileFilters;
