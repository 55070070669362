import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import ServicesTable from "./ServicesTable";
import ServicesModal, { ModalType } from "./ServicesModal";
import i18n from "../i18n";
import ServiceService from "../../services/data_services/service";
import Permissions from "../../services/permissions";
import {
  VisitableComponents,
  Actions
} from "../../services/detailedPermissionRules";

import "../../../assets/stylesheets/Services/services.scss";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalType: ModalType.New,
      service: {},
      paramsByType: {}
    };
    props.setBreadcrumb([{ title: i18n.t("Services"), location: "/services" }]);
  }

  componentDidMount() {
    this.loadServices();
    this.getParametersByType();
  }

  loadServices = () => {
    ServiceService.loadServices()
      .then(response => {
        this.props.loadServices(response.data);
      })
      .catch(() => {
        this.props.loadServices([]);
      });
  };

  getParametersByType = () => {
    ServiceService.loadServiceParameters()
      .then(response => {
        this.setState({ paramsByType: response.data });
      })
      .catch(() => {
        this.setState({ paramsByType: [] });
      });
  };

  showModal = (modalType, service = {}) => {
    this.setState({
      modalOpen: true,
      modalType,
      service
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  };

  renderComponent() {
    return (
      <div className="ui segment noShadow super-admin-container">
        <ServicesTable
          services={this.props.services}
          paramsByType={this.state.paramsByType}
          showModal={this.showModal}
          closeModal={this.closeModal}
        />
        <Permissions
          component={VisitableComponents.Services}
          action={Actions.create}
          yes={() => {
            return (
              <div className="actionButtons">
                <button
                  type="button"
                  className="ui button blue"
                  onClick={() => this.showModal(ModalType.New)}
                >
                  {i18n.t("AddServiceButton")}
                </button>
              </div>
            );
          }}
          no={() => {
            return null;
          }}
        />
        <ServicesModal
          open={this.state.modalOpen}
          service={this.state.service}
          modalType={this.state.modalType}
          paramsByType={this.state.paramsByType}
          closeModal={this.closeModal}
          loadServices={this.loadServices}
        />
      </div>
    );
  }

  render() {
    return (
      <Permissions
        component={VisitableComponents.Services}
        action={Actions.read}
        yes={() => {
          return this.renderComponent();
        }}
        no={() => {
          return <Redirect to="/" />;
        }}
      />
    );
  }
}

export default Services;
