import React from "react";
import i18n from "../i18n";
import { checkImageExists, defaultProfileImage } from "../../services/images";
import Breadcrumb from "./Breadcrumb";
import Avatar from "../Ui/Avatar";

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageExists: false
    };

    checkImageExists(this.props.user.profile_img, imageExists => {
      this.setState({
        imageExists
      });
    });
  }

  settings() {
    this.props.history.push("/settings");
  }

  profileImage = () => {
    if (this.state.imageExists) {
      return this.props.user.profile_img;
    }
    return defaultProfileImage();
  };

  logOut() {
    this.props.history.replace("/");
    this.props.logOut();
  }

  renderMenuButton = () => {
    return (
      <div className="mobile menu">
        <i
          role="button"
          aria-label="Toggle sidebar"
          tabIndex={0}
          className="icon bars"
          onClick={() => this.props.toggleSideBar(true)}
        />
      </div>
    );
  };

  renderLogOutButton() {
    if (this.props.user) {
      return (
        <div className="ui menu nav">
          <div className="ui simple dropdown item userDropdown">
            <Avatar
              imageUrl={this.profileImage()}
              name={this.props.user.name}
            />
            <div className="menu">
              <div
                role="button"
                aria-label="Settings"
                tabIndex={0}
                className="item"
                onClick={() => this.settings()}
              >
                {i18n.t("Settings")}
              </div>
              <div
                role="button"
                aria-label="Log Out"
                tabIndex={0}
                className="item"
                onClick={() => this.logOut()}
              >
                {i18n.t("LogOut")}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <div className="topbar">
        {this.renderMenuButton()}
        <Breadcrumb breadcrumb={this.props.breadcrumb} />
        {this.renderLogOutButton()}
      </div>
    );
  }
}

export default NavigationBar;
