import React from "react";
import { ReactFormBuilder } from "react-ui-form-builder-attachment";

import "stylesheets/form-builder.scss";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/css/brands.min.css";
import "@fortawesome/fontawesome-free/css/regular.min.css";
import "@fortawesome/fontawesome-free/css/solid.min.css";
import "@fortawesome/fontawesome-free/css/svg-with-js.min.css";
import "@fortawesome/fontawesome-free/css/v4-shims.min.css";
import "react-ui-form-builder-attachment/dist/app.css";
import { Field, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import i18n from "../../../i18n";
import SubmitButton from "../../../SubmitButton";
import FormsModal from "./FormsModal";
import InlineField from "../../../Ui/Form/InlineField";
import { isDesktop } from "../../../../services/general";

const FormsBuilder = props => {
  const [showModal, setShowModal] = React.useState(false);

  const renderField = (name, fieldName, isRequired, errors) => {
    const errorClass = isDesktop() ? "inline error" : "mobile error";
    return (
      <>
        {isDesktop() ? (
          <InlineField
            required={isRequired}
            label={name}
            fieldName={fieldName}
            errors={errors}
          />
        ) : (
          <div className={`field ${isRequired ? "required" : null}`}>
            <label>{i18n.t(name)}</label>
            <Field type="text" name={fieldName} autoComplete="off" />
          </div>
        )}
        <ErrorMessage name={fieldName} component="div" className={errorClass} />
      </>
    );
  };

  return (
    <div>
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          const valuesToPost = {
            ...values,
            description: values.description || null
          };
          props.submit(valuesToPost, setSubmitting());
        }}
        initialValues={{
          name: props.formData ? props.formData.name : "",
          description: props.formData?.description || undefined,
          configuration: props.formData ? props.formData.configuration : []
        }}
        validationSchema={Yup.object().shape({
          configuration: Yup.array().min(
            1,
            i18n.t("FormFieldsRequiredValidation")
          )
        })}
        validate={values => {
          const errors = {};

          if (!values.name || values.name === "") {
            errors.name = i18n.t("NameRequiredValidation");
          }

          if (values.configuration) {
            if (
              new Set(values.configuration).size !== values.configuration.length
            ) {
              errors.configuration = i18n.t("SomeIdDuplicatedValidation");
            }
          } else if (
            !values.configuration ||
            values.configuration.length === 0
          ) {
            errors.configuration = i18n.t("FormFieldsRequiredValidation");
          }

          return errors;
        }}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values, errors }) => (
          <form className="ui form" onSubmit={handleSubmit}>
            {renderField("Name", "name", true, errors)}
            {renderField("Description", "description", false, errors)}
            <div
              className={
                isDesktop() ? "field inlineField required" : "field required"
              }
            >
              <label>{i18n.t("FormConfiguration")}</label>
              <div className="previewFormButtonContainer">
                <button
                  className="ui button blue small"
                  type="button"
                  onClick={() => setShowModal(true)}
                  disabled={!values.configuration.length}
                >
                  {i18n.t("PreviewForm")}
                </button>
                <ErrorMessage
                  name="configuration"
                  component="div"
                  className="inline error"
                />
              </div>
            </div>

            <ReactFormBuilder
              onPost={form => {
                setFieldValue("configuration", form.task_data);
              }}
              data={props.formData ? props.formData.configuration : null}
            />

            <div className="ui segment transparent">
              <div className="actionButtons">
                <button
                  type="button"
                  className="ui button basic blue"
                  onClick={() => {
                    if (props.returnToForms) props.returnToForms();
                  }}
                >
                  {i18n.t("Cancel")}
                </button>
                <SubmitButton label="SaveButton" isSubmitting={isSubmitting} />
              </div>
            </div>

            <FormsModal
              open={showModal}
              title={values.name}
              configuration={values.configuration}
              close={() => {
                setShowModal(false);
              }}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default FormsBuilder;
