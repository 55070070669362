import React from "react";
import i18n from "../i18n";

const CompaniesInfo = props => {
  return (
    <div className="ui two column grid">
      <div className="row">
        <p className="five wide column">
          <strong>{i18n.t("CompanyName")}:</strong>
        </p>
        <p className="eleven wide column">{props.company.name}</p>
      </div>
      <div className="row">
        <p className="five wide column">
          <strong>{i18n.t("Description")}:</strong>
        </p>
        <p className="eleven wide column">{props.company.description}</p>
      </div>
      <div className="row">
        <p className="five wide column">
          <strong>{i18n.t("Email")}:</strong>
        </p>
        <p className="eleven wide column">{props.company.admin_email}</p>
      </div>
    </div>
  );
};

export default CompaniesInfo;
