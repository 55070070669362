import React from "react";
import { debounce, cloneDeep } from "lodash";
import i18n from "../../../i18n";
import Table from "../../../Ui/Table/Table";
import { TYPES } from "../../../Ui/Table/types";
import EventActivitiesTable from "../../../Activities/Events/EventActivitiesTable";
import QueueService from "../../../../services/data_services/queue";
import ServiceService from "../../../../services/data_services/service";
import SearchBox from "../../../Ui/SearchBox";
import {
  searchParamFromUrl,
  urlFromSearchParam
} from "../../../../services/url";

class QueuesTable extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = searchParamFromUrl(this.props.location.search);

    this.state = {
      alerts: undefined,
      currentEdit: undefined,
      currentText: undefined,
      columns: [
        { name: "Alert", show: true, key: "alert" },
        { name: "Description", show: true, key: "description" },
        { name: "Notification", show: true, key: "notification" }
      ],
      queryParams,
      nPages: 0
    };
  }

  componentDidMount() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Alerts"),
        location: "/admin/alerts"
      }
    ]);
    this.loadQueues();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (!isEqual(prevState.queryParams, this.state.queryParams)) {
    //   this.props.history.push(`?${urlFromSearchParam(this.state.queryParams)}`);
    //   this.loadQueues();
    // }
  }

  loadQueues = debounce(() => {
    // const query = { ...this.state.queryParams };
    // query.company_id = this.props.user.company_id;

    let results = [
      {"alert":"Warning","description":"Resources in warning status","notification":"rpa.team@invokeinc.com"},
      {"alert":"Pending","description":"Resources with a pending session fo longer than 5 minutes","notification":"rpa.team@invokeinc.com"},
      {"alert":"Idle","description":"Resources idle for longer than 1 hour","notification":"rpa.team@invokeinc.com"},
      {"alert":"Multiple terminations","description":"More than 3 terminations in the las 15 minutes","notification":"rpa.team@invokeinc.com"},
      {"alert":"Offline resources","description":"Resources offline for longer than 5 minutes","notification":"rpa.team@invokeinc.com"}
    ];

    results = results.map((result, index)=> {
      let tempResult = cloneDeep(result);
      tempResult.id = index;
      tempResult.notification = this.renderNotifications(result.notification, index);
      tempResult.notification_INITIAL = result.notification;
      return tempResult;
    })

    this.setState({
      alerts: results,
      nPages: 1
    });

    // QueueService.loadQueues(query).then(result => {
    //   this.setState({
    //     queues: result.data.results,
    //     nPages: result.data.total
    //   });
    // });
  }, 500);

  reloadTable = _ => {   

    let alerts = this.state.alerts.map((result, index)=> {
      let tempResult = cloneDeep(result);
      tempResult.notification = this.renderNotifications(result.notification_INITIAL, index);
      return tempResult;
    })

    this.setState({
      alerts
    });
  }

  

  renderNotifications(text, index) {
    if (this.state.currentEdit === index) {
      return (
        <div className="ui input d-flex-center" style={{ width: "100%" }}>
          <input
            defaultValue={this.state.currentText}
            placeholder="Notification email..."
            onChange={e => {           
              const currentText = e.target.value;   
              this.setState({currentText});
            }}
          />
          <div className="line-action-button accept" onClick={this.handleAccept} ><i className="check icon green action-icon"></i></div>
          <div className="line-action-button decline" onClick={_=>this.setState({currentText:undefined, currentEdit: undefined},()=>{this.reloadTable()})} ><i className="times icon red action-icon"></i></div>
        </div>
      );
    }
    return (
      <div>
        {text}<i className="edit outline icon row-icon"  onClick={()=>{
          this.setState({currentEdit: index, currentText: text},()=>{this.reloadTable()})
        }}></i>
      </div>
    );
  }

  handleAccept = _ => {   
    this.setState(prevState => {
      const alerts = [...prevState.alerts];
      alerts[this.state.currentEdit].notification_INITIAL = this.state.currentText
      return { alerts, currentText:undefined, currentEdit: undefined };
    },()=>{this.reloadTable()});
  }


  setQueryParams(key, value, search = false) {
    this.setState(prevState => {
      const queryParams = {
        ...prevState.queryParams
      };

      if (search) {
        queryParams.page = 1;
      }

      value === undefined ||
      value === "" ||
      (Array.isArray(value) && value.length === 0)
        ? delete queryParams[key]
        : (queryParams[key] = value);

      return { queryParams };
    });
  }

  render() {
    return (
      <>
        <Table
          tableId={TYPES.ALERTS}
          columns={this.state.columns}
          items={this.state.alerts}
          noItemsKey="NoResources"
          user={this.props.user}
          loadItems={entries => {
            Object.keys(entries).forEach(key => {
              this.setQueryParams(key, entries[key]);
            });
          }}
          nPages={this.state.nPages}
          page={this.state.queryParams ? this.state.queryParams.page : 1}
          filters={this.state.queryParams}
          mobileColumns={{ name: "alert" }}
          totalItems={5}
        />
      </>
    );
  }
}

export default QueuesTable;
