import React from "react";
import i18n from "../../../i18n";
import SearchBox from "../../SearchBox";
import TableActionButton from "../../TableActionButton";
import Permissions from "../../../../services/permissions";
import { Actions } from "../../../../services/detailedPermissionRules";

function TableSearchbar({ quickSearchParam = "search_param", ...props }) {
  const Button = (
    <TableActionButton icon="plus" type="primary" onClick={props.createAction}>
      {i18n.t("Create")}
    </TableActionButton>
  );
  const renderCreateButton = () => {
    if (props.component) {
      return (
        <Permissions
          component={props.component}
          action={Actions.create}
          yes={() => {
            return Button;
          }}
          no={() => {
            return null;
          }}
        />
      );
    }
    return Button;
  };
  return (
    <div className="d-flex-center-sb" style={{ paddingBottom: "1rem" }}>
      {props.customSearch ? (
        props.customSearch()
      ) : (
        <SearchBox
          value={props.filters[quickSearchParam]}
          loadItems={searchParams => {
            props.loadItems({ [quickSearchParam]: searchParams });
          }}
        />
      )}
      <div className="table-actions-buttons">
        {props.activitiesTable && (
          <React.Fragment>
            {props.displayRetry && (
              <TableActionButton
                icon="redo alt"
                type="secondary"
                onClick={props.retryAction}
                disabled={props.retryDisabled}
              >
                {i18n.t("Retry")}
              </TableActionButton>
            )}
            {props.displayDelete && (
              <TableActionButton
                icon="trash"
                type="secondary"
                onClick={props.deleteAction}
                disabled={props.deleteDisabled}
              >
                {i18n.t("Delete")}
              </TableActionButton>
            )}
            <TableActionButton
              icon="print"
              type="secondary"
              onClick={props.exportAction}
              disabled={props.exportDisabled}
            >
              {i18n.t("Export")}
            </TableActionButton>
          </React.Fragment>
        )}
        {props.createAction && renderCreateButton()}
        {props.reloadAction && (
          <TableActionButton
            icon="sync"
            type="secondary"
            onClick={props.reloadAction}
          >
            {i18n.t("Refresh")}
          </TableActionButton>
        )}
        <TableActionButton
          icon="columns"
          type="secondary"
          onClick={props.toggleColumns}
        >
          {i18n.t("Columns")}
        </TableActionButton>
      </div>
    </div>
  );
}

export default TableSearchbar;
