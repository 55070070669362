import React from "react";
import i18n from "../i18n";

import ServiceRow from "./ServiceRow";

const ServicesTable = props => {
  const renderEmpty = () => {
    return (
      <div className="noContent" style={{ padding: "40px" }}>
        <h4>{i18n.t("There are no services yet")}</h4>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="ui" style={{ minHeight: "100px" }}>
        <div className="ui active inverted dimmer">
          <div className="ui text loader">{i18n.t("Loading")}</div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <div className="tableContainer">
        <table className="ui table unstackable">
          <thead>
            <tr>
              <th>{i18n.t("ServiceName")}</th>
              <th>{i18n.t("CompanyName")}</th>
              <th>{i18n.t("ServiceUrl")}</th>
              <th>{i18n.t("Type")}</th>
              <th aria-label="actions" />
            </tr>
          </thead>
          <tbody>
            {props.services.map(service => (
              <ServiceRow
                key={service.id}
                service={service}
                paramsByType={props.paramsByType}
                showModal={props.showModal}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  if (props.services === undefined) {
    return renderLoading();
  }
  if (props.services.length > 0) {
    return renderTable();
  }
  return renderEmpty();
};

export default ServicesTable;
