import React from "react";
import i18n from "../../../../i18n";
import ApplicationService from "../../../../../services/data_services/application";
import QueueService from "../../../../../services/data_services/queue";
import InputDropdown from "../../../../Ui/InputDropdown";

class ProcessForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processes: [],
      processInputs: [],
      queues: []
    };

    QueueService.loadQueues({ company_id: this.props.user.company_id }).then(
      result => {
        if (result.data) {
          this.setState({
            queues: result.data.results
          });
        } else {
          throw new Error("Queues: Not able to fetch");
        }
      }
    );

    if (this.props.values.service_id && this.props.isHuman) {
      this.loadRpaProcesses(this.props.values.service_id);
    }
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.values.service_id !== this.props.values.service_id) && this.props.isHuman) {
      this.loadRpaProcesses(this.props.values.service_id);
    }
  }

  loadRpaProcesses(serviceId) {
    if (serviceId) {
      ApplicationService.loadRpaProcesses(serviceId)
        .then(result => {
          if (result.status !== 200) {
            throw new Error("Rpa Processes: Not able to fetch");
          } else {
            this.setState({
              processes: result.data
            });
          }
        })
        .catch(() => {
          this.setState({
            processes: []
          });
        });
    } else {
      this.props.setFieldValue("process_entity.rpa_process", "");
      this.setState({
        processes: []
      });
    }
  }

  processItemsOptions() {
    return this.state.processes.map(process => {
      return { key:process.name, value: process.name, text: process.name };
    });
  }

  processItemsValue() {
    return this.processItemsOptions().find(
      option => option.value === this.props.values.process_entity.rpa_process
    );
  }

  queueItemsOptions() {
    if (Array.isArray(this.state.queues) && this.props.selectedService) {
      let availableQueues = this.state.queues;
      if (this.props.values.service_id) {
        availableQueues = this.state.queues.filter(
          queue => queue.service === this.props.selectedService.text
        );
      }
      return availableQueues.map(queue => {
        return { key: queue.name, value: queue.name, text: queue.name };
      });
    }
    return [];
  }

  queueItemsValue() {
    if (!this.props.values.process_entity.rpa_queues?.length) return null;
    const selectedQueue = this.queueItemsOptions().filter(
      option =>
        this.props.values.process_entity.rpa_queues &&
        this.props.values.process_entity.rpa_queues.includes(option.value)
    );
    return selectedQueue.length > 0 ? selectedQueue[0].value : null;
  }

  processInput(input) {
    return (
      <div className="field inlineField">
        <label>{input.Name}</label>
        <input name={input.Stage} type={input.Type} />
      </div>
    );
  }

  processInputs() {
    return (
      <div>
        {this.state.processInputs.map(input => this.processInput(input))}
      </div>
    );
  }

  getError = (value, target) => !value && `${target} is mandatory`;

  render() {
    return (
      <React.Fragment>
        {this.props.isHuman ? 
          <InputDropdown
              required
              error={this.getError(this.processItemsValue()?.value, 'rpa_process')}
              inputlabel={i18n.t("RpaProcess")}
              options={this.processItemsOptions()}
              value={this.processItemsValue()?.value}
              placeholder={`${i18n.t("Select")}...`}
              disabled={this.state.processes.length === 0 || this.props.isDisabled}
              search
              selection
              onChange={(e, { value } ) => {
                this.props.setFieldValue("process_entity.rpa_process", value ? value : '');
                this.setState({
                  processInputs: []
                });
              }}
          />    
        :
        <InputDropdown
            required
            error={this.getError(this.queueItemsValue(), 'rpa_queues')}
            inputlabel={i18n.t("Queue")}
            options={this.queueItemsOptions()}
            value={this.queueItemsValue()}
            placeholder={`${i18n.t("Select")}...`}
            disabled={this.state.queues.length === 0 || this.props.isDisabled}
            search
            selection
            onChange={(e, { value } ) => {
              this.props.setFieldValue(
                "process_entity.rpa_queues",
                value ? [value] : []
              );
            }}
        />
      }
      </React.Fragment>
    );
  }
}

export default ProcessForm;
