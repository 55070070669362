import React from "react";
import { Redirect } from "react-router-dom";
import TeamsForm from "./TeamsForm";
import i18n from "../../../i18n";
import TeamService from "../../../../services/data_services/team";
import Toast from "../../../../services/toasts";
import WithLoadingComponent from "../../../Ui/WithLoadingComponent";
import EmailsForm from "../../AssetManagement/Emails/EmailsForm";

class TeamsNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      team: undefined
    };

    this.setBreadcrumb();

    if (this.props.match) {
      TeamService.loadTeam(
        this.props.match.params.id ? this.props.match.params.id : ""
      ).then(result => {
        this.setState(
          {
            team: result.data
          },
          this.setBreadcrumb
        );
      });
    }
  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Teams"),
        location: "/admin/teams"
      },
      {
        title: this.title(),
        location: "/admin/teams/new"
      }
    ]);
  }

  title() {
    if (this.state.team) {
      return `${i18n.t("NewTeam")}`;
    }
    return `${i18n.t("NewTeam")}`;
  }

  submitForm = (values, submittingFormik, returnTeams) => {
    TeamService.createTeam(values)
      .then(() => {
        submittingFormik(false);
        Toast.show.teamsCreated();
        returnTeams();
      })
      .catch(() => {
        submittingFormik(false);
        Toast.show.teamsNotCreated();
      });
  };

  render() {
    if (this.state.returnToTeams) {
      return <Redirect push to="/admin/teams" />;
    }

    return (
      <WithLoadingComponent
        loadingCondition={() => this.props.match.params.id && !this.state.team}
        contentCondition={() =>
          (this.props.match.params.id && this.state.team.id) ||
          !this.props.match.params.id
        }
        noItemsKey="NotAbleLoadTeam"
      >
        <TeamsForm {...this.state} submit={this.submitForm} />
      </WithLoadingComponent>
    );
  }
}

export default TeamsNew;
