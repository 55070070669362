import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { isEqual } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import UserSettingsService from "../../services/data_services/userSettings";
import { VisitableComponents } from "../../services/detailedPermissionRules";
import SidebarItem from "./SidebarItem";

class IndividualApplications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: [],
      userSettingsExist: false
    };
  }

  componentDidMount() {
    this.loadUserSettings();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.applications, this.props.applications)) {
      this.loadUserSettings();
    }
  }

  loadUserSettings = () => {
    const userId = this.props.user.id;

    UserSettingsService.loadUsersSettings(userId, this.props.sidebarUuid)
      .then(result => {
        this.setState(
          {
            userSettingsExist: true
          },
          () => {
            // Do not save settings when we just load them.
            this.setUserSettings(result.data.columns, false);
          }
        );
      })
      .catch(() => {
        const applicationIds = this.props.applications.map(app => app.uuid);
        // Do not save settings when we just load them.
        this.setUserSettings(applicationIds, false);
      });
  };

  setUserSettings = (appIds, saveUserSettings = true) => {
    const result = [];
    let sortedApplications = [...this.props.applications];
    const allApplications = this.props.applications.map(app => app.uuid);
    const appIdsCopy = [...appIds];

    allApplications.forEach(app => {
      if (!appIdsCopy.includes(app)) {
        appIdsCopy.push(app);
      }
    });

    appIdsCopy.forEach(uuid => {
      let found = false;
      sortedApplications = sortedApplications.filter(app => {
        if (!found && app.uuid === uuid) {
          result.push({ ...app });
          found = true;
          return false;
        }
        return true;
      });
    });

    this.saveApplicationsOrder(result, saveUserSettings);
  };

  saveApplicationsOrder = (applications, saveUserSettings = true) => {
    this.setState(
      {
        applications
      },
      () => {
        if (saveUserSettings) {
          if (this.state.userSettingsExist) {
            UserSettingsService.updateUserSettings(
              this.props.user.id,
              this.props.sidebarUuid,
              this.getApplicationUuids(applications)
            );
          } else {
            UserSettingsService.createUserSettings(
              this.props.user.id,
              this.props.sidebarUuid,
              this.getApplicationUuids(applications)
            ).then(this.setState({ userSettingsExist: true }));
          }
        }
      }
    );
  };

  getApplicationUuids = applications => {
    return applications.filter(app => app.active).map(app => app.uuid);
  };

  onDragEnd = result => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newApplicationOrder = Array.from(this.state.applications);
    const element = newApplicationOrder.splice(source.index, 1);
    newApplicationOrder.splice(destination.index, 0, element[0]);

    this.saveApplicationsOrder(newApplicationOrder);
  };

  render() {
    return (
      <div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="application_sidebar">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {this.state.applications.map((application, index) => {
                  return (
                    <Draggable
                      key={application.id}
                      index={index}
                      draggableId={`draggable_${application.id}`}
                    >
                      {(dragProvided, dragSnapshot) => (
                        <div
                          className={`sidebarDraggable ${
                            dragSnapshot.isDragging ? "dragging" : ""
                          }`}
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <SidebarItem
                            width={this.props.width}
                            key={application.id}
                            component={VisitableComponents.Activities}
                            componentName={application.name}
                            url={`/applications/${application.id}`}
                            location={this.props.location}
                            icon="list"
                            toggleSideBar={this.props.toggleSideBar}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default withRouter(IndividualApplications);
