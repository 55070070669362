import axios from "../axios";

class EmailService {
  static loadEmails = params => {
    return axios.get("/conf/inboxes", { params });
  };

  static loadEmail = id => {
    return axios.get(`/conf/inboxes/${id}`);
  };

  static createEmail = values => {
    return axios.post("/conf/inboxes/", values);
  };

  static updateEmail = (id, values) => {
    return axios.put(`/conf/inboxes/${id}`, values);
  };
}

export default EmailService;
