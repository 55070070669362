import React from "react";
import i18n from "../../../i18n";

const StepHeader = props => {
  const _stepCompleted = stepToCheck => {
    return stepToCheck < props.currentStep;
  };

  const _stepActive = stepToCheck => {
    return stepToCheck === props.currentStep;
  };

  const _stepClickable = () => {
    return props.action === "edit" && !_stepActive(props.step);
  };

  const onClickHandler = () => {
    if (_stepClickable()) {
      props.changeCurrentStep(props.step);
    }
  };

  let base = "step";
  base = _stepCompleted(props.step) ? base.concat(" completed") : base;
  base = _stepActive(props.step) ? base.concat(" active") : base;
  base = _stepClickable() ? base.concat(" clickable") : base;

  return (
    <div className={base} onClick={() => onClickHandler()} role="navigation">
      <div className="content">
        <div className="description">{i18n.t(props.title)}</div>
      </div>
    </div>
  );
};

export default StepHeader;
