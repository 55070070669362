import defaultProfile from "images/default_profile.jpg";

export const checkImageExists = (imageUrl, callBack) => {
  const imageData = new Image();
  imageData.onload = function() {
    callBack(true);
  };
  imageData.onerror = function() {
    callBack(false);
  };
  imageData.src = imageUrl;
};

export const defaultProfileImage = () => {
  return defaultProfile;
};

export const icon = extension => {
  switch (extension) {
    case "zip":
    case "rar":
    case "7zip":
      return "file archive outline";
    case "mp3":
      return "file audio outline";
    case "xlsx":
    case "xlsm":
    case "xlsb":
    case "sltx":
    case "csv":
      return "file excel outline";
    case "jpg":
    case "jpeg":
    case "png":
      return "file image outline";
    case "pdf":
      return "file pdf outline";
    case "ppt":
    case "pptx":
    case "pptm":
      return "file powerpoint outline";
    case "webm":
    case "mpeg":
    case "mp4":
    case "avi":
    case "flv":
    case "mkv":
      return "file video outline";
    case "doc":
    case "docx":
    case "docm":
    case "dotx":
    case "dotm":
      return "file word outline";
    default:
      return "file alternate outline";
  }
};
