import React from "react";
import { Field, ErrorMessage } from "formik";
import Select from "react-select";
import { reactSelectStyle } from "../../../../semantic-ui";
import i18n from "../../../i18n";
import SchedulerService from "../../../../services/data_services/scheduler";
import NotificationSelector from "../notificationSelector";
import TooltipLabel from "../TooltipLabel";
import { PRIORITY } from "../../../../constants/priority";
import { isDesktop } from "../../../../services/general";

class SchedulersStep3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sla_actions: undefined
    };
  }

  componentDidMount() {
    this.checkIfSLADefault();
  }

  loadSlaActions = () => {
    SchedulerService.loadSlaOptions().then(response => {
      this.setState({
        sla_actions: response.data
      });
    });
  };

  loadNotifications = () => {
    SchedulerService.loadNotificationOptions().then(response => {
      this.setState({
        notifications: response.data
      });
    });
  };

  enableField = fieldName => {
    this.props.setFieldValue(fieldName, true);
  };

  disableField = fieldName => {
    this.props.setFieldValue(fieldName, false);
  };

  fieldEnabled = fieldName => {
    return this.props.values[fieldName];
  };

  _yesChecked = fieldName => {
    return this.fieldEnabled(fieldName) ? "checked" : "";
  };

  _noChecked = fieldName => {
    return !this.fieldEnabled(fieldName) ? "checked" : "";
  };

  priorityOptions = () => {
    return PRIORITY;
  };

  priorityValue = () => {
    return this.priorityOptions().filter(
      option => option.value === this.props.values.priority
    )[0];
  };

  slaActionOptions() {
    if (this.state.sla_actions) {
      return this.state.sla_actions.map(action => {
        return { label: action, value: action };
      });
    }

    return [];
  }

  slaActionsValue() {
    return this.slaActionOptions().filter(option => {
      return this.props.values.sla_action === option.value;
    });
  }

  notificationOptions() {
    if (this.state.notifications) {
      return this.state.notifications.map(action => {
        return { label: action, value: action.toLowerCase() };
      });
    }

    return [];
  }

  notificationValue() {
    const result = this.notificationOptions().filter(action => {
      return (
        this.props.values.sla_type &&
        this.props.values.sla_type.toLowerCase() === action.value.toLowerCase()
      );
    });

    return result;
  }

  errorMessage(fieldName) {
    let message = "";

    if (this.props.errors && this.props.errors[fieldName]) {
      message = this.props.errors[fieldName];
    }

    return message;
  }

  enableSLADefault = () => {
    this.enableField("sla_default");
    this.props.setFieldValue("sla", 1440);
  };

  checkIfSLADefault = () => {
    if (this.props.values.sla !== 1440 && this.props.values.sla_default === true){
      this.props.setFieldValue("sla_default", false);
    }
  };

  render() {
    const SLAContainerClass = isDesktop ? "inline inlineFields fields" : "inline field";
    const marginClass = isDesktop() ? "inline fields" : "inline fields step4";
    const fieldClass = isDesktop() ? "inline fields" : "field";
    const errorClass = isDesktop() ? "inline error" : "mobile error";
    return (
      <div className="stepContainer">
        <div className={SLAContainerClass}>
          <TooltipLabel
            label={i18n.t("SLA")}
            content={i18n.t("SlaTooltip")}
            isRequired
          />
          <div className={`${marginClass} noMarginBottom`}>
            <div className="field">
              <div className="ui radio checkbox">
                <input
                  type="radio"
                  name="sla_default"
                  checked={this._yesChecked("sla_default")}
                  onChange={() => this.enableSLADefault("sla_default")}
                />
                <label>{i18n.t("Default")}</label>
              </div>
            </div>

            <div className="field">
              <div className="ui radio checkbox">
                <input
                  type="radio"
                  name="sla_default"
                  checked={this._noChecked("sla_default")}
                  onChange={() => this.disableField("sla_default")}
                />
                <label>{i18n.t("Defined")}</label>
              </div>
            </div>
          </div>

          <div className="field">
            <Field
              disabled={this.fieldEnabled("sla_default")}
              name="sla"
              type="number"
              min={1}
            />
            <label className="minutesLabel">{i18n.t("Minutes")}</label>
          </div>
        </div>
        <ErrorMessage name="sla" component="div" className={errorClass} />

        <NotificationSelector
          notifications={this.props.notifications}
          user={this.props.user}
          values={this.props.values}
          setFieldValue={this.props.setFieldValue}
          fieldName="sla"
          tooltipContent={i18n.t("SlaNotificationTooltip")}
          isRequired
        />
        <ErrorMessage
          name="sla_type"
          component="div"
          className={errorClass}
        />

        <div className={fieldClass}>
          <TooltipLabel
            label={i18n.t("Priority")}
            content={i18n.t("PriorityTooltip")}
            isRequired
          />
          <div className="field full-width">
            <Select
              value={this.priorityValue()}
              options={this.priorityOptions()}
              onChange={option => {
                this.props.setFieldValue("priority", option.value);
              }}
              placeholder={`${i18n.t("Select")}...`}
              className="react-select-container"
              classNamePrefix="react-select"
              styles={reactSelectStyle}
            />
          </div>
        </div>
        <ErrorMessage
          name="priority"
          component="div"
          className={errorClass}
        />
      </div>
    );
  }
}

export default SchedulersStep3;
