import axios from "../axios";

class NcwService {
  static getDepartments = applicationUuid => {
    return axios.get("ncws/departments", {
      params: { application_uuid: applicationUuid }
    });
  };

  static getClients = (applicationUuid, name) => {
    return axios.get("ncws/clients", {
      params: {
        application_uuid: applicationUuid,
        name
      }
    });
  };

  static getExistingClient = (applicationUuid, macId) => {
    return axios.get("ncws/existing_client", {
      params: {
        application_uuid: applicationUuid,
        mac_id: macId
      }
    });
  };

  static getNicheAndIndustries = applicationUuid => {
    return axios.get("ncws/niche_and_industries", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getNiche = applicationUuid => {
    return axios.get("ncws/niche", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getIndustries = (applicationUuid, nicheId = undefined) => {
    return axios.get("ncws/industries", {
      params: {
        application_uuid: applicationUuid,
        niche_id: nicheId
      }
    });
  };

  static getAviiStaff = applicationUuid => {
    return axios.get("ncws/avii_staff", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getOffices = applicationUuid => {
    return axios.get("ncws/office", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getPartnersInCharge = applicationUuid => {
    return axios.get("ncws/partners_in_charge", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getSalesChannels = applicationUuid => {
    return axios.get("ncws/sales_channel", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getSoftwareTypes = applicationUuid => {
    return axios.get("ncws/software_type", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getEntityType = applicationUuid => {
    return axios.get("ncws/entity_type", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getJobPeriods = applicationUuid => {
    return axios.get("ncws/job_periods", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getJobPartners = applicationUuid => {
    return axios.get("ncws/job_partners", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getJobManagers = applicationUuid => {
    return axios.get("ncws/job_managers", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getClientEntities = applicationUuid => {
    return axios.get("ncws/client_entity", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getClientManagers = applicationUuid => {
    return axios.get("ncws/client_manager", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getClientOriginators = applicationUuid => {
    return axios.get("ncws/client_originator", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getCountries = applicationUuid => {
    return axios.get("ncws/countries", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getUsaStates = applicationUuid => {
    return axios.get("ncws/usa_states", {
      params: {
        application_uuid: applicationUuid
      }
    });
  };

  static getActiveClients = (applicationUuid, name) => {
    return axios.get("ncws/active_clients", {
      params: {
        name,
        application_uuid: applicationUuid
      }
    });
  };
}

export default NcwService;
