import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Checkbox } from "semantic-ui-react";
import i18n from "../../i18n";

const TableFilterItem = props => {
  return (
    <Draggable draggableId={props.column.key} index={props.index}>
      {provided => (
        <div
          className="table_filter_item"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Checkbox
            label={{ children: i18n.t(props.column.name) }}
            defaultChecked={!props.disabled}
            onChange={(_, b) => {
              props.toggleDisabled(props.index, b.checked);
            }}
          />
        </div>
      )}
    </Draggable>
  );
};

export default TableFilterItem;
