import React, { Component } from "react";
import { isEqual } from "lodash";
import i18n from "../i18n";
import ActivityDetails from "./ActivityDetails";
import ActivityNewComment from "./Comments/ActivityNewComment";
import ActivityComments from "./Comments/ActivityComments";
import AttachmentContainer from "./AttachmentContainer";
import RossumIframe from "./RossumIframe";
import RetryButton from "./RetryButton";
import ActivityMeta from "./ActivityMeta";
import { attachmentTypes } from "../../constants/email-data";
import { isDesktop, isIE } from "../../services/general";
import { EDITABLE_STATUS } from "../../constants/status";
import { previewSupport } from "../../services/files";
import { APPLICATION_TYPES } from "../../constants/ApplicationTypes";

class ActivityRPA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      editActivity: false,
      previewUrl: undefined,
      showRossumModal: false,
      showCommentModal: false,
      showDetails: isDesktop()
    };
  }

  componentDidMount() {
    this.setBreadcrumb();
    this.openAttachmentByDefault();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.activity.data, this.props.activity.data)) {
      this.openAttachmentByDefault();
    }
  }

  setBreadcrumb = () => {
    this.props.setBreadcrumb([
      {
        title: this.props.application.name,
        location: `/applications/${this.props.application.id}`
      }
    ]);
  };

  isAttachmentValid = attachment =>
    Object.values(attachmentTypes).includes(attachment.type) &&
    attachment.value &&
    attachment.value !== "" &&
    !attachment.value.includes(null);

  openAttachmentByDefault = () => {
    if (this.props.activity.data) {
      const attachment = this.props.activity.data.find(item =>
        this.isAttachmentValid(item)
      );
      if (attachment && previewSupport(attachment.value)) {
        this.setPreviewUrl(attachment.value);
      } else if (this.state.previewUrl !== undefined) {
        this.setPreviewUrl(undefined);
      }
    } else if (this.state.previewUrl !== undefined) {
      this.setPreviewUrl(undefined);
    }
  };

  setEditActivity = value => {
    this.setState({
      editActivity: value
    });
  };

  setPreviewUrl = previewUrl => {
    this.setState(prevState => {
      const url = prevState.previewUrl === previewUrl ? undefined : previewUrl;
      return { previewUrl: url };
    });
  };

  isEditable = () => {
    const teamEdit = this.teams().some(team => team.allow_edit);
    const statusEdit = EDITABLE_STATUS.includes(this.props.activity.status);

    return teamEdit && statusEdit;
  };

  teams = () => {
    return this.props.user.teams;
  };

  openRossumIframe = url => {
    this.setState({
      rossumUrl: url,
      showRossumModal: true
    });
  };

  closeRossumModal = () => {
    this.setState({
      rossumUrl: null,
      showRossumModal: false
    });
  };

  toggleCommentsModal = value => {
    this.setState({ showCommentModal: value });
  };

  renderAttachment = () => <AttachmentContainer url={this.state.previewUrl} />;

  toggleDetails = () => {
    this.setState(prevState => {
      return { showDetails: !prevState.showDetails };
    });
  };

  returnToApplication = () => {
    this.props.history.push(`/applications/${this.props.application.id}`);
  };

  renderActivity() {
    const isUnattended =
      this.props.application?.applicationType === APPLICATION_TYPES.UNATTENDED;

    const displayRetry =
      this.props.application &&
      this.props.application.applicationType !== APPLICATION_TYPES.UNATTENDED &&
      this.props.application.process_entities instanceof Array &&
      this.props.application.process_entities.length > 0 &&
      this.props.application.process_entities.rpa_process != "" &&
      !isUnattended;

    return (
      <>
        <div className="activity-cards">
          <div className="activityShow">
            <div
              className="ui cards"
              style={{ marginBottom: !isDesktop() ? "10px" : "" }}
            >
              <div className="card">
                <div className="content">
                  {this.state.showDetails && (
                    <React.Fragment>
                      <div
                        className="d-flex-center-sb tittle-container"
                        style={{
                          flexDirection: isDesktop() ? "row" : "column"
                        }}
                      >
                        <ActivityDetails
                          activity={this.props.activity}
                          loadActivityData={this.props.loadActivityData}
                          user={this.props.user}
                          editable={this.isEditable()}
                          application={this.props.application}
                          displayRetry={displayRetry}
                        />
                        <RetryButton
                          activity={this.props.activity}
                          editable={this.isEditable()}
                          application={this.props.application}
                          displayRetry={displayRetry}
                        />
                      </div>
                      <div className="section-divider" />
                    </React.Fragment>
                  )}
                  <div className="d-flex-center-sb tittle-container">
                    {!isUnattended && (
                      <button
                        disabled={!this.isEditable()}
                        type="button"
                        className="ui small button"
                        onClick={() => {
                          this.setEditActivity(!this.state.editActivity);
                        }}
                      >
                        <i className="edit icon" />
                        {i18n.t("Edit")}
                      </button>
                    )}
                    {!isDesktop() && (
                      <a onClick={this.toggleDetails} role="link" tabIndex={0}>
                        {`${
                          this.state.showDetails ? "Collapse" : "Show"
                        } actions`}
                      </a>
                    )}
                  </div>
                  <ActivityMeta
                    activity={this.props.activity}
                    editActivity={this.state.editActivity}
                    users={this.state.users}
                    previewUrl={this.state.previewUrl}
                    setPreviewUrl={this.setPreviewUrl}
                    loadActivityData={this.props.loadActivityData}
                    setEditActivity={this.setEditActivity}
                    application={this.props.application}
                  />
                </div>
              </div>
            </div>

            {!isDesktop() && this.renderAttachment()}

            {!isUnattended && (
              <>
                <ActivityComments
                  comments={
                    Object.prototype.hasOwnProperty.call(
                      this.props.activity,
                      "comments"
                    )
                      ? this.props.activity.comments
                      : []
                  }
                  openRossumIframe={this.openRossumIframe}
                  activity={this.props.activity}
                  loadActivityData={this.props.loadActivityData}
                  setPreviewUrl={this.setPreviewUrl}
                />

                {!isIE() ? (
                  <div>
                    <ActivityNewComment
                      open={this.state.showCommentModal}
                      onClose={() => this.toggleCommentsModal(false)}
                      activity={this.props.activity}
                      loadActivityData={this.props.loadActivityData}
                      user={this.props.user}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </>
            )}
          </div>
          {isDesktop() && this.renderAttachment()}
        </div>

        <RossumIframe
          url={this.state.rossumUrl}
          open={this.state.showRossumModal}
          onClose={this.closeRossumModal}
        />
      </>
    );
  }

  render() {
    return this.props.application && this.props.activity ? (
      this.renderActivity()
    ) : (
      <div />
    );
  }
}

export default ActivityRPA;
