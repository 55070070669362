import React from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";
import i18n from "../i18n";

import CompaniesForm from "./CompaniesForm";
import CompaniesInfo from "./CompaniesInfo";
import CompaniesSuspend from "./CompaniesSuspend";

export const ModalType = Object.freeze({
  New: "NEW",
  Edit: "EDIT",
  Show: "SHOW",
  Suspend: "SUSPEND"
});

class CompaniesModal extends React.Component {
  modalTitle = () => {
    switch (this.props.modalType) {
      case ModalType.New:
        return "AddingCompany";
      case ModalType.Edit:
        return "EditingCompany";
      case ModalType.Suspend:
        return "SuspendCompany";
      case ModalType.Show:
        return "CompanyInfo";
      default:
        return "";
    }
  };

  renderModalContent() {
    switch (this.props.modalType) {
      case ModalType.New:
        return (
          <CompaniesForm
            company={{}}
            loadCompanies={this.props.loadCompanies}
            closeModal={this.props.closeModal}
          />
        );
      case ModalType.Edit:
        return (
          <CompaniesForm
            company={this.props.company}
            loadCompanies={this.props.loadCompanies}
            closeModal={this.props.closeModal}
          />
        );
      case ModalType.Suspend:
        return (
          <CompaniesSuspend
            company={this.props.company}
            loadCompanies={this.props.loadCompanies}
            closeModal={this.props.closeModal}
          />
        );
      case ModalType.Show:
      default:
        return <CompaniesInfo company={this.props.company} />;
    }
  }

  render() {
    return (
      <TransitionablePortal
        open={this.props.open}
        transition={{
          animation: "scale",
          duration: 300
        }}
      >
        <Modal open size="tiny" onClose={() => this.props.closeModal()}>
          <Modal.Header>
            <div style={{ textAlign: "center" }}>
              {i18n.t(this.modalTitle())}
              <i
                role="button"
                tabIndex={0}
                aria-label="close"
                className="close icon"
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => this.props.closeModal()}
              />
            </div>
          </Modal.Header>
          <Modal.Content>{this.renderModalContent()}</Modal.Content>
        </Modal>
      </TransitionablePortal>
    );
  }
}

export default CompaniesModal;
