import React from "react";
import { Redirect } from "react-router";
import i18n from "../../../i18n";
import axios, { checkAndRedirect } from "../../../../services/axios";
import ApplicationsForm from "./ApplicationsForm";
import Toast from "../../../../services/toasts";
import WithLoadingComponent from "../../../Ui/WithLoadingComponent";
import UpperBar from "./UpperBar";

class ApplicationsShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      returnToApplications: false,
      application: undefined
    };

    this.setStateRef = this.setState.bind(this);
    this.loadApplication();
  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Applications"),
        location: "/admin/applications"
      },
      {
        title: `${i18n.t("Edit")} - ${this.state.application.name}`,
        location: `/admin/applications/${this.props.match.params.id}`
      }
    ]);
  }

  getProcessEntity(processEntity) {
    if (
      processEntity.name === "" &&
      processEntity.description === "" &&
      processEntity.service_id === "" &&
      processEntity.rpa_process === "" &&
      processEntity.rpa_queues.length === 0
    ) {
      return undefined;
    }
    return processEntity;
  }

  updateApplication = (values, setSubmitting) => {
    const valuesToPost = { ...values };

    axios
      .put(`/applications/${this.props.match.params.id}`, valuesToPost)
      .then(() => {
        Toast.show.applicationsUpdated();
        setSubmitting(false);
        this.setState({
          returnToApplications: true
        });
      })
      .catch(() => {
        Toast.show.applicationsNotUpdated();
        setSubmitting(false);
      });
  };

  loadApplication() {
    axios
      .get(`/applications/${this.props.match.params.id}`)
      .then(response => {
        this.setState(
          {
            application: response.data
          },
          () => {
            this.setBreadcrumb();
          }
        );
      })
      .catch(checkAndRedirect);
  }
  cancelForm = _ => {
    this.setState({
      returnToApplications: true
    });
  }

  render() {
    if (this.state.returnToApplications) {
      return <Redirect push to="/admin/applications" />;
    }

    return (
      <WithLoadingComponent
        loadingCondition={() => !this.state.application}
        contentCondition={() => this.state.application.id}
        noItemsKey="NotAbleLoadApplication"
        noClassName
      >
        <UpperBar
          application={this.state.application} 
          cancelForm={this.cancelForm}/>
        <ApplicationsForm
          user={this.props.user}
          setState={this.setStateRef}
          onSubmit={this.updateApplication}
          application={this.state.application}
        />
      </WithLoadingComponent>
    );
  }
}

export default ApplicationsShow;
