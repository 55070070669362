import React from "react";
import loader from "images/loader.svg";
import i18n from "./i18n";

const SubmitButton = props => {
  return (
    <button
      className="ui button blue"
      type="submit"
      disabled={props.isDisabled || props.isSubmitting}
      style={props.style || {}}
      onClick={event => {
        props.to_submit && props.to_submit(true);
      }}
    >
      {props.isSubmitting ? (
        <img alt="Loader" src={loader} style={{ height: "0.8em" }} />
      ) : (
        i18n.t(props.label)
      )}
    </button>
  );
};

export default SubmitButton;
