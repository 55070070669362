import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { Icon, Popup } from "semantic-ui-react";
import i18n from "../../i18n";

const StepContainer = props => {

    const [collapsed, setCollapsed] = useState(props.collapsed);
    useEffect(() => {
      setCollapsed(props.collapsed)
    }, [props.collapsed]) 

    const renderCollapseButton = () => {
        const buttonName = `angle icon ${collapsed ? "down" : "up"}` ;

        return (
        <button
            className="ui mini button icon basic"
            type="button"
            disabled={props.disabled}
            onClick={() => setCollapsed(!collapsed)}
        >
            <i className={buttonName} />
        </button>
        );
    }

    const stepStatus = _ => {        
        if (props.completed === 'last' || props.disabled || props.new)
            return '';       
        if( props.completed)
            return <Icon className="infoIcon success" name='check circle' size='large' />
        else    
            return <Icon className="infoIcon warning" name='warning circle' size='large' />
    }

    return (
        <div className="step-container" id={`step-${props.stepNumber}`}>
            <div className="d-flex-center-sb" onClick={() => {if(!props.disabled) setCollapsed(!collapsed)}}>
                <div className="d-flex-center-sb">
                    <div className="step-number">{props.stepNumber}</div>
                    <div className="step-title">{i18n.t(props.stepTitle)}</div>
                    {stepStatus()}
                </div>
                <div>{renderCollapseButton()}</div>
            </div>
            <div className="content" style={{ maxHeight:collapsed ? "" : "4000px", overflow: collapsed ? "" : "visible" }}>
                <div style={{marginTop: '16px'}}>{props.children}</div>
            </div>
        </div>
    );
};

export default StepContainer;
