import React from "react";
import { Redirect } from "react-router";
import FormsService from "../../../../services/data_services/forms";
import i18n from "../../../i18n";
import FormsBuilder from "./FormsBuilder";
import Toast from "../../../../services/toasts";
import WithLoadingComponent from "../../../Ui/WithLoadingComponent";
import { checkAndRedirect } from "../../../../services/axios";

class FormsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: undefined,
      dataHasError: false
    };

    this.loadForm();
    this.setBreadCrumb();

    this.returnToForms = this.returnToForms.bind(this);
  }

  componentDidCatch() {
    this.setState(
      {
        dataHasError: true
      },
      () => {
        Toast.show.formsCorrupted();
      }
    );
  }

  returnToForms = () => {
    this.setState({
      returnToForms: true
    });
  };

  loadForm = () => {
    FormsService.loadForm(this.props.match.params.id)
      .then(result => {
        this.setState(
          {
            formData: result.data
          },
          () => {
            this.setBreadCrumb();
          }
        );
      })
      .catch(checkAndRedirect);
  };

  submitForm = values => {
    FormsService.updateForm(this.props.match.params.id, values)
      .then(() => {
        Toast.show.formsUpdated();
        this.returnToForms();
      })
      .catch(() => {
        Toast.show.formsNotUpdated();
      });
  };

  setBreadCrumb = () => {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Forms"),
        location: "/admin/forms"
      },
      {
        title: this.state.formData ? this.state.formData.name : "",
        location: "/admin/forms/new"
      }
    ]);
  };

  formData = () => {
    const formData = { ...this.state.formData };

    if (this.state.dataHasError) {
      formData.configuration = [];
    }

    return formData;
  };

  render() {
    if (this.state.returnToForms) {
      return <Redirect push to="/admin/forms" />;
    }

    return (
      <WithLoadingComponent
        loadingCondition={() => !this.state.formData}
        contentCondition={() => this.state.formData.configuration}
        noItemsKey="NotAbleLoadForm"
      >
        <FormsBuilder
          formData={this.formData()}
          submit={this.submitForm}
          returnToForms={this.returnToForms}
        />
      </WithLoadingComponent>
    );
  }
}

export default FormsEdit;
