import i18n from "../../i18n";
import {
  activityDate,
  displayFileName,
  capitalizeFirst
} from "../../../services/string";
import { userIdToName } from "../../../services/users";
import {
  STATUS,
  displayActivityStatus,
  UNATTENDED_STATUS,
  formatUnattendedStatus
} from "../../../constants/status";
import { PRIORITY_OPTIONS, getText } from "../../../constants/priority";
import { APPLICATION_TYPES } from "../../../constants/ApplicationTypes";

const filterStatus = activityType => {
  return STATUS.filter(status => {
    if (Array.isArray(status.type)) {
      return status.type.includes(activityType);
    }
    return status.type === activityType;
  }).map(s => {
    return {
      label: s.label,
      value: s.value
    };
  });
};

const SCHEDULED_EVENT = "schedulers";

export const unattendedColumns = [
  { name: i18n.t("Key"), show: true, key: "identifier_key" },
  {
    name: i18n.t("Priority"),
    show: true,
    key: "priority",
    method: getText,
    type: "select",
    options: PRIORITY_OPTIONS,
    single: true
  },
  {
    name: i18n.t("Status"),
    show: true,
    key: "status",
    type: "select",
    sortable: false,
    options: UNATTENDED_STATUS.map(status => {
      return {
        label: capitalizeFirst(status.value),
        value: status.value
      };
    }),
    method: formatUnattendedStatus
  },
  {
    name: i18n.t("Deferred"),
    show: true,
    key: "deferred",
    type: "datetime",
    method: activityDate
  },
  {
    name: i18n.t("CreatedAt"),
    show: true,
    key: "created_at",
    type: "datetime",
    method: activityDate
  },
  {
    name: i18n.t("UpdatedAt"),
    show: true,
    key: "updated_at",
    type: "datetime",
    method: activityDate
  },
  {
    name: i18n.t("Exception Date"),
    show: true,
    key: "exception_date",
    type: "datetime",
    method: activityDate
  },
  {
    name: i18n.t("Tag"),
    show: true,
    key: "tag"
  },
  {
    name: i18n.t("Work Time"),
    show: true,
    key: "worktime",
    searchable: false
  }
];

export const activityColumns = [
  {
    name: i18n.t("identifier_key"),
    show: true,
    key: "identifier_key",
    order: "",
    method: displayFileName
  },
  {
    name: i18n.t("assigned_to"),
    show: true,
    key: "assigned_to",
    order: ""
  },
  {
    name: i18n.t("priority"),
    show: true,
    key: "priority",
    method: getText,
    order: "",
    type: "select",
    options: PRIORITY_OPTIONS,
    single: true
  },
  {
    name: i18n.t("detail"),
    show: true,
    key: "detail",
    order: ""
  },
  {
    name: i18n.t("status"),
    show: true,
    key: "status",
    method: displayActivityStatus,
    order: "",
    type: "select",
    options: filterStatus(APPLICATION_TYPES.HUMAN_IN_THE_LOOP)
  },
  {
    name: i18n.t("created_at"),
    show: true,
    key: "created_at",
    method: activityDate,
    order: "",
    type: "datetime"
  },
  {
    name: i18n.t("updated_at"),
    show: true,
    key: "updated_at",
    method: activityDate,
    order: "",
    type: "datetime"
  }
];

export const OCRColumns = [
  {
    name: i18n.t("DocumentID"),
    show: true,
    key: "identifier_key",
    order: "",
    method: displayFileName
  },
  {
    name: i18n.t("Name"),
    show: true,
    key: "detail",
    order: ""
  },
  {
    name: i18n.t("Status"),
    show: true,
    key: "status",
    method: displayActivityStatus,
    order: "",
    type: "select",
    options: filterStatus(APPLICATION_TYPES.HYPERSCIENCE)
  },
  {
    name: i18n.t("created_at"),
    show: true,
    key: "created_at",
    method: activityDate,
    order: "",
    type: "datetime"
  },
  {
    name: i18n.t("updated_at"),
    show: true,
    key: "updated_at",
    method: activityDate,
    order: "",
    type: "datetime"
  }
];

export const schedulerColumns = [
  {
    name: i18n.t("priority"),
    show: true,
    key: "priority",
    method: getText,
    order: "",
    type: "select",
    options: PRIORITY_OPTIONS,
    single: true,
    sortable: false
  },
  {
    name: i18n.t("SchedulerProcess"),
    show: true,
    key: "process_name",
    order: ""
  },
  {
    name: i18n.t("Status"),
    show: true,
    key: "status",
    method: displayActivityStatus,
    order: "",
    type: "select",
    options: filterStatus(SCHEDULED_EVENT)
  },
  {
    name: i18n.t("Type"),
    show: true,
    key: "type",
    order: "",
    type: "select",
    options: [
      { label: "Launcher", value: "Launcher" },
      { label: "Scheduler", value: "Scheduler" }
    ],
    sortable: false
  },
  {
    name: i18n.t("StartTime"),
    show: true,
    key: "first_try",
    method: activityDate,
    order: "",
    type: "datetime"
  },
  {
    name: i18n.t("ScheduledAt"),
    show: true,
    key: "scheduled_at",
    method: activityDate,
    order: "",
    type: "datetime"
  },
  {
    name: i18n.t("EndTime"),
    show: true,
    key: "end_time",
    method: activityDate,
    order: "",
    type: "datetime"
  }
];

export const NcwColumns = [
  {
    name: i18n.t("ClientName"),
    show: true,
    key: "identifier_key",
    order: "",
    method: displayFileName
  },
  {
    name: i18n.t("Approver"),
    show: true,
    key: "approvers",
    method: userIdToName,
    order: "",
    type: "select",
    options: "users"
  },
  {
    name: i18n.t("Creator"),
    show: true,
    key: "creator",
    method: userIdToName,
    order: "",
    type: "select",
    options: "users"
  },
  {
    name: i18n.t("Status"),
    show: true,
    key: "status",
    method: displayActivityStatus,
    order: "",
    type: "select",
    options: filterStatus(APPLICATION_TYPES.NCW)
  },
  {
    name: i18n.t("CreatedAt"),
    show: true,
    key: "created_at",
    method: activityDate,
    order: "",
    type: "datetime"
  },
  {
    name: i18n.t("UpdatedAt"),
    show: true,
    key: "updated_at",
    method: activityDate,
    order: "",
    type: "datetime"
  }
];
