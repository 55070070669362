import React from "react";
import Cron from "react-js-cron";
import { Field, ErrorMessage } from "formik";
import moment from "moment-timezone";
import { DateTimeInput, TimeInput } from "semantic-ui-calendar-react-yz";
import { isEmpty } from "lodash";
import Select from "react-select";
import SchedulerService from "../../../../services/data_services/scheduler";
import NotificationSelector from "../notificationSelector";
import TooltipLabel from "../TooltipLabel";
import TimezoneSelector from "../../../Account/TimezoneSelector";
import i18n from "../../../i18n";
import { isDesktop } from "../../../../services/general";

import "antd/dist/antd.min.css";

class SchedulersStep2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendars: undefined,
      cronText: "",
      timezone: this.props.values.cron_timezone_description
    };
    this.dateFormatString = "DD-MM-YYYY HH:mm";
    this.timeFormatString = "HH:mm";
  }

  componentDidMount() {
    this.loadCalendars();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.timezone && prevState.timezone !== this.state.timezone) {
      this.recalculateDates();
    }
  }

  setFieldValue = (field, value) => {
    this.props.setFieldValue(field, value);
    this.props.setFieldTouched(field, true);
  };

  /**
   * Recieves a moment object and returns an UTC string (2020-09-03T09:42:39.063Z).
   * @param m
   * @returns {string}
   */
  momentFormatToUTC(m = moment()) {
    return m.format();
  }

  enableField = fieldName => {
    this.props.setFieldValue(fieldName, true);
  };

  disableField = fieldName => {
    this.props.setFieldValue(fieldName, false);
  };

  fieldEnabled = fieldName => {
    return this.props.values[fieldName];
  };

  _yesChecked = fieldName => {
    return this.fieldEnabled(fieldName) ? "checked" : "";
  };

  _noChecked = fieldName => {
    return !this.fieldEnabled(fieldName) ? "checked" : "";
  };

  calendarOptions() {
    const { calendars } = this.state;
    const multipleServices = () => {
      const includedServices = [];
      for (const calendar of calendars) {
        if (!includedServices.includes(calendar.service_id)) {
          includedServices.push(calendar.service_id);
        }
      }
      return includedServices.length >= 2;
    };

    if (calendars) {
      return calendars.map(calendar => {
        return {
          label: multipleServices(calendars)
            ? `${calendar.name} - ${calendar.service_name}`
            : calendar.name,
          value: {
            calendar_name: calendar.name,
            service_id: calendar.service_id
          }
        };
      });
    }

    return [];
  }

  recalculateDates = () => {
    if (this.props.values.start_date) {
      const newStartDate = moment(this.props.values.start_date).tz(this.state.timezone).format();
      this.setFieldValue("start_date", newStartDate);
    }
    if (this.props.values.end_date) {
      const newEndDate = moment(this.props.values.end_date).tz(this.state.timezone).format();
      this.setFieldValue("end_date", newEndDate);
    }
  };

  setTimezoneValue = event => {
    const timezoneOffset =
      -moment.tz.zone(event.value).utcOffset(moment()) / 60;
    this.setFieldValue("cron_timezone", timezoneOffset);
    this.setFieldValue("cron_timezone_description", event.value);
    this.setState({ timezone: event.value });
  };

  calendarValue() {
    const { bp_calendar } = this.props.values;
    if (bp_calendar) {
      const calendars = this.calendarOptions();
      const result = calendars.filter(calendar => {
        const serviceId =
          typeof bp_calendar.service_id === "string"
            ? parseInt(bp_calendar.service_id, 0)
            : bp_calendar.service_id;
        return (
          bp_calendar.calendar_name === calendar.value.calendar_name &&
          serviceId === calendar.value.service_id
        );
      });
      return result;
    }
    return [];
  }

  setDateTime(name, value) {
    if (value) {
      const parsedValue = moment.tz(
        value,
        this.dateFormatString,
        this.state.timezone
      );
      const stringValue = this.momentFormatToUTC(parsedValue) || "";
      this.setFieldValue(name, stringValue);
    }
  }

  setTime = (name, value) => {
    if (!value) {
      this.setFieldValue(name, null);
    } else {
      this.setFieldValue(
        name,
        moment.utc(value, this.timeFormatString).format()
      );
    }
  };

  loadCalendars = () => {
    let servicePromisses = [];

    const removeDuplicatedCalendars = (value, index, self) => {
      const currentIndex = self.findIndex(
        t => t.name === value.name && t.service_id === value.service_id
      );
      return currentIndex === index;
    };

    if (
      Array.isArray(this.props.values.processes) &&
      !isEmpty(this.props.values.processes)
    ) {
      servicePromisses = this.props.values.processes
        .filter(process =>
          Object.prototype.hasOwnProperty.call(process, "service_id")
        )
        .map(process => {
          return SchedulerService.loadCalendarOptions(process.service_id);
        });
    }

    Promise.all(servicePromisses).then(responses => {
      let calendars = [];

      responses.forEach(response => {
        calendars = calendars.concat(response.data);
      });

      calendars = calendars.flat().filter(removeDuplicatedCalendars);
      this.setState({ calendars });
    });
  };

  /**
   * Return value for time input
   * @param altValue
   * @returns {string}
   */
  fieldTimeValue(fieldName, altValue = "") {
    if (this.props.values[fieldName]) {
      return moment
        .utc(this.props.values[fieldName])
        .format(this.timeFormatString);
    }

    return altValue;
  }

  /**
   * Return value for datetime input
   * @param altValue
   * @returns {string}
   */
  fieldDateTimeValue(fieldName, altValue = "") {
    return this._inputDateTimeValue(
      this.props.values[fieldName],
      altValue,
      "date"
    );
  }

  /**
   * Returns vale for date or time input.
   * @param value
   * @param altValue
   * @param format 'date' or 'time'
   * @returns {string}
   */
  _inputDateTimeValue(value, altValue = undefined, format = "date") {
    if (value) {
      const auxMoment = moment
        .tz(value, this.state.timezone)
        .format(
          format === "date" ? this.dateFormatString : this.timeFormatString
        );

      return auxMoment;
    }

    return altValue;
  }

  firstValue() {
    return this.fieldDateTimeValue("start_date");
  }

  endValue() {
    return this.fieldDateTimeValue("end_date");
  }

  _minDate(fieldName) {
    const startDate = this.fieldDateTimeValue(fieldName);
    const momentStart = moment(startDate, this.dateFormatString);
    const momentCurrent = moment().tz(this.state.timezone);

    if (!startDate) {
      return momentCurrent.format(this.dateFormatString);
    }

    return momentStart < momentCurrent
      ? momentStart.format(this.dateFormatString)
      : momentCurrent.format(this.dateFormatString);
  }

  firstMinDate() {
    return this._minDate("start_date");
  }

  endMinDate() {
    const startDate = this.fieldDateTimeValue("start_date");
    const momentStart = moment(startDate, this.dateFormatString);
    const momentCurrent = moment().tz(this.state.timezone);
    if (!startDate) {
      return momentCurrent.format(this.dateFormatString);
    }

    return momentStart.format(this.dateFormatString);
  }

  firstInitialDate() {
    return this.fieldDateTimeValue("start_date");
  }

  endInitialDate() {
    return this.fieldDateTimeValue(
      "end_date",
      this.fieldDateTimeValue("start_date")
    );
  }

  getCronTranslation = cronExpression => {
    SchedulerService.getCronText(cronExpression)
      .then(response => {
        this.setState({ cronText: response.data });
      })
      .catch(() => {
        this.setState({ cronText: "" });
      });
  };

  render() {
    const fieldClass = isDesktop() ? "inline fields" : "field";
    const errorClass = isDesktop() ? "inline error" : "mobile error";
    return (
      <div className="stepContainer">
        <div className={fieldClass}>
          <label>{i18n.t("Timezone")}</label>
          <div className="field full-width">
            <TimezoneSelector
              defaultTimezone={this.props.values.cron_timezone_description}
              onChange={e => {
                this.setTimezoneValue(e);
              }}
            />
          </div>
        </div>
        <div className={fieldClass}>
          <TooltipLabel
            label={i18n.t("FirstRun")}
            content={i18n.t("FirstRunTooltip")}
            isRequired
          />
          <div className="field full-width">
            <DateTimeInput
              name="start_date"
              placeholder={i18n.t("DateTime")}
              animation="none"
              value={this.firstValue()}
              minDate={this.firstMinDate()}
              initialDate={this.firstInitialDate()}
              iconPosition="left"
              closable
              preserveViewMode={false}
              onChange={(event, { name, value }) => {
                this.setDateTime(name, value);
              }}
            />
          </div>
        </div>
        <ErrorMessage
          name="start_date"
          component="div"
          className={errorClass}
        />

        <div className={fieldClass}>
          <TooltipLabel
            label={i18n.t("LastRun")}
            content={i18n.t("LastRunTooltip")}
            isRequired
          />
          <div className="field full-width">
            <DateTimeInput
              name="end_date"
              placeholder={i18n.t("DateTime")}
              value={this.endValue()}
              minDate={this.endMinDate()}
              initialDate={this.endInitialDate()}
              animation="none"
              iconPosition="left"
              closable
              preserveViewMode={false}
              onChange={(event, { name, value }) => {
                this.setDateTime(name, value);
              }}
            />
          </div>
        </div>
        <ErrorMessage name="end_date" component="div" className={errorClass} />

        <div className={fieldClass}>
          <TooltipLabel
            label={i18n.t("ExceptHolidaysInCalendar")}
            content={i18n.t("HolidaysTooltip")}
          />
          <div className="field full-width">
            <Select
              value={this.calendarValue()}
              options={this.calendarOptions()}
              className="react-select-container"
              classNamePrefix="react-select"
              isClearable
              onChange={option => {
                this.setFieldValue(
                  "bp_calendar",
                  option ? option.value : undefined
                );
              }}
            />
          </div>
        </div>

        <h4 className="ui dividing header">{i18n.t("Schedule")}</h4>
        <Cron
          clearButton={false}
          value={this.props.values.cron_expression}
          setValue={expression => {
            this.setFieldValue("cron_expression", expression);
            this.getCronTranslation(expression);
          }}
        />
        <div className="cronDescription">{this.state.cronText}</div>
        <span className="cronExplanation">
          <i className="info circle icon" />
          {i18n.t("WhenComponentMessage")}
        </span>
        <ErrorMessage name="cron" component="div" className={errorClass} />

        <div className={fieldClass}>
          <TooltipLabel
            label={i18n.t("Between")}
            content={i18n.t("BetweenTooltip")}
          />
          <div className="field full-width">
            <TimeInput
              name="between_from"
              placeholder={i18n.t("Time")}
              value={this.fieldTimeValue("between_from")}
              iconPosition="left"
              clearable
              closable
              animation="none"
              onChange={(event, { name, value }) => {
                this.setTime(name, value);
              }}
            />
          </div>
          <div className="field full-width">
            <TimeInput
              name="between_to"
              placeholder="Time"
              value={this.fieldTimeValue("between_to")}
              iconPosition="left"
              clearable
              closable
              animation="none"
              onChange={(event, { name, value }) => {
                this.setTime(name, value);
              }}
            />
          </div>
        </div>
        <ErrorMessage
          name="between_from"
          component="div"
          className={errorClass}
        />
        <ErrorMessage
          name="between_to"
          component="div"
          className={errorClass}
        />

        <h4 className="ui dividing header">{i18n.t("Timeout")}</h4>

        <div className={isDesktop() ? "inline inlineField fields" : "field"}>
          <TooltipLabel
            label={i18n.t("Timeout")}
            content={i18n.t("TimeoutTooltip")}
            isRequired
          />
          <div className="field">
            <Field name="deadline" type="number" min={1} />
            <label className="minutesLabel">{i18n.t("Minutes")}</label>
          </div>
        </div>
        <ErrorMessage name="deadline" component="div" className={errorClass} />
        <NotificationSelector
          notifications={this.props.notifications}
          user={this.props.user}
          values={this.props.values}
          setFieldValue={this.setFieldValue}
          fieldName="deadline"
          tooltipContent={i18n.t("TimeoutNotificationTooltip")}
          isRequired
        />
        <ErrorMessage
          name="deadline_type"
          component="div"
          className={errorClass}
        />
      </div>
    );
  }
}

export default SchedulersStep2;
