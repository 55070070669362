import React from "react";
import { Field, ErrorMessage } from "formik";
import { isEmpty } from "lodash";
import Select from "react-select";
import TooltipLabel from "../TooltipLabel";
import ServiceService from "../../../../services/data_services/service";
import ApplicationService from "../../../../services/data_services/application";
import i18n from "../../../i18n";
import { isDesktop } from "../../../../services/general";

import "stylesheets/Scheduler/Schedulers.scss";
import { RPA_TYPES } from "../../../../constants/service-types";

class Process extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [],
      resourcePools: [],
      processes: undefined,
      filteredPools: undefined
    };
  }

  componentDidMount() {
    this.loadServices();
  }

  getElementsFromService = serviceId => {
    this.filterResourcePoolsByService(serviceId);
    this.loadRpaProcesses(serviceId);
  };

  getValue = field => {
    return this.props.values.processes[this.props.index][field];
  };

  setFieldValue(field, value) {
    this.props.setFieldValue(this.fieldName(field), value);
    this.props.setFieldTouched(this.fieldName(field), true);
  }

  fieldName(field) {
    return `processes[${this.props.index}].${field}`;
  }

  loadServices() {
    ServiceService.loadServices().then(response => {
      this.setState(
        {
          services: response.data
        },
        () => {
          this.loadResourcePools();
          const serviceId = this.getValue("service_id");
          if (serviceId) {
            this.loadRpaProcesses(serviceId);
          }
        }
      );
    });
  }

  loadResourcePools = () => {
    ApplicationService.loadResourcesPools(this.props.values.company_id)
      .then(response => {
        this.setState(
          {
            resourcePools: response.data
          },
          () => {
            const serviceId = this.getValue("service_id");
            if (serviceId) {
              this.filterResourcePoolsByService(serviceId);
            }
          }
        );
      })
      .catch(() => {
        this.setState({
          resourcePools: []
        });
      });
  };

  findServiceFromId = serviceId => {
    return this.state.services.find(service => service.id === serviceId);
  };

  filterResourcePoolsByService = serviceId => {
    const { resourcePools } = this.state;
    if (resourcePools && !isEmpty(resourcePools) && serviceId) {
      const selectedService = this.findServiceFromId(serviceId);
      if (selectedService) {
        const selectedPools = resourcePools[selectedService.name];
        if (!selectedPools) {
          this.setState({ filteredPools: [] });
        } else {
          const selectedPoolsList = selectedPools.map(pool => {
            return {
              label: pool.number_resources
                ? `${pool.name} (${pool.number_resources})`
                : pool.name,
              value: pool.name
            };
          });
          this.setState({
            filteredPools: selectedPoolsList
          });
        }
      } else {
        this.setFieldValue("service_id", undefined);
      }
    } else {
      const resourceKeys = Object.keys(resourcePools);
      const auxPoolArray = [];
      for (const key of resourceKeys) {
        auxPoolArray.push(...resourcePools[key]);
      }
      const selectedPoolsList = auxPoolArray.map(pool => {
        return {
          label: pool.number_resources
            ? `${pool.name} (${pool.number_resources})`
            : pool.name,
          value: pool.name
        };
      });
      this.setState({
        filteredPools: selectedPoolsList
      });
    }
  };

  loadRpaProcesses(serviceId) {
    if (serviceId) {
      ApplicationService.loadRpaProcesses(serviceId)
        .then(result => {
          if (result.status !== 200) {
            throw new Error("Rpa Processes: Not able to fetch");
          } else {
            this.setState({
              processes: result.data
            });
          }
        })
        .catch(() => {
          this.setState({
            processes: []
          });
        });
    } else {
      this.props.setFieldValue("process_entity.rpa_process", "");
      this.setState({
        processes: []
      });
    }
  }

  servicesDisabled() {
    return !this.props.editable || this.state.services === undefined;
  }

  servicesOption() {
    if (this.state.services) {
      const allowedServices = this.state.services.filter(service =>
        RPA_TYPES.includes(service.service_type)
      );
      return allowedServices.map(service => {
        return { label: service.name, value: service.id };
      });
    }

    return [];
  }

  servicesValue() {
    const result = this.servicesOption().filter(service => {
      const id = this.getValue("service_id");
      return (
        id !== undefined && parseInt(id, 10) === parseInt(service.value, 10)
      );
    });

    return result;
  }

  resourcesPoolsDisabled() {
    return (
      !this.props.editable ||
      !this.state.filteredPools ||
      isEmpty(this.state.filteredPools)
    );
  }

  resourcesPoolsValue() {
    if (this.state.filteredPools) {
      const resourcePools = this.getValue("resource_pools");
      return this.state.filteredPools.filter(resource => {
        return resourcePools.includes(resource.value);
      });
    }
    return [];
  }

  processItemsDisabled() {
    return !this.props.editable || this.state.processes === undefined;
  }

  processItemsOptions() {
    if (this.state.processes) {
      return this.state.processes.map(process => {
        return { value: process.name, label: process.name };
      });
    }

    return [];
  }

  processItemsValue() {
    return this.processItemsOptions().filter(
      option => option.value === this.getValue("process_name")
    );
  }

  renderProcessHeader = () => {
    if (isDesktop()) {
      return (
        <h4 className="ui dividing header">
          {i18n.t("Process")} #{this.props.index + 1}
        </h4>
      );
    }
    return (
      <div className="processHeaderMobile">
        <h4 style={{ flexGrow: "2" }} className="ui dividing header">
          {i18n.t("Process")} #{this.props.index + 1}
        </h4>
        <div className="buttonContainer">
          <button
            type="button"
            className="ui mini icon basic grey button"
            disabled={!this.props.editable}
            onClick={() => {
              this.props.removeProcesses(this.props.index);
            }}
          >
            <i className="icon minus" />
          </button>
        </div>
      </div>
    );
  };

  render() {
    const inlineClasses = isDesktop() ? "inline inlineField" : "";
    const errorClass = isDesktop() ? "inline error" : "mobile error";
    return (
      <div className="schedulerProcess">
        <div className="fieldsContainer">
          {this.renderProcessHeader()}
          <div className={`required ${inlineClasses} field`}>
            <label>{i18n.t("Service")}</label>
            <Select
              isClearable
              value={this.servicesValue()}
              options={this.servicesOption()}
              onChange={e => {
                const value = e ? e.value : undefined;
                this.setFieldValue("service_id", value);
                this.getElementsFromService(value);
              }}
              isDisabled={this.servicesDisabled()}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>
          <ErrorMessage
            name={this.fieldName("service_id")}
            component="div"
            className={errorClass}
          />

          <div className={`required ${inlineClasses} field`}>
            <label>{i18n.t("ProcessName")}</label>
            <Select
              isClearable
              value={this.processItemsValue()}
              options={this.processItemsOptions()}
              onChange={process => {
                const value = process ? process.value : undefined;
                this.setFieldValue("process_name", value);
                this.setFieldValue("data", undefined);
              }}
              isDisabled={this.processItemsDisabled()}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>
          <ErrorMessage
            name={this.fieldName("process_name")}
            component="div"
            className={errorClass}
          />

          <div className={`required ${inlineClasses} field`}>
            <label>{i18n.t("MaxResources")}</label>
            <Field
              name={this.fieldName("max_resources")}
              style={{ width: "100%" }}
              type="number"
              min={1}
              disabled={!this.props.editable}
            />
          </div>
          <ErrorMessage
            name={this.fieldName("max_resources")}
            component="div"
            className={errorClass}
          />

          <div className={`${inlineClasses} field`}>
            <TooltipLabel
              label={i18n.t("ResourcePool")}
              content={i18n.t("ResourcePoolTooltip")}
              isRequired
            />
            <Select
              value={this.resourcesPoolsValue()}
              options={this.state.filteredPools}
              isMulti
              onChange={e => {
                this.setFieldValue(
                  "resource_pools",
                  e ? e.map(resource => resource.value) : []
                );
              }}
              isDisabled={this.resourcesPoolsDisabled()}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>
          <ErrorMessage
            name={this.fieldName("resource_pools")}
            component="div"
            className={errorClass}
          />
        </div>
        {isDesktop() && (
          <div className="buttonContainer">
            <button
              type="button"
              className="ui mini icon basic grey button"
              disabled={!this.props.editable}
              onClick={() => {
                this.props.removeProcesses(this.props.index);
              }}
            >
              <i className="icon minus" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Process;
