export const toIntArray = element => {
  let array = [];

  if (element) {
    array = Array.isArray(element) ? element : [element];
  }

  array = array.map(arrayElement => parseInt(arrayElement, 10));

  return array;
};
