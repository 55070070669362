import React from "react";
import { Icon } from "semantic-ui-react";

const ProcessTick = props => {
  return props.tick ? (
    <Icon name="check circle outline" size="big" style={{ color: "green" }} />
  ) : (
    <Icon name="times circle outline" size="big" style={{ color: "red" }} />
  );
};

export default ProcessTick;
