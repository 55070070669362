/* eslint-disable react/prefer-stateless-function */
import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { CompactPicker  } from 'react-color';

import i18n from "../../../../i18n";
import { Icon } from "semantic-ui-react";

const ColorPicker = props => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

  return (
    <div style={{width:'100%', maxWidth:'30px' }}>
        <div onClick={()=>{setDisplayColorPicker(true)}} style={{backgroundColor: props.color}} className="colorbutton" >
        </div>
        { displayColorPicker ? 
                <div className={`popover ${props.last && 'last'}`}>
                    <div className="cover" onClick={()=>{ setDisplayColorPicker(false)} }/>
                    <CompactPicker color={ props.color } onChange={ x =>{props.onChange(x, props.index); setDisplayColorPicker(false)} } />
                </div> 
            : null }
    </div>
  );
};

export default ColorPicker;
