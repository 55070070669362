import axios from "../axios";

class CredentialService {
  static getCredentials = params => {
    return axios.get("/credentials", { params });
  };

  static createCredential = params => {
    return axios.post("/credentials", params);
  };

  static updateCredentials = params => {
    return axios.put("/credentials/update", params);
  };

  static deleteCredential = params => {
    return axios.delete("/credentials/destroy", { data: params });
  };
}

export default CredentialService;
