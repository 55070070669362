import React from "react";
import moment from "moment";
import { debounce, union } from "lodash";
import Cookies from "js-cookie";
import ProcessGrid from "./ProcessGrid";
import i18n from "../i18n";
import ApplicationService from "../../services/data_services/application";
import UserSettingsService from "../../services/data_services/userSettings";
import { generateUuidFrom } from "../../services/uuid";
import { TYPES } from "../Ui/Table/types";
import { initTab } from "../../semantic-ui";
import LaunchFilter from "./LaunchFilter";
import { tabNameBasedOnPathName, tabActive } from "../../services/url";

class LaunchProcess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: undefined,
      settingExist: false,
      isSearching: false,
      showFilter: this.showFilter(),
      nPages: 0
    };

    this.userId = parseInt(Cookies.get("ID"), 10);

    this.tableLaunchUuid = generateUuidFrom(TYPES.LAUNCH);
    this.loadApplications();
    this.setBreadCrumb = this.setBreadCrumb.bind(this);
    this.tabNameBasedOnPathName = this.tabNameBasedOnPathName.bind(this);
  }

  showFilter() {
    return this.props.location.pathname.includes("launch");
  }

  componentDidMount() {
    initTab();
    this.setBreadCrumb();
  }

  setBreadCrumb(tabName = this.tabNameBasedOnPathName()) {
    if (tabName === "executions") {
      this.props.setBreadcrumb([
        {
          title: i18n.t("Executions"),
          location: "/executions"
        }
      ]);
    } else {
      this.props.setBreadcrumb([
        {
          title: i18n.t("Launcher"),
          location: "/launch"
        }
      ]);
    }
  }

  // eslint-disable-next-line react/sort-comp
  loadApplications = debounce((search_param, page) => {
    this.setState({ isSearching: search_param && search_param.length > 0 });

    ApplicationService.loadLaunchApplications({
      search_param,
      page
    }).then(applicationsResponse => {
      const applications = applicationsResponse.data.results;

      this.setState({
        nPages: applicationsResponse.data.total
      });

      UserSettingsService.loadUsersSettings(this.userId, this.tableLaunchUuid)
        .then(settingsResponse => {
          if (this.state.isSearching) {
            throw "Filter disabled";
          }

          const newerAppIds = applications
            .filter(app => {
              return (
                moment(app.created_at) >
                moment(settingsResponse.data.updated_at)
              );
            })
            .map(application => application.id);

          this.setState(
            {
              settingExist: true
            },
            () => {
              // Do not save settings when we just load them.
              this.setApplicationsToShow(
                union(settingsResponse.data.columns, newerAppIds),
                applications,
                false
              );
            }
          );
        })
        .catch(() => {
          // Do not save settings when we just load them.
          this.setApplicationsToShow(
            applications.map(application => application.id),
            applications,
            false
          );
        });
    });
  }, 500);

  columnsToSent = () => {
    return this.state.applications
      .filter(column => column.show)
      .map(application => application.id);
  };

  setApplicationsToShow = (applicationIds, applications, saveUserConfig = true) => {
    const intIds = applicationIds.map(id => parseInt(id, 10));

    const appsToReturn = applications.map(application => {
      const applicationCopy = { ...application };
      applicationCopy.show = intIds.includes(parseInt(application.id, 10));
      return applicationCopy;
    });

    this.sortApplications(intIds, appsToReturn, saveUserConfig);
  };

  /**
   *
   * @param ids
   * @param applications
   * @param saveUserConfig Indicates wheter changes will be saved or omited
   */
  sortApplications = (ids, applications, saveUserConfig = true) => {
    const sortedApplications = [];
    const allIds = [...ids];
    let applicationsToSort = [...applications];

    applications.forEach(application => {
      if (!allIds.includes(application.id)) {
        allIds.push(application.id);
      }
    });

    allIds.forEach(id => {
      let found = false;
      applicationsToSort = applicationsToSort.filter(application => {
        if (!found && application.id === id) {
          sortedApplications.push({ ...application });
          found = true;
          return false;
        }
        return true;
      });
    });

    this.setState(
      {
        applications: sortedApplications
      },
      () => {
        if (!this.state.isSearching && saveUserConfig) {
          if (this.state.settingExist) {
            UserSettingsService.updateUserSettings(
              this.props.user.id,
              this.tableLaunchUuid,
              this.columnsToSent()
            );
          } else {
            UserSettingsService.createUserSettings(
              this.props.user.id,
              this.tableLaunchUuid,
              this.columnsToSent()
            ).then(this.setState({ settingExist: true }));
          }
        }
      }
    );
  };

  renderFilter() {
    return (
      <LaunchFilter
        applications={this.state.applications}
        setApplicationsToShow={this.setApplicationsToShow}
        customStyle={{
          position: "absolute",
          right: "60px",
          top: "20px"
        }}
      />
    );
  }

  columns = () => {
    return this.state.applications
      ? this.state.applications.map(application => {
          return {
            name: application.name,
            show: application.show,
            key: `${application.id}`
          };
        })
      : [];
  };

  active = name => {
    return tabActive(this.props.location.pathname, name);
  };

  tabNameBasedOnPathName() {
    return tabNameBasedOnPathName(this.props.location.pathname);
  }

  applicationsToShow() {
    return this.state.applications
      ? this.state.applications.filter(application => application.show)
      : undefined;
  }

  render() {
    return (
      <div className="component-wrapper">
        {/* <div className="ui segment noPadding">
          <div
            className="ui secondary pointing menu"
            style={{ height: "46px" }}
          >
            <a
              className={`item ${this.active("launch")}`}
              data-tab="launch"
              onClick={() =>
                this.setState(
                  {
                    showFilter: true
                  },
                  () => {
                    this.setBreadCrumb("launch");
                    window.history.pushState("", "", "/launch");
                  }
                )
              }
            >
              {i18n.t("LaunchProcess")}
            </a>
            <div className="right menu">
              <div className="ui item">{this.renderFilter()}</div>
            </div>
          </div>
        </div> */}

        <div className={`ui tab ${this.active("launch")}`} data-tab="launch">
          <ProcessGrid
            setApplicationsToShow={this.setApplicationsToShow}
            applicationsInit={this.state.applications}
            loadApplications={this.loadApplications}
            applications={this.applicationsToShow()}
            nPages={this.state.nPages}
          />
        </div>
      </div>
    );
  }
}

export default LaunchProcess;
