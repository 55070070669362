import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { cloneDeep, isEmpty } from "lodash";
import { DateInput } from "semantic-ui-calendar-react-yz";
import { Grid, Radio } from "semantic-ui-react";
import moment from "moment-timezone";
import i18n from "../../i18n";
import StepContainer from "./StepContainer";
import InputField from "../../Ui/InputField";
import InputDropdown from "../../Ui/InputDropdown";
import TableActionButton from "../../Ui/TableActionButton";
import DatePickerForm from "../../Ui/DatePickerFormContainer";
import { getDateFormat } from "../../../services/timezones";
import NcwService from "../../../services/data_services/ncw";

const requiredFields = [
  "engagement_type",
  "estimated_effective_date",
  // "partner_in_charge_name",
  "estimated_end_date",
  "prod_budget",
  "billing_budget",
  "period_type",
  "avii_partner",
  "avii_manager",
  "period_start_year",
  "period_end_year"
];


const TaxOptions = [
  { id: undefined, key: '1040_2020', text: '1040 Individual Tax (2020)', value: '1040_2020' }, 
  { id: undefined, key: '1041_2020', text: '1041 Fiduciary Tax (2020)', value: '1041_2020' },
  { id: undefined, key: '1040_2021', text: '1040 Individual Tax (2021)', value: '1040_2021' }, 
  { id: undefined, key: '1041_2021', text: '1041 Fiduciary Tax (2021)', value: '1041_2021' }
]

const startingValues = {
  pe_job: false,
  recurring: true,
  // acceptance_decision: false,
  fee_collection: false
};

const EngagementInfo = props => {
  const dateFormatString = getDateFormat(props.user.timezone);
  const [formFields, setFormFieldsValue] = useState(props?.data?.engagement_info || startingValues);
  // const [loadingPartnersInCharge, setLoadingPartnersInCharge] = useState(false);
  const [loadingJobPartners, setLoadingJobPartners] = useState(false);
  const [loadingJobManagers, setLoadingJobManagers] = useState(false);
  // const [partnerInChargeOptions, setPartnerInChargeOptions] = useState({fullList: [], dummyList: []});
  const [jobPartnerOptions, setJobPartnerOptions] = useState({fullList: [], dummyList: []});
  const [jobManagerOptions, setJobManagerOptions] = useState({fullList: [], dummyList: []});

  const [loadingAvii, setLoadingAvii] = useState(false);
  const [loadingSalesChannels, setLoadingSalesChannels] = useState(false);
  const [aviiStaffOptions, setAviiStaffOptions] = useState({fullList: [], dummyList: []});
  const [salesChannelOptions, setSalesChannelOptions] = useState({fullList: [], dummyList: []});

  const [loadingEntityType, setLoadingEntityType] = useState(false);
  const [entityTypeOptions, setEntityTypeOptions] = useState({fullList: [], dummyList: []});

  const [loadingPeriodEnd, setLoadingPeriodEnd] = useState(false);
  const [periodEndOptions, setPeriodEndOptions] = useState({fullList: [], dummyList: []});


  useEffect(() => {
    if(!isEmpty(props?.data) && !isEmpty(props?.data?.engagement_info) ){
      const tempValues = cloneDeep(formFields);
      tempValues.engagement_type = props.data.engagement_info.engagement_type;
      setFormFieldsValue(tempValues);
    }
  }, [props?.data?.engagement_info?.engagement_type]);

  // useEffect(() => {
  //   if (loadingPartnersInCharge) {
  //     loadPartnersInCharge();
  //   }
  // }, [loadingPartnersInCharge]);

  useEffect(() => {
    if (loadingJobPartners) {
      loadJobPartners();
    }
  }, [loadingJobPartners]);

  useEffect(() => {
    if (loadingJobManagers) {
      loadJobManagers();
    }
  }, [loadingJobManagers]);

  useEffect(() => {
    if (props.newClient) {
      setFormFieldsValue(startingValues);
    }
  }, [props.newClient]);

  useEffect(() => {
    if (loadingSalesChannels) {
      loadSalesChannels()
    }
  }, [loadingSalesChannels])

  useEffect(() => {
    if (loadingEntityType) {
      loadEntityType()
    }
  }, [loadingEntityType])

  useEffect(() => {
    if (loadingPeriodEnd) {
      loadPeriodEnd()
    }
  }, [loadingPeriodEnd])


  const onlyNumber = (value) => {
    if (!value) return value;
    return value.replace(/[^\d]/g, '');
  }

  const onChangeField = (target, value) => {
    const tempValues = cloneDeep(formFields);
    tempValues[target] = value;
    setFormFieldsValue(tempValues);
    props.onChange('engagement_info', tempValues);
  };

  // const loadPartnersInCharge = () => {
  //   NcwService.getPartnersInCharge(props.application.uuid)
  //     .then(response => {
  //       const auxOptions = response.data.map(item => {
  //         return {
  //           key: item.StaffEMail,
  //           id: item.StaffIndex,
  //           text: item.StaffName,
  //           value: item.StaffName
  //         };
  //       });
  //       setPartnerInChargeOptions({fullList:auxOptions, dummyList:auxOptions });
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       setPartnersInChargeFromInit(false);
  //       setLoadingPartnersInCharge(false);
  //     });
  // };

  const loadJobPartners = () => {
    NcwService.getJobPartners(props.application.uuid)
      .then(response => {
        const auxOptions = response.data.map(item => {
          return {
            key: item.StaffEMail,
            text: item.StaffName,
            id: item.StaffIndex,
            value: item.StaffName
          };
        });
        setJobPartnerOptions({fullList:auxOptions, dummyList:auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingJobPartners(false);
      });
  };

  const loadJobManagers = () => {
    NcwService.getJobManagers(props.application.uuid)
      .then(response => {
        const auxOptions = response.data.map(item => {
          return {
            key: item.StaffEMail,
            text: item.StaffName,
            id: item.StaffIndex,
            value: item.StaffName
          };
        });
        setJobManagerOptions({fullList:auxOptions, dummyList:auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingJobManagers(false);
      });
  };
  
  useEffect(() => {
    if (loadingAvii) {
      loadAviiStaff()
    }
  }, [loadingAvii])


  const loadAviiStaff = () => {
    NcwService.getAviiStaff(props.application.uuid)
      .then(response => {
        const auxOptions = response.data.map(item => {
          return {
            key: item.StaffName,
            text: item.StaffName,
            value: item.StaffName
          }
        })
        setAviiStaffOptions({fullList:auxOptions, dummyList:auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingAvii(false);
      })
  }

  const loadSalesChannels = () => {
    NcwService.getSalesChannels(props.application.uuid)
      .then(response => {
        const auxOptions = response.data.map(item => {
          return {
            key: item.sales_channel,
            text: item.sales_channel,
            value: item.sales_channel
          }
        })
        setSalesChannelOptions({fullList:auxOptions, dummyList:auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingSalesChannels(false);
      })
  }

  const loadEntityType = () => {
    NcwService.getEntityType(props.application.uuid)
      .then(response => {
        // const auxOptions = 'pepe';
        const auxOptions = response.data.map(item => {
          return {
            key: item.AviiName,
            text: item.AviiName,
            id: item.AviiId,
            value: item.AviiName
          }
        })
        setEntityTypeOptions({fullList:auxOptions, dummyList:auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingEntityType(false);
      })
  }
  const loadPeriodEnd = () => {
    NcwService.getJobPeriods(props.application.uuid)
      .then(response => {
        //const auxOptions = 'pepe';
        const auxOptions = response.data.map(item => {
          return {
            key: item.Name,
            text: item.Name,
            id: item.Id,
            value: item.Name
          }
        })
        setPeriodEndOptions({fullList:auxOptions, dummyList:auxOptions });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingPeriodEnd(false);
      })
  }



  const onSelectChangeField = (target, value, partner = false, linked = false) =>{
    const tempValues = cloneDeep(formFields);
    let selectedOption = value.options.find(o => o.value === value.value)
    if(partner){
      tempValues[target+'_email'] = selectedOption?.key;
    }
    tempValues[`${target}${partner ? '_name': ''}`] = value.value;
    tempValues[target+'_id'] = selectedOption?.id;
    let externalForm = !linked ? false : {
      formTarget: "ncw_services",
      valueTarget: "tax_type",
      value: value.value,
      isSelect: true,
    }
    setFormFieldsValue(tempValues);
    props.onChange('engagement_info', tempValues, externalForm);
  }

  const getDateInputValue = value => moment.utc(value).format(dateFormatString);

  const setDateInputValue = (name, value) => {
    const toSave = value === '' ? '' : formatDateToSend(value);
    onChangeField(name, toSave);
  };

  const formatDateToSend = value =>
    moment
      .utc(value, dateFormatString)
      .startOf("day")
      .format();

    const stepContent = () => {
        return(
          <React.Fragment>
          <Grid columns={4} divided>
            <Grid.Row stretched>
              <Grid.Column>
                <InputDropdown
                  onClick={() => {
                    if (!loadingEntityType && entityTypeOptions?.fullList?.length === 0) {
                      setLoadingEntityType(true);
                    }
                    else{
                      setEntityTypeOptions({fullList: entityTypeOptions?.fullList, dummyList: entityTypeOptions?.fullList });
                    }
                  }}
                  loading={loadingEntityType}
                  required
                  error={props.errors?.engagement_info?.engagement_type}
                  clearable={false}
                  disabled={props.preview}
                  noResultsMessage={i18n.t("TryAnotherSearch")}
                  inputlabel={i18n.t("EngagementType")}
                  placeholder={i18n.t("StartTyping")}
                  options={entityTypeOptions?.dummyList}
                  search
                  selection
                  value={formFields?.engagement_type}
                  onChange={(e, value) =>{
                    onSelectChangeField('engagement_type', value, false, false)
                    setEntityTypeOptions({fullList: entityTypeOptions?.fullList, dummyList: [] });
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched style={{ borderTop: "1px solid #22242626" }}>
              <Grid.Column>
                <DatePickerForm 
                  error={props.errors?.engagement_info?.estimated_effective_date}
                  required 
                  inputlabel={i18n.t("EstimatedStartDate")} >
                  <DateInput
                    required                    
                    clearable={false}
                    disabled={props.preview}
                    name="effective_date"
                    dateFormat={dateFormatString}
                    value={formFields.estimated_effective_date ? getDateInputValue(formFields.estimated_effective_date) : ''}
                    iconPosition="left"
                    closable
                    autoComplete="off"
                    onChange={(event, { name, value }) => {
                      if(!props.preview) setDateInputValue("estimated_effective_date", value);
                    }}
                  />
                </DatePickerForm>
                <InputField
                    icon='dollar sign' 
                    iconPosition='left'
                    required
                    error={props.errors?.engagement_info?.prod_budget}
                    disabled={props.preview}
                    inputlabel={i18n.t("ProductionBudget")}
                    value={formFields?.prod_budget}
                    onChange={(e, { value }) =>onChangeField('prod_budget', onlyNumber(value) )}
                />
              </Grid.Column>
              <Grid.Column>
                  {/* <InputDropdown
                    onClick={() => {
                      if (!loadingPartnersInCharge && partnerInChargeOptions?.fullList?.length === 0) {
                        setLoadingPartnersInCharge(true);
                      }
                      else{
                        setPartnerInChargeOptions({fullList: partnerInChargeOptions?.fullList, dummyList: partnerInChargeOptions?.fullList });
                      }
                    }}
                    loading={loadingPartnersInCharge}
                    required
                    error={props.errors?.engagement_info?.partner_in_charge_name}
                    disabled={props.preview}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("Approver")}
                    placeholder={i18n.t("StartTyping")}
                    options={partnerInChargeOptions?.dummyList}
                    search
                    selection
                    value={formFields?.partner_in_charge_name}
                    onChange={(e, value) =>{
                      onSelectChangeField('partner_in_charge', value, true)
                      setPartnerInChargeOptions({fullList: partnerInChargeOptions?.fullList, dummyList: [] });
                    }}
                  /> */}
                <DatePickerForm
                  error={props.errors?.engagement_info?.estimated_end_date}
                  required 
                  inputlabel={i18n.t("EstimatedEndDate")} >
                  <DateInput
                    required
                    clearable={false}
                    disabled={props.preview}
                    minDate={formFields.estimated_effective_date ? getDateInputValue(formFields.estimated_effective_date) : ''}
                    name="end_date"
                    dateFormat={dateFormatString}
                    value={formFields.estimated_end_date ? getDateInputValue(formFields.estimated_end_date) : ''}
                    iconPosition="left"
                    closable
                    autoComplete="off"
                    onChange={(event, { name, value }) => {
                      if(!props.preview)  setDateInputValue('estimated_end_date', value );
                    }}
                  />
                </DatePickerForm>
                <InputField
                    icon='dollar sign' 
                    iconPosition='left'
                    required
                    error={props.errors?.engagement_info?.billing_budget}
                    disabled={props.preview}
                    inputlabel={i18n.t("BillingBudget")}
                    value={formFields?.billing_budget}
                    onChange={(e, { value }) => onChangeField('billing_budget', onlyNumber(value) )}
                />
              </Grid.Column>
              <Grid.Column>
                <InputField
                      disabled={props.preview}
                    inputlabel={i18n.t("CustomDescription")}
                    value={formFields?.custom_description}
                    onChange={(e,  { value }) =>onChangeField('custom_description', value )}
                    textarea
                />
              </Grid.Column>
              <Grid.Column>
                <InputField
                      disabled={props.preview}
                    inputlabel={i18n.t("CustomDeliverable")}
                    value={formFields?.custom_deliverable}
                    onChange={(e,  { value }) =>onChangeField('custom_deliverable', value )}
                    textarea
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <DatePickerForm 
                    error={props.errors?.engagement_info?.period_start_year}
                    required
                    inputlabel={i18n.t("PeriodStartYear")} >
                    <DateInput
                      required                    
                      clearable={false}
                      disabled={props.preview}
                      name="period_start_year"
                      dateFormat={dateFormatString}
                      value={formFields.period_start_year ? getDateInputValue(formFields.period_start_year) : ''}
                      iconPosition="left"
                      closable
                      autoComplete="off"
                      onChange={(event, { name, value }) => {
                        if(!props.preview) setDateInputValue("period_start_year", value);
                      }}
                    />
                </DatePickerForm>
              </Grid.Column>
              <Grid.Column>
                <DatePickerForm 
                    error={props.errors?.engagement_info?.period_end_year}
                    required
                    inputlabel={i18n.t("PeriodEndYear")} >
                    <DateInput
                      required                    
                      clearable={false}
                      disabled={props.preview}
                      name="period_end_year"
                      dateFormat={dateFormatString}
                      value={formFields.period_end_year ? getDateInputValue(formFields.period_end_year) : ''}
                      iconPosition="left"
                      closable
                      autoComplete="off"
                      onChange={(event, { name, value }) => {
                        if(!props.preview) setDateInputValue("period_end_year", value);
                      }}
                    />
                </DatePickerForm>
              </Grid.Column>
              <Grid.Column>
                <InputDropdown
                    onClick={() => {
                      if (!loadingPeriodEnd && periodEndOptions?.fullList?.length === 0) {
                        setLoadingPeriodEnd(true);
                      }
                      else{
                        setPeriodEndOptions({fullList: periodEndOptions?.fullList, dummyList: periodEndOptions?.fullList });
                      }
                    }}
                    loading={loadingPeriodEnd}
                    required
                    error={props.errors?.engagement_info?.period_type}
                    clearable={false}
                    disabled={props.preview}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("PeriodType")}
                    placeholder={i18n.t("StartTyping")}
                    options={periodEndOptions?.dummyList}
                    search
                    selection
                    value={formFields?.period_type}
                    onChange={(e, value) =>{
                      //onChangeField('period_type', value )
                      onSelectChangeField('period_type', value, false, false)
                      setPeriodEndOptions({fullList: periodEndOptions?.fullList, dummyList: [] });
                    }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched >
              <Grid.Column>
                <div className='question-container'>
                  <span>{i18n.t("OnlyPEJob")}</span>
                  <div>
                    <Radio
                      disabled={props.preview}
                      label='Yes'
                      checked={formFields?.pe_job === true}
                      onChange={(e,  { value }) =>onChangeField('pe_job', true )}
                    />
                    <Radio
                      disabled={props.preview}
                      label='No'
                      checked={formFields?.pe_job === false}
                      onChange={(e,  { value }) =>onChangeField('pe_job', false )}
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className='question-container'>
                  <span>{i18n.t("IsRecurring")}</span>
                  <div>
                    <Radio
                      disabled={props.preview}
                      label='Yes'
                      checked={formFields?.recurring === true}
                      onChange={(e,  { value }) =>onChangeField('recurring', true )}
                    />
                    <Radio
                      disabled={props.preview}
                      label='No'
                      checked={formFields?.recurring === false}
                      onChange={(e,  { value }) =>onChangeField('recurring', false )}
                    />
                  </div>
                </div>
              </Grid.Column>
              {/* <Grid.Column>
                <div className='question-container'>
                  <span>{i18n.t("AcceptanceDecision")}:</span>
                  <div>
                    <Radio
                      disabled={props.preview}
                      label='Yes'
                      checked={formFields?.acceptance_decision === true}
                      onChange={(e,  { value }) =>onChangeField('acceptance_decision', true )}
                    />
                    <Radio
                      disabled={props.preview}
                      label='No'
                      checked={formFields?.acceptance_decision === false}
                      onChange={(e,  { value }) =>onChangeField('acceptance_decision', false )}
                    />
                  </div>
                </div>
              </Grid.Column>   */}
              <Grid.Column>
                <div className='question-container'>
                  <span>{i18n.t("FeeCollectionProblems")}​</span>
                  <div>
                    <Radio
                      disabled={props.preview}
                      label='Yes'
                      checked={formFields?.fee_collection === true}
                      onChange={(e,  { value }) =>onChangeField('fee_collection', true )}
                    />
                    <Radio
                      disabled={props.preview}
                      label='No'
                      checked={formFields?.fee_collection === false}
                      onChange={(e,  { value }) =>onChangeField('fee_collection', false )}
                    />
                  </div>
                </div>
              </Grid.Column>        
            </Grid.Row>
            <Grid.Row className='Form-section' stretched >
              <Grid.Column>
                <div className='section-info tex-info'>{i18n.t("Roles")}</div>
                <InputDropdown
                    onClick={() => {
                      if (!loadingJobManagers && jobManagerOptions?.fullList?.length === 0) {
                        setLoadingJobManagers(true);
                      }
                      else{
                        setJobManagerOptions({fullList: jobManagerOptions?.fullList, dummyList: jobManagerOptions?.fullList });
                      }
                    }}
                    loading={loadingJobManagers}
                    required
                    error={props.errors?.engagement_info?.avii_manager}
                    disabled={props.preview}
                    noResultsMessage={i18n.t("TryAnotherSearch")}
                    inputlabel={i18n.t("JobManager")}
                    placeholder={i18n.t("StartTyping")}
                    options={jobManagerOptions?.dummyList}
                    search
                    selection
                    value={formFields?.avii_manager}
                    onChange={(e, value) =>{
                      onSelectChangeField('avii_manager', value)
                      setJobManagerOptions({fullList: jobManagerOptions?.fullList, dummyList: [] });
                    }}
                />
              </Grid.Column>
              <Grid.Column>
                  <InputDropdown
                      onClick={() => {
                        if (!loadingJobPartners && (jobPartnerOptions?.fullList?.length === 0)) {
                          setLoadingJobPartners(true);
                        }
                        else{
                          setJobPartnerOptions({fullList: jobPartnerOptions?.fullList, dummyList: jobPartnerOptions?.fullList });
                        }
                      }}
                      loading={loadingJobPartners}
                    required
                    error={props.errors?.engagement_info?.avii_partner}
                    disabled={props.preview}
                      noResultsMessage={i18n.t("TryAnotherSearch")}
                      inputlabel={i18n.t("JobPartner")}
                      placeholder={i18n.t("StartTyping")}
                      options={jobPartnerOptions?.dummyList}
                      search
                      selection
                      value={formFields?.avii_partner}
                      onChange={(e, value) =>{
                        onSelectChangeField('avii_partner', value)
                        setJobPartnerOptions({fullList: jobPartnerOptions?.fullList, dummyList: [] });
                      }}
                  />
              </Grid.Column>
              <Grid.Column>
                  <InputDropdown
                      onClick={() => {
                        if (!loadingAvii && aviiStaffOptions?.fullList?.length === 0) {
                          setLoadingAvii(true);
                        }
                        else{
                          setAviiStaffOptions({fullList: aviiStaffOptions?.fullList, dummyList: aviiStaffOptions?.fullList });
                        }
                      }}
                      loading={loadingAvii}
                    disabled={props.preview}
                      noResultsMessage={i18n.t("TryAnotherSearch")}
                      inputlabel={i18n.t("Delivery")}
                      placeholder={i18n.t("StartTyping")}
                      options={aviiStaffOptions?.dummyList}
                      clearable
                      search
                      selection
                      value={formFields?.avii_delivery}
                      onChange={(e, value) =>{
                        onSelectChangeField('avii_delivery', value)
                        setAviiStaffOptions({fullList: aviiStaffOptions?.fullList, dummyList: [] });
                      }}
                  />
              </Grid.Column>
              <Grid.Column>
                  <InputDropdown
                      onClick={() => {
                        if (!loadingAvii && aviiStaffOptions?.fullList?.length === 0) {
                          setLoadingAvii(true);
                        }
                        else{
                          setAviiStaffOptions({fullList: aviiStaffOptions?.fullList, dummyList: aviiStaffOptions?.fullList });
                        }
                      }}
                      loading={loadingAvii}
                    disabled={props.preview}
                      noResultsMessage={i18n.t("TryAnotherSearch")}
                      inputlabel={i18n.t("Preparer")}
                      placeholder={i18n.t("StartTyping")}
                      options={aviiStaffOptions?.dummyList}
                      clearable
                      search
                      selection
                      value={formFields?.avii_preparer}
                      onChange={(e, value) =>{
                        onSelectChangeField('avii_preparer', value)
                        setAviiStaffOptions({fullList: aviiStaffOptions?.fullList, dummyList: [] });
                      }}
                  />
              </Grid.Column>
              <Grid.Column>
                  <InputDropdown
                      onClick={() => {
                        if (!loadingAvii && aviiStaffOptions?.fullList?.length === 0) {
                          setLoadingAvii(true);
                        }
                        else{
                          setAviiStaffOptions({fullList: aviiStaffOptions?.fullList, dummyList: aviiStaffOptions?.fullList });
                        }
                      }}
                      loading={loadingAvii}
                      disabled={props.preview}
                      noResultsMessage={i18n.t("TryAnotherSearch")}
                      inputlabel={i18n.t("Reviewer")}
                      placeholder={i18n.t("StartTyping")}
                      options={aviiStaffOptions?.dummyList}
                      clearable
                      search
                      selection
                      value={formFields?.avii_reviewer}
                      onChange={(e, value) =>{
                        onSelectChangeField('avii_reviewer', value)
                        setAviiStaffOptions({fullList: aviiStaffOptions?.fullList, dummyList: [] });
                      }}
                  />
              </Grid.Column>  
              <Grid.Column>
                  <InputDropdown
                      onClick={() => {
                        if (!loadingJobPartners && (jobPartnerOptions?.fullList?.length === 0)) {
                          setLoadingJobPartners(true);
                        }
                        else{
                          setJobPartnerOptions({fullList: jobPartnerOptions?.fullList, dummyList: jobPartnerOptions?.fullList });
                        }
                      }}
                      loading={loadingJobPartners}
                      disabled={props.preview}
                      noResultsMessage={i18n.t("TryAnotherSearch")}
                      inputlabel={i18n.t("Signer")}
                      placeholder={i18n.t("StartTyping")}
                      options={jobPartnerOptions?.dummyList}
                      clearable
                      search
                      selection
                      value={formFields?.avii_signer}
                      onChange={(e, value) =>{
                        onSelectChangeField('avii_signer', value)
                        setJobPartnerOptions({fullList: jobPartnerOptions?.fullList, dummyList: [] });
                      }}
                  />
                </Grid.Column>  
                <Grid.Column>
                  <InputDropdown
                      onClick={() => {
                        if (!loadingSalesChannels && (salesChannelOptions?.fullList?.length === 0)) {
                          setLoadingSalesChannels(true);
                        }
                        else{
                          setSalesChannelOptions({fullList: salesChannelOptions?.fullList, dummyList: salesChannelOptions?.fullList });
                        }
                      }}
                      loading={loadingSalesChannels}
                      disabled={props.preview}
                      noResultsMessage={i18n.t("TryAnotherSearch")}
                      inputlabel={i18n.t("SalesChannel")}
                      placeholder={i18n.t("StartTyping")}
                      options={salesChannelOptions?.dummyList}
                      clearable
                      search
                      selection
                      value={formFields?.avii_sales_channel}
                      onChange={(e, value) =>{
                        onSelectChangeField('avii_sales_channel', value)
                        setSalesChannelOptions({fullList: salesChannelOptions?.fullList, dummyList: [] });
                      }}
                  />
              </Grid.Column>           
            </Grid.Row>
          </Grid>
          </React.Fragment>
        );
    }

  const readyForSubmit = (data, collection) =>{
    let filled = 0;
    for (let i = 0; i < collection.length; i++) {
      if (data && data[collection[i]] && data[collection[i]] !== "") {
        filled++;
      } else {
        return false;
      }
    }
    return filled === collection.length;
  };

  return (
    <StepContainer
      stepNumber="3"
      stepTitle="EngagementInfo"
      completed={!isEmpty(props.data?.engagement_info) && isEmpty(props.errors?.engagement_info)}
      collapsed={props.collapsed}
      disabled={props.currentStep === 1}
      new={isEmpty(props.data?.engagement_info)}
    >
      {stepContent()}
      <div className="action-section">
        {!props.preview && (
          <TableActionButton
            type="primary"
            disabled={!readyForSubmit(formFields, requiredFields)}
            loading={props.isSubmitting}
            onClick={() => {
              props.handleSave("save_and_next", {
                engagement_info: formFields
              });
            }}
          >
            {i18n.t("SaveNext")}
          </TableActionButton>
        )}
      </div>
    </StepContainer>
  );
};

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(EngagementInfo);
