import React, { useEffect } from "react";
import i18n from "../../../i18n";

const FlagInputField = props => {
  const trueValue = props.fromEnvVariables ? "True" : "true";
  const falseValue = props.fromEnvVariables ? "False" : "false";

  useEffect(() => {
    if (!props.value && props.value !== false) {
      props.setFieldValue(falseValue);
    }
  }, [props.value]);

  return (
    <div className="inline fields">
      <div className="field">
        <div className="ui radio checkbox">
          <input
            type="radio"
            checked={props.value === trueValue}
            onChange={() => props.setFieldValue(trueValue)}
          />
          <label>{i18n.t("True")}</label>
        </div>
      </div>

      <div className="field">
        <div className="ui radio checkbox">
          <input
            type="radio"
            checked={props.value === falseValue}
            onChange={() => props.setFieldValue(falseValue)}
          />
          <label>{i18n.t("False")}</label>
        </div>
      </div>
    </div>
  );
};

export default FlagInputField;
