import React from "react";
import moment from "moment-timezone";
import { Chart } from "react-google-charts";
import { Image, Dropdown } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import i18n from "../i18n";
import ExportCsv from "./ExportCsv";
import ExportPdf from "./ExportPdf";
import WidgetEdit from "./WidgetEdit";

import { chartTypeMapping, chartColumnWidth } from "../../constants/widgets";
import { formatChart } from "../../services/widgets.js";
import { getDateFormat } from "../../services/timezones";
import { getValue } from "../../constants/status";

import EmptyChart from "./EmptyChart"

//FOR CUSTOM TABLE
const chartData1=[
  ['X', 'UCL=38.47', 'X=30.25','LCL=22.08', 'Measure', {'type': 'string', 'role': 'style'}],
  ['1', 38.47, 30.25, 22.08, 32, 'point { size: 3; fill-color: blue; }'],
  ['2', 38.47, 30.25, 22.08, 26, 'point { size: 3; fill-color: blue; }'],
  ['3', 38.47, 30.25, 22.08, 25, 'point { size: 3; fill-color: blue; }'],
  ['4', 38.47, 30.25, 22.08, 25, 'point { size: 3; fill-color: blue; }'],
  ['5', 38.47, 30.25, 22.08, 32, 'point { size: 3; fill-color: blue; }'],
  ['6', 38.47, 30.25, 22.08, 26, 'point { size: 3; fill-color: blue; }'],
  ['7', 38.47, 30.25, 22.08, 28, 'point { size: 3; fill-color: blue; }'],
  ['8', 38.47, 30.25, 22.08, 33, 'point { size: 3; fill-color: blue; }'],
  ['9', 38.47, 30.25, 22.08, 32, 'point { size: 3; fill-color: blue; }'],
  ['10', 38.47, 30.25, 22.08, 33, 'point { size: 3; fill-color: blue; }'],
  ['11', 38.47, 30.25, 22.08, 27, 'point { size: 3; fill-color: blue; }'],
  ['12', 38.47, 30.25, 22.08, 30, 'point { size: 3; fill-color: blue; }'],
];
const options1={
  backgroundColor: "#F8F8F8",
  title: 'I-MR Chart of Speed',
  pointSize: 0.1,
  hAxis: { title: 'Observation', minValue: 0, maxValue:10 },
  vAxis: { title: 'Individual value', minValue: 20, maxValue: 40},
  colors: ['#ee5465', '#6fcf97', '#ee5465', 'blue']
}

const chartData2=[
  ['X', 'UCL=10.10', 'X=3.09','LCL=0', 'Measure', {'type': 'string', 'role': 'style'}],
  ['1',10.10, 3.09, 0, null, 'point { size: 3; fill-color: blue; }'],
  ['2',10.10, 3.09, 0, 4.5, 'point { size: 3; fill-color: blue; }'],
  ['3',10.10, 3.09, 0, 3.09, 'point { size: 3; fill-color: blue; }'],
  ['4',10.10, 3.09, 0, 3.09, 'point { size: 3; fill-color: blue; }'],
  ['5',10.10, 3.09, 0, 1.25, 'point { size: 3; fill-color: blue; }'],
  ['6',10.10, 3.09, 0, 3.09, 'point { size: 3; fill-color: blue; }'],
  ['7',10.10, 3.09, 0, 0, 'point { size: 3; fill-color: blue; }'],
  ['8',10.10, 3.09, 0, 3.09, 'point { size: 3; fill-color: blue; }'],
  ['9',10.10, 3.09, 0, 5, 'point { size: 3; fill-color: blue; }'],
  ['10',10.10, 3.09, 0, 1.3, 'point { size: 3; fill-color: blue; }'],
  ['11',10.10, 3.09, 0, 6, 'point { size: 3; fill-color: blue; }'],
  ['12',10.10, 3.09, 0, 5.3, 'point { size: 3; fill-color: blue; }'],
]
const options2={
  backgroundColor: "#F8F8F8",
  title: ' ',
  pointSize: 0.1,
  hAxis: { title: 'Observation', minValue: 0, maxValue:10 },
  vAxis: { title: 'Moving range', minValue: 0, maxValue: 11},
  colors: ['#ee5465', '#6fcf97', '#ee5465', 'blue']
}


class Widget extends React.Component {
  constructor(props) {
    super(props);

    this.userTimezone = this.props.user.timezone || moment.tz.guess();
    this.userDateFormat = getDateFormat(this.userTimezone);

    this.state = {
      imageUri: undefined,
      chartHeight: "300px",
      isEditing: false,
      chartReady: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.imageUri !== this.state.imageUri) {
      const widgetImage = {
        imageUri: this.state.imageUri,
        size: this.props.widget.size,
        height: this.state.chartHeight
      };
      this.setState({ widgetImage })
      this.props.addWidgetImage(widgetImage);
    }
  }

  /**
   * Get an array with on sidebar applications (active applications(.
   *
   * @returns array Returns an array with all active applications (or empty array if any)
   */
  getActiveApplications = () => {
    return this.props.individualApplications
      ? this.props.individualApplications.filter(
          application => application.active
        )
      : [];
  };

  className() {
    const width = chartColumnWidth(this.props.widget.size);

    return `${width} wide column ${
      this.widgetIsClickable() ? "clickable-widget" : ""
    }`;
  }

  displayTitle = () => {
    return ["timeline", "table"].includes(this.props.widget.chart_type);
  };

  toolbarClassName = () => {
    let className = "widgetToolbar";

    if (this.state.isEditing) {
      className += " hide";
    } else if (this.displayTitle()) {
      className += " with-title";
    }

    return className;
  };

  triggerEdit = () => {
    this.setState({ isEditing: true });
  };

  cancelEdit = () => {
    this.setState({ isEditing: false });
  };

  renderEditButton = () => {
    if (isEmpty(this.props.widget.function_inputs)) {
      return null;
    }

    return (
      <button
        type="button"
        className="ui mini button basic"
        onClick={() => this.triggerEdit()}
      >
        {i18n.t("Edit")}
      </button>
    );
  };

  chartData() {
    return formatChart(
      this.props.widget.chart_data,
      this.userDateFormat,
      this.props.widget.chart_type
    );
  }

  widgetIsClickable = () => {
    return (
      this.props.widget.application_id &&
      this.getActiveApplications().some(
        application => application.id === this.props.widget.application_id
      )
    );
  };

  onChartClick = chartWrapper => {
    if (this.widgetIsClickable()) {
      const selection = chartWrapper.getChart().getSelection();
      if (selection.length) {
        const { row, column } = chartWrapper.getChart().getSelection()[0];
        const selectedDate =
          row || row === 0 ? this.props.widget.chart_data[row + 1][0] : null;
        const selectedStatus =
          column || column === 0
            ? this.props.widget.chart_data[0][column]
            : null;
        this.applyWidgetFilters(selectedDate, selectedStatus);
      }
    }
  };

  applyWidgetFilters = (date, status) => {
    const searchParams = new URLSearchParams();
    if (date) {
      const dateFromFilter = moment
        .tz(date, "YYYY-MM-DDTHH:mm:ss.sss", this.userTimezone)
        .utc()
        .format("DD-MM-YYYY HH:mm");
      const endOfDate = moment
        .tz(date, "YYYY-MM-DDTHH:mm:ss.sss", this.userTimezone)
        .endOf("day");
      const dateToFilter = endOfDate.utc().format("DD-MM-YYYY HH:mm");
      searchParams.append("date_from", dateFromFilter);
      searchParams.append("date_to", dateToFilter);
    }
    if (status) {
      const statusValue = getValue(status);
      searchParams.append("status", statusValue);
      searchParams.append("cards", true);
    }
    this.props.history.push(
      `/applications/${
        this.props.widget.application_id
      }?${searchParams.toString()}`
    );
  };

  //FOR CUSTOM TABLE
  customChart = _ =>{
    switch (window.location.hostname){
      case "localhost":
      case "previewaria.invokeinc.com":
      case "ariadev.invokeinc.com":
      case "ariaqa.invokeinc.com":
        return true;
      default:
        return false;
    }
  }

  createCustomChart = () => {
    return (
      <div>
        <Chart
          width="100%"
          height="450px"
          chartType="LineChart"
          loader={(
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px"
              }}
            >
              {i18n.t("Loading")}
            </div>
          )}
          data={this.props.widget.name==="demoChart_sparktx1" ? chartData1 : chartData2}
          options={this.props.widget.name==="demoChart_sparktx1" ? options1 : options2}
          rootProps={{ "data-testid": "1" }}
          chartEvents={[
            {
              eventName: "ready",
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();

                if (!this.state.imageUri) {
                  this.setState({
                    imageUri: chart.getImageURI()
                  });
                }
              }
            },
            {
              eventName: "select",
              callback: ({ chartWrapper }) => {
                this.onChartClick(chartWrapper);
              }
            }
          ]}
        />
      </div>
    );
  };

  longLabels = chartData => {
    if(this.props.widget.chart_type==='hbar'){
      for(let i=1; i<chartData.length; i++ ){
        if(chartData[i][0].length > 15) return true
      }
    }
    return false

  }
  
  renderChart = () => {
    let auxOptions = this.props?.widget?.options ? this.props.widget.options : {}
    auxOptions.backgroundColor = "#F8F8F8";
    auxOptions.colors = this.props?.widget?.options?.colors && this.props.widget.options.colors.length > 0 ? this.props.widget.options.colors : ['#6fcf97', '#ffae8b', '#ee5465' ,'#56ccf2', '#f8ae57']
    let chartData = this.chartData();
    if(this.longLabels(chartData)){
      auxOptions.vAxis = {};
      auxOptions.vAxis.textPosition = 'in';
    }
    if(isEmpty(chartData)){
      return <EmptyChart title={auxOptions.title} chartHeight={this.state.chartHeight} />
    }
    //FOR CUSTOM TABLE
    if(this.customChart() && (this.props.widget.name==="demoChart_sparktx1" || this.props.widget.name==="demoChart_sparktx2") ) return this.createCustomChart()
    return (
      <Chart
        width="100%"
        height={this.state.chartHeight}
        chartType={chartTypeMapping(this.props.widget.chart_type)}
        loader={(
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px"
            }}
          >
            {i18n.t("Loading")}
          </div>
        )}
        data={chartData}
        options={auxOptions}
        rootProps={{ "data-testid": "1" }}
        chartEvents={[
          {
            eventName: "ready",
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();

              if (
                !this.state.imageUri &&
                !["timeline", "table"].includes(this.props.widget.chart_type)
              ) {
                this.setState({
                  imageUri: chart.getImageURI()
                });
              }
              if (
                !this.state.chartReady &&
                ["timeline", "table"].includes(this.props.widget.chart_type)
              ) {
                this.setState({ chartReady: true });
              }
            }
          },
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              this.onChartClick(chartWrapper);
            }
          }
        ]}
      />
    );
  };

  renderEdit = () => {
    return (
      <WidgetEdit
        widget={this.props.widget}
        onCancel={this.cancelEdit}
        reloadButtonId={this.props.reloadButtonId}
      />
    );
  };

  renderChartTitle = () => {
    if (!["timeline", "table"].includes(this.props.widget.chart_type)) {
      return null;
    }
    return <div className="graph-title">{this.props.widget.name}</div>;
  };

  render() {
    return (
      <div className={this.className()}>
        <div className="dashboard-widget">
          <div className={this.toolbarClassName()}>
            {this.renderChartTitle()}
            <div>
              {this.renderEditButton()}
              <Dropdown
                icon="print"
                floating
                button
                className="icon mini basic right"
              >
                <Dropdown.Menu>
                  <ExportCsv
                    as={Dropdown.Item}
                    className="item"
                    pagesData={[
                      { title: this.props.widget.name, data: this.chartData() }
                    ]}
                    fileName={this.props.widget.name}
                  />
                  <ExportPdf
                    className="item"
                    widgets={this.state.widgetImage}
                    fileName={this.props.widget.name}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {this.state.isEditing ? this.renderEdit() : this.renderChart()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user,
    individualApplications: state.applicationsReducer.individualApplications
  };
};

export default connect(mapStateToProps)(withRouter(Widget));
