/* eslint-disable camelcase */
import React from "react";
import InputField from "./InputFields/InputField";
import ApplicationService from "../../../services/data_services/application";
import i18n from "../../i18n";
import { isDesktop } from "../../../services/general";

class SchedulersInputs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputs: [],
      expanded: false
    };

    this.isApplication =
      this.props.values.processes.find(process => {
        return Object.prototype.hasOwnProperty.call(process, "application_id");
      }) !== undefined;

    this.isProcess =
      this.props.values.processes.filter(process => {
        return Object.prototype.hasOwnProperty.call(process, "service_id");
      }).length > 0;
  }

  componentDidMount() {
    this.loadInputs();
  }

  componentDidUpdate(prevProps) {
    if (
      this.isApplication &&
      prevProps.values.processes[this.props.index]?.application_id !==
        this.props.values.processes[this.props.index]?.application_id
    ) {
      this.loadInputs();
    } else if (
      this.isProcess &&
      prevProps.values.processes[this.props.index]?.process_name !==
        this.props.values.processes[this.props.index]?.process_name
    ) {
      this.loadInputs();
    }
  }

  loadInputs = () => {
    if (this.isApplication) {
      ApplicationService.loadApplication(
        this.props.values.processes[this.props.index].application_id
      )
        .then(applicationResponse => {
          const { process_entities } = applicationResponse.data;

          if (process_entities.length) {
            const { rpa_process, service_id } = process_entities[0];

            this._loadProcessInputs(service_id, rpa_process);
          } else {
            this.setState({
              inputs: []
            });
          }
        })
        .catch(() => {
          this.setState({
            inputs: []
          });
        });
    } else if (this.isProcess) {
      const { process_name, service_id } = this.props.values.processes[
        this.props.index
      ];

      this._loadProcessInputs(service_id, process_name);
    }
  };

  _loadProcessInputs = (service_id, process_name) => {
    ApplicationService.checkProcessInputs(service_id, process_name)
      .then(inputsResponse => {
        // On edit, make sure empty values are null to make this.enabled() work properly
        if (this.props.values.processes[this.props.index].data) {
          inputsResponse.data.forEach(item => {
            const fieldData = this.props.values.processes[this.props.index]
              .data[item.Name];
            if (fieldData && fieldData.value === "") {
              this.props.values.processes[this.props.index].data[
                item.Name
              ].value = null;
            }
          });
        }

        this.setState({
          inputs: inputsResponse.data || []
        });
      })
      .catch(() => {
        this.setState({
          inputs: []
        });
      });
  };

  title = () => {
    let title = `${i18n.t("StartParameters")}`;
    if (!isDesktop()) {
      return title;
    }

    if (this.props.values.processes[this.props.index]) {
      if (this.isApplication) {
        title = `${title}: ${
          this.props.values.processes[this.props.index].application_name
        }`;
      } else if (this.isProcess) {
        title = `${title}: ${
          this.props.values.processes[this.props.index].process_name
        }`;
      }
    }

    return title;
  };

  enabledName = () => {
    return `processes.${this.props.index}`;
  };

  dataName = () => {
    return `${this.enabledName()}.data`;
  };

  fieldName = key => {
    return `${this.dataName()}.${key}`;
  };

  enabled = (props = this.props) => {
    // Disabled when there is not data.
    if (!props.values.processes[props.index].data) {
      return false;
    }

    // Disabled if any data item has value.
    let enabled = false;
    for (const dataKey in props.values.processes[props.index].data) {
      const item = props.values.processes[props.index].data[dataKey];
      if (item && item.value !== undefined && item.value !== null) {
        enabled = true;
        break;
      }
    }

    return enabled;
  };

  enableData = () => {
    const dataName = this.dataName();
    const inputHash = {};

    this.state.inputs.forEach(input => {
      inputHash[input.Name] = { value: "", name: input.Name, type: input.Type };
    });

    this.props.setFieldValue(dataName, inputHash);
  };

  disableData = () => {
    const dataName = this.dataName();
    this.props.setFieldValue(dataName, undefined);
  };

  getFieldValue = name => {
    const { data } = this.props.values.processes[this.props.index];

    return this.enabled() &&
      data &&
      Object.prototype.hasOwnProperty.call(data, name)
      ? data[name].value ?? ""
      : "";
  };

  setFieldValue = (name, value) => {
    const fieldName = this.fieldName(name);

    this.props.setFieldValue(fieldName, value);
  };

  _yesChecked = () => {
    return this.enabled() ? "checked" : "";
  };

  _noChecked = () => {
    return !this.enabled() ? "checked" : "";
  };

  _fieldDisabled = () => {
    return !this.enabled();
  };

  renderRadioInputs = () => {
    const radioClass = isDesktop() ? "inline fields" : "inline fields mobile";
    return (
      <div className={radioClass}>
        <label>{i18n.t("Enable")}?</label>
        <div className="field">
          <div className="ui radio checkbox">
            <input
              type="radio"
              name={this.enabledName()}
              checked={this._yesChecked()}
              onChange={this.enableData}
            />
            <label>{i18n.t("Yes")}</label>
          </div>
        </div>

        <div className="field">
          <div className="ui radio checkbox">
            <input
              type="radio"
              name={this.enabledName()}
              checked={this._noChecked()}
              onChange={this.disableData}
            />
            <label>{i18n.t("No")}</label>
          </div>
        </div>
      </div>
    );
  };

  renderTitle = () => {
    if (isDesktop()) {
      return <h4 className="ui dividing header">{this.title()}</h4>;
    }
    return (
      <div className="processHeaderMobile">
        <h4 style={{ flexGrow: "2" }} className="ui dividing header">
          {this.title()}
        </h4>
        {this.renderCollapseButton()}
      </div>
    );
  };

  renderCollapseButton = () => {
    const arrowDirection = this.state.expanded ? "up" : "down";
    return (
      <div className="collapse-button-container">
        <button
          type="button"
          className="ui mini icon basic grey button"
          onClick={() => {
            this.setState(prevState => {
              return {
                expanded: !prevState.expanded
              };
            });
          }}
        >
          <i className={`angle icon ${arrowDirection}`} />
        </button>
      </div>
    );
  };

  render() {
    if (this.state.inputs.length) {
      return (
        <div className="scheduler-inputs-container">
          <div className={`input-fields-container ${this.state.expanded ? "expanded" : ""}`}>
            {/* <h4 className="ui dividing header">{this.title()}</h4> */}
            {this.renderTitle()}
            {this.renderRadioInputs()}
            {this.enabled() &&
              this.state.inputs.map(input => {
                const name = this.fieldName(input.Name);
                return (
                  <InputField
                    key={input.Name}
                    title={input.Name}
                    name={name}
                    disabled={this._fieldDisabled()}
                    type={input.Type}
                    value={this.getFieldValue(input.Name)}
                    setFieldValue={value => {
                      this.setFieldValue(input.Name, {
                        value,
                        name: input.Name,
                        type: input.Type
                      });
                    }}
                  />
                );
              })}
          </div>
          {isDesktop() && this.renderCollapseButton()}
        </div>
      );
    }
    return null;
  }
}

export default SchedulersInputs;
