import React from "react";

const RadioInput = props => {
  return (
    <>
      <label className="fieldLabel">{props.name}</label>
      {props.options.map(option => {
        return (
          <div key={option.label} className="radioField">
            <input
              type="radio"
              name={props.name}
              value={option.value}
              checked={props.value === option.value}
              onChange={props.onChange}
              className="radioInput"
            />
            <label className="radioInputLabel">{option.label}</label>
          </div>
        );
      })}
    </>
  );
};

export default RadioInput;
