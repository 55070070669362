import moment from "moment-timezone";
import timezones from "../components/Account/timezones.json";

export const getTimezoneName = timezone => {
  const selectedTimezone = timezones.find(tz => {
    return tz.utc.includes(timezone);
  });
  return selectedTimezone?.text || "";
};

export const getTimezoneValue = timezone => {
  const selectedTimezone = timezones.find(tz => tz.text === timezone);
  return selectedTimezone?.utc[0] || "";
};

export const getDateFormat = timezone => {
  try {
    const selectedTimezone = timezones.find(tz => {
      return tz.utc.includes(timezone);
    });
    return selectedTimezone?.dateFormat || "DD-MM-YYYY";
  } catch {
    return "DD-MM-YYYY";
  }
};

export const getUTCOffset = timezone => {
  try {
    return -moment.tz.zone(timezone).utcOffset(moment()) / 60;
  } catch {
    return 0;
  }
};
