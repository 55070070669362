import React from "react";
import { DateTimeInput } from "semantic-ui-calendar-react-yz";

const InputDateTime = props => {
  return (
    <div>
      {props.inputlabel && (
        <div className="inputField-label">{props.inputlabel}</div>
      )}
      <DateTimeInput
        closable
        animation="none"
        iconPosition="left"
        preserveViewMode={false}
        {...props}
      />
    </div>
  );
};

InputDateTime.defaultProps = {
  inputlabel: ""
};

export default InputDateTime;
