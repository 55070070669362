import React from "react";
import { Route, Switch } from "react-router-dom";
import UsersTable from "./UsersTable";
import UsersEdit from "./UsersEdit";
import UsersNew from "./UsersNew";

const Users = props => {
  return (
    <Switch>
      <Route
        path="/admin/users/new"
        render={routerProps => (
          <UsersNew {...props} match={routerProps.match} />
        )}
      />
      <Route
        path="/admin/users/:id"
        render={routerProps => (
          <UsersEdit {...props} match={routerProps.match} />
        )}
      />
      <Route
        path="/admin/users"
        render={routerProps => (
          <UsersTable {...props} match={routerProps.match} />
        )}
      />
    </Switch>
  );
};

export default Users;
