import axios from "../axios";

class CompanySettingsService {
  static loadCompanySettings = (companyId, tableUuid) => {
    return axios.get(`/company_configs/show`, {
      params: {
        company_id: companyId,
        table_id: tableUuid
      }
    });
  };

  static createCompanySettings = (companyId, tableUuid, fieldsArray) => {
    return axios.post("/company_configs", {
      id: companyId,
      table_id: tableUuid,
      columns: fieldsArray
    });
  };

  static updateCompanySettings = (companyId, tableUuid, fieldsArray) => {
    return axios.put("/company_configs/update", {
      company_id: companyId,
      table_id: tableUuid,
      columns: fieldsArray
    });
  };
}

export default CompanySettingsService;
