import React from "react";
import AdminElement from "./AdminElement";
import Permissions from "../../services/permissions";
import { Actions } from "../../services/detailedPermissionRules";
import i18n from "../i18n";
import { isDesktop } from "../../services/general";

class AdminSection extends React.Component {
  renderSection() {
    if (isDesktop()) {
      return (
        <div className="ui segment noborder desktop">
          <div className="header">
            <h4 style={{ fontSize: "1.2rem" }}>{i18n.t(this.props.title)}</h4>
            <p style={{ color: "gray" }}>{i18n.t(this.props.description)}</p>
          </div>
          <div className="ui divider" />
          <div className="body" style={{ display: "flex" }}>
            {this.props.elements.map(element => {
              return (
                <AdminElement
                  key={element.title}
                  url={element.url}
                  icon={element.icon}
                  title={element.title}
                />
              );
            })}
          </div>
        </div>
      );
    }
    return (
      <div className="ui segment noborder mobile">
        <div className="header">
          <h4 style={{ fontSize: "1.2rem" }}>{i18n.t(this.props.title)}</h4>
          <p style={{ color: "gray" }}>{i18n.t(this.props.description)}</p>
        </div>
        <div className="ui divider" />
        <div className="body adminSectionFlexbox">
          {this.props.elements.map(element => {
            return (
              <AdminElement
                key={element.title}
                url={element.url}
                icon={element.icon}
                title={element.title}
              />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Permissions
        component={this.props.elements.map(element => element.component)}
        action={Actions.read}
        yes={() => {
          return this.renderSection();
        }}
        no={() => {
          return null;
        }}
      />
    );
  }
}

export default AdminSection;
