import React from "react";
import { Loader } from "semantic-ui-react";

const TableActionButton = props => {
  const buttonClassName = () => {
    const base = ["table-button", props.type, props.className];
    return base.join(" ");
  };

  const renderLoading = () => {
    return (
      <Loader
        active
        size="tiny"
        inline
        centered
        inverted={props.type === "primary"}
        style={{ marginBottom: "2px" }}
      />
    );
  };

  const renderContent = () => {
    return (
      <React.Fragment>
        {props.icon && <i className={`${props.icon} icon`} />}
        {props.children}
      </React.Fragment>
    );
  };

  return (
    <button
      type="button"
      className={buttonClassName()}
      style={props.style}
      onClick={props.onClick}
      disabled={props.disabled || props.loading}
    >
      {props.loading ? renderLoading() : renderContent()}
    </button>
  );
};

TableActionButton.defaultProps = {
  icon: null,
  type: "primary",
  disabled: false,
  className: null
};

export default TableActionButton;
