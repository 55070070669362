import React, { useState, useEffect } from "react";
import { TextArea } from "semantic-ui-react";
import InputField from "../../../Ui/InputField";
import ValueInput from "../../../Scheduler/Schedulers/InputFields/InputField";
import InputDropdown from "../../../Ui/InputDropdown";
import UiModal from "../../../Ui/Modal";
import ActionButtons from "../../../Ui/ActionButtons";
import i18n from "../../../i18n";
import ServiceService from "../../../../services/data_services/service";
import EnvVariableService from "../../../../services/data_services/env_variable";
import Toast from "../../../../services/toasts";
import { SERVICE_TYPES } from "../../../../constants/service-types";

const initialValues = {
  name: "",
  service_id: null,
  datatype: "text",
  value: "",
  description: ""
};

const mandatoryFields = ["name", "service_id", "datatype", "value"];

const EnvVariablesModal = ({
  open,
  onClose,
  onSubmit,
  typeOptions,
  item = null
}) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (item) {
      const { service, ...itemValues } = item;
      setFormValues(itemValues);
    } else {
      setFormValues(initialValues);
    }
  }, [item]);

  useEffect(() => {
    if (open && serviceOptions.length === 0) loadServices();
  }, [open]);

  const loadServices = () => {
    ServiceService.loadServices().then(({ data }) => {
      const auxServices = data
        .filter(s => s.service_type === SERVICE_TYPES.BLUEPRISM)
        .map(s => {
          return {
            key: s.id,
            text: s.name,
            value: s.id
          };
        });
      setServiceOptions(auxServices);
    });
  };

  const handleFormChange = (name, value) => {
    setFormValues(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const canSubmit = () =>
    mandatoryFields.filter(f => !formValues[f]).length === 0;

  const handleSubmit = evt => {
    evt.preventDefault();
    setSubmitting(true);
    EnvVariableService.updateEnvVariable(formValues)
      .then(() => {
        Toast.displaySuccess(
          item ? i18n.t("VariableUpdated") : i18n.t("VariableCreated")
        );
        onSubmit();
      })
      .catch(() => {
        Toast.displayError(i18n.t("SomethingWentWrong"));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    setFormValues(initialValues);
    onClose();
  };

  const renderForm = () => {
    return (
      <form
        onSubmit={event => handleSubmit(event)}
        className="env-variables-form"
      >
        <InputField
          required
          inputlabel="Name"
          disabled={item}
          value={formValues.name}
          onChange={(e, { value }) => handleFormChange("name", value)}
        />
        <InputDropdown
          required
          inputlabel="Service"
          placeholder={i18n.t("SelectPlaceholder")}
          search
          selection
          disabled={item || serviceOptions.length === 0}
          options={serviceOptions}
          value={formValues.service_id}
          onChange={(e, { value }) => handleFormChange("service_id", value)}
        />
        <InputDropdown
          required
          inputlabel="Type"
          placeholder={i18n.t("SelectPlaceholder")}
          search
          selection
          options={typeOptions}
          value={formValues.datatype}
          onChange={(e, { value }) => handleFormChange("datatype", value)}
        />
        <div className="ui form">
          <div className="inputField-label">{`${i18n.t("Value")}*`}</div>
          <ValueInput
            fromEnvVariables
            type={formValues.datatype}
            value={formValues.value}
            setFieldValue={value => handleFormChange("value", value)}
          />
        </div>
        <div className="ui form">
          <div className="inputField-label">{i18n.t("Description")}</div>
          <TextArea
            value={formValues.description}
            onChange={(e, { value }) => handleFormChange("description", value)}
          />
        </div>
        <ActionButtons
          cancelAction={handleClose}
          isSubmitting={submitting}
          disabled={!canSubmit()}
        />
      </form>
    );
  };
  return (
    <UiModal
      open={open}
      headerContent={!item ? i18n.t("CreateVariable") : i18n.t("EditVariable")}
      onClose={handleClose}
    >
      {renderForm()}
    </UiModal>
  );
};

export default EnvVariablesModal;
