import Cookies from "js-cookie";
import {
  LOGIN,
  LOGOUT,
  LOAD_COMPANIES,
  LOAD_USERS,
  LOAD_ROLES,
  SET_BREADCRUMB,
  SET_LOADING_BAR,
  TOGGLE_SIDE_BAR,
  LOAD_APPLICATIONS,
  LOAD_INDIVIDUAL_APPLICATIONS,
  LOAD_LAUNCH_APPLICATIONS,
  LOAD_SERVICES,
  LOAD_COLUMNS,
  LOAD_FILTERS,
  DELETE_FILTERS
} from "../constants/action-types";

export const logIn = user => dispatch => {
  if (Object.prototype.hasOwnProperty.call(user, "jwt")) {
    Cookies.set("JWT", user.jwt);
  }

  if (Object.prototype.hasOwnProperty.call(user, "id")) {
    Cookies.set("ID", user.id);
  }

  return dispatch({ type: LOGIN, payload: user });
};

export const logOut = () => dispatch => {
  Cookies.remove("JWT");
  Cookies.remove("ID");
  return dispatch({ type: LOGOUT, payload: null });
};

export const loadCompanies = companies => dispatch => {
  return dispatch({ type: LOAD_COMPANIES, payload: companies });
};

export const loadUsers = users => dispatch => {
  return dispatch({ type: LOAD_USERS, payload: users });
};

export const loadRoles = roles => dispatch => {
  return dispatch({ type: LOAD_ROLES, payload: roles });
};

export const setBreadcrumb = breadcrumb => dispatch => {
  return dispatch({ type: SET_BREADCRUMB, payload: breadcrumb });
};

export const setLoadingBar = bar => dispatch => {
  return dispatch({ type: SET_LOADING_BAR, payload: bar });
};

export const toggleSideBar = status => dispatch => {
  return dispatch({ type: TOGGLE_SIDE_BAR, payload: status });
};

export const loadApplications = applications => dispatch => {
  return dispatch({ type: LOAD_APPLICATIONS, payload: applications });
};

export const loadIndividualApplications = applications => dispatch => {
  return dispatch({
    type: LOAD_INDIVIDUAL_APPLICATIONS,
    payload: applications
  });
};
export const loadLaunchApplications = applications => dispatch => {
  return dispatch({ type: LOAD_LAUNCH_APPLICATIONS, payload: applications });
};
export const loadServices = services => dispatch => {
  return dispatch({ type: LOAD_SERVICES, payload: services });
};

export const loadColumnOrder = columns => dispatch => {
  return dispatch({ type: LOAD_COLUMNS, payload: columns });
};

export const loadFilters = (appUuid, filters) => dispatch => {
  return dispatch({ appUuid, type: LOAD_FILTERS, payload: filters });
};

export const deleteFilters = () => dispatch => {
  return dispatch({ type: DELETE_FILTERS });
};
