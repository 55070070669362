import React, { useState, useEffect } from "react";
import MobileRow from "./MobileRow";
import PerPageSelector from "../PerPageSelector";
import EmptyTable from "../EmptyTable";
import CurrentPageInfo from "../CurrentPageInfo";
import i18n from "../../../i18n";
import MobileFilters from "./MobileFilters";
import MobileSearchbar from "./MobileSearchbar";
import MobileColumnFilter from "./MobileColumnFilter";
import Pagination from "../../../Pagination";
import TableLoader from "../TableLoader";

const initialMobileColumns = {
  name: "name",
  timestamp: "updated_at",
  status: "status"
};

const MobileTable = props => {
  const [mobileColumns, setMobileColumns] = useState(initialMobileColumns);
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showSearchFilters, setShowSearchFilters] = useState(false);

  const replaceInitialColumns = (columns = {}) => {
    const newColumns = { ...initialMobileColumns };
    if (typeof columns !== "object" || Object.keys(columns).length === 0)
      return initialMobileColumns;
    Object.keys(columns).forEach(key => {
      newColumns[key] = columns[key];
    });
    return newColumns;
  };

  useEffect(() => {
    const newMobileColumns = replaceInitialColumns(props.mobileColumns);
    setMobileColumns(newMobileColumns);
  }, [props.mobileColumns]);

  const topbarClassName = props.onSelectAllClick
    ? "mobile-table-topbar"
    : "mobile-table-topbar one-item";

  const renderTable = () => (
    <React.Fragment>
      <div className={topbarClassName}>
        {props.onSelectAllClick && (
          <div className="ui checkbox">
            <input
              type="checkbox"
              checked={props.selectAllChecked()}
              onChange={props.onSelectAllClick}
            />
            <label className="select-all-label">{i18n.t("SelectAll")}</label>
          </div>
        )}
        <div>
          <PerPageSelector
            filters={props.filters}
            loadItems={props.loadItems}
          />
        </div>
      </div>
      <ul className="mobile-table">
        {props.items?.map(item => (
          <MobileRow
            key={item.id || item[props.mobileColumns?.name || "name"]}
            item={item}
            {...props}
            {...props.mobileColumns}
          />
        ))}
      </ul>
      <Pagination
        nPages={props.nPages}
        page={props.page}
        onChange={page => {
          props.loadItems({ page });
        }}
      />
    </React.Fragment>
  );

  const renderContent = () => {
    if (!props.items || props.loadingItems) {
      return (
        <div className="mobile-table-body">
          <TableLoader />
        </div>
      );
    }
    if (props.items.length === 0) {
      return (
        <EmptyTable
          noItemsKey={props.noItemsKey}
          className="mobile-table-body"
        />
      );
    }

    return renderTable();
  };

  const displayCurrentPageInfo = props.items?.length > 0 && !props.loadingItems;
  const filterBarClass = displayCurrentPageInfo
    ? "d-flex-center-sb"
    : "d-flex-end";

  return (
    <React.Fragment>
      <MobileSearchbar
        filters={props.filters}
        loadItems={props.loadItems}
        quickSearchParam={props.quickSearchParam}
        activitiesTable={props.activitiesTable}
        component={props.component}
        createAction={props.createAction}
        customSearch={props.customSearch}
        deleteAction={props.deleteAction}
        deleteDisabled={props.deleteDisabled}
        displayRetry={props.displayRetry}
        exportAction={props.exportAction}
        exportDisabled={props.exportDisabled}
        reloadAction={props.reloadAction}
        retryAction={props.retryAction}
        retryDisabled={props.retryDisabled}
        tableId={props.tableId}
        toggleColumns={() => setShowColumnFilters(true)}
      />
      <div className={filterBarClass}>
        {displayCurrentPageInfo && (
          <CurrentPageInfo
            page={props.filters.page}
            perPage={props.filters.per_page}
            totalItems={props.totalItems}
          />
        )}
        <a
          role="link"
          tabIndex={0}
          aria-label="Toggle filters"
          className="filter-link"
          onClick={() => setShowSearchFilters(prevShow => !prevShow)}
        >
          {`${showSearchFilters ? "Collapse" : "Show"} filters`}
        </a>
      </div>
      <MobileFilters
        columns={props.columns}
        filters={props.filters}
        loadItems={props.loadItems}
        loadingItems={props.loadingItems}
        show={showSearchFilters}
      />
      {renderContent()}
      <MobileColumnFilter
        open={showColumnFilters}
        columns={props.columns}
        setColumns={props.setColumns}
        mobileColumns={mobileColumns}
        toggleColumnFilter={setShowColumnFilters}
      />
    </React.Fragment>
  );
};

export default MobileTable;
