import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import i18n from "../../../i18n";
import SubmitButton from "../../../SubmitButton";
import { isDesktop } from "../../../../services/general";
import ApplicationService from "../../../../services/data_services/application";
import UserService from "../../../../services/data_services/user";
import DashboardService from "../../../../services/data_services/dashboard";

export const teamsFormSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t("NameRequiredValidation")),
  description: Yup.string(),
  launcher_application_ids: Yup.array().required(
    i18n.t("ApplicationRequiredValidation")
  ),
  individual_item_application_ids: Yup.array().required(
    i18n.t("ApplicationRequiredValidation")
  ),
  users_ids: Yup.array().required(i18n.t("UserRequiredValidation")),
  allow_edit: Yup.bool(),
  allow_delete: Yup.bool(),
  monitor: Yup.bool(),
  scheduling: Yup.bool(),
  visible_section_ids: Yup.array(),
  rpa_management: Yup.bool()
});

class TeamsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      launcher_applications: [],
      individual_item_applications: [],
      visible_section_ids: [],
      users: [],
      returnToTeams: false
    };
  }

  componentDidMount() {
    this.loadApplications();
    this.loadUsers();
  }

  componentDidUpdate = prevProps => {
    if (!prevProps.team && this.props.team) {
      this.loadApplications();
      this.loadUsers();
    }
  };

  loadApplications = () => {
    ApplicationService.loadApplications({ page: 0 }).then(result => {
      this.setState({
        individual_item_applications: result.data.results,
      });
    });
    ApplicationService.loadLauncherApplications().then(result => {
      this.setState({
        launcher_applications: result.data
      });
    });
    DashboardService.loadAvailableSections().then(response => {
      this.setState({
        visible_section_ids: response.data
      });
    });
  };

  launcherApplicationValues = LauncherApplicationIds => {
    if (this.state.launcher_applications) {
      return this.state.launcher_applications
        .map(application => ({
          value: application.id,
          label: application.name
        }))
        .filter(launcher_applications => {
          return LauncherApplicationIds.includes(launcher_applications.value);
        });
    }
    return [];
  };

  checkPermissions = target => {
    return this.props?.user?.company?.access_to[target] === 'true'
  };

  individualItemApplicationValues = IndividualItemApplicationIds => {
    if (this.state.individual_item_applications) {
      return this.state.individual_item_applications
        .map(application => ({
          value: application.id,
          label: application.name
        }))
        .filter(individual_item_applications => {
          return IndividualItemApplicationIds.includes(
            individual_item_applications.value
          );
        });
    }

    return [];
  };

  applicationLauncherOptions = () => {
    if (this.state.launcher_applications) {
      return this.state.launcher_applications.map(application => ({
        value: application.id,
        label: application.name
      }));
    }
    return [];
  };

  individualItemApplicationOptions = () => {
    if (this.state.individual_item_applications) {
      return this.state.individual_item_applications.map(application => ({
        value: application.id,
        label: application.name
      }));
    }

    return [];
  };

  dashboardVisibleSectionIdsOptions = () => {
    if (this.state.visible_section_ids) {
      return this.state.visible_section_ids.map(section => ({
        value: section.id,
        label: section.name
      }));
    }

    return [];
  };

  dashboardVisibleSectionIdsValues = dashboardVisibleSectionIds => {
    if (!this.state.visible_section_ids) return [];
    return this.dashboardVisibleSectionIdsOptions().filter(section => {
      return dashboardVisibleSectionIds.includes(section.value);
    });
  };

  userValues = userIds => {
    if (this.state.users) {
      return this.state.users
        .map(user => ({
          value: user.id,
          label: user.name
        }))
        .filter(user => {
          return userIds.includes(user.value);
        });
    }
    return [];
  };

  userOptions = () => {
    if (this.state.users) {
      return this.state.users.map(user => ({
        value: user.id,
        label: user.name
      }));
    }

    return [];
  };

  loadUsers = () => {
    UserService.loadUsers().then(result => {
      this.setState({
        users: result.data.results
      });
    });
  };

  returnTeams = () => {
    this.setState({
      returnToTeams: true
    });
  };

  renderButtons(isSubmitting) {
    return (
      <div className="actionButtons">
        <button
          className="ui button basic blue"
          type="button"
          onClick={() => {
            this.setState({
              returnToTeams: true
            });
          }}
        >
          {i18n.t("CancelButton")}
        </button>

        <SubmitButton label="SaveButton" isSubmitting={isSubmitting} />
      </div>
    );
  }

  handleSelectChange = (name, selectedValues, setFieldValue) => {
    const values = selectedValues?.length
      ? selectedValues.map(value => value.value)
      : [];
    setFieldValue(name, values);
  };

  render() {
    if (this.state.returnToTeams) {
      return <Redirect push to="/admin/teams" />;
    }
    const mobileClass = !isDesktop() ? "inlineFieldMobile" : "inlineField";
    return (
      <div className="content">
        <Formik
          initialValues={{
            name: this.props.team ? this.props.team.name : "",
            description: this.props.team ? this.props.team.description : "",
            launcher_application_ids:
              this.props.team && this.props.team.launcher_applications
                ? this.props.team.launcher_applications.map(
                    application => application.id
                  )
                : [],
            individual_item_application_ids:
              this.props.team && this.props.team.individual_item_applications
                ? this.props.team.individual_item_applications.map(
                    application => application.id
                  )
                : [],
            user_ids:
              this.props.team && this.props.team.users
                ? this.props.team.users.map(user => user.id)
                : "",
            allow_edit: this.props.team ? this.props.team.allow_edit : false,
            allow_delete: this.props.team
              ? this.props.team.allow_delete
              : false,
            monitor: this.props.team ? this.props.team.monitor : false,
            scheduling: this.props.team ? this.props.team.scheduling : false,
            visible_section_ids:
              this.props.team && this.props.team.visible_sections
                ? this.props.team.visible_sections.map(section => section.id)
                : [],
            rpa_management: this.props.team
              ? this.props.team.rpa_management
              : false
          }}
          //validationSchema={teamsFormSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            this.props.submit(values, setSubmitting, this.returnTeams);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            errors
          }) => (
            <form className="ui form" onSubmit={handleSubmit}>
              <div className="fieldsContainer">
                <div className={`required field ${mobileClass}`}>
                  <label>{i18n.t("Name")}</label>
                  <input
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    autoComplete="off"
                  />
                  <ErrorMessage name="name" component="div" className="error" />
                </div>
                <div className={`field ${mobileClass}`}>
                  <label>{i18n.t("Description")}</label>
                  <input
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error"
                  />
                </div>

                <div className={`${mobileClass} required field`}>
                  <label>{i18n.t("Users")}</label>
                  <Select
                    value={this.userValues(values.user_ids)}
                    options={this.userOptions()}
                    onChange={selectedUsers => {
                      this.handleSelectChange(
                        "user_ids",
                        selectedUsers,
                        setFieldValue
                      );
                    }}
                    isMulti
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                  <ErrorMessage
                    name="user_ids"
                    component="div"
                    className="error"
                  />
                </div>
                {this.checkPermissions('launcher') && (
                  <div className={`${mobileClass} required field`}>
                    <label>{i18n.t("LaunchMenu")}</label>
                    <Select
                      value={this.launcherApplicationValues(
                        values.launcher_application_ids
                      )}
                      options={this.applicationLauncherOptions()}
                      onChange={selectedLauncherApplications => {
                        this.handleSelectChange(
                          "launcher_application_ids",
                          selectedLauncherApplications,
                          setFieldValue
                        );
                      }}
                      isMulti
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                    <ErrorMessage
                      name="application_ids"
                      component="div"
                      className="error"
                    />
                  </div>
                )}
                {this.checkPermissions('case_view') && (
                  <div className={`${mobileClass} required field`}>
                    <label>{i18n.t("CaseView")}</label>
                    <Select
                      value={this.individualItemApplicationValues(
                        values.individual_item_application_ids
                      )}
                      options={this.individualItemApplicationOptions()}
                      onChange={selectedIndividualItemApplications => {
                        this.handleSelectChange(
                          "individual_item_application_ids",
                          selectedIndividualItemApplications,
                          setFieldValue
                        );
                      }}
                      isMulti
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                    <ErrorMessage
                      name="individual_item_application_ids"
                      component="div"
                      className="error"
                    />
                  </div>
                )}
                {this.checkPermissions('dashboard') && (
                  <div
                    className={`inline inlineField field  ${
                      errors.name ? "withError" : ""
                    }`}
                  >
                    <label>{i18n.t("DashboardSections")}</label>
                    <Select
                      value={this.dashboardVisibleSectionIdsValues(
                        values.visible_section_ids
                      )}
                      options={this.dashboardVisibleSectionIdsOptions()}
                      onChange={selectedDashboardSections => {
                        this.handleSelectChange(
                          "visible_section_ids",
                          selectedDashboardSections,
                          setFieldValue
                        );
                      }}
                      isMulti
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                    <ErrorMessage
                      name="visible_section_ids"
                      component="div"
                      className="error"
                    />
                  </div>
                )}
                <div className="inline field">
                  <label>{i18n.t("Edit")}</label>
                  <input
                    name="allow_edit"
                    onChange={handleChange}
                    checked={values.allow_edit}
                    type="checkbox"
                    style={{ marginLeft: "10px" }}
                  />
                  <ErrorMessage
                    name="allow_edit"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="inline field">
                  <label>{i18n.t("Archive")}</label>
                  <input
                    name="allow_delete"
                    onChange={handleChange}
                    checked={values.allow_delete}
                    type="checkbox"
                    style={{ marginLeft: "10px" }}
                  />
                  <ErrorMessage
                    name="allow_delete"
                    component="div"
                    className="error"
                  />
                </div>

                {this.checkPermissions('scheduling') && (
                  <React.Fragment>
                    <div className="inline field">
                      <label>{i18n.t("Monitor")}</label>
                      <input
                        name="monitor"
                        onChange={handleChange}
                        checked={values.monitor}
                        type="checkbox"
                        style={{ marginLeft: "10px" }}
                      />
                      <ErrorMessage
                        name="monitor"
                        component="div"
                        className="error"
                      />
                    </div>

                    <div className="inline field">
                      <label>{i18n.t("Scheduling")}</label>
                      <input
                        name="scheduling"
                        onChange={handleChange}
                        checked={values.scheduling}
                        type="checkbox"
                        style={{ marginLeft: "10px" }}
                      />
                      <ErrorMessage
                        name="scheduling"
                        component="div"
                        className="error"
                      />
                    </div>
                  </React.Fragment>
                )}
                {this.checkPermissions('rpa_management') && (
                  <div className="inline field">
                    <label>{i18n.t("RPA management")}</label>
                    <input
                      name="rpa_management"
                      onChange={handleChange}
                      checked={values.rpa_management}
                      type="checkbox"
                      style={{ marginLeft: "10px" }}
                    />
                    <ErrorMessage
                      name="rpa_management"
                      component="div"
                      className="error"
                    />
                  </div>
                )}
              </div>
              {this.renderButtons(isSubmitting)}
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.currentUserReducer.user
  };
};

export default connect(mapStateToProps)(TeamsForm);
