import React from "react";
import i18n from "../../../i18n";
import EmailsForm from "./EmailsForm";
import EmailService from "../../../../services/data_services/email";
import Toast from "../../../../services/toasts";
import WithLoadingComponent from "../../../Ui/WithLoadingComponent";
import {checkAndRedirect} from "../../../../services/axios";

class EmailsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: undefined
    };

    props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Emails"),
        location: "/admin/emails"
      },
      {
        title: `${i18n.t("Email")} #${this.props.match.params.id}`,
        location: `/admin/emails/${this.props.match.params.id}`
      }
    ]);

    EmailService.loadEmail(this.props.match.params.id)
      .then(result => {
        this.setState(
          {
            email: result.data
          },
          this.setBreadcrumb
        );
      })
        .catch(checkAndRedirect)

  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Settings"),
        location: "/admin"
      },
      {
        title: i18n.t("Emails"),
        location: "/admin/emails"
      },
      {
        title: this.title(),
        location: `/admin/emails/${this.props.match.params.id}`
      }
    ]);
  }

  title() {
    if (this.state.email) {
      return `${i18n.t("Email")} - ${this.state.email.name}`;
    }
    return `${i18n.t("Email")}`;
  }

  updateEmail = (values, setSubmitting, returnToApplication) => {
    const valuesToPost = values;
    valuesToPost.subject_filters = (values.subject_filters || []).map(
      filter => filter.label
    );

    EmailService.updateEmail(this.state.email.id, valuesToPost)
      .then(() => {
        setSubmitting(false);
        returnToApplication();
        Toast.show.emailsUpdated();
      })
      .catch(e => {
        setSubmitting(false);
        Toast.show.emailsNotUpdated();
      });
  };

  render() {
    return (
      <WithLoadingComponent
        loadingCondition={() => !this.state.email}
        contentCondition={() => this.state.email.id}
        noItemsKey="NotAbleLoadEmail"
      >
        <EmailsForm email={this.state.email} onSubmit={this.updateEmail} />
      </WithLoadingComponent>
    );
  }
}

export default EmailsEdit;
