import React from "react";
import { Dropdown, Input } from "semantic-ui-react";
import IconButton from "../../IconButton";
import TableActionButton from "../../TableActionButton";
import i18n from "../../../i18n";
import Permissions from "../../../../services/permissions";
import { Actions } from "../../../../services/detailedPermissionRules";
import { TYPES } from "../types";

const SCHEDULER_OPTIONS = [
  { key: "running", text: "Running", value: false },
  { key: "paused", text: "Paused", value: true }
];

export default function MobileSearchbar({
  quickSearchParam = "search_param",
  ...props
}) {
  const createButton = (
    <TableActionButton
      type="primary"
      className="full-width"
      icon="add"
      style={{
        flexGrow: "1",
        height: props.tableId === TYPES.SCHEDULERS ? "36px" : undefined
      }}
      onClick={props.createAction}
    >
      {i18n.t("Create")}
    </TableActionButton>
  );

  const renderCreateButton = () => {
    if (props.component) {
      return (
        <Permissions
          component={props.component}
          action={Actions.create}
          yes={() => {
            return createButton;
          }}
          no={() => {
            return null;
          }}
        />
      );
    }
    return createButton;
  };

  const renderSchedulerTypeDropdown = () => (
    <Dropdown
      button
      basic
      floating
      fluid
      style={{ flexGrow: "1", marginRight: "8px" }}
      options={SCHEDULER_OPTIONS}
      onChange={(e, { value }) => {
        props.loadItems({ paused: value });
      }}
      value={props.filters?.paused}
    />
  );

  const renderActivitiesMenu = () => (
    <Dropdown
      className="icon-button secondary"
      icon="ellipsis horizontal"
      direction="left"
      style={{ position: "relative" }}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          icon="columns"
          text={i18n.t("Columns")}
          onClick={props.toggleColumns}
        />
        <Dropdown.Item
          icon="sync"
          text={i18n.t("Refresh")}
          onClick={props.reloadAction}
        />
        <Dropdown.Item
          icon="print"
          text={i18n.t("Export")}
          onClick={props.exportAction}
          disabled={props.exportDisabled}
        />
        <Dropdown.Item
          icon="trash"
          text={i18n.t("Delete")}
          onClick={props.deleteAction}
          disabled={props.deleteDisabled}
        />
        {props.displayRetry && (
          <Dropdown.Item
            icon="redo"
            text={i18n.t("Retry")}
            onClick={props.retryAction}
            disabled={props.retryDisabled}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <React.Fragment>
      <div className="d-flex-center-sb">
        {props.tableId === TYPES.ACTIVITIES && props.customSearch ? (
          props.customSearch()
        ) : (
          <Input
            fluid
            style={{ flexGrow: "1", marginRight: "8px" }}
            icon="search"
            placeholder={i18n.t("SearchPlaceholder")}
            onChange={evt => {
              props.loadItems({ [quickSearchParam]: evt.target.value });
            }}
            value={props.filters[quickSearchParam]}
          />
        )}
        {props.activitiesTable ? (
          renderActivitiesMenu()
        ) : (
          <IconButton
            type="secondary"
            icon="columns"
            onClick={props.toggleColumns}
          />
        )}
      </div>
      <div className="d-flex-center-sb" style={{ marginTop: "8px" }}>
        {props.tableId === TYPES.SCHEDULERS && renderSchedulerTypeDropdown()}
        {props.createAction && renderCreateButton()}
      </div>
    </React.Fragment>
  );
}
