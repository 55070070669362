import React from "react";

const ReloadButton = ({ reloadResources, style = {} }) => {
  return (
    <button
      type="button"
      className="reload-button"
      onClick={reloadResources}
      style={style}
    >
      <i className="sync icon" />
    </button>
  );
};

export default ReloadButton;
