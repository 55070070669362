/* eslint-disable react/prefer-stateless-function */
import React, { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import { CompactPicker  } from 'react-color';
import ColorPicker from "./ColorPicker";
// import InputDropdown from "../../../../Ui/InputDropdown";

import i18n from "../../../../i18n";
import { Icon } from "semantic-ui-react";

const ARRAYNUMBERS = [1,2,3,4,5,6,7,8,9,10,11,12]


const GraphColorPicker = props => {
  const {colorList, labelsList} = props;
  const [numberOfcolors, setNumberOfcolors] = useState(undefined);
  // const [colorList, setColorList] = useState(['#6fcf97', '#ffae8b', '#ee5465' ,'#56ccf2', '#f8ae57']);

  const changeColor = (color, index) => {
    const tempList = cloneDeep(colorList);
    tempList[index] = color.hex;
    props.onChange(tempList);
  }
  
  const changeNumberOfColors = length => {
    const tempList = cloneDeep(colorList);
    if(colorList.length < length){
      let lengthDif = length - colorList.length;
      for(let i = 0; i< lengthDif; i++){
        let auxColor = '#' + Math.floor(Math.random()*16777215).toString(16);
        tempList.push(auxColor)
      }
    }
    else{
      let lengthDif = colorList.length - length; 
      tempList.splice(length-1, lengthDif); 
    }
    props.onChange(tempList);
  }

  useEffect(() => {
    setNumberOfcolors(colorList.length)
  }, [colorList]);

  const renderColorList = _ =>{
    const auxColors = [];
    for(let i=0; i<labelsList.length; i++){
      auxColors.push(
        <div className="colorContainer" key={`${i}_${colorList[i]}`}>
          <ColorPicker color={colorList[i]} index={i} last={i === (colorList.length-1) } onChange={changeColor} />
          <span>{labelsList[i]}</span>
        </div>
      )
    }
    return auxColors;
  }

  return (
    <div>
      {/* <h3 className="ui header">
        {i18n.t("ChartColors")}:
      </h3> */}
      <div className='legendSection'>
        {renderColorList()}
      </div>
      {/* <h6>
        *Please select a color for the chart legend.
      </h6> */}
    </div>
  );
};

export default GraphColorPicker;
