import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import ApplicationsRadioInput from "./ApplicationsRadioInput";
import i18n from "../../../i18n";
import ProcessForm from "./Process/ProcessForm";
import ApplicationService from "../../../../services/data_services/application";
import ServiceService from "../../../../services/data_services/service";
import FormFeedWork from "./FeedWorkForm/FormFeedWork";
import InboxFeedWork from "./FeedWorkForm/InboxFeedWork";
import NCWFeedWork from "./FeedWorkForm/NCWFeedWork";
import { isDesktop } from "../../../../services/general";
import { SERVICE_TYPES } from "../../../../constants/service-types";
import { Grid, Divider, Checkbox, Header } from "semantic-ui-react";
import InputField from "../../../Ui/InputField";
import InputDropdown from "../../../Ui/InputDropdown";
import { isNull, isEmpty } from "lodash";

class ApplicationsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFeedWork: true,
      showProcessForm: false,
      resourcesPools: [],
      types: [],
      isUnattended: false
    };
  }

  componentDidMount() {
    this.loadServices();
    this.loadTypes();
  }

  loadServices = () => {
    ServiceService.loadServices()
      .then(result => {
        this.setState(
          {
            services: result.data
          },
          () => {
            if (this.getInitialService()) {
              this.loadResourcesPools(this.getInitialService());
            }
          }
        );
      })
      .catch(() => {
        throw new Error("Services: Not able to fetch");
      });
  };

  loadTypes = () => {
    ApplicationService.getApplicationTypes()
      .then(result => {
        this.setState(
          {
            types: result.data.map(x =>{
              let auxServiceType = isNull(x.service_type) ? 'CMO' : x.service_type
              return {id: x.id, text:x.name, value: x.id,  key: x.id,  service_type: auxServiceType}
            })
          },
          () => {
            if (this.props.application?.type_id) {
              let auxSelected = this.getValueType(this.props.application?.type_id);
              this.setState({                
                typeSelected: auxSelected,
                isUnattended: auxSelected.text.indexOf('Unattended') >= 0
              })
            }
          }
        )
      })
      .catch(() => {
        throw new Error("Services: Not able to fetch");
      });
  };

  getValueType = id => {
    return this.state.types.find(x => x.value === id)
  }

  getInitialService = () => {
    // UiPath ????
    if (this.props.application) {
      switch (this.props.application.service_type) {
        case SERVICE_TYPES.BLUEPRISM:
          return this.props.application.process_entities.length > 0
            ? this.props.application.process_entities[0].service_id
            : null;
        case SERVICE_TYPES.HYPERSCIENCE:
          return this.props.application.ocr?.service_id || null;
        case SERVICE_TYPES.NCW:
          return this.props.application.ncw?.service_id || null;
        default:
          if(this.props.application.type_id){
            return 'CMO'
          }
          return null;
      }
    }
    return null;
  };

  serviceItemsOptions() {
    if (this.state.services) {
      let availableServices;
      if (this.state.typeSelected?.service_type.includes("BluePrism")) {
        availableServices = this.state.services.filter(service =>
          [SERVICE_TYPES.BLUEPRISM, SERVICE_TYPES.UIPATH].includes(
            service.service_type
          )
        );
      } else {
        availableServices = this.state.services.filter(
          service =>
            service.service_type === this.state.typeSelected?.service_type
        );
      }
      return availableServices.map(service => {
        return { value: service.id, text: service.name, key: service.id };
      });
    }
    return [];
  }

  serviceItemsValue(values) {
    if (this.state.services && values.service_id && values.service_type) {
      const availableServices = this.serviceItemsOptions();
      return availableServices.find(
        option => option.value === values.service_id
      );
    }
    return "";
  }

  renderFeedWork = (values, setFieldValue, errors) => {
    if (this.state.showFeedWork) {
      switch (values.trigger_type) {
        case "Conf::Inbox":
          return (
            <InboxFeedWork
              errors={errors}
              values={values}
              preConfigured={this.preConfigured}
              setFieldValue={setFieldValue}
              setShowFeedWork={this.setShowFeedWork}
            />
          );
        case "Form":
          return (
            <FormFeedWork
              errors={errors}
              values={values}
              preConfigured={this.preConfigured}
              setFieldValue={setFieldValue}
              setShowFeedWork={this.setShowFeedWork}
            />
          );
        case "NCW":
          return (
            <NCWFeedWork
              values={values}
              errors={errors}
              preConfigured={this.preConfigured}
              setFieldValue={setFieldValue}
            />
          );
        default:
          return null;
      }
    }

    return null;
  };

  setShowFeedWork = value => {
    this.setState({
      showFeedWork: value
    });
  };

  feedItemsValue(values) {
    return this.feedItems().find(item => item.value === values.trigger_type);
  }

  feedItems() {
    return [
      { text: i18n.t("Email"), value: "Conf::Inbox", key: "Conf::Inbox", },
      { text: i18n.t("Form"), value: "Form", key: "Form" }
    ];
  }

  defaultStringCheckbox(attribute) {
    if (this.props.application) {
      return `${this.props.application[attribute]}`;
    }
    return `false`;
  }

  defaultStringAttribute(attribute) {
    return this.props.application
      ? this.props.application[attribute]
      : undefined;
  }

  defaultProcessEntityAttribute(attribute) {
    return this.props.application &&
      this.props.application.process_entities instanceof Array &&
      this.props.application.process_entities.length > 0
      ? this.props.application.process_entities[0][attribute]
      : undefined;
  }

  defaultCompanyId() {
    return this.props.application && this.props.application.company_id
      ? this.props.application.company_id
      : this.props.user.company_id;
  }

  defaultArrayAttribute = attribute => {
    return this.props.application && this.props.application[attribute]
      ? this.props.application[attribute]
      : [];
  };

  loadResourcesPools = serviceId => {
    const companyId = this.props.user.company_id;
    ApplicationService.loadResourcesPools(companyId).then(response => {
      const resourcesKeys = Object.keys(response.data) || [];
      const selectedService = this.state.services.find(
        service => service.id === serviceId
      );
      const selectedPoolIndex = resourcesKeys.findIndex(
        pool => pool === selectedService.name
      );
      if (resourcesKeys.length > 0 && selectedPoolIndex !== -1) {
        this.setState({
          resourcesPools: response.data[resourcesKeys[selectedPoolIndex]]
        });
      }
    });
  };

  resourcesPoolsItemsOptions() {
    if (!this.state.resourcesPools) return [];

    return this.state.resourcesPools.map(resource => {
      return {
        text: resource.number_resources
          ? `${resource.name} (${resource.number_resources})`
          : resource.name,
        value: resource.name,
        key: resource.name
      };
    });
  }

  resourcesPoolsItemsValue(values) {
    const resourcePoolsOptions = this.resourcesPoolsItemsOptions();
    if (resourcePoolsOptions.length === 0) return null;
    return this.resourcesPoolsItemsOptions().filter(resource => {
      return values.resource_pool.includes(resource.value);
    })?.map(x => x.value);
  }

  preConfigured = () => {
    return this.props.application && this.props.application.pre_configured;
  };

  renderConfigureLink = (showConfigure = false, stateVarToUpdate) => {
    let linkClassName = "configure-link";
    if (showConfigure) {
      linkClassName += " active";
    }
    return (
      <span
        role="button"
        tabIndex={0}
        className={linkClassName}
        onClick={() => {
          if (showConfigure) {
            this.setState(prevState => {
              return { [stateVarToUpdate]: !prevState[stateVarToUpdate] };
            });
          }
        }}
      >
        {i18n.t("Configure")}
      </span>
    );
  };

  setProcessType = values => this.state.types.find(type => type.value === values.service_type)

  setService = values =>
    this.serviceOptions(values.service_type).filter(
      service => service.value === values.service
    );

  resetRPAValues = setFieldValue => {
    setFieldValue("process_entity", {});
    setFieldValue("resource_pool", []);
  };

  renderFeedWorkFromLabel = values => {
    if (!isDesktop()) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "0.5em"
          }}
          className="field"
        >
          <label>{i18n.t("FeedWorkFrom")}</label>
          {this.renderConfigureLink(
            values.trigger_type !== undefined && values.trigger_type !== null,
            "showFeedWork"
          )}
        </div>
      );
    }
    return <label>{i18n.t("FeedWorkFrom")}</label>;
  };

  renderProcessConfig = (values, setFieldValue, isHuman = false, errors) => {
    if (isDesktop()) {
      return (
        <React.Fragment>
            <ProcessForm
              values={values}
              selectedService={this.serviceItemsValue(values)}
              setFieldValue={setFieldValue}
              isDisabled={this.preConfigured()}
              user={this.props.user}
              isHuman = {isHuman}
              errors={errors}
            />
        </React.Fragment>
      );
    }
    return (
      <div >
          <ProcessForm
            values={values}
            selectedService={this.serviceItemsValue(values)}
            setFieldValue={setFieldValue}
            isDisabled={this.preConfigured()}
            user={this.props.user}
            isHuman = {isHuman}
          />
      </div>
    );
  };

  createButtonFields= (setFieldValue, values, errors, handleChange ) =>{
    const fieldClass = isDesktop() ? "field inlineField" : "field";
    return(
      <React.Fragment>
        <div className={`${fieldClass}`} key="create_button">
          <ApplicationsRadioInput
            title={i18n.t("CreateButton")}
            name="create_button"
            fields={[
              { label: "Yes", value: "true" },
              { label: "No", value: "false" }
            ]}
            onChange={(e) => {
            setFieldValue("trigger_id", undefined);
            setFieldValue("identifier_key", undefined);
            setFieldValue("trigger_type", undefined);
            setFieldValue("partner_team_ids", []);
            setFieldValue("manager_team_ids", []);    
            handleChange(e)}}
            values={values}
            setFieldValue={setFieldValue}
          />
        </div>
          {values.create_button === 'true' && 
            <React.Fragment>
            <InputDropdown
                required
                inputlabel={i18n.t("FeedWorkFrom")}         
                options={this.feedItems()}
                value={this.feedItemsValue(values)?.value}
                placeholder={`${i18n.t("Select")}...`}
                search
                error={errors?.trigger_type}
                selection
                onChange={(e , { value }) => {
                  setFieldValue("trigger_id", undefined);
                  setFieldValue("trigger_type", value ? value : undefined);
                  setFieldValue("partner_team_ids", []);
                  setFieldValue("manager_team_ids", []);    
                  if (!value) {
                    this.setState({
                      showFeedWork: false
                    });
                  }
                  else{
                    this.setState({
                      showFeedWork: true
                    });
                  }
                }}
            />
            {this.renderFeedWork(values, setFieldValue, errors)}
          </React.Fragment>}
      </React.Fragment>
    )
  }

  selectedForm = (
    handleChange,
    handleSubmit,
    validateForm,
    setFieldValue,
    isSubmitting,
    values,
    errors
  ) => {
    const fieldClass = isDesktop() ? "field inlineField" : "field";
    switch (this.getValueType(values.service_type)?.service_type) {
      case SERVICE_TYPES.BLUEPRISM:
        return (
          <React.Fragment>
            {this.state.isUnattended ?
            <React.Fragment>
              <InputDropdown
                  required
                  inputlabel={i18n.t("Service")}         
                  options={this.serviceItemsOptions()}
                  value={this.serviceItemsValue(values)?.value}
                  placeholder={`${i18n.t("Select")}...`}
                  search
                  selection
                  error={errors?.service_id}
                  onChange={(e , { value }) => {
                    setFieldValue("service_id", value ? value : undefined);
                    this.resetRPAValues(setFieldValue);
                    if (!value) {
                      this.setState({
                        resourcesPools: [],
                        showProcessForm: false
                      });
                    }
                  }}
              />
              {this.renderProcessConfig(values, setFieldValue,false, errors)} 
            </React.Fragment>
           : 
           <React.Fragment>
             <InputDropdown
                 required
                 inputlabel={i18n.t("Service")}         
                 options={this.serviceItemsOptions()}
                 value={this.serviceItemsValue(values)?.value}
                 placeholder={`${i18n.t("Select")}...`}
                 search
                 selection
                 error={errors?.service_id}
                 onChange={(e , { value }) => {
                   setFieldValue("service_id", value ? value : undefined);
                   this.resetRPAValues(setFieldValue);
                   if (!value) {
                     this.setState({
                       resourcesPools: [],
                       showProcessForm: false
                     });
                   }
                   else{
                    this.loadResourcesPools(value)
                    this.setState({
                      showProcessForm: true
                    });
                     
                   }
                 }}
             />
             {this.renderProcessConfig(values, setFieldValue, true,errors)} 
             <InputDropdown
                 required
                 inputlabel={i18n.t("ResourcePool")}         
                 options={this.resourcesPoolsItemsOptions()}
                 value={this.resourcesPoolsItemsValue(values)}
                 placeholder={`${i18n.t("Select")}...`}
                 search
                 selection
                 multiple
                 error={errors?.resource_pool}
                 onChange={(e , { value }) => {
                    setFieldValue(
                      "resource_pool",
                      value ? value : []
                    );
                  }}
             />
             {this.createButtonFields(setFieldValue, values, errors, handleChange)}
           </React.Fragment>
           }
          </React.Fragment>
        )
      case SERVICE_TYPES.HYPERSCIENCE:
        return (
          <React.Fragment>
          <InputDropdown
              required
              inputlabel={i18n.t("Service")}         
              options={this.serviceItemsOptions()}
              value={this.serviceItemsValue(values)?.value}
              placeholder={`${i18n.t("Select")}...`}
              search
              selection
              error={errors?.service_id}
              onChange={(e , { value }) => {
                setFieldValue("service_id", value ? value : undefined);
                this.resetRPAValues(setFieldValue);
                if (!value) {
                  this.setState({
                    resourcesPools: [],
                    showProcessForm: false
                  });
                }
                else{
                 this.setState({
                   showProcessForm: true
                 });
                  
                }
              }}
          />
           
           {this.createButtonFields(setFieldValue, values, errors, handleChange)}
          </React.Fragment>
        )
      case 'CMO':
        return this.createButtonFields(setFieldValue, values, errors, handleChange)
      case SERVICE_TYPES.NCW:
        return (
          <React.Fragment>
            <InputDropdown
                required
                inputlabel={i18n.t("Service")}         
                options={this.serviceItemsOptions()}
                value={this.serviceItemsValue(values)?.value}
                placeholder={`${i18n.t("Select")}...`}
                search                
                selection
                error={errors?.service_id}
                onChange={(e , { value }) => {
                  setFieldValue("service_id", value ? value : undefined);                
                  setFieldValue("trigger_type", value ? 'NCW' : undefined);
                  
                  this.setState({showFeedWork: true});
                  this.resetRPAValues(setFieldValue);
                  if (!value) {
                    this.setState({
                      resourcesPools: [],
                      showProcessForm: false
                    });
                  }
                }}
            />
            {this.renderFeedWork(values, setFieldValue, errors)}
          </React.Fragment>
        )
      default:
        return null;
    }
    
  }
  getServiceTypeText = values => {
    let auxValues = this.state.isUnattended ? values+'__U' : values;
    return this.state.types.find(x => x.value === auxValues)?.text
  }

  customActions = (setFieldValue, values) => {
    if(values){
      let typeSelected = this.getValueType(values);
      let auxType = typeSelected.text.indexOf('Unattended') >= 0 ? `${typeSelected.service_type}__U` : typeSelected.service_type
      switch (auxType) {
        case SERVICE_TYPES.NCW:
          this.setState({
            showFeedWork: true,
            isUnattended: false
          });
          setFieldValue("trigger_type", 'NCW' );
          setFieldValue("create_button", true );
          break;
        case 'BluePrism__U':
          this.setState({isUnattended: true});
          break;
        case 'BluePrism':
          setFieldValue("trigger_type", '' );
          this.setState({
            isUnattended: false,
            showProcessForm: true,
          });
          break;
        case 'CMO':
          setFieldValue("service_id", 'CMO');
          break;
        default:          
          this.setState({isUnattended: false});
      }
    }
  }

  renderContent = (
    handleChange,
    handleSubmit,
    validateForm,
    setFieldValue,
    isSubmitting,
    values,
    errors
  ) => {
    const fieldClass = isDesktop() ? "field inlineField" : "field";
    return (
      <form className="ui form" onSubmit={handleSubmit}>
      <Grid columns={3}>
        <Grid.Row stretched>
          <Grid.Column>
            <InputField
                inputlabel={i18n.t("ApplicationName")}
                required
                name="name"
                error={errors?.name}
                value={values.name}
                onChange={handleChange}
            />
            <InputField
                inputlabel={i18n.t("UUID")}
                required
                disabled
                name="uuid"
                value={values.uuid}
            />
            <InputDropdown
                required
                inputlabel={i18n.t("Type")}
                options={this.state.types}
                value={this.setProcessType(values)?.value}
                placeholder={`${i18n.t("Select")}...`}
                search
                selection
                onChange={(e , { value }) => {
                  setFieldValue("service_type", value);
                  setFieldValue("service_id", null);
                  setFieldValue("trigger_type", '' );
                  setFieldValue("create_button", false );
                  this.customActions(setFieldValue, value)
                  this.resetRPAValues(setFieldValue);
                  this.setState({
                    showProcessForm: false,
                    typeSelected: this.getValueType(value),
                    resourcesPools: []
                  });
                }}
            />
          </Grid.Column>
          <Grid.Column>
            <InputField
                inputlabel={i18n.t("Description")}
                onChange={evt => {
                  const value = evt.target.value || null;
                  setFieldValue("description", value);
                }}
                name="description"
                value={values.description}
                textarea                
            />
          </Grid.Column>
        </Grid.Row>
        <Divider style={{margin:'1rem 1px'}} />
        {
        values.service_type &&
          <React.Fragment>
            <Grid.Row stretched>
                <Header style={{paddingLeft: '14px'}} as='h1'>{this.state.typeSelected?.text} - App Configuration</Header>
            </Grid.Row>
            <Grid.Row stretched>
              <Grid.Column>
                {this.selectedForm(
                  handleChange,
                  handleSubmit,
                  validateForm,
                  setFieldValue,
                  isSubmitting,
                  values,
                  errors)}
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>
        }
      </Grid>      
      {values.service_type &&
        <div className="actionButtons">
          <button
            className="ui button blue"
            type="submit"
            disabled={isSubmitting}
            onClick={() => {
              validateForm().then(validationErrors => {
                const showFeedWork =
                  validationErrors.trigger_id ||
                  validationErrors.partner_team_ids ||
                  validationErrors.manager_team_ids;
                // this.setState({
                //   showFeedWork
                // });
              });
            }}
          >
            {i18n.t("Save")}
          </button>
        </div>
      }
      </form>
    );
  };

  render() {
    return (
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          let auxValue = {...values};
          auxValue.type_id = this.state.typeSelected.value;
          auxValue.service_type = this.state.typeSelected.service_type;
          if(auxValue.service_type === "BluePrism"){
           if (!isEmpty(auxValue.process_entity)  || !isEmpty(auxValue.resource_pool)){
            this.props.onSubmit(auxValue, setSubmitting);
           }
           else{
            setSubmitting(false);
           }
          }
          else{
            this.props.onSubmit(auxValue, setSubmitting);
          }
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(i18n.t("NameRequired")),
          description: Yup.string().nullable(),
          create_button: Yup.boolean(),
          manager_team_ids: Yup.array().when("trigger_type", {
            is: "NCW",
            then: Yup.array()
              .ensure()
              .required(i18n.t("AtLeastOneApprover")),
            otherwise: Yup.array().nullable()
          }),
          partner_team_ids: Yup.array().when("trigger_type", {
            is: val => val == "NCW",
            then: Yup.array()
              .required(i18n.t("AtLeastOneNonApprover")),
            otherwise: Yup.array().nullable()
          }),
          service_type: Yup.string(),
          trigger_type: Yup.string().when("create_button", {
            is: true,
            then: Yup.string()
              .required(i18n.t("feed work item is mandatory")),
            otherwise: Yup.string().nullable()
          }),
          identifier_key: Yup.string().when("trigger_type", {
            is:  "Form",
            then: Yup.string()
              .required(i18n.t("identifier key is mandatory for form")),
            otherwise: Yup.string().nullable()
          }),
          trigger_id: Yup.string().when("trigger_type", {
            is: (val) => val === "Conf::Inbox" || val === "Form",
            then: Yup.string()
              .required(i18n.t("Form key is mandatory")),
            otherwise: Yup.string().nullable()
          }),
          service_id: Yup.string()
            .nullable()
            .when("service_type", {
              is: serviceType => serviceType,
              then: Yup.string().required(i18n.t("ServiceRequired"))
            }),
            
          resource_pool: Yup.string().when("process_entity", {
            is: processEntity =>
              processEntity.rpa_process && processEntity.rpa_process !== "",
            then: Yup.string().required(i18n.t("ResourcePoolRequired"))
          })
        })}
        initialValues={{
          company_id: this.defaultCompanyId(),
          name: this.defaultStringAttribute("name"),
          uuid: this.defaultStringAttribute("uuid"),
          service_type: this.props.application?.type_id,
          service_id: this.getInitialService(),
          description: this.defaultStringAttribute("description"),
          create_button: this.defaultStringCheckbox("create_button"),
          trigger_type: this.defaultStringAttribute("trigger_type"),
          trigger_id: this.defaultStringAttribute("trigger_id"),
          identifier_key: this.defaultStringAttribute("identifier_key"),
          partner_team_ids: this.defaultArrayAttribute("partner_team_ids"),
          manager_team_ids: this.defaultArrayAttribute("manager_team_ids"),
          process_entity: {
            name: this.defaultProcessEntityAttribute("name"),
            description: this.defaultProcessEntityAttribute("description"),
            rpa_process: this.defaultProcessEntityAttribute("rpa_process"),
            rpa_queues: this.defaultProcessEntityAttribute("rpa_queues")
          },
          resource_pool: this.defaultArrayAttribute("resource_pool")
        }}
        enableReinitialize
      >
        {({
          handleChange,
          handleSubmit,
          validateForm,
          setFieldValue,
          isSubmitting,
          values,
          errors
        }) => (
          <React.Fragment>
            {this.renderContent(
              handleChange,
              handleSubmit,
              validateForm,
              setFieldValue,
              isSubmitting,
              values,
              errors
            )}
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

export default ApplicationsForm;
