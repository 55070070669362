import React from "react";
import { Redirect } from "react-router-dom";
import i18n from "../../i18n";
import SchedulersForm from "./SchedulersForm";
import SchedulerService from "../../../services/data_services/scheduler";
import WithLoadingComponent from "../../Ui/WithLoadingComponent";
import Toast from "../../../services/toasts";
import { checkAndRedirect } from "../../../services/axios";
import Upperbar from "../../Ui/Upperbar/Upperbar";
import { isDesktop } from "../../../services/general";

class SchedulersEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scheduler: undefined
    };

    this.setBreadCrumb();

    this.schedulerId = this.props.match.params.id;

    SchedulerService.loadScheduler(this.schedulerId)
      .then(result => {
        this.setState(
          {
            scheduler: result.data
          },
          this.setBreadcrumb
        );
      })
      .catch(checkAndRedirect);
  }

  setBreadCrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Schedulers"),
        location: "/schedulers"
      },
      {
        title: i18n.t("NewScheduler"),
        location: "/schedulers/new"
      }
    ]);
  }

  setBreadcrumb() {
    this.props.setBreadcrumb([
      {
        title: i18n.t("Schedulers"),
        location: "/schedulers"
      },
      {
        title: this.title(),
        location: `/schedulers/new/${this.schedulerId}`
      }
    ]);
  }

  updateScheduler = (values, setSubmitting, returnToSchedulers) => {
    SchedulerService.updateSchedulers(
      this.schedulerId,
      SchedulerService.prepareValuesFromFormValuesHelper(values)
    )
      .then(() => {
        setSubmitting(false);
        Toast.show.schedulerUpdated();
        returnToSchedulers();
      })
      .catch(e => {
        if (
          Object.prototype.hasOwnProperty.call(
            e.response.data.message,
            "message"
          )
        ) {
          Toast.displayError(e.response.data.message.message[0]);
        } else if (
          Object.prototype.hasOwnProperty.call(e.response.data, "message")
        ) {
          Toast.displayError(e.response.data.message);
        } else {
          Toast.show.schedulerNotCreated();
        }
        setSubmitting(false);
      });
  };

  schedulersVisible() {
    return this.props.user.teams.some(team => {
      return team.scheduling;
    });
  }

  title = () => {
    return this.state.scheduler
      ? `${i18n.t("Schedulers")} - ${this.state.scheduler.name}`
      : `${i18n.t("Schedulers")} - ${this.schedulerId}`;
  };

  render() {
    if (!this.schedulersVisible()) {
      return <Redirect to="/" />;
    }

    return (
      <>
        {isDesktop() && (
          <div style={{ marginTop: "-24px" }}>
            <Upperbar
              title={this.title()}
              onCancel={() => this.props.history.push("/schedulers")}
            />
          </div>
        )}
        <WithLoadingComponent
          loadingCondition={() => !this.state.scheduler}
          contentCondition={() => this.state.scheduler.id}
          noItemsKey="NotAbleLoadTeam"
        >
          <div className="ui segment">
            <SchedulersForm
              onSubmit={this.updateScheduler}
              scheduler={this.state.scheduler}
              action="edit"
            />
          </div>
        </WithLoadingComponent>
      </>
    );
  }
}

export default SchedulersEdit;
