import hasUserPermission from "./detailedPermissionRules";

export const check = (components, action) => {
  if (typeof components === "string") {
    components = [components];
  }

  const reducer = (accumulator, component) =>
    accumulator || hasUserPermission(component, action);

  return components.reduce(reducer, false);
};

const Permissions = props =>
  check(props.component, props.action) ? props.yes() : props.no();

Permissions.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Permissions;
